/** @format */

import * as types from "./types";
import * as commonTypes from "../common/types";
import { type } from "i18next-sprintf-postprocessor";

export default (state = {}, action) => {
  switch (action.type) {
    case commonTypes.ASYNC_START: {
      if (!types[action.waiting]) {
        return state;
      }

      return {
        ...state,
        loading: true,
        waiting: action.waiting,
        done: null,
        action: commonTypes.ASYNC_START,
      };
    }
    case commonTypes.ASYNC_END: {
      if (!types[action.done]) {
        return state;
      }

      return {
        ...state,
        waiting: null,
        done: action.done,
        loading: false,
        action: commonTypes.ASYNC_END,
      };
    }
    case types.COURSE_MODULE_CREATE: {
      return {
        ...state,
        action: types.COURSE_MODULE_CREATE,
        status: action.status,
        message: action.message,
      };
    }
    case types.COURSE_MODULE_LIST: {
      return {
        ...state,
        action: types.COURSE_MODULE_LIST,
        courses: action.status ? action.payload : [],
        status: action.status,
        message: action.message,
      };
    }
    case types.COURSE_MODULE_DELETE: {
      return {
        ...state,
        action: types.COURSE_MODULE_DELETE,
        status: action.status,
        message: action.message,
      };
    }
    case types.COURSE_MODULE_UPDATE: {
      return {
        ...state,
        action: types.COURSE_MODULE_UPDATE,
        status: action.status,
        message: action.message,
      };
    }
    case types.COURSE_LESSON_CREATE: {
      return {
        ...state,
        selectedCourseLessonId: action.status ? action.payload.course_lesson_id : null,
        action: types.COURSE_LESSON_CREATE,
        status: action.status,
        message: action.message,
      };
    }
    case types.COURSE_LESSON_DELETE: {
      return {
        ...state,
        action: types.COURSE_LESSON_DELETE,
        status: action.status,
        message: action.message,
      };
    }
    case types.COURSE_LESSON_UPDATE: {
      return {
        ...state,
        action: types.COURSE_LESSON_UPDATE,
        status: action.status,
        message: action.message,
      };
    }
    case types.COURSE_DOCUMENT_DELETE: {
      return {
        ...state,
        courseDocumentId: action.status ? action.payload.course_document_id : null,
        action: types.COURSE_DOCUMENT_DELETE,
        status: action.status,
        message: action.message,
      };
    }
    case types.REPLICATION_MODULE: {
      return {
        ...state,
        action: types.REPLICATION_MODULE,
        status: action.status,
        message: action.message,
      };
    }
    case types.REPLICATION_LESSON: {
      return {
        ...state,
        action: types.REPLICATION_LESSON,
        status: action.status,
        message: action.message,
      };
    }
    case types.MODULE_UPDATE_POSITION: {
      return {
        ...state,
        action: types.MODULE_UPDATE_POSITION,
        status: action.status,
        message: action.message,
      };
    }
    case types.LESSON_UPDATE_POSITION: {
      return {
        ...state,
        action: types.LESSON_UPDATE_POSITION,
        status: action.status,
        message: action.message,
      };
    }
    case types.COURSE_DOCUMENT_CREATE: {
      return {
        ...state,
        document: action.status ? action.payload : [],
        action: types.COURSE_DOCUMENT_CREATE,
        status: action.status,
        message: action.message,
      };
    }
    case types.COURSE_CUSTOMER_LIST: {
      return {
        ...state,
        action: types.COURSE_CUSTOMER_LIST,
        customers: action.status ? action.payload.customers : [],
        totalRecord: action.status ? action.payload.total_record : 0,
        totalPage: action.status ? action.payload.total_page : 0,
        status: action.status,
        message: action.message,
      };
    }
    case types.COURSE_CUSTOMER_LIST_MODAL: {
      return {
        ...state,
        action: types.COURSE_CUSTOMER_LIST_MODAL,
        customers: action.status ? action.payload.customers : [],
        totalRecord: action.status ? action.payload.total_record : 0,
        totalPage: action.status ? action.payload.total_page : 0,
        status: action.status,
        message: action.message,
      };
    }
    case types.COURSE_CUSTOMER_LIST_OWNED_COURSE: {
      return {
        ...state,
        action: types.COURSE_CUSTOMER_LIST_OWNED_COURSE,
        customers: action.status ? action.payload.customers : [],
        totalRecord: action.status ? action.payload.total_record : 0,
        totalPage: action.status ? action.payload.total_page : 0,
        status: action.status,
        message: action.message,
      };
    }
    case types.LOCK_CUSTOMER: {
      return {
        ...state,
        action: types.LOCK_CUSTOMER,
        status: action.status,
        message: action.message,
      };
    }
    case types.CANCEL_LOCK_CUSTOMER: {
      return {
        ...state,
        action: types.CANCEL_LOCK_CUSTOMER,
        status: action.status,
        message: action.message,
      };
    }
    case types.LOCK_COURSE: {
      return {
        ...state,
        action: types.LOCK_COURSE,
        status: action.status,
        message: action.message,
      };
    }
    case types.CANCEL_LOCK_COURSE: {
      return {
        ...state,
        action: types.CANCEL_LOCK_COURSE,
        status: action.status,
        message: action.message,
      };
    }
    case types.CREATE_CUSTOMER: {
      return {
        ...state,
        action: types.CREATE_CUSTOMER,
        status: action.status,
        message: action.message,
      };
    }
    case types.CREATE_MANY_CUSTOMERS: {
      return {
        ...state,
        action: types.CREATE_MANY_CUSTOMERS,
        status: action.status,
        message: action.message,
      };
    }
    case types.CREATE_ACCOUNT: {
      return {
        ...state,
        action: types.CREATE_ACCOUNT,
        status: action.status,
        message: action.message,
      };
    }
    case types.ADD_COURSE_TO_STUDENT: {
      return {
        ...state,
        action: types.ADD_COURSE_TO_STUDENT,
        status: action.status,
        message: action.message,
      };
    }
    default:
      return state;
  }
};
