import React from "react";
import "../../../assets/css/report/report.css";
import chartConfig from "../../../config/chartconfig";

import reportActions from "../../../redux/futures/report/actions";
import * as reportTypes from "../../../redux/futures/report/types";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { cloneDeep, map } from "lodash";
import SelectOption from "../../../components/SelectOption";

import baseHelper from "../../../helpers/BaseHelper";
import ReportUtmSource from "./UtmSource";

class ReportTopProduct extends React.Component {
  constructor(props) {
    super(props);

    this.moment = baseHelper.getMoment();

    const now = this.moment();
    const firstDay = now.set({
      date: 1,
    });

    this.state = {
      fromDate: baseHelper.getFromDate(firstDay),
      toDate: baseHelper.getToDate(this.moment()),
      utm_select: 0,
      isLoadingInfo: false,
      data_utm: [],
      data_utm_source: [],
      data_utm_campaign: [],
      data_utm_term: [],
      data_utm_content: [],
      data_utm_medium: [],
    };

    this.listTag = [
      {
        name: props.t("REPORTS.UTM.SOURCE"),
        value: 0,
      },
      {
        name: props.t("REPORTS.UTM.CAMPAIGN"),
        value: 1,
      },
      {
        name: props.t("REPORTS.UTM.TERM"),
        value: 2,
      },
      {
        name: props.t("REPORTS.UTM.MEDIUM"),
        value: 3,
      },
      {
        name: props.t("REPORTS.UTM.CONTENT"),
        value: 4,
      },
    ];
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.report.action != nextProps.report.action) {
      if (nextProps.report.action == reportTypes.OVERVIEW_REPORT) {
        this.setState({
          isLoadingInfo: nextProps.report.loading,
        });
        if (nextProps.report.status && nextProps.report.data && nextProps.report.data.revenue_utm) {
          this.setState({
            data_utm_source: nextProps.report.data.revenue_utm.rp_utm_source,
            data_utm: nextProps.report.data.revenue_utm.rp_utm_source,
            data_utm_campaign: nextProps.report.data.revenue_utm.rp_utm_campaign,
            data_utm_term: nextProps.report.data.revenue_utm.rp_utm_term,
            data_utm_content: nextProps.report.data.revenue_utm.rp_utm_content,
            data_utm_medium: nextProps.report.data.revenue_utm.rp_utm_medium,
          });
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.report.message, "OK");
        }
      }
    }
  }

  componentDidMount() {
    window.LadiUI.init();
    this.setState({
      isLoadingInfo: true,
    });
    // this.getReportTopProduct();
  }
  componentDidUpdate() {
    window.LadiUI.init();
  }

  setUtm() {
    const { utm_select, data_utm_source, data_utm_campaign, data_utm_term, data_utm_content, data_utm_medium } = this.state;
    switch (utm_select) {
      case 0:
        this.setState({
          data_utm: data_utm_source,
        });
        break;
      case 1:
        this.setState({
          data_utm: data_utm_campaign,
        });
        break;
      case 2:
        this.setState({
          data_utm: data_utm_term,
        });
        break;
      case 3:
        this.setState({
          data_utm: data_utm_medium,
        });
        break;
      case 4:
        this.setState({
          data_utm: data_utm_content,
        });
        break;

      default:
        break;
    }
  }

  render() {
    const { utm_select, isLoadingInfo, data_utm } = this.state;

    const { t } = this.props;

    return (
      <div className="chart-item revenue-stage-chart">
        <div className="head-card top-product">
          <div className="item-card">
            <i className="ladi-icon icon-orders"></i>
            <h3 className="mr-l-5">{t("REPORTS.UTM.TITLE")}</h3>
          </div>
          <div>
            <SelectOption
              wrapperStyle={{ minWidth: 150 }}
              items={this.listTag}
              _key="value"
              _value="name"
              currentKey={utm_select}
              onSelectItem={(item) => {
                this.setState(
                  {
                    utm_select: item.value,
                  },
                  () => {
                    this.setUtm();
                  }
                );
              }}
            />
          </div>
        </div>
        <div className="content-chart-utm">
          <div className="chart-utm">
            <ReportUtmSource data={data_utm} />
          </div>
          <div className="table-utm">
            {/* <table className={`ladiui table text-left ${isLoadingInfo ? "loader" : ""}`}> */}
            <table className={`ladiui table text-left`}>
              {/* <table className={`ladiui table`}> */}
              <thead>
                <tr className="ladiui table-vertical header">
                  <th name="full_name" scope="col">
                    {t("REPORTS.UTM.TABLE.STT")}
                  </th>
                  <th name="full_name" scope="col">
                    {t("REPORTS.UTM.TABLE.NAME")}
                  </th>
                  <th name="email" scope="col">
                    {t("REPORTS.UTM.TABLE.REVENUE")}
                  </th>
                  <th name="phone" scope="col">
                    {t("REPORTS.UTM.TABLE.NUM")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {map(data_utm, (value, index) => {
                  if (!value.refund) value.refund = 0;

                  return (
                    <tr key={index} className="ladiui table-vertical">
                      <td>{index + 1}</td>
                      <td>{value.utm_name || t("REPORTS.UTM.NULL")}</td>
                      <td>{baseHelper.formatMoneyPostFix(value.total - value.refund, this.props.storeReducer.userInfo.currentStore.currency_symbol)}</td>
                      <td>{value.num_order}</td>
                    </tr>
                  );
                })}
                {data_utm.length <= 0 && (
                  <tr className="text-center">
                    <td colSpan="100%">{t("REPORTS.PRODUCT.NO_DATA")}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    topProductReport: (data) => dispatch(reportActions.topProductReport(data)),
  };
};

const mapStateToProps = (state) => ({
  report: { ...state.report },
  storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReportTopProduct));
