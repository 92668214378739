import React from 'react';
import PropTypes from 'prop-types';
import { map, remove } from 'lodash';
import Modal from '../../../../components/Modal';

import baseHelper from '../../../../helpers/BaseHelper';
import { useTranslation } from 'react-i18next';
import Input from '../../../../components/Input';
import appConfig from '../../../../config/app';

// ModalGoogleSheet.propTypes = {
//     onCancel: PropTypes.func,
// }

export default function ModalGoogleSheet(props) {

    const { mode, formAccount } = props;
    const { t } = useTranslation();

    return (
        <div>
            <div className="content">
                {
                    mode == appConfig.FORM_MODE.EDIT
                    && <div className="ladiui form-group">
                        <label className="ladiui-label">{t('COMMON.EMAIL')}</label>
                        <input type="text" style={{ padding: "10px", borderRadius: '5px', background: '#e8e8e8' }} disabled className="ladiui form-control" value={formAccount.email} />
                    </div>
                }
                <div className="ladiui form-group">
                    <div className="text-center" style={{ marginTop: '40px' }}>
                        {t('INTEGRATE.ACCOUNT.LB_GOOGLE_SHEET')}
                    </div>
                </div>
            </div>

        </div>
    )
}

