import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import baseHelper from '../../../../helpers/BaseHelper';
import Image from '../../../../components/Image';

import ModalAddDiscount from './ModalAddDiscount';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// eslint-disable-next-line max-lines-per-function
function ModalDiscountHomePage(props) {
    const { t, onClose, isShow, innerStyle, selectedDiscount, handleAddContentLocation, dataSelect, selectedIndex } = props;
    const wrapperRef = useRef(null);

    // useEffect(() => {
    //   function handleClickOutside(event) {
    //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
    //       onClose(false);
    //     }
    //   }
    //   document.addEventListener("mousedown", handleClickOutside);
    //   return () => {
    //     document.removeEventListener("mousedown", handleClickOutside);
    //   };
    // }, [wrapperRef]);

    const [isShowModalAddDisount, setIsShowModalAddDiscount] = useState(false);
    const [discounts, setDiscounts] = useState([]);

    const dispatch = useDispatch();

    const [title, setTitle] = useState('Danh sách khuyến mãi');

    useEffect(() => {
        if (dataSelect) {
            setTitle(dataSelect.title || title);
            setDiscounts(dataSelect.values || []);
        }
    }, [dataSelect]);

    const eventCancel = () => {
        onClose(false);
    };

    const onChangeTitle = (e) => {
        let { value } = e.target;

        setTitle(value);
    };

    const getValueByType = (item) => {
        let symbol = ' đ';
        let amount = 0;
        if (item.type == 1) {
            amount = item.value;
            symbol = ' %';
        } else {
            amount = item.value;
            symbol = ' đ';
        }

        return amount + symbol;
    };

    const deleteDiscount = (discountId) => {
        let _discounts = [...discounts];
        if (discountId) {
            _discounts = _discounts.filter((item) => item.discount_id != discountId);
        }

        setDiscounts(_discounts);
    };

    const listDiscountItem = () => {
        return discounts.map((item, index) => {
            return (
                <Draggable key={index} draggableId={`${index}`} index={index}>
                    {(provided, snapshot) => (
                        <tr
                            className='ladiui table-vertical'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                                ...provided.draggableProps.style,
                                userSelect: 'none',
                                background: snapshot.isDragging ? '#e8f0fe' : 'none',
                            }}
                        >
                            <td>
                                <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg'} />
                            </td>
                            <td>
                                <a href={`/discounts?is_new=true&selected_discount_id=${item.discount_id ? item.discount_id : ""}`} target='_blank'>{item.code} </a>
                                {
                                    item.status && item.status != "Active"
                                    &&
                                    <span style={{ color: "#D94F45", marginLeft: "4px" }}> {item.status == "Pending" ? " (Chưa áp dụng)" : " (Hết hạn)"} </span>
                                }
                            </td>
                            <td>{item.rule_type == 1 ? 'Mã khuyến mãi' : 'Chương trình khuyến mãi'}</td>
                            <td>{getValueByType(item)}</td>
                            <td className='text-right pd-0'>
                                <a onClick={() => deleteDiscount(item.discount_id)}>
                                    <i className='ladi-icon icon-bin' />
                                </a>
                            </td>
                        </tr>
                    )}
                </Draggable>
            );
        });
    };

    const AddContentDiscount = () => {
        let data = {
            title: title,
            type: 'DISCOUNT',
            // values: discounts && discounts.length > 0 ? discounts.map((item) => item.discount_id) : [],
            values: discounts && discounts.length > 0 ? discounts : [],
        };
        handleAddContentLocation(data, selectedIndex);
    };

    const handleAddDiscount = (item) => {
        let _discounts = [...discounts];

        _discounts.push(item);

        setDiscounts(_discounts);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    const onDragEndSelectedDiscount = (result) => {
        if (!result.destination || result.source.index == result.destination.index) {
            return;
        }
        const items = reorder(discounts, result.source.index, result.destination.index);
        setDiscounts(items);
    };

    return (
        <>
            <div className={`ladiui modal ${isShow ? 'show' : ''}`} id='homepage-discount-modal'>
                <div className='ladiui modal-dialog modal-dialog-centered w-600' ref={wrapperRef}>
                    <div className='ladiui modal-content '>
                        <div className='ladiui modal-header '>
                            <h2 className='ladiui modal-title '>Khuyến mãi</h2>
                            <button data-dismiss='modal' data-target='add-product' onClick={eventCancel} className='ladiui modal-close'>
                                <i className='ladiui icon icon-ldp-close'></i>
                            </button>
                        </div>
                        <div className='ladiui modal-body'>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>Tiêu đề</label>
                                <input
                                    className='ladiui form-control'
                                    value={title}
                                    placeholder='Nhập vào tiêu đề'
                                    onChange={onChangeTitle}
                                />
                            </div>
                            <div className={`list-discounts mt-24`}>
                                <h3 className='ladiui-label'>Danh sách khuyến mãi</h3>
                                {discounts && discounts.length > 0 && (
                                    <DragDropContext onDragEnd={onDragEndSelectedDiscount}>
                                        <Droppable droppableId='droppable'>
                                            {(provided, snapshot) => (
                                                <table
                                                    className='ladiui table panel-package table-custom'
                                                    ref={provided.innerRef}
                                                    style={{
                                                        background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                                    }}
                                                >
                                                    <thead>
                                                        <tr className='ladiui table-vertical header'>
                                                            <th className='text-left' />
                                                            <th className='text-left'>Mã khuyến mãi</th>
                                                            <th className='text-left'>Loại khuyến mãi</th>
                                                            <th className='text-left'>Giá trị</th>
                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {listDiscountItem()}
                                                        {provided.placeholder}
                                                    </tbody>
                                                </table>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                )}
                            </div>

                            <div className='cursor-pointer' style={innerStyle}>
                                <a className='flex-center-left cursor-pointer mt-24 pd-0' onClick={() => setIsShowModalAddDiscount(true)}>
                                    <img className='mr-8' src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg'} alt='' />
                                    <div>Thêm khuyến mãi</div>
                                </a>
                            </div>
                        </div>

                        <div className='ladiui modal-footer '>
                            <div className='ladiui footer-modal-btn'>
                                <button type='button' className='ladiui btn btn-secondary bold' onClick={eventCancel}>
                                    {t('ACTIONS.CLOSE')}
                                </button>
                                <button type='button' className='ladiui btn btn-primary' onClick={() => AddContentDiscount()}>
                                    {selectedIndex >= 0 ? 'Cập nhật' : 'Thêm nội dung'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isShowModalAddDisount && (
                <ModalAddDiscount
                    onClose={(status) => setIsShowModalAddDiscount(status)}
                    isShow={isShowModalAddDisount}
                    handleAddDiscount={handleAddDiscount}
                    selectedDiscounts={discounts}
                />
            )}
        </>
    );
}

export default withTranslation()(ModalDiscountHomePage);
