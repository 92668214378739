import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import baseHelper from "../../helpers/BaseHelper";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";

import LoadingTable from "../../components/LoadingTable";

function ReportRevenueByStaff(props) {
  const { revenue_by_staff, fromDate, toDate, queryOverTime } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const storeReducer = useSelector((state) => state.store);

  const getOption = () => {
    if (!revenue_by_staff || revenue_by_staff.length === 0) return [];

    let categories = []
    let dataSuccess = []
    let dataPending = []

    revenue_by_staff.resultSuccess.map((value, index) => {
      if (!value.refund)
        value.refund = 0
      let indexStaff = categories.indexOf(value.email)

      if (indexStaff < 0) {
        categories.push(value.email)
        dataSuccess.push(value.total - value.refund)
        dataPending.push(0)
      } else {
        dataSuccess[indexStaff] = value.total - value.refund;
      }

    })
    revenue_by_staff.resultPending.map((value, index) => {
      if (!value.refund)
        value.refund = 0

      let indexStaff = categories.indexOf(value.email)
      if (indexStaff < 0) {
        categories.push(value.email)
        dataSuccess.push(0)
        dataPending.push(value.total - value.refund)
      } else {
        dataPending[indexStaff] = value.total - value.refund;
      }

    })

    const options = {
      title: {
        text: "",
      },
      colors: ['#A6CEE3', '#1F78B4', '#A8D24C', '#F7B500'],
      xAxis: {
        categories: categories,
        gridLineWidth: 0.5,
        gridLineColor: "#f5f5f5"
      },
      yAxis: {
        // min: 0,
        gridLineColor: "#f5f5f5",
        title: {
          text: "",
        },
        stackLabels: {
          enabled: false,
        },
      },
      tooltip: {
        shared: true,
        backgroundColor: "#061438",
        borderColor: "#061438",
        borderRadius: 3,
        style: {
          color: "#ffffff",
        },
        useHTML: true,
        formatter: function () {
          let text = ``;
          let total = 0;

          this.points.map((item) => {
            total = item.total;
            text += `
            <br/>
            <div class="text-tooltip">  
              <div class="point-tolltip" style="background:${item.point.color}"></div> 
              <div>
                ${item.point.series.name}: ${baseHelper.formatMoneyPostFix(item.point.y, storeReducer.userInfo.currentStore.currency_symbol)}
              </div> 
            </div>
            `
          })
          return (
            `${this.x}
            <br/>
            ${text}
            <br/>
            <div>
            ${t("REPORTS.REVENUE_REPORT.TOTAL")}: ${baseHelper.formatMoneyPostFix(total, storeReducer.userInfo.currentStore.currency_symbol)}
          </div> `
          );
        },
      },
      legend: {
        enabled: true
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          }
        }
      },
      series: [
        {
          type: "column",
          name: t("REPORTS.REVENUE_REPORT.SUCCESS"),
          data: dataSuccess,
        },
        {
          type: "column",
          name: t("REPORTS.REVENUE_REPORT.PENDING"),
          data: dataPending,
        },
      ],
    };
    return options;
  };

  return (
    <div className="chart-item revenue-stage-chart">
      <div className="head-card space-between">
        <div className="flex">
          <i className="ladi-icon icon-orders"></i>
          <h3 className="mr-l-5">{t("REPORTS.REVENUE_BY_STAFF")}</h3>
        </div>
        <NavLink className="mr-l-5" to={`/reports/orders_over_time?${queryOverTime}`}>
          {t(("COMMON.DETAIL"))}
        </NavLink>
      </div>
      <div className="content-chart">{!isLoading ? <HighchartsReact highcharts={Highcharts} options={getOption()} /> : <LoadingTable />}</div>
    </div>
  );
}

export default ReportRevenueByStaff;
