import React from 'react';

export default class Footer extends React.Component {
    render() {
        return (
            <div>
                <iframe name="print_frame" width="0" height="0" frameBorder="0" src="about:blank"></iframe>
            </div>
        )
    }
}