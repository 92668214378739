import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { map } from "lodash";
import appConfig from "../config/app";

class SidebarItem extends React.Component {
  static propTypes = {
    keyStr: PropTypes.array,
    classLi: PropTypes.string,
    classNav: PropTypes.string,
    classIcon: PropTypes.string,
    to: PropTypes.any,
    title: PropTypes.string,
    userEmail: PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { classLi, classNav, classIcon, to, title, keyStr, t, children, userEmail } = this.props;
    const paths = window.location.pathname.split("/");

    const classOpen = keyStr.indexOf(paths[1]) >= 0 ? `${classLi} active` : classLi;

    let classSubmenuITem = paths[paths.length - 1];

    return (
      <li className={classOpen}>
        {to ? (
          <NavLink to={to} className={classNav}>
            <i className={classIcon} />
            <span className="ladiui menu-text">{t(title)}</span>
          </NavLink>
        ) : (
          <a className={classNav} data-toggle="dropdown">
            <i className={classIcon} />
            <div className="ladiui menu-text">
              <span className="ladiui"> {t(title)}</span>
              <i className="ladiui icon icon-prev"></i>
            </div>
          </a>
        )}
        {children && (
          <ul className={`ladiui menu-child dropdown-menu`}>
            {map(children, (item, index) => {
              if (!item.to.includes("/store-page")) {
                return (
                  <li key={index} className={item.classLi}>
                    <NavLink to={item.to} className={item.classLink}>
                      {t(item.title)}
                    </NavLink>
                  </li>
                );
              } else {
                if (userEmail && appConfig.WHITE_LIST_EMAIL.includes(userEmail)) {
                  return (
                    <li key={index} className={item.classLi}>
                      <NavLink to={item.to} className={item.classLink}>
                        {t(item.title)}
                      </NavLink>
                    </li>
                  );
                }
              }
              // return (
              //   <li key={index} className={item.classLi}>
              //     <NavLink to={item.to} className={item.classLink}>
              //       {t(item.title)}
              //     </NavLink>
              //   </li>
              // );
            })}
          </ul>
        )}
      </li>
    );
  }
}

export default withTranslation()(SidebarItem);
