import React, { useState } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import baseHelper from "../../helpers/BaseHelper";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import LoadingTable from "../../components/LoadingTable";

function ReportOrder(props) {
  const { num_orders_day, queryOverTime, fromDate, toDate } = props;
  const [isLoading, setIsLoading] = useState(false);
  const moment = baseHelper.getMoment();
  const { t } = useTranslation();

  const getOption = () => {
    if (!num_orders_day || num_orders_day.length === 0 || !fromDate || !toDate) return [];

    let categories = [];

    let dataSuccess = []
    let dataPending = []
    let dataCancel = []

    for (
      let datex = moment(fromDate).format("YYYY/MM/DD");
      datex <= moment(toDate).format("YYYY/MM/DD");
      datex = moment(datex, 'YYYY/MM/DD').add(1, "days").format("YYYY/MM/DD")
    ) {
      let xxxxx = moment(datex).format("DD/MM/YYYY");
      categories.push(xxxxx);

      // let datax = dataRevenueTotal[idDate];

      let itemSuccess = num_orders_day.orderSuccess.find(item => moment(item.date).format("DD/MM/YYYY") === xxxxx)
      if (itemSuccess) dataSuccess.push(itemSuccess.total_success); else dataSuccess.push(0);

      let itemPending = num_orders_day.orderPending.find(item => moment(item.date).format("DD/MM/YYYY") === xxxxx)

      if (itemPending) dataPending.push(itemPending.total_pending); else dataPending.push(0);

      let itemCancel = num_orders_day.orderCancel.find(item => moment(item.date).format("DD/MM/YYYY") === xxxxx)

      if (itemCancel) dataCancel.push(itemCancel.total_canceled); else dataCancel.push(0);

    }

    const options = {
      title: {
        text: "",
      },
      colors: ['#A6CEE3', '#1F78B4', '#A8D24C', '#F7B500'],
      xAxis: {
        categories: categories,
        gridLineWidth: 0.5,
        gridLineColor: "#f5f5f5"

      },
      yAxis: {
        gridLineColor: "#f5f5f5",
        title: {
          text: "",
        },
        allowDecimals: false,
        stackLabels: {
          enabled: false,
        },
      },
      tooltip: {
        shared: true,
        backgroundColor: "#061438",
        borderColor: "#061438",
        borderRadius: 3,
        style: {
          color: "#ffffff",
        },
      },
      labels: {},
      series: [
        {
          name: t("REPORTS.ORDER_REPORT.SUCCESS"),
          data: dataSuccess,
        },
        {
          name: t("REPORTS.ORDER_REPORT.PENDING"),
          data: dataPending,
        },
        {
          name: t("REPORTS.ORDER_REPORT.CANCEL"),
          data: dataCancel,
        },
      ],
    };
    return options;
  };

  const getOrderSuccess = () => {
    if (!num_orders_day || !num_orders_day.orderSuccess) return 0
    let total = 0;
    num_orders_day.orderSuccess.map((item) => {
      total += item.total_success
    })

    return baseHelper.numberToCurrencyStyle(total, ".");
  }
  const getOrderPending = () => {
    if (!num_orders_day || !num_orders_day.orderPending) return 0
    let total = 0;
    num_orders_day.orderPending.map((item) => {
      total += item.total_pending
    })

    return baseHelper.numberToCurrencyStyle(total, ".");
  }
  const getOrderCancel = () => {
    if (!num_orders_day || !num_orders_day.orderCancel) return 0
    let total = 0;
    num_orders_day.orderCancel.map((item) => {
      total += item.total_canceled
    })

    return baseHelper.numberToCurrencyStyle(total, ".");
  }

  return (
    <div className="chart-item">
      <div className="head-card space-between">
        <div className="flex">
          <i className="ladi-icon icon-orders"></i>
          <h3 className="mr-l-5">{t(("REPORTS.ORDER"))}</h3>
        </div>
        <NavLink className="mr-l-5" to={`/reports/orders_over_time?${queryOverTime}`}>
          {t(("COMMON.DETAIL"))}
        </NavLink>
      </div>
      <div className="content-chart">{!isLoading ? <HighchartsReact highcharts={Highcharts} options={getOption()} /> : <LoadingTable />}</div>
      <div className="bottom-cart">
        <div>
          <span> <b>{t("REPORTS.ORDER_REPORT.TOTAL_SUCCESS")}:</b> {getOrderSuccess()}</span>
        </div>
        <div>
          <span><b>{t("REPORTS.ORDER_REPORT.TOTAL_PENDING")}:</b> {getOrderPending()}</span>
        </div>
        <div>
          <span><b>{t("REPORTS.ORDER_REPORT.TOTAL_CANCEL")}:</b> {getOrderCancel()}</span>
        </div>
      </div>
    </div>
  );
}

export default ReportOrder;
