import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Input from "./Input";

import fileActions from "../redux/futures/file/actions";
import * as fileTypes from "../redux/futures/file/types";

import productActions from "../redux/futures/product/actions";
import * as productTypes from "../redux/futures/product/types";

import DatePicker from "./DatePicker/CustomDatePicker";

import SelectManyDropdown from "./SelectManyDropdown";

import appConfig from "../config/app";
import baseHelper from "../helpers/BaseHelper";

import LoadingScene from "./LoadingScene";

import moment from "moment";
import { map, compact, find, includes } from "lodash";


let CDN = "https://w.ladicdn.com/";

// eslint-disable-next-line max-lines-per-function
function ModalAddProductView(props) {
    const { t, onClose, isShow, innerStyle, productId } = props;
    const wrapperRef = useRef(null);
    const dispatch = useDispatch();

    const fileReducer = useSelector((state) => state.file);

    const productReducer = useSelector((state) => state.product);

    const inputsRef = new Set();

    const eventCancel = () => {
        onClose(false);
    };

    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const [dataProductView, setDataProductView] = useState({
        avatar: "",
        name: "",
        point: 5,
        images: [],
        date_created: new Date(),
        content: ""
    })

    useEffect(() => {
        if (props.selectedProductReview) {
            let _data = props.selectedProductReview;

            setDataProductView({
                avatar: _data.avatar ? _data.avatar : dataProductView.avatar,
                name: _data.name ? _data.name : dataProductView.name,
                point: _data.point ? _data.point : dataProductView.point,
                images: _data.images ? _data.images : dataProductView.images,
                date_created: _data.date_created ? new Date(_data.date_created) : dataProductView.date_created,
                content: _data.content ? _data.content : dataProductView.content,
                product_reviews_id: _data.product_reviews_id ? _data.product_reviews_id : null,
            })
        }
    }, [props.selectedProductReview])

    useEffect(() => {
        if (props.isManageReview) {
            let payload = {
                search: {
                },
                paged: 1,
                limit: 20,
            };

            dispatch(productActions.list(payload))
        }
    }, [props.isManageReview])

    useEffect(() => {
        if (productReducer.action == productTypes.LIST_PRODUCT) {
            if (productReducer.status) {
                if (productReducer.products && productReducer.products.length > 0) {
                    let _data = [...products];

                    productReducer.products.map((item, index) => {
                        const isExitProduct = _data.some((_item) => _item.value == item.product_id);
                        if (!isExitProduct) {
                            _data.unshift({
                                name: item.name,
                                value: item.product_id,
                            });
                        }
                    });




                    setProducts(_data);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
    }, [productReducer]);

    useEffect(() => {
        if (fileReducer.action == fileTypes.UPLOAD_AVATAR) {
            if (fileReducer.status) {
                if (fileReducer.avatar_url) {
                    setDataProductView({
                        ...dataProductView,
                        avatar: fileReducer.avatar_url
                    })
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', fileReducer.message, 'OK');
            }
        }
        if (fileReducer.action == fileTypes.UPLOAD_PRODUCT_REVIEW_IMAGES) {
            let _selectedImages = [...dataProductView.images];
            if (fileReducer.status) {
                const newUploadedImage = map(fileReducer.product_images, item => item)

                if (newUploadedImage && newUploadedImage.length > 0 && (_selectedImages.length + newUploadedImage.length) <= 6) {
                    map(newUploadedImage, (item, index) => {
                        _selectedImages.push(item);
                    });

                    setDataProductView({
                        ...dataProductView,
                        images: _selectedImages
                    })
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', "Số lượng ảnh không được vượt quá 6", 'OK');
                }

                // selectedBanners.push(newUploadedImage);

                // setDataProductView({
                //     ...dataProductView,
                //     images: _selectedImages
                // })
            }
        }
    }, [fileReducer])

    const removeAvatarReview = () => {
        setDataProductView({
            ...dataProductView,
            avatar: ""
        })
    }

    const fileChangedHandlerAvatarReview = (event) => {
        if (event.target.files.length > 0) {
            const uploadFile = event.target.files[0];
            const form = baseHelper.getFormDataUpload([uploadFile]);
            if (form) {
                dispatch(fileActions.uploadAvatar(form));
            }
        }
    };

    const onChangeDataProductView = (e) => {
        let { name, value } = e.target;

        setDataProductView((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const handleAddView = () => {
        let errors = [];
        const _inputsRef = compact(Array.from(inputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);

            return;
        }

        if (props.isManageReview && !dataProductView.product_reviews_id) {

            let payload = {
                product_ids: selectedProducts && selectedProducts.length > 0 ? selectedProducts : [],
                review: {
                    ...dataProductView,
                }
            }

            props.handleCreateReviews(payload);

        } else {
            if (dataProductView.product_reviews_id) {
                let payload = {
                    review: {
                        ...dataProductView,
                    }
                }

                props.handleUpdateReview(payload);
            } else {
                let payload = {
                    product_id: props.productID,
                    review: {
                        ...dataProductView,
                    }
                }

                props.handleCreateReview(payload);
            }
        }
    }

    const handleClickInputFileImageReview = () => {
        document.getElementById("upload-list-image-review").click();
    };

    const fileChangedHandlerListImageReview = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                dispatch(fileActions.uploadProductPreviewImages(form));
            }
        }
    };
    const handleDeleteImagePreview = (index) => {
        let _selectedImages = [...dataProductView.images];
        _selectedImages.splice(index, 1);

        setDataProductView({
            ...dataProductView,
            images: _selectedImages
        });
    };

    const handleSearchData = (value) => {
        let payload = {
            search: {
                name: value
            },
            paged: 1,
            limit: 20,
        };

        dispatch(productActions.list(payload))
    }


    const handleRefreshDataProduct = () => {
        let payload = {
            search: {
            },
            paged: 1,
            limit: 20,
        };

        dispatch(productActions.list(payload))
    }


    const isLoadingUploadImage = fileReducer.loading && includes([fileTypes.UPLOAD_PRODUCT_REVIEW_IMAGES, fileTypes.UPLOAD_AVATAR], fileReducer.waiting);

    return (
        <>
            {
                isLoadingUploadImage && <LoadingScene blur={true} />
            }
            <div className={`ladiui modal ${isShow ? "show" : ""}`} id="modal-add-product-review" >
                <div className="ladiui modal-dialog modal-dialog-centered w-600" ref={wrapperRef}>
                    <div className="ladiui modal-content ">
                        <div className="ladiui modal-header ">
                            <h2 className="ladiui modal-title ">
                                {dataProductView.product_reviews_id ? "Sửa đánh giá" : "Thêm mới đánh giá"}
                            </h2>
                            <button data-dismiss="modal" data-target="add-product" onClick={eventCancel} className="ladiui modal-close">
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>
                        <div className="ladiui modal-body" style={{ position: "initial" }}>
                            <div className="block-content-product-view config-panel">
                                {
                                    props.isManageReview && !dataProductView.product_reviews_id
                                    &&
                                    <div className='ladiui form-group'>
                                        <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                                            Chọn sản phẩm
                                        </label>
                                        <SelectManyDropdown
                                            data={products}
                                            _key='name'
                                            _value='value'
                                            pickTitle={'Tiêu đề'}
                                            placeHolder={'Chọn sản phẩm'}
                                            onSubmit={(items) => {
                                                setSelectedProducts(items);
                                            }}
                                            innerClassName='w100'
                                            styleCustomPeople={{ left: 0, maxHeight: '450px', overflow: 'auto' }}
                                            buttonStyle={{
                                                width: '100%',
                                                paddingRight: '30px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: ' -webkit-box',
                                                webkitLineClamp: 1,
                                                webkitBoxOrient: 'vertical',
                                                textAlign: 'left',
                                            }}
                                            selectedItems={selectedProducts}
                                            isShowBlockInputSearchCallApi={true}
                                            isShowBlockInputSearch={true}
                                            isManageReview={true}
                                            isCourse={true}
                                            handleSearchData={handleSearchData}
                                            handleRefreshData={handleRefreshDataProduct}
                                        />
                                    </div>
                                }
                                <div className="content">
                                    <div className="panel">
                                        <div className="panel-line">
                                            <div className="main-description">
                                                Ảnh đại diện
                                                <br />
                                                <div className="sub-description" style={{ whiteSpace: "nowrap" }}>
                                                    Kích thước 198x198 (px)
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="preview-store-logo">
                                                    <div id="store-logo" style={{ width: "max-content" }}>
                                                        <img
                                                            className="store-logo"
                                                            src={dataProductView.avatar ? `${CDN}${dataProductView.avatar}` : "https://w.ladicdn.com/ladiui/ladisales/no-image.svg"}
                                                            alt=""
                                                        />
                                                        <div id="store-logo-overlay">
                                                            <div className="ladiui btn-group">
                                                                <div className="ladiui dropdown">
                                                                    <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle" style={{ minWidth: "auto" }}>
                                                                        <i className="ladiui icon icon-ldp-pen-edit"></i>
                                                                    </button>
                                                                    <ul className="ladiui dropdown-menu custom-dropdown-menu" style={{ minWidth: "100px", textAlign: "left" }}>
                                                                        <li className="ladiui open-modal">
                                                                            <label htmlFor="upload-avatar-review">Thay ảnh</label>
                                                                        </li>
                                                                        <li className="ladiui dropdown-item danger-text" onClick={() => removeAvatarReview()}>
                                                                            Xóa
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="file"
                                                        id="upload-avatar-review"
                                                        name="file"
                                                        accept="image/*"
                                                        multiple={false}
                                                        onChange={fileChangedHandlerAvatarReview}
                                                        onClick={(event) => {
                                                            event.target.value = null;
                                                        }}
                                                        style={{ display: "none" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ladiui form-group">
                                    <label className="ladiui-label">Tên khách hàng</label>
                                    <Input
                                        name="name"
                                        validationName={"tên khách hàng"}
                                        placeholder={"Nhập tên khách hàng"}
                                        value={dataProductView.name}
                                        onChange={(e) => onChangeDataProductView(e)}
                                        ref={(ref) => inputsRef.add(ref)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>
                                <div className="flex">
                                    <div className="ladiui form-group mr-12 mt-0">
                                        <label className="ladiui-label">Điểm đánh giá (Tối đa 5 điểm)</label>
                                        <Input
                                            name="point"
                                            type="number"
                                            validationName={"điểm đánh giá"}
                                            placeholder={"Nhập điểm đánh giá"}
                                            value={dataProductView.point}
                                            onChange={(e) => onChangeDataProductView(e)}
                                            ref={(ref) => inputsRef.add(ref)}
                                            validations={{ isRequired: true }}
                                            max={5}
                                            min={0}
                                        />
                                    </div>
                                    <div className="ladiui form-group ml-12 mt-0">
                                        <label className="ladiui-label">Thời gian tạo</label>
                                        <div style={{ width: "100%" }}>
                                            <DatePicker
                                                name='start'
                                                // minDate={baseHelper.momentToDate(moment())}
                                                selected={dataProductView.date_created}
                                                onChange={(value) => {
                                                    setDataProductView({
                                                        ...dataProductView,
                                                        date_created: value
                                                    })
                                                }}
                                                showTimeSelect
                                                timeFormat={appConfig.DEFAUT_TIME}
                                                timeIntervals={15}
                                                dateFormat={`${appConfig.DEFAULT_DATE_PICKER_FORMAT_DATE} ${appConfig.DEFAUT_TIME}`}
                                                timeCaption='Time'
                                                customPlaceholder='Chọn thời gian'
                                                customClass="w100"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="panel">
                                    <div className="panel-line">
                                        <div className="main-description">
                                            Hình ảnh ({dataProductView.images ? dataProductView.images.length : 0}/6)
                                            <br />
                                            <div className="sub-description">
                                                Kích thước 500x500(px)
                                            </div>
                                        </div>
                                        <div className="content">
                                            {dataProductView.images && dataProductView.images.length == 0 ? (
                                                <>
                                                    <div className="block-add-image-background" onClick={handleClickInputFileImageReview}>
                                                        <img src="https://w.ladicdn.com/ladiui/icons/ldicon-image.svg" className="mb-8" />
                                                        <p>Tải ảnh lên</p>
                                                    </div>
                                                    <div className="ladiui button upload text-center">
                                                        <input
                                                            id="upload-list-image-review"
                                                            className="cursor-pointer"
                                                            type="file"
                                                            name="file"
                                                            accept="image/*"
                                                            multiple="multiple"
                                                            onChange={fileChangedHandlerListImageReview}
                                                            onClick={(event) => {
                                                                event.target.value = null;
                                                            }}
                                                            style={{ display: "none" }}
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="block-list-banner-images">
                                                    {dataProductView.images.map((item, index) => {
                                                        return (
                                                            <div key={index} className="banner-image-item">
                                                                <div className="block-action-item">
                                                                    <div className="block-icon-delete" onClick={() => handleDeleteImagePreview(index)}>
                                                                        <i className="ldicon-delete" />
                                                                    </div>
                                                                </div>
                                                                <img src={`${CDN}${item}`}></img>
                                                            </div>
                                                        );
                                                    })}
                                                    {
                                                        dataProductView.images && dataProductView.images.length < 6
                                                        &&
                                                        <>
                                                            <div className="block-add-image-background" onClick={handleClickInputFileImageReview}>
                                                                <img src="https://w.ladicdn.com/ladiui/icons/ldicon-add.svg" className="mb-8" />
                                                                <p>Thêm mới</p>
                                                            </div>
                                                            <div className="ladiui button upload text-center">
                                                                <input
                                                                    id="upload-list-image-review"
                                                                    className="cursor-pointer"
                                                                    type="file"
                                                                    name="file"
                                                                    accept="image/*"
                                                                    multiple="multiple"
                                                                    onChange={fileChangedHandlerListImageReview}
                                                                    onClick={(event) => {
                                                                        event.target.value = null;
                                                                    }}
                                                                    style={{ display: "none" }}
                                                                />
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="ladiui form-group mt-0">
                                    <label className="ladiui-label">Nội dung đánh giá</label>
                                    <textarea
                                        className="ladiui textarea"
                                        validationName={"nội dung đánh giá"}
                                        ref={(ref) => inputsRef.add(ref)}
                                        validations={{ isRequired: true }}
                                        name="content"
                                        placeholder={"Nhập nội dung đánh giá"}
                                        value={dataProductView.content}
                                        onChange={(e) => onChangeDataProductView(e)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="ladiui modal-footer">
                            <div className="ladiui footer-modal-btn">
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    data-target="add-product"
                                    className="ladiui btn btn-secondary bold"
                                    onClick={eventCancel}
                                >
                                    {t("ACTIONS.CANCEL")}
                                </button>
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    data-target="add-product"
                                    className={`ladiui btn btn-primary bold ml-16 ${props.isLoading ? "loader" : ""}`}
                                    onClick={() => handleAddView()}
                                >
                                    {
                                        dataProductView.product_reviews_id ? t("ACTIONS.SAVE") : t("ACTIONS.ADD")
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withTranslation()(ModalAddProductView);
