import React from 'react';
import PropTypes from 'prop-types';
import { includes, map, remove, debounce, isEmpty } from 'lodash';
import validatorHelper from '../helpers/ValidatorHelper';
import i18n from '../i18n';
import baseHelper from '../helpers/BaseHelper';
import { compact } from 'lodash';

class SelectManyDropdown extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        onSelectItem: PropTypes.func,
        searchTag: PropTypes.func,
        currentKey: PropTypes.any,
        currentValue: PropTypes.any,
        _key: PropTypes.string,
        _value: PropTypes.string,
        validations: PropTypes.shape({
            isRequired: PropTypes.bool,
        }),
        placeHolder: PropTypes.string,
        renderItem: PropTypes.func,
        wrapperStyle: PropTypes.object,
        buttonStyle: PropTypes.object,
        wrapperClassName: PropTypes.string,
        inputClassName: PropTypes.string,
        isCourse: PropTypes.bool
    };

    static defaultProps = {
        _key: 'code',
        _value: 'name',
        validations: {
            isRequired: false,
        },
    };

    constructor(props) {
        super(props);

        this.state = {
            searchInput: '',
            disabled: props.disabled,
            selectedItems: props.selectedItems ? props.selectedItems : [],
            selectedNames: '',
            // visibleData: props.data,
            datas: props.data,
        };
        this.selectedItems = props.selectedItems ? props.selectedItems : [];

        this.ref = React.createRef();
        this.inputRef = React.createRef();

        this.reset = this.reset.bind(this);
        this.autoSearch = debounce(this.search, 500);
    }

    // eslint-disable-next-line camelcase
    async UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.data != nextProps.data) {
            await this.setState({
                datas: nextProps.data,
            });
        }

        const { datas } = this.state;
        let { selectedItems } = this;
        let selectedNames = '';
        for (let i = 0; i < datas.length; i++) if (includes(selectedItems, datas[i].value)) selectedNames += ` ${datas[i].name},`;
        selectedNames = selectedNames.substring(',', selectedNames.length - 1);

        if (!isEmpty(selectedNames))
            this.setState({
                selectedNames,
            });

        if (this.props.selectedItems != nextProps.selectedItems) {
            this.setState({
                selectedItems: nextProps.selectedItems || [],
            });
            this.selectedItems = nextProps.selectedItems || [];
        }
    }

    componentDidMount() {
        this.init(this.props);
    }

    init = (props) => {
        const { data } = props;
        const { selectedItems } = this;
        let selectedNames = '';


        for (let i = 0; i < data.length; i++) if (includes(selectedItems, data[i].value)) selectedNames += ` ${data[i].name},`;
        selectedNames = selectedNames.substring(',', selectedNames.length - 1);
        if (!isEmpty(selectedNames))
            this.setState({
                selectedNames,
            });
        this.setState({
            data,
            selectedItems: props.selectedItems ? props.selectedItems : [],
        });
        this.selectedItems = props.selectedItems ? props.selectedItems : [];
    };

    getValueByKey = (_key) => {
        let matchedItem = {};
        map(this.state.datas, (item) => {
            if (item[this.props._key] === _key) {
                matchedItem = item;
            }
        });
        return matchedItem[this.props._value];
    };

    onChangeSearchInput = (event) => {
        const { value } = event.target;

        let visibleData = map(this.props.data, (item) => {
            if (baseHelper.getAliasName(item[this.props._key]).indexOf(baseHelper.getAliasName(value)) > -1) {
                return item;
            }
        });

        visibleData = compact(visibleData);

        this.setState({
            searchInput: value,
            datas: visibleData,
        });
    };

    onChangeSearchInputCallApi = (event) => {
        const { value } = event.target;

        this.setState({
            searchInput: value,
        });
    };

    onKeyDownInputSearch = (event) => {
        if (event.key == "Enter") {
            this.props.handleSearchData(this.state.searchInput)
        }
    }

    search = () => {
        const { searchInput } = this.state;
        this.props.searchTag(searchInput);
    };

    onSelectItem = (selected) => {
        if ((!selected && !this.props.currentKey) || (selected && selected[this.props._key] === this.props.currentKey)) {
            return;
        }
        this.props.onSelectItem(selected);

        this.reset();
    };

    validate = () => {
        const errors = [];
        if (!this.ref.current) {
            return errors;
        }

        const { classList } = this.inputRef.current;
        classList.remove('error');

        let validateStatus = true;

        const { currentKey, validationName } = this.props;

        const { isRequired } = this.props.validations;
        if (isRequired) {
            if (validatorHelper.isEmpty(currentKey)) {
                validateStatus = false;
                errors.push(i18n.t('VALIDATION.ENTER', validationName));
            }
        }

        if (!validateStatus) {
            classList.add('error');
        }

        return errors;
    };

    renderItems = () => {
        const { _key, data } = this.props;
        const { selectedItems, datas } = this.state;

        const items = map(datas, (item, index) => (
            <li className='ladiui check-element' key={index}>
                <label className='ladiui search-checkbox default-text flex' data-id={index}>
                    <input
                        name='checkbox'
                        type='checkbox'
                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-0'
                        onChange={(event) => this.checkItem(event, item)}
                        checked={selectedItems.indexOf(item.value) >= 0}
                    />
                    {item.level && item.level > 1 && (
                        <i className='ldicon-subcategory mr-8' style={{ marginLeft: item.level * 16 + 'px' }} />
                    )}
                    <p style={{ fontWeight: item.level == 1 ? 500 : '', marginLeft: item.level == 1 ? '16px' : '8px', }}>
                        {item[_key] || item}
                    </p>
                </label>
            </li>
        ));

        return items;
    };

    reset() {
        this.setState({
            searchInput: '',
        });
    }

    checkAllItem = (event) => {
        const { checked } = event.target;
        const { datas } = this.state;
        this.selectedItems = [];
        if (checked) {
            this.selectedItems = map(datas, (tag) => tag.value);
        }

        let selectedNames = '';

        for (let i = 0; i < datas.length; i++) {
            if (includes(this.selectedItems, datas[i].value)) {
                selectedNames += `${datas[i].name},`;
            }
        }
        selectedNames = selectedNames.substring(',', selectedNames.length - 1);

        this.setState(
            {
                selectedItems: this.selectedItems,
                selectedNames,
            },
            () => {
                this.onSubmit();
            }
        );
    };
    checkItem = (event, item) => {
        const { checked } = event.target;
        let { selectedItems } = this;
        let { datas } = this.state;

        if (checked) {
            if (!includes(selectedItems, item.value)) {
                selectedItems.push(item.value);
            }
        } else {
            remove(selectedItems, (selectedID) => selectedID === item.value);
        }
        let selectedNames = '';

        for (let i = 0; i < datas.length; i++) {
            if (includes(selectedItems, datas[i].value)) {
                selectedNames += `${datas[i].name},`;
            }
        }

        selectedNames = selectedNames.substring(',', selectedNames.length - 1);
        this.setState(
            {
                selectedItems,
                selectedNames,
            },
            () => {
                this.onSubmit();
            }
        );

        // this.selectedItems = selectedItems;
    };

    openItem = () => {
        this.setState({
            openItem: true,
        });
    };

    closeItem = () => {
        this.setState({
            openItem: false,
        });
        this.props.onSubmit(this.state.selectedItems);
    };

    onSubmit = () => {
        // if (this.props.data.length > 0) {
        this.props.onSubmit(this.state.selectedItems);
        // }
    };

    render() {
        const {
            id,
            currentKey,
            currentValue,
            placeHolder,
            wrapperStyle,
            wrapperClassName,
            buttonStyle,
            inputClassName,
            innerClassName,
            disabled,
            pickTitle,
            styleCustomPeople,
            data,
            isLoading,
            isCreateCategory,
            isShowBlockInputSearch,
            isCourse,
            isManageReview,
            isShowBlockInputSearchCallApi
        } = this.props;
        const { searchInput, openItem, selectedNames, selectedItems, datas } = this.state;
        let displayData = '';
        if (isEmpty(selectedNames)) {
            displayData = currentValue || this.getValueByKey(currentKey) || placeHolder;
        } else {
            displayData = selectedNames;
        }
        return (
            <div className={`ladiui select-search dropdown ${innerClassName}`} id='dropdown-staff'>
                <button className='ladiui btn btn-outline-light dropdown-toggle relative' data-toggle='dropdown' style={buttonStyle}>
                    <i></i>
                    <span className='ladiui dropdown-text' data-text={displayData}>
                        {displayData}
                    </span>
                </button>
                <ul className={`ladiui dropdown-menu custom-people field`} style={styleCustomPeople}>
                    {isShowBlockInputSearch && (
                        !isShowBlockInputSearchCallApi
                            ?
                            <div className='ladiui form-group dropdown-input-search-custom mb-16'>
                                <i className='ladi-icon ldicon-search' />
                                <input
                                    type='text'
                                    className='ladiui form-control'
                                    name='searchInput'
                                    value={this.state.searchInput}
                                    placeholder='Tìm kiếm...'
                                    onChange={(e) => this.onChangeSearchInput(e)}
                                />
                            </div>
                            :
                            <div className='ladiui form-group dropdown-input-search-custom mb-16'>
                                <i className='ladi-icon ldicon-search' />
                                <input
                                    type='text'
                                    className='ladiui form-control'
                                    name='searchInput'
                                    value={this.state.searchInput}
                                    placeholder='Tìm kiếm...'
                                    onChange={(e) => this.onChangeSearchInputCallApi(e)}
                                    onKeyDown={(e) => this.onKeyDownInputSearch(e)}
                                />
                            </div>
                    )}
                    {
                        !isManageReview
                        &&
                        <li>
                            <a className={`ladiui border-none btn-add-option ml-16 pb-16`} target='_blank' href={`${isCourse ? "/course-category?type=Course&is_show_modal=true" : "/product-category?type=Product&is_show_modal=true"}`}>
                                <i className='ladi-icon icon-c-add mr-8' />
                                <div className='btn-add-product'>Tạo danh mục mới</div>
                            </a>
                        </li>
                    }
                    <li
                        onClick={() => {
                            this.props.handleRefreshData();
                        }}
                    >
                        <a className={`ladiui border-none btn-add-option ml-16 pb-16`}>
                            <i className='ladi-icon icon-sync mr-8' />
                            <div className='btn-add-product'>Làm mới danh sách</div>
                        </a>
                    </li>
                    <li className='ladiui check-element' key={-1}>
                        <label className='ladiui search-checkbox default-text flex' data-id={-1}>
                            <input
                                name='checkbox'
                                type='checkbox'
                                className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                onChange={(event) => this.checkAllItem(event)}
                                checked={selectedItems.length === datas.length}
                            />
                            {'Chọn tất cả'}
                        </label>
                    </li>
                    {this.renderItems()}
                </ul>
            </div>
        );
    }
}

export default SelectManyDropdown;
