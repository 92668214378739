import config from "../../../config/config";

export const SETTING_SHIPPING_CONNECT = 'SETTING_SHIPPING_CONNECT';
export const SETTING_SHIPPING_DISCONNECT = 'SETTING_SHIPPING_DISCONNECT';
export const SETTING_SHIPPING_INACTIVE = 'SETTING_SHIPPING_INACTIVE';
export const SETTING_SHIPPING_ACTIVE = 'SETTING_SHIPPING_ACTIVE';
export const SETTING_LIST_TEMPLATE_PRINT = 'SETTING_LIST_TEMPLATE_PRINT';
export const SETTING_LIST_TEMPLATE_EMAIL = 'SETTING_LIST_TEMPLATE_EMAIL';
export const SETTING_UPDATE_TEMPLATE_PRINT = 'SETTING_UPDATE_TEMPLATE_PRINT';
export const SETTING_UPDATE_TEMPLATE_EMAIL = 'SETTING_UPDATE_TEMPLATE_EMAIL';
export const SETTING_RESTORE_TEMPLATE_PRINT = 'SETTING_RESTORE_TEMPLATE_PRINT';
export const SETTING_RESTORE_TEMPLATE_EMAIL = 'SETTING_RESTORE_TEMPLATE_EMAIL';
export const SETTING_LIST_NOTIFICATION_EMAIL = 'SETTING_LIST_NOTIFICATION_EMAIL';
export const SETTING_CREATE_NOTIFICATION_EMAIL = 'SETTING_CREATE_NOTIFICATION_EMAIL';
export const SETTING_CHANGE_STATUS_NOTIFICATION_EMAIL = 'SETTING_CHANGE_STATUS_NOTIFICATION_EMAIL';
export const SETTING_SEND_NOTIFICATION_EMAIL = 'SETTING_SEND_NOTIFICATION_EMAIL';
export const SETTING_DELETE_NOTIFICATION_EMAIL = 'SETTING_DELETE_NOTIFICATION_EMAIL';
export const SETTING_UPDATE_NOTIFICATION_EMAIL_CONFIG = 'SETTING_UPDATE_NOTIFICATION_EMAIL_CONFIG';

export const SETTING_PAYMENT_CONNECT = 'SETTING_PAYMENT_CONNECT';
export const SETTING_PAYMENT_ACTIVE = 'SETTING_PAYMENT_ACTIVE';
export const SETTING_PAYMENT_INACTIVE = 'SETTING_PAYMENT_INACTIVE';
export const SETTING_PAYMENT_DISCONNECT = 'SETTING_PAYMENT_DISCONNECT';
export const SETTING_LIST_WEB_HOOK = 'SETTING_LIST_WEB_HOOK';
export const SETTING_WEB_HOOK_CREATE = 'SETTING_WEB_HOOK_CREATE';
export const SETTING_WEB_HOOK_UPDATE = 'SETTING_WEB_HOOK_UPDATE';
export const SETTING_WEB_HOOK_DELETE = 'SETTING_WEB_HOOK_DELETE';
export const SETTING_WEB_HOOK_TRANSACTION_LIST = 'SETTING_WEB_HOOK_TRANSACTION_LIST';
export const SETTING_PAYMENT_GATEWAY = 'SETTING_PAYMENT_GATEWAY';

export const SETTING_LIST_CUSTOM_FIELD = 'SETTING_LIST_CUSTOM_FIELD';
export const SETTING_ADD_CUSTOM_FIELD = 'SETTING_ADD_CUSTOM_FIELD';
export const SETTING_LIST_BY_CUSTOM_FIELD = 'SETTING_LIST_BY_CUSTOM_FIELD';
export const SETTING_DELETE_CUSTOM_FIELD = 'SETTING_DELETE_CUSTOM_FIELD';
export const SETTING_UPDATE_CUSTOM_FIELD = 'SETTING_UPDATE_CUSTOM_FIELD';

export const LIST_BANKS_VIET_QR = 'LIST_BANKS_VIET_QR';