import React, { Component } from "react";
import "./assets/css/reset.css";
import "./assets/css/fonts.css";
import "./assets/css/orther.css";
import "./assets/css/ladiui.css";

import Layout from "./layout/Layout";

import appConfig from "./config/app";
import queryString from "query-string";
import baseHelper from "./helpers/BaseHelper";
import { authEndpoint } from "./config/config";
import "moment/locale/vi";
// import 'moment/locale/en-au';

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

class App extends Component {
  constructor(props) {
    super(props);

    const parser = queryString.parse(this.props.location.search);
    if (parser.token) {
      baseHelper.setCookie(appConfig.COOKIE.SSID, parser.token);
      delete parser.token;
      const restParam = queryString.stringify(parser);
      props.history.push(`?${restParam}`);
    }
  }

  render() {
    let token = baseHelper.getCookie(appConfig.COOKIE.SSID);
    if (!token) {
      const redirectParser = queryString.parseUrl(authEndpoint.AUTH_SIGNIN);
      redirectParser.query.callback_url = window.location.href;

      const redirectUrl = queryString.stringifyUrl(redirectParser);

      window.location.href = redirectUrl;
      return;
    }
    return <Layout />;
  }
}

export default App;
