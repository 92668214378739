import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import baseHelper from "../../helpers/BaseHelper";
import { useTranslation } from 'react-i18next';
import LoadingTable from "../../components/LoadingTable";
import { useDispatch, useSelector } from "react-redux";

function ReportRevenue(props) {
  const { revenue_day, fromDate, toDate } = props;
  const [isLoading, setIsLoading] = useState(false);
  const moment = baseHelper.getMoment();
  const { t } = useTranslation();
  const storeReducer = useSelector((state) => state.store);

  const getOption = () => {
    if (!revenue_day || !revenue_day.revenueSuccess ||
      !revenue_day.revenuePending ||
      !fromDate ||
      !toDate
    ) return [];

    let categories = []
    let dataRevenueExpected = []
    let dataRevenueSuccess = []

    for (
      let datex = moment(fromDate).format("YYYY/MM/DD");
      datex <= moment(toDate).format("YYYY/MM/DD");
      datex = moment(datex, 'YYYY/MM/DD').add(1, "d").format("YYYY/MM/DD")
    ) {
      let xxxxx = moment(datex).format("DD/MM/YYYY");
      categories.push(xxxxx);

      // let datax = dataRevenueTotal[idDate];
      let valueSuccess = 0;
      let valuePending = 0;

      let itemSuccess = revenue_day.revenueSuccess.find(item => moment(item.date).format("DD/MM/YYYY") === xxxxx)
      if (itemSuccess && !itemSuccess.refund)
        itemSuccess.refund = 0

      if (itemSuccess) valueSuccess = itemSuccess.total - itemSuccess.refund;

      let itemPending = revenue_day.revenuePending.find(item => moment(item.date).format("DD/MM/YYYY") === xxxxx)
      if (itemPending && !itemPending.refund)
        itemPending.refund = 0

      if (itemPending) valuePending = itemPending.total - itemPending.refund;

      dataRevenueSuccess.push(valueSuccess)
      dataRevenueExpected.push(valuePending)

    }

    const options = {
      chart: {
        type: "column",
      },
      colors: ['#A6CEE3', '#1F78B4', '#A8D24C', '#F7B500'],
      title: {
        text: "",
      },
      xAxis: {
        categories: categories,
        gridLineWidth: 0.5,
        gridLineColor: "#f5f5f5"
      },
      yAxis: {
        gridLineColor: "#f5f5f5",
        // min: 0,
        title: {
          text: "",
        },
        stackLabels: {
          enabled: false,
        },
        gridLineWidth: 1
      },
      tooltip: {
        shared: true,
        backgroundColor: "#061438",
        borderColor: "#061438",
        borderRadius: 3,
        style: {
          color: "#ffffff",
        },
        useHTML: true,
        formatter: function () {
          let text = ``;
          let total = 0;
          this.points.map((item) => {
            total = item.total;
            text += `
            <br/>
            <div class="text-tooltip">  
              <div class="point-tolltip" style="background:${item.point.color}"></div> 
              <div>
                ${item.point.series.name}: ${baseHelper.formatMoneyPostFix(item.point.y, storeReducer.userInfo.currentStore.currency_symbol)}
              </div> 
            </div>
            `
          })
          return (
            `${this.x}
             <br/>
            ${text}
            <br/>
            <div>
            ${t("REPORTS.REVENUE_REPORT.TOTAL")}: ${baseHelper.formatMoneyPostFix(total, storeReducer.userInfo.currentStore.currency_symbol)}
          </div> `
          );
        },
      },
      legend: {
        enabled: true
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          }
        }
      },
      series: [
        {
          name: t("REPORTS.REVENUE_REPORT.SUCCESS"),
          data: dataRevenueSuccess,
        },
        {
          name: t("REPORTS.REVENUE_REPORT.PENDING"),
          data: dataRevenueExpected,
        },

      ],
    };
    return options;
  };

  const getRevenueSuccess = () => {
    if (!revenue_day || !revenue_day.revenueSuccess) return 0
    let total = 0;
    revenue_day.revenueSuccess.map((item) => {
      total += item.total
    })

    return baseHelper.formatMoneyPostFix(total, storeReducer.userInfo.currentStore.currency_symbol);
  }
  const getRevenuePending = () => {
    if (!revenue_day || !revenue_day.revenuePending || !revenue_day.revenueSuccess) return 0
    let total = 0;
    revenue_day.revenuePending.map((item) => {
      total += item.total
    })

    revenue_day.revenueSuccess.map((item) => {
      total += item.total
    })

    return baseHelper.formatMoneyPostFix(total, storeReducer.userInfo.currentStore.currency_symbol);
  }
  return (
    <div className="chart-item revenue-stage-chart">
      <div className="head-card">
        <i className="ladi-icon icon-orders"></i>
        <h3 className="mr-l-5">{t("REPORTS.REVENUE")}</h3>
      </div>
      <div className="content-chart">{!isLoading ? <HighchartsReact highcharts={Highcharts} options={getOption()} /> : <LoadingTable />}</div>
      <div className="bottom-cart">
        <div>
          <span> <b>{t("REPORTS.REVENUE_REPORT.TOTAL_SUCCESS")}:</b> {getRevenueSuccess()}</span>
        </div>
        <div>
          <span><b>{t("REPORTS.REVENUE_REPORT.TOTAL")}:</b> {getRevenuePending()}</span>
        </div>
      </div>
    </div>
  );
}

export default ReportRevenue;
