/** @format */

// import moment from "moment-timezone";
import moment from "moment";
import appConfig from "../config/app";
import { map, isNumber, isBoolean, isEmpty, includes, find, isEqual, reduce } from "lodash";
import countryData from "../countries/country";
import Cookies from "universal-cookie";
import i18next from "i18next";
import React from "react";
import produce from "immer";
import { useLocation } from "react-router-dom";

const prefixViettel = [
  "086",
  "096",
  "097",
  "098",
  "0162",
  "032",
  "0163",
  "033",
  "0164",
  "034",
  "0165",
  "035",
  "0166",
  "036",
  "0167",
  "037",
  "0168",
  "038",
  "0169",
  "039",
];
const prefixVinaphone = ["088", "091", "094", "0123", "083", "0124", "084", "0125", "085", "0127", "081", "0129", "082"];
const prefixMobiphone = ["089", "090", "093", "0120", "070", "0121", "079", "0122", "077", "0126", "076", "0128", "078"];
const prefixVietnamobile = ["092", "056", "058"];
const prefixGMobile = ["099", "0199", "059"];

class BaseHelper {
  useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  getFromDate(date) {
    if (!this.getMoment().isMoment(date)) {
      return date;
    }

    const _date = date.clone();
    _date.set({
      hour: 0,
      minute: 0,
      second: 0,
      milisecond: 0,
    });

    return _date;
  }

  getToDate(date) {
    if (!this.getMoment().isMoment(date)) {
      return date;
    }

    const _date = date.clone();
    _date.set({
      hour: 23,
      minute: 59,
      second: 59,
      milisecond: 999,
    });

    return _date;
  }

  getMoment() {
    if (!this.moment) {
      this.moment = moment;
    }

    return this.moment;
  }

  formatStrToDate(str, format = appConfig.DEFAULT_FORMAT_DATE_TIME) {
    try {
      const date = this.getMoment()(str || "");
      if (!date.isValid()) {
        return "";
      }

      return date.format(format);
    } catch (e) {
      return "";
    }
  }

  formatDateToStr(date, format = appConfig.DB_DATE_TIME_FORMAT) {
    try {
      return moment(date).format(format);
    } catch (e) {
      return moment().format(format);
    }
  }

  formatNumber(data, _default = 0) {
    if (!data) {
      return 0;
    }

    return data.toLocaleString("en-EN", { minimumFractionDigits: _default });
  }

  formatMoneyPostFix(value = 0, postFix = "đ") {
    if (this.isEmpty(value)) {
      value = 0;
    }
    if (isNaN(value)) {
      return "";
    }
    value = parseFloat(value);
    value = Math.round((value + Number.EPSILON) * 10) / 10;
    const formattedValue = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    let result = formattedValue;
    if (postFix) {
      result = `${result} ${postFix}`;
    }
    return result;
  }

  getText(data) {
    return data ? data : "";
  }

  hasAllEmptyValues(obj) {
    let result = true;

    map(obj, (value) => {
      if (value) {
        result = false;
        return;
      }
    });

    return result;
  }

  getCountryNameByCode = (code) => {
    const matchedCountry = find(countryData, (item) => item.code == code) || {};
    return matchedCountry.name;
  };

  resetForm(id) {
    window.LadiUI.forEach(window.LadiUI.querySelector(`#${id} .ladiui input`), function (e) {
      e.classList.remove("error");
    });
  }

  /**
   *
   * @param  {...any} values
   */
  isEmpty(...values) {
    let result = false;

    map(values, (value) => {
      if (!(isNumber(value) || isBoolean(value)) && isEmpty(value)) {
        result = true;
        return;
      }
    });

    return result;
  }

  getRandomInt(max = 10000) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  /**
   *
   * @param {*} value
   * @param {*} _default
   * @param {*} fix_length
   */
  parseFloat(value, _default = 0, fix_length = 4) {
    if (this.isEmpty(value) || isNaN(value)) {
      return parseFloat(parseFloat(_default).toFixed(fix_length));
    }

    return parseFloat(parseFloat(value).toFixed(fix_length));
  }

  /**
   *
   * @param {*} value
   * @param {*} _default
   * @description Don't understant why cannot use default variable as 2nd paramter
   */
  parseInt(value, _default = 0) {
    if (this.isEmpty(value) || isNaN(value)) {
      return _default;
    }

    return parseInt(value, 10);
  }

  parseInt(value, _default = 0) {
    if (this.isEmpty(value) || isNaN(value)) {
      return _default;
    }

    return parseInt(value, 10);
  }

  /**
   *
   * @param {*} value
   * @param {*} _default
   */
  parsePositiveInt(value, _default = 0) {
    let result = this.parseInt(value, _default);
    if (result < 0) {
      result = 0;
    }

    return result;
  }

  setCookie(key, value, day = appConfig.COOKIE.EXPIRE) {
    const expire = new Date();
    expire.setDate(new Date().getDate() + day);
    const cookies = new Cookies();
    var options = { path: "/", expires: expire };
    if (window.location.protocol == "https:") {
      options.sameSite = "none";
      options.secure = true;
    }
    cookies.set(key, value, options);
  }

  getCookie(key) {
    const cookies = new Cookies();

    return cookies.get(key);
  }

  removeCookie(key) {
    try {
      const cookies = new Cookies();
      var options = { path: "/" };
      if (window.location.protocol == "https:") {
        options.sameSite = "none";
        options.secure = true;
      }
      cookies.remove(key, options);
    } catch (err) {
      console.log(err);
    }
  }

  /**
   *
   * @param {*} price
   * @param {*} type
   * @param {*} value
   */
  calculateDiscountFee(price, type, value) {
    let fee = 0;
    if (type == appConfig.DISCOUNT.TYPE.PERCENT.value) {
      fee = this.parseFloat(price) * this.parseFloat(value / 100);
    } else if (type == appConfig.DISCOUNT.TYPE.FIXED.value) {
      fee = this.parseFloat(value);
    }

    if (fee < 0) {
      fee = 0;
    }

    if (fee > price) {
      fee = price;
    }

    return fee;
  }

  copyToClipboard(data) {
    const textField = document.createElement("textarea");
    textField.innerHTML = data;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  }

  getStaffNameById(staffs, ladiUID, meID) {
    if (!ladiUID) {
      return "";
    }

    const matched = find(staffs, (item) => item.ladi_uid == ladiUID);
    if (!matched) {
      return "";
    }

    if (meID && meID == matched.ladi_uid) {
      return i18next.t("COMMON.ME");
    }
    return matched.name;
  }

  compareShippingAndBilling(shippingAddress, billingAddress) {
    return isEqual(shippingAddress, billingAddress);
  }

  sanitizeUTF8(text) {
    const vnTexts = [
      "á",
      "à",
      "ả",
      "ã",
      "ạ",
      "â",
      "ấ",
      "ầ",
      "ẩ",
      "ẫ",
      "ậ",
      "ă",
      "ắ",
      "ằ",
      "ẳ",
      "ẵ",
      "ặ",
      "đ",
      "é",
      "è",
      "ẻ",
      "ẽ",
      "ẹ",
      "ê",
      "ế",
      "ề",
      "ể",
      "ễ",
      "ệ",
      "í",
      "ì",
      "ỉ",
      "ĩ",
      "ị",
      "ó",
      "ò",
      "ỏ",
      "õ",
      "ọ",
      "ô",
      "ố",
      "ồ",
      "ổ",
      "ỗ",
      "ộ",
      "ơ",
      "ớ",
      "ờ",
      "ở",
      "ỡ",
      "ợ",
      "ú",
      "ù",
      "ủ",
      "ũ",
      "ụ",
      "ư",
      "ứ",
      "ừ",
      "ử",
      "ữ",
      "ự",
      "ý",
      "ỳ",
      "ỷ",
      "ỹ",
      "ỵ",
      "Á",
      "À",
      "Ả",
      "Ã",
      "Ạ",
      "Â",
      "Ấ",
      "Ầ",
      "Ẩ",
      "Ẫ",
      "Ậ",
      "Ă",
      "Ắ",
      "Ằ",
      "Ẳ",
      "Ẵ",
      "Ặ",
      "Đ",
      "É",
      "È",
      "Ẻ",
      "Ẽ",
      "Ẹ",
      "Ê",
      "Ế",
      "Ề",
      "Ể",
      "Ễ",
      "Ệ",
      "Í",
      "Ì",
      "Ỉ",
      "Ĩ",
      "Ị",
      "Ó",
      "Ò",
      "Ỏ",
      "Õ",
      "Ọ",
      "Ô",
      "Ố",
      "Ồ",
      "Ổ",
      "Ỗ",
      "Ộ",
      "Ơ",
      "Ớ",
      "Ờ",
      "Ở",
      "Ỡ",
      "Ợ",
      "Ú",
      "Ù",
      "Ủ",
      "Ũ",
      "Ụ",
      "Ư",
      "Ứ",
      "Ừ",
      "Ử",
      "Ữ",
      "Ự",
      "Ý",
      "Ỳ",
      "Ỷ",
      "Ỹ",
      "Ỵ",
    ];
    const replaceText = [
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "d",
      "e",
      "e",
      "e",
      "e",
      "e",
      "e",
      "e",
      "e",
      "e",
      "e",
      "e",
      "i",
      "i",
      "i",
      "i",
      "i",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "u",
      "u",
      "u",
      "u",
      "u",
      "u",
      "u",
      "u",
      "u",
      "u",
      "u",
      "y",
      "y",
      "y",
      "y",
      "y",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "D",
      "E",
      "E",
      "E",
      "E",
      "E",
      "E",
      "E",
      "E",
      "E",
      "E",
      "E",
      "I",
      "I",
      "I",
      "I",
      "I",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "U",
      "U",
      "U",
      "U",
      "U",
      "U",
      "U",
      "U",
      "U",
      "U",
      "U",
      "Y",
      "Y",
      "Y",
      "Y",
      "Y",
    ];
    let index;
    for (let i = 0; i < vnTexts.length; i++) {
      index = text.indexOf(vnTexts[i]);
      if (index > -1) {
        text = text.replace(new RegExp(vnTexts[i], "g"), replaceText[i]);
      }
    }
    return text;
  }

  getAliasName(name) {
    if (!name) {
      return "";
    }

    name = this.sanitizeUTF8(name);
    name = name.replace(/ /g, "-");
    name = name.replace(/[^A-Za-z0-9-_\.]/g, "");
    name = name.replace(/\.+/g, "");
    name = name.replace(/-+/g, "-");
    name = name.replace(/_+/g, "_");
    name = name.toLowerCase();
    return name;
  }

  getTrackingHref = (shipping) => {
    if (!shipping.tracking_number) {
      return <span>N/A</span>;
    }

    const partnerCode = shipping.shipping_partner_code;
    let urlTracking;
    switch (partnerCode) {
      case appConfig.SHIPPING_PARTNERS.GHN.CODE:
        urlTracking = "https://tracking.ghn.dev/?order_code=";
        if (!this.isDevelopEnv()) {
          urlTracking = "https://donhang.ghn.vn/?order_code=";
        }
        return (
          <a target="_blank" href={`${urlTracking}${shipping.tracking_number}`}>
            {shipping.tracking_number}
          </a>
        );
      case appConfig.SHIPPING_PARTNERS.GHTK.CODE:
        return (
          <a target="_blank" href={`https://khachhang.giaohangtietkiem.vn/khach-hang/tracking/order/${shipping.tracking_number}`}>
            {shipping.tracking_number}
          </a>
        );
      case appConfig.SHIPPING_PARTNERS.VNPOST.CODE:
        return (
          <a target="_blank" href={`http://www.vnpost.vn/vi-vn/dinh-vi/buu-pham?key=${shipping.tracking_number}`}>
            {shipping.tracking_number}
          </a>
        );
    }

    return <span>{shipping.tracking_number}</span>;
  };

  generateBarCode = (html, option = {}) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    const barcodes = div.querySelectorAll("#barcode");
    if (!barcodes) {
      return div;
    }

    barcodes.forEach((barcode) => {
      const val = barcode.getAttribute("val");
      if (val) {
        window.JsBarcode(barcode, val, option);
      }
    });

    return div;
  };

  print = (
    html,
    option = {
      width: 1.6,
      height: 50,
      quite: 0,
      margin: 5,
      format: "CODE128",
      displayValue: true,
      font: "helvetica",
      fontSize: 15,
      textAlign: "center",
      lineColor: "#000",
    }
  ) => {
    const div = this.generateBarCode(html, option);
    var printwindow = window.frames["print_frame"];
    printwindow.document.body.innerHTML = "<style>@page{size:auto;margin:0}</style>";
    printwindow.document.body.innerHTML += div.innerHTML;
    setTimeout(function () {
      printwindow.document.close();
      printwindow.focus();
      printwindow.print();
      printwindow.close();
    }, 250);
  };

  isDevelopEnv = () => {
    return process.env.REACT_APP_ENVIRONMENT != "production";
  };

  getDisplayName = (name) => {
    if (!name) {
      return "";
    }
    const words = name.split(" ");
    let result = reduce(
      words,
      function (result, item) {
        return result + item.substring(0, 1).toUpperCase();
      },
      ""
    );

    result = result.substring(0, 2);

    return result;
  };

  checkImageUrl(url, timeout) {
    return new Promise(function (resolve, reject) {
      var _timeout = timeout || 500;
      var img = new Image();

      let timer;

      img.onerror = img.onabort = function () {
        clearTimeout(timer);
        reject("error");
      };

      img.onload = function () {
        clearTimeout(timer);
        resolve("success");
      };

      timer = setTimeout(function () {
        reject("timeout");
      }, _timeout);

      img.src = url;
    });
  }

  decodeEntities(encodedString) {
    var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
    var translate = {
      nbsp: " ",
      amp: "&",
      quot: '"',
      lt: "<",
      gt: ">",
    };
    return encodedString
      .replace(translate_re, function (match, entity) {
        return translate[entity];
      })
      .replace(/&#(\d+);/gi, function (match, numStr) {
        var num = parseInt(numStr, 10);
        return String.fromCharCode(num);
      });
  }

  encodeEntities(str) {
    return str.replace(/[\u00A0-\u9999<>\&]/gim, function (i) {
      return "&#" + i.charCodeAt(0) + ";";
    });
  }

  isJson(item) {
    item = typeof item !== "string" ? JSON.stringify(item) : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }

    return false;
  }

  checkScope(role, scopes, group, action) {
    if (role == appConfig.ROLE.OWNER.CODE) {
      return true;
    }
    const actions = scopes[group];
    if (actions == true) {
      return true;
    }

    if (Array.isArray(actions) && includes(actions, action)) {
      return true;
    }

    return false;
  }

  momentToDate(moment) {
    return new Date(
      moment.year(),
      moment.month(),
      moment.date(),
      moment.hours(),
      moment.minutes(),
      moment.seconds(),
      moment.milliseconds()
    );
  }

  // add 7 days
  momentToEventDate(moment) {
    return new Date(moment.year(), moment.month(), moment.date() + 7, 9, 0, 0, 0);
  }

  getFormDataUpload(files) {
    const form = new FormData();
    for (let i = 0; i < files.length; i++) {
      const item = files[i];
      if (item.size / 1024 / 1024 > appConfig.IMAGE_SIZE_ALLOW) {
        window.LadiUI.toastCustom("danger", "", i18next.t("VALIDATION.IMAGE_OVER_SIZE"));
        return false;
      }
      form.append("files", item, item.name);
    }
    form.append(
      "json_data",
      JSON.stringify({
        ladi_app: "LADISALES",
      })
    );
    return form;
  }

  // getFileType = (prefix) => {
  //   if (prefix) {
  //     if (appConfig.UPLOAD_FILE.IMAGE.includes(prefix)) {
  //       return appConfig.UPLOAD_FILE.TYPE.IMAGE;
  //     }
  //     if (appConfig.UPLOAD_FILE.VIDEO.includes(prefix)) {
  //       return appConfig.UPLOAD_FILE.TYPE.VIDEO;
  //     }
  //     if (appConfig.UPLOAD_FILE.FILE.includes(prefix)) {
  //       return appConfig.UPLOAD_FILE.TYPE.DOCUMENT;
  //     }
  //     if (appConfig.UPLOAD_FILE.AUDIO.includes(prefix)) {
  //       return appConfig.UPLOAD_FILE.TYPE.AUDIO;
  //     }
  //   }
  // };

  getFormDataUploadFile(files, maxSize = appConfig.FILE_SIZE_UPLOAD) {
    // let prefix = files[0].name.split(".")[files.length];

    const form = new FormData();
    for (let i = 0; i < files.length; i++) {
      const item = files[i];
      if (item.size / 1024 / 1024 > maxSize) {
        window.LadiUI.toastCustom("danger", "", i18next.t("VALIDATION.IMAGE_OVER_SIZE_CUSTOM", maxSize));
        return false;
      }
      form.append("files", item, item.name);
    }
    form.append(
      "json_data",
      JSON.stringify({
        ladi_app: "LADISALES",
        type: "UP_FILE",
        // file_type: this.getFileType(prefix),
      })
    );
    return form;
  }

  getFormDataUploadVideo(files, productID, courseModuleID, videoId) {
    // let prefix = files[0].name.split(".")[files.length];

    const form = new FormData();
    for (let i = 0; i < files.length; i++) {
      const item = files[i];
      // if (item.size / 1024 / 1024 > maxSize) {
      //   window.LadiUI.toastCustom("danger", "", i18next.t("VALIDATION.IMAGE_OVER_SIZE_CUSTOM", maxSize));
      //   return false;
      // }
      form.append("files", item, item.name);
    }
    form.append("product_id", productID);
    form.append("course_module_id", courseModuleID);
    form.append("product_video_id", videoId);

    return form;
  }

  getExtension(str) {
    return String(str).split(".").pop().toLowerCase();
  }

  getPhoneServiceProviderName(phone) {
    if (this.isEmpty(phone)) {
      return "";
    }

    // Viettel
    for (let i = 0; i < prefixViettel.length; i++) {
      if (String(phone).startsWith(prefixViettel[i])) {
        return "Viettel";
      }
    }

    for (let i = 0; i < prefixVinaphone.length; i++) {
      if (String(phone).startsWith(prefixVinaphone[i])) {
        return "Vinaphone";
      }
    }

    for (let i = 0; i < prefixMobiphone.length; i++) {
      if (String(phone).startsWith(prefixMobiphone[i])) {
        return "Mobiphone";
      }
    }

    for (let i = 0; i < prefixGMobile.length; i++) {
      if (String(phone).startsWith(prefixGMobile[i])) {
        return "GMobile";
      }
    }

    for (let i = 0; i < prefixVietnamobile.length; i++) {
      if (String(phone).startsWith(prefixVietnamobile[i])) {
        return "Vietnamobile";
      }
    }
  }

  sanitizeUTF8(text) {
    const vnTexts = [
      "á",
      "à",
      "ả",
      "ã",
      "ạ",
      "â",
      "ấ",
      "ầ",
      "ẩ",
      "ẫ",
      "ậ",
      "ă",
      "ắ",
      "ằ",
      "ẳ",
      "ẵ",
      "ặ",
      "đ",
      "é",
      "è",
      "ẻ",
      "ẽ",
      "ẹ",
      "ê",
      "ế",
      "ề",
      "ể",
      "ễ",
      "ệ",
      "í",
      "ì",
      "ỉ",
      "ĩ",
      "ị",
      "ó",
      "ò",
      "ỏ",
      "õ",
      "ọ",
      "ô",
      "ố",
      "ồ",
      "ổ",
      "ỗ",
      "ộ",
      "ơ",
      "ớ",
      "ờ",
      "ở",
      "ỡ",
      "ợ",
      "ú",
      "ù",
      "ủ",
      "ũ",
      "ụ",
      "ư",
      "ứ",
      "ừ",
      "ử",
      "ữ",
      "ự",
      "ý",
      "ỳ",
      "ỷ",
      "ỹ",
      "ỵ",
      "Á",
      "À",
      "Ả",
      "Ã",
      "Ạ",
      "Â",
      "Ấ",
      "Ầ",
      "Ẩ",
      "Ẫ",
      "Ậ",
      "Ă",
      "Ắ",
      "Ằ",
      "Ẳ",
      "Ẵ",
      "Ặ",
      "Đ",
      "É",
      "È",
      "Ẻ",
      "Ẽ",
      "Ẹ",
      "Ê",
      "Ế",
      "Ề",
      "Ể",
      "Ễ",
      "Ệ",
      "Í",
      "Ì",
      "Ỉ",
      "Ĩ",
      "Ị",
      "Ó",
      "Ò",
      "Ỏ",
      "Õ",
      "Ọ",
      "Ô",
      "Ố",
      "Ồ",
      "Ổ",
      "Ỗ",
      "Ộ",
      "Ơ",
      "Ớ",
      "Ờ",
      "Ở",
      "Ỡ",
      "Ợ",
      "Ú",
      "Ù",
      "Ủ",
      "Ũ",
      "Ụ",
      "Ư",
      "Ứ",
      "Ừ",
      "Ử",
      "Ữ",
      "Ự",
      "Ý",
      "Ỳ",
      "Ỷ",
      "Ỹ",
      "Ỵ",
    ];
    const replaceText = [
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "a",
      "d",
      "e",
      "e",
      "e",
      "e",
      "e",
      "e",
      "e",
      "e",
      "e",
      "e",
      "e",
      "i",
      "i",
      "i",
      "i",
      "i",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "o",
      "u",
      "u",
      "u",
      "u",
      "u",
      "u",
      "u",
      "u",
      "u",
      "u",
      "u",
      "y",
      "y",
      "y",
      "y",
      "y",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "A",
      "D",
      "E",
      "E",
      "E",
      "E",
      "E",
      "E",
      "E",
      "E",
      "E",
      "E",
      "E",
      "I",
      "I",
      "I",
      "I",
      "I",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "O",
      "U",
      "U",
      "U",
      "U",
      "U",
      "U",
      "U",
      "U",
      "U",
      "U",
      "U",
      "Y",
      "Y",
      "Y",
      "Y",
      "Y",
    ];
    let index;
    for (let i = 0; i < vnTexts.length; i++) {
      index = text.indexOf(vnTexts[i]);
      if (index > -1) {
        text = text.replace(new RegExp(vnTexts[i], "g"), replaceText[i]);
      }
    }
    return text;
  }

  domainToUrl(domain, domainVerified = appConfig.STATUS.ACTIVE) {
    if (!domain || domainVerified) {
      return `https://checkout.ladi.sale`;
    }
    return `https://${domain}`;
  }

  getDomainName(url) {
    try {
      let uri = new URL(url);

      let domain = uri.origin;
      return domain;
    } catch (err) {
      return "https://checkout.ladi.sale";
    }
  }

  getContentType(contentType) {
    let result;

    switch (contentType) {
      case appConfig.API_CONTENT_TYPE.APPLICATION_JSON.CODE:
        result = appConfig.API_CONTENT_TYPE.APPLICATION_JSON.NAME;
        break;
      case appConfig.API_CONTENT_TYPE.URL_ENCODED.CODE:
        result = appConfig.API_CONTENT_TYPE.URL_ENCODED.NAME;
        break;
      case appConfig.API_CONTENT_TYPE.MULTIPART_FORM_DATA.CODE:
        result = appConfig.API_CONTENT_TYPE.MULTIPART_FORM_DATA.NAME;
        break;
      default:
        result = "";
    }

    return result;
  }

  getUrlVariant(variant) {
    let result;

    switch (variant.product_type) {
      case appConfig.PRODUCT_TYPE.SERVICE.CODE:
        result = `/services/update/${variant.product_id}`;
        break;
      case appConfig.PRODUCT_TYPE.EVENT.CODE:
        result = `/events/update/${variant.product_id}`;
        break;
      case appConfig.PRODUCT_TYPE.COURSE.CODE:
        result = `/courses/update/${variant.product_id}`;
        break;
      default:
        result = `/products/update/${variant.product_id}`;
        break;
    }

    return result;
  }

  getUrlProduct(type, productID, search = "") {
    let result;

    switch (type) {
      case appConfig.PRODUCT_TYPE.SERVICE.CODE:
        result = `/services/update/${productID}${search}`;
        break;
      case appConfig.PRODUCT_TYPE.EVENT.CODE:
        result = `/events/update/${productID}${search}`;
        break;
      case appConfig.PRODUCT_TYPE.DIGITAL.CODE:
        result = `/digital/update/${productID}${search}`;
        break;
      case appConfig.PRODUCT_TYPE.COURSE.CODE:
        result = `/courses/update/${productID}${search}`;
        break;
      case appConfig.PRODUCT_TAG.TAG.CODE:
        result = `/settings#TAG`;
        break;
      default:
        result = `/products/update/${productID}${search}`;
        break;
    }

    return result;
  }

  /**
   *
   * @param {*} data  Mask by rate last character to *
   */
  maskText(data, rate = 0.8) {
    if (!data) {
      return data;
    }
    try {
      const { length } = data;
      const blurLength = Math.round(length * rate);
      let result = data.slice(0, length - blurLength);
      for (let i = 0; i < blurLength; i++) {
        result += "*";
      }
      return result;
    } catch (err) {
      console.error(data);
    }
  }

  isDateAfterToday(strDate) {
    return new Date(strDate) > new Date();
  }

  isDateBeforeToday(strDate) {
    return new Date() > new Date(strDate);
  }

  isNotOpenForSale(variant) {
    if (variant.product_type != appConfig.PRODUCT_TYPE.EVENT.CODE) {
      return false;
    }
    return !this.isEmpty(variant.start_date) && this.isDateAfterToday(variant.start_date);
  }

  isSoldOut(variant) {
    // eslint-disable-next-line default-case
    switch (variant.product_type) {
      case appConfig.PRODUCT_TYPE.EVENT.CODE:
        return variant.rest_quantity <= 0 || (!this.isEmpty(variant.end_date) && this.isDateBeforeToday(variant.end_date));
      case appConfig.PRODUCT_TYPE.SERVICE.CODE:
        return variant.inventory_checked && variant.rest_quantity <= 0;
      case appConfig.PRODUCT_TYPE.PHYSICAL.CODE:
        return variant.inventory_checked && variant.rest_quantity <= 0 && variant.allow_sold_out == appConfig.STATUS.INACTIVE;
    }
  }

  isAvailableVariant(variant) {
    return !this.isNotOpenForSale(variant) && !this.isSoldOut(variant);
  }

  getAccountTypeIcon(type) {
    let image = "https://w.ladicdn.com/ladiui/ladisales/logo-google-sheets.svg";
    switch (type) {
      case appConfig.FORM_TYPE.GOOGLE_SHEET.CODE:
        image = "https://w.ladicdn.com/ladiui/ladisales/logo-google-sheets.svg";
        break;
      case appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.CODE:
        image = "https://w.ladicdn.com/ladiui/ladisales/logo-active-campaign.svg";
        break;
      case appConfig.FORM_TYPE.CONVERTKIT.CODE:
        image = "https://w.ladicdn.com/ladiui/ladisales/logo-convert-kit.svg";
        break;
      case appConfig.FORM_TYPE.GET_RESPONSE.CODE:
        image = "https://w.ladicdn.com/ladiui/ladisales/logo-get-response.svg";
        break;
      case appConfig.FORM_TYPE.HUBSPOT.CODE:
        image = "https://w.ladicdn.com/ladiui/ladisales/logo-hubspot.svg";
        break;
      case appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE:
        image = "https://w.ladicdn.com/ladiui/ladisales/logo-infusion-soft.svg";
        break;
      case appConfig.FORM_TYPE.MAIL_CHIMP.CODE:
        image = "https://w.ladicdn.com/ladiui/ladisales/logo-mailchimp.svg";
        break;
      case appConfig.FORM_TYPE.HARAVAN.CODE:
        image = "https://w.ladicdn.com/ladiui/ladisales/logo-haravan.svg";
        break;
      case appConfig.FORM_TYPE.SAPO.CODE:
        image = "https://w.ladicdn.com/ladiui/ladisales/logo-sapo.svg";
        break;
      case appConfig.FORM_TYPE.SHOPIFY.CODE:
        image = "https://w.ladicdn.com/ladiui/ladisales/logo-shopify.svg";
        break;
      case appConfig.FORM_TYPE.WORDPRESS.CODE:
        image = "https://w.ladicdn.com/ladiui/ladisales/logo-wordpress.svg";
        break;
      case appConfig.FORM_TYPE.KIOTVIET.CODE:
        image = "https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-kiotviet.svg";
        break;
      case appConfig.FORM_TYPE.NHANH.CODE:
        image = "https://w.ladicdn.com/ladiui/ladisales/logo-nhanh.svg";
        break;
    }

    return image;
  }

  normalizeCopyProduct = async (productTg) => {
    let product = { ...productTg };
    delete product.product_id;

    product.name = `${product.name} (Copy)`;
    product.alias_name = `${product.alias_name}-copy`;

    const options = product.options || [];
    for await (let item of options) {
      delete item.option_id;
      const values = item.values || [];
      map(values, (_item) => delete _item.option_value_id);
    }
    // map(options, (item) => {
    //     delete item.option_id;
    //     const values = item.values || [];

    //     map(values, (_item) => delete _item.option_value_id);
    // });
    product.options = options;

    const variants = [...product.variants] || [];

    for await (let [index, item] of variants.entries()) {
      delete item.product_id;
      delete item.product_variant_id;

      item.total_sold = 0;
      item.quantity = 0;
      // if ([appConfig.PRODUCT_TYPE.PHYSICAL.CODE, appConfig.PRODUCT_TYPE.SERVICE.CODE].includes(item.product_type)) {
      //   item.inventory_checked = appConfig.INVENTORY_STATUS.INACTIVE.CODE;
      // } else {
      //   item.inventory_checked = appConfig.INVENTORY_STATUS.ACTIVE.CODE;
      // }

      if (item.sku) {
        item.sku = `${item.sku}-${index + 1}`;
      }

      const _options = item.options || [];

      const normalizeOptions = {};

      for await (let _item of _options) {
        if (_item.option_value_value) normalizeOptions[_item.option_name] = _item.option_value_value.name;
        else normalizeOptions[_item.option_name] = _item;
      }

      item.options = { ...normalizeOptions };
    }
    product.variants = [...variants];

    const defaultVariant = variants[0];

    if (defaultVariant) {
      product.sku = defaultVariant.sku;
      product.weight = defaultVariant.weight;
      product.weight_unit = defaultVariant.weight_unit;
      product.price = defaultVariant.price;
      product.price_compare = defaultVariant.price_compare;
      product.cost_per_item = defaultVariant.cost_per_item;
    }

    // if ([appConfig.PRODUCT_TYPE.PHYSICAL.CODE, appConfig.PRODUCT_TYPE.SERVICE.CODE].includes(product.type)) {
    //   product.inventory_checked = appConfig.INVENTORY_STATUS.INACTIVE.CODE;
    // } else {
    //   product.inventory_checked = appConfig.INVENTORY_STATUS.ACTIVE.CODE;
    // }
    product.quantity = 0;

    const images = product.images || [];
    for await (let item of images) {
      delete item.image_id;
      delete item.src_hash;
    }
    // map(images, (item) => {
    //     delete item.image_id;
    //     delete item.src_hash;
    // });
    product.images = images;

    product.tags = product.tags || [];
    product.product_up_sells = product.product_up_sells || [];

    return product;
  };

  normalizeCopyProductCourse = async (productTg) => {
    let product = { ...productTg };
    delete product.product_id;

    product.name = `${product.name} (Copy)`;
    product.alias_name = `${product.alias_name}-copy`;

    const options = product.options || [];
    for await (let item of options) {
      delete item.option_id;
      const values = item.values || [];
      map(values, (_item) => delete _item.option_value_id);
    }
    product.options = options;

    const variants = [...product.variants] || [];

    for await (let [index, item] of variants.entries()) {
      delete item.product_id;
      delete item.product_variant_id;
      delete item.options;
      delete item.option_ids;

      item.total_sold = 0;
      item.quantity = 0;
      if ([appConfig.PRODUCT_TYPE.PHYSICAL.CODE, appConfig.PRODUCT_TYPE.SERVICE.CODE].includes(item.product_type)) {
        item.inventory_checked = appConfig.INVENTORY_STATUS.INACTIVE.CODE;
      } else {
        item.inventory_checked = appConfig.INVENTORY_STATUS.ACTIVE.CODE;
      }

      if (item.sku) {
        item.sku = `${item.sku}-${index + 1}`;
      }
    }
    product.variants = [...variants];

    const defaultVariant = variants[0];

    if (defaultVariant) {
      product.sku = defaultVariant.sku;
      product.weight = defaultVariant.weight;
      product.weight_unit = defaultVariant.weight_unit;
      product.price = defaultVariant.price;
      product.price_compare = defaultVariant.price_compare;
      product.cost_per_item = defaultVariant.cost_per_item;
    }

    if ([appConfig.PRODUCT_TYPE.PHYSICAL.CODE, appConfig.PRODUCT_TYPE.SERVICE.CODE].includes(product.type)) {
      product.inventory_checked = appConfig.INVENTORY_STATUS.INACTIVE.CODE;
    } else {
      product.inventory_checked = appConfig.INVENTORY_STATUS.ACTIVE.CODE;
    }
    product.quantity = 0;

    const images = product.images || [];
    for await (let item of images) {
      delete item.image_id;
      delete item.src_hash;
    }
    product.images = images;

    product.tags = product.tags || [];
    product.product_category_ids = product.product_category_ids;

    return product;
  };

  showCustomerInfo = (item, index) => {
    let label = item.label;
    let value = item.value;

    switch (item.data_type) {
      case appConfig.CUSTOM_FIELD.DATA_TYPES.DROPDOWN.CODE:
        const dropdownValue = item.data_values ? item.data_values.split("\n") : "";
        const dataConFix = dropdownValue.map((item, index) => {
          item = {
            NAME: item.split("|")[0],
            CODE: item.split("|")[1],
          };

          return item;
        });
        const foundItem = find(dataConFix, (item) => item.CODE == value);
        if (foundItem) {
          value = foundItem.NAME;
        }

        break;
      case appConfig.CUSTOM_FIELD.DATA_TYPES.DATE.CODE:
        value = this.formatStrToDate(value, appConfig.DEFAULT_FORMAT_DATE);
        break;
    }
    return (
      <span key={index}>
        <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-mail.svg" /> {label}: {value}
      </span>
    );
  };

  numberToCurrencyStyle(x, style = ",", suffix = "") {
    return `${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, style)}${suffix}`;
  }

  getTypeProduct(type) {
    switch (type) {
      case appConfig.PRODUCT_TYPE.PHYSICAL.CODE:
        return appConfig.PRODUCT_TYPE.PHYSICAL.NAME;
      case appConfig.PRODUCT_TYPE.EVENT.CODE:
        return appConfig.PRODUCT_TYPE.EVENT.NAME;
      case appConfig.PRODUCT_TYPE.F_B.CODE:
        return appConfig.PRODUCT_TYPE.F_B.NAME;
      case appConfig.PRODUCT_TYPE.SERVICE.CODE:
        return appConfig.PRODUCT_TYPE.SERVICE.NAME;
      case appConfig.PRODUCT_TYPE:
        return appConfig.PRODUCT_TYPE.SERVICE.NAME;

      default:
        return appConfig.PRODUCT_TYPE.PHYSICAL.NAME;
        break;
    }
  }

  getLabelNameByType(type) {
    let labelName = "";
    switch (type) {
      case appConfig.PRODUCT_TYPE.SERVICE.CODE:
        labelName = "Dịch vụ";
        break;
      case appConfig.PRODUCT_TYPE.DIGITAL.CODE:
        labelName = "Sản phẩm số";
        break;
      case appConfig.PRODUCT_TYPE.EVENT.CODE:
        labelName = "Sự kiện";
        break;
      case appConfig.PRODUCT_TYPE.PHYSICAL.CODE:
        labelName = "Vật lý";
        break;
      case appConfig.PRODUCT_TYPE.COMBO.CODE:
        labelName = "COMBO";
        break;
      case appConfig.PRODUCT_TYPE.F_B.CODE:
        labelName = "F&B";
        break;
      default:
    }
    return labelName;
  }

  getPackageName(name) {
    let packageName = "";
    let isBusiness = name.includes("BUSINESS");
    if (isBusiness) {
      packageName = "BUSINESS";
    } else {
      packageName = name;
    }

    return packageName;
  }

  msToTime(duration) {
    // var milliseconds = Math.floor((duration % 1000) / 100),
    let seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  }

  msToTimeSeconds(duration) {
    // var milliseconds = Math.floor((duration % 1000) / 100),
    let seconds = Math.floor(duration % 60),
      minutes = Math.floor((duration / 60) % 60),
      hours = Math.floor((duration / (60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  }

  loadGoogleFont(fontFamily) {
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css?family=${fontFamily.replace(/ /g, '+')}&display=swap`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }
}

export default new BaseHelper();
