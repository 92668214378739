import React from "react";
import PropTypes from "prop-types";
import { map, forEach, cloneDeep, find, remove } from "lodash";
import { withTranslation } from "react-i18next";

import appConfig from "../../../../config/app";
import { appLocalStorage } from "../../../../localforage";
import baseHelper from "../../../../helpers/BaseHelper";

import Modal from "../../../../components/Modal";
import NumberInput from "../../../../components/NumberInput";
import RuleItem from "./components/RuleItem";

import assignActions from "../../../../redux/futures/assign/actions";
import * as assignTypes from "../../../../redux/futures/assign/types";

import { connect } from "react-redux";

class ModalSettingAssign extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    basicRule: PropTypes.object,
    advancedRules: PropTypes.array,
    storeConfig: PropTypes.object,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    updateStoreAssignStatus: PropTypes.func,
    isLoading: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.fakeAssignRuleID = -100;

    const advancedRules = this.getInitAdvancedRules(props);

    this.state = {
      staffs: [],
      restBasicWeight: 100,
      basicRule: cloneDeep(props.basicRule) || { config: { to: {} } },
      advancedRules,
      storeConfig: cloneDeep(props.storeConfig) || {},
      basicType: baseHelper.isEmpty(props.basicRule.config.to) ? appConfig.ASSIGN_BASIC_TYPE.DEFAULT : appConfig.ASSIGN_BASIC_TYPE.CUSTOM,
      assignType: appConfig.ASSIGN_TYPE.BASIC,
    };

    this.ref = React.createRef();
    this.advancedRulesRef = {};
    this.inputsRef = new Set();
  }

  componentDidMount() {
    appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
      const { basicRule } = this.state;
      let restBasicWeight = 100;
      const defaultWeight = 100 / result.staffs.length;

      if (baseHelper.isEmpty(basicRule.config.to)) {
        basicRule.config.to = {};
        forEach(result.staffs, (item) => {
          basicRule.config.to[item.ladi_uid] = defaultWeight;
        });
      }

      map(this.state.basicRule.config.to, (value, key) => {
        restBasicWeight -= baseHelper.parseFloat(value);
      });

      map(result.staffs, (item) => {
        if (item.ladi_uid == result.ladi_uid) {
          item.name = this.props.t("COMMON.ME");
        }
      });

      this.setState({
        staffs: result.staffs,
        basicRule,
        restBasicWeight: baseHelper.parseFloat(restBasicWeight),
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props === nextProps) {
    }

    if (this.props.basicRule != nextProps.basicRule) {
      this.setState({
        basicRule: cloneDeep(nextProps.basicRule) || { config: { to: {} } },
        basicType: baseHelper.isEmpty(nextProps.basicRule.config.to)
          ? appConfig.ASSIGN_BASIC_TYPE.DEFAULT
          : appConfig.ASSIGN_BASIC_TYPE.CUSTOM,
      });
    }

    if (this.props.advancedRules != nextProps.advancedRules) {
      const advancedRules = this.getInitAdvancedRules(nextProps);

      this.setState({
        advancedRules,
      });
    }

    if (this.props.storeConfig != nextProps.storeConfig) {
      this.setState({
        storeConfig: cloneDeep(nextProps.storeConfig) || {},
        assignType: nextProps.storeConfig.assign_type,
      });
    }
  }

  getInitAdvancedRules = (props) => {
    const defaultAdvancedRule = {
      assign_rule_id: this.fakeAssignRuleID--,
      config: {},
      using: appConfig.ASSIGN_STRATEGY.BASIC,
      priority: appConfig.OTHER_ASSIGN_PRIORITY,
    };

    const advancedRules = cloneDeep(props.advancedRules) || [defaultAdvancedRule];
    const hasOtherAssign = find(advancedRules, (item) => item.priority == appConfig.OTHER_ASSIGN_PRIORITY);

    if (!hasOtherAssign) {
      advancedRules.push(defaultAdvancedRule);
    }

    return advancedRules;
  };

  onChangeBasicType = (basicType) => {
    this.setState({
      basicType,
    });
  };

  removeRule = (index) => {
    const { advancedRules } = this.state;
    advancedRules.splice(index, 1);

    this.setState({
      advancedRules,
    });
  };

  onChangeBasicWeight = (event, key) => {
    let value = baseHelper.parseFloat(event.target.value);
    const { basicRule } = this.state;
    const { to } = basicRule.config;

    let currentWeight = 0;
    map(to, (value, _key) => {
      if (key != _key) {
        currentWeight += baseHelper.parseFloat(value);
      }
    });

    if (currentWeight + value > 100) {
      value = 100 - currentWeight;
    }

    to[key] = value;

    this.setState({
      basicRule,
      restBasicWeight: baseHelper.parseFloat(100 - currentWeight - value),
    });
  };

  getStaff = (ladiUID) => {
    let found = find(this.state.staffs, (item) => item.ladi_uid == ladiUID);

    if (!found) {
      found = {};
    }

    return found;
  };

  onChangeStoreStatus = () => {
    let { storeConfig } = this.state;

    if (storeConfig.assign_status === appConfig.STATUS.ACTIVE) {
      this.props.updateStoreAssignStatus(0);
    } else {
      this.props.updateStoreAssignStatus(1);
    }
    // this.setState(
    //   {
    //     storeConfig: {
    //       ...this.state.storeConfig,
    //       assign_status:
    //         this.state.storeConfig.assign_status == appConfig.STATUS.ACTIVE ? appConfig.STATUS.INACTIVE : appConfig.STATUS.ACTIVE,
    //     },
    //   },
    //   () => {
    //     this.props.updateStoreAssignStatus(this.state.storeConfig.assign_status);
    //   }
    // );
  };

  addNewRule = () => {
    const { advancedRules } = this.state;

    const newRule = {
      assign_rule_id: this.fakeAssignRuleID--,
      config: {
        rules: [
          {
            [appConfig.ASSIGN_RULE.PRODUCT.CODE]: [],
          },
        ],
        to: {},
        using: appConfig.ASSIGN_STRATEGY.BASIC,
      },
    };

    advancedRules.splice(advancedRules.length - 1, 0, newRule);

    this.setState({
      advancedRules,
    });
  };

  submit = () => {
    const { basicRule, basicType, assignType, storeConfig } = this.state;

    // if (storeConfig.assign_status == appConfig.STATUS.INACTIVE) {
    //   window.LadiUI.toastCustom(
    //     "danger",
    //     "",
    //     "Không thể thao tác với tài khoản STARTER, vui lòng nâng cấp tài khoản để thực hiện hành động !"
    //   );
    //   return false;
    // }

    let data = {};

    if (assignType == appConfig.ASSIGN_TYPE.BASIC) {
      data = {
        assign_rule_id: basicRule.assign_rule_id,
        assign_type: assignType,
      };
      if (basicType == appConfig.ASSIGN_BASIC_TYPE.DEFAULT) {
        data.config = {
          rules: [],
          to: {},
          using: appConfig.ASSIGN_STRATEGY.BASIC,
        };
      } else {
        data.config = basicRule.config;
      }
    } else {
      data = {
        assign_type: assignType,
        rules: [],
      };

      map(this.advancedRulesRef, (ref) => {
        if (ref) {
          const config = ref.getData();
          if (config) {
            const item = {
              name: "Advanced",
              config,
              type: appConfig.ASSIGN_TYPE.ADVANCED,
            };

            if (ref.props.priority == appConfig.OTHER_ASSIGN_PRIORITY) {
              item.priority = appConfig.OTHER_ASSIGN_PRIORITY;
            }

            data.rules.push(item);
          }
        }
      });
    }

    this.props.onSubmit(data, assignType);
  };

  hasOtherAssign = () => {
    const { advancedRules } = this.state;
    const found = find(advancedRules, (item) => item.priority == appConfig.OTHER_ASSIGN_PRIORITY);

    return found || false;
  };

  render() {
    const { t } = this.props;
    const { restBasicWeight, basicRule, advancedRules, storeConfig, basicType, assignType } = this.state;

    return (
      <div className="page-setting" id="page-assign" title={t("ASSIGN.TITLE")}>
        <div className="block-title-action">
          <label className="ladiui-label title-block" style={{ display: "flex" }}>
            <h3 className="title">Auto Assign</h3>
            <div className="ladiui item-form switch">
              <label className="ladiui switch" style={{ display: "block", margin: "0px 12px" }}>
                <input type="checkbox" checked={storeConfig.assign_status == appConfig.STATUS.ACTIVE} onChange={this.onChangeStoreStatus} />
                <span className="ladiui slider round"></span>
              </label>
            </div>
          </label>
          <div className="btn-action">
            <button
              type="button"
              className="ladiui btn btn-primary btn-sm "
              // disabled={storeConfig.assign_status == appConfig.STATUS.INACTIVE}
              onClick={this.submit}
            >
              {t("SETTINGS.SAVE_CHANGE")}
            </button>
          </div>
        </div>
        <div className="ladiui tab">
          <ul className="ladiui nav nav-tabs" id="myTab" role="tablist">
            <li className="ladiui nav-item" role="presentation">
              <span
                className="ladiui tab-link active"
                data-toggle="tab"
                data-parent="myTab"
                data-target="tab-basic"
                id="tab-basic"
                onClick={() => {
                  this.setState({
                    assignType: appConfig.ASSIGN_TYPE.BASIC,
                  });
                }}
              >
                {t("ASSIGN.TAB_BASIC")}
              </span>
            </li>
            <li className="ladiui nav-item" role="presentation">
              <span
                className="ladiui tab-link"
                data-toggle="tab"
                data-parent="myTab"
                data-target="tab-advanced"
                id="tab-advanced"
                onClick={() => {
                  this.setState({
                    assignType: appConfig.ASSIGN_TYPE.ADVANCED,
                  });
                }}
              >
                {t("ASSIGN.TAB_ADVANCED")}
              </span>
            </li>
          </ul>
        </div>

        <div className="ladiui-tab-content pt-24">
          <div className={`ladiui-tab-item ${assignType == appConfig.ASSIGN_TYPE.BASIC ? "active" : ""}`} id="tab-basic">
            <div style={{ display: "flex" }} className="ladiui form-group form-check-inputs mb-16">
              <input
                id="autoPercent"
                type="radio"
                name="radio-group-apply-to"
                className="ladiui form-check-input"
                onChange={() => {
                  this.onChangeBasicType(appConfig.ASSIGN_BASIC_TYPE.DEFAULT);
                }}
                checked={basicType == appConfig.ASSIGN_BASIC_TYPE.DEFAULT}
              />
              <label htmlFor="autoPercent">{t("ASSIGN.AUTO_PERCENT")}</label>
            </div>

            <div style={{ display: "flex" }} className="ladiui form-group form-check-inputs mb-12">
              <input
                id="customPercent"
                type="radio"
                name="radio-group-apply-to"
                className="ladiui form-check-input"
                onChange={() => {
                  this.onChangeBasicType(appConfig.ASSIGN_BASIC_TYPE.CUSTOM);
                }}
                checked={basicType == appConfig.ASSIGN_BASIC_TYPE.CUSTOM}
              />
              <label htmlFor="customPercent">{t("ASSIGN.CUSTOM_PERCENT")}</label>
            </div>

            {basicType == appConfig.ASSIGN_BASIC_TYPE.CUSTOM && (
              <div className="ladi-card">
                <table className="ladiui table text-left">
                  <thead>
                    <tr className="ladiui table-vertical header">
                      <th scope="col" className="text-left">
                        {t("COMMON.FULL_NAME")}
                      </th>
                      <th scope="col" className="text-left">
                        {t("COMMON.EMAIL")}
                      </th>
                      <th scope="col" style={{ width: 250 }} className="text-left">
                        {t("ASSIGN.WEIGHT", "%", "%")}
                        <span className="basic-rest-weight">{t("ASSIGN.REST_WEIGHT", `${restBasicWeight}%`)}</span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {map(basicRule.config.to, (value, key) => (
                      <tr key={key} className="ladiui table-vertical main">
                        <td className="text-left">{`${baseHelper.getText(this.getStaff(key)["name"])}`}</td>
                        <td className="text-left">{this.getStaff(key)["email"]}</td>
                        <td>
                          <NumberInput
                            value={value}
                            thousandSeparator={false}
                            onlyNumber={true}
                            onChange={(event) => this.onChangeBasicWeight(event, key)}
                            suffix="%"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <div className={`ladiui-tab-item ${assignType == appConfig.ASSIGN_TYPE.ADVANCED ? "active" : ""}`} id="tab-advanced">
            {map(advancedRules, (item, index) => {
              return (
                <RuleItem
                  key={item.assign_rule_id}
                  data={item}
                  rules={item.config.rules}
                  to={item.config.to}
                  ignore={item.config.ignore}
                  priority={item.priority}
                  indexRule={index}
                  removeRule={() => this.removeRule(index)}
                  ref={(ref) => (this.advancedRulesRef[item.assign_rule_id] = ref)}
                />
              );
            })}
            <div className="mt-24">
              {/* <a onClick={this.addNewRule} className="ladiui button secondary new-variant-option">{t('ASSIGN.ADD_RULE_GROUP')}</a> */}
              <button onClick={this.addNewRule} type="button" className="ladiui btn btn-outline-primary">
                {t("ASSIGN.ADD_RULE_GROUP")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listAssignRules: () => dispatch(assignActions.list()),
  };
};

const mapStateToProps = (state) => ({
  store: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation("translation", { withRef: true })(ModalSettingAssign));
