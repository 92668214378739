import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import appConfig from "../../../config/app";

import * as productTypes from "../../../redux/futures/product/types";
import * as productTagTypes from "../../../redux/futures/product_tag/types";
import productActions from "../../../redux/futures/product/actions";
import productTagActions from "../../../redux/futures/product_tag/actions";

import pageCheckoutActions from "../../../redux/futures/page_checkout/actions";

import * as pageCheckoutTypes from "../../../redux/futures/page_checkout/types";

import producer from "immer";

import { compact, map, find, debounce, findIndex } from "lodash";
import AsyncAutoComplete from "../../../components/AsyncAutoComplete";
import PanelProductCheckout from "../../../components/PanelProductCheckout";
import Modal from "../../../components/Modal";
import Image from "../../../components/Image";
import Input from "../../../components/Input";
import baseHelper from "../../../helpers/BaseHelper";
import SelectOption from "../../../components/SelectOption";
import ModalAddProduct from "../../orders/OrderCreate/components/ModalAddProduct";
import ModalAddTag from "../../tags/components/ModalAddTag";
import PanelProductSeoSocial from "../../../components/PanelProductSeoSocial";
import ValidatorHelper from "../../../helpers/ValidatorHelper";

import LoadingScene from "../../../components/LoadingScene";

export default function PageCheckout(props) {
  // const { isReloadIframe } = props;
  let { pageCheckoutID } = useParams();

  const [isReloadIframe, setReloadIframe] = useState(false);

  const pageCheckoutReducer = useSelector((state) => state.pageCheckoutReducer);

  const productReducer = useSelector((state) => state.product);

  const productTagReducer = useSelector((state) => state.productTag);
  const [interfaceOptions, setInterfaceOptions] = useState([]);
  const [isShowModalAddProduct, setIsShowModalAddProduct] = useState(false);
  const [isShowModalAddTag, setIsShowModalAddTag] = useState(false);

  const [currentPageCheckout, setCurrentPageCheckout] = useState({});

  const [pageCheckout, setPageCheckout] = useState({
    page_checkout_id: "",
    name: "",
    total_product: "",
    checkout_config_id: "",
    product_ids: [],
    products: [],
    payment_redirect_url: "",
    payment_redirect_after: appConfig.DEFAULT_PAYMENT_REDIRECT_AFTER,
    domain: "",
    path: "",
    url_published: "",
    tags: [],
    tag_ids: [],
    is_home: 0,
    types: appConfig.PAGE_CHECKOUT.PRODUCTS.CODE,
    seo: {},
  });
  const [tabIndexActive, setTabIndexActive] = useState(0);

  const [keyword, setKeyword] = useState("");

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputsRef = new Set();
  const panelProductCheckoutRef = React.createRef();
  const productSeoSocialRef = React.createRef();

  useEffect(() => {
    window.LadiUI.init();
    window.LadiUI.customInit();
  });

  useEffect(() => {
    if (pageCheckoutID) {
      dispatch(pageCheckoutActions.show(pageCheckoutID));
    }
  }, [pageCheckoutID]);

  useEffect(() => {
    // if (pageCheckoutReducer.action == pageCheckoutTypes.CREATE_PAGE_CHECKOUT) {
    //   setReloadIframe(!isReloadIframe);
    //   if (pageCheckoutReducer.status) {
    //     setIsShowModal(false);
    //     list();
    //     window.LadiUI.toastCustom("success", "", pageCheckoutReducer.message);
    //   } else {
    //     window.LadiUI.showErrorMessage("Thông báo", pageCheckoutReducer.message, "OK");
    //   }
    // }

    if (pageCheckoutReducer.action == pageCheckoutTypes.UPDATE_PAGE_CHECKOUT) {
      setReloadIframe(!isReloadIframe);
      if (pageCheckoutReducer.status) {
        // list();
        dispatch(pageCheckoutActions.reload(currentPageCheckout.page_checkout_id));
        window.LadiUI.toastCustom("success", "", pageCheckoutReducer.message);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", pageCheckoutReducer.message, "OK");
      }
    }

    if (pageCheckoutReducer.action == pageCheckoutTypes.SHOW_PAGE_CHECKOUT) {
      if (pageCheckoutReducer.status) {
        if (pageCheckoutReducer.page_checkout.interface_options && typeof pageCheckoutReducer.page_checkout.interface_options == "string")
          pageCheckoutReducer.page_checkout.interface_options = JSON.parse(pageCheckoutReducer.page_checkout.interface_options);
        else pageCheckoutReducer.page_checkout.interface_options = [];
        setCurrentPageCheckout(pageCheckoutReducer.page_checkout);
        // setMode(appConfig.FORM_MODE.EDIT);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", pageCheckoutReducer.message, "OK");
      }
    }
    if (pageCheckoutReducer.action == pageCheckoutTypes.RELOAD_PAGE_CHECKOUT) {
      if (pageCheckoutReducer.status) {
        if (pageCheckoutReducer.page_checkout.interface_options && typeof pageCheckoutReducer.page_checkout.interface_options == "string")
          pageCheckoutReducer.page_checkout.interface_options = JSON.parse(pageCheckoutReducer.page_checkout.interface_options);
        else pageCheckoutReducer.page_checkout.interface_options = [];

        setCurrentPageCheckout(pageCheckoutReducer.page_checkout);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", pageCheckoutReducer.message, "OK");
      }
    }
  }, [pageCheckoutReducer]);

  useEffect(() => {
    document.getElementById("trigger-tab-product").click();

    init();
  }, []);

  useEffect(() => {
    init();
    if (currentPageCheckout.interface_options) {
      let tg = currentPageCheckout.interface_options;

      if (typeof currentPageCheckout.interface_options === "string") tg = JSON.parse(currentPageCheckout.interface_options);
      setInterfaceOptions(tg);
    }
  }, [currentPageCheckout]);

  const init = () => {
    if (currentPageCheckout) {
      setPageCheckout(
        producer(pageCheckout, (draft) => {
          map(draft, (value, key) => {
            draft[key] = !baseHelper.isEmpty(currentPageCheckout[key]) && currentPageCheckout[key] ? currentPageCheckout[key] : "";
            if (key == "path" && baseHelper.isEmpty(currentPageCheckout[key]) && currentPageCheckout.page_checkout_id) {
              draft[key] = "" + currentPageCheckout.page_checkout_id;
            }
            if (key == "types" && baseHelper.isEmpty(currentPageCheckout[key])) {
              draft[key] = appConfig.PAGE_CHECKOUT.PRODUCTS.CODE;
            }

            if (key == "is_home" && (baseHelper.isEmpty(currentPageCheckout[key]) || currentPageCheckout[key] == 0)) {
              draft[key] = 0;
            }
            if (key == "seo" && baseHelper.isEmpty(currentPageCheckout[key])) {
              draft[key] = {
                title: "",
                description: "",
                keywords: "",
                image: "",
                canonical_url: "",
              };
            }
          });
        })
      );
    }
  };

  useEffect(() => {
    if (pageCheckout.types == "product") {
      searchProduct();
    } else {
      searchProductTag();
    }
  }, [keyword]);

  useEffect(() => {
    if (pageCheckout.types == "product") {
      searchProduct();
    } else {
      searchProductTag();
    }
  }, [pageCheckout.types]);

  const onChangeInput = (event) => {
    setPageCheckout(
      producer(pageCheckout, (draft) => {
        draft[event.target.name] = event.target.value;
      })
    );
  };

  const searchProduct = debounce(() => {
    dispatch(productActions.search(keyword));
  }, 500);

  const searchProductTag = debounce(() => {
    dispatch(productTagActions.search(keyword));
  }, 500);

  const submit = () => {
    let errors = [];
    const _inputsRef = compact(Array.from(inputsRef));
    map(_inputsRef, (ref) => {
      if (ref.validate) {
        const error = ref.validate();
        if (error.length > 0) {
          errors = errors.concat(error);
        }
      }
    });

    errors = compact(errors);

    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      window.LadiUI.toastCustom("danger", "", errorMessage);

      return;
    }

    let productSeoSocial = productSeoSocialRef.current.getData();

    if (productSeoSocial) {
      if (productSeoSocial.title && productSeoSocial.title.length > 70) {
        window.LadiUI.toastCustom("warning", "", "Tiêu đề vượt quá số ký tự quy định!");
        return;
      }
      if (productSeoSocial.description && productSeoSocial.description.length > 170) {
        window.LadiUI.toastCustom("warning", "", "Mô tả vượt quá số ký tự quy định!");
        return;
      }
      if (productSeoSocial.keywords && productSeoSocial.keywords.length > 170) {
        window.LadiUI.toastCustom("warning", "", "Từ khóa vượt quá số ký tự quy định!");
        return;
      }
    }

    if (pageCheckout.types == appConfig.PAGE_CHECKOUT.PRODUCTS.CODE) {
      if (pageCheckout.product_ids.length <= 0) {
        window.LadiUI.toastCustom("danger", "", t("PAGE_CHECKOUT.REQUIRED_PRODUCT"));
        return;
      }
    }

    if (pageCheckout.types == appConfig.PAGE_CHECKOUT.TAGS.CODE) {
      if (pageCheckout.tag_ids.length <= 0) {
        window.LadiUI.toastCustom("danger", "", t("PAGE_CHECKOUT.REQUIRED_PRODUCT_TAG"));
        return;
      }
    }

    const payloadCheckout = {
      ...pageCheckout,
      seo: { ...productSeoSocial },
    };

    let dataSend = {
      page_checkout: { ...payloadCheckout, interface_options: JSON.stringify(interfaceOptions) },
    };

    dispatch(pageCheckoutActions.update(dataSend));
  };

  const onSelectProduct = (item) => {
    if (find(pageCheckout.products, (_item) => _item.product_id == item.product_id)) {
      return;
    }

    setPageCheckout(
      producer(pageCheckout, (draft) => {
        if (!draft.product_ids) {
          draft.product_ids = [];
        }
        if (!draft.products) {
          draft.products = [];
        }
        draft.product_ids.push(item.product_id);
        draft.products.push(item);
      })
    );
  };

  const onSelectProductTag = (item) => {
    if (find(pageCheckout.tags, (_item) => _item.product_tag_id == item.product_tag_id)) {
      return;
    }

    setPageCheckout(
      producer(pageCheckout, (draft) => {
        if (!draft.tag_ids) {
          draft.tag_ids = [];
        }
        if (!draft.tags) {
          draft.tags = [];
        }
        draft.tag_ids.push(item.product_tag_id);
        draft.tags.push(item);
      })
    );
  };

  const onRemoveProduct = (product) => {
    setPageCheckout(
      producer(pageCheckout, (draft) => {
        const indexProduct = findIndex(draft.products, (item) => item.product_id == product.product_id);
        const indexProductID = findIndex(draft.product_ids, (item) => item == product.product_id);
        draft.products.splice(indexProduct, 1);
        draft.product_ids.splice(indexProductID, 1);
      })
    );
  };

  const onRemoveProductTag = (tag) => {
    setPageCheckout(
      producer(pageCheckout, (draft) => {
        const indexProductTag = findIndex(draft.tags, (item) => item.product_tag_id == tag.product_tag_id);
        const indexProductTagID = findIndex(draft.tag_ids, (item) => item == tag.product_tag_id);

        draft.tags.splice(indexProductTag, 1);
        draft.tag_ids.splice(indexProductTagID, 1);
      })
    );
  };

  const loadingSearchProduct = productReducer.loading && productReducer.waiting == productTypes.SEARCH_PRODUCT;
  const loadingSearchProductTag = productTagReducer.loading && productTagReducer.waiting == productTagTypes.SEARCH_PRODUCT_TAG;
  const loadingSubmit =
    pageCheckoutReducer.loading &&
    [pageCheckoutTypes.CREATE_PAGE_CHECKOUT, pageCheckoutTypes.UPDATE_PAGE_CHECKOUT].includes(pageCheckoutReducer.waiting);
  const foundProducts = productReducer.products || [];
  const foundProductTags = productTagReducer.productTags || [];

  return (
    <div id="modal-page-checkout" className="page-content">
      {
        loadingSubmit && <LoadingScene blur={true} />
      }
      <div className="ladiui content-main-title flex-row mb-20">
        <div className="ladiui flex-row">
          <div className="ladiui main-title">{t("PAGE_CHECKOUT.UPDATE")}</div>
        </div>
        <div className="flex">
          <button onClick={(e) => props.history.push("/page-checkout")} className="ladiui btn btn-secondary mr-24 ">
            {t("ACTIONS.REMOVE")}
          </button>

          <button
            type="button"
            // className={`ladiui btn btn-primary btn-lg btn-open-modal ${loadingSubmit ? "loader" : ""}`}
            className={`ladiui btn btn-primary btn-lg btn-open-modal`}
            onClick={submit}
          >
            <div>Lưu</div>
          </button>
        </div>
      </div>
      <ul className="ladiui nav nav-tabs" id="myTab" role="tablist" style={{ marginBottom: "24px" }}>
        <li className="ladiui nav-item" role="presentation">
          <span className="ladiui tab-link active" id="trigger-tab-product" data-toggle="tab" data-parent="myTab" data-target="tab-product">
            {t("PAGE_CHECKOUT.TAB_PRODUCT")}
          </span>
        </li>
        <li className="ladiui nav-item" role="presentation">
          <span
            className="ladiui tab-link active"
            id="trigger-tab-config"
            data-toggle="tab"
            data-parent="myTab"
            data-target="tab-checkout-config"
          >
            {t("PAGE_CHECKOUT.TAB_CHECKOUT_CONFIG")}
          </span>
        </li>
        <li className="ladiui nav-item" role="presentation">
          <span
            className="ladiui tab-link active"
            id="trigger-tab-seo-social"
            data-toggle="tab"
            data-parent="myTab"
            data-target="tab-seo-social"
          >
            Seo & Social
          </span>
        </li>
      </ul>

      <div className="ladiui tab-content" id="myTabContent">
        <div className="ladiui tab-pane fade show open" id="tab-product" role="tabpanel" aria-labelledby="trigger-tab-product">
          <div className="ladiui form-group">
            <label className="ladiui-label">{t("PAGE_CHECKOUT.NAME")}</label>
            <Input
              name="name"
              validationName={t("PAGE_CHECKOUT.NAME")}
              placeholder={t("PAGE_CHECKOUT.PLACE_HOLDER_NAME")}
              value={pageCheckout.name}
              onChange={onChangeInput}
              ref={(ref) => inputsRef.add(ref)}
              validations={{ isRequired: true }}
            />
          </div>
          <div className="ladiui-select-page-checkout">
            <label className="ladiui-label">{t("PAGE_CHECKOUT.CHOOSE_TYPE")}</label>
            <SelectOption
              currentKey={pageCheckout.types}
              items={[appConfig.PAGE_CHECKOUT.PRODUCTS, appConfig.PAGE_CHECKOUT.TAGS]}
              innerStyle={{ paddingRight: "30px" }}
              _key={"CODE"}
              _value={"NAME"}
              onSelectItem={(item) => {
                setPageCheckout((prev) => {
                  if (item.CODE == "product") {
                    return {
                      ...prev,
                      tags: [],
                      tag_ids: [],
                      types: item.CODE,
                    };
                  } else {
                    return {
                      ...prev,
                      products: [],
                      product_ids: [],
                      types: item.CODE,
                    };
                  }
                });
              }}
            />
          </div>
          <div className="cursor-pointer">
            {pageCheckout.types == "product" ? (
              <a className="flex cursor-pointer my-24 pd-0" onClick={() => setIsShowModalAddProduct(true)}>
                <img className="mr-8" src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg"} alt="" />
                <div> {t("PRODUCTS.ADD_NEW")}</div>
              </a>
            ) : (
              <a className="flex cursor-pointer my-24 pd-0" onClick={() => setIsShowModalAddTag(true)}>
                <img className="mr-8" src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg"} alt="" />
                <div>Thêm tag mới</div>
              </a>
            )}
          </div>
          <div className="block-content-product-tag" style={{ marginTop: 15, minWidth: "auto" }}>
            <ul className="list" style={{ maxWidth: "600px" }}>
              {pageCheckout.types == "product"
                ? map(pageCheckout.products, (item, index) => (
                  <li key={index} className="list-item" style={{ borderBottom: "1px solid rgb(239, 239, 239)" }}>
                    <div className="img-name flex ">
                      <div className="image">
                        {" "}
                        <Image src={item.src} width={40} height={40} />
                        {item.status == appConfig.PRODUCT_STATUS.INACTIVE.CODE && (
                          <span className="badge badge-child-light sold-out flex">
                            <i className="ladi-icon icon-c-warning mr-4"></i>
                            {t("PRODUCT_STATUS.INACTIVE")}
                          </span>
                        )}
                      </div>
                      <div className="name ml-24">
                        <a href={baseHelper.getUrlProduct(item.type, item.product_id)} target="_blank">
                          {item.name}
                        </a>
                      </div>
                    </div>
                    <div className="text-right">
                      <a style={{ color: "rgb(109, 109, 109)" }} onClick={() => onRemoveProduct(item)}>
                        <i className="ladi-icon icon-bin" />
                      </a>
                    </div>
                  </li>
                ))
                : map(pageCheckout.tags, (item, index) => {
                  return (
                    <li
                      key={index}
                      // className={
                      //   item.status == appConfig.PRODUCT_STATUS.INACTIVE.CODE ? "sold-out ladiui table-vertical" : "ladiui table-vertical"
                      // }
                      className="list-item"
                      style={{ borderBottom: "1px solid rgb(239, 239, 239)" }}
                    >
                      <div className="img-name flex">
                        <div className="image">
                          {" "}
                          <Image src={item.image_url} width={40} height={40} />
                          {item.status == appConfig.PRODUCT_STATUS.INACTIVE.CODE && (
                            <span className="badge badge-child-light sold-out flex">
                              <i className="ladi-icon icon-c-warning mr-4"></i>
                              {t("PRODUCT_STATUS.INACTIVE")}
                            </span>
                          )}
                        </div>
                        <div className="name ml-24">
                          <a href={baseHelper.getUrlProduct("Tag")} target="_blank">
                            {item.name}
                          </a>
                        </div>
                      </div>
                      <div className="text-right">
                        <a style={{ color: "rgb(109, 109, 109)" }} onClick={() => onRemoveProductTag(item)}>
                          <i className="ladi-icon icon-bin" />
                        </a>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <div className="ladiui tab-pane fade" id="tab-checkout-config" role="tabpanel" aria-labelledby="trigger-tab-config">
          <PanelProductCheckout
            isReloadIframe={isReloadIframe}
            ref={panelProductCheckoutRef}
            checkoutConfigID={pageCheckout.checkout_config_id}
            payment_redirect_url={pageCheckout.payment_redirect_url}
            payment_redirect_after={pageCheckout.payment_redirect_after || 0}
            domain={pageCheckout.domain}
            path={pageCheckout.path}
            urlPublished={pageCheckout.url_published}
            checkoutLocation={interfaceOptions}
            is_home={pageCheckout.is_home}
            setCheckoutLocation={(data) => {
              setInterfaceOptions(data);
              // setPageCheckout(
              //     producer(pageCheckout, (draft) => {
              //         draft.interface_options = data;
              //     })
              // );
            }}
            onChangeCheckoutConfig={(checkoutConfigID) => {
              setPageCheckout(
                producer(pageCheckout, (draft) => {
                  draft.checkout_config_id = checkoutConfigID;
                })
              );
            }}
            onChangePaymentRedirectPath={(paymentRedirectUrl) => {
              setPageCheckout(
                producer(pageCheckout, (draft) => {
                  draft.payment_redirect_url = paymentRedirectUrl;
                })
              );
            }}
            onChangePaymentRedirectAfter={(paymentRedirectAfter) => {
              setPageCheckout(
                producer(pageCheckout, (draft) => {
                  draft.payment_redirect_after = baseHelper.parsePositiveInt(paymentRedirectAfter);
                })
              );
            }}
            onChangePath={(path) => {
              setPageCheckout(
                producer(pageCheckout, (draft) => {
                  draft.path = path;
                })
              );
            }}
            onChangeDomain={(domain) => {
              setPageCheckout(
                producer(pageCheckout, (draft) => {
                  draft.domain = domain;
                })
              );
            }}
            onChangeIsHome={(event) => {
              let { checked } = event.target;
              if (checked) {
                setPageCheckout(
                  producer(pageCheckout, (draft) => {
                    draft.is_home = 1;
                  })
                );
              } else {
                setPageCheckout(
                  producer(pageCheckout, (draft) => {
                    draft.is_home = 0;
                  })
                );
              }
            }}
            isPageCheckout={true}
          />
        </div>
        <div className="ladiui tab-pane fade" id="tab-seo-social" role="tabpanel" aria-labelledby="trigger-tab-seo-social">
          <PanelProductSeoSocial ref={productSeoSocialRef} productInfoSeo={pageCheckout.seo || {}} />
        </div>
        {isShowModalAddProduct && (
          <ModalAddProduct
            onClose={() => setIsShowModalAddProduct(false)}
            isShow={isShowModalAddProduct}
            listVariant={foundProducts}
            isSelectedTypeProduct={true}
            selectedProducts={pageCheckout.products || []}
            innerStyle={{ display: "none" }}
            selectVariant={onSelectProduct}
            onChangeSearchVariantInput={(event) => setKeyword(event.target.value)}
          />
        )}
        {isShowModalAddTag && (
          <ModalAddTag
            onClose={() => setIsShowModalAddTag(false)}
            isShow={isShowModalAddTag}
            listVariant={foundProductTags}
            selectedTags={pageCheckout.tags || []}
            innerStyle={{ display: "none" }}
            selectVariant={onSelectProductTag}
            onChangeSearchVariantInput={(event) => setKeyword(event.target.value)}
          />
        )}
      </div>
    </div>
  );
}
