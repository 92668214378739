/** @format */

import React from "react";
import PropTypes from "prop-types";
import { cloneDeep, filter, map, compact } from "lodash";
import { connect } from "react-redux";
import Input from "../../../components/Input";
import appConfig from "../../../config/app";
import { withTranslation } from "react-i18next";
import Image from "../../../components/Image";
import Modal from "../../../components/Modal";
import NumberInput from "../../../components/NumberInput";
import { appLocalStorage } from "../../../localforage";
import baseHelper from "../../../helpers/BaseHelper";

import LoadingScene from "../../../components/LoadingScene";

import fileActions from "../../../redux/futures/file/actions";
import * as fileTypes from "../../../redux/futures/file/types";

import ValidatorHelper from "../../../helpers/ValidatorHelper";

class ModalPackage extends React.Component {
  static propTypes = {
    currentStore: PropTypes.object,
    currentProduct: PropTypes.object,
    mode: PropTypes.string,
    currentVariantInfo: PropTypes.object,
    images: PropTypes.array,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    visible: PropTypes.bool,
    isLoading: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.moment = baseHelper.getMoment();

    this.state = {
      variant: {
        option_value_value: "",
        src: "",
        // src_hash: '',
        sku: "",
        price: 0,
        quantity: 1,
        price: "",
        package_price: "",
        total_sold: 0,
        timezone: "Asia/Bangkok",
        total_quantity: props.currentProduct.ticket_creation_form == appConfig.TICKET_CREATION_FORM.IMPORT.CODE ? 0 : 1,
        description: "",
        inventory_checked:
          props.currentProduct.ticket_creation_form == appConfig.TICKET_CREATION_FORM.IMPORT.CODE
            ? appConfig.STATUS.ACTIVE
            : appConfig.STATUS.INACTIVE,
        position: 0,
        file: "",
        fileUrl: "",
        download_count: 1,
      },
      isShowAdvanced: false,
      userInfo: {
        currentStore: {},
      },
      fileType: 0,
    };

    this.ref = React.createRef();
    this.inputsRef = new Set();
  }

  componentWillMount() {
    appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
      this.setState({
        userInfo: result,
      });
    });
  }

  componentDidMount() {
    window.LadiUI.init();
    window.LadiUI.customInit();

    if (this.props.currentVariantInfo) {
      this.init(this.props);
    }
  }

  componentDidUpdate() {
    window.LadiUI.init();
    window.LadiUI.customInit();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props === nextProps) {
      return;
    }

    if (this.props.currentVariantInfo !== nextProps.currentVariantInfo) {
      this.init(nextProps);
    }

    if (this.props.fileReducer.action != nextProps.fileReducer.action) {
      if (nextProps.fileReducer.action == fileTypes.UPLOAD_VARIANT_IMAGE) {
        if (nextProps.fileReducer.status) {
          this.setState({
            variant: {
              ...this.state.variant,
              src: nextProps.fileReducer.ticket_images[0],
            },
          });
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.fileReducer.message, "OK");
          // window.LadiUI.toastCustom("danger", "", nextProps.fileReducer.message);
        }
      }

      if (nextProps.fileReducer.action == fileTypes.UPLOAD_FILE_DIGITAL) {
        if (nextProps.fileReducer.status) {
          this.setState({
            variant: {
              ...this.state.variant,
              file: nextProps.fileReducer.file_url,
            },
          });
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.fileReducer.message, "OK");
          // window.LadiUI.toastCustom("danger", "", nextProps.fileReducer.message);
        }
      }
    }
  }

  init = (props) => {
    if (props.mode == appConfig.FORM_MODE.EDIT) {
      const variant = cloneDeep(props.currentVariantInfo) || {};

      if (variant.file) {
        if (variant.file.includes("api.files.ladicdn.com")) {
          this.setState({
            fileType: 0,
          });
        } else {
          this.setState({
            fileType: 1,
          });
        }
      }

      this.setState({
        variant,
      });
    }
  };

  fileChangedHandler = (event) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const form = baseHelper.getFormDataUpload(files);
      if (form) {
        this.props.uploadTicketImage(form);
      }
    }
  };

  onChangeInput = (event) => {
    let { name, value } = event.target;
    this.setState(
      {
        variant: {
          ...this.state.variant,
          [name]: value,
        },
      },
      () => {
        if (["price", "package_quantity"].includes(name)) {
          let avgPrice = 0;
          if (this.state.variant.price > 0 && this.state.variant.package_quantity > 0) {
            avgPrice = this.state.variant.price / this.state.variant.package_quantity;
          }

          this.setState({
            variant: {
              ...this.state.variant,
              package_price: avgPrice,
            },
          });
        }

        if ("total_quantity" == name) {
          this.setState({
            variant: {
              ...this.state.variant,
              quantity: value,
            },
          });
        }
      }
    );
  };

  handleChangeUploadFile = (event) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      const form = baseHelper.getFormDataUploadFile(files);

      if (form) {
        this.props.uploadFileDigital(form);
      }
    }
  };

  onSubmit = (event) => {
    event.preventDefault();
    // Validate
    let errors = [];
    const _inputRef = compact(Array.from(this.inputsRef));
    map(_inputRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });
    errors = compact(errors);
    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      window.LadiUI.toastCustom("danger", "", errorMessage);
      return;
    }

    if (this.state.variant.download_count) {
      let numDownload = parseInt(this.state.variant.download_count);
      if (numDownload < 1) {
        window.LadiUI.toastCustom("danger", "", "Số lượt tải tối đa phải lớn hơn hoặc bằng 1");
        return false;
      }
    }

    if (!ValidatorHelper.isURL(this.state.variant.file)) {
      window.LadiUI.toastCustom("warning", "", "Vui lòng nhập đúng định dạng đường dẫn !");
      return false;
    }

    const _variant = {
      ...this.state.variant,
      price: this.state.variant.price || 0,
      quantity: this.state.variant.quantity || 0,
      package_price: this.state.variant.package_price || 0,
      package_quantity: this.state.variant.package_quantity || 1,
      package_addition_quantity: this.state.variant.package_addition_quantity || 0,
    };

    this.props.onSubmit(_variant);
  };

  onChangeFileType = (type) => {
    this.setState({
      variant: {
        ...this.state.variant,
        file: "",
      },
      fileType: type,
    });
  };

  render() {
    const { t } = this.props;
    const { variant } = this.state;
    const { currency_symbol: currencySymbol, currency_code: currencyCode } = this.state.userInfo.currentStore;
    const isLoadingUploadImage = this.props.fileReducer.loading && this.props.fileReducer.waiting == fileTypes.UPLOAD_VARIANT_IMAGE;

    return (
      <Modal
        id="modal-create-version-digital"
        title={`Tạo phiên bản mới`}
        onCancel={this.props.onCancel}
        onOk={(event) => this.onSubmit(event)}
        isLoading={this.props.isLoading}
        visible={this.props.visible}
        width={900}
      >
        {isLoadingUploadImage && <LoadingScene blur={true} />}
        <div className="ladiui-row">
          <div className="ladiui form-group">
            <label className="ladiui-label">Tên phiên bản</label>
            <Input
              ref={(ref) => this.inputsRef.add(ref)}
              validationName={t("PRODUCTS.VERSION_NAME")}
              value={variant.option_value_value}
              name="option_value_value"
              onChange={this.onChangeInput}
              placeholder="Nhập tên phiên bản"
              validations={{ isRequired: true }}
            />
          </div>

          <div className="ladiui form-group group-date">
            <label className="ladiui-label">Giá phiên bản</label>
            <div className="ladiui-input-group">
              <NumberInput
                name="price"
                prefix={currencySymbol}
                value={variant.price}
                onChange={this.onChangeInput}
                placeholder="Nhập giá phiên bản"
              />
            </div>
          </div>
        </div>
        <div className="ladiui form-group">
          <label className="ladiui-label">Tải file tài liệu</label>
          <div className="block-choose-type-upload">
            <div className="ladiui radio">
              <input
                type="radio"
                className="ladiui form-check-input"
                onChange={() => this.onChangeFileType(0)}
                id="exampleCheck"
                checked={this.state.fileType == 0}
                name="radio-group"
              />
              <span>Tải từ máy tính</span>
              <input
                type="radio"
                className="ladiui form-check-input ml-24"
                onChange={() => this.onChangeFileType(1)}
                id="exampleCheck"
                name="radio-group"
                checked={this.state.fileType == 1}
              />
              <span>Chèn link</span>
            </div>
          </div>
          {this.state.fileType == 0 ? (
            <div className="ladiui form-group">
              <div className="input-upload-file relative w100">
                <Input
                  className="limit-text-upload"
                  name="file"
                  placeholder="Tải file từ máy tính (Dung lượng tối đa 25MB)"
                  disabled={true}
                  value={this.state.variant.file}
                />
                <div className="ladiui-button-upload">
                  <button type="button" className="flex">
                    <i className="icon-export mr-4"></i>
                    <span>Tải lên</span>
                  </button>
                  <input
                    type="file"
                    name="file"
                    accept=".doc, .docx, .pdf, .xsl, .xlsx, .ppt, .pptx, .tiff, .cdr, .svg, .psd, .pdf, .ai, .mp4, .mov, .avi, .ladipage, .ladiflow"
                    // multiple="multiple"
                    onChange={this.handleChangeUploadFile}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="ladiui form-group">
              <div className="input-upload-file relative w100">
                <Input
                  name="file"
                  className="limit-text-link"
                  onChange={this.onChangeInput}
                  placeholder="Nhập link tài liệu của bạn (Dung lượng tối đa 25MB)"
                  value={this.state.variant.file}
                />
              </div>
            </div>
          )}
          <p className="request-format-file">
            (*) Định dạng file cho phép: doc, docx, pdf, .xsl, .xlsx, .csv, .ppt, .pptx. .tiff, .cdr, .svg, .psd, .pdf, .ai, .mp4, .mov,
            .avi, .mp3, .wma, .wav, .ladipage, .ladiflow
          </p>
        </div>
        <div className="ladiui form-group">
          <label className="ladiui-label">Số lượt tải tối đa (Trên 1 lần mua)</label>
          <Input
            type="number"
            value={variant.download_count}
            name="download_count"
            onChange={this.onChangeInput}
            style={{ width: "320px" }}
          />
        </div>
        {this.state.isShowAdvanced && (
          <div>
            <div style={{ display: "inline-block", width: "100%" }}>
              <div className="ladiui-col-3 pull-left text-center image setting-block-image mt-24">
                <div style={{ width: "100%", height: 250 }}>
                  <Image width={250} height={250} resize={appConfig.RESIZE.SMALL} src={variant.src} />

                  <div className="ladiui-button-upload image block-upload-image">
                    <div className="block-overlay" style={{ marginTop: "90px" }}>
                      <button type="button" className="ladiui btn btn-primary icon">
                        <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-gallery.svg" alt="" />

                        <p>
                          Bấm vào đây để chọn ảnh sản phẩm <br /> từ máy tính của bạn
                        </p>
                      </button>
                      <input
                        type="file"
                        name="file"
                        accept="image/*"
                        onChange={this.fileChangedHandler}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ladiui-col-7 pull-right">
                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PRODUCTS.DESCRIPTION")}</label>
                  <textarea
                    style={{ height: 250 }}
                    name="description"
                    className="ladiui textarea"
                    value={variant.description}
                    onChange={this.onChangeInput}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="is-Show-Advanced">
          <button
            onClick={() =>
              this.setState({
                isShowAdvanced: !this.state.isShowAdvanced,
              })
            }
            className="ladiui btn btn-secondary"
          >
            {this.state.isShowAdvanced ? t("COMMON.HIDE") : t("PRODUCT_EVENTS.BTN_TICKET_SHOW_ADVANCED")}
          </button>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    uploadTicketImage: (form) => dispatch(fileActions.uploadTicketImage(form)),
    uploadFileDigital: (form) => dispatch(fileActions.uploadFileDigital(form)),
  };
};

const mapStateToProps = (state) => ({
  variantReducer: { ...state.variant },
  fileReducer: { ...state.file },
  storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withTranslation("translation", { withRef: true })(ModalPackage)
);
