import React from "react";
import PropTypes from "prop-types";
import { find, compact, map, includes, remove, keys } from "lodash";

import * as storeTypes from "../../../redux/futures/store/types";
import storeActions from "../../../redux/futures/store/actions";

import { connect } from "react-redux";
import Input from "../../../components/Input";
import appConfig from "../../../config/app";
import { withTranslation } from "react-i18next";
import Modal from "../../../components/Modal";
import ConfirmModal from "../../../components/ConfirmModal";

class ModalSettingAccount extends React.Component {
  // static propTypes = {
  //     visible: PropTypes.bool,
  //     onCancel: PropTypes.func,
  //     onSubmit: PropTypes.func,
  //     isLoading: PropTypes.bool,
  // }

  constructor(props) {
    super(props);

    this.tabs = {
      blacklist: appConfig.IP_TYPE.BLACKLIST,
      spam: appConfig.IP_TYPE.SPAM,
    };

    this.state = {
      ipInput: "",
      typeSelected: this.tabs.blacklist,
      tabSelected: this.tabs.blacklist,
      isShowModal: false,
      selected: "",
    };

    this.inputsRef = new Set();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.storeReducer.action != nextProps.storeReducer.action) {
      if (nextProps.storeReducer.action == storeTypes.CREATE_NEW_IP) {
        if (nextProps.storeReducer.status) {
          this.setState({
            isShowModal: false,
          });
          setTimeout(() => {
            this.props.getUserInfo();
          }, 0);
          window.LadiUI.toastCustom("success", "", nextProps.storeReducer.message);
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.storeReducer.message);
        }
      }

      if (nextProps.storeReducer.action == storeTypes.DELETE_IP) {
        if (nextProps.storeReducer.status) {
          setTimeout(() => {
            this.props.getUserInfo();
          }, 0);

          window.LadiUI.closeModal("confirm-ip-delete");
          window.LadiUI.toastCustom("success", "", nextProps.storeReducer.message);
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.storeReducer.message);
        }
      }
    }
  }

  componentDidUpdate() {
    window.LadiUI.init();
  }

  openModalCreate = () => {
    this.setState({
      ipInput: "",
      isShowModal: true,
    });
  };

  hideModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  geTypeName = () => {
    const { t } = this.props;
    switch (this.state.typeSelected) {
      case this.tabs.blacklist:
        return t("IP.TAB_BLACKLIST");
      case this.tabs.spam:
        return t("IP.TAB_SPAM");
    }
  };

  opemModalConfirmDelete = (ip) => {
    this.setState(
      {
        selected: ip,
      },
      () => {
        window.LadiUI.showModal("confirm-ip-delete");
      }
    );
  };

  submit = () => {
    // Validate
    let errors = [];
    const inputsRef = compact(Array.from(this.inputsRef));
    map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });

    errors = compact(errors);
    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      window.LadiUI.toastCustom("danger", "", errorMessage);
      return;
    }

    this.props.createNewIP({
      type: this.state.typeSelected,
      ip: this.state.ipInput,
    });
  };

  delete = () => {
    this.props.deleteIP({
      type: this.state.tabSelected,
      ip: this.state.selected,
    });
  };

  render() {
    const { t } = this.props;
    const { isShowModal, ipInput, typeSelected, tabSelected } = this.state;

    let ips = [];

    if (tabSelected == this.tabs.blacklist) {
      ips = this.props.storeReducer.userInfo.ip_blacklist || [];
    } else {
      ips = this.props.storeReducer.userInfo.ip_spamlist || [];
    }

    const isLoadingCreate = this.props.storeReducer.loading && storeTypes.CREATE_NEW_IP == this.props.storeReducer.waiting;
    const isLoadingDelete = this.props.storeReducer.loading && storeTypes.DELETE_IP == this.props.storeReducer.waiting;

    return (
      <div
        className="page-setting"
        id="page-setting-ip"
        title={t("SETTINGS.IP_CONTENT")}
        // onOk={this.submit}
      >
        <div className="block-title-action">
          <h3 className="title">IP</h3>
          <div className="btn-action">
            <button type="button" onClick={this.openModalCreate} className="ladiui btn btn-primary btn-sm">
              <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />

              {t("IP.ADD_IP_TITLE")}
            </button>
          </div>
        </div>
        <div className="ladiui tab">
          <ul className="ladiui nav nav-tabs" id="myTab" role="tablist">
            <li className="ladiui nav-item" role="presentation">
              <span
                className="ladiui tab-link active"
                id="tab-blacklist"
                data-toggle="tab"
                data-parent="myTab"
                data-target="tab-blacklist"
                onClick={() => {
                  this.setState({
                    tabSelected: this.tabs.blacklist,
                    typeSelected: this.tabs.blacklist,
                  });
                }}
              >
                {t("IP.TAB_BLACKLIST")}
              </span>
            </li>
            <li className="ladiui nav-item" role="presentation">
              <span
                className="ladiui tab-link"
                id="tab-spam"
                data-toggle="tab"
                data-parent="myTab"
                data-target="tab-spam"
                onClick={() => {
                  this.setState({
                    tabSelected: this.tabs.spam,
                    typeSelected: this.tabs.spam,
                  });
                }}
              >
                {t("IP.TAB_SPAM")}
              </span>
            </li>
          </ul>
        </div>

        <div className="ladiui tab-content" id="myTabContent">
          <div className="ladi-card ladiui-table-responsive">
            <table className="ladiui table text-left">
              <thead>
                <tr className="ladiui table-vertical header">
                  <th scope="col" className={`text-left`}>
                    {t("IP.IP")}
                  </th>
                </tr>
              </thead>

              <tbody>
                {map(ips, (item, index) => {
                  return (
                    <tr key={index} className="ladiui table-vertical main">
                      <td>{item}</td>
                      <td className="text-right pd-0">
                        <div className="ladiui btn-group">
                          <div className="ladiui dropdown hide-mt ba-c">
                            <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                              <i className="ladiui icon icon-ldp-dot"></i>
                            </button>
                            <ul className="ladiui dropdown-menu r-0">
                              <li>
                                <a onClick={() => this.opemModalConfirmDelete(item)} className="ladiui dropdown-item">
                                  {t("ACTIONS.DELETE")}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}

                {ips.length <= 0 && (
                  <tr className="text-center">
                    <td colSpan="100%">{t("NO_DATA")}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {isShowModal && (
            <Modal
              id="modal-ip"
              title={t("IP.ADD_IP_TITLE")}
              visible={isShowModal}
              onCancel={this.hideModal}
              onOk={this.submit}
              bodyClassName="account-body"
              width={350}
              isLoading={isLoadingCreate}
            >
              <div className="ladiui form-group data-status">
                {/* <div className="ladiui-select-options" style={{ minWidth: 120 }}>
                                    <span className="ladiui-select-init">{this.geTypeName(this.state.typeSelected)}</span>
                                    <input type="hidden" name="select" defaultValue />
                                    <ul>
                                        <li className="select-item"
                                            onClick={() => this.setState({
                                                typeSelected: this.tabs.blacklist
                                            })}>
                                            <a data-value={''}>{t('IP.TAB_BLACKLIST')}</a>
                                        </li>
                                        <li className="select-item"
                                            onClick={() => this.setState({
                                                typeSelected: this.tabs.spam
                                            })}>
                                            <a data-value={''}>{t('IP.TAB_SPAM')}</a>
                                        </li>
                                    </ul>
                                </div> */}
                <div className="ladiui dropdown" style={{ width: "100%" }}>
                  <button className="ladiui btn btn-outline-light dropdown-toggle" data-toggle="dropdown" style={{ width: "100%" }}>
                    {this.geTypeName(this.state.typeSelected)}
                  </button>
                  <ul className="ladiui dropdown-menu custom-form-dynamic" style={{ width: "100%" }}>
                    <li
                      className="ladiui dropdown-item"
                      onClick={() =>
                        this.setState({
                          typeSelected: this.tabs.blacklist,
                        })
                      }
                    >
                      <a data-value={""}>{t("IP.TAB_BLACKLIST")}</a>
                    </li>
                    <li
                      className="ladiui dropdown-item"
                      onClick={() =>
                        this.setState({
                          typeSelected: this.tabs.spam,
                        })
                      }
                    >
                      <a data-value={""}>{t("IP.TAB_SPAM")}</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="ladiui form-group">
                <Input
                  value={ipInput}
                  onChange={(event) => {
                    this.setState({
                      ipInput: event.target.value,
                    });
                  }}
                  validationName={t("IP.IP")}
                  validations={{ isRequired: true }}
                  ref={(ref) => this.inputsRef.add(ref)}
                  placeholder={t("IP.PLACE_HOLDER")}
                />
              </div>
              <span style={{ fontSize: 12, fontStyle: "italic", marginTop: 15 }}>{t("IP.DESCRIPTION")}</span>
            </Modal>
          )}
        </div>

        <ConfirmModal
          id="confirm-ip-delete"
          title={t("IP.MSG_DELETE_IP_TITLE")}
          content={t("IP.MSG_DELETE_IP_CONTENT", this.state.selected)}
          cancelText={t("ACTIONS.CANCEL")}
          okText={t("ACTIONS.DELETE")}
          onOk={this.delete}
          isLoading={isLoadingDelete}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createNewIP: (data) => dispatch(storeActions.createNewIP(data)),
    deleteIP: (storeIDs) => dispatch(storeActions.deleteIP(storeIDs)),
    getUserInfo: () => dispatch(storeActions.getUserInfo()),
  };
};

const mapStateToProps = (state) => ({
  storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translation", { withRef: true })(ModalSettingAccount));
