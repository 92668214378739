/** @format */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import pageCheckoutActions from '../../../redux/futures/page_checkout/actions';
import * as pageCheckoutTypes from '../../../redux/futures/page_checkout/types';

import PanelStoreInfo from './PanelStoreInfo';
import PanelSettingGeneralStore from './PanelSettingGeneralStore';
import PanelHomepageInterface from './PanelHomepageInterface';

import LoadingScene from '../../../components/LoadingScene';

import moment from 'moment';

import { includes, map, remove, compact } from 'lodash';
import appConfig from '../../../config/app';

export default function HomePageInfo(props) {
    let hash = window.location.hash;
    if (!hash) {
        hash = 'STORE_INFO';
    } else {
        hash = hash.replace('#', '');
    }

    let { storePageID } = useParams();
    const location = useLocation();
    const history = useHistory();
    const queryStrings = new URLSearchParams(location.search);

    const pageCheckoutReducer = useSelector((state) => state.pageCheckoutReducer);

    const [selectedState, setSelectedState] = useState(hash);

    const inputsRef = new Set();

    const formRef = React.createRef();
    const panelStoreInfoRef = React.createRef();
    const panelGeneralStoreRef = React.createRef();
    const panelStoreInterfaceRef = React.createRef();

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [dataStoreInfo, setDataStoreInfo] = useState({});
    const [dataSeo, setDataSeo] = useState({});
    const [dataHomePageConfig, setDataHomePageConfig] = useState([]);

    const [currentDataGeneral, setCurrentDataGeneral] = useState({});
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedPageCheckoutId, setSelectedPageCheckoutId] = useState(null);

    const [isReloadIframe, setIsReloadIframe] = useState(false);

    const [urlPublished, setUrlPublished] = useState('');

    useEffect(() => {
        if (storePageID) {
            dispatch(pageCheckoutActions.show(storePageID));
        }
    }, [storePageID]);

    useEffect(() => {
        if (pageCheckoutReducer.action == pageCheckoutTypes.SHOW_PAGE_CHECKOUT) {
            if (pageCheckoutReducer.status) {
                let dataPageCheckout = pageCheckoutReducer.page_checkout;

                setDataStoreInfo({
                    ...dataPageCheckout.config_store,
                    status: dataPageCheckout.status ? dataPageCheckout.status : false,
                    store_name: dataPageCheckout.name,
                });
                setDataSeo(dataPageCheckout.seo || {});
                setDataHomePageConfig(dataPageCheckout.home_page_config || []);
                setCurrentDataGeneral({
                    ...currentDataGeneral,
                    checkout_config_id: dataPageCheckout.checkout_config_id || null,
                    domain: dataPageCheckout.domain && dataPageCheckout.domain != appConfig.DOMAIN_CHECKOUTS ? dataPageCheckout.domain : '',
                    path: dataPageCheckout.path || '',
                });
                setSelectedCategories(dataPageCheckout.categories || []);
                setSelectedPageCheckoutId(dataPageCheckout.page_checkout_id);

                if (dataPageCheckout.path) {
                    setUrlPublished(`https://${appConfig.DOMAIN_CHECKOUTS}/page/${dataPageCheckout.path}`);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
            }
        }
        // if (pageCheckoutReducer.action == pageCheckoutTypes.CREATE_PAGE_CHECKOUT) {
        //     if (pageCheckoutReducer.status) {
        //         window.LadiUI.toastCustom('success', '', pageCheckoutReducer.message);
        //         // props.history.push('/store-page');
        //     } else {
        //         window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
        //     }
        // }
        if (pageCheckoutReducer.action == pageCheckoutTypes.UPDATE_PAGE_CHECKOUT) {
            if (pageCheckoutReducer.status) {
                if (storePageID) {
                    dispatch(pageCheckoutActions.show(storePageID));
                }
                setIsReloadIframe(!isReloadIframe);
                window.LadiUI.toastCustom('success', '', pageCheckoutReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
            }
        }
    }, [pageCheckoutReducer]);

    const handleSelectedState = async (state) => {
        window.location.hash = `#${state}`;
        setSelectedState(state);
        await setIsReloadIframe(!isReloadIframe);
    };

    const submitData = () => {
        let errors = [];
        const _inputsRef = compact(Array.from(inputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);

            return;
        }

        const dataStoreInfo = panelStoreInfoRef.current.getData();
        const dataGeneralStore = panelGeneralStoreRef.current.getData();
        const dataStoreInterface = panelStoreInterfaceRef.current.getData();

        let dataLocations = [];

        if (dataStoreInterface.home_page_config && dataStoreInterface.home_page_config.length > 0) {
            dataStoreInterface.home_page_config.map((item, index) => {
                if (item.type == 'PRODUCT') {
                    item.values = item.values && item.values.length > 0 ? item.values.map((_item) => _item.product_id || _item) : [];
                }

                if (item.type == 'DISCOUNT') {
                    item.values = item.values && item.values.length > 0 ? item.values.map((_item) => _item.discount_id || _item) : [];
                }

                dataLocations.push(item);
            });
        }

        let payloadSubmitData = {
            page_checkout: {
                checkout_config_id: dataGeneralStore.checkout_config_id ? dataGeneralStore.checkout_config_id : null,
                domain: dataGeneralStore.domain ? dataGeneralStore.domain : '',
                path: dataGeneralStore.path ? dataGeneralStore.path : '',
                types: 'Store',
                name: dataStoreInfo ? dataStoreInfo.store_name : '',
                seo: dataGeneralStore.seo ? dataGeneralStore.seo : {},
                config_store: {
                    banner: dataStoreInfo.banner ? dataStoreInfo.banner : '',
                    logo: dataStoreInfo.logo ? dataStoreInfo.logo : '',
                    address: dataStoreInfo.store_address ? dataStoreInfo.store_address : '',
                    phone: dataStoreInfo.store_phone ? dataStoreInfo.store_phone : '',
                    reviews: dataStoreInfo.review ? dataStoreInfo.review : null,
                    quantity_of_products_sold: dataStoreInfo.product_sold ? dataStoreInfo.product_sold : null,
                    interactive_channels: dataStoreInfo.interactive_channels ? dataStoreInfo.interactive_channels : [],
                },
                status: dataStoreInfo.status ? dataStoreInfo.status : false,
                home_page_config: dataLocations,
                config_category: dataStoreInterface.category_ids ? dataStoreInterface.category_ids : [],
            },
        };

        if (!selectedPageCheckoutId) {
            dispatch(pageCheckoutActions.create(payloadSubmitData));
        } else {
            payloadSubmitData.page_checkout.page_checkout_id = selectedPageCheckoutId;

            dispatch(pageCheckoutActions.update(payloadSubmitData));
        }
    };

    const isLoadingHomePageCreate = pageCheckoutReducer.loading && pageCheckoutReducer.waiting == pageCheckoutTypes.CREATE_PAGE_CHECKOUT;
    const isLoadingHomePageUpdate = pageCheckoutReducer.loading && pageCheckoutReducer.waiting == pageCheckoutTypes.UPDATE_PAGE_CHECKOUT;

    return (
        <div id='home-page-info' className='customer-page'>
            {isLoadingHomePageCreate || (isLoadingHomePageUpdate && <LoadingScene blur={true} />)}
            <div className='content'>
                <div className={`setting-content`} style={{ height: '100vh' }}>
                    <ul>
                        <li
                            className={`${selectedState == 'STORE_INFO' ? 'ladiui setting-item active' : 'ladiui setting-item'}`}
                            onClick={() => handleSelectedState('STORE_INFO')}
                        >
                            <div className='text'>
                                <p className='title'>Thông tin cửa hàng</p>
                            </div>
                        </li>
                        <li
                            className={`${selectedState == 'SETTING_GENERAL' ? 'ladiui setting-item active' : 'ladiui setting-item'}`}
                            onClick={() => handleSelectedState('SETTING_GENERAL')}
                        >
                            <div className='text'>
                                <p className='title'>Cài đặt chung</p>
                            </div>
                        </li>
                        <li
                            className={`${selectedState == 'HOME_PAGE_INTERFACE' ? 'ladiui setting-item active' : 'ladiui setting-item'}`}
                            onClick={() => handleSelectedState('HOME_PAGE_INTERFACE')}
                        >
                            <div className='text'>
                                <p className='title'>Giao diện trang chủ</p>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className='right-content'>
                    <PanelStoreInfo
                        ref={panelStoreInfoRef}
                        isShow={selectedState == 'STORE_INFO'}
                        handleSubmitData={() => submitData()}
                        currentDataStoreInfo={dataStoreInfo || {}}
                        selectedPageCheckoutId={selectedPageCheckoutId}
                        urlPublished={urlPublished}
                        isReloadIframe={isReloadIframe}
                    />
                    <PanelSettingGeneralStore
                        ref={panelGeneralStoreRef}
                        isShow={selectedState == 'SETTING_GENERAL'}
                        handleSubmitData={() => submitData()}
                        currentDataSeo={dataSeo || {}}
                        currentDataGeneral={currentDataGeneral || {}}
                        selectedPageCheckoutId={selectedPageCheckoutId}
                        urlPublished={urlPublished}
                        isReloadIframe={isReloadIframe}
                    />
                    <PanelHomepageInterface
                        ref={panelStoreInterfaceRef}
                        isShow={selectedState == 'HOME_PAGE_INTERFACE'}
                        handleSubmitData={() => submitData()}
                        currentDataConfig={dataHomePageConfig || []}
                        currentCategories={selectedCategories || []}
                        selectedPageCheckoutId={selectedPageCheckoutId}
                        urlPublished={urlPublished}
                        isReloadIframe={isReloadIframe}
                    />
                </div>
            </div>
        </div>
    );
}
