/** @format */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import moment from 'moment';

import fileActions from '../../../redux/futures/file/actions';
import * as fileTypes from '../../../redux/futures/file/types';

import productCategoryActions from '../../../redux/futures/product_category/actions';
import * as productCategoryTypes from '../../../redux/futures/product_category/types';

import ModalDiscountHomePage from './Modals/ModalDiscountHomePage';
import ModalAddDescription from './Modals/ModalAddDescription';
import ModalProduct from './Modals/ModalProduct';
import ModalAddBanner from './Modals/ModalAddBanner';
import ModalAddProduct from '../../orders/OrderCreate/components/ModalAddProduct';

import { includes, map, remove } from 'lodash';
import Input from '../../../components/Input';
import SelectOption from '../../../components/SelectOption';

import BaseHelper from '../../../helpers/BaseHelper';
import Tag from '../../../components/Tag';

import appConfig from '../../../config/app';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const CDN = 'https://w.ladicdn.com/';
const PanelHomepageInterface = forwardRef((props, ref) => {
    let { isShow, currentDataConfig, currentCategories, isReloadIframe } = props;
    const location = useLocation();
    const history = useHistory();
    const queryStrings = new URLSearchParams(location.search);

    const formRef = React.createRef();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return {
                home_page_config: locations,
                category_ids:
                    suggestedCategories && suggestedCategories.length > 0
                        ? suggestedCategories.map((item) => item.product_category_id)
                        : [],
            };
        },
    }));

    const fileReducer = useSelector((state) => state.file);
    const productCategoryReducer = useSelector((state) => state.productCategory);

    const [locations, setLocations] = useState([
        {
            title: 'Khuyến mãi',
            type: 'DISCOUNT',
            values: [],
        },
        {
            title: 'Banner cửa hàng',
            type: 'BANNER',
            values: [],
        },
        {
            title: 'Mô tả cửa hàng',
            type: 'DESCRIPTION',
            value: '',
        },
        {
            title: 'Sản phẩm bán chạy',
            type: 'PRODUCT',
            values: [],
            layout_type: 'Carousel',
            is_show_star: false,
        },
        {
            title: 'Sản phẩm mới nhất',
            type: 'PRODUCT',
            values: [],
            layout_type: 'Carousel',
            is_show_star: false,
        },
        {
            title: 'Sản phẩm đề xuất',
            type: 'PRODUCT',
            values: [],
            layout_type: 'Carousel',
            is_show_star: false,
        },
    ]);

    const [isShowBlockAddContent, setIsShowBlockAddContent] = useState(false);

    const [isShowModalDiscount, setIsShowModalDiscount] = useState(false);
    const [isShowModalAddBanner, setIsShowModalAddBanner] = useState(false);
    const [isShowModalAddDescription, setIsShowModalAddDescription] = useState(false);
    const [isShowModalAddBlockProduct, setIsShowModalAddBlockProduct] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [dataSelect, setDataSelect] = useState({});

    const [categories, setCategories] = useState([]);
    const [suggestedCategories, setSuggestedCategories] = useState([]);

    const [valueSearchCategory, setValueSearchCategory] = useState('');
    const [isShowModalAddCategory, setIsShowModalAddCategory] = useState(false);

    const [urlPublished, setUrlPublished] = useState(props.urlPublished);

    useEffect(() => {}, [fileReducer]);

    useEffect(() => {
        dispatch(productCategoryActions.search('', 'Product'));
    }, []);

    useEffect(() => {
        let iFrame = document.getElementById('iframe-interface');

        if (iFrame) {
            iFrame.src = iFrame.src;
            // iFrame.contentWindow.location.reload();
        }
    }, [isReloadIframe]);

    useEffect(() => {
        if (props.urlPublished) {
            setUrlPublished(props.urlPublished);
        }
    }, [props.urlPublished]);

    useEffect(() => {
        if (productCategoryReducer && productCategoryReducer.action == productCategoryTypes.SEARCH_CATEGORY) {
            if (productCategoryReducer.status) {
                setCategories(productCategoryReducer.productCategories);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productCategoryReducer.message, 'OK');
            }
        }
    }, [productCategoryReducer]);

    useEffect(() => {
        if (currentDataConfig && currentDataConfig.length > 0) {
            // let data = [];
            // currentDataConfig.map((item, index) => {
            //     if (item.type == "PRODUCT") {
            //         item.values = item.values && item.values.length > 0 ? item.values.map(_item => _item.product_id) : []
            //     }

            //     data.push(item);
            // })
            setLocations(currentDataConfig);
        }
    }, [currentDataConfig]);

    useEffect(() => {
        if (currentCategories && currentCategories.length > 0) {
            setSuggestedCategories(currentCategories);
        }
    }, [currentCategories]);

    const handleShowBlockAddContent = () => {
        setIsShowBlockAddContent(!isShowBlockAddContent);
    };

    const handleAddContentLocationDiscount = async (data, _index) => {
        if (!data.title || data.title == '') {
            window.LadiUI.toastCustom('warning', '', 'Vui lòng nhập tiêu đề');
            return;
        }
        if (!data.values || data.values.length == 0) {
            window.LadiUI.toastCustom('warning', '', 'Vui lòng chọn ít nhất 1 mã khuyến mãi');
            return;
        }

        let _locations = [...locations];

        if (_index < 0) {
            _locations.push(data);
        } else {
            _locations[_index] = data;
        }

        setLocations(_locations);

        setIsShowModalDiscount(false);

        props.handleSubmitData();
    };

    const handleAddContentLocationBanner = async (data, _index) => {
        if (!data.title || data.title == '') {
            window.LadiUI.toastCustom('warning', '', 'Vui lòng nhập tiêu đề');
            return;
        }

        let _locations = [...locations];

        if (_index < 0) {
            _locations.push(data);
        } else {
            _locations[_index] = data;
        }

        setLocations(_locations);

        setIsShowModalAddBanner(false);

        props.handleSubmitData();
    };

    const handleAddContentLocationDescription = async (data, _index) => {
        if (!data.title || data.title == '') {
            window.LadiUI.toastCustom('warning', '', 'Vui lòng nhập tiêu đề ');
            return;
        }

        if (!data.value || data.value == '') {
            window.LadiUI.toastCustom('warning', '', 'Vui lòng nhập nội dung');
            return;
        }

        let _locations = [...locations];

        if (_index < 0) {
            _locations.push(data);
        } else {
            _locations[_index] = data;
        }

        setLocations(_locations);

        setIsShowModalAddDescription(false);

        props.handleSubmitData();
    };

    const handleAddContentLocationProduct = async (data, _index) => {
        if (!data.title || data.title == '') {
            window.LadiUI.toastCustom('warning', '', 'Vui lòng nhập tiêu đề');
            return;
        }

        if (!data.values || data.values.length == 0) {
            window.LadiUI.toastCustom('warning', '', 'Vui lòng chọn ít nhất 1 sản phẩm');
            return;
        }

        let _locations = [...locations];

        if (_index < 0) {
            _locations.push(data);
        } else {
            _locations[_index] = data;
        }

        setLocations(_locations);

        setIsShowModalAddBlockProduct(false);

        props.handleSubmitData();
    };

    const handleSubmitData = () => {
        props.handleSubmitData();
    };

    const handleDeleteLocation = (index) => {
        let _locations = [...locations];

        _locations.splice(index, 1);

        setLocations(_locations);
    };

    const handleEditLoaction = (item, index) => {
        setDataSelect(item);
        setSelectedIndex(index);
        setIsShowBlockAddContent(false);

        switch (item.type) {
            case 'DISCOUNT':
                setIsShowModalDiscount(true);
                break;
            case 'BANNER':
                setIsShowModalAddBanner(true);
                break;
            case 'DESCRIPTION':
                setIsShowModalAddDescription(true);
                break;
            case 'PRODUCT':
                setIsShowModalAddBlockProduct(true);
                break;
            default:
                break;
        }
    };

    const getIconByType = (type) => {
        let imgIcon = '';
        switch (type) {
            case 'DISCOUNT':
                imgIcon = <img src='https://w.ladicdn.com/ladiui/icons/ldicon-discount-coupon.svg' alt=' ' />;
                break;
            case 'BANNER':
                imgIcon = <img src='https://w.ladicdn.com/ladiui/icons/ldicon-image.svg' alt=' ' />;
                break;
            case 'DESCRIPTION':
                imgIcon = <img src='https://w.ladicdn.com/ladiui/icons/ldicon-showcase.svg' alt=' ' />;
                break;
            case 'PRODUCT':
                imgIcon = <img src='https://w.ladicdn.com/ladiui/icons/ldicon-shopping-bag.svg' alt=' ' />;
                break;
            default:
                imgIcon = <img src='https://w.ladicdn.com/ladiui/icons/ldicon-shopping-bag.svg' alt=' ' />;
                break;
        }

        return imgIcon;
    };

    const onChangeSearchValueCategory = async (event) => {
        let value = event.target.value;
        await setValueSearchCategory(value);
        dispatch(productCategoryActions.search(value));
    };

    const handleSearchCategorySuggestion = () => {
        dispatch(productCategoryActions.search(valueSearchCategory));
    };

    const selectCategorySuggestion = (item) => {
        let _category_ids = [...suggestedCategories];

        _category_ids.push(item);

        setSuggestedCategories(_category_ids);
    };

    const handleDeleteCategoryItem = (_item) => {
        let _selectedCategories = [...suggestedCategories];
        let _suggestedDelete = _selectedCategories.filter((item) => item.product_category_id != _item.product_category_id);
        if (_suggestedDelete) {
            setSuggestedCategories(_suggestedDelete);
        }
    };

    const handleGetLinkEditCate = (item) => {
        switch (item.type) {
            case 'Product':
                window.open(
                    `/product-category?type=Product&is_show_modal=true&current_cate_id=${item.product_category_id}`,
                    '_blank',
                    'noopener,noreferrer'
                );
                break;
            case 'Course':
                window.open(
                    `/course-category?type=Course&is_show_modal=true&current_cate_id=${item.product_category_id}`,
                    '_blank',
                    'noopener,noreferrer'
                );
                break;
            default:
        }
    };

    const renderCategoryItem = () => {
        return suggestedCategories.map((item, index) => {
            return (
                <Draggable key={index} draggableId={'' + index} index={index}>
                    {(provided, snapshot) => (
                        <div
                            className='category-item'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                                ...provided.draggableProps.style,
                                userSelect: 'none',
                            }}
                        >
                            <div className='category-name'>
                                <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg' />
                                <div className='flex ml-16'>
                                    <a className='ladiui-label pb-0' onClick={() => handleGetLinkEditCate(item)}>
                                        {item.name}
                                    </a>
                                    <span className='ladiui-sub-title mb-0 ml-8'>
                                        {item.num_product ? item.num_product + ' sản phẩm' : ''}
                                    </span>
                                </div>
                            </div>
                            <div className='actions'>
                                <div className='btn-delete cursor-pointer' onClick={() => handleDeleteCategoryItem(item)}>
                                    <i className='ladi-icon icon-bin'></i>
                                </div>
                            </div>
                        </div>
                    )}
                </Draggable>
            );
        });
    };

    const removeCategorySuggestion = (_item) => {
        let _selectedCategories = [...suggestedCategories];
        let _suggestedDelete = _selectedCategories.filter((item) => item.product_category_id != _item.product_category_id);
        if (_suggestedDelete) {
            setSuggestedCategories(_suggestedDelete);
        }
    };

    // ------------------------------------------------------kéo thả-----------------------------------------------

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    const onDragEndLocation = (result) => {
        if (!result.destination || result.source.index == result.destination.index) {
            return;
        }
        const items = reorder(locations, result.source.index, result.destination.index);
        setLocations(items);
    };

    const onDragEndSuggestionCategory = (result) => {
        if (!result.destination || result.source.index == result.destination.index) {
            return;
        }
        const items = reorder(suggestedCategories, result.source.index, result.destination.index);
        setSuggestedCategories(items);
    };

    return (
        <div className='panel-store-info block-homepage-interface' style={isShow ? { display: 'block' } : { display: 'none' }}>
            <div className='block-title-action'>
                <h3 className='title'>Giao diện trang chủ</h3>
                <button className='ladiui btn btn-primary' onClick={() => handleSubmitData()}>
                    {props.selectedPageCheckoutId ? 'Lưu cửa hàng' : 'Tạo cửa hàng'}
                </button>
            </div>
            <div className='main-content'>
                <div className='left-content'>
                    <div className='block-interfaces'>
                        <h3 className='ladiui-label'>Nội dung trang chủ</h3>
                        <span className='ladiui-sub-title' style={{ fontWeight: 'normal' }}>
                            Chọn loại nội dung và vị trí hiển thị trên trang chủ
                        </span>
                        {console.log('locations: ', locations)}
                        {locations && locations.length > 0 && (
                            <DragDropContext onDragEnd={onDragEndLocation}>
                                <Droppable droppableId='droppable'>
                                    {(provided, snapshot) => (
                                        <div
                                            className='block-list-locations'
                                            ref={provided.innerRef}
                                            style={{
                                                background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                            }}
                                        >
                                            {locations &&
                                                locations.map((item, index) => {
                                                    return (
                                                        <Draggable key={index} draggableId={`${index}`} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    className='location-item'
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={{
                                                                        ...provided.draggableProps.style,
                                                                        userSelect: 'none',
                                                                        // background: snapshot.isDragging ? "#e8f0fe" : "none",
                                                                    }}
                                                                >
                                                                    <i className='icon-move mr-12'>
                                                                        <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg' />
                                                                    </i>
                                                                    <div className='w100 flex space-between content'>
                                                                        <div className='flex'>
                                                                            <div className='item-img flex mr-12'>
                                                                                {getIconByType(item.type)}
                                                                            </div>
                                                                            <div className='item-text'>
                                                                                <span>{item.title}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='action-item'>
                                                                            <span className='btn-edit cursor-pointer'>
                                                                                <img
                                                                                    src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-edit.svg'
                                                                                    onClick={() => handleEditLoaction(item, index)}
                                                                                />
                                                                            </span>
                                                                            <span
                                                                                className='btn-delete cursor-pointer'
                                                                                onClick={() => handleDeleteLocation(index)}
                                                                            >
                                                                                <i className='ladi-icon icon-bin' />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}

                        <div className='ladiui border-none btn-add-option mb-12 mt-24' onClick={() => handleShowBlockAddContent()}>
                            <img className='mr-8' src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg' />
                            <div className='btn-add-product'>Thêm nội dung</div>
                        </div>
                        {isShowBlockAddContent && (
                            <div className='choose-content'>
                                <ul className='list-choose-item'>
                                    <li
                                        onClick={() => {
                                            setIsShowModalDiscount(true);
                                            setIsShowBlockAddContent(false);
                                            setDataSelect({});
                                            setSelectedIndex(-1);
                                        }}
                                    >
                                        <div className='image'>
                                            <img
                                                src='https://w.ladicdn.com/ladiui/icons/ldicon-discount-coupon.svg'
                                                alt=' '
                                                style={{ marginRight: '4px' }}
                                            />
                                        </div>
                                        <div className='content'>
                                            <h3>Khuyến mãi</h3>
                                            <span>Thêm chương trình khuyến mãi lên trang chủ</span>
                                        </div>
                                    </li>
                                    <li
                                        onClick={() => {
                                            setIsShowModalAddBanner(true);
                                            setIsShowBlockAddContent(false);
                                            setDataSelect({});
                                            setSelectedIndex(-1);
                                        }}
                                    >
                                        <div className='image'>
                                            <img
                                                src='https://w.ladicdn.com/ladiui/icons/ldicon-image.svg'
                                                alt=' '
                                                style={{ marginRight: '4px' }}
                                            />
                                        </div>
                                        <div className='content'>
                                            <h3>Banner cửa hàng</h3>
                                            <span>Thêm banner cửa hàng lên trang chủ</span>
                                        </div>
                                    </li>
                                    <li
                                        onClick={() => {
                                            setIsShowModalAddDescription(true);
                                            setIsShowBlockAddContent(false);
                                            setDataSelect({});
                                            setSelectedIndex(-1);
                                        }}
                                    >
                                        <div className='image'>
                                            <img
                                                src='https://w.ladicdn.com/ladiui/icons/ldicon-showcase.svg'
                                                alt=' '
                                                style={{ marginRight: '4px' }}
                                            />
                                        </div>
                                        <div className='content'>
                                            <h3>Mô tả cửa hàng</h3>
                                            <span>Thêm mô tả cửa hàng của bạn lên trang chủ</span>
                                        </div>
                                    </li>
                                    <li
                                        onClick={() => {
                                            setIsShowModalAddBlockProduct(true);
                                            setIsShowBlockAddContent(false);
                                            setDataSelect({});
                                            setSelectedIndex(-1);
                                        }}
                                    >
                                        <div className='image'>
                                            <img
                                                src='https://w.ladicdn.com/ladiui/icons/ldicon-shopping-bag.svg'
                                                alt=' '
                                                style={{ marginRight: '4px' }}
                                            />
                                        </div>
                                        <div className='content'>
                                            <h3>Block sản phẩm</h3>
                                            <span>Thêm block sản phẩm lên trang chủ(đề xuất, bán chạy, mới nhất...)</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )}

                        <div className='block-suggestion-category'>
                            <h3>Danh mục sản phẩm</h3>
                            <span className='sub-title'>Hiển thị tại trang sản phẩm của cửa hàng</span>
                            {suggestedCategories && suggestedCategories.length > 0 && (
                                <DragDropContext onDragEnd={onDragEndSuggestionCategory}>
                                    <Droppable droppableId='droppable' type='droppableItem'>
                                        {(provided, snapshot) => (
                                            <div
                                                className='list-categories'
                                                ref={provided.innerRef}
                                                style={{
                                                    background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                                }}
                                            >
                                                {renderCategoryItem()}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            )}

                            <div className='ladiui border-none btn-add-option mb-12' onClick={() => setIsShowModalAddCategory(true)}>
                                <img className='mr-8' src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg' />
                                <div className='btn-add-product'>Thêm danh mục</div>
                            </div>

                            {/* <Tag
                                wrapperStyle={{
                                    position: 'relative',
                                    minWidth: '400px',
                                }}
                                tabIndex={6}
                                // ref={categoryRef}
                                id='category-tag'
                                allTags={categories || []}
                                selectedTags={suggestedCategories || []}
                                title={''}
                                className='mt-0'
                                placeholderInput={'Chọn danh mục'}
                                // isCategoryTag={true}
                                hideTitle={true}
                                onSelectTag={(item) => selectCategorySuggestion(item)}
                                onRemoveTag={(item) => removeCategorySuggestion(item)}
                            /> */}
                        </div>
                    </div>
                </div>
                <div className='right-content'>
                    {
                        // urlPublished
                        // &&
                        <div className='ladiui iframe-checkout'>
                            <iframe src={`${urlPublished}?isPreview=true`} width={'100%'} id='iframe-interface' />
                        </div>
                    }
                </div>
            </div>

            {isShowModalAddCategory && (
                <ModalAddProduct
                    onClose={() => setIsShowModalAddCategory(false)}
                    titleModal={'Thêm danh mục'}
                    isShow={isShowModalAddCategory}
                    listVariant={categories || []}
                    innerStyle={{ display: 'none' }}
                    selectVariant={selectCategorySuggestion}
                    isSelectedTypeProduct={true}
                    selectedProducts={suggestedCategories || []}
                    searchVariantValue={valueSearchCategory}
                    placeholder='Tìm kiếm danh mục'
                    isCategory={true}
                    onChangeSearchVariantInput={onChangeSearchValueCategory}
                    searchVariant={handleSearchCategorySuggestion}
                />
            )}

            {isShowModalDiscount && (
                <ModalDiscountHomePage
                    isShow={isShowModalDiscount}
                    onClose={(status) => setIsShowModalDiscount(status)}
                    selectedDiscount={[]}
                    handleAddContentLocation={handleAddContentLocationDiscount}
                    selectedIndex={selectedIndex}
                    dataSelect={dataSelect || {}}
                />
            )}
            {isShowModalAddBanner && (
                <ModalAddBanner
                    isShow={isShowModalAddBanner}
                    onClose={(status) => setIsShowModalAddBanner(status)}
                    selectedValue={''}
                    handleAddContentLocation={handleAddContentLocationBanner}
                    selectedIndex={selectedIndex}
                    dataSelect={dataSelect || {}}
                />
            )}
            {isShowModalAddDescription && (
                <ModalAddDescription
                    isShow={isShowModalAddDescription}
                    onClose={(status) => setIsShowModalAddDescription(status)}
                    selectedValue={''}
                    handleAddContentLocation={handleAddContentLocationDescription}
                    selectedIndex={selectedIndex}
                    dataSelect={dataSelect || {}}
                />
            )}
            {isShowModalAddBlockProduct && (
                <ModalProduct
                    isShow={isShowModalAddBlockProduct}
                    onClose={(status) => setIsShowModalAddBlockProduct(status)}
                    selectedProducts={[]}
                    selectedIndex={selectedIndex}
                    dataSelect={dataSelect || {}}
                    selectedPageCheckoutId={props.selectedPageCheckoutId}
                    handleAddContentLocation={handleAddContentLocationProduct}
                />
            )}
        </div>
    );
});

PanelHomepageInterface.propTypes = {};

export default PanelHomepageInterface;
