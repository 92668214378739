import React from 'react';

import { connect } from 'react-redux';
import packageActions from '../../redux/futures/package/actions';
import * as packageTypes from '../../redux/futures/package/types';

import { compact, map } from 'lodash';
import appConfig from '../../config/app';
import baseHelper from '../../helpers/BaseHelper';

class Upgrade extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            month: 3,
            package_id: 2,
        }

        this.inputsRef = new Set();
    }

    componentDidMount() {
        window.LadiUI.init();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.upgradeReducer.action != nextProps.upgradeReducer.action) {
            if (nextProps.upgradeReducer.action == packageTypes.UPGRADE_PACKAGE) {
                if (nextProps.upgradeReducer.status) {
                    window.location.href = nextProps.upgradeReducer.payURL;
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.upgradeReducer.message);
                }
            }
        }
    }

    onChangeInput = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    }

    submit = () => {
        // Validate
        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        const { month, package_id: packageID } = this.state;
        const storeID = baseHelper.getCookie(appConfig.COOKIE.STORE_ID);
        this.props.upgrade(
            storeID,
            month,
            packageID,
        );
    }
    /****************************************************************/

    render() {

        return (
            <div className="upgrade-page">
                <h1 className="page-title">Upgrade</h1>
                <div className="upgrade-content">
                    <div>
                        <h3 className="upgrade-heading">Choose Package</h3>
                        <div className="upgrade-package">
                            <span>
                                <input
                                    id=""
                                    type="radio"
                                    name="package_name"
                                    value="CORE"
                                    checked={true}
                                />
                                <label htmlFor="package_name">CORE</label>
                            </span>

                            <span>
                                <input
                                    id=""
                                    type="radio"
                                    name="package_name"
                                    value="ENTERPRISE"
                                    checked={false}
                                />
                                <label htmlFor="package_name">ENTERPRISE</label>
                            </span>
                        </div>

                        <div>
                            <h3 className="upgrade-heading">Usage Time</h3>

                            <ul className="upgrade-package-time">
                                <li className="active">3 Months</li>
                                <li>1 Year</li>
                                <li>2 Years</li>
                            </ul>

                            <div className="upgrade-total">
                                <span><strong>Total: $100</strong></span>
                            </div>
                        </div>

                        <div className="upgrade-checkout">
                            <button onClick={this.submit} className="ladiui button primary">CHECKOUT</button>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        upgrade: (storeID, month, packageID) => dispatch(packageActions.upgrade(storeID, month, packageID)),
    }
}

const mapStateToProps = (state) => ({
    upgradeReducer: { ...state.package }
});

export default connect(mapStateToProps, mapDispatchToProps)(Upgrade);