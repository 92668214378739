import * as types from "./types";
import * as commonTypes from "../common/types";

export default (state = {}, action) => {
  switch (action.type) {
    case commonTypes.ASYNC_START: {
      if (!types[action.waiting]) {
        return state;
      }

      return {
        ...state,
        loading: true,
        waiting: action.waiting,
        action: commonTypes.ASYNC_START,
      };
    }
    case commonTypes.ASYNC_END: {
      if (!types[action.done]) {
        return state;
      }

      return {
        ...state,
        waiting: null,
        loading: false,
        action: commonTypes.ASYNC_END,
      };
    }
    case types.LIST_DISCOUNT: {
      return {
        ...state,
        action: types.LIST_DISCOUNT,
        discounts: action.status ? action.payload.discounts : [],
        totalRecord: action.status ? action.payload.total_record : 0,
        totalPage: action.status ? action.payload.total_page : 0,
        status: action.status,
        message: action.message,
      };
    }
    case types.DELETE_DISCOUNTS: {
      return {
        ...state,
        action: types.DELETE_DISCOUNTS,
        status: action.status,
        message: action.message,
      };
    }
    case types.DELETE_DISCOUNT: {
      return {
        ...state,
        action: types.DELETE_DISCOUNT,
        status: action.status,
        message: action.message,
      };
    }
    case types.CREATE_DISCOUNT: {
      return {
        ...state,
        action: types.CREATE_DISCOUNT,
        status: action.status,
        message: action.message,
      };
    }
    case types.UPDATE_DISCOUNT: {
      return {
        ...state,
        action: types.UPDATE_DISCOUNT,
        status: action.status,
        message: action.message,
      };
    }
    case types.SHOW_DISCOUNT: {
      return {
        ...state,
        action: types.SHOW_DISCOUNT,
        discount: action.status ? action.payload.discount : {},
        status: action.status,
        message: action.message,
      };
    }
    case types.VALIDATE_DISCOUNT: {
      return {
        ...state,
        action: types.VALIDATE_DISCOUNT,
        discount_fee: action.status ? action.payload.discount_fee : undefined,
        variants: action.status ? action.payload.variants : [],
        status: action.status,
        message: action.message,
      };
    }
    case types.ACTIVE_DISCOUNT: {
      return {
        ...state,
        action: types.ACTIVE_DISCOUNT,
        status: action.status,
        message: action.message,
      };
    }
    case types.EXPIRE_DISCOUNT: {
      return {
        ...state,
        action: types.EXPIRE_DISCOUNT,
        status: action.status,
        message: action.message,
      };
    }
    case types.RELOAD_DISCOUNT: {
      return {
        ...state,
        action: types.RELOAD_DISCOUNT,
        discount: action.status ? action.payload.discount : {},
        status: action.status,
        message: action.message,
      };
    }
    case types.EXPORT_DISCOUNT: {
      return {
        ...state,
        action: types.EXPORT_DISCOUNT,
        status: action.status,
        message: action.message,
      };
    }
    default:
      return state;
  }
};
