import React from "react";
import PropTypes from "prop-types";
import { map, remove } from "lodash";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Modal from "../../../components/Modal";

import apiActions from "../../../redux/futures/api/actions";
import * as apiTypes from "../../../redux/futures/api/types";

import baseHelper from "../../../helpers/BaseHelper";
import ConfirmModal from "../../../components/ConfirmModal";

type Props = {};

class ModalSettingApi extends React.Component<Props> {
  static propTypes = {
    reload: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      third_parties: [],
      selected_api_key_index: null,
      selected_third_party: {},
    };
  }

  componentDidMount() {
    this.props.listThirdParties();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.apiReducer.action != nextProps.apiReducer.action) {
      if (nextProps.apiReducer.action == apiTypes.API_REVOKE_TOKEN) {
        if (nextProps.apiReducer.status) {
          window.LadiUI.toastCustom("success", "", nextProps.apiReducer.message);

          window.LadiUI.closeModal("confirm-revoke-permission");
          const thirdParties = remove(
            this.state.third_parties,
            (thirdParty) => thirdParty.api_key_id != this.state.selected_third_party.api_key_id
          );
          this.setState({
            third_parties: thirdParties,
          });
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.apiReducer.message, "OK");
        }
      }

      if (nextProps.apiReducer.action == apiTypes.API_LIST_THIRD_PARTY) {
        if (nextProps.apiReducer.status) {
          this.setState({
            third_parties: nextProps.apiReducer.third_parties,
          });
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.apiReducer.message, "OK");
        }
      }

      if (nextProps.apiReducer.action == apiTypes.API_RESET_API_KEY) {
        if (nextProps.apiReducer.status) {
          const thirdParties = this.state.third_parties;
          const index = this.state.selected_api_key_index;
          thirdParties[index] = {
            ...thirdParties[index],
            api_key: nextProps.apiReducer.api_key.api_key,
            updated_at: nextProps.apiReducer.api_key.updated_at,
            seen: true,
          };
          this.setState({
            third_parties: thirdParties,
          });
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.apiReducer.message, "OK");
        }
      }
    }
  }

  openModalConfirmRevoke = (selectedThirdParty) => {
    this.setState(
      {
        selected_third_party: selectedThirdParty,
      },
      () => {
        window.LadiUI.showModal("confirm-revoke-permission");
      }
    );
  };

  onClickRefreshApiKey = async (apiKey, index) => {
    this.setState({
      selected_api_key_index: index,
    });
    this.props.resetApiKey(apiKey.api_key_id);
  };

  onClickCopyApiKey = (item) => {
    navigator.clipboard.writeText(item.api_key);
    window.LadiUI.toastCustom("success", "", this.props.t("SETTINGS.COPIED_API_KEY"));
  };

  render() {
    const { t } = this.props;
    const isLoadingRevoke = this.props.apiReducer.loading && apiTypes.API_REVOKE_TOKEN == this.props.apiReducer.waiting;

    return (
      <div className="page-setting" id="page-setting-api" title={t("API.TITLE")}>
        <div className="block-title-action">
          <h3 className="title">Quản lý API</h3>
        </div>
        <div className="ladi-card">
          <table className="ladiui table text-left">
            <thead>
              <tr className="ladiui table-vertical header">
                <th scope="col" name="name">
                  {t("API.CLIENT_NAME")}
                </th>
                <th scope="col" name="created_at">
                  {t("COMMON.CREATED_AT")}
                </th>
                <th scope="col" name="created_at">
                  {t("COMMON.API_KEY")}
                </th>
              </tr>
            </thead>
            <tbody>
              {map(this.state.third_parties, (item, index) => {
                return (
                  <tr key={index} className="ladiui table-vertical main">
                    <td>{item.client_name}</td>
                    <td>{item.updated_at ? baseHelper.formatStrToDate(item.updated_at) : baseHelper.formatStrToDate(item.created_at)}</td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          border: "1px solid #F1F3F4",
                          padding: "6px 12px",
                        }}
                      >
                        {this.state.third_parties[index].seen ? item.api_key : baseHelper.maskText(item.api_key, 1)}
                        <ul className="flex">
                          <li className="mr-8">
                            <a
                              style={{ color: "#000" }}
                              onClick={() => {
                                this.state.third_parties[index].seen = !this.state.third_parties[index].seen;
                                this.setState({
                                  third_parties: this.state.third_parties,
                                });
                              }}
                            >
                              <i
                                className={this.state.third_parties[index].seen ? "ladi-icon icon-preview" : "ladi-icon icon-b-preview"}
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a style={{ color: "#000" }} onClick={() => this.onClickCopyApiKey(item)}>
                              <i className="ladi-icon icon-copy"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td className="text-right pd-0">
                      <div className="ladiui btn-group">
                        <div className="ladiui dropdown hide-mt ba-c">
                          <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                            <i className="ladiui icon icon-ldp-dot"></i>
                          </button>
                          <ul className="ladiui dropdown-menu r-0">
                            <li>
                              <a className="ladiui dropdown-item" onClick={() => this.onClickRefreshApiKey(item, index)}>
                                {t("ACTIONS.RESET_API_KEY")}
                              </a>
                            </li>
                            {this.state.third_parties.length > 1 && (
                              <li>
                                <a className="ladiui dropdown-item" onClick={() => this.openModalConfirmRevoke(item)}>
                                  {t("ACTIONS.REVOKE_PERMISSION")}
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <ConfirmModal
          id="confirm-revoke-permission"
          title={t("API.MSG_REVOKE_PERMISSION_TITLE")}
          content={t(
            "API.MSG_REVOKE_PERMISSION_CONTENT",
            this.state.selected_third_party.client_name ? this.state.selected_third_party.client_name : ""
          )}
          cancelText={t("ACTIONS.CANCEL")}
          okText={t("ACTIONS.DELETE")}
          onOk={() => this.props.revoke(this.state.selected_third_party.api_key_id)}
          isLoading={isLoadingRevoke}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    revoke: (apiKeyID) => dispatch(apiActions.revoke(apiKeyID)),
    resetApiKey: (apiKeyID) => dispatch(apiActions.resetApiKey(apiKeyID)),
    listThirdParties: () => dispatch(apiActions.list()),
  };
};

const mapStateToProps = (state) => ({
  apiReducer: { ...state.api },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation("translation", { withRef: true })(ModalSettingApi));
