import React, { useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import { cloneDeep, filter, map, compact, includes, size } from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import appConfig from "../../../config/app";

import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import Dropdown from "../../../components/Dropdown";

import * as formAccountTypes from "../../../redux/futures/form_account/types";
import formAccountActions from "../../../redux/futures/form_account/actions";
import baseHelper from "../../../helpers/BaseHelper";
import ModalConVerKit from "./ModalIntegrate/ModalConVerKit";
import ModalGoogleSheet from "./ModalIntegrate/ModalGoogleSheet";
import ModalGetResponse from "./ModalIntegrate/ModalGetResponse";
import ModalHubsPot from "./ModalIntegrate/ModalHubsPot";
import ModalActiveCampaign from "./ModalIntegrate/ModalActiveCampaign";
import ModalInfusion from "./ModalIntegrate/ModalInfusion";
import ModalMailChimp from "./ModalIntegrate/ModalMailChimp";
import ModalHaravan from "./ModalIntegrate/ModalHaravan";
import ModalSapo from "./ModalIntegrate/ModalSapo";
import ModalShopify from "./ModalIntegrate/ModalShopify";
import ModalNhanh from "./ModalIntegrate/ModalNhanh";
import ModalWordPress from "./ModalIntegrate/ModalWordPress";
import ModalKiotViet from "./ModalIntegrate/ModalKiotViet";

ModalFormAccount.propTypes = {
  mode: PropTypes.string,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  currentFormAccount: PropTypes.any,
};

export default function ModalFormAccount(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formAccountReducer = useSelector((state) => state.formAccount);
  const [selectAccountType, setSelectAccountType] = useState("");
  // const [selectAccountType, setSelectAccountType] = useState(props.currentFormAccount.type
  //     ? { CODE: props.currentFormAccount.type } : appConfig.FORM_TYPE.GOOGLE_SHEET);
  const [formAccount, setFormAccount] = useState(props.mode == appConfig.FORM_MODE.CREATE ? {} : props.currentFormAccount);
  const [textToggleInputType, setTextToggleInputType] = useState(false);
  const [keySearch, setKeySearch] = useState("");
  const [valueSearch, setValueSearch] = useState("");

  const [titleModal, setTitleModal] = useState(null);
  const [logoModal, setLogoModal] = useState(null);
  const [descriptionModal, setDescriptionModal] = useState(null);

  const inputRefs = new Set();

  useEffect(() => {
    window.LadiUI.init();
    window.LadiUI.customInit();
  });

  useEffect(() => {
    window.callBackEventInfusionSoft = (event) => {
      dispatch(
        formAccountActions.oauthAccess({
          code: event.data.code,
          type: appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE,
        })
      );
    };

    window.callBackEventGoogleSheet = (event) => {
      dispatch(
        formAccountActions.oauth2GoogleSheets({
          code: event.data.code,
          id: props.currentFormAccount.id,
        })
      );
    };
  }, []);

  useEffect(() => {
    if (formAccountReducer.action === formAccountTypes.INTEGRATE_OAUTH_ACCESS) {
      if (formAccountReducer.status) {
        const data = {
          ...formAccount,
          type: selectAccountType.CODE,
          access_token: formAccountReducer.account.access_token,
          refresh_token: formAccountReducer.account.refresh_token,
        };

        props.onSubmit(data);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_OAUTH2_GET_LINK) {
      if (formAccountReducer.status) {
        window.LadiUI.popupWindow(formAccountReducer.url, "sharer", window, 550, 600);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_GOOGLE_SHEETS) {
      if (formAccountReducer.status) {
        window.LadiUI.popupWindow(formAccountReducer.url, "sharer", window, 550, 600);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_HARAVAN) {
      if (formAccountReducer.status) {
        window.LadiUI.popupWindow(formAccountReducer.url, "sharer", window, 550, 600);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_SAPO) {
      if (formAccountReducer.status) {
        window.LadiUI.popupWindow(formAccountReducer.url, "sharer", window, 550, 600);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_SHOPIFY) {
      if (formAccountReducer.status) {
        window.LadiUI.popupWindow(formAccountReducer.url, "sharer", window, 550, 600);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_NHANH) {
      if (formAccountReducer.status) {
        window.LadiUI.popupWindow(formAccountReducer.url, "sharer", window, 550, 600);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    }
  }, [formAccountReducer]);

  const selectFormAccountType = (selectedItem) => {
    if (selectedItem.CODE != selectAccountType.CODE) {
      setSelectAccountType(selectedItem);
      setFormAccount({});
      setTextToggleInputType(false);
    }
  };

  const onChangeInput = (event) => {
    let accountData = cloneDeep(formAccount);
    accountData = {
      ...accountData,
      [event.target.name]: event.target.value,
    };
    setFormAccount(accountData);
  };

  const getLinkApiUrl = () => {
    if (selectAccountType.CODE == appConfig.FORM_TYPE.HARAVAN.CODE) {
      dispatch(formAccountActions.haravan());
      window.callBackEventHaravan = (event) => {
        dispatch(
          formAccountActions.createHaravan({
            ...formAccount,
            token: event.data.code,
            shop_id: event.data.shop,
            type: "HARAVAN",
          })
        );
      };
    }
    if (selectAccountType.CODE == appConfig.FORM_TYPE.SAPO.CODE) {
      dispatch(
        formAccountActions.sapo({
          store_url: formAccount.api_url,
        })
      );
      window.callBackEventSapo = (event) => {
        dispatch(
          formAccountActions.createSapo({
            ...formAccount,
            store_url: event.data.store,
            code: event.data.code,
          })
        );
      };
    }
    if (selectAccountType.CODE == appConfig.FORM_TYPE.SHOPIFY.CODE) {
      dispatch(
        formAccountActions.shopify({
          store_url: formAccount.api_url,
        })
      );
      window.callBackEventShopify = (event) => {
        // dispatch(formAccountActions.createShopify({
        //     ...formAccount,
        //     store_url: event.data.store,
        //     code: event.data.code,
        // }))
      };
    }

    if (selectAccountType.CODE == appConfig.FORM_TYPE.NHANH.CODE) {
      dispatch(formAccountActions.nhanh());
      window.callBackEventNhanh = (event) => {
        dispatch(
          formAccountActions.createNhanh({
            ...formAccount,
            code: event.data.code,
          })
        );
      };
    }
  };

  const submit = () => {
    let errors = [];

    if (inputRefs) {
      const inputsRef = compact(Array.from(inputRefs));
      map(inputsRef, (ref) => {
        if (ref.validate) {
          errors = errors.concat(ref.validate());
        }
      });

      errors = compact(errors);

      if (errors.length > 0) {
        const errorMessage = errors.join("<br/>");
        window.LadiUI.toastCustom("danger", "", errorMessage);
        return;
      }
    }

    const data = {
      ...formAccount,
      type: selectAccountType.CODE,
    };

    if (selectAccountType.CODE == appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE) {
      dispatch(formAccountActions.oauth2GetLink(data.type));
    } else if (selectAccountType.CODE == appConfig.FORM_TYPE.GOOGLE_SHEET.CODE) {
      dispatch(formAccountActions.googleSheets());
    } else {
      props.onSubmit(data);
    }
  };

  const getAccountTypeIcon = (type) => {
    return baseHelper.getAccountTypeIcon(type);
  };

  const handleSelectedAccountType = (item) => {
    if (item) {
      setSelectAccountType(item);
      setLogoModal(getAccountTypeIcon(item.CODE));
      setTitleModal(item.NAME);
      setDescriptionModal(item.DESCRIPTION);
    }
  };

  const handleResetValue = () => {
    setSelectAccountType("");
    setTitleModal(null);
    setLogoModal(null);
  };

  const renderForm = () => {
    let formContent = <></>;
    switch (selectAccountType.CODE) {
      case appConfig.FORM_TYPE.GOOGLE_SHEET.CODE:
        formContent = <ModalGoogleSheet />;
        break;
      case appConfig.FORM_TYPE.CONVERTKIT.CODE:
        formContent = (
          <ModalConVerKit
            inputRefs={inputRefs}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
            formAccount={formAccount}
            onChangeInput={onChangeInput}
          />
        );
        break;
      case appConfig.FORM_TYPE.GET_RESPONSE.CODE:
        formContent = (
          <ModalGetResponse
            inputRefs={inputRefs}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
            formAccount={formAccount}
            onChangeInput={onChangeInput}
          />
        );
        break;
      case appConfig.FORM_TYPE.HUBSPOT.CODE:
        formContent = (
          <ModalHubsPot
            inputRefs={inputRefs}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
            formAccount={formAccount}
            onChangeInput={onChangeInput}
          />
        );
        break;
      case appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.CODE:
        formContent = (
          <ModalActiveCampaign
            inputRefs={inputRefs}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
            formAccount={formAccount}
            onChangeInput={onChangeInput}
          />
        );
        break;
      case appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE:
        formContent = <ModalInfusion inputRefs={inputRefs} formAccount={formAccount} onChangeInput={onChangeInput} />;
        break;
      case appConfig.FORM_TYPE.MAIL_CHIMP.CODE:
        formContent = (
          <ModalMailChimp
            inputRefs={inputRefs}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
            formAccount={formAccount}
            onChangeInput={onChangeInput}
          />
        );
        break;
      case appConfig.FORM_TYPE.HARAVAN.CODE:
        formContent = <ModalHaravan inputRefs={inputRefs} formAccount={formAccount} onChangeInput={onChangeInput} />;
        break;
      case appConfig.FORM_TYPE.SAPO.CODE:
        formContent = <ModalSapo inputRefs={inputRefs} formAccount={formAccount} onChangeInput={onChangeInput} />;
        break;
      case appConfig.FORM_TYPE.SHOPIFY.CODE:
        formContent = <ModalShopify inputRefs={inputRefs} formAccount={formAccount} onChangeInput={onChangeInput} />;
        break;
      case appConfig.FORM_TYPE.WORDPRESS.CODE:
        formContent = (
          <ModalWordPress
            inputRefs={inputRefs}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
            formAccount={formAccount}
            onChangeInput={onChangeInput}
          />
        );
        break;
      case appConfig.FORM_TYPE.KIOTVIET.CODE:
        formContent = (
          <ModalKiotViet
            inputRefs={inputRefs}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
            formAccount={formAccount}
            onChangeInput={onChangeInput}
          />
        );
        break;
      case appConfig.FORM_TYPE.NHANH.CODE:
        formContent = <ModalNhanh inputRefs={inputRefs} formAccount={formAccount} onChangeInput={onChangeInput} />;
        break;
      default:
    }

    return formContent;
  };

  const getUrlHelpIntegrate = (type) => {
    let url = "";
    switch (type) {
      case appConfig.FORM_TYPE.GOOGLE_SHEET.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-google-sheet";
        break;
      case appConfig.FORM_TYPE.CONVERTKIT.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-convertkit";
        break;
      case appConfig.FORM_TYPE.GET_RESPONSE.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-getresponse";
        break;
      case appConfig.FORM_TYPE.HUBSPOT.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-hubspot";
        break;
      case appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-activecampaign";
        break;
      case appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-infusion-soft";
        break;
      case appConfig.FORM_TYPE.MAIL_CHIMP.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-mailchimp";
        break;
      case appConfig.FORM_TYPE.HARAVAN.CODE:
        url =
          "https://help.ladipage.vn/dong-bo-don-hang-sang-cac-nen-tang-website-thuong-mai-dien-tu-va-phan-mem-quan-ly-ban-hang/dong-bo-don-hang-tu-landing-page-sang-haravan";
        break;
      case appConfig.FORM_TYPE.SAPO.CODE:
        url =
          "https://help.ladipage.vn/dong-bo-don-hang-sang-cac-nen-tang-website-thuong-mai-dien-tu-va-phan-mem-quan-ly-ban-hang/dong-bo-don-hang-tu-landing-page-sang-sapo";
        break;
      case appConfig.FORM_TYPE.SHOPIFY.CODE:
        url =
          "https://help.ladipage.vn/dong-bo-don-hang-sang-cac-nen-tang-website-thuong-mai-dien-tu-va-phan-mem-quan-ly-ban-hang/dong-bo-don-hang-tu-landing-page-sang-shopify";
        break;
      case appConfig.FORM_TYPE.WORDPRESS.CODE:
        url =
          "https://help.ladipage.vn/dong-bo-don-hang-sang-cac-nen-tang-website-thuong-mai-dien-tu-va-phan-mem-quan-ly-ban-hang/dong-bo-don-hang-tu-landing-page-sang-wordpress";
        break;
      case appConfig.FORM_TYPE.KIOTVIET.CODE:
        url =
          "https://help.ladipage.vn/dong-bo-don-hang-sang-cac-nen-tang-website-thuong-mai-dien-tu-va-phan-mem-quan-ly-ban-hang/dong-bo-don-hang-tu-landing-page-sang-kiotviet";
        break;
      case appConfig.FORM_TYPE.NHANH.CODE:
        url =
          "https://help.ladipage.vn/dong-bo-don-hang-sang-cac-nen-tang-website-thuong-mai-dien-tu-va-phan-mem-quan-ly-ban-hang/dong-bo-don-hang-tu-landingpage-sang-nhanh.vn";
        break;
      default:
    }
    return url;
  };

  const handleSearchFormType = () => {
    setValueSearch(keySearch);
  };

  const handleChangeKeyPress = (event) => {
    if (event.key === "Enter") {
      setValueSearch(keySearch);
    }
  };

  return (
    <Modal
      id="modal-form-account"
      title={props.mode === appConfig.FORM_MODE.CREATE && !titleModal ? t("INTEGRATE.ADD_NEW_AFFILIATE_ACCOUNT") : titleModal}
      onOk={["HARAVAN", "SAPO", "SHOPIFY", "NHANH"].includes(selectAccountType.CODE) ? getLinkApiUrl : submit}
      onCancel={props.onCancel}
      isLoading={props.isLoading}
      resetAccountType={handleResetValue}
      isShowIconBack={selectAccountType ? true : false}
      visible={props.visible}
      descriptionModal={descriptionModal}
      isShowDescription={descriptionModal ? true : false}
      // bodyStyles={{ minHeight: '50vh' }}
      logo={logoModal}
      hasFooter={selectAccountType ? true : false}
      width={800}
      okText={t("ACTIONS.CONTINUE")}
      isCustomFooter={true}
      footer={
        <div className="ladiui-modal-footer">
          <div id="data-tooltip-guide" className="ladiui help-integrate">
            <span className="tooltip-icon flex mr-10" data-tooltip-position="right">
              {" "}
              <i className="ladi-icon icon-c-question" />
            </span>
            <span>
              {t("INTEGRATE.HELP_CONNECT_ACCOUNT")} &nbsp;
              <a href={getUrlHelpIntegrate(selectAccountType.CODE)} target="_blank">
                {selectAccountType.NAME}
              </a>
            </span>
          </div>
          <div className="flex">
            <button onClick={props.onCancel} className="ladiui btn btn-secondary mr-12">
              {t("ACTIONS.REMOVE")}
            </button>
            <button
              onClick={["HARAVAN", "SAPO", "SHOPIFY", "NHANH"].includes(selectAccountType.CODE) ? getLinkApiUrl : submit}
              className={`ladiui btn btn-primary ${props.isLoading ? "loader" : ""}`}
            >
              {selectAccountType.CODE == appConfig.FORM_TYPE.GOOGLE_SHEET.CODE ||
              selectAccountType.CODE == appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE
                ? t("ACTIONS.CONTINUE")
                : t("ACTIONS.ADD_LINK")}
            </button>
          </div>
        </div>
      }
    >
      <div>
        {!selectAccountType ? (
          <>
            <div className="ladiui-table-actions mb-16">
              <div className="action-item mr-0" style={{ width: "100%" }}>
                <div className="ladiui search-keyword" style={{ width: "100%" }}>
                  <input
                    type="text"
                    id="search"
                    placeholder={t("INTEGRATE.SEARCH_AFFILIATE_ACCOUNT_EVENT")}
                    name="search_name"
                    value={keySearch}
                    onChange={(e) => setKeySearch(e.target.value)}
                    className="ladiui form-control"
                    onKeyPress={(event) => handleChangeKeyPress(event)}
                  />
                  <i className="ladi-icon icon-zoom" onClick={() => handleSearchFormType()} />
                </div>
              </div>
            </div>
            <div className="list-integrate">
              {props.mode === appConfig.FORM_MODE.CREATE ? (
                map(appConfig.FORM_TYPE, (item, index) => {
                  if (item.NAME.toLowerCase().includes(valueSearch.toLocaleLowerCase())) {
                    return (
                      <div
                        key={index}
                        className="item-integrate"
                        style={
                          [appConfig.FORM_TYPE.SHOPIFY.CODE, appConfig.FORM_TYPE.WORDPRESS.CODE].includes(item.CODE)
                            ? { backgroundColor: "#f1f3f4" }
                            : {}
                        }
                        onClick={
                          [appConfig.FORM_TYPE.SHOPIFY.CODE, appConfig.FORM_TYPE.WORDPRESS.CODE].includes(item.CODE)
                            ? () => {}
                            : () => handleSelectedAccountType(item)
                        }
                      >
                        <div className="img-title">
                          <img src={getAccountTypeIcon(item.CODE)} className="icon-form-account" />
                          <span>{item.NAME}</span>
                        </div>
                        <div className="description">
                          <p>{item.DESCRIPTION}</p>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <div style={{ background: "#e8e8e8", borderRadius: "5px" }}>
                  <div style={{ padding: "10px" }}>
                    <div className="ladiui text">
                      <img src={getAccountTypeIcon(formAccount.type)} className="icon-form-account" />
                      {appConfig.FORM_TYPE[formAccount.type].NAME}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="box-app-integrate-content-setting">{renderForm()}</div>
          </>
        )}
      </div>
    </Modal>
  );
}
