import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useLocation } from "react-router-dom";
import { I18nContext, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import produce from "immer";
import Input from "../../components/Input";
import { map, includes, remove } from "lodash";

import courseActions from "../../redux/futures/course/actions";
import * as courseTypes from "../../redux/futures/course/types";

import customerActions from "../../redux/futures/customer/actions";
import * as customerTypes from "../../redux/futures/customer/types";

import baseHelper from "../../helpers/BaseHelper";

import appConfig from "../../config/app";

import LadiPagination from "../../components/LadiPagination";
import ModalMembershipKey from "./components/ModalMembershipKey";

import LadiDropdownMenu from "../../components/LadiDropdownMenu";
import ConfirmModal from "../../components/ConfirmModal";
import ModalReasonLock from "./components/ModalReasonLock";
import ModalOwnedCourse from "./components/ModalOwnedCourse";

import ModalAddAccount from "./components/ModalAddAccount";

import ModalShowPasswordToken from "./components/ModalShowPasswordToken";

const CDN = "https://w.ladicdn.com/";

const PageStudentManagement = forwardRef((props, ref) => {
    let { isShow, currentDataDomain, selectedLogo, urlPublished } = props;

    const location = useLocation();
    const queryStrings = new URLSearchParams(location.search);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const fileReducer = useSelector((state) => state.file);

    const courseReducer = useSelector((state) => state.course);

    const customerReducer = useSelector((state) => state.customer);

    const listAction = [
        {
            value: "LOCK",
            name: "Vô hiệu tài khoản",
        },
        {
            value: "UN_LOCK",
            name: "Kích hoạt tài khoản",
        },
    ];

    const [conditions, setConditions] = useState({
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
    });

    const [customers, setCustomers] = useState([]);

    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);

    const [isShowModalMembershipKey, setIsShowModalMembershipKey] = useState(false);

    const [keyWord, setKeyWord] = useState("");
    const [statusPaid, setStatusPaid] = useState(null);
    const [statusVerify, setStatusVerify] = useState(null);
    const [statusActive, setStatusActive] = useState(null);

    const [selectedReason, setSelectedReason] = useState("");
    const [isShowModalReasonLock, setIsShowModalReasonLock] = useState(false);

    const [isShowModalOwnedCourse, setIsShowModalOwnedCourse] = useState(false);
    const [ownedCourses, setOwnedCourses] = useState([]);

    const [isShowModalAddAccount, setIsShowModalAddAccount] = useState(false);
    const [valueSearchOwnedCourse, setValueSearchOwnedCourse] = useState("");

    const [isOaOa, setIsOaOa] = useState(false);

    const [isShowModalPassWord, setIsShowModalPassWord] = useState(false);

    const [dataModalShowPassword, setDataModalShowPassword] = useState({
        email: "",
        token: ""
    })


    const inputsRef = new Set();

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return {};
        },
    }));

    // const handleSubmitData = () => {
    //   props.handleSubmitData();
    // };

    useEffect(() => {
        let payload = {
            search: {},
            ...conditions,
            paged: conditions.page
        };
        dispatch(courseActions.courseCustomerList(payload));



        if (queryStrings.get('oanh_check')) {
            setIsOaOa(true);
        }

    }, []);

    useEffect(() => {
        let payload = {
            search: {
                keyword: keyWord,
                is_verify: statusVerify,
                customer_is_lock: statusActive,
                paid_status: statusPaid
            },
            ...conditions,
            paged: conditions.page
        };
        dispatch(courseActions.courseCustomerList(payload));
    }, [conditions]);

    useEffect(() => {
        let payload = {
            search: {
                keyword: keyWord,
                is_verify: statusVerify,
                customer_is_lock: statusActive,
                paid_status: statusPaid
            },
            ...conditions,
            paged: conditions.page
        };
        dispatch(courseActions.courseCustomerList(payload));
    }, [statusActive]);
    useEffect(() => {
        let payload = {
            search: {
                keyword: keyWord,
                is_verify: statusVerify,
                customer_is_lock: statusActive,
                paid_status: statusPaid
            },
            ...conditions,
            paged: conditions.page
        };
        dispatch(courseActions.courseCustomerList(payload));
    }, [statusPaid]);

    useEffect(() => {
        if (courseReducer.action == courseTypes.COURSE_CUSTOMER_LIST) {
            if (courseReducer.status) {
                if (courseReducer.customers) {
                    setCustomers([...courseReducer.customers]);
                }
            } else {
                window.LadiUI.showErrorMessage("Thông báo", courseReducer.message, "OK");
            }
        }

        if (courseReducer.action == courseTypes.CREATE_ACCOUNT) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom("success", "", courseReducer.message);
                setIsShowModalAddAccount(false)

                let payload = {
                    search: {},
                    ...conditions,
                };
                dispatch(courseActions.courseCustomerList(payload));
            } else {
                window.LadiUI.showErrorMessage("Thông báo", courseReducer.message, "OK");
            }
        }

        if (courseReducer.action == courseTypes.COURSE_CUSTOMER_LIST_OWNED_COURSE) {
            if (courseReducer.status) {
                if (courseReducer.customers) {
                    setOwnedCourses(courseReducer.customers)
                }
            } else {
                window.LadiUI.showErrorMessage("Thông báo", courseReducer.message, "OK");
            }
        }
        if (courseReducer.action == courseTypes.LOCK_CUSTOMER) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom("success", "", courseReducer.message);
                setIsShowModalMembershipKey(false);
                let inputCheck = Array.from(document.querySelectorAll("#checkInputItem"));
                for (let i = 0; i < inputCheck.length; i++) {
                    inputCheck[i].checked = false;
                }
                document.getElementById("checkAllItem").checked = false;
                setSelectedIds([]);

                let payload = {
                    search: {},
                    ...conditions,
                    paged: conditions.page
                };
                dispatch(courseActions.courseCustomerList(payload));
            } else {
                window.LadiUI.showErrorMessage("Thông báo", courseReducer.message, "OK");
            }
        }
        if (courseReducer.action == courseTypes.CANCEL_LOCK_CUSTOMER) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom("success", "", courseReducer.message);
                window.LadiUI.closeModal("confirm-enable-customer");

                let inputCheck = Array.from(document.querySelectorAll("#checkInputItem"));
                for (let i = 0; i < inputCheck.length; i++) {
                    inputCheck[i].checked = false;
                }
                document.getElementById("checkAllItem").checked = false;
                setSelectedIds([]);

                let payload = {
                    search: {},
                    ...conditions,
                    paged: conditions.page
                };
                dispatch(courseActions.courseCustomerList(payload));
            } else {
                window.LadiUI.showErrorMessage("Thông báo", courseReducer.message, "OK");
            }
        }
    }, [courseReducer]);

    useEffect(() => {
        if (customerReducer.action == customerTypes.VERIFY_ACCOUNT) {
            if (customerReducer.status) {
                window.LadiUI.toastCustom("success", "", customerReducer.message);
                // let payload = {
                //     search: {},
                //     ...conditions,
                // };
                // dispatch(courseActions.courseCustomerList(payload));
            } else {
                window.LadiUI.showErrorMessage("Thông báo", customerReducer.message, "OK");
            }
        }

        if (customerReducer.action == customerTypes.GET_PASSWORD_TOKEN) {
            if (customerReducer.status) {
                if (customerReducer.password_token) {
                    setDataModalShowPassword({
                        ...dataModalShowPassword,
                        token: customerReducer.password_token
                    })

                    setIsShowModalPassWord(true)
                }
            } else {
                window.LadiUI.showErrorMessage("Thông báo", customerReducer.message, "OK");
            }
        }
    }, [customerReducer]);

    const onChangeLimit = (option) => {
        setConditions({
            ...conditions,
            limit: option.value,
        });
    };

    const onPageChange = (pageNumber) => {
        setConditions({
            ...conditions,
            page: pageNumber,
        });
    };

    const checkItem = (event, customerID) => {
        const { checked } = event.target;

        let _selectedIds = [...selectedIds];

        if (checked) {
            if (!includes(_selectedIds, customerID)) {
                _selectedIds.push(customerID);
            }

            if (_selectedIds.length == customers.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            remove(_selectedIds, (selectedID) => selectedID == customerID);
        }

        setSelectedIds(_selectedIds);
    };

    const handleMembershipKey = (customerID) => {
        if (customerID) {
            setSelectedCustomerId(customerID);
            setIsShowModalMembershipKey(true);
        }
    };

    const handleCancelMembershipKey = (customerID) => {
        if (customerID) {
            setSelectedCustomerId(customerID);
            // setIsShowModalMembershipKey(true);
            window.LadiUI.showModal("confirm-enable-customer");
        }
    };

    const handleOpenModalReasonLock = (reason) => {
        if (reason) {
            setSelectedReason(reason);
            setIsShowModalReasonLock(true);
        }
    };

    const handleVerifyAccount = (customerID) => {
        if (customerID) {
            dispatch(
                customerActions.verifyAccount({
                    customer_id: customerID,
                })
            );
        }
    };

    const handleShowModalOwnedCourse = async (customerID) => {
        if (customerID) {
            setSelectedCustomerId(customerID);
            let payload = {
                search: {
                    customer_id: customerID
                },
                ...conditions,
                limit: 100
            };
            await dispatch(courseActions.courseCustomerListOwnedCourse(payload));

            setIsShowModalOwnedCourse(true)

        }
    }

    const handleGetPassword = (email) => {
        if (email) {
            setDataModalShowPassword({
                ...dataModalShowPassword,
                email: email
            })
            dispatch(customerActions.getPasswordToken(email))
        }
    }

    const fetchListCustomer = () => {
        return customers.map((item, index) => {
            return (
                <tr key={index} className="ladiui table-vertical main">
                    <td>
                        <input
                            id="checkInputItem"
                            type="checkbox"
                            onClick={(event) => checkItem(event, item.customer_id)}
                            className="ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle"
                        />
                    </td>
                    <td>{(item.first_name ? item.first_name : "") + (item.last_name ? item.last_name : "")}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    {/* <td>{item.is_verify && item.is_verify == 1 ? "Đã xác minh" : "Chưa xác minh"}</td> */}
                    <td>{item.buy_price && item.buy_price> 0 ? "Đã trả phí" : "Chưa trả phí"}</td>
                    <td>
                        {item.customer_is_lock == 0 ? (
                            <span className="flex">
                                <i className="ldicon-status-success mr-8" />
                                Đang hoạt động
                            </span>
                        ) : (
                            <span className="flex">
                                <i className="ldicon-status-error mr-8" />
                                Đã vô hiệu
                            </span>
                        )}
                    </td>
                    <td>{item.customer_created_at ? baseHelper.formatDateToStr(item.customer_created_at) : ""}</td>

                    <td className="text-right pd-0">
                        <div className="ladiui btn-group">
                            <div className="ladiui dropdown hide-mt ba-c">
                                <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                                    <i className="ladiui icon icon-ldp-dot"></i>
                                </button>
                                <ul className="ladiui dropdown-menu r-0">
                                    <li onClick={() => handleShowModalOwnedCourse(item.customer_id)}>
                                        <a className="ladiui dropdown-item space-nowrap">Khóa học đã sở hữu</a>
                                    </li>
                                    {!item.customer_is_lock || item.customer_is_lock == 0 ? (
                                        <li onClick={() => handleMembershipKey(item.customer_id)}>
                                            <a className="ladiui dropdown-item space-nowrap">Vô hiệu tài khoản</a>
                                        </li>
                                    ) : (
                                        <>
                                            <li onClick={() => handleOpenModalReasonLock(item.customer_lock_reason)}>
                                                <a className="ladiui dropdown-item space-nowrap">Lý do vô hiệu</a>
                                            </li>
                                            <li onClick={() => handleCancelMembershipKey(item.customer_id)}>
                                                <a className="ladiui dropdown-item space-nowrap">Kích hoạt tài khoản</a>
                                            </li>
                                        </>
                                    )}
                                    {!item.is_verify || item.is_verify == 0 ? (
                                        <li onClick={() => handleVerifyAccount(item.customer_id)}>
                                            <a className="ladiui dropdown-item space-nowrap">Xác minh tài khoản</a>
                                        </li>
                                    ) : (
                                        ""
                                    )}
                                    {
                                        isOaOa
                                        &&
                                        <li onClick={() => handleGetPassword(item.email)}>
                                            <a className="ladiui dropdown-item space-nowrap">Lấy mật khẩu</a>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const checkAllItem = (event) => {
        const { checked } = event.target;

        let selectedIDsCm = [];
        if (checked) {
            selectedIDsCm = map(customers, (customer) => customer.customer_id);

            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = false;
            });
        }

        setSelectedIds(selectedIDsCm);
    };

    const handleLockCustomer = (reason) => {
        let payload = {};
        if (selectedCustomerId) {
            payload = {
                customer_id: selectedCustomerId,
                reason: reason,
            };
        } else {
            payload = {
                customer_ids: selectedIds,
                reason: reason,
            };
        }

        dispatch(courseActions.lockCustomer(payload));
    };

    const handleCancelLockCustomer = () => {
        let payload = {};
        if (selectedCustomerId) {
            payload = {
                customer_id: selectedCustomerId,
            };
        } else {
            payload = {
                customer_ids: selectedIds,
            };
        }

        dispatch(courseActions.cancelLockCustomer(payload));
    };

    const eventSelectAction = (action, event) => {
        switch (action) {
            case "LOCK":
                setIsShowModalMembershipKey(true);
                break;
            case "UN_LOCK":
                // setIsShowModalMembershipKey(true);
                window.LadiUI.showModal("confirm-enable-customer");
                break;
            default:
        }
    };

    const onKeyDownInput = (event) => {
        if (event.key === "Enter") {
            let payload = {
                search: {
                    keyword: keyWord,
                    is_verify: statusVerify,
                    customer_is_lock: statusActive,
                    paid_status: statusPaid
                },
                ...conditions,
            };
            dispatch(courseActions.courseCustomerList(payload));
        }
    };

    const handleSearchCustomer = () => {
        let payload = {
            search: {
                keyword: keyWord,
                is_verify: statusVerify,
                customer_is_lock: statusActive,
                paid_status: statusPaid
            },
            ...conditions,
        };
        dispatch(courseActions.courseCustomerList(payload));
    };


    const onChangeSearchOwnedCourse = (e) => {
        let { value } = e.target;

        setValueSearchOwnedCourse(value)
    }

    const onKeyDownInputOwnedCourse = async (event) => {
        if (event.key === "Enter") {
            let payload = {
                search: {
                    customer_id: selectedCustomerId,
                    keyword: valueSearchOwnedCourse,
                },
                ...conditions,
            };
            await dispatch(courseActions.courseCustomerListOwnedCourse(payload));
        }
    };

    const handleSearchOwnedCourse = async () => {
        let payload = {
            search: {
                customer_id: selectedCustomerId,
                keyword: valueSearchOwnedCourse
            },
            ...conditions,
            limit: 100
        };
        await dispatch(courseActions.courseCustomerListOwnedCourse(payload));
    }

    return (
        <div className="page-setting" style={{ padding: "0 24px" }}>
            <div className="block-title-action">
                <div className="title">
                    <h3>Danh sách học viên</h3>
                </div>
            </div>
            <div className="setting-content-domain-course config-panel">
                <div className="ladiui-table-actions" style={{ justifyContent: "space-between" }}>
                    <div className="flex">
                        <div className="ladiui search-group mr-24">
                            <input
                                id="keyword_search"
                                className="ladiui search-field dropdown-toggle form-control search-width"
                                name="searchName"
                                placeholder="Tìm kiếm học viên"
                                aria-expanded="false"
                                value={keyWord}
                                onChange={(event) => {
                                    setKeyWord(event.target.value);
                                }}
                                onKeyDown={onKeyDownInput}
                            />
                            <i className="ladiui icon icon-search" onClick={() => handleSearchCustomer()}></i>
                        </div>
                        <div className="ladiui flex-row mr-24">
                            <div className="ladiui btn-group">
                                <div className="ladiui dropdown">
                                    <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown">
                                        <i className="ladiui icon"></i>
                                        <span className="ladiui dropdown-text">{"Loại tài khoản"}</span>
                                    </button>
                                    <ul className="ladiui dropdown-menu">
                                        {appConfig.LIST_STATUS_PAID.map((item) => (
                                            <li key={item.value} onClick={(e) => setStatusPaid(item.value)}>
                                                <a className="ladiui dropdown-item" href="#">
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="ladiui flex-row mr-24">
                            <div className="ladiui btn-group">
                                <div className="ladiui dropdown">
                                    <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown">
                                        <i className="ladiui icon"></i>
                                        <span className="ladiui dropdown-text">{"Trạng thái vô hiệu"}</span>
                                    </button>
                                    <ul className="ladiui dropdown-menu">
                                        {appConfig.LIST_STATUS_ACTIVE.map((item) => (
                                            <li key={item.value} onClick={(e) => setStatusActive(item.value)}>
                                                <a className="ladiui dropdown-item" href="#">
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="ladiui btn btn-primary btn-lg btn-open-modal"
                        data-toggle="modal"
                        onClick={() => setIsShowModalAddAccount(true)}
                    >
                        <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />
                        <div className="ladiui btn-custom-text ">{t("COURSES.ADD_STUDENT")}</div>
                    </button>
                </div>

                <table className={`ladiui table text-left`}>
                    <thead style={{ position: "relative" }}>
                        <tr className="ladiui table-vertical header">
                            <th scope="col" className="ladiui checkall">
                                <input
                                    id="checkAllItem"
                                    onClick={checkAllItem}
                                    type="checkbox"
                                    className="ladiui checkbox size-checkbox form-check-input-checkbox ladiui-checkall vertical-middle"
                                />
                            </th>
                            {/* <th scope="col" name="reference_no">
                Tên học viên
              </th> */}
                            {selectedIds && selectedIds.length <= 0 ? (
                                <th scope="col" name="full_name">
                                    Tên học viên
                                </th>
                            ) : (
                                <th>
                                    Tên học viên
                                    <LadiDropdownMenu
                                        labelDropdown={"Chọn hành động"}
                                        listProp={listAction}
                                        defaultTextProp={t("ACTIONS.TITLE")}
                                        hideLabel={true}
                                        cbProp={eventSelectAction}
                                        classSize="btn-sm "
                                        classWidth="w-180"
                                        classDropdown="w-180 action-record-table"
                                    />
                                </th>
                            )}
                            <th scope="col" name="full_name">
                                Email
                            </th>
                            <th scope="col" name="email" className="text-left">
                                Số điện thoại
                            </th>
                            <th scope="col" name="verify" className="text-left">
                                Loại tài khoản
                            </th>
                            <th scope="col" name="status" className="text-left">
                                Trạng thái
                            </th>
                            <th scope="col" name="phone" className="text-left">
                                Thời gian đăng ký
                            </th>
                            <th scope="col" />
                        </tr>
                    </thead>
                    <tbody>
                        {fetchListCustomer()}
                        {customers && customers.length <= 0 && (
                            <tr className="text-center">
                                <td colSpan="100%">{t("NO_DATA")}</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                {courseReducer.totalRecord && courseReducer.totalRecord > 0 ? (
                    <LadiPagination
                        conditionsProp={conditions}
                        listName={t("PAGINATION.ITEMS")}
                        message={""}
                        items={customers || []}
                        total={courseReducer.totalRecord}
                        limitAction={onChangeLimit}
                        pageAction={onPageChange}
                    />
                ) : (
                    ""
                )}
            </div>

            {isShowModalMembershipKey && (
                <ModalMembershipKey
                    onClose={() => setIsShowModalMembershipKey(false)}
                    isShow={isShowModalMembershipKey}
                    innerStyle={{ display: "none" }}
                    handleLockCustomer={handleLockCustomer}
                    // handleCancelLockCustomer={handleCancelLockCustomer}
                    isStudentCourse={false}
                />
            )}

            {isShowModalReasonLock && (
                <ModalReasonLock
                    onClose={() => setIsShowModalReasonLock(false)}
                    isShow={isShowModalReasonLock}
                    innerStyle={{ display: "none" }}
                    isStudentCourse={false}
                    selectedReason={selectedReason}
                />
            )}

            {isShowModalOwnedCourse && (
                <ModalOwnedCourse
                    onClose={() => setIsShowModalOwnedCourse(false)}
                    isShow={isShowModalOwnedCourse}
                    innerStyle={{ display: "none" }}
                    dataOwnedCourses={ownedCourses || []}
                    onKeyDownInputOwnedCourse={onKeyDownInputOwnedCourse}
                    onChangeSearchVariantInput={onChangeSearchOwnedCourse}
                    searchOwnedCourse={handleSearchOwnedCourse}
                    customerID={selectedCustomerId}
                />
            )}

            {isShowModalAddAccount && (
                <ModalAddAccount
                    onClose={() => setIsShowModalAddAccount(false)}
                    isShow={isShowModalAddAccount}
                    innerStyle={{ display: "none" }}
                    styleCustom={{ position: "unset" }}
                />
            )}

            {isShowModalPassWord && (
                <ModalShowPasswordToken
                    onClose={() => setIsShowModalPassWord(false)}
                    isShow={isShowModalPassWord}
                    dataPassword={dataModalShowPassword}
                    innerStyle={{ display: "none" }}
                    styleCustom={{ position: "unset" }}
                />
            )}

            <ConfirmModal
                id="confirm-enable-customer"
                title={"Kích hoạt tài khoản"}
                content={"Tài khoản được kích hoạt trở lại sẽ có thể đăng nhập vào trang khóa học. Bạn có muốn thực hiện hành động này?"}
                cancelText={t("ACTIONS.CANCEL")}
                okText={"Kích hoạt"}
                isBtnPrimary={true}
                onOk={handleCancelLockCustomer}
            // isLoading={this.state.loadingDelete}
            />
        </div>
    );
});
PageStudentManagement.propTypes = {};

export default PageStudentManagement;
