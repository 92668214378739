import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function ItemProduct(props) {
  const { t, i18n, onClick, info } = props;

  return (
    <Link
      className="item-product"
      to={{
        pathname: info.path,
        state: { fromDashboard: true },
        search: `?type=${info.search}`,
      }}
    >
      <div>
        <div className="flex mb-16">
          <img src={info.icon} alt="" />
          <span className="title-16 ml-12">{info.name}</span>
        </div>
        <div className="des-product">{info.des}</div>
      </div>
    </Link>
  );
}

export default withTranslation()(ItemProduct);
