const config = {
    ACCOUNT_UID: 'https://accounts.dev.ladiuid.com',
    API_UID_V2_0: 'https://api.dev.ladiuid.com/2.0',

    API_V2_0: 'https://api.dev.ladisales.com/2.0',
    BUILDER_LDP: 'builder.dev.ladipage.com',

    API_FILE_V2_0: 'https://files.dev.ladicdn.com/2.0',

    // Production Configs
    //API_V2_0: 'https://apiv4.sales.ldpform.net/2.0',
    //API_V4_0: 'https://apiv4.sales.ldpform.net/2.0',
    //API_UID_V2_0: 'https://api.ladiuid.com/2.0',
    //ACCOUNT_UID: 'https://accounts.ladiuid.com',
    //API_FILE_V2_0: 'https://files.ladicdn.com/2.0',
    //BUILDER_LDP: 'builder.ladipage.com',
};

const authEndpoint = {
    AUTH_SIGNIN: `${config.ACCOUNT_UID}/signin?callback_url=http://localhost:3000&app_code=ls`,
    AUTH_SIGNUP: `${config.ACCOUNT_UID}/signup?callback_url=http://localhost:3000&app_code=ls`,
    AUTH_SIGNOUT: `${config.ACCOUNT_UID}/signin?logout=1&callback_url=http://localhost:3000&app_code=ls`,
};

export default config;

export { authEndpoint };
