import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import baseHelper from "../../../../helpers/BaseHelper";
import Image from "../../../../components/Image";
import { map } from "lodash";

import discountActions from "../../../../redux/futures/discount/actions";
import * as discountTypes from "../../../../redux/futures/discount/types";
import { color } from "highcharts";
// eslint-disable-next-line max-lines-per-function
function ModalAddDiscount(props) {
  const { t, onClose, isShow, selectedDiscounts = [] } = props;
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();

  const discountReducer = useSelector((state) => state.discount);

  const [discounts, setDiscounts] = useState([]);

  const [valueSearch, setValueSearch] = useState("");

  useEffect(() => {
    if (discountReducer.action == discountTypes.LIST_DISCOUNT) {
      if (discountReducer.status) {
        if (discountReducer.discounts && discountReducer.discounts.length > 0) {
          setDiscounts(discountReducer.discounts);
        }
      }
    }
  }, [discountReducer]);

  useEffect(() => {
    let payload = {
      search: {
        rule_type: 1,
        is_show_web: true
      },
    };
    dispatch(discountActions.list(payload));
  }, []);

  // useEffect(() => {
  //   let payload = {
  //     search: {
  //       name: valueSearch,
  //       rule_type: 1,
  //     },
  //   };
  //   dispatch(discountActions.list(payload));
  // }, [valueSearch]);

  const handleSearch = (e) => {
    e.preventDefault();
    let payload = {
      search: {
        name: valueSearch,
        rule_type: 1,
        is_show_web: true
      },
    };
    dispatch(discountActions.list(payload));
  };

  const eventCancel = () => {
    onClose(false);
  };

  const getValueByType = (item) => {
    let symbol = " đ";
    let amount = 0;
    if (item.type == 1) {
      amount = item.value;
      symbol = " %";
    } else {
      amount = item.value;
      symbol = " đ";
    }

    return amount + symbol;
  };

  const onChangeSearchDiscountInput = (e) => {
    setValueSearch(e.target.value);
  };

  const handleEnterSearch = (event) => {
    if (event.key === "Enter") {
      let payload = {
        search: {
          name: valueSearch,
          rule_type: 1,
          is_show_web: true
        },
      };
      dispatch(discountActions.list(payload));
    }
  };

  const handleAddDiscount = (item) => {
    props.handleAddDiscount(item);
  };

  return (
    <div className={`ladiui modal ${isShow ? "show" : ""}`} id="homepage-add-discount-modal">
      <div className="ladiui modal-dialog modal-dialog-centered w-600" ref={wrapperRef}>
        <div className="ladiui modal-content ">
          <div className="ladiui modal-header ">
            <h2 className="ladiui modal-title ">Thêm khuyến mãi</h2>
            <button data-dismiss="modal" data-target="add-product" onClick={eventCancel} className="ladiui modal-close">
              <i className="ladiui icon icon-ldp-close"></i>
            </button>
          </div>
          <div className="ladiui modal-body">
            <div className="ladiui search-group">
              <input
                className="ladiui search-field dropdown-toggle form-control"
                value={valueSearch}
                placeholder={"Tìm kiếm mã khuyến mãi"}
                data-toggle="dropdown"
                onKeyPress={(e) => handleEnterSearch(e)}
                aria-expanded="false"
                onChange={(e) => onChangeSearchDiscountInput(e)}
              />
              <i className="ladiui icon icon-search" onClick={(e) => handleSearch(e)}></i>
            </div>

            {discounts && discounts.length > 0 && (
              <div className={`list-discounts mt-24`}>
                <table className="ladiui table text-left table-custom table-store-add-discount">
                  <thead>
                    <tr className="ladiui table-vertical header">
                      <th className="text-left">Mã khuyến mãi</th>
                      <th className="text-left">Hình thức</th>
                      <th className="text-left">Giá trị</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {map(discounts, (item, index) => {
                      let isSelected = selectedDiscounts.some((value) => {
                        return selectedDiscounts.some((value) => value.discount_id == item.discount_id);
                      });
                      return (
                        <tr className="ladiui table-vertical" key={index}>
                          <td>
                            <span>{item.code} </span>
                            {
                              item.status && item.status != "Active"
                              &&
                              <span style={{ color: "#D94F45", marginLeft: "4px" }}> {item.status == "Pending" ? " (Chưa áp dụng)" : " (Hết hạn)"} </span>
                            }
                          </td>
                          <td>{item.rule_type == 1 ? "Mã khuyến mãi" : "Chương trình khuyến mãi"}</td>
                          <td>{getValueByType(item)}</td>
                          <td className="text-right">
                            {isSelected ? (
                              <span style={{ color: "#d94f45" }}>{t("COMMON.SELECTED")}</span>
                            ) : (
                              <div className="add-discount" onClick={() => handleAddDiscount(item)}>
                                <button className="ladiui btn btn-outline-primary">Thêm</button>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <div className="ladiui modal-footer ">
            <div className="ladiui footer-modal-btn">
              <button
                type="button"
                data-dismiss="modal"
                data-target="add-product"
                className="ladiui btn btn-secondary bold"
                onClick={eventCancel}
              >
                {t("ACTIONS.DONE")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default withTranslation()(ModalAddDiscount);
