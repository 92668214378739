import React from 'react';
import PropTypes from 'prop-types';
import { find, remove, map } from 'lodash';
import { withTranslation } from 'react-i18next';
import Modal from './Modal';
import Input from './Input';
import { cloneDeep, compact } from 'lodash';
import baseHelper from './../helpers/BaseHelper';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import appConfig from '../config/app';

class ModalAddText extends React.Component {
    static propTypes = {
        onCancel: PropTypes.func,
        onOk: PropTypes.func,
        visible: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            name: props.dataSelect.name || '',
            content: props.dataSelect.content || '',
        };

        this.inputsRef = new Set();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.dataSelect != nextProps.dataSelect) {
            this.setState({
                name: nextProps.dataSelect.name || '',
                content: nextProps.dataSelect.content || '',
            });
        }
    }

    submit = () => {
        let { name, content } = this.state;
        let data = {
            name: name,
            content: content,
            type: 'TEXT',
        };
        this.props.onSubmit(data, this.props.indexData);
    };

    validate = () => {
        // Validate
        const { t } = this.props;
        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = errors.concat(this.locationRef.current.validate());

        errors = compact(errors);

        return errors;
    };
    onChangeInput = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value,
        });
    };

    fileChangedHandler = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                this.props.uploadProductImages(form);
            }
        }
    };

    onChangeContent = (value) => {
        this.setState({
            content: value,
        });
    };

    render() {
        const { t, visible, onCancel } = this.props;
        const { name, content } = this.state;
        return (
            <Modal id='modal-add-text' visible={visible} onCancel={onCancel} onOk={this.submit} title='Nội dung' width={600}>
                <div>
                    <div className='ladiui form-group'>
                        <label className='ladiui-label'>Tên gợi nhớ</label>
                        <Input
                            name='name'
                            validationName='nhập tên hiển thị'
                            placeholder='Nhập tóm tắt'
                            value={name}
                            onChange={this.onChangeInput}
                            ref={(ref) => this.inputsRef.add(ref)}
                            validations={{ isRequired: true }}
                        />
                    </div>
                    <div className='ladiui form-group mt-0' style={{ display: 'inline-block' }}>
                        <label className='ladiui-label'>Nội dung</label>
                        <Editor
                            tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                            licenseKey="gpl"
                            value={content}
                            init={{
                                height: 250,
                                plugins: 'preview fullpage autosave visualblocks fullscreen table lists link image code paste',
                                paste_as_text: true,
                                extended_valid_elements: 'a[data|href|class|style|target|rel]',
                                toolbar:
                                    'bold italic underline | formatselect fontsizeselect | lineheight | alignleft aligncenter alignright alignjustify | outdent indent | image |  numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat',
                                toolbar_drawer: 'sliding',
                                language_url: '../../../../language/tinymce-vi',
                                // valid_elements: '*[*]',
                            }}
                            onEditorChange={this.onChangeContent}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => { };

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation', { withRef: true })(ModalAddText)));
