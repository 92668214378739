import * as types from './types';
import * as commonTypes from '../common/types';
import { map } from 'lodash';

export default (state = { allTags: [] }, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            }
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            }
        }
        case types.ORDER_TAG_DELETE: {
            return {
                ...state,
                action: types.ORDER_TAG_DELETE,
                status: action.status,
                message: action.message,
            }
        }
        case types.SEARCH_ORDER_TAG: {
            return {
                ...state,
                action: types.SEARCH_ORDER_TAG,
                status: action.status,
                message: action.message,
                orderTags: action.status ? action.payload.order_tags : [],
            }
        }
        case types.LIST_RECENT_ORDER_TAG: {
            return {
                ...state,
                action: types.LIST_RECENT_ORDER_TAG,
                status: action.status,
                message: action.message,
                recentTags: action.status ? action.payload.order_tags : [],
            }
        }
        case types.LIST_ALL_ORDER_TAG: {
            return {
                ...state,
                action: types.LIST_ALL_ORDER_TAG,
                status: action.status,
                message: action.message,
                allTags: action.status ? action.payload.order_tags : [],
            }
        }
        case types.LIST_ORDER_TAG: {
            return {
                ...state,
                action: types.LIST_ORDER_TAG,
                status: action.status,
                message: action.message,
                allTags: action.status ? action.payload.order_tags : [],
                totalRecord: action.status ? action.payload.total_record : 0,
                totalPage: action.status ? action.payload.total_page : 0,
            }
        }
        case types.UPDATE_ORDER_TAG: {
            return {
                ...state,
                action: types.UPDATE_ORDER_TAG,
                status: action.status,
                message: action.message,
            }
        }
        case types.CREATE_ORDER_TAG: {
            return {
                ...state,
                action: types.CREATE_ORDER_TAG,
                status: action.status,
                message: action.message,
            }
        }
        default:
            return state;
    }
}

