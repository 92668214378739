import React from "react";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";

const TRIGGER_TYPE = {
  click: "click",
  hover: "hover",
};

type Props = {};

class Popover extends React.Component<Props> {
  static propTypes = {
    wrapperProps: PropTypes.object,
    wrapperPopoverProps: PropTypes.object,
    content: PropTypes.any,
    trigger: PropTypes.string,
    onShow: PropTypes.func,
  };

  static defaultProps = {
    wrapperProps: {
      style: {
        display: "relative",
      },
    },
    wrapperPopoverProps: {
      style: {},
    },
    trigger: "click",
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };

    this.wrapperRef = React.createRef();
  }

  toggle = () => {
    this.setState(
      {
        visible: !this.state.visible,
      },
      () => {
        if (this.state.visible && this.props.onShow) {
          this.props.onShow();
        }
      }
    );
  };

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleClickOutside = () => {
    this.hide();
  };

  renderChildren = () => {
    if (typeof this.props.children === "string") {
      switch (this.props.trigger) {
        case TRIGGER_TYPE.click:
          return <span onClick={this.toggle}>{this.props.children}</span>;
        default:
          break;
      }
    }

    switch (this.props.trigger) {
      case TRIGGER_TYPE.click:
        return React.cloneElement(this.props.children, {
          onClick: this.toggle,
        });
      default:
        break;
    }

    return null;
  };

  renderContent = () => {
    if (!this.state.visible) {
      return null;
    }
    return React.cloneElement(this.props.content);
  };

  render() {
    let left = 0;

    return (
      <div ref={this.wrapperRef} {...this.props.wrapperProps}>
        {this.renderChildren()}
        {this.state.visible && (
          <div
            {...this.props.wrapperPopoverProps}
            style={
              this.props.styleCustom
                ? { ...this.props.styleCustom }
                : { position: "absolute", top: 30, left: left, ...this.props.wrapperPopoverProps.style }
            }
          >
            {this.renderContent()}
          </div>
        )}
      </div>
    );
  }
}
export default onClickOutside(Popover);
