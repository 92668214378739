/** @format */

import * as types from "./types";
import * as commonTypes from "../common/types";

export default (state = {}, action) => {
  switch (action.type) {
    case commonTypes.ASYNC_START: {
      if (!types[action.waiting]) {
        return state;
      }

      return {
        ...state,
        loading: true,
        waiting: action.waiting,
        done: null,
        action: commonTypes.ASYNC_START,
      };
    }
    case commonTypes.ASYNC_END: {
      if (!types[action.done]) {
        return state;
      }

      return {
        ...state,
        waiting: null,
        done: action.done,
        loading: false,
        action: commonTypes.ASYNC_END,
      };
    }
    case types.LIST_PRODUCT: {
      return {
        ...state,
        action: types.LIST_PRODUCT,
        products: action.status ? action.payload.products : [],
        totalRecord: action.status ? action.payload.total_record : 0,
        totalPage: action.status ? action.payload.total_page : 0,
        status: action.status,
        message: action.message,
      };
    }
    case types.DELETE_PRODUCTS: {
      return {
        ...state,
        action: types.DELETE_PRODUCTS,
        status: action.status,
        message: action.message,
      };
    }
    case types.DELETE_PRODUCT: {
      return {
        ...state,
        action: types.DELETE_PRODUCT,
        status: action.status,
        message: action.message,
      };
    }
    case types.CREATE_PRODUCT: {
      return {
        ...state,
        action: types.CREATE_PRODUCT,
        status: action.status,
        message: action.message,
        product: action.status ? action.payload.product : {},
      };
    }
    case types.UPDATE_PRODUCT: {
      return {
        ...state,
        action: types.UPDATE_PRODUCT,
        status: action.status,
        message: action.message,
      };
    }
    case types.SHOW_PRODUCT: {
      return {
        ...state,
        action: types.SHOW_PRODUCT,
        product: action.status ? action.payload.product : {},
        status: action.status,
        message: action.message,
      };
    }
    case types.RELOAD_PRODUCT: {
      return {
        ...state,
        action: types.RELOAD_PRODUCT,
        product: action.status ? action.payload.product : {},
        status: action.status,
        message: action.message,
      };
    }
    case types.COPY_PRODUCT: {
      return {
        ...state,
        action: types.COPY_PRODUCT,
        product: action.status ? action.payload.product : {},
        status: action.status,
        message: action.message,
      };
    }
    case types.SEARCH_PRODUCT: {
      return {
        ...state,
        action: types.SEARCH_PRODUCT,
        products: action.status ? action.payload.products : [],
        status: action.status,
        message: action.message,
      };
    }
    case types.LIST_ALL_CUSTOM_FIELD: {
      return {
        ...state,
        action: types.LIST_ALL_CUSTOM_FIELD,
        customFields: action.payload.custom_fields,
        status: action.status,
        message: action.message,
      };
    }
    case types.UPDATE_CUSTOM_FIELD_BY_ORDER: {
      return {
        ...state,
        action: types.UPDATE_CUSTOM_FIELD_BY_ORDER,
        status: action.status,
        message: action.message,
      };
    }
    case types.IMPORT_PRODUCT: {
      return {
        ...state,
        action: types.IMPORT_PRODUCT,
        status: action.status,
        message: action.message,
      };
    }
    case types.EXPORT_PRODUCT: {
      return {
        ...state,
        action: types.EXPORT_PRODUCT,
        status: action.status,
        message: action.message,
      };
    }
    case types.IMPORT_EVENT: {
      return {
        ...state,
        action: types.IMPORT_EVENT,
        status: action.status,
        message: action.message,
      };
    }
    case types.IMPORT_SERVICE: {
      return {
        ...state,
        action: types.IMPORT_SERVICE,
        status: action.status,
        message: action.message,
      };
    }
    default:
      return state;
  }
};
