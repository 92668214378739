import React from "react";
import PropTypes from "prop-types";
import appConfig from "../../../../config/app";
import Input from "../../../../components/Input";
import Popover from "../../../../components/Popover";
import { cloneDeep } from "lodash";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import NumberInput from "../../../../components/NumberInput";

class PopoverDiscount extends React.Component {
  static propTypes = {
    discount: PropTypes.object,
    apply: PropTypes.func,
    remove: PropTypes.func,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      discount: {
        type: appConfig.DISCOUNT.TYPE.FIXED.value,
        value: "",
        note: "",
      },
      disabledApply: false,
    };

    this.initialState = cloneDeep(this.state);
    this.popoverRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }
  }

  onChangeDiscountValue = (event) => {
    this.setState(
      {
        discount: {
          ...this.state.discount,
          value: event.target.value,
        },
      },
      () => {
        this.checkDisabledApply();
      }
    );
  };

  checkDisabledApply = () => {
    if (this.state.discount.type == appConfig.DISCOUNT.TYPE.PERCENT.value && this.state.discount.value > 100) {
      this.setState({
        disabledApply: true,
      });
    } else {
      this.setState({
        disabledApply: false,
      });
    }
  };

  onChangeDiscountType = (event, type) => {
    this.setState(
      {
        discount: {
          ...this.state.discount,
          type,
        },
      },
      () => {
        this.checkDisabledApply();
      }
    );
  };

  onChangeDiscountReason = (event) => {
    const { discount } = this.state;
    discount[event.target.name] = event.target.value;
    this.setState({
      discount,
    });
  };

  onShow = () => {
    if (this.props.discount) {
      this.setState({
        discount: cloneDeep(this.props.discount),
      });
    } else {
      this.setState({
        ...cloneDeep(this.initialState),
      });
    }
  };

  apply = () => {
    this.props.apply(this.state.discount);
    this.popoverRef.current.instanceRef.hide();
  };

  remove = () => {
    this.props.remove();
    this.popoverRef.current.instanceRef.hide();
  };

  render() {
    const { t } = this.props;
    const { discount } = this.state;
    const isActivePercent = discount.type == appConfig.DISCOUNT.TYPE.PERCENT.value;
    const { currency_symbol: currencySymbol, currency_code: currencyCode } = this.props.store.userInfo.currentStore;

    return (
      <Popover
        ref={this.popoverRef}
        wrapperProps={{ className: "price-option" }}
        wrapperPopoverProps={{ className: "price-box" }}
        onShow={this.onShow}
        content={
          <div>
            <div className="ladiui-input-group" style={{ flexWrap: "nowrap", justifyContent: "end" }}>
              {discount.type == appConfig.DISCOUNT.TYPE.FIXED.value && (
                <NumberInput
                  className="w-180"
                  prefix={currencySymbol}
                  value={discount.value}
                  onChange={this.onChangeDiscountValue}
                  isDiscountType={appConfig.DISCOUNT.TYPE.FIXED.value}
                />
              )}
              {discount.type == appConfig.DISCOUNT.TYPE.PERCENT.value && (
                <NumberInput
                  name="value"
                  min={0}
                  max={100}
                  value={discount.value}
                  onChange={(event) => this.onChangeDiscountValue(event)}
                  isDiscountType={appConfig.DISCOUNT.TYPE.PERCENT.value}
                />
              )}

              <div className="ladiui-input-group-prepend">
                <button
                  onClick={(event) => {
                    this.onChangeDiscountType(event, appConfig.DISCOUNT.TYPE.PERCENT.value);
                  }}
                  type="button"
                  className={`discount-percent ladiui-input-group-text ladiui no-radius button secondary discount-button ${
                    isActivePercent ? "discount-button-active" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  %
                </button>
              </div>
              <div className="ladiui-input-group-prepend">
                <button
                  onClick={(event) => {
                    this.onChangeDiscountType(event, appConfig.DISCOUNT.TYPE.FIXED.value);
                  }}
                  type="button"
                  className={`discount-fixed ladiui-input-group-text ladiui button secondary discount-button ${
                    !isActivePercent ? "discount-button-active" : ""
                  }`}
                  style={{ cursor: "pointer", border: "1px solid #ebedf2" }}
                >
                  đ
                </button>
              </div>
            </div>

            <div className="discount-reason">
              <label className="ladiui-label">{t("COMMON.REASON")}</label>
              <Input type="text" name="note" value={discount.note} onChange={this.onChangeDiscountReason} />
            </div>

            <div className="flex-end">
              <button className="ladiui btn btn-secondary btn-sm  mr-8" data-dissmiss="discount-popup" onClick={this.remove}>
                {" "}
                {t("ACTIONS.REMOVE")}
              </button>

              <button
                className={`ladiui btn btn-primary btn-sm  ${this.state.disabledApply ? "disabled" : ""}`}
                disabled={this.state.disabledApply}
                data-dissmiss="discount-popup"
                onClick={this.apply}
              >
                {" "}
                {t("ACTIONS.APPLY")}
              </button>
            </div>
          </div>
        }
      >
        {this.props.children}
      </Popover>
    );
  }
}

const mapStateToProps = (state) => ({
  store: { ...state.store },
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(withTranslation()(PopoverDiscount));
