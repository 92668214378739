import React from "react";
import PropTypes from "prop-types";
import { find, compact, map, includes, remove, keys } from "lodash";
import * as staffTypes from "../../../redux/futures/staff/types";
import { connect } from "react-redux";
import Input from "../../../components/Input";
import appConfig from "../../../config/app";
import baseHelper from "../../../helpers/BaseHelper";
import { withTranslation } from "react-i18next";
import Modal from "../../../components/Modal";
import ConfirmModal from "../../../components/ConfirmModal";

import staffActions from "../../../redux/futures/staff/actions";
import { pick } from "lodash";

class ModalSettingAccount extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    // staffs: PropTypes.array,
    // userScopes: PropTypes.object.isRequired,
    remove: PropTypes.func,
    isLoadingRemove: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedStaff: {
        email: "",
        role: appConfig.ROLE.STAFF.CODE,
        status: appConfig.STAFF_STATUS.ACTIVE.CODE,
      },
      mode: null,
      isShowModalAccount: false,
      isShowModalScope: false,
      selectedPermission: true,
      staffs: [],
      userScopes: {},
    };

    this.inputsRef = new Set();
  }

  componentDidMount() {
    this.props.listStaffs();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.staffReducer.action != nextProps.staffReducer.action) {
      if (staffTypes.LIST_STAFFS == nextProps.staffReducer.action) {
        if (nextProps.staffReducer.status) {
          this.setState({
            userScopes: nextProps.staffReducer.userScopes,
            staffs: nextProps.staffReducer.staffs,
          });
        }
      }
      if (staffTypes.CREATE_STAFF == nextProps.staffReducer.action) {
        if (nextProps.staffReducer.status) {
          window.LadiUI.toastCustom("success", "", nextProps.staffReducer.message);
          this.setState({
            isShowModalAccount: false,
          });
          setTimeout(() => {
            this.props.listStaffs();
          }, 1);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.staffReducer.message, "OK");
        }
      }
      if (staffTypes.UPDATE_STAFF == nextProps.staffReducer.action) {
        if (nextProps.staffReducer.status) {
          window.LadiUI.toastCustom("success", "", nextProps.staffReducer.message);
          this.setState({
            isShowModalAccount: false,
          });
          this.props.listStaffs();
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.staffReducer.message, "OK");
        }
      }
      if (staffTypes.REMOVE_STAFF == nextProps.staffReducer.action) {
        if (nextProps.staffReducer.status) {
          window.LadiUI.toastCustom("success", "", nextProps.staffReducer.message);
          this.props.listStaffs();
          window.LadiUI.closeModal("confirm-remove-staff");
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.staffReducer.message, "OK");
        }
      }
    }
  }

  componentDidUpdate() {
    window.LadiUI.init();
  }

  onChangeInput = (event) => {
    this.setState({
      selectedStaff: {
        ...this.state.selectedStaff,
        [event.target.name]: event.target.value,
      },
    });
  };

  selectRole = (role) => {
    // if (this.state.mode == appConfig.FORM_MODE.CREATE || this.props.store.userInfo.currentStore.pkg_name == appConfig.PACKAGE_NAME.STARTER) {
    if (this.state.mode == appConfig.FORM_MODE.CREATE) {
      const defaultScopes = this.getDefaultScopesByRole(role);
      this.setState({
        selectedStaff: {
          ...this.state.selectedStaff,
          scopes: defaultScopes,
          role,
        },
      });
    } else {
      this.setState({
        selectedStaff: {
          ...this.state.selectedStaff,
          role,
        },
      });
    }
  };

  getRoleNameByStatus = (status) => {
    const matched = find(appConfig.ROLE, (item) => item.CODE == status);
    if (matched) {
      return matched.NAME;
    }
  };

  getStatusName = (status) => {
    const matched = find(appConfig.STAFF_STATUS, (item) => item.CODE == status);
    if (matched) {
      return matched.NAME;
    }
  };

  getRoleName = (role) => {
    const matched = find(appConfig.ROLE, (item) => item.CODE == role);
    if (matched) {
      return matched.NAME;
    }
  };

  openModalDelete = (selectedStaff) => {
    this.setState({
      selectedStaff,
    });

    window.LadiUI.showModal("confirm-remove-staff");
  };

  openModalCreate = () => {
    const defaultScopes = this.getDefaultScopesByRole(appConfig.ROLE.STAFF.CODE);

    this.setState({
      selectedStaff: {
        email: "",
        role: appConfig.ROLE.STAFF.CODE,
        status: appConfig.STAFF_STATUS.ACTIVE.CODE,
        scopes: defaultScopes,
      },
      isShowModalAccount: true,
      mode: appConfig.FORM_MODE.CREATE,
    });
  };

  getDefaultScopesByRole = (role) => {
    let defaultScopes = {};
    map(this.state.userScopes[role], (scope, key) => {
      let mark = false;
      if (scope.default) {
        defaultScopes[key] = true;
        mark = true;
      } else {
        const groups = scope.groups || {};

        map(groups, (group, _key) => {
          if (group.default) {
            if (!defaultScopes[key]) {
              defaultScopes[key] = [];
            }

            defaultScopes[key].push(_key);
            mark = true;
          }
        });
      }

      if (!mark) {
        defaultScopes[key] = false;
      }
    });

    return defaultScopes;
  };

  openModalEdit = (selectedStaff) => {
    const scopes = selectedStaff.scopes;

    map(this.state.userScopes[selectedStaff.role], (value, key) => {
      if (!Object.prototype.hasOwnProperty.call(scopes, key)) {
        scopes[key] = false;
      }
    });

    this.setState({
      selectedStaff,
      isShowModalAccount: true,
      mode: appConfig.FORM_MODE.EDIT,
    });
  };

  hideModal = () => {
    this.setState({
      selectedStaff: {
        email: "",
        role: appConfig.ROLE.STAFF.CODE,
        status: appConfig.STAFF_STATUS.ACTIVE.CODE,
      },
      isShowModalAccount: false,
      mode: null,
    });
  };

  onChangeStatus = (status) => {
    this.setState({
      selectedStaff: {
        ...this.state.selectedStaff,
        status,
      },
    });
  };

  onChangeScope = (scope) => {
    const { selectedStaff } = this.state;
    const groups = selectedStaff.scopes[scope];

    this.setState({
      selectedStaff: {
        ...selectedStaff,
        scopes: {
          ...selectedStaff.scopes,
          [scope]: groups == true ? false : true,
        },
      },
    });
  };

  onChangeGroup = (scope, group) => {
    const { selectedStaff } = this.state;
    const userScopes = this.state.userScopes[selectedStaff.role];
    const totalGroup = keys(userScopes[scope].groups).length;
    let groups = selectedStaff.scopes[scope];

    if (groups == true) {
      selectedStaff.scopes[scope] = keys(userScopes[scope].groups);
      groups = selectedStaff.scopes[scope];
    }

    if (includes(groups, group)) {
      remove(groups, (item) => item == group);
    } else {
      if (!Array.isArray(groups)) {
        selectedStaff.scopes[scope] = [];
        groups = selectedStaff.scopes[scope];
      }
      groups.push(group);
    }

    if (groups.length == totalGroup) {
      selectedStaff.scopes[scope] = true;
    }

    this.setState({
      selectedStaff,
    });
  };

  submit = () => {
    // Validate
    let errors = [];
    const inputsRef = compact(Array.from(this.inputsRef));
    map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });

    errors = compact(errors);
    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      window.LadiUI.toastCustom("danger", "", errorMessage);
      return;
    }

    this.handleSubmitData(this.state.selectedStaff, this.state.mode);
  };

  handleSubmitData = (staff, mode) => {
    if (mode == appConfig.FORM_MODE.CREATE) {
      this.props.createStaff(staff);
    } else if (mode == appConfig.FORM_MODE.EDIT) {
      staff = pick(staff, ["staff_id", "scopes", "status", "role"]);

      this.props.updateStaff(staff);
    }
  };

  render() {
    const { t } = this.props;
    const { selectedStaff, mode, isShowModalAccount } = this.state;
    const { staffs } = this.state;
    const userScopes = this.state.userScopes[selectedStaff.role];

    let listScope = {};
    if (userScopes) {
      map(userScopes, (item) => {
        if (item.groups) {
          map(item.groups, (scope, key) => {
            if (!listScope[key]) {
              listScope[key] = scope.title;
            }
          });
        }
      });
    }

    const { role, scopes, email, currentStore } = this.props.store.userInfo;

    const hasCreate = baseHelper.checkScope(role, scopes, "account", "create");
    const hasUpdate = baseHelper.checkScope(role, scopes, "account", "update");
    const hasDelete = baseHelper.checkScope(role, scopes, "account", "delete");

    return (
      <div id="page-setting-staff" className="page-setting">
        <div className="ladi-card account-table">
          <div className="block-title-action mb-12">
            <h3 className="title">Tài khoản</h3>
            <div className="ladiui actions">
              {hasCreate && (
                <div className="action-item btn-action">
                  <button type="button" className="ladiui btn btn-primary btn-sm " onClick={this.openModalCreate}>
                    <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />

                    {t("ACCOUNTS.INVITE")}
                  </button>
                </div>
              )}
            </div>
          </div>

          <table className="ladiui table text-left">
            <thead>
              <tr className="ladiui table-vertical header">
                <th scope="col" name="name">
                  {t("COMMON.FULL_NAME")}
                </th>
                <th scope="col" name="email">
                  {t("COMMON.EMAIL")}
                </th>
                <th scope="col">{t("STATUS.TITLE")}</th>
                <th scope="col">{t("ACCOUNTS.ROLE")}</th>
                <th scope="col" name="created_at">
                  {t("COMMON.CREATED_AT")}
                </th>
              </tr>
            </thead>
            <tbody>
              {map(staffs, (item, index) => (
                <tr className="ladiui table-vertical main" key={index}>
                  <td>{`${baseHelper.getText(item.last_name)} ${baseHelper.getText(item.first_name)}`}</td>
                  <td>{item.email}</td>
                  <td>{this.getStatusName(item.status)}</td>
                  <td>{this.getRoleNameByStatus(item.role)}</td>
                  <td>{baseHelper.formatStrToDate(item.created_at, appConfig.DEFAULT_FORMAT_DATE)}</td>
                  <td className="text-right pd-0">
                    {item.role != appConfig.ROLE.OWNER.CODE && item.email != email && (hasDelete || hasUpdate) && (
                      <div className="ladiui btn-group">
                        <div className="ladiui dropdown hide-mt ba-c">
                          <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                            <i className="ladiui icon icon-ldp-dot"></i>
                          </button>
                          <ul className="ladiui dropdown-menu r-0">
                            {hasUpdate && (
                              <li>
                                <a className="ladiui dropdown-item" onClick={() => this.openModalEdit(item)}>
                                  {t("ACTIONS.EDIT")}
                                </a>
                              </li>
                            )}
                            {hasDelete && (
                              <li>
                                <a className="ladiui dropdown-item" onClick={() => this.openModalDelete(item)}>
                                  {t("ACTIONS.DELETE")}
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {role == appConfig.ROLE.OWNER.CODE && (
          <ConfirmModal
            id="confirm-remove-staff"
            title={t("ACCOUNTS.MSG_ACCOUNT_TITLE_DELETE")}
            content={t("ACCOUNTS.MSG_ACCOUNT_CONTENT_DELETE", selectedStaff.email)}
            cancelText={t("ACTIONS.CANCEL")}
            okText={t("ACTIONS.DELETE")}
            onOk={() => this.props.removeStaff(selectedStaff.staff_id)}
            isLoading={this.props.isLoadingRemove}
          />
        )}

        {isShowModalAccount && (
          <Modal
            id="modal-edit-account"
            title={mode == appConfig.FORM_MODE.CREATE ? t("ACCOUNTS.INVITE") : t("ACCOUNTS.EDIT")}
            onOk={this.submit}
            onCancel={this.hideModal}
            isLoading={this.props.isLoading}
            visible={isShowModalAccount}
            bodyStyles={{ minHeight: "40vh" }}
            width={1200}
          >
            <div className="ladiui-row block">
              <div className="col-50">
                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("COMMON.EMAIL")}</label>
                  <Input
                    ref={(ref) => this.inputsRef.add(ref)}
                    name="email"
                    validationName={t("COMMON.EMAIL")}
                    placeholder={t("COMMON.EMAIL")}
                    value={selectedStaff.email}
                    onChange={this.onChangeInput}
                    validations={{ isRequired: true, email: true }}
                    disabled={mode == appConfig.FORM_MODE.EDIT}
                  />
                </div>
              </div>

              <div className="col-50">
                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("ACCOUNTS.ROLE")}</label>

                  <div className="ladiui dropdown" style={{ width: "100%" }}>
                    <button className="ladiui btn btn-outline-light dropdown-toggle" data-toggle="dropdown" style={{ width: "100%" }}>
                      {this.getRoleName(selectedStaff.role)}
                    </button>
                    <ul className="ladiui dropdown-menu custom-form-dynamic" style={{ width: "100%" }}>
                      {map(appConfig.ROLE, (item, index) => {
                        if (item.CODE != appConfig.ROLE.OWNER.CODE) {
                          return (
                            <li key={index} onClick={() => this.selectRole(item.CODE)}>
                              <span className="ladiui dropdown-item">{item.NAME}</span>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="ladiui radio-check-status">
              <div className="ladiui form-group form-check-inputs">
                <label className="ladiui-label" style={{ marginBottom: "17px" }}>
                  {t("STATUS.TITLE")}
                </label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "80px",
                    }}
                  >
                    <input
                      id="status-active"
                      type="radio"
                      name="radio-group"
                      className="ladiui form-check-input"
                      onChange={() => {
                        this.onChangeStatus(appConfig.STAFF_STATUS.ACTIVE.CODE);
                      }}
                      checked={selectedStaff.status == appConfig.STAFF_STATUS.ACTIVE.CODE}
                    />
                    <label htmlFor="status-active">{t("STAFF_STATUS.ACTIVE")}</label>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      id="status-inactive"
                      type="radio"
                      name="radio-group"
                      className="ladiui form-check-input"
                      onChange={() => {
                        this.onChangeStatus(appConfig.STAFF_STATUS.INACTIVE.CODE);
                      }}
                      checked={selectedStaff.status == appConfig.STAFF_STATUS.INACTIVE.CODE}
                    />
                    <label htmlFor="status-inactive">{t("STAFF_STATUS.INACTIVE")}</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="ladiui form-group">
              <div>
                <label className="ladiui-label pb-4">{t("ACCOUNTS.LIMIT_PERMISSION")}</label>
              </div>

              {this.state.selectedPermission && (
                <div>
                  <div className="ladi-card">
                    <table
                      className="ladiui table text-center table-custom mt-0"
                      style={{ display: "block", width: "100%", overflow: "auto" }}
                    >
                      <thead className="table-account">
                        <tr className="ladiui table-vertical header">
                          <th></th>
                          {map(userScopes, (scope, key) => {
                            return <th key={key}>{scope.title}</th>;
                          })}
                        </tr>
                      </thead>

                      <tbody>
                        <tr className="ladiui table-vertical">
                          <td className="text-left"> {t("COMMON.ALL")}</td>
                          {map(userScopes, (scope, key) => {
                            const groups = selectedStaff.scopes[key];
                            return (
                              <td key={key}>
                                <input
                                  type="checkbox"
                                  className="ladiui checkbox size-checkbox form-check-input-checkbox"
                                  onChange={() => this.onChangeScope(key)}
                                  checked={groups == true}
                                  // disabled={currentStore.pkg_name == appConfig.PACKAGE_NAME.STARTER}
                                />
                              </td>
                            );
                          })}
                        </tr>
                        {map(listScope, (scopetg, keytg) => {
                          return (
                            <tr key={keytg} className="ladiui table-vertical">
                              <td className="text-left" style={{ minWidth: "200px", display: "block" }}>
                                {scopetg}
                              </td>
                              {map(userScopes, (scope, key) => {
                                const groups = selectedStaff.scopes[key];
                                if (scope.groups) {
                                  let isHave = false;
                                  let valueScope = "";
                                  map(scope.groups, (group, _key) => {
                                    if (_key == keytg) {
                                      isHave = true;
                                      valueScope = _key;
                                    } else return null;
                                  });
                                  if (!isHave) return <td key={key}>...</td>;
                                  else {
                                    return (
                                      <td key={key}>
                                        <label>
                                          <input
                                            type="checkbox"
                                            className="ladiui checkbox size-checkbox form-check-input-checkbox"
                                            onChange={() => {
                                              this.onChangeGroup(key, valueScope);
                                            }}
                                            checked={groups == true || includes(groups, valueScope)}
                                            // disabled={currentStore.pkg_name == appConfig.PACKAGE_NAME.STARTER}
                                          />{" "}
                                          {/* {group.title} */}
                                        </label>
                                      </td>
                                    );
                                  }
                                } else return <td key={key}>...</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listStaffs: () => dispatch(staffActions.list()),
    createStaff: (staff) => dispatch(staffActions.create(staff)),
    updateStaff: (staff) => dispatch(staffActions.update(staff)),
    removeStaff: (staffID) => dispatch(staffActions.remove(staffID)),
  };
};

const mapStateToProps = (state) => ({
  store: { ...state.store },
  staffReducer: { ...state.staff },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation("translation", { withRef: true })(ModalSettingAccount));
