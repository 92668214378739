import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import { useTranslation } from "react-i18next";
import producer from "immer";

import storeActions from "../../../redux/futures/store/actions";
import * as storeTypes from "../../../redux/futures/store/types";

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";

export default function ModalSettingTracking(props) {
  const { t } = useTranslation();
  const storeReducer = useSelector((state) => state.store);

  const dispatch = useDispatch();
  const [tracking, setTracking] = useState({
    facebook_pixel: "",
    google_analytics_id: "",
    google_ads_id: "",
    google_ads_id_label: "",
    tiktok_pixel: "",
    google_tag_manager_id: "",
    custom_javascript_head: "",
    custom_javascript_body: "",
  });

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("trigger-tab-tracking-general").click();
    }, 0);
    dispatch(storeActions.showTracking());
  }, []);

  useEffect(() => {
    if (storeReducer.action == storeTypes.UPDATE_TRACKING_STORE) {
      if (storeReducer.status) {
        window.LadiUI.toastCustom("success", "", storeReducer.message);
      } else {
        window.LadiUI.toastCustom("danger", "", storeReducer.message);
      }
    }

    if (storeReducer.action == storeTypes.SHOW_TRACKING_STORE) {
      if (storeReducer.status) {
        setTracking(storeReducer.tracking);
      } else {
        window.LadiUI.toastCustom("danger", "", storeReducer.message);
      }
    }
  }, [storeReducer]);

  const onChangeInput = (event) => {
    setTracking(
      producer(tracking, (draft) => {
        draft[event.target.name] = event.target.value;
      })
    );
  };

  const submit = () => {
    dispatch(storeActions.updateTracking(JSON.stringify(tracking)));
  };

  const isLoadingSubmit = storeReducer.loading && storeReducer.waiting == storeTypes.UPDATE_TRACKING_STORE;
  return (
    <div
      className="page-setting"
      id="page-setting-tracking"
      // onOk={submit}
    >
      <div className="block-title-action">
        <h3 className="title">Mã chuyển đổi</h3>
        <div className="btn-action">
          <button type="button" onClick={submit} className="ladiui btn btn-primary btn-sm">
            {t("SETTINGS.SAVE_CHANGE")}
          </button>
        </div>
      </div>
      <div className="ladiui tab">
        <ul className="ladiui nav nav-tabs" id="myTab" role="tablist">
          <li className="ladiui nav-item" role="presentation">
            {/* <span data-tab="tab-tracking-general" id="trigger-tab-tracking-general"> */}
            <span
              className="ladiui tab-link active"
              id="trigger-tab-tracking-general"
              data-toggle="tab"
              data-parent="myTab"
              data-target="tab-tracking-general"
            >
              {t("SETTINGS.TRACKING")}
            </span>
          </li>
          <li className="ladiui nav-item" role="presentation">
            {/* <span data-tab="tab-tracking-custom" id="trigger-tab-tracking-custom"> */}
            <span
              className="ladiui tab-link"
              id="trigger-tab-tracking-custom"
              data-toggle="tab"
              data-parent="myTab"
              data-target="tab-tracking-custom"
            >
              {t("SETTINGS.TRACKING_CUSTOM")}
            </span>
          </li>
        </ul>
      </div>

      <div className="ladiui-tab-content max-width-content ladiui tab-content pt-24" id="myTabContent">
        <div className="ladiui-tab-item ladiui tab-pane fade show open mt-0" id="tab-tracking-general">
          <div className="ladiui form-group mt-0">
            <label className="ladiui-label">{t("TRACKING.FB_PIXEL_ID")}</label>
            <Input
              name="facebook_pixel"
              placeholder={t("TRACKING.FB_PIXEL_ID_PLACE_HOLDER")}
              value={tracking.facebook_pixel}
              onChange={onChangeInput}
            />
          </div>

          <div className="ladiui form-group">
            <label className="ladiui-label">{t("TRACKING.GG_ANALYTICS_ID")}</label>
            <Input
              name="google_analytics_id"
              placeholder={t("TRACKING.GG_ANALYTICS_ID_PLACE_HOLDER")}
              value={tracking.google_analytics_id}
              onChange={onChangeInput}
            />
          </div>

          <div className="ladiui form-group">
            <label className="ladiui-label">{t("TRACKING.GG_ADS_ID")}</label>
            <Input
              name="google_ads_id"
              placeholder={t("TRACKING.GG_ADS_ID_PLACE_HOLDER")}
              value={tracking.google_ads_id}
              onChange={onChangeInput}
            />
          </div>

          <div className="ladiui form-group">
            <label className="ladiui-label">{t("TRACKING.GG_ADS_ID_LABEL")}</label>
            <Input
              name="google_ads_id_label"
              placeholder={t("TRACKING.GG_ADS_ID_LABEL_PLACE_HOLDER")}
              value={tracking.google_ads_id_label}
              onChange={onChangeInput}
            />
          </div>

          <div className="ladiui form-group">
            <label className="ladiui-label">{t("TRACKING.TT_PIXEL_ID")}</label>
            <Input
              name="tiktok_pixel"
              placeholder={t("TRACKING.TT_PIXEL_ID_PLACE_HOLDER")}
              value={tracking.tiktok_pixel}
              onChange={onChangeInput}
            />
          </div>

          <div className="ladiui form-group">
            <label className="ladiui-label">{t("TRACKING.GG_TAG_MANAGER_ID")}</label>
            <Input
              name="google_tag_manager_id"
              placeholder={t("TRACKING.GG_TAG_MANAGER_ID_PLACE_HOLDER")}
              value={tracking.google_tag_manager_id}
              onChange={onChangeInput}
            />
          </div>
        </div>

        <div className="ladiui-tab-item ladiui tab-pane fade" id="tab-tracking-custom">
          <div className="tracking-custom-item">
            <label className="ladiui-label">{t("SETTINGS.TRACKING_BEFORE_HEAD")}</label>
            <AceEditor
              mode="javascript"
              theme="github"
              onChange={(newValue) => {
                const event = {
                  target: {
                    name: "custom_javascript_head",
                    value: newValue,
                  },
                };
                onChangeInput(event);
              }}
              name="custom_javascript_head"
              height="200px"
              width="100%"
              value={tracking.custom_javascript_head}
            />
          </div>

          <div className="tracking-custom-item">
            <label className="ladiui-label">{t("SETTINGS.TRACKING_BEFORE_BODY")}</label>
            <AceEditor
              mode="javascript"
              theme="github"
              onChange={(newValue) => {
                const event = {
                  target: {
                    name: "custom_javascript_body",
                    value: newValue,
                  },
                };
                onChangeInput(event);
              }}
              name="custom_javascript_body"
              height="200px"
              width="100%"
              value={tracking.custom_javascript_body}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
