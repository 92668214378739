/** @format */

import React from "react";

import { connect } from "react-redux";

import * as productTypes from "../../redux/futures/product/types";
import productActions from "../../redux/futures/product/actions";

import * as variantTypes from "../../redux/futures/product_variant/types";
import variantActions from "../../redux/futures/product_variant/actions";

import { find, includes, map, remove } from "lodash";
import appConfig from "../../config/app";
import baseHelper from "../../helpers/BaseHelper";
import LoadingTable from "../../components/LoadingTable";
import { withTranslation } from "react-i18next";
import ConfirmModal from "../../components/ConfirmModal";
import LoadingScene from "../../components/LoadingScene";
import { appLocalStorage } from "../../localforage";
import queryString from "query-string";

import * as productTagTypes from "../../redux/futures/product_tag/types";
import productTagActions from "../../redux/futures/product_tag/actions";
import Tag from "../../components/Tag";


import * as productCategoryTypes from "../../redux/futures/product_category/types";
import productCategoryActions from "../../redux/futures/product_category/actions";

import produce from "immer";
import FilterConditionCourse from "./components/FilterConditionCourse";
import LadiPagination from "../../components/LadiPagination";
import LadiDropdownMenu from "../../components/LadiDropdownMenu";
import ListTags from "../../components/ListTags";

class Course extends React.Component {
    constructor(props) {
        super(props);
        const defaultConditions = {
            page: 1,
            limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        };

        this.state = {
            activePage: 1,

            searchProductName: "",
            selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
            currentProductInfo: {},
            userInfo: {},
            selectedIDs: [],
            conditions: defaultConditions,
            productType: "",
            tag_ids: [],
            product_category_ids: [],
            type: "",
        };

        this.selectedID = "";
        this.selectedProduct = {};

        this.sortBy = {
            product_id: "DESC",
        };
        this.searchTagRef = React.createRef();
    }

    componentWillMount() {
        appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
            this.setState({
                userInfo: result,
            });
        });
    }

    componentDidMount() {
        this.list();

        let { product_id: productID, variant_id: variantID } = queryString.parse(this.props.location.search);
        if (productID) {
            this.handelProductEdit(productID);
        }

        this.props.listAllTags();
        let payload = {
            search: {
                type: "Course"
            }
        }
        this.props.listAllCategories(payload);

        const { isShowMessageErr, message } = queryString.parse(this.props.location.search);

        if (isShowMessageErr && message) {
            window.LadiUI.showErrorMessage("Thông báo", message, "OK");
        }
    }

    componentDidUpdate() {
        window.LadiUI.init();
        setTimeout(() => {
            window.LadiUI.customInit();
        }, 0);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.product.action != nextProps.product.action) {
            if (nextProps.product.action === productTypes.RELOAD_PRODUCT) {
                if (nextProps.product.status) {
                    this.setState({
                        currentProductInfo: nextProps.product.product,
                    });
                }
            }

            if (nextProps.product.action === productTypes.UPDATE_PRODUCT) {
                if (nextProps.product.status) {
                    window.LadiUI.toastCustom("success", "", nextProps.product.message);
                    this.props.reload(this.state.currentProductInfo.product_id);
                    this.list();
                } else {
                    window.LadiUI.showErrorMessage("Thông báo", nextProps.product.message, "OK");
                }
            }

            if (includes([productTypes.DELETE_PRODUCT, productTypes.DELETE_PRODUCTS], nextProps.product.action)) {
                if (nextProps.product.status) {
                    window.LadiUI.toastCustom("success", "", nextProps.product.message);

                    this.list();

                    window.LadiUI.closeModal("confirm-product-delete");
                    window.LadiUI.closeModal("confirm-product-deletes");
                } else {
                    window.LadiUI.showErrorMessage("Thông báo", nextProps.product.message, "OK");
                }
            }

            if (nextProps.product.action == productTypes.LIST_PRODUCT) {
                if (nextProps.product.status) {
                    window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui table .sorting"), (item) => {
                        item.classList.remove("up");

                        if (this.sortBy[item.getAttribute("name")] == "DESC") {
                            item.classList.add("up");
                        }
                    });
                } else {
                    window.LadiUI.showErrorMessage("Thông báo", nextProps.product.message, "OK");
                }
            }
        }

        if (this.props.variant.action != nextProps.variant.action) {
            if (nextProps.variant.action == variantTypes.GET_VARIANT_INFOS) {
                if (nextProps.variant.status) {
                    const variantInfos = map(nextProps.variant.variantInfos, (item) => {
                        const variantName = `${item.product_variant_id}:1|${this.selectedProduct.name} ${item.name ? `(${item.name})` : ""}`;
                        return variantName;
                    });
                    const variantInfo = variantInfos.join("\r\n");
                    baseHelper.copyToClipboard(variantInfo);

                    window.LadiUI.toastCustom("success", "", this.props.t("PRODUCTS.COPIED"));
                } else {
                    window.LadiUI.showErrorMessage("Thông báo", nextProps.variant.message, "OK");
                }
            }
        }
    }

    /**
     * list product
     */
    list = (resetActivePageFlag = true) => {
        if (document.getElementById("checkAllItem")) {
            document.getElementById("checkAllItem").checked = false;
            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = false;
            });
        }

        const { searchProductName, selectedLimit, conditions } = this.state;
        let activePage = conditions.page;

        if (resetActivePageFlag) {
            activePage = 1;
            conditions.page = 1;

            this.setState({
                conditions: conditions,
            });
        }

        // Reset selectedIDs
        this.setState({
            selectedIDs: [],
        });
        const tagIDs = this.state.tag_ids ? this.state.tag_ids : [];
        const productCategoryIds = this.state.product_category_ids ? this.state.product_category_ids : [];
        // const tagIDs = this.searchTagRef.current.getIDs();

        const data = {
            search: {
                name: searchProductName,
                tag_ids: tagIDs,
                product_category_ids: productCategoryIds,
                type: "COURSE",
            },
            paged: activePage,
            limit: conditions.limit,
            sort: this.sortBy,
        };

        this.props.list(data);
    };

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === "Enter") {
            this.list();
        }
    };

    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        let { conditions } = this.state;
        conditions.limit = option.value;

        // this.setState({ selectedLimit: option.value }, () => {
        this.setState({ conditions: conditions }, () => {
            this.list();
        });
    };

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        let { conditions } = this.state;
        conditions.page = pageNumber;

        this.setState({ conditions }, () => {
            this.list(false);
        });
    };
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        let selectedIDs = [];
        if (checked) {
            selectedIDs = map(this.props.product.products, (product) => product.product_id);

            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = false;
            });
        }

        this.setState({
            selectedIDs,
        });
    };

    checkItem = (event, productID) => {
        const { checked } = event.target;
        const { selectedIDs } = this.state;

        if (checked) {
            if (!includes(selectedIDs, productID)) {
                selectedIDs.push(productID);
            }

            if (selectedIDs.length == this.props.product.products.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            remove(selectedIDs, (selectedProductID) => selectedProductID == productID);
        }

        this.setState({
            selectedIDs,
        });
    };

    deletes = () => {
        if (this.state.selectedIDs.length <= 0) {
            window.LadiUI.toastCustom("danger", "", this.props.t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
            return;
        }

        this.props.deletes(this.state.selectedIDs);
    };

    deleteItem = (productID) => {
        this.props.delete(productID);
    };

    sort = (event) => {
        const { classList } = event.target;

        const name = event.target.getAttribute("name");

        window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui table .sorting"), (item) => {
            if (item.getAttribute("name") != name) {
                item.classList.remove("up");
            }
        });

        this.sortBy = {};

        // Change arrow up and down
        if (includes(classList, "up")) {
            classList.remove("up");
            this.sortBy[name] = "ASC";
        } else {
            classList.add("up");
            this.sortBy[name] = "DESC";
        }
        this.setState({}, () => this.list());
    };

    openModalEdit = (productID) => {
        // this.props.show(productID);
        this.props.history.push(`/products/update/${productID}`);
    };

    handelProductEdit = (productID) => {
        this.props.history.push(`/courses/update/${productID}`);
    };

    handleCopyCourse = (productID) => {
        this.props.history.push(`/courses/update/${productID}?is_copy=true`);
    };

    openOptionDeleteModal = (selectedID) => {
        this.selectedID = selectedID;

        window.LadiUI.showModal("confirm-product-delete");
    };

    openOptionDeletesModal = () => {
        if (this.state.selectedIDs.length <= 0) {
            window.LadiUI.toastCustom("danger", "", this.props.t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
            return;
        }

        window.LadiUI.showModal("confirm-product-deletes");
    };

    copyVariantInfo = (product) => {
        this.selectedProduct = product;
        this.props.getVariantInfos(product.product_id);
    };

    eventSelectAction = (action) => {
        switch (action) {
            case "DELETE":
                this.openOptionDeletesModal();
                break;
            default:
        }
    };
    clearFilter = (callback) => {
        this.setState(
            {
                tag_ids: [],
                product_category_ids: [],
                type: "",
            },
            () => {
                if (callback) {
                    callback();
                }
            }
        );
    };

    filterActionProp = async (conditions) => {
        this.clearFilter();

        for await (const condition of conditions) {
            switch (condition.attributeFilter.value) {
                case "tag_ids":
                    this.setState({
                        tag_ids: condition.listFilter.map((item) => item.value),
                    });
                    break;
                case "product_category_ids":
                    this.setState({
                        product_category_ids: condition.listFilter.map((item) => item.value),
                    });
                    break;
                default:
                    break;
            }
        }
        this.list();
    };

    getNameProductByStatus = (status) => {
        const matched = find(appConfig.PRODUCT_STATUS, (item) => item.CODE == status) || {};
        if (matched) {
            return matched.NAME;
        }
    };

    handleClickTag = (item, selectedTags) => {
        this.setState(
            {
                tag_ids: [...this.state.tag_ids, item],
            },
            () => {
                this.list();
            }
        );
    };

    render() {
        const { t } = this.props;
        const { totalRecord, totalPage } = this.props.product;
        const { activePage, selectedLimit, searchProductName, userInfo, conditions } = this.state;
        let fromItem = 0;
        let toItem = 0;

        if ((this.props.product.products || []).length > 0) {
            fromItem = (conditions.page - 1) * conditions.limit + 1;
            toItem = fromItem + this.props.product.products.length - 1;
        }

        let { product_id: productID, variant_id: variantID } = queryString.parse(this.props.location.search);

        const msgShowItem = ``;

        const isLoadingData = this.props.product.loading && this.props.product.waiting == productTypes.LIST_PRODUCT;
        const isLoadingInfo =
            (this.props.product.loading && includes([productTypes.SHOW_PRODUCT, productTypes.COPY_PRODUCT], this.props.product.waiting)) ||
            (this.props.variant.loading && this.props.variant.waiting == variantTypes.GET_VARIANT_INFOS);
        const isLoadingDelete =
            this.props.product.loading && includes([productTypes.DELETE_PRODUCT, productTypes.DELETE_PRODUCTS], this.props.product.waiting);
        const isSubmitLoading =
            this.props.product.loading && includes([productTypes.CREATE_PRODUCT, productTypes.UPDATE_PRODUCT], this.props.product.waiting);
        const isLoadingReload = this.props.product.loading && productTypes.RELOAD_PRODUCT == this.props.product.waiting;

        const allTags = this.props.productTagReducer.allTags || [];

        const allCategories = this.props.productCategory.listSelects || [];

        const listAction = [{ value: "DELETE", name: t("ACTIONS.DELETE") }];

        return (
            <div className="page-content">
                {isLoadingReload && <LoadingScene blur={true} />}

                <div>
                    <div className="ladiui-table-actions">
                        <div className="ladiui content-main-tools block-filter-condition flex-row">
                            <div className="ladiui btn-group block-filter-header">
                                <FilterConditionCourse
                                    conditionProp={conditions}
                                    allTags={allTags}
                                    allCategories={allCategories}
                                    filterActionProp={this.filterActionProp}
                                />
                            </div>
                            <div className="ladiui search-group" style={{ width: "100%" }}>
                                <input
                                    id="keyword_search"
                                    className="ladiui search-field dropdown-toggle form-control search-width"
                                    name="searchProductName"
                                    placeholder={t("COURSES.SEARCH_COURSE")}
                                    aria-expanded="false"
                                    value={searchProductName}
                                    onChange={this.onChangeInput}
                                    onKeyDown={this.onKeyDownInput}
                                />
                                <i className="ladiui icon icon-search" onClick={this.list}></i>
                            </div>
                        </div>
                        <div className="action-btn">
                            <button
                                type="button"
                                className="ladiui btn btn-primary btn-lg btn-open-modal"
                                data-toggle="modal"
                                onClick={() => {
                                    this.props.history.push(`/courses/create`);
                                }}
                            >
                                <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />
                                <div className="ladiui btn-custom-text ">{t("COURSES.ADD_COURSE")}</div>
                            </button>
                        </div>
                    </div>
                    {isLoadingData ? (
                        <LoadingTable />
                    ) : (
                        <div>
                            <div className="ladi-card ladiui-table-responsive">
                                <table className={`ladiui table text-left ${isLoadingInfo ? "loader" : ""}`}>
                                    <thead style={{ position: "relative" }}>
                                        <tr className="ladiui table-vertical header">
                                            <th className="ladiui checkall">
                                                <input
                                                    id="checkAllItem"
                                                    onClick={this.checkAllItem}
                                                    type="checkbox"
                                                    className="ladiui-checkall ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle"
                                                />
                                            </th>
                                            {this.state.selectedIDs.length <= 0 ? (
                                                <th
                                                    scope="col"
                                                    name="name"
                                                    style={{ width: "600px" }}
                                                    onClick={this.sort}
                                                    className={`text-left sorting ${this.sortBy["name"] == "DESC" ? "up" : ""}`}
                                                >
                                                    {t("COURSES.NAME")}
                                                </th>
                                            ) : (
                                                <th scope="col" style={{ width: "600px" }}>
                                                    {t("COURSES.NAME")}
                                                    <LadiDropdownMenu
                                                        labelDropdown={"Chọn hành động"}
                                                        listProp={listAction}
                                                        defaultTextProp={t("ACTIONS.TITLE")}
                                                        hideLabel={true}
                                                        cbProp={this.eventSelectAction}
                                                        classSize="btn-sm "
                                                        classWidth="w-180"
                                                        classDropdown="w-180 action-record-table"
                                                    />
                                                </th>
                                            )}
                                            <th scope="col">{t("COMMON.PRODUCT_STATUS")}</th>
                                            <th scope="col">{t("COMMON.CREATOR")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {map(this.props.product.products, (product) => {
                                            let tagIds = [];
                                            let tagValues = [];
                                            const tags = product.tags ? product.tags.split(",") : [];
                                            if (tags) {
                                                map(tags, (_item) => {
                                                    let id = _item.split(":")[0];
                                                    let name = _item.split(":")[1];
                                                    tagIds.push(id);
                                                    tagValues.push(name);
                                                });
                                            }
                                            return (
                                                <>
                                                    <tr
                                                        key={product.product_id}
                                                        className="ladiui table-vertical main"
                                                        style={tags.length > 0 ? { borderBottom: "none" } : {}}
                                                    >
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                onClick={(event) => this.checkItem(event, product.product_id)}
                                                                className="ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle"
                                                            />
                                                        </td>
                                                        <td style={{ maxWidth: "600px", paddingRight: "10px" }}>
                                                            <a
                                                                className="limit-text-vertical"
                                                                onClick={() => this.handelProductEdit(product.product_id)}
                                                            >
                                                                {product.name}
                                                            </a>
                                                        </td>
                                                        <td>{this.getNameProductByStatus(product.status)}</td>
                                                        <td>{baseHelper.getStaffNameById(userInfo.staffs, product.creator_id, userInfo.ladi_uid)}</td>
                                                        <td className="text-right pd-0">
                                                            <div className="ladiui btn-group">
                                                                <div className="ladiui dropdown hide-mt ba-c">
                                                                    <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                                                                        <i className="ladiui icon icon-ldp-dot"></i>
                                                                    </button>
                                                                    <ul className="ladiui dropdown-menu r-0">
                                                                        <li>
                                                                            <a
                                                                                className="ladiui dropdown-item"
                                                                                onClick={() => this.handelProductEdit(product.product_id)}
                                                                            >
                                                                                {t("ACTIONS.EDIT")}
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a
                                                                                className="ladiui dropdown-item"
                                                                                onClick={() => this.handleCopyCourse(product.product_id)}
                                                                            >
                                                                                {t("COURSES.COPY_COURSE")}
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="ladiui dropdown-item" onClick={() => this.copyVariantInfo(product)}>
                                                                                {t("PRODUCTS.GET_INFO")}
                                                                            </a>
                                                                        </li>
                                                                        {product.url_published && (
                                                                            <li>
                                                                                <a
                                                                                    className="ladiui dropdown-item"
                                                                                    target="_blank"
                                                                                    onClick={() => {
                                                                                        window.open(product.url_published);
                                                                                    }}
                                                                                >
                                                                                    {t("COMMON.PREVIEW")}
                                                                                </a>
                                                                            </li>
                                                                        )}
                                                                        <li>
                                                                            <a className="ladiui dropdown-item" onClick={() => this.openOptionDeleteModal(product.product_id)}>
                                                                                {t("ACTIONS.DELETE")}
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {tags && tags.length > 0 && (
                                                        <tr className="ladiui table-vertical main custom-tag">
                                                            <td></td>
                                                            <td colSpan={10}>
                                                                {tags.length > 0 && (
                                                                    <ListTags
                                                                        tagValues={tagIds}
                                                                        tagColors={[]}
                                                                        tagNames={tagValues}
                                                                        handleClickTagItem={this.handleClickTag}
                                                                    />
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            );
                                        })}
                                        {totalRecord <= 0 && (
                                            <tr className="text-center">
                                                <td colSpan="100%">{t("NO_DATA")}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {totalRecord > 0 && (
                                <LadiPagination
                                    conditionsProp={conditions}
                                    listName={t("PAGINATION.ITEMS")}
                                    message={""}
                                    items={this.props.product.products}
                                    total={totalRecord}
                                    limitAction={this.onChangeLimit}
                                    pageAction={this.onPageChange}
                                />
                            )}
                        </div>
                    )}
                </div>

                <ConfirmModal
                    id="confirm-product-delete"
                    title={t("PRODUCTS.MSG_DELETE_PRODUCT_TITLE")}
                    content={t("PRODUCTS.MSG_DELETE_PRODUCT_CONTENT")}
                    cancelText={t("ACTIONS.CANCEL")}
                    okText={t("ACTIONS.DELETE")}
                    onOk={() => this.props.delete(this.selectedID)}
                    isLoading={isLoadingDelete}
                />

                <ConfirmModal
                    id="confirm-product-deletes"
                    title={t("PRODUCTS.MSG_DELETE_PRODUCT_TITLE")}
                    content={t("PRODUCTS.MSG_DELETE_PRODUCTS_CONTENT")}
                    cancelText={t("ACTIONS.CANCEL")}
                    okText={t("ACTIONS.DELETE")}
                    onOk={() => this.props.deletes(this.state.selectedIDs)}
                    isLoading={isLoadingDelete}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(productActions.list(data)),
        deletes: (productIDs) => dispatch(productActions.deletes(productIDs)),
        delete: (productID) => dispatch(productActions.delete(productID)),
        show: (productID) => dispatch(productActions.show(productID)),
        reload: (productID) => dispatch(productActions.reload(productID)),
        copy: (productID) => dispatch(productActions.copy(productID)),
        create: (product) => dispatch(productActions.create(product)),
        update: (product) => dispatch(productActions.update(product)),
        getVariantInfos: (productID) => dispatch(variantActions.getVariantInfos(productID)),
        listAllTags: () => dispatch(productTagActions.listAll()),
        listAllCategories: (data) => dispatch(productCategoryActions.listSelectCategory(data)),
    };
};

const mapStateToProps = (state) => ({
    product: { ...state.product },
    productCategory: { ...state.productCategory },
    variant: { ...state.variant },
    productTagReducer: { ...state.productTag },
    storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Course));
