import React from "react";

import baseHelper from "../../helpers/BaseHelper";
import appConfig from "../../config/app";
import { cloneDeep, includes, map, find } from "lodash";

import * as shippingTypes from "../../redux/futures/shipping/types";
import shippingActions from "../../redux/futures/shipping/actions";

import * as printTypes from "../../redux/futures/print/types";
import printActions from "../../redux/futures/print/actions";

import { connect } from "react-redux";

import ConfirmModal from "../../components/ConfirmModal";
import PanelCustomer from "../../pages/orders/components/OrderCustomer";
import PanelShippingAddress from "../../pages/orders/components/ShippingAddress";
import { withTranslation } from "react-i18next";
import LoadingSence from "../../components/LoadingScene";
import ShippingInfo from "./components/ShippingInfo";
import ModalShippingFee from "./components/ModalShippingFee";
import ModalShippingStatus from "./components/ModalShippingStatus";
import ModalPaymentStatus from "./components/ModalPaymentStatus";
import Image from "../../components/Image";
import History from "../../components/History";

class ShippingEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      note: "",
      fee: null,
      trackingNumber: null,
      isShowModalShippingFee: false,
      isShowModalShippingStatus: false,
      isShowModalPaymentStatus: false,
      updatedTrackingNumber: false,
      statusCode: "",
    };
  }

  componentDidMount() {
    this.props.show(this.props.match.params["shippingID"]);
  }

  componentDidUpdate() {
    window.LadiUI.init();
    setTimeout(() => {
      window.LadiUI.customInit();
    }, 0);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.shippingReducer.action != nextProps.shippingReducer.action) {
      if (nextProps.shippingReducer.action === shippingTypes.SHOW_SHIPPING) {
        if (nextProps.shippingReducer.status) {
          const {
            shipping: { note },
          } = nextProps.shippingReducer;
          this.setState({
            note: cloneDeep(note),
          });
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.shippingReducer.message, "OK");
          this.props.history.push("/shippings");
        }
      }

      if (nextProps.shippingReducer.action === shippingTypes.UPDATE_SHIPPING) {
        if (nextProps.shippingReducer.status) {
          this.props.show(this.props.match.params["shippingID"]);
          window.LadiUI.toastCustom("success", "", nextProps.shippingReducer.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.shippingReducer.message, "OK");
        }
      }

      if (nextProps.shippingReducer.action == shippingTypes.SHIPPING_UPDATE_FEE) {
        if (nextProps.shippingReducer.status) {
          window.LadiUI.toastCustom("success", "", nextProps.shippingReducer.message);
          this.hideModalShippingFee();
          this.props.show(this.props.match.params["shippingID"]);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.shippingReducer.message, "OK");
        }
      }

      if (nextProps.shippingReducer.action == shippingTypes.DELETE_SHIPPING) {
        if (nextProps.shippingReducer.status) {
          window.LadiUI.toastCustom("success", "", nextProps.shippingReducer.message);
          window.LadiUI.closeModal("confirm-shipping-delete");

          this.props.history.push("/shippings");
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.shippingReducer.message, "OK");
        }
      }

      if (nextProps.shippingReducer.action == shippingTypes.SHIPPING_UPDATE_TRACKING_NUMBER) {
        if (nextProps.shippingReducer.status) {
          window.LadiUI.toastCustom("success", "", nextProps.shippingReducer.message);
          this.setState({
            updatedTrackingNumber: true,
          });

          this.props.show(this.props.match.params["shippingID"]);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.shippingReducer.message, "OK");
        }
      }

      if (nextProps.shippingReducer.action == shippingTypes.SHIPPING_UPDATE_STATUS) {
        if (nextProps.shippingReducer.status) {
          if (this.state.statusCode == appConfig.SHIPPING_STATUS.CANCELED.CODE) {
            window.LadiUI.closeModal("confirm-shipping-cancel");
          }
          window.LadiUI.toastCustom("success", "", nextProps.shippingReducer.message);

          this.props.show(this.props.match.params["shippingID"]);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.shippingReducer.message, "OK");
        }
      }

      if (nextProps.shippingReducer.action == shippingTypes.SHIPPING_UPDATE_PAYMENT_STATUS) {
        if (nextProps.shippingReducer.status) {
          window.LadiUI.toastCustom("success", "", nextProps.shippingReducer.message);
          // this.hideModalPaymentStatus();

          this.props.show(this.props.match.params["shippingID"]);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.shippingReducer.message, "OK");
        }
      }
    }

    if (this.props.printReducer.action != nextProps.printReducer.action) {
      if (nextProps.printReducer.action == printTypes.PRINT_SHIPPING) {
        if (nextProps.printReducer.status) {
          baseHelper.print(nextProps.printReducer.source);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.printReducer.message, "OK");
        }
      }
    }
  }

  onChangeInput = (event) => {
    this.setState({
      ...this.state.shipping,
      [event.target.name]: event.target.value,
    });
  };

  /*******************************MODAL***************************/
  // openModalShippingStatus = () => {
  //     this.setState({
  //         statusCode: this.props.shippingReducer.shipping.status,
  //         isShowModalShippingStatus: true,
  //     });
  // }

  // hideModalShippingStatus = () => {
  //     this.setState({
  //         isShowModalShippingStatus: false,
  //     });
  // }

  // openModalPaymentStatus = () => {
  //     this.setState({
  //         paymentStatusCode: this.props.shippingReducer.shipping.payment_status,
  //         isShowModalPaymentStatus: true,
  //     });
  // }

  // hideModalPaymentStatus = () => {
  //     this.setState({
  //         isShowModalPaymentStatus: false,
  //     });
  // }

  openModalShippingFee = () => {
    this.setState({
      fee: this.props.shippingReducer.shipping.fee,
      isShowModalShippingFee: true,
    });
  };

  hideModalShippingFee = () => {
    this.setState({
      isShowModalShippingFee: false,
    });
  };
  /****************************************************************/

  /*******************************CHANGE STATUS***************************/
  // updateStatus = (shippingID, statusCode) => {
  //     this.setState({
  //         statusCode
  //     });

  //     if (statusCode == appConfig.SHIPPING_STATUS.CANCELED.CODE) {
  //         window.LadiUI.showModal('confirm-shipping-cancel');
  //         return;
  //     }
  //     this.props.updateStatus(shippingID, statusCode);
  // }

  onChangeShippingStatus = (shippingID, statusCode) => {
    this.setState({
      statusCode,
    });

    if (statusCode == appConfig.SHIPPING_STATUS.CANCELED.CODE) {
      window.LadiUI.showModal("confirm-shipping-cancel");
      return;
    }
    this.props.updateStatus(shippingID, statusCode);
  };

  onChangePaymentStatus = (shippingID, statusCode) => {
    this.props.updatePaymentStatus(shippingID, statusCode);
  };

  // updatePaymentStatus = (shippingID, paymentStatusCode) => {
  //     this.props.updatePaymentStatus(shippingID, paymentStatusCode);
  // }

  updateFee = (shippingID, fee) => {
    this.props.updateFee(shippingID, fee);
  };

  updateTrackingNumber = (shippingID, trackingNumber) => {
    this.props.updateTrackingNumber(shippingID, trackingNumber);
  };
  /*********************************************************************/

  onChangeAddress = (address, addressType) => {
    const { shipping } = this.props.shippingReducer;
    let updateShipping;
    if (addressType == appConfig.ADDRESS_TYPE.BILLING) {
      updateShipping = {
        shipping_id: shipping.shipping_id,
        billing_address: {
          ...address,
        },
      };
    } else {
      updateShipping = {
        shipping_id: shipping.shipping_id,
        shipping_address: {
          ...address,
        },
      };
    }

    this.props.update(updateShipping);
  };

  openOptionDeleteModal = () => {
    window.LadiUI.showModal("confirm-shipping-delete");
  };

  print = () => {
    this.props.print(this.props.match.params["shippingID"]);
  };

  submit = () => {
    const data = {
      shipping_id: this.props.shippingReducer.shipping.shipping_id,
      note: this.state.note,
      tags: [],
    };

    this.props.update(data);
  };

  getNameShippingByCode = (code) => {
    const matched = find(appConfig.SHIPPING_STATUS, (item) => item.CODE == code) || {};
    return matched.NAME;
  };
  getNamPaymentByCode = (code) => {
    const matched = find(appConfig.SHIPPING_PAYMENT_STATUS, (item) => item.CODE == code) || {};
    return matched.NAME;
  };

  render() {
    const { t } = this.props;
    const { shipping } = this.props.shippingReducer;
    const { note, fee, statusCode } = this.state;
    const isLoadingData =
      this.props.shippingReducer.loading &&
      includes([shippingTypes.SHOW_SHIPPING, shippingTypes.UPDATE_SHIPPING], this.props.shippingReducer.waiting);
    const isLoadingPrint = this.props.printReducer.loading && printTypes.PRINT_SHIPPING == this.props.printReducer.waiting;
    const isLoadingDelete =
      this.props.shippingReducer.loading && includes([shippingTypes.DELETE_SHIPPING], this.props.shippingReducer.waiting);
    const isLoadingUpdateStatus =
      this.props.shippingReducer.loading &&
      shippingTypes.SHIPPING_UPDATE_STATUS == this.props.shippingReducer.waiting &&
      statusCode != appConfig.SHIPPING_STATUS.CANCELED.CODE;
    const isLoadingCancel =
      this.props.shippingReducer.loading &&
      shippingTypes.SHIPPING_UPDATE_STATUS == this.props.shippingReducer.waiting &&
      statusCode == appConfig.SHIPPING_STATUS.CANCELED.CODE;
    const isLoadingUpdatePaymentStatus =
      this.props.shippingReducer.loading && shippingTypes.SHIPPING_UPDATE_PAYMENT_STATUS == this.props.shippingReducer.waiting;
    const isLoadingUpdateFee =
      this.props.shippingReducer.loading && shippingTypes.SHIPPING_UPDATE_FEE == this.props.shippingReducer.waiting;
    const isLoadingUpdateTrackingNumber =
      this.props.shippingReducer.loading && shippingTypes.SHIPPING_UPDATE_TRACKING_NUMBER == this.props.shippingReducer.waiting;

    const { items } = shipping;

    const shippingAddress = baseHelper.isEmpty(shipping)
      ? {}
      : {
          first_name: shipping.shipping_first_name,
          last_name: shipping.shipping_last_name,
          company: shipping.shipping_company,
          phone: shipping.shipping_phone,
          address: shipping.shipping_address,
          apartment: shipping.shipping_apartment,
          ward_name: shipping.shipping_ward_name,
          district_name: shipping.shipping_district_name,
          state_name: shipping.shipping_state_name,
          country_name: shipping.shipping_country_name,
          postal_code: shipping.shipping_postal_code,
        };

    return (
      <div className="page-content">
        {(isLoadingData || isLoadingPrint) && <LoadingSence blur={true} />}
        <div className="ladiui content-main-title flex-row">
          <div className="ladiui flex-row">
            <div className="ladiui main-title mr-12">{`Phiếu giao hàng: ${baseHelper.getText(shipping.shipping_id)}`}</div>

            <span className="placed-on flex mr-16">
              {/* <img src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-date.svg"} alt="" style={{ marginRight: "4px" }} /> */}
              <span>
                {t("SHIPPINGS.CREATED_AT")}: {baseHelper.formatStrToDate(shipping.created_at)}
              </span>
            </span>
          </div>
          <div className="ladiui flex-row">
            <button className="placed-on btn-print-order-my-order mr-12 cursor-pointer" onClick={this.print}>
              <img src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-print-order-pr.svg"} alt="" style={{ marginRight: "4px" }} />
              <span>{t("COMMON.PRINT")}</span>
            </button>
            <button type="button" className={`ladiui btn btn-primary btn-lg`} onClick={this.submit} data-target="#white-page">
              <img src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-save-data.svg"} alt="" style={{ marginRight: "8px" }} />
              {t("SHIPPINGS.SAVE")}
            </button>
          </div>
        </div>

        <div className="block-content-shippings">
          <div className="ladiui-order-left">
            <div className="ladi-card order-products" style={{ marginTop: 12, marginBottom: "24px" }}>
              {items &&
                items.map((item, index) => {
                  return (
                    <div className={`item-variant ${index == 0 ? "border-none" : ""}`} key={index}>
                      <div className="w-100">
                        <Image src={item.src} width={82} height={82} />
                      </div>
                      <div className="w-name-product">
                        <div className="item-variant-name-info">
                          <span className="item-title">{item.product_name || item.product_name_full}</span>

                          <div className="item-variant-list-option">
                            {item.options &&
                              item.options.map((option, index) => {
                                return (
                                  <div key={index}>
                                    <span className="option-name">{option.option_name}: </span>
                                    <span className="option-value">{option.option_value_value}</span>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="w-200 flex">
                        <span>
                          {baseHelper.formatMoneyPostFix(item.price, this.props.storeReducer.userInfo.currentStore.currency_symbol)}
                        </span>
                      </div>
                      <div className="w-120">
                        <span>x{item.quantity}</span>
                      </div>
                      <div className="w-190 text-end item-title">
                        {baseHelper.formatMoneyPostFix(item.total, this.props.storeReducer.userInfo.currentStore.currency_symbol)}
                      </div>
                    </div>
                  );
                })}

              <div className="footer-order">
                <div className="w-49-p">
                  <div className="title-lable mb-10">Ghi chú</div>
                  <textarea className="ladiui textarea" value={note || ""} name="note" onChange={this.onChangeInput} />
                </div>

                <div className="w-49-p">
                  <div className="item-info">
                    <div className="flex">
                      <img src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-dola.svg"} alt="" />
                      <span className="footer-lable">Tổng tiền</span>
                    </div>
                    <div>
                      <span>
                        {baseHelper.formatMoneyPostFix(shipping.total, this.props.storeReducer.userInfo.currentStore.currency_symbol)}
                      </span>
                    </div>
                  </div>

                  <div className="item-info">
                    <div className="flex">
                      {/* <img src={icon_delivery} alt='' /> */}
                      <span className="footer-lable">
                        {t("SHIPPINGS.FEE")}{" "}
                        <i onClick={this.openModalShippingFee} style={{ cursor: "pointer" }} className="ladi-icon icon-edit"></i>
                      </span>
                    </div>
                    <div>
                      <span>
                        {baseHelper.formatMoneyPostFix(shipping.fee, this.props.storeReducer.userInfo.currentStore.currency_symbol)}
                      </span>
                    </div>
                  </div>
                  <div className="item-info">
                    <div className="flex">
                      <span className="footer-lable">{t("SHIPPINGS.COD_AMOUNT")}</span>
                    </div>
                    <div>
                      <span>
                        {baseHelper.formatMoneyPostFix(shipping.cod_amount, this.props.storeReducer.userInfo.currentStore.currency_symbol)}
                      </span>
                    </div>
                  </div>
                  {shipping.status == appConfig.SHIPPING_STATUS.CANCELED.CODE && (
                    <div className="item-info">
                      <div className="flex"></div>
                      <div className="flex">
                        <button onClick={() => this.openOptionDeleteModal()} className="ladiui btn btn-danger btn-status">
                          {t("SHIPPINGS.DELETE_SHIPPING")}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="block-update-status mt-24">
                <div className="item-update-status py-24 flex space-between">
                  <label className="ladiui-label pb-0">Trạng thái giao hàng</label>
                  <div className="ladiui btn-group" style={{ width: "300px", float: "right" }}>
                    <div className="ladiui dropdown w100">
                      <button
                        style={{ minWidth: "100%" }}
                        className="ladiui btn btn-outline-light dropdown-toggle"
                        disabled={shipping.status == appConfig.SHIPPING_STATUS.CANCELED.CODE}
                        data-toggle="dropdown"
                      >
                        {this.getNameShippingByCode(shipping.status)}
                      </button>
                      <ul className="ladiui dropdown-menu w100">
                        {map(appConfig.SHIPPING_STATUS, (option) => {
                          if (option != appConfig.SHIPPING_STATUS.PENDING) {
                            return (
                              <li key={option.CODE} onClick={() => this.onChangeShippingStatus(shipping.shipping_id, option.CODE)}>
                                <a className="ladiui dropdown-item" href="#">
                                  {option.NAME}
                                </a>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="item-update-status py-24 flex space-between">
                  <label className="ladiui-label pb-0">Trạng thái thu hộ</label>
                  <div className="ladiui btn-group" style={{ width: "300px", float: "right" }}>
                    <div className="ladiui btn-group w100">
                      <div className="ladiui dropdown w100">
                        <button
                          style={{ minWidth: "100%" }}
                          className="ladiui btn btn-outline-light dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          {this.getNamPaymentByCode(shipping.payment_status)}
                        </button>
                        <ul className="ladiui dropdown-menu w100">
                          {map(appConfig.SHIPPING_PAYMENT_STATUS, (option) => {
                            return (
                              <li key={option.CODE} onClick={() => this.onChangePaymentStatus(shipping.shipping_id, option.CODE)}>
                                <a className="ladiui dropdown-item" href="#">
                                  {option.NAME}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='item-update-status'></div>
                                <div className='flex-center'>
                                    <button onClick={this.openModalShippingStatus} className="ladiui btn btn-outline-primary btn-status mr-8">{t('SHIPPINGS.SHIPPING_STATUS_UPDATE_TITLE')}</button>
                                    <button onClick={this.openModalPaymentStatus} className="ladiui btn btn-outline-primary btn-status mr-8">{t('SHIPPINGS.PAYMENT_STATUS_UPDATE_TITLE')}</button>

                                </div> */}
              </div>
            </div>

            <History type={appConfig.HISTORY_FORM.SHIPPING} shippingID={this.props.match.params["shippingID"]} />
          </div>
          <div className="ladiui-order-right t-145">
            <div className="shiping-info-edit custom">
              <ShippingInfo
                shipping={shipping}
                onSubmit={this.updateTrackingNumber}
                isLoading={isLoadingUpdateTrackingNumber}
                updated={this.state.updatedTrackingNumber}
              />
            </div>
            <div className="shipping-info-customer shipping-edit">
              <PanelCustomer
                mode={appConfig.FORM_MODE.VIEW}
                orderCustomer={{
                  customer_id: shipping.customer_id,
                  first_name: shipping.customer_first_name,
                  last_name: shipping.customer_last_name,
                  phone: shipping.customer_phone,
                  email: shipping.customer_email,
                }}
              />
              <div className="shipping-address">
                <PanelShippingAddress
                  ref={this.panelShippingAddressRef}
                  mode={appConfig.FORM_MODE.VIEW}
                  shippingAddress={shippingAddress}
                  onChangeAddress={this.onChangeAddress}
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.isShowModalShippingFee && (
          <ModalShippingFee
            shippingID={shipping.shipping_id}
            fee={fee}
            visible={this.state.isShowModalShippingFee}
            onSubmit={this.updateFee}
            onCancel={this.hideModalShippingFee}
            isLoading={isLoadingUpdateFee}
          />
        )}

        {/* {
                    this.state.isShowModalShippingStatus
                    &&
                    <ModalShippingStatus
                        shippingID={shipping.shipping_id}
                        statusCode={shipping.status}
                        visible={this.state.isShowModalShippingStatus}
                        onSubmit={this.updateStatus}
                        onCancel={this.hideModalShippingStatus}
                        isLoading={isLoadingUpdateStatus}
                    />
                } */}

        {/* {
                    this.state.isShowModalPaymentStatus
                    &&
                    <ModalPaymentStatus
                        shippingID={shipping.shipping_id}
                        paymentStatusCode={shipping.payment_status}
                        visible={this.state.isShowModalPaymentStatus}
                        onSubmit={this.updatePaymentStatus}
                        onCancel={this.hideModalPaymentStatus}
                        isLoading={isLoadingUpdatePaymentStatus}
                    />
                } */}

        <ConfirmModal
          id="confirm-shipping-delete"
          title={t("SHIPPINGS.MSG_DELETE_SHIPPING_TITLE")}
          content={t("SHIPPINGS.MSG_DELETE_SHIPPINGS_CONTENT")}
          cancelText={t("ACTIONS.CANCEL")}
          okText={t("ACTIONS.DELETE")}
          onOk={() => this.props.delete(shipping.shipping_id)}
          isLoading={isLoadingDelete}
        />

        <ConfirmModal
          id={`confirm-shipping-cancel`}
          title={t("SHIPPINGS.MSG_CANCEL_SHIPPING_TITLE")}
          content={t("SHIPPINGS.MSG_CANCEL_SHIPPING_CONTENT")}
          cancelText={t("ACTIONS.CLOSE")}
          okText={t("ACTIONS.CANCEL")}
          onOk={() => this.props.updateStatus(shipping.shipping_id, statusCode)}
          isLoading={isLoadingCancel}
          maxWidth={600}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    show: (shippingID) => dispatch(shippingActions.show(shippingID)),
    delete: (shippingID) => dispatch(shippingActions.delete(shippingID)),
    update: (shipping) => dispatch(shippingActions.update(shipping)),
    updateStatus: (shippingID, status) => dispatch(shippingActions.updateStatus(shippingID, status)),
    updatePaymentStatus: (shippingID, paymentStatusCode) => dispatch(shippingActions.updatePaymentStatus(shippingID, paymentStatusCode)),
    updateFee: (shippingID, fee) => dispatch(shippingActions.updateFee(shippingID, fee)),
    updateTrackingNumber: (shippingID, trackingNumber) => dispatch(shippingActions.updateTrackingNumber(shippingID, trackingNumber)),
    print: (shippingID) => dispatch(printActions.printShipping(shippingID)),
  };
};

const mapStateToProps = (state) => ({
  shippingReducer: { ...state.shipping },
  printReducer: { ...state.print },
  storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ShippingEdit));
