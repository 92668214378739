import React from "react";
import PropTypes from "prop-types";
import validatorHelper from "../helpers/ValidatorHelper";
import baseHelper from "../helpers/BaseHelper";
import i18n from "../i18n";
import NumberFormat from "react-number-format";
import appConfig from "../config/app";

class NumberInput extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    validationName: PropTypes.string,
    value: PropTypes.any,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    placeholder: PropTypes.string,
    style: PropTypes.any,
    disabled: PropTypes.bool,
    tabIndex: PropTypes.number,
    thousandSeparator: PropTypes.bool,
    decimalScale: PropTypes.number,
    isDiscountType: PropTypes.number,
    onlyNumber: PropTypes.bool,
    allowNegative: PropTypes.bool,

    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,

    validations: PropTypes.shape({
      isRequired: PropTypes.bool,
      min: PropTypes.number,
      max: PropTypes.number,
    }),
  };

  static defaultProps = {
    allowNegative: false,
    onlyNumber: false,
    disabled: false,
    thousandSeparator: true,
    decimalScale: 2,
    validations: {
      isRequired: false,
      min: 0,
      max: 1000000000,
    },
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();

    // this.state = {
    //   className: `ladiui ${
    //     props.prefix ? "input-padding-left" : ""
    //   } form-control ${props.className}`,
    // };

    this.state = {
      className: `ladiui form-control ${props.className}`,
    };
  }

  validate = () => {
    const errors = [];
    if (!this.ref.current) {
      return errors;
    }

    let validateStatus = true;

    let { value } = this.props;

    let { validationName, name } = this.props;
    if (!validationName) {
      validationName = name;
    }
    const { isRequired, min, max } = this.props.validations;
    if (isRequired) {
      if (validatorHelper.isEmpty(value)) {
        validateStatus = false;
        errors.push(i18n.t("VALIDATION.ENTER", validationName));
      }
    }

    if (min) {
      if (baseHelper.parseFloat(value) < baseHelper.parseFloat(min)) {
        validateStatus = false;
        errors.push(i18n.t("VALIDATION.GREATER_THAN", validationName, min));
      }
    }

    if (max) {
      if (baseHelper.parseFloat(value) > baseHelper.parseFloat(max)) {
        validateStatus = false;
        errors.push(i18n.t("VALIDATION.SMALLER_THAN", validationName, max));
      }
    }

    if (!validateStatus) {
      if (this.state.className.indexOf("error") < 0) {
        this.setState({
          className: "ladiui error form-control",
        });
      }
    } else {
      if (this.state.className.indexOf("error") > 0) {
        this.setState({
          className: "ladiui form-control",
        });
      }
    }

    return errors;
  };

  render() {
    const {
      id,
      name,
      placeholder,
      style,
      disabled,
      tabIndex,
      onChange,
      onBlur,
      onFocus,
      prefix,
      thousandSeparator,
      isDiscountType,
      onlyNumber,
      decimalScale,
      allowNegative,
      min,
      max,
      suffix,
    } = this.props;

    const value = this.props.value;

    if (isDiscountType) {
      if (isDiscountType == appConfig.DISCOUNT.TYPE.FIXED.value) {
        return (
          <NumberFormat
            id={id}
            tabIndex={tabIndex}
            ref={this.ref}
            name={name}
            className={this.state.className}
            style={style}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            thousandSeparator={thousandSeparator}
            decimalScale={decimalScale}
            allowNegative={allowNegative}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              onChange({
                target: {
                  name,
                  value,
                },
              });
            }}
          />
        );
      }

      return (
        <NumberFormat
          id={id}
          tabIndex={tabIndex}
          ref={this.ref}
          name={name}
          className={this.state.className}
          style={style}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          allowNegative={allowNegative}
          onValueChange={(values) => {
            const { formattedValue, value } = values;
            onChange({
              target: {
                name,
                value,
              },
            });
          }}
        />
      );
    }

    if (onlyNumber) {
      return (
        <NumberFormat
          id={id}
          tabIndex={tabIndex}
          ref={this.ref}
          name={name}
          className={this.state.className}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          thousandSeparator={thousandSeparator}
          decimalScale={decimalScale}
          allowNegative={allowNegative}
          max={max}
          min={min}
          onValueChange={(values) => {
            const { formattedValue, value } = values;
            onChange({
              target: {
                name,
                value,
              },
            });
          }}
          suffix={suffix}
        />
      );
    }

    return (
      <div className="ladiui-input-group">
        {prefix && (
          <div className="ladiui-input-group-prepend ladiui-money">
            <span className="ladiui-input-group-text prefix">{prefix}</span>
          </div>
        )}

        <NumberFormat
          id={id}
          tabIndex={tabIndex}
          ref={this.ref}
          name={name}
          className={this.state.className}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          thousandSeparator={thousandSeparator}
          decimalScale={decimalScale}
          allowNegative={allowNegative}
          onValueChange={(values) => {
            const { formattedValue, value } = values;
            onChange({
              target: {
                name,
                value,
              },
            });
          }}
          // suffix={suffix}
        />

        {suffix && <span className="sub-icon-group">{suffix}</span>}
      </div>
    );
  }
}

export default NumberInput;
