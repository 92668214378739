import React from 'react';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { cloneDeep, map, includes, find, remove, compact, filter, forEach } from 'lodash';
import moment from 'moment';

import appConfig from '../../config/app';
import Pagination from 'react-js-pagination';
import baseHelper from '../../helpers/BaseHelper';

import * as types from '../../redux/futures/order/types';
import orderActions from '../../redux/futures/order/actions';

import * as orderTagTypes from '../../redux/futures/order_tag/types';
import orderTagActions from '../../redux/futures/order_tag/actions';

import * as printTypes from '../../redux/futures/print/types';
import printActions from '../../redux/futures/print/actions';

import * as productType from '../../redux/futures/product/types';
import productActions from '../../redux/futures/product/actions';

import paymentActions from '../../redux/futures/payment/actions';

import storePageActions from '../../redux/futures/store_page/actions';
import storePageTypes from '../../redux/futures/store_page/types';

import LoadingScene from '../../components/LoadingScene';

import LoadingTable from '../../components/LoadingTable';
import { withTranslation } from 'react-i18next';

import { appLocalStorage } from '../../localforage';
import queryString from 'query-string';

import ModalExportOrder from './components/ModalExportOrder';

import socket from '../../socket';

import OrderDateRangePicker from '../../components/OrderDateRangePicker';
import SelectOption from '../../components/SelectOption';
import ConfirmModal from '../../components/ConfirmModal';
import ItemFilter from './components/ItemFilter';
import ModalCancel from './components/ModalCancel';
import ModalConfirmPayment from './OrderEdit/components/ModalConfirmPayment';
import FilterConditionOrder from './components/FilterConditionOrder';
import LadiDropdownMenu from '../../components/LadiDropdownMenu';
import LadiPagination from '../../components/LadiPagination';
import ListTags from '../../components/ListTags';

import produce from 'immer';
import { type } from 'i18next-sprintf-postprocessor';
class Order extends React.Component {
    constructor(props) {
        super(props);

        this.moment = baseHelper.getMoment();

        const defaultConditions = {
            page: 1,
            limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        };

        this.state = {
            listOrder: [],
            totalAmount: 0,
            conditionProp: {
                status: {
                    value: appConfig.ORDER_STATUS.OPEN.CODE,
                    name: appConfig.ORDER_STATUS.OPEN.NAME,
                    condition: '=',
                },
            },
            activePage: 1,
            searchName: '',
            searchStatus: appConfig.ORDER_STATUS.OPEN.CODE,
            searchSpam: appConfig.ORDER_SPAM.NOT_SPAM.CODE,
            searchPaymentStatus: [],
            searchPaymentMethod: [],
            searchPaymentType: [],
            searchShippingStatus: [],
            searchProductTypes: [],
            product_ids: [],
            searchUtm: {
                urlPage: '',
                source: '',
                medium: '',
                campaign: '',
                term: '',
                content: '',
                msgID: '',
                ref: '',
                ad_id: '',
                fb_id: '',
            },
            searchIP: '',
            searchAssigneeID: null,
            searchCreatorID: null,
            searchTag: {},
            // fromDate: null,
            // toDate: null,
            fromDate: moment(new Date()).subtract(30, 'd'),
            toDate: moment(new Date()),
            selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
            currentOrderInfo: {},
            mode: '',
            userInfo: {},
            page_checkout_ids: [],
            isShowModalExportOrder: false,
            tabSelected: appConfig.ORDER_FILTER_TYPE.ALL,
            focusedInput: null,
            duplicationID: '',
            duplicationNo: '',
            selectedTags: [],
            isShowModalCancel: false,
            isShowModalConfirmPayment: false,
            payment_gateway_code: '',
            is_auto_shipping: false,
            payment_type: '',
            selectedIDs: [],
            conditions: defaultConditions,
        };

        this.sortBy = {};

        this.selectedID = '';
        // this.selectedIDs = [];
        this.formEditRef = React.createRef();
        this.searchTagRef = React.createRef();
        this.room = appConfig.SOCKET.ROOMS.ORDER;
    }

    componentWillMount() {
        appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
            const owner = find(result.staffs, (item) => item.ladi_uid == result.ladi_uid);
            owner.name = this.props.t('COMMON.ME');

            this.setState(
                {
                    userInfo: result,
                },
                () => {
                    this.subscribe();
                }
            );
        });
    }

    componentDidMount() {
        this.list();
        const self = this;
        socket.getInstance().on(appConfig.SOCKET.EVENTS.ASSIGNED_ORDER, (data) => {
            self.wsAssignedOrder(data);
        });

        socket.getInstance().on(appConfig.SOCKET.EVENTS.NEW_ORDER, (data) => {
            self.wsAppendNewOrder(data);
        });

        const payloadListStore = {
            keyword: '',
            search: {},
            paged: 1,
            limit: 20,
        };

        this.props.searchProduct();

        this.props.listStores(payloadListStore);

        this.props.listAllTags();
    }

    componentWillUnmount() {
        socket.getInstance().emit(appConfig.SOCKET.EVENTS.LEAVE_ORDER, { room: this.room });
    }

    componentDidUpdate() {
        window.LadiUI.init();
        setTimeout(() => {
            window.LadiUI.customInit();
        }, 0);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.order.action != nextProps.order.action) {
            if (nextProps.order.action === types.SHOW_ORDER) {
                if (nextProps.order.status) {
                    this.setState({
                        mode: appConfig.FORM_MODE.EDIT,
                        currentOrderInfo: nextProps.order.order,
                    });
                }
            }

            if (nextProps.order.action === types.UPDATE_ORDER) {
                if (nextProps.order.status) {
                    this.props.show(this.state.currentOrderInfo.order_id);

                    this.list();

                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action == types.LIST_ORDER) {
                if (nextProps.order.status) {
                    this.setState({
                        listOrder: nextProps.order.orders,
                        totalAmount: nextProps.order.total,
                    });

                    window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui table .sorting'), (item) => {
                        item.classList.remove('up');

                        if (this.sortBy[item.getAttribute('name')] == 'DESC') {
                            item.classList.add('up');
                        }
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action == types.ASSIGN_ORDER) {
                if (nextProps.order.status) {
                    this.list();
                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action == types.EXPORT_ORDER) {
                if (nextProps.order.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action === types.UPDATE_SPAMS) {
                if (nextProps.order.status) {
                    this.list();

                    window.LadiUI.closeModal('confirm-mark-spam');
                    window.LadiUI.closeModal('confirm-remove-spam');

                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action === types.REMOVE_DUPLICATION_ORDER) {
                if (nextProps.order.status) {
                    this.list();

                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action === types.CANCEL_ORDERS) {
                if (nextProps.order.status) {
                    this.list();

                    this.setState({
                        isShowModalCancel: false,
                    });

                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action === types.MARK_AS_PAIDS) {
                if (nextProps.order.status) {
                    this.list();

                    this.setState({
                        isShowModalConfirmPayment: false,
                    });

                    window.LadiUI.closeModal('confirm-last-payment');

                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }
        }

        if (this.props.printReducer.action != nextProps.printReducer.action) {
            if (this.props.printReducer.action != nextProps.printReducer.action) {
                if (nextProps.printReducer.action == printTypes.PRINTS_ORDER) {
                    if (nextProps.printReducer.status) {
                        baseHelper.print(nextProps.printReducer.source);
                    } else {
                        window.LadiUI.showErrorMessage('Thông báo', nextProps.printReducer.message, 'OK');
                    }
                }
            }
        }
    }

    subscribe() {
        const { tabSelected } = this.state;
        switch (tabSelected) {
            case appConfig.ORDER_FILTER_TYPE.DRAFT:
            case appConfig.ORDER_FILTER_TYPE.DUPLICATION:
            case appConfig.ORDER_FILTER_TYPE.SEARCH_ALL:
            case appConfig.ORDER_FILTER_TYPE.ALL:
                socket.getInstance().emit(appConfig.SOCKET.EVENTS.JOIN_ORDER, { room: this.room });
                break;
            case appConfig.ORDER_FILTER_TYPE.SPAM:
            case appConfig.ORDER_FILTER_TYPE.PAYMENT_PENDING:
            case appConfig.ORDER_FILTER_TYPE.SHIPPING_PENDING:
                socket.getInstance().emit(appConfig.SOCKET.EVENTS.LEAVE_ORDER, { room: this.room });
                break;
        }
    }

    wsAppendNewOrder = (newOrder) => {
        const { role, ladi_uid: ladiUID } = this.state.userInfo;

        const validOrder =
            includes([appConfig.ROLE.OWNER.CODE, appConfig.ROLE.MANAGER.CODE], role) ||
            !newOrder.assignee_id ||
            newOrder.assignee_id == ladiUID ||
            newOrder.creator_id == ladiUID;
        if (!validOrder) {
            return;
        }
        const { selectedLimit } = this.state;

        // Kiểm tra xem order đã tồn tại trong danh sách hiện tại hay chưa
        let orders = cloneDeep(this.props.order.orders || []);
        let matched = find(orders, (item) => item.order_id == newOrder.order_id);

        if (matched) {
            return false;
        }

        // Kiểm tra xem order có bị trùng không?
        // Nếu không tìm thấy thì bye bye
        if (newOrder.parent_id) {
            matched = find(orders, (item) => item.order_id == newOrder.parent_id);

            if (!matched) {
                return;
            }
        }

        let totalRecord = cloneDeep(this.props.order.totalRecord);
        if (matched) {
            matched.total_child = baseHelper.parseInt(matched.total_child) + 1;
        } else {
            totalRecord++;
            orders.unshift(newOrder);
            orders = orders.slice(0, selectedLimit);
        }

        this.props.wsAppendNewOrder(orders, totalRecord);
    };

    wsAssignedOrder = (data) => {
        let orders = cloneDeep(this.props.order.orders);
        const matched = find(orders, (item) => item.order_id == data.order_id);

        if (!matched) {
            return;
        }

        matched.assignee_id = data.assignee_id;
        this.props.wsAssignedOrder(orders);
    };

    /**
     * list order
     */
    list = (resetActivePageFlag = true) => {
        if (document.getElementById('checkAllItem')) {
            document.getElementById('checkAllItem').checked = false;
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        const {
            searchName,
            selectedLimit,
            fromDate,
            toDate,
            searchSpam,
            conditions,
            conditionProp,
            searchStatus,
            searchPaymentStatus,
            searchPaymentMethod,
            searchPaymentType,
            searchShippingStatus,
            searchProductTypes,
            product_ids,
            searchUtm,
            searchTag,
            tabSelected,
            searchAssigneeID,
            searchCreatorID,
            searchIP,
            duplicationID,
            page_checkout_ids,
        } = this.state;

        let activePage = conditions.page;

        if (resetActivePageFlag) {
            activePage = 1;
            conditions.page = 1;
            this.setState({
                conditions,
            });
        }

        // Reset selectedIDs
        // this.selectedIDs = [];
        this.setState({
            selectedIDs: [],
        });

        // let tagIDs = this.searchTagRef.current.getIDs();
        // tagIDs = compact(tagIDs);

        let arrayPaymentType = [];
        for (let item of searchPaymentType) {
            arrayPaymentType = [...arrayPaymentType, ...JSON.parse(item)];
        }

        const data = {
            search: {
                status: searchStatus,
                mark_spam: searchSpam,
                payment_status: searchPaymentStatus,
                payment_method: searchPaymentMethod,
                payment_type: arrayPaymentType,
                shipping_status: searchShippingStatus,
                product_types: searchProductTypes,
                product_ids: product_ids,
                name: searchName,
                from_date: fromDate ? moment(new Date(fromDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
                to_date: toDate ? moment(new Date(toDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
                tag_ids: searchTag,
                ip: searchIP,
                assignee_id: searchAssigneeID,
                creator_id: searchCreatorID,
                url_page: searchUtm.urlPage,
                utm_source: searchUtm.source,
                utm_medium: searchUtm.medium,
                utm_campaign: searchUtm.campaign,
                utm_term: searchUtm.term,
                utm_content: searchUtm.content,
                msg_id: searchUtm.msgID,
                ref: searchUtm.ref,
                ad_id: searchUtm.ad_id,
                fb_id: searchUtm.fb_id,
                page_checkout_ids: page_checkout_ids,
            },
            paged: activePage,
            limit: conditions.limit,
            sort: this.sortBy,
        };

        data.type = tabSelected;
        if (tabSelected == appConfig.ORDER_FILTER_TYPE.DUPLICATION) {
            data.parent_id = duplicationID;
        }

        const { customer_id: customerID } = queryString.parse(this.props.location.search);
        if (customerID) {
            data.search.customer_id = customerID;
        }

        this.props.list(data);
    };

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.list();
        }
    };

    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        let { conditions } = this.state;
        conditions.limit = option.value;

        // this.setState({ selectedLimit: option.value }, () => {
        this.setState({ conditions: conditions }, () => {
            this.list();
        });
    };

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        let { conditions } = this.state;
        conditions.page = pageNumber;

        this.setState({ conditions }, () => {
            this.list(false);
        });
    };
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        let selectedIDsTg = [];
        if (checked) {
            selectedIDsTg = map(this.props.order.orders, (order) => order.order_id);
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        this.setState({
            selectedIDs: selectedIDsTg,
        });
    };

    checkItem = (event, orderID) => {
        const { checked } = event.target;
        const { selectedIDs } = this.state;

        if (checked) {
            if (!includes(selectedIDs, orderID)) {
                selectedIDs.push(orderID);
            }

            if (selectedIDs.length == this.props.order.orders.length) {
                document.getElementById('checkAllItem').checked = true;
            }
        } else {
            let elm = document.getElementById('checkAllItem');
            if (elm) elm.checked = false;
            remove(selectedIDs, (selectedOrderID) => selectedOrderID == orderID);
        }
        this.setState({
            selectedIDs,
        });
    };

    deletes = () => {
        const { selectedIDs } = this.state;

        if (selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        this.props.deletes(selectedIDs);
    };

    deleteItem = (orderID) => {
        this.props.delete(orderID);
    };

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');

        window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui table .sorting'), (item) => {
            if (item.getAttribute('name') != name) {
                item.classList.remove('up');
            }
        });

        this.sortBy = {};

        // Change arrow up and down
        if (includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({}, () => this.list());
    };

    /**
     * Tim kiem theo status
     */
    onChangeSearchStatus = (status) => {
        this.setState({
            searchStatus: status,
        });
    };

    onChangeSearchSpam = (spam) => {
        this.setState({
            searchSpam: spam,
        });
    };

    onChangePaymentStatus = (paymentStatus) => {
        if (paymentStatus === null) {
            this.setState({
                searchPaymentStatus: [],
            });

            return;
        }
        const { searchPaymentStatus } = this.state;
        if (includes(searchPaymentStatus, paymentStatus)) {
            remove(searchPaymentStatus, (item) => item == paymentStatus);
        } else {
            searchPaymentStatus.push(paymentStatus);
        }

        this.setState({
            searchPaymentStatus,
        });
    };

    onChangePaymentMethod = (paymentMethod) => {
        if (paymentMethod === null) {
            this.setState({
                searchPaymentMethod: [],
            });

            return;
        }
        const { searchPaymentMethod } = this.state;
        if (includes(searchPaymentMethod, paymentMethod)) {
            remove(searchPaymentMethod, (item) => item == paymentMethod);
        } else {
            searchPaymentMethod.push(paymentMethod);
        }

        this.setState({
            searchPaymentMethod,
        });
    };

    onChangePaymentType = (paymentType) => {
        if (paymentType === null) {
            this.setState({
                searchPaymentType: [],
            });
            return;
        }

        const { searchPaymentType } = this.state;
        if (includes(searchPaymentType, paymentType)) {
            remove(searchPaymentType, (item) => item == paymentType);
        } else {
            searchPaymentType.push(paymentType);
        }

        this.setState({
            searchPaymentType,
        });
    };

    onChangeShippingStatus = (shippingStatus) => {
        if (shippingStatus === null) {
            this.setState({
                searchShippingStatus: [],
            });

            return;
        }

        const { searchShippingStatus } = this.state;
        if (includes(searchShippingStatus, shippingStatus)) {
            remove(searchShippingStatus, (item) => item == shippingStatus);
        } else {
            searchShippingStatus.push(shippingStatus);
        }

        this.setState({
            searchShippingStatus,
        });
    };

    onChangeProductType = (productType) => {
        if (productType === null) {
            this.setState({
                searchProductTypes: [],
            });

            return;
        }
        const { searchProductTypes } = this.state;
        if (includes(searchProductTypes, productType)) {
            remove(searchProductTypes, (item) => item == productType);
        } else {
            searchProductTypes.push(productType);
        }

        this.setState({
            searchProductTypes,
        });
    };

    onChangeStaff = (staffID) => {
        this.setState({
            searchAssigneeID: staffID,
        });
    };

    onChangeCreator = (creatorID) => {
        this.setState({
            searchCreatorID: creatorID,
        });
    };

    onChangeInputUtm = (event) => {
        this.setState({
            searchUtm: {
                ...this.state.searchUtm,
                [event.target.name]: event.target.value,
            },
        });
    };

    getPaymentStatus = (status) => {
        const matched = find(appConfig.PAYMENT_STATUS, (item) => item.CODE == status);
        if (matched) {
            return matched.NAME;
        }
    };

    getShippingStatus = (status) => {
        const matched = find(appConfig.SHIPPING_STATUS, (item) => item.CODE == status);
        if (matched) {
            return matched.NAME;
        }
    };

    getPaymentType = (type) => {
        const matched = find(appConfig.SN_PAYMENT_METHODS, (item) => item.CODE == type);
        if (matched) {
            return matched.NAME;
        }
    };

    getListPaymentTypes = (types) => {
        let array = [];
        for (let i = 0; i < types.length; i++) {
            let findIndex = array.findIndex((item) => item.NAME === types[i].NAME);
            if (findIndex === -1) {
                array.push({
                    CODE: [types[i].CODE],
                    NAME: types[i].NAME,
                });
            } else {
                array[findIndex].CODE.push(types[i].CODE);
            }
        }
        for (let item of array) {
            item.CODE = JSON.stringify(item.CODE);
        }
        return array;
    };

    getPaymentMethod = (method) => {
        const matched = find(appConfig.PAYMENT_PARTNERS, (item) => item.CODE == method);
        if (matched) {
            return matched.NAME;
        }
    };

    onChangeTab = (tabID, event) => {
        if (event) {
            event.stopPropagation();
        }
        this.clearFilter(() => {
            let searchStatus = this.state.searchStatus;
            let searchSpam = this.state.searchSpam;
            let searchPaymentStatus = this.state.searchPaymentStatus;
            let searchPaymentMethod = this.state.searchPaymentMethod;
            let searchPaymentType = this.state.searchPaymentType;
            let searchShippingStatus = this.state.searchShippingStatus;
            switch (tabID) {
                case appConfig.ORDER_FILTER_TYPE.DRAFT:
                    searchStatus = appConfig.ORDER_STATUS.OPEN.CODE;
                    searchSpam = appConfig.ORDER_SPAM.NOT_SPAM.CODE;
                    searchPaymentMethod = [];
                    searchPaymentType = [];
                    break;
                case appConfig.ORDER_FILTER_TYPE.PAYMENT_PENDING:
                    searchStatus = appConfig.ORDER_STATUS.OPEN.CODE;
                    searchPaymentStatus = [appConfig.PAYMENT_STATUS.PENDING.CODE];
                    searchSpam = appConfig.ORDER_SPAM.NOT_SPAM.CODE;
                    searchPaymentMethod = [];
                    searchPaymentType = [];
                    break;
                case appConfig.ORDER_FILTER_TYPE.SHIPPING_PENDING:
                    searchStatus = appConfig.ORDER_STATUS.OPEN.CODE;
                    searchShippingStatus = [appConfig.SHIPPING_STATUS.PENDING.CODE];
                    searchSpam = appConfig.ORDER_SPAM.NOT_SPAM.CODE;
                    searchPaymentMethod = [];
                    searchPaymentType = [];
                    break;
                case appConfig.ORDER_FILTER_TYPE.SPAM:
                    searchSpam = appConfig.ORDER_SPAM.SPAM.CODE;
                    searchPaymentMethod = [];
                    searchPaymentType = [];
                    break;
                case appConfig.ORDER_FILTER_TYPE.ALL:
                    break;
            }

            this.setState(
                {
                    tabSelected: tabID,
                    searchStatus,
                    searchSpam,
                    searchPaymentStatus,
                    searchPaymentMethod,
                    searchPaymentType,
                    searchShippingStatus,
                },
                () => {
                    this.subscribe();
                    this.list();
                }
            );
        });
    };

    clearFilter = (callback) => {
        this.setState(
            {
                duplicationID: '',
                duplicationNo: '',
                // searchName: "",
                searchStatus: null,
                searchSpam: null,
                searchTag: {},
                // fromDate: null,
                // toDate: null,
                selectedTags: [],
                searchStatus: null,
                searchAssigneeID: '',
                searchCreatorID: '',
                searchPaymentStatus: [],
                searchShippingStatus: [],
                searchProductTypes: [],
                product_ids: [],
                searchPaymentMethod: [],
                searchIP: '',
                searchUtm: {
                    urlPage: '',
                    source: '',
                    medium: '',
                    campaign: '',
                    term: '',
                    content: '',
                    ref: '',
                    ad_id: '',
                    fb_id: '',
                },
                page_checkout_ids: [],
            },
            () => {
                if (callback) {
                    callback();
                }
            }
        );
    };

    /******************************CALLBACK MODAL*********************/
    exportOrder = (type) => {
        let ids;
        const { selectedIDs } = this.state;
        switch (baseHelper.parseInt(type)) {
            case appConfig.EXPORT_ORDER.ALL_ORDER:
                ids = appConfig.EXPORT_TYPE.ALL;
                break;
            case appConfig.EXPORT_ORDER.ON_PAGE:
                const onPageIDs = map(this.props.order.orders, (item) => item.order_id);
                ids = onPageIDs;
                break;
            case appConfig.EXPORT_ORDER.SELECTED_ORDER:
                if (baseHelper.isEmpty(selectedIDs)) {
                    window.LadiUI.toastCustom('warning', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
                    return;
                }
                ids = selectedIDs;
                break;
        }

        const {
            searchName,
            selectedLimit,
            fromDate,
            toDate,
            searchSpam,
            conditions,
            conditionProp,
            searchStatus,
            searchPaymentStatus,
            searchPaymentMethod,
            searchPaymentType,
            searchShippingStatus,
            searchProductTypes,
            searchUtm,
            searchTag,
            tabSelected,
            searchAssigneeID,
            searchCreatorID,
            searchIP,
            duplicationID,
        } = this.state;

        let arrayPaymentType = [];
        for (let item of searchPaymentType) {
            arrayPaymentType = [...arrayPaymentType, ...JSON.parse(item)];
        }

        let search = {
            status: searchStatus,
            mark_spam: searchSpam,
            payment_status: searchPaymentStatus,
            payment_method: searchPaymentMethod,
            payment_type: arrayPaymentType,
            shipping_status: searchShippingStatus,
            product_types: searchProductTypes,
            name: searchName,
            from_date: fromDate ? moment(new Date(fromDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
            to_date: toDate ? moment(new Date(toDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
            tag_ids: searchTag,
            ip: searchIP,
            assignee_id: searchAssigneeID,
            creator_id: searchCreatorID,
            url_page: searchUtm.urlPage,
            utm_source: searchUtm.source,
            utm_medium: searchUtm.medium,
            utm_campaign: searchUtm.campaign,
            utm_term: searchUtm.term,
            utm_content: searchUtm.content,
            msg_id: searchUtm.msgID,
            ref: searchUtm.ref,
            ad_id: searchUtm.ad_id,
            fb_id: searchUtm.fb_id,
        };

        this.props.exportOrder({ ids, search, type: tabSelected });
    };

    exportTransaction = (selected) => {
        let ids = [];

        if (selected == appConfig.EXPORT_ORDER.ALL_ORDER) {
            ids = ['all'];
        } else {
            ids = selected;
        }

        this.props.exportTransaction(ids);
    };
    /****************************************************************/

    openModalRemoveSpam = () => {
        if (this.state.selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        window.LadiUI.showModal('confirm-remove-spam');
    };

    openModalMarkSpam = () => {
        if (this.state.selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        window.LadiUI.showModal('confirm-mark-spam');
    };

    /******************************************CANCEL********************************/
    openModalCancel = () => {
        if (this.state.selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        this.setState({
            isShowModalCancel: true,
        });
    };

    openModalConfirmPayment = async () => {
        if (this.state.selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        let orders = this.props.order.orders.filter((item) => this.state.selectedIDs.includes(item.order_id));
        let isOrder = false;

        for await (let item of orders) {
            if (
                item.is_draft === 1 ||
                item.payment_status !== appConfig.PAYMENT_STATUS.PENDING.CODE ||
                item.status !== appConfig.ORDER_STATUS.OPEN.CODE
            ) {
                isOrder = true;
                break;
            }
        }

        if (isOrder) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.CHOOSE_UNPAID_ORDERS'));
            return;
        }

        this.setState({
            isShowModalConfirmPayment: true,
        });
    };

    hideModalConfirmPayment = () => {
        this.setState({
            isShowModalConfirmPayment: false,
        });
    };

    hideModalCancel = () => {
        this.setState({
            isShowModalCancel: false,
        });
    };

    cancelOrders = (reason) => {
        this.props.cancelOrders(this.state.selectedIDs, reason);
    };

    confirmPayments = async (payment_gateway_code, payment_type, is_auto_shipping) => {
        await this.setState({
            payment_gateway_code,
            payment_type,
            is_auto_shipping,
            isShowModalConfirmPayment: false,
        });

        window.LadiUI.showModal('confirm-last-payment');
    };

    confirmLastPayment = () => {
        let data = {
            order_ids: this.state.selectedIDs,
            payment_gateway_code: this.state.payment_gateway_code,
            is_auto_shipping: this.state.is_auto_shipping,
            payment_type: this.state.payment_type,
        };

        this.props.markAsPaids(data);
    };
    /********************************************************************************/

    prints = () => {
        if (this.state.selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        this.props.prints(this.state.selectedIDs);
    };

    setDuplication = (orderID, referenceNo) => {
        this.setState(
            {
                duplicationID: orderID,
                duplicationNo: `#${referenceNo}`,
                tabSelected: appConfig.ORDER_FILTER_TYPE.DUPLICATION,
            },
            () => {
                document.getElementById('triggerTabDuplication').click();
                this.list(true);
            }
        );
    };

    eventSelectAction = (action) => {
        switch (action) {
            case 'REMOVE_MARK_SPAM':
                this.openModalRemoveSpam();
                break;
            case 'MARK_SPAM':
                this.openModalMarkSpam();
                break;
            case 'CANCEL_ORDER':
                this.openModalCancel();
                break;
            case 'CONFIRM_PAYMENT':
                this.openModalConfirmPayment();
                break;
            case 'PRINT':
                this.prints();
                break;
            default:
        }
    };

    filterActionProp = async (conditions) => {
        this.clearFilter();

        let filter = {
            tabSelected: appConfig.ORDER_FILTER_TYPE.SEARCH_ALL,
            searchUtm: {},
        };

        for await (const condition of conditions) {
            switch (condition.attributeFilter.value) {
                case 'status':
                    filter.searchStatus = condition.valueFilter.value;
                    break;
                case 'payment_status':
                    filter.searchPaymentStatus = condition.listFilter.map((item) => item.value);
                    break;
                case 'payment_method':
                    filter.searchPaymentMethod = condition.listFilter.map((item) => item.value);
                    break;
                case 'payment_shipping_status':
                    filter.searchShippingStatus = condition.listFilter.map((item) => item.value);
                    break;
                case 'product_ids':
                    filter.product_ids = condition.listFilter.map((item) => item.value);
                    break;
                case 'product_type':
                    filter.searchProductTypes = condition.listFilter.map((item) => item.value);
                    break;
                case 'utm_medium':
                    filter.searchUtm.medium = condition.valueFilter.value;
                    break;
                case 'creator':
                    filter.searchCreatorID = condition.valueFilter.value;
                    break;
                case 'staff':
                    filter.searchAssigneeID = condition.valueFilter.value;
                    break;
                case 'tags':
                    filter.searchTag = condition.listFilter.map((item) => item.value);
                    break;
                case 'ip':
                    filter.searchIP = condition.valueFilter.value;
                    break;
                case 'spam':
                    filter.searchSpam = condition.valueFilter.value;
                    break;
                case 'utm_page':
                    filter.searchUtm.urlPage = condition.valueFilter.value;
                    break;
                case 'utm_source':
                    filter.searchUtm.source = condition.valueFilter.value;
                    break;
                case 'utm_campaign':
                    filter.searchUtm.campaign = condition.valueFilter.value;
                    break;
                case 'utm_term':
                    filter.searchUtm.term = condition.valueFilter.value;
                    break;
                case 'utm_content':
                    filter.searchUtm.content = condition.valueFilter.value;
                    break;
                case 'msg_id':
                    filter.searchUtm.msgID = condition.valueFilter.value;
                    break;
                case 'ref':
                    filter.searchUtm.ref = condition.valueFilter.value;
                    break;
                case 'ad_id':
                    filter.searchUtm.ad_id = condition.valueFilter.value;
                    break;
                case 'fb_id':
                    filter.searchUtm.fb_id = condition.valueFilter.value;
                    break;
                case 'page_checkout_ids':
                    filter.page_checkout_ids = [condition.valueFilter.value];
                    break;
                default:
                    break;
            }
        }

        this.setState(
            {
                ...filter,
            },
            () => {
                this.list();
            }
        );
    };

    handleClickTag = (item, selectedTags) => {
        this.setState(
            {
                searchTag: {
                    ...this.state.searchTag,
                    order_tag_id: item,
                },
                selectedTags,
            },
            () => {
                this.list();
            }
        );
    };

    /****************************************************************/
    render() {
        const { t } = this.props;
        const { totalRecord, totalDuplication } = this.props.order;
        const {
            tabSelected,
            conditions,
            searchName,
            searchSpam,
            searchStatus,
            userInfo,
            searchPaymentStatus,
            searchPaymentMethod,
            searchPaymentType,
            searchShippingStatus,
            searchProductTypes,
            searchUtm,
            searchAssigneeID,
            searchCreatorID,
            searchIP,
            selectedTags,
            duplicationNo,
            conditionProp,
            listOrder,
        } = this.state;

        let fromItem = 0;
        let toItem = 0;
        if ((this.props.order.orders || []).length > 0) {
            fromItem = (conditions.page - 1) * conditions.limit + 1;
            toItem = fromItem + this.props.order.orders.length - 1;
        }

        const msgShowItem = `${t('PAGINATION.SHOW')} ${fromItem} ${t('PAGINATION.TO')} ${toItem} ${t('PAGINATION.OF')}
     ${baseHelper.formatNumber(totalRecord)} ${t('PAGINATION.ITEMS')} ${t('ORDERS.INCLUDE_DUPLICATION', totalDuplication)}`;
        const isLoadingData = this.props.order.loading && this.props.order.waiting == types.LIST_ORDER;
        const isLoadingExportOrder = this.props.order.loading && includes([types.EXPORT_ORDER], this.props.order.waiting);
        const isLoadingExportTransaction = this.props.order.loading && includes([types.EXPORT_TRANSACTION], this.props.order.waiting);
        const isLoadingAssign = this.props.order.loading && types.ASSIGN_ORDER == this.props.order.waiting;
        const isLoadingUpdateSpam = this.props.order.loading && types.UPDATE_SPAMS == this.props.order.waiting;
        const isLoadingRemoveDuplication = this.props.order.loading && types.REMOVE_DUPLICATION_ORDER == this.props.order.waiting;
        const isLoadingCancels = this.props.order.loading && types.CANCEL_ORDERS == this.props.order.waiting;
        const isLoadingPrint = this.props.printReducer.loading && this.props.printReducer.waiting == printTypes.PRINTS_ORDER;
        const isLoadingModalConfirmPayment = this.props.order.loading && types.MARK_AS_PAIDS == this.props.order.waiting;

        const allTags = this.props.orderTagReducer.allTags || [];
        const allStorePages = this.props.storePageReducer.store_pages || [];
        const productLists = this.props.productReducer.products || [];
        const staffs = userInfo.staffs;
        const listAction = [
            { value: 'PRINT', name: t('COMMON.PRINT') },
            { value: 'REMOVE_MARK_SPAM', name: t('ORDERS.REMOVE_MARK_SPAM') },
            { value: 'MARK_SPAM', name: t('ORDERS.MARK_SPAM') },
            { value: 'CANCEL_ORDER', name: t('ORDERS.CANCEL_ORDER') },
            { value: 'CONFIRM_PAYMENT', name: t('ORDERS.CONFIRM_PAYMENT') },
        ];

        return (
            <div className='page-content'>
                {(isLoadingAssign || isLoadingUpdateSpam || isLoadingRemoveDuplication || isLoadingPrint) && <LoadingScene blur={true} />}
                <div className='block-content'>
                    <div className='ladiui-table-actions mb-24'>
                        <div className='ladiui content-main-tools block-filter-condition flex-row'>
                            <div className='ladiui btn-group block-filter-header'>
                                <FilterConditionOrder
                                    filterActionProp={this.filterActionProp}
                                    conditionProp={conditionProp}
                                    staffs={staffs}
                                    products={productLists}
                                    allTags={allTags}
                                    allStorePages={allStorePages}
                                />
                            </div>
                            <div className='ladiui search-group mr-24'>
                                <input
                                    id='keyword_search'
                                    className='ladiui search-field dropdown-toggle form-control search-width'
                                    name='searchName'
                                    placeholder={t('ORDERS.SEARCH_ORDER')}
                                    aria-expanded='false'
                                    value={searchName}
                                    onChange={this.onChangeInput}
                                    onKeyDown={this.onKeyDownInput}
                                />
                                <i className='ladiui icon icon-search' onClick={this.list}></i>
                            </div>
                            <div className='date-filter'>
                                <OrderDateRangePicker
                                    startDateId='fromDate'
                                    endDateId='toDate'
                                    startDate={this.state.fromDate}
                                    endDate={this.state.toDate}
                                    onDatesChange={({ startDate, endDate }) => {
                                        this.setState(
                                            {
                                                fromDate: baseHelper.getFromDate(startDate),
                                                toDate: baseHelper.getToDate(endDate),
                                            },
                                            () => {
                                                if ((startDate && endDate) || (!startDate && !endDate)) {
                                                    this.list();
                                                }
                                            }
                                        );
                                    }}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={(focusedInput) => {
                                        this.setState({ focusedInput });
                                    }}
                                    showDefaultInputIcon={true}
                                    inputIconPosition='after'
                                    small={true}
                                    hideKeyboardShortcutsPanel={true}
                                    customInputIcon={<i className='ladi-icon icon-calendar-date-2' />}
                                    startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                                    endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                                    isOutsideRange={() => false}
                                    isDayHighlighted={(date) => {
                                        return this.moment().isSame(date, 'day');
                                    }}
                                    minimumNights={0}
                                    showClearDates={true}
                                />
                            </div>
                            <div className='ladiui btn-group custom-btn-list-landingpage'>
                                <button
                                    className='ladiui btn btn-light'
                                    onClick={() =>
                                        this.setState({
                                            isShowModalExportOrder: true,
                                        })
                                    }
                                >
                                    <img
                                        src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-export.svg'
                                        style={{ marginBottom: '-2px', marginRight: '10px' }}
                                    />
                                    {t('ACTIONS.EXPORT')}
                                </button>
                            </div>
                        </div>
                        <div className='action-btn'>
                            <button
                                type='button'
                                className='ladiui btn btn-primary btn-lg btn-open-modal'
                                data-toggle='modal'
                                onClick={() => this.props.history.push('/orders/create')}
                                data-target='#white-page'
                            >
                                <img
                                    className='ladiui btn-custom-img'
                                    src='https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg'
                                    alt=''
                                />
                                <div className='ladiui btn-custom-text '>{t('ORDERS.ADD_ORDER')}</div>
                            </button>
                        </div>
                    </div>

                    <div className='order-tab'>
                        <ul className='ladiui nav nav-tabs overflow-hide' role='tab-order-all'>
                            <li className='ladiui nav-item' id='tabAll' role='tab-order-all-item'>
                                <span
                                    className={`ladiui tab-link ${tabSelected === appConfig.ORDER_FILTER_TYPE.ALL ? 'active' : ''}`}
                                    data-toggle='tab'
                                    data-tab='tabAll'
                                    onClick={() => this.onChangeTab(appConfig.ORDER_FILTER_TYPE.ALL)}
                                >
                                    {t('ORDERS.TAB_ALL')}
                                </span>
                            </li>
                            <li className='ladiui nav-item' id='tabDraft' role='tab-order-all-item'>
                                <span
                                    className={`ladiui tab-link ${tabSelected === appConfig.ORDER_FILTER_TYPE.DRAFT ? 'active' : ''}`}
                                    data-toggle='tab'
                                    data-tab='tabDraft'
                                    onClick={(event) => this.onChangeTab(appConfig.ORDER_FILTER_TYPE.DRAFT, event)}
                                >
                                    {t('ORDERS.TAB_DRAFT')}
                                </span>
                            </li>
                            <li className='ladiui nav-item' id='tabShippingPending' role='tab-order-all-item'>
                                <span
                                    className={`ladiui tab-link ${
                                        tabSelected === appConfig.ORDER_FILTER_TYPE.SHIPPING_PENDING ? 'active' : ''
                                    }`}
                                    data-toggle='tab'
                                    data-tab='tabShippingPending'
                                    onClick={() => this.onChangeTab(appConfig.ORDER_FILTER_TYPE.SHIPPING_PENDING)}
                                >
                                    {t('ORDERS.TAB_SHIPPING_PENDING')}
                                </span>
                            </li>
                            <li className='ladiui nav-item' id='tabPaymentPending' role='tab-order-all-item'>
                                <span
                                    className={`ladiui tab-link ${
                                        tabSelected === appConfig.ORDER_FILTER_TYPE.PAYMENT_PENDING ? 'active' : ''
                                    }`}
                                    data-toggle='tab'
                                    data-tab='tabPaymentPending'
                                    onClick={() => this.onChangeTab(appConfig.ORDER_FILTER_TYPE.PAYMENT_PENDING)}
                                >
                                    {t('ORDERS.TAB_PAYMENT_PENDING')}
                                </span>
                            </li>
                            <li className='ladiui nav-item' id='tabSpam' role='tab-order-all-item'>
                                <span
                                    className={`ladiui tab-link ${tabSelected === appConfig.ORDER_FILTER_TYPE.SPAM ? 'active' : ''}`}
                                    data-toggle='tab'
                                    data-tab='tabSpam'
                                    onClick={() => this.onChangeTab(appConfig.ORDER_FILTER_TYPE.SPAM)}
                                >
                                    {t('ORDERS.TAB_SPAM')}
                                </span>
                            </li>

                            <li
                                className='ladiui nav-item'
                                id='tabDuplication'
                                role='tab-order-all-item'
                                style={
                                    this.state.tabSelected == appConfig.ORDER_FILTER_TYPE.DUPLICATION
                                        ? { display: 'block' }
                                        : { display: 'none' }
                                }
                            >
                                <span
                                    className={`ladiui tab-link ${tabSelected === appConfig.ORDER_FILTER_TYPE.DUPLICATION ? 'active' : ''}`}
                                    data-toggle='tab'
                                    id='triggerTabDuplication'
                                    data-tab='tabDuplication'
                                >
                                    {t('ORDERS.TAB_SEARCH_DUPLICATION', duplicationNo)}
                                </span>
                            </li>
                            <li
                                className='ladiui nav-item'
                                id='tabSearch'
                                role='tab-order-all-item'
                                style={
                                    this.state.tabSelected == appConfig.ORDER_FILTER_TYPE.SEARCH_ALL
                                        ? { display: 'block' }
                                        : { display: 'none' }
                                }
                            >
                                <span
                                    className={`ladiui tab-link ${tabSelected === appConfig.ORDER_FILTER_TYPE.SEARCH_ALL ? 'active' : ''}`}
                                    data-toggle='tab'
                                    id='triggerTabSearch'
                                    data-tab='tabSearch'
                                >
                                    {t('ORDERS.TAB_SEARCH')}
                                </span>
                            </li>
                        </ul>
                    </div>
                    {isLoadingData ? (
                        <LoadingTable />
                    ) : (
                        <div>
                            <div className='ladi-card ladiui-table-responsive'>
                                <table className='ladiui table text-left table-order'>
                                    <thead style={{ position: 'relative' }}>
                                        <tr className='ladiui table-vertical header'>
                                            <th scope='col' className='ladiui checkall'>
                                                <input
                                                    id='checkAllItem'
                                                    onClick={this.checkAllItem}
                                                    type='checkbox'
                                                    className='ladiui-checkall ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle'
                                                />
                                            </th>
                                            {this.state.selectedIDs.length <= 0 ? (
                                                <th scope='col' style={{ width: '180px', maxWidth: 'fit-content' }}>
                                                    {t('ORDERS.REF_NO')}{' '}
                                                </th>
                                            ) : (
                                                <th scope='col' style={{ width: '180px', maxWidth: 'fit-content' }}>
                                                    {t('ORDERS.REF_NO')}
                                                    <LadiDropdownMenu
                                                        labelDropdown={'Chọn hành động'}
                                                        listProp={listAction}
                                                        defaultTextProp={t('ACTIONS.TITLE')}
                                                        hideLabel={true}
                                                        cbProp={this.eventSelectAction}
                                                        classSize='btn-sm '
                                                        classWidth='w-180'
                                                        classDropdown='w-180 action-record-table'
                                                    />
                                                </th>
                                            )}
                                            <th scope='col'>{t('ORDERS.CUSTOMER')}</th>
                                            <th scope='col'>{t('COMMON.PHONE')}</th>
                                            <th scope='col'>{t('COMMON.ORDERED_AT')}</th>
                                            <th scope='col'>{t('ORDERS.STATUS')}</th>
                                            <th scope='col'>{t('ORDERS.PAYMENT_METHOD')}</th>
                                            {/* <th className="text-left">{t("ORDERS.PAYMENT_TYPE")}</th> */}
                                            <th scope='col'>{t('ORDERS.LB_SHIPPING')}</th>
                                            <th scope='col'>{t('ORDERS.TOTAL')}</th>
                                            <th scope='col'>{t('ORDERS.ASSIGNEE')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {map(listOrder, (order) => {
                                            const tagIDs = order.tag_ids ? order.tag_ids.split(appConfig.SEPARATOR.DEFAULT) : [];
                                            const tagNames = order.tag_names ? order.tag_names.split(appConfig.SEPARATOR.DEFAULT) : [];
                                            const tagColors = order.tag_colors ? order.tag_colors.split(appConfig.SEPARATOR.DEFAULT) : [];

                                            const isDuplicate = order.parent_id;
                                            const isShow = order.is_show == appConfig.STATUS.ACTIVE;
                                            let className = 'ladiui table-vertical main';
                                            if (order.status == appConfig.ORDER_STATUS.CANCELED.CODE) {
                                                className = `${className} order-cancel`;
                                            }

                                            if (isDuplicate) {
                                                className = `${className} duplicate`;
                                            }

                                            return (
                                                <React.Fragment key={order.order_id}>
                                                    <tr
                                                        className={`${className}`}
                                                        style={tagIDs.length > 0 ? { borderBottom: 'none' } : {}}
                                                    >
                                                        <td>
                                                            <input
                                                                type='checkbox'
                                                                onClick={(event) => this.checkItem(event, order.order_id)}
                                                                className='ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle'
                                                            />
                                                        </td>
                                                        <td
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                minWidth: '150px',
                                                                maxWidth: 'fit-content',
                                                            }}
                                                        >
                                                            <NavLink
                                                                className='order-table-item block'
                                                                to={`/orders/${order.order_id}`}
                                                                // onClick={() => {
                                                                //   document.getElementById("menu-more-filter").click();
                                                                // }}
                                                            >{`${
                                                                this.props.storeReducer &&
                                                                this.props.storeReducer.userInfo.currentStore &&
                                                                this.props.storeReducer.userInfo.currentStore.order_prefix
                                                                    ? this.props.storeReducer.userInfo.currentStore.order_prefix
                                                                    : ''
                                                            }${order.reference_no}`}</NavLink>
                                                            {order.total_child > 0 && (
                                                                <span
                                                                    onClick={() => this.setDuplication(order.order_id, order.reference_no)}
                                                                    className='badge badge-light flex mr-16 ml-16'
                                                                >
                                                                    <i className='ladi-icon icon-duplicate mr-4' />({order.total_child})
                                                                </span>
                                                            )}
                                                            {order.parent_id && (
                                                                <span
                                                                    onClick={() =>
                                                                        this.setDuplication(order.parent_id, order.parent_reference_no)
                                                                    }
                                                                    className='badge badge-child-light flex mr-16 ml-16'
                                                                >
                                                                    <i className='ladi-icon icon-duplicate mr-4' />#
                                                                    {order.parent_reference_no}
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td className={`${!isShow && 'prevent-click'}`}>
                                                            <a href={`/customers?customer_id=${order.customer_id}`}>{`${baseHelper.getText(
                                                                order.customer_last_name
                                                            )} ${baseHelper.getText(order.customer_first_name)}`}</a>
                                                        </td>
                                                        <td>{order.customer_phone}</td>
                                                        <td>{baseHelper.formatStrToDate(order.ordered_at)}</td>
                                                        <td>
                                                            {this.getPaymentStatus(order.payment_status)}
                                                            {order.payment_retry_get_status >= 5 &&
                                                                order.payment_status != appConfig.PAYMENT_STATUS.SUCCESS && (
                                                                    <span
                                                                        style={{ marginLeft: 5 }}
                                                                        data-tooltip={t('ORDERS.PAYMENT_FAILED')}
                                                                        data-tooltip-position='top'
                                                                    >
                                                                        <i className='ladi-icon icon-c-warning' />
                                                                    </span>
                                                                )}
                                                        </td>
                                                        <td>{this.getPaymentMethod(order.payment_method)}</td>
                                                        {/* <td>{this.getPaymentType(order.payment_type)}</td> */}
                                                        <td>{this.getShippingStatus(order.shipping_status)}</td>
                                                        <td>
                                                            {baseHelper.formatMoneyPostFix(
                                                                order.refund ? order.total - order.refund : order.total,
                                                                this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                            )}
                                                        </td>
                                                        {order.assignee_id ? (
                                                            <td>
                                                                {baseHelper.getStaffNameById(
                                                                    userInfo.staffs,
                                                                    order.assignee_id,
                                                                    userInfo.ladi_uid
                                                                )}
                                                            </td>
                                                        ) : (
                                                            <td>
                                                                <button
                                                                    onClick={() => this.props.assignOrder(order.order_id)}
                                                                    className={`ladiui btn btn-outline-primary btn-sm ${
                                                                        !isShow && 'prevent-click'
                                                                    }`}
                                                                >
                                                                    {t('ORDERS.ASSIGN')}
                                                                </button>
                                                            </td>
                                                        )}
                                                        <td className='text-right pd-0'>
                                                            <div className='ladiui btn-group'>
                                                                <div className='ladiui dropdown hide-mt ba-c'>
                                                                    <button
                                                                        data-toggle='dropdown'
                                                                        className='ladiui-btn-dropdown dropdown-toggle'
                                                                    >
                                                                        <i className='ladiui icon icon-ldp-dot'></i>
                                                                    </button>
                                                                    <ul className='ladiui dropdown-menu r-0'>
                                                                        <li>
                                                                            <a
                                                                                className='ladiui dropdown-item'
                                                                                onClick={() =>
                                                                                    this.props.history.push(`/orders/${order.order_id}`)
                                                                                }
                                                                            >
                                                                                {t('ACTIONS.EDIT')}
                                                                            </a>
                                                                        </li>
                                                                        {order.mark_spam == appConfig.STATUS.ACTIVE && (
                                                                            <li>
                                                                                <a
                                                                                    className='ladiui dropdown-item'
                                                                                    onClick={() =>
                                                                                        this.props.updateSpams({
                                                                                            order_ids: [order.order_id],
                                                                                            mark_spam: appConfig.STATUS.INACTIVE,
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    {t('ORDERS.REMOVE_MARK_SPAM')}
                                                                                </a>
                                                                            </li>
                                                                        )}
                                                                        {order.mark_spam == appConfig.STATUS.INACTIVE &&
                                                                            order.status != appConfig.ORDER_STATUS.CANCELED.CODE && (
                                                                                <li>
                                                                                    <a
                                                                                        className='ladiui dropdown-item'
                                                                                        onClick={() =>
                                                                                            this.props.updateSpams({
                                                                                                order_ids: [order.order_id],
                                                                                                mark_spam: appConfig.STATUS.ACTIVE,
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        {t('ORDERS.MARK_SPAM')}
                                                                                    </a>
                                                                                </li>
                                                                            )}
                                                                        {isDuplicate && (
                                                                            <li>
                                                                                <a
                                                                                    className='ladiui dropdown-item'
                                                                                    onClick={() =>
                                                                                        this.props.removeDuplication(order.order_id)
                                                                                    }
                                                                                >
                                                                                    {t('ORDERS.REMOVE_DUPLICATION')}
                                                                                </a>
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {tagIDs.length > 0 && (
                                                        <tr className='ladiui table-vertical main custom-tag'>
                                                            <td></td>
                                                            <td colSpan={10}>
                                                                {tagIDs.length > 0 && (
                                                                    <ListTags
                                                                        tagColors={tagColors}
                                                                        tagValues={tagIDs}
                                                                        tagNames={tagNames}
                                                                        handleClickTagItem={this.handleClickTag}
                                                                    />
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                        {totalRecord <= 0 && (
                                            <tr className='text-center'>
                                                <td colSpan='100%'>{t('NO_DATA')}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={8}>
                                                <strong>{t('ORDERS.TOTAL_AMOUNT')}</strong>
                                            </td>
                                            <td colSpan={3}>
                                                <strong className='pl-0'>
                                                    {baseHelper.formatMoneyPostFix(
                                                        this.state.totalAmount,
                                                        this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                    )}
                                                </strong>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            {totalRecord > 0 && (
                                <LadiPagination
                                    conditionsProp={conditions}
                                    listName={t('CUSTOM_FIELD.ORDER')}
                                    message={msgShowItem}
                                    items={this.props.order.orders}
                                    total={totalRecord}
                                    limitAction={this.onChangeLimit}
                                    pageAction={this.onPageChange}
                                />
                            )}
                        </div>
                    )}
                </div>

                {this.state.isShowModalExportOrder && (
                    <ModalExportOrder
                        visible={this.state.isShowModalExportOrder}
                        isLoadingExportOrder={isLoadingExportOrder}
                        isLoadingExportTransaction={isLoadingExportTransaction}
                        onExportOrder={this.exportOrder}
                        onExportTransaction={this.exportTransaction}
                        onCancel={() => {
                            this.setState({
                                isShowModalExportOrder: false,
                            });
                        }}
                    />
                )}

                <ConfirmModal
                    id='confirm-mark-spam'
                    title={t('ORDERS.MARK_SPAM')}
                    content={t('ORDERS.MSG_MARK_SPAM_TO_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.OK')}
                    onOk={() =>
                        this.props.updateSpams({
                            order_ids: this.state.selectedIDs,
                            mark_spam: appConfig.STATUS.ACTIVE,
                        })
                    }
                    isLoading={isLoadingUpdateSpam}
                />

                <ConfirmModal
                    id='confirm-remove-spam'
                    title={t('ORDERS.REMOVE_MARK_SPAM')}
                    content={t('ORDERS.MSG_REMOVE_MARK_SPAM_TO_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.OK')}
                    onOk={() =>
                        this.props.updateSpams({
                            order_ids: this.state.selectedIDs,
                            mark_spam: appConfig.STATUS.INACTIVE,
                        })
                    }
                    isLoading={isLoadingUpdateSpam}
                />

                <ConfirmModal
                    id='confirm-last-payment'
                    title={t('ORDERS.CONFIRM_PAYMENT')}
                    content={t('ORDERS.MSG_CONFIRM_LAST_PAYMENT', this.state.payment_gateway_code)}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.OK')}
                    onOk={this.confirmLastPayment}
                    isBtnPrimary={true}
                    isLoading={isLoadingModalConfirmPayment}
                />

                {this.state.isShowModalCancel && (
                    <ModalCancel
                        visible={this.state.isShowModalCancel}
                        onOk={this.cancelOrders}
                        onCancel={this.hideModalCancel}
                        isLoading={isLoadingCancels}
                    />
                )}
                {this.state.isShowModalConfirmPayment && (
                    <ModalConfirmPayment
                        visible={this.state.isShowModalConfirmPayment}
                        onCancel={this.hideModalConfirmPayment}
                        onOk={this.confirmPayments}
                        isLoading={isLoadingModalConfirmPayment}
                        currentStoreID={userInfo.currentStore.store_id}
                    />
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(orderActions.list(data)),
        cancel: (orderID) => dispatch(orderActions.cancel(orderID)),
        show: (orderID) => dispatch(orderActions.show(orderID)),
        markAsPaids: (data) => dispatch(orderActions.markAsPaids(data)),
        cancelOrders: (orderIDs, reason) => dispatch(orderActions.cancels(orderIDs, reason)),
        wsAppendNewOrder: (orders, totalRecord) => dispatch(orderActions.appendNewOrder(orders, totalRecord)),
        assignOrder: (orderID) => dispatch(orderActions.assign(orderID)),
        removeDuplication: (orderID) => dispatch(orderActions.removeDuplication(orderID)),
        exportOrder: (data) => dispatch(orderActions.exportOrder(data)),
        exportTransaction: (data) => dispatch(orderActions.exportTransaction(data)),
        wsAssignedOrder: (orders) => dispatch(orderActions.assignedOrder(orders)),
        updateSpams: (data) => dispatch(orderActions.updateSpams(data)),
        listAllTags: () => dispatch(orderTagActions.listAll()),
        prints: (orderIDs) => dispatch(printActions.printsOrder(orderIDs)),
        listStores: (data) => dispatch(storePageActions.storePageList(data)),
        searchProduct: (data) => dispatch(productActions.search(data)),
    };
};

const mapStateToProps = (state) => ({
    order: { ...state.order },
    orderTagReducer: { ...state.orderTag },
    printReducer: { ...state.print },
    storeReducer: { ...state.store },
    storePageReducer: { ...state.storePage },
    productReducer: { ...state.product },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Order));
