import React from "react";

import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import shippingActions from "../../../../redux/futures/shipping/actions";
import * as shippingTypes from "../../../../redux/futures/shipping/types";

import paymentActions from "../../../../redux/futures/payment/actions";
import * as paymentTypes from "../../../../redux/futures/payment/types";

import orderActions from "../../../../redux/futures/order/actions";

import appConfig from "../../../../config/app";
import { find, map } from "lodash";
import baseHelper from "../../../../helpers/BaseHelper";

import Modal from "../../../../components/Modal";
import Dropdown from "../../../../components/Dropdown";

class ModalConfirmPayment extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedPayment: {
        method: "",
        type: "",
      },
      listPaymentTypes: [],
    };
  }

  componentDidMount() {
    this.props.listGateways();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.paymentReducer.action != nextProps.paymentReducer.action) {
      if (this.props.paymentReducer.action != nextProps.paymentReducer.action) {
        if ([paymentTypes.PAYMENT_LIST_GATEWAYS].includes(nextProps.paymentReducer.action)) {
          if (nextProps.paymentReducer.status) {
            this.loadedListPayment = true;

            if (nextProps.paymentReducer.gateways) {
              this.setState({
                selectedPayment: {
                  method: nextProps.paymentReducer.gateways[0].code,
                },
                listPaymentTypes: nextProps.paymentReducer.gateways[0].config.active_payment_methods,
              });
            }
          } else {
            window.LadiUI.showErrorMessage("Thông báo", nextProps.paymentReducer.message, "OK");
          }
        }
      }
    }
  }

  submit = () => {
    let selectedPayment = this.state.selectedPayment;
    let paymentType = selectedPayment.type;
    if (selectedPayment.method == appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE) {
      paymentType = "SSP";
    }
    if (selectedPayment.method == appConfig.PAYMENT_PARTNERS.BANK.CODE) {
      paymentType = "BK";
    }
    if (selectedPayment.method == appConfig.PAYMENT_PARTNERS.COD.CODE) {
      paymentType = selectedPayment.method;
    }
    if (selectedPayment.method == appConfig.PAYMENT_PARTNERS.MOMO.CODE) {
      paymentType = "MO";
    }
    if (selectedPayment.method == appConfig.PAYMENT_PARTNERS.PAYPAL.CODE) {
      paymentType = "PAYPAL";
    }
    this.props.onOk(selectedPayment.method, paymentType);
  };

  getDataPaymentTypes = (types) => {
    let dataListTypes = [];
    map(appConfig.SN_PAYMENT_METHODS, (value, index) => {
      let findIndex = types.findIndex((item) => item == value.CODE);
      if (findIndex !== -1) {
        dataListTypes.push({
          code: value.CODE,
          name: value.NAME,
        });
      }
    });
    return dataListTypes;
  };

  render() {
    const { t } = this.props;
    const { selectedPayment } = this.state;
    return (
      <Modal
        id="modal-confirm-payment"
        title={t("ORDERS.MSG_PAYMENT_SUCCESS_TITLE")}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        onOk={this.submit}
        bodyClassName='position-initial'
        isLoading={this.props.isLoading}
        okText={t("COMMON.VERIFY")}
        width={600}
      >
        {selectedPayment.method == appConfig.PAYMENT_PARTNERS.ALE_PAY.CODE &&
          selectedPayment.type == appConfig.PAYMENT_METHODS.ALE_PAY.TYPE.INSTALLMENT.CODE && (
            <p>
              <strong>{t("PAYMENTS.MSG_INSTALLMENT")}</strong>
            </p>
          )}
        <p>
          <strong>{t("ORDERS.MSG_PAYMENTS_SUCCESS_CONFIRM")}</strong>
        </p>
        <div className="ladiui form-group">
          <label className="ladiui-label">{t("PAYMENTS.LB_SELECT_PAYMENT")}</label>
          <Dropdown
            data={this.props.paymentReducer.gateways}
            onSelectItem={(item) => {
              this.setState({
                selectedPayment: {
                  ...this.state.selectedPayment,
                  method: item.code,
                },
                listPaymentTypes: item.config.active_payment_methods,
              });
            }}
            currentKey={selectedPayment.method}
            _key={"code"}
            _value={"name"}
            validationName={t("PAYMENTS.LB_PAYMENT_VALIDATION")}
            placeHolder={t("PAYMENTS.LB_SELECT_PAYMENT")}
            ref={(ref) => (this.paymentRef = ref)}
            validations={{ isRequired: true }}
            idBlock='dropdown-confirm-payment'
            wrapperClassName='position-initial w100'
          />
        </div>
        {!baseHelper.isEmpty(this.state.listPaymentTypes) && (
          <div className="ladiui form-group">
            <label className="ladiui-label">{t("PAYMENTS.LB_SELECT_PAYMENT_TYPE")}</label>
            <Dropdown
              data={this.getDataPaymentTypes(this.state.listPaymentTypes)}
              onSelectItem={(item) => {
                this.setState({
                  selectedPayment: {
                    ...this.state.selectedPayment,
                    type: item.code,
                  },
                });
              }}
              currentKey={selectedPayment.type}
              _key={"code"}
              _value={"name"}
              validationName={t("PAYMENTS.LB_PAYMENT_VALIDATION")}
              placeHolder={t("PAYMENTS.CHOOSE_PAYMENT_TYPE")}
              ref={(ref) => (this.paymentRef = ref)}
              validations={{ isRequired: true }}
              idBlock='dropdown-confirm-payment'
              wrapperClassName='position-initial w100'
            />
          </div>
        )}
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listGateways: () => dispatch(paymentActions.listGateways()),
  };
};

const mapStateToProps = (state) => ({
  paymentReducer: { ...state.payment },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withTranslation("translation", { withRef: true })(ModalConfirmPayment)
);
