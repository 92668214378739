/** @format */

import * as types from "./types";
import * as commonTypes from "../common/types";

export default (state = {}, action) => {
  switch (action.type) {
    case commonTypes.ASYNC_START: {
      if (!types[action.waiting]) {
        return state;
      }

      return {
        ...state,
        loading: true,
        waiting: action.waiting,
        done: null,
        action: commonTypes.ASYNC_START,
      };
    }
    case commonTypes.ASYNC_END: {
      if (!types[action.done]) {
        return state;
      }

      return {
        ...state,
        waiting: null,
        done: action.done,
        loading: false,
        action: commonTypes.ASYNC_END,
      };
    }
    case types.LIST_CATEGORY: {
      return {
        ...state,
        action: types.LIST_CATEGORY,
        categories: action.status ? action.payload : [],
        status: action.status,
        message: action.message,
      };
    }
    case types.CREATE_CATEGORY: {
      return {
        ...state,
        action: types.CREATE_CATEGORY,
        status: action.status,
        message: action.message,
      };
    }
    case types.LIST_SELECT_CATEGORY: {
      return {
        ...state,
        listSelects: action.status ? action.payload : [],
        action: types.LIST_SELECT_CATEGORY,
        status: action.status,
        message: action.message,
      };
    }
    case types.UPDATE_CATEGORY: {
      return {
        ...state,
        action: types.UPDATE_CATEGORY,
        status: action.status,
        message: action.message,
      };
    }
    case types.UPDATE_POSITION: {
      return {
        ...state,
        action: types.UPDATE_POSITION,
        status: action.status,
        message: action.message,
      };
    }
    case types.SHOW_CATEGORY: {
      return {
        ...state,
        action: types.SHOW_CATEGORY,
        productCategory: action.status ? action.payload.productCategory : [],
        status: action.status,
        message: action.message,
      };
    }
    case types.SEARCH_CATEGORY: {
      return {
        ...state,
        action: types.SEARCH_CATEGORY,
        productCategories: action.status ? action.payload.productCategories : [],
        status: action.status,
        message: action.message,
      };
    }
    case types.DELETE_CATEGORY: {
      return {
        ...state,
        action: types.DELETE_CATEGORY,
        status: action.status,
        message: action.message,
      };
    }
    case types.DELETE_CATEGORIES: {
      return {
        ...state,
        action: types.DELETE_CATEGORIES,
        status: action.status,
        message: action.message,
      };
    }
    default:
      return state;
  }
};
