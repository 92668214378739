import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import baseHelper from "../helpers/BaseHelper";
// eslint-disable-next-line max-lines-per-function
let CDN = "https://w.ladicdn.com/";
function ModalIntroduceVietQr(props) {
    const {
        t,
        onClose,
        isShow,
    } = props;
    const wrapperRef = useRef(null);

    // useEffect(() => {
    //     /**
    //      * Alert if clicked on outside of element
    //      */
    //     function handleClickOutside(event) {
    //         if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
    //             onClose(false);
    //         }
    //     }
    //     // Bind the event listener
    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         // Unbind the event listener on clean up
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [wrapperRef]);

    const dispatch = useDispatch();

    const eventCancel = () => {
        onClose(false);
    };

    const handleOpenNewTap = () => {
        window.location = "/settings?is_viet_qr=true#PAYMENT";
    }

    const handleSetCookie = () => {
        baseHelper.setCookie("not_show_message", true);
    }



    return (
        <div className={`ladiui modal ${isShow ? "show" : ""}`} id="modal-introduce-viet-qr">
            <div className="ladiui modal-dialog modal-dialog-centered" style={{ width: "800px" }} ref={wrapperRef}>
                <div className="ladiui modal-content ">
                    <div className="ladiui modal-header">
                        <button data-dismiss="modal" data-target="add-product" onClick={eventCancel} className="ladiui modal-close" style={{ right: 16 }}>
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="ladiui modal-body">
                        <div className="block-content-viet-qr">
                            <div className="content-left">
                                <div className="content">
                                    <span className="text-new">Mới</span>
                                    <h3>Hiển thị mã VietQR khi thanh toán qua ngân hàng</h3>
                                    <p>Giờ đây bạn có thể thiết lập để hiển thị mã VietQR khi thanh toán <span><a href="https://help.ladisales.vn/cai-dat-tai-khoan/phuong-thuc-thanh-toan/chuyen-khoan-qua-ngan-hang/huong-dan-tao-template-vietqr-id" target="_blank">Xem hướng dẫn</a></span></p>
                                    <button className="ladiui btn btn-primary" onClick={() => handleOpenNewTap()}>
                                        Thiết lập ngay
                                    </button>
                                    <div className="ladiui form-group flex">
                                        <input
                                            type="checkbox"
                                            onClick={() => handleSetCookie()}
                                            className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                                        />
                                        <span>Không hiển thị thông báo lần tiếp theo</span>
                                    </div>
                                </div>
                            </div>
                            <div className="content-right">
                                <div className="block-banner">
                                    <img src="https://w.ladicdn.com/ladiui/ladisales/ladisales-illustration.png" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ladiui modal-footer" style={props.isVietQrModal ? { display: "none" } : ''}>
                        <div className="ladiui footer-modal-btn">
                            <button
                                type="button"
                                data-dismiss="modal"
                                data-target="add-product"
                                className="ladiui btn btn-secondary bold"
                                onClick={eventCancel}
                            >
                                {t("ACTIONS.CANCEL")}
                            </button>

                            <button
                                type="button"
                                data-dismiss="modal"
                                data-target="add-product"
                                className="ladiui btn btn-primary bold ml-16"
                            >
                                {t("ACTIONS.DONE")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(ModalIntroduceVietQr);
