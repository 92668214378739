/** @format */

import React from 'react';

import baseHelper from '../../../helpers/BaseHelper';
import appConfig from '../../../config/app';
import _, { debounce, includes, cloneDeep, map, find, remove, isEqual, compact } from 'lodash';

import * as orderTypes from '../../../redux/futures/order/types';
import * as variantTypes from '../../../redux/futures/product_variant/types';

import orderActions from '../../../redux/futures/order/actions';
import variantActions from '../../../redux/futures/product_variant/actions';

import checkoutConfigActions from '../../../redux/futures/checkout_config/actions';
import * as checkoutConfigTypes from '../../../redux/futures/checkout_config/types';

import customerActions from '../../../redux/futures/customer/actions';
import * as customerTypes from '../../../redux/futures/customer/types';

import paymentActions from '../../../redux/futures/payment/actions';
import * as paymentTypes from '../../../redux/futures/payment/types';

import orderTagActions from '../../../redux/futures/order_tag/actions';
import * as orderTagTypes from '../../../redux/futures/order_tag/types';

import discountActions from '../../../redux/futures/discount/actions';
import * as discountTypes from '../../../redux/futures/discount/types';

import shippingRateActions from '../../../redux/futures/shipping_rate/actions';
import * as shippingRateTypes from '../../../redux/futures/shipping_rate/types';

import printActions from '../../../redux/futures/print/actions';
import * as printTypes from '../../../redux/futures/print/types';

import { connect } from 'react-redux';

import Image from '../../../components/Image';
import PanelCustomer from '../components/OrderCustomer';
import PanelBillingAddress from '../components/BillingAddress';
import PanelShippingAddress from '../components/ShippingAddress';
import PanelBillingInfomation from '../components/BillingInfomation';
import { withTranslation } from 'react-i18next';
import LoadingSence from '../../../components/LoadingScene';
import ShippingDelivery from './components/ShippingDelivery';
import AsyncAutoComplete from '../../../components/AsyncAutoComplete';
import PopoverDiscount from '../OrderCreate/components/PopoverDiscount';
import Modal from '../../../components/Modal';
import Popover from '../../../components/Popover';
import DropdownTag from '../../../components/DropdownTag';
import Input from '../../../components/Input';
import { appLocalStorage } from '../../../localforage';
import ShippingDetail from './components/ShippingDetail';
import NumberInput from '../../../components/NumberInput';
import History from '../../../components/History';
import ModalRefund from './components/ModalRefund';
import ModalCancel from '../components/ModalCancel';
import ModalConfirmCancel from '../components/ModalConfirmCancel';
import SelectOption from '../../../components/SelectOption';
import PanelTicketSeat from '../../events/components/PanelTicketSeat';
import ConfirmModal from '../../../components/ConfirmModal';
import Dropdown from '../../../components/Dropdown';
import PanelCustomField from '../../../components/PanelCustomField';
import ModalAddProduct from '../OrderCreate/components/ModalAddProduct';
import PanelCustomerField from '../components/PanelCustomerField';

class OrderEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orderCustomer: {},
            billingAddress: {},
            billingInfomation: {},
            shippingAddress: {},
            currentCustomerInfo: {},
            tags: [],
            note: '',
            editableOrder: {
                variants: [],
                selectDiscount: {
                    type: appConfig.DISCOUNT.TYPE.FIXED.value,
                    value: '',
                    note: '',
                    code: '',
                },
                applyDiscount: {},
                discountFee: '',
                selectShipping: {
                    type: appConfig.SHIPPING_TYPE.FREE,
                    note: '',
                    value: '',
                },
                applyShipping: {},
                shipping_fee: '',
                is_auto_shipping: false,
                tax_fee: '',
                searchVariant: '',
                displaySearchVariant: props.t('PRODUCTS.SEARCH_PRODUCT'),
                total: 0,
                subTotal: 0,
                customVariant: {
                    name: '',
                    price: '',
                    quantity: '',
                },
                isShowModalNewProduct: false,
                disabledApplyDiscount: true,
                isShowModalConfirmCreate: false,
                emailNotification: false,
                isShowBlockCustomField: false,
            },
            refundPrice: '',
            isShowModalRefund: false,
            isEditableOrder: false,
            userInfo: {
                currentStore: {},
            },
            isShowModalCancel: false,
            isShowModalConfirmPayment: false,
            isShowModalAssignOther: false,
            isShowModalTicketSeat: false,
            isShowBlockUrl: true,
            assigneeID: '',
            selectedCheckoutConfig: {},
            isShowModalConfirmCancel: false,
            selectedPayment: {
                method: '',
                type: '',
                status: '',
            },
            customFields: [],
            listPaymentTypes: [],
            orderCoppy: {
                note: '',
                variants: [],
                selectedCustomer: {},
                currentShippingAddress: {},
                currentBillingAddress: {},
            },
            tagSelected: {},
            isResetProduct: false,
            isShowModalAddProduct: false,
            isShowModalShipping: false,
            isShowModalAddTag: false,
            typeSelected: appConfig.TAG_SELECTED.TAG_ORDER.CODE,
            isShowModalConfirmResendTicket: false,
        };

        this.panelBillingAddressRef = React.createRef();
        this.panelShippingAddressRef = React.createRef();
        this.popoverDiscountRef = React.createRef();
        this.popoverShippingRef = React.createRef();
        this.tagRef = React.createRef();
        this.paymentRef = React.createRef();
        this.panelCustomFieldRef = React.createRef();
        this.panelBillingInfomationRef = React.createRef();

        this.formProductCreateRef = new Set();
        this.formRefundRef = new Set();
        this.searchVariant = debounce(this.searchVariant, 500);
        this.loadedListPayment = false;
        this.inputsRef = new Set();

        this.selectedOrderID = '';
    }

    componentWillMount() {
        appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
            map(result.staffs, (item) => {
                if (item.ladi_uid == result.ladi_uid) {
                    item.name = this.props.t('COMMON.ME');
                }
            });

            this.setState({
                userInfo: result,
            });
        });
    }

    componentDidMount() {
        this.props.listCheckoutConfig();
        this.props.show(this.props.match.params['orderID']);
        this.props.listAllTags();
    }

    componentDidUpdate(prevProps, prevState) {
        window.LadiUI.init();
        setTimeout(() => {
            window.LadiUI.customInit();
        }, 0);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }
        if (this.props.order.action != nextProps.order.action) {
            if (includes([orderTypes.SHOW_ORDER, orderTypes.RELOAD_ORDER], nextProps.order.action)) {
                if (nextProps.order.status) {
                    if (nextProps.order.order_not_show) {
                        return;
                    }

                    // Nếu order chưa hủy + nháp
                    const order = nextProps.order.order || {};

                    if (order.order_details) {
                        order.order_details.map((item, index) => {
                            item.name = item.product_name;
                        });
                    }

                    const editable = order.status != appConfig.ORDER_STATUS.CANCELED.CODE && order.is_draft == appConfig.STATUS.ACTIVE;

                    this.setState({
                        isEditableOrder: editable,
                        selectedCheckoutConfig: {
                            checkout_config_id: order.checkout_config_id,
                        },
                    });

                    const billingAddress = baseHelper.isEmpty(order)
                        ? {}
                        : {
                              first_name: order.billing_first_name,
                              last_name: order.billing_last_name,
                              company: order.billing_company,
                              phone: order.billing_phone,
                              address: order.billing_address,
                              apartment: order.billing_apartment,
                              state: order.billing_state,
                              country_code: order.billing_country_code,
                              country_name: order.billing_country_name,
                              state_id: order.billing_state_id,
                              state_name: order.billing_state_name,
                              district_id: order.billing_district_id,
                              district_name: order.billing_district_name,
                              ward_id: order.billing_ward_id,
                              ward_name: order.billing_ward_name,
                              postal_code: order.billing_postal_code,
                          };

                    const billingInfomation = baseHelper.isEmpty(order)
                        ? {}
                        : {
                              billing_company: order.billing_company,
                              billing_tax_code: order.billing_tax_code,
                              billing_email: order.billing_email,
                              billing_company_address: order.billing_company_address,
                          };

                    const shippingAddress = baseHelper.isEmpty(order)
                        ? {}
                        : {
                              first_name: order.shipping_first_name,
                              last_name: order.shipping_last_name,
                              company: order.shipping_company,
                              phone: order.shipping_phone,
                              address: order.shipping_address,
                              apartment: order.shipping_apartment,
                              state: order.shipping_state,
                              country_code: order.shipping_country_code,
                              country_name: order.shipping_country_name,
                              state_id: order.shipping_state_id,
                              state_name: order.shipping_state_name,
                              district_id: order.shipping_district_id,
                              district_name: order.shipping_district_name,
                              ward_id: order.shipping_ward_id,
                              ward_name: order.shipping_ward_name,
                              postal_code: order.shipping_postal_code,
                              note: order.shipping_note,
                          };

                    const selectedCustomerAddress = {
                        address_default: shippingAddress,
                        addresses: [shippingAddress],
                        customer_id: order.customer_id,
                        email: order.customer_email,
                        last_name: order.customer_last_name,
                        first_name: order.customer_first_name,
                        phone: order.billing_phone,
                        total_order: order.customer_total_order,
                    };

                    this.setState({
                        billingAddress,
                        shippingAddress,
                        billingInfomation,
                        note: order.note,
                        tags: cloneDeep(order.tags),
                        orderCustomer: {
                            customer_id: order.customer_id,
                            first_name: order.customer_first_name,
                            last_name: order.customer_last_name,
                            email: order.customer_email,
                            phone: order.customer_phone,
                            total_order: order.customer_total_order,
                        },
                        assigneeID: order.assignee_id,
                        orderCoppy: {
                            note: order.note ? order.note : '',
                            variants: order.order_details ? order.order_details : [],
                            selectedCustomer: selectedCustomerAddress ? selectedCustomerAddress : [],
                            currentShippingAddress: shippingAddress,
                            currentBillingAddress: billingAddress,
                        },
                    });

                    if (editable) {
                        const { order_details: orderDetails } = order;

                        const variants = map(orderDetails, (item) => {
                            const variant = {
                                options: item.options,
                                src: item.src,
                                name: item.product_name || item.product_name_full,
                                price: item.price,
                                product_id: item.product_id,
                                quantity: item.quantity,
                                total: item.total,
                                variant_id: item.product_variant_id,
                                product_type: item.product_type,
                            };

                            if (item.discount_type) {
                                variant.discount = {
                                    fee: item.discount_fee,
                                    note: item.discount_note,
                                    type: item.discount_type,
                                    value: item.discount_value,
                                };
                            }

                            return variant;
                        });

                        const selectDiscount = {
                            type: order.discount_code
                                ? appConfig.DISCOUNT.TYPE.FIXED.value
                                : order.discount_type || appConfig.DISCOUNT.TYPE.FIXED.value,
                            value: order.discount_code ? '' : order.discount_value,
                            note: order.discount_note,
                            code: order.discount_code,
                        };

                        const applyDiscount = { ...selectDiscount };
                        const discountFee = order.discount_fee;
                        const selectShipping = {
                            id: order.shipping_rate_detail_id,
                            type: order.shipping_rate_type || appConfig.SHIPPING_TYPE.FREE,
                            note: order.shipping_rate_name,
                            value: order.shipping_fee,
                        };
                        const applyShipping = { ...selectShipping };
                        const taxFee = order.tax_fee;
                        const total = order.total;
                        const subTotal = order.sub_total;

                        this.setState(
                            {
                                editableOrder: {
                                    ...this.state.editableOrder,
                                    variants,
                                    selectDiscount,
                                    applyDiscount,
                                    discountFee,
                                    selectShipping,
                                    applyShipping,
                                    shipping_fee: order.shipping_fee,
                                    taxFee,
                                    total,
                                    subTotal,
                                },
                            },
                            () => {
                                let totalWeight = 0;
                                map(variants, (item) => {
                                    totalWeight += baseHelper.parseInt(item.quantity) * baseHelper.parseFloat(item.weight);
                                });
                                this.props.getAvailableShippingRate({
                                    total: subTotal,
                                    weight: totalWeight,
                                    shipping_address: shippingAddress,
                                });
                            }
                        );
                    }
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');

                    this.props.history.push('/orders');
                }
            }

            if (orderTypes.SHOW_ORDER == nextProps.order.action) {
                this.props.showCustomer(nextProps.order.order.customer_id);
            }

            if (nextProps.order.action === orderTypes.UPDATE_ORDER_STATUS) {
                if (nextProps.order.status) {
                    this.props.reload(this.props.match.params['orderID']);
                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action === orderTypes.UPDATE_SPAMS) {
                if (nextProps.order.status) {
                    this.props.reload(this.props.match.params['orderID']);
                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action === orderTypes.MARK_AS_PAID) {
                if (nextProps.order.status) {
                    this.setState({
                        isShowModalConfirmPayment: false,
                    });
                    this.props.reload(this.props.match.params['orderID']);
                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action === orderTypes.RESEND_TICKET_LIST) {
                if (nextProps.order.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (
                includes(
                    [
                        orderTypes.UPDATE_ORDER_ADDRESS,
                        orderTypes.SHIPPING_UPDATE_ORDER_ADDRESS,
                        orderTypes.UPDATE_ORDER,
                        orderTypes.PARTIAL_UPDATE_ORDER,
                    ],
                    nextProps.order.action
                )
            ) {
                if (nextProps.order.status) {
                    setTimeout(() => {
                        this.props.reload(this.props.match.params['orderID']);
                    }, 0);
                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action == orderTypes.REFUND_ORDER) {
                if (nextProps.order.status) {
                    this.props.reload(this.props.match.params['orderID']);
                    window.LadiUI.toastCustom('success', '', nextProps.order.message);

                    this.hideModalRefund();
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action == orderTypes.CANCEL_ORDER) {
                if (nextProps.order.status) {
                    this.setState({
                        isShowModalCancel: false,
                    });

                    this.props.reload(this.props.match.params['orderID']);
                    window.LadiUI.toastCustom('success', '', nextProps.order.message);

                    this.hideModalRefund();
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action == orderTypes.FULLFILL_ORDER) {
                if (nextProps.order.status) {
                    this.props.reload(this.props.match.params['orderID']);

                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action == orderTypes.ASSIGN_ORDER) {
                if (nextProps.order.status) {
                    this.setState({
                        assigneeID: nextProps.order.assigneeID,
                    });

                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action == orderTypes.ASSIGN_TO_OTHER_ORDER) {
                if (nextProps.order.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    this.setState({
                        assigneeID: this.props.order.order.assignee_id,
                    });

                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }

                this.setState({
                    isShowModalAssignOther: false,
                });
            }

            if (nextProps.order.action == orderTypes.GENERATE_CHECKOUT_ORDER) {
                if (nextProps.order.status) {
                    const checkoutLink = `${baseHelper.domainToUrl(this.props.storeReducer.userInfo.default_domain)}/checkout/${
                        nextProps.order.order.checkout_token
                    }`;
                    baseHelper.copyToClipboard(checkoutLink);
                    window.LadiUI.toastCustom('success', '', this.props.t('COMMON.COPIED'));
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }
        }

        if (this.props.printReducer.action != nextProps.printReducer.action) {
            if (nextProps.printReducer.action == printTypes.PRINT_ORDER) {
                if (nextProps.printReducer.status) {
                    baseHelper.print(nextProps.printReducer.source);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.printReducer.message, 'OK');
                }
            }
        }

        if (this.props.paymentReducer.action != nextProps.paymentReducer.action) {
            if (nextProps.paymentReducer.action === paymentTypes.UPDATE_PAYMENT_STATUS) {
                if (nextProps.paymentReducer.status) {
                    this.props.reload(this.props.match.params['orderID']);
                    window.LadiUI.toastCustom('success', '', nextProps.paymentReducer.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.paymentReducer.message, 'OK');
                }
            }

            if (this.props.paymentReducer.action != nextProps.paymentReducer.action) {
                if ([paymentTypes.PAYMENT_LIST_GATEWAYS].includes(nextProps.paymentReducer.action)) {
                    if (nextProps.paymentReducer.status) {
                        this.loadedListPayment = true;

                        if (this.props.order.order && this.props.order.order.payment_method) {
                            this.setState({
                                selectedPayment: {
                                    method: this.props.order.order.payment_method,
                                    type: this.props.order.order.payment_type,
                                    status: this.props.order.order.payment_status,
                                },
                            });
                        }
                        if (this.props.paymentReducer.gateways != nextProps.paymentReducer.gateways) {
                            map(nextProps.paymentReducer.gateways, (item, index) => {
                                if (item.code == this.props.order.order.payment_method) {
                                    this.setState({
                                        listPaymentTypes: item.config.active_payment_methods,
                                    });
                                }
                            });
                        }
                    } else {
                        window.LadiUI.showErrorMessage('Thông báo', nextProps.paymentReducer.message, 'OK');
                    }
                }
            }
        }

        if (this.props.customer.action != nextProps.customer.action) {
            const { currentCustomerInfo } = this.state;

            if (includes([customerTypes.SHOW_CUSTOMER, customerTypes.RELOAD_CUSTOMER], nextProps.customer.action)) {
                this.setState({
                    currentCustomerInfo: cloneDeep(nextProps.customer.customer),
                });
            }

            if (this.props.customer.action == customerTypes.UPDATE_CUSTOM_FIELD_BY_CUSTOMER) {
                if (nextProps.customer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.customer.message);
                    this.props.showCustomer(currentCustomerInfo.customer_id);
                }
            }
        }

        if (this.props.discount.action != nextProps.discount.action) {
            if (nextProps.discount.action == discountTypes.VALIDATE_DISCOUNT) {
                if (nextProps.discount.status) {
                    let { variants } = this.state.editableOrder || [];
                    variants = variants.map((variant) => {
                        let index = nextProps.discount.variants.findIndex((item) => item.variant_id == variant.variant_id);

                        if (index > -1) return nextProps.discount.variants[index];
                        return variant;
                    });
                    this.setState(
                        {
                            editableOrder: {
                                ...this.state.editableOrder,
                                discountFee: nextProps.discount.discount_fee || this.state.editableOrder.discountFee,
                                variants: variants,
                            },
                        },
                        () => this.calculateTotal()
                    );
                } else {
                    this.setState(
                        {
                            editableOrder: {
                                ...this.state.editableOrder,
                                discountFee: 0,
                                selectDiscount: {
                                    type: appConfig.DISCOUNT.TYPE.FIXED.value,
                                    code: '',
                                    note: '',
                                    value: '',
                                },
                                applyDiscount: {
                                    type: appConfig.DISCOUNT.TYPE.FIXED.value,
                                    code: '',
                                    note: '',
                                    value: '',
                                },
                            },
                        },
                        () => this.calculateTotal()
                    );
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.discount.message, 'OK');
                }
            }
        }

        if (this.props.shippingRate.action != nextProps.shippingRate.action) {
            if (nextProps.shippingRate.action == shippingRateTypes.SHIPPING_RATE_GET_AVAILABLE) {
                if (nextProps.shippingRate.status) {
                    if (this.state.isEditableOrder) {
                        const { selectShipping } = this.state.editableOrder;
                        if (selectShipping.id) {
                            const found = find(
                                nextProps.shippingRate.shipping_rates,
                                (item) => item.shipping_rate_detail_id == selectShipping.id
                            );
                            if (!found) {
                                this.clearShippingRate();
                            }
                        }
                    }
                }
            }
        }

        if (this.props.checkoutConfigReducer.action != nextProps.checkoutConfigReducer.action) {
            if (nextProps.checkoutConfigReducer.action == checkoutConfigTypes.LIST_CHECKOUT_CONFIG) {
                if (nextProps.checkoutConfigReducer.status) {
                    const defaultCheckoutConfig = find(
                        nextProps.checkoutConfigReducer.checkout_configs,
                        (item) => item.is_default == appConfig.STATUS.ACTIVE
                    );
                    if (defaultCheckoutConfig) {
                        this.setState({
                            selectedCheckoutConfig: defaultCheckoutConfig,
                        });
                    }
                }
            }
        }

        if (this.props.orderTag != nextProps.orderTag) {
            if (nextProps.orderTag.action === orderTagTypes.CREATE_ORDER_TAG) {
                if (nextProps.orderTag.action) {
                    this.props.listAllTags();
                    window.LadiUI.toastCustom('success', '', nextProps.orderTag.message);
                    this.setState({
                        isShowModalAddTag: false,
                    });
                }
            }
        }
    }

    /*******************************CHANGE STATUS***************************/

    onChangePaymentStatus = (status) => {
        const {
            order: { order_id, payment_id },
        } = this.props.order;

        this.props.updatePaymentStatus(order_id, payment_id, status);
    };
    /*********************************************************************/

    onChangeAddress = (address, addressType, fromShippingForm) => {
        const { order } = this.props.order;
        let data;
        if (addressType == appConfig.ADDRESS_TYPE.BILLING) {
            this.setState({
                billingAddress: {
                    ...address,
                },
            });
            data = {
                order_id: order.order_id,
                billing_address: {
                    ...address,
                },
            };
        } else if (addressType == appConfig.ADDRESS_TYPE.BILLING_INFO) {
            this.setState({
                billingInfomation: {
                    ...address,
                },
            });
            data = {
                order_id: order.order_id,
                billing_info: {
                    ...address,
                },
            };
        } else {
            this.setState({
                shippingAddress: {
                    ...address,
                },
            });
            data = {
                order_id: order.order_id,
                shipping_address: {
                    ...address,
                },
            };
        }

        if (!fromShippingForm) {
            this.props.updateAddress(data);
        } else {
            this.props.updateShippingAddress(data);
        }
    };

    getPaymentStatusName = (paymentStatus) => {
        const matched = find(appConfig.PAYMENT_STATUS, (item) => item.CODE == paymentStatus) || {};
        return matched.NAME;
    };

    updatedOrderCustomer = (email, phone) => {
        this.props.reload(this.props.match.params['orderID']);
    };

    fullSubmit = (hasPayment) => {
        const {
            variants,
            applyDiscount,
            tax_fee,
            applyShipping,
            shipping_fee: shippingFee,
            emailNotification,
            is_auto_shipping,
        } = this.state.editableOrder;
        const { orderCustomer, note, selectedPayment } = this.state;

        if (!this.validateOrder(hasPayment)) {
            return;
        }

        const billingAddress = this.panelBillingAddressRef.current.getData();
        const shippingAddress = this.panelShippingAddressRef.current.getData();
        const tags = this.tagRef.current.getData();
        const productCustomField = this.panelCustomFieldRef.current.getData();
        const {
            order: { order_id: orderID },
        } = this.props.order;

        const order = {
            order_id: orderID,
            customer_id: orderCustomer.customer_id,
            billing_address: {
                ...billingAddress,
            },
            shipping_address: {
                ...shippingAddress,
            },
            variants,
            discount: applyDiscount,
            note,
            custom_fields: productCustomField,
            shipping_rate_type: applyShipping.type,
            shipping_rate_detail_id: applyShipping.id,
            shipping_rate_name: applyShipping.note,
            shipping_fee: shippingFee,
            tax_fee,
            tags,
            email_notification: emailNotification,
            status: this.props.order.order.status,
            is_auto_shipping,
        };

        if (hasPayment) {
            if (selectedPayment.method == appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE) {
                selectedPayment.type = 'SPP';
            }
            if (selectedPayment.method == appConfig.PAYMENT_PARTNERS.BANK.CODE) {
                selectedPayment.type = 'BK';
            }
            if (selectedPayment.method == appConfig.PAYMENT_PARTNERS.COD.CODE) {
                selectedPayment.type = selectedPayment.method;
            }
            if (selectedPayment.method == appConfig.PAYMENT_PARTNERS.MOMO.CODE) {
                selectedPayment.type = 'MO';
            }
            if (selectedPayment.method == appConfig.PAYMENT_PARTNERS.PAYPAL.CODE) {
                selectedPayment.type = 'PAYPAL';
            }
            order.payment = selectedPayment;
        }

        this.props.updateOrder(order);
    };

    partialSubmit = (isCanceled) => {
        if (isCanceled) {
            window.LadiUI.toastCustom('danger', '', 'Không thể thao tác với đơn hàng bị hủy!');
            return;
        }
        const tags = this.tagRef.current.getData();
        const custom_fields = this.panelCustomFieldRef.current.getData();
        const { note } = this.state;
        const {
            order: { order_id: orderID },
        } = this.props.order;

        const order = {
            order_id: orderID,
            tags,
            custom_fields,
            note,
        };

        this.props.partialUpdateOrder(order);
    };

    cancelOrder = (reason) => {
        this.props.cancelOrder(this.props.order.order.order_id, reason);
        if (this.state.isResetProduct) {
            let data = this.state.orderCoppy;
            this.props.history.push({
                pathname: '/orders/create',
                state: data,
            });
        }
    };

    confirmOrderCancel = (manipulation) => {
        if (manipulation == appConfig.MANIPULATION_ORDER.CANCEL_RESET.CODE) {
            this.setState({
                isShowModalConfirmCancel: false,
                isShowModalCancel: true,
                isResetProduct: true,
            });
        } else {
            let data = this.state.orderCoppy;
            this.props.history.push({
                pathname: '/orders/create',
                state: data,
            });
        }
    };

    /*************************************************************************************EDITABLEORDER****************************************************************/
    /*******************************SEARCH VARIANT***************************/
    onChangeSearchVariantInput = (event) => {
        this.setState(
            {
                editableOrder: {
                    ...this.state.editableOrder,
                    searchVariant: event.target.value,
                },
            },
            () => this.searchVariant()
        );
    };

    onFocusVariantInput = (event) => {
        if (!this.props.variant.variants) {
            this.searchVariant();
        }
    };

    /**
     * Tim kiem theo name
     */

    searchVariant = () => {
        this.props.searchVariant(this.state.editableOrder.searchVariant);
    };

    /**
     * @description:
     * Vi code JS doi value cua displaySearchVariant nhung state trong React khong doi
     * =>Khong render lai ma phai thay doi state cua displaySearchVariant de React hieu duoc
     */
    selectVariant = (variant) => {
        if (!baseHelper.isAvailableVariant(variant)) {
            return;
        }

        this.setState(
            {
                editableOrder: {
                    ...this.state.editableOrder,
                    displaySearchVariant: '',
                    disabledApplyDiscount: false,
                },
                isShowModalAddProduct: false,
            },
            () => {
                this.setState({
                    editableOrder: {
                        ...this.state.editableOrder,
                        search: '',
                        displaySearchVariant: this.props.t('PRODUCTS.SEARCH_PRODUCT'),
                    },
                });
            }
        );

        const { variants } = this.state.editableOrder;
        const { product_id: productID, product_variant_id: variantID, name, price, src, quantity, weight } = variant;

        if (find(variants, (item) => item.product_id && item.variant_id && item.product_id == productID && item.variant_id == variantID)) {
            document.getElementById(`quantity-${variantID}`).select();
            return;
        }

        const newVariant = {
            product_id: productID,
            variant_id: variantID,
            name,
            price,
            src,
            weight,
            quantity: 1,
            total: 1 * price,
        };

        variants.push(newVariant);

        this.setState(
            {
                editableOrder: {
                    ...this.state.editableOrder,
                    variants,
                },
            },
            () => this.validateDiscount()
        );
    };

    removeVariant = (index) => {
        const { variants } = this.state.editableOrder;
        remove(variants, (item, _index) => {
            return index == _index;
        });

        this.setState(
            {
                editableOrder: {
                    ...this.state.editableOrder,
                    variants,
                },
            },
            () => {
                this.checkDisabledApply();
                this.validateDiscount();
            }
        );
    };
    /****************************************************************/

    onChangeQuantity = (event, max_buy, index) => {
        const { variants } = this.state.editableOrder;

        const selectVariant = variants[index];
        selectVariant.quantity = event.target.value || 1;

        if (!selectVariant.quantity) {
            selectVariant.quantity = 1;
        }
        this.setState({
            editableOrder: {
                ...this.state.editableOrder,
                variants,
            },
        });
    };

    changeQuantityVariant = (value, max_buy, index) => {
        const { variants } = this.state.editableOrder;

        const message = this.props.t('STORES.MSG_MAX_BUY');
        const selectVariant = variants[index];
        selectVariant.quantity += value;

        if (max_buy != null && selectVariant.quantity > max_buy) {
            window.LadiUI.toastCustom('danger', message);
            selectVariant.quantity -= value;
        }

        if (selectVariant.quantity === 0) {
            this.removeVariant(index);
            return;
        }

        if (!selectVariant.quantity) {
            selectVariant.quantity = 1;
        }

        this.setState(
            {
                editableOrder: {
                    ...this.state.editableOrder,
                    variants,
                },
            },
            () => {
                this.validateDiscount();
            }
        );
    };

    calculateTotal = (listAvailableShippingRate = true) => {
        const { variants, shipping_fee, discountFee } = this.state.editableOrder;
        let subTotal = 0;
        let totalWeight = 0;
        map(variants, (item) => {
            item.total = this.getLastPrice(item) * (item.quantity || 0);
            subTotal += baseHelper.parseFloat(item.total);
            totalWeight += baseHelper.parseFloat(item.weight) * baseHelper.parseInt(item.quantity);
        });

        let total =
            baseHelper.parseFloat(subTotal) +
            baseHelper.parseFloat(shipping_fee) -
            baseHelper.parseFloat(discountFee) -
            baseHelper.parseFloat(this.props.order.order.package_discount_price);
        if (total < 0) {
            total = 0;
        }

        this.setState(
            {
                editableOrder: {
                    ...this.state.editableOrder,
                    subTotal,
                    total,
                },
            },
            () => {
                if (listAvailableShippingRate) {
                    const shippingAddress = this.panelShippingAddressRef.current.getData();
                    this.props.getAvailableShippingRate({
                        total: subTotal,
                        weight: totalWeight,
                        shipping_address: shippingAddress,
                    });
                }
            }
        );
    };

    /*******************************DISCOUNT***************************/
    onChangeDiscountType = (event, type) => {
        const { classList } = event.target;
        const { selectDiscount } = this.state.editableOrder;

        window.LadiUI.forEach(window.LadiUI.querySelector('.order-discount .discount-button'), function (e) {
            e.classList.remove('discount-button-active');
        });

        classList.add('discount-button-active');

        selectDiscount.type = type;

        this.setState({
            editableOrder: {
                ...this.state.editableOrder,
                selectDiscount,
            },
        });
    };

    onChangeDiscountValue = (event) => {
        const { selectDiscount } = this.state.editableOrder;

        selectDiscount.value = event.target.value;
        this.setState(
            {
                editableOrder: {
                    ...this.state.editableOrder,
                    selectDiscount,
                },
            },
            () => {
                this.checkDisabledApply();
            }
        );
    };

    onChangeDiscountInput = (event) => {
        const { selectDiscount } = this.state.editableOrder;
        selectDiscount[event.target.name] = event.target.value;
        this.setState(
            {
                editableOrder: {
                    ...this.state.editableOrder,
                    selectDiscount,
                },
            },
            () => {
                this.checkDisabledApply();
            }
        );
    };

    removeDiscount = () => {
        this.setState(
            {
                editableOrder: {
                    ...this.state.editableOrder,
                    selectDiscount: {
                        type: appConfig.DISCOUNT.TYPE.FIXED.value,
                        value: '',
                        note: '',
                    },
                    applyDiscount: {},
                },
            },
            () => this.calculateDiscount()
        );

        this.popoverDiscountRef.current.instanceRef.hide();
    };

    applyDiscount = () => {
        const applyDiscount = cloneDeep(this.state.editableOrder.selectDiscount);
        this.setState(
            {
                editableOrder: {
                    ...this.state.editableOrder,
                    applyDiscount,
                },
            },
            () => this.calculateDiscount()
        );

        this.popoverDiscountRef.current.instanceRef.hide();
    };

    calculateDiscount = () => {
        let {
            applyDiscount: { value, type },
            subTotal,
        } = this.state.editableOrder;
        let discountFee = 0;

        if (type) {
            if (type == appConfig.DISCOUNT.TYPE.FIXED.value) {
                discountFee = value;
            } else {
                if (value < 0) {
                    value = 0;
                }

                if (value > 100) {
                    value = 100;
                }
                discountFee = (baseHelper.parseFloat(subTotal) * baseHelper.parseFloat(value)) / 100;
            }

            if (discountFee > subTotal) {
                discountFee = subTotal;
            }
        }

        this.setState(
            {
                editableOrder: {
                    ...this.state.editableOrder,
                    discountFee,
                },
            },
            () => this.validateDiscount(true)
        );
    };

    validateDiscount = (force = false) => {
        const variants = cloneDeep(this.state.editableOrder.variants);
        if (baseHelper.isEmpty(variants)) {
            this.calculateTotal();
            return;
        }

        // Loại bỏ các promotion
        map(variants, (item) => {
            if (!item.is_custom_discount) {
                delete item.discount;
            }
        });

        const data = {
            order_id: this.props.order.order.order_id,
            discount_code: this.state.editableOrder.selectDiscount.code,
            variants,
            customer_id: this.state.orderCustomer.customer_id,
        };

        if (force) {
            this.oldData = data;
            this.props.validateDiscount(data);
        } else {
            if (!isEqual(this.oldData, data)) {
                this.oldData = data;
                this.props.validateDiscount(data);
            }
        }
    };

    checkDisabledApply = () => {
        const { selectDiscount } = this.state.editableOrder;
        let flag = false;

        if (baseHelper.isEmpty(this.state.editableOrder.variants)) {
            flag = true;
        }
        if ((selectDiscount.value || selectDiscount.note) && selectDiscount.code) {
            flag = true;
        }
        if (selectDiscount.type == appConfig.DISCOUNT.TYPE.PERCENT.value && selectDiscount.value > 100) {
            flag = true;
        }

        this.setState({
            editableOrder: {
                ...this.state.editableOrder,
                disabledApplyDiscount: flag,
            },
        });
    };

    applyVariantDiscount = (index, discount) => {
        const { variants } = this.state.editableOrder;
        variants[index].discount = discount;
        variants[index].is_custom_discount = true;

        this.setState(
            {
                editableOrder: {
                    ...this.state.editableOrder,
                    variants,
                },
            },
            () => {
                this.validateDiscount();
            }
        );
    };

    removeVariantDiscount = (index) => {
        const { variants } = this.state.editableOrder;
        variants[index].is_custom_discount = true;
        delete variants[index].discount;

        this.setState(
            {
                editableOrder: {
                    ...this.state.editableOrder,
                    variants,
                },
            },
            () => {
                this.validateDiscount();
            }
        );
    };
    /****************************************************************/

    /*******************************SHIPPING***************************/
    showShippingOption = () => {
        const classList = document.getElementsByClassName('shipping-box')[0].classList;
        if (classList.contains('visible')) {
            classList.remove('visible');
            return;
        }

        classList.add('visible');
    };

    onChangeShippingType = (type, shippingRateDetail) => {
        const { selectShipping } = this.state.editableOrder;

        selectShipping.type = type;

        if (type == appConfig.SHIPPING_TYPE.AVAILABLE) {
            selectShipping.id = shippingRateDetail.shipping_rate_detail_id;
            selectShipping.note = shippingRateDetail.name;
            selectShipping.value = shippingRateDetail.fee;
        } else {
            selectShipping.note = '';
            selectShipping.value = '';
            delete selectShipping.id;
        }

        this.setState({
            editableOrder: {
                ...this.state.editableOrder,
                selectShipping,
            },
        });
    };

    onChangeShippingValue = (event) => {
        const { selectShipping } = this.state.editableOrder;

        selectShipping.value = event.target.value;
        this.setState({
            editableOrder: {
                ...this.state.editableOrder,
                selectShipping,
            },
        });
    };

    onChangeShippingNote = (event) => {
        const { selectShipping } = this.state.editableOrder;
        selectShipping[event.target.name] = event.target.value;
        this.setState({
            editableOrder: {
                ...this.state.editableOrder,
                selectShipping,
            },
        });
    };

    clearShippingRate = () => {
        this.setState(
            {
                editableOrder: {
                    ...this.state.editableOrder,
                    selectShipping: {
                        type: appConfig.SHIPPING_TYPE.FREE,
                        value: '',
                        note: '',
                    },
                    applyShipping: {},
                },
            },
            () => this.calculateShipping()
        );

        if (this.popoverShippingRef.current) {
            this.popoverShippingRef.current.instanceRef.hide();
        }
    };

    applyShipping = () => {
        const applyShipping = cloneDeep(this.state.editableOrder.selectShipping);
        this.setState(
            {
                editableOrder: {
                    ...this.state.editableOrder,
                    applyShipping,
                },
            },
            () => this.calculateShipping()
        );
        this.popoverShippingRef.current.instanceRef.hide();
    };

    calculateShipping = () => {
        let { value } = this.state.editableOrder.applyShipping;
        let shipping_fee = baseHelper.parseFloat(value);

        this.setState(
            {
                editableOrder: {
                    ...this.state.editableOrder,
                    shipping_fee,
                },
            },
            () => this.calculateTotal(false)
        );
    };
    /****************************************************************/

    /*******************************CUSTOMER***************************/
    openCreateCustomerForm = () => {
        baseHelper.resetForm('modal-customer');

        this.setState({
            currentCustomerInfo: {},
        });
    };
    /****************************************************************/

    /*******************************ADD PRODUCT***************************/
    onChangeCustomVariant = (event) => {
        this.setState({
            editableOrder: {
                ...this.state.editableOrder,
                customVariant: {
                    ...this.state.editableOrder.customVariant,
                    [event.target.name]: event.target.value,
                },
            },
        });
    };

    openModalNewProduct = () => {
        this.setState({
            editableOrder: {
                ...this.state.editableOrder,
                isShowModalNewProduct: true,
            },
            isShowModalAddProduct: false,
        });
    };

    hideModalNewProduct = () => {
        this.setState({
            editableOrder: {
                ...this.state.editableOrder,
                customVariant: {},
                isShowModalNewProduct: false,
            },
        });
    };

    createNewProduct = () => {
        // Validate
        let errors = [];
        const inputsRef = compact(Array.from(this.formProductCreateRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        this.selectVariant(cloneDeep(this.state.editableOrder.customVariant));

        this.hideModalNewProduct();
    };

    openModalRefund = () => {
        this.setState({
            isShowModalRefund: true,
        });
    };

    hideModalRefund = () => {
        this.setState({
            refundPrice: '',
            isShowModalRefund: false,
        });
    };

    refund = (data) => {
        this.props.refund(data);
    };
    /****************************************************************/

    /******************************************CANCEL********************************/
    openModalCancel = () => {
        const allowCancel =
            !this.props.order.order.shipping_status ||
            includes(
                [appConfig.SHIPPING_STATUS.PENDING.CODE, appConfig.SHIPPING_STATUS.CANCELED.CODE],
                this.props.order.order.shipping_status
            );
        if (!allowCancel) {
            window.LadiUI.toastCustom('danger', '', this.props.t('ORDERS.CANCEL_ORDER_INVALID_BY_SHIPPING'));
            return;
        }
        this.setState({
            isShowModalCancel: true,
        });
    };

    openModalConfirmCancel = () => {
        this.setState({
            isShowModalConfirmCancel: true,
        });
    };

    hideModalCancel = () => {
        this.setState({
            isShowModalCancel: false,
        });
    };

    hideModalConfirmCancel = () => {
        this.setState({
            isShowModalConfirmCancel: false,
        });
    };

    confirmResendTicket = () => {
        let data = {
            order_id: this.selectedOrderID,
        };
        this.props.resendTicketList(data);
    };

    hideModalConfirmResendTicket = () => {
        this.setState({
            isShowModalConfirmResendTicket: false,
        });
    };

    hasEvent = () => {
        if (!this.props.order.order) {
            return;
        }
        const orderDetails = this.props.order.order.order_details;
        const foundEvent = find(orderDetails, (item) =>
            [appConfig.PRODUCT_TYPE.EVENT.CODE, appConfig.PRODUCT_TYPE.SERVICE.CODE, appConfig.PRODUCT_TYPE.DIGITAL.CODE].includes(
                item.product_type
            )
        );
        return foundEvent;
    };
    /********************************************************************************/

    getLastPrice = (variant) => {
        if (!variant.discount) {
            return baseHelper.parseFloat(variant.price);
        }

        const discountFee = baseHelper.calculateDiscountFee(variant.price, variant.discount.type, variant.discount.value);
        return baseHelper.parseFloat(variant.price) - baseHelper.parseFloat(discountFee);
    };

    getStatusName = (status) => {
        const matched = find(appConfig.ORDER_STATUS, (item) => item.CODE === status);
        if (matched) {
            return matched.NAME;
        }
    };

    print = () => {
        this.props.print(this.props.match.params['orderID']);
    };

    openModalConfirmCreateOrder = (paymentStatus) => {
        if (!this.validateOrder()) {
            return;
        }

        if (!this.loadedListPayment) {
            this.props.listGateways();
        }

        this.setState({
            editableOrder: {
                ...this.state.editableOrder,
                isShowModalConfirmCreate: true,
            },
            selectedPayment: {
                method: '',
                type: '',
                status: paymentStatus,
            },
        });
    };

    validateOrder = (hasPayment = false) => {
        const { variants } = this.state.editableOrder;
        const { orderCustomer } = this.state;

        if (variants.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('ORDERS.SELECT_AT_LEAST_ONE_PRODUCT'));
            return false;
        }

        if (!orderCustomer.customer_id) {
            window.LadiUI.toastCustom('danger', '', this.props.t('ORDERS.SELECT_AT_LEAST_ONE_CUSTOMER'));
            return false;
        }

        if (hasPayment) {
            let errors = this.paymentRef.validate();
            if (errors.length > 0) {
                const errorMessage = errors.join('<br/>');
                window.LadiUI.toastCustom('danger', '', errorMessage);

                return false;
            }
        }

        return true;
    };

    markAsPaid = () => {
        const {
            order: { order_id: orderID },
        } = this.props.order;
        let errors = this.paymentRef.validate();
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);

            return;
        }

        const { selectedPayment } = this.state;

        const { is_auto_shipping } = this.state.editableOrder;
        let payment_type = selectedPayment.type;
        if (selectedPayment.method == appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE) {
            payment_type = 'SSP';
        }
        if (selectedPayment.method == appConfig.PAYMENT_PARTNERS.BANK.CODE) {
            payment_type = 'BK';
        }
        if (selectedPayment.method == appConfig.PAYMENT_PARTNERS.COD.CODE) {
            payment_type = selectedPayment.method;
        }
        if (selectedPayment.method == appConfig.PAYMENT_PARTNERS.MOMO.CODE) {
            payment_type = 'MO';
        }
        if (selectedPayment.method == appConfig.PAYMENT_PARTNERS.PAYPAL.CODE) {
            payment_type = 'PAYPAL';
        }
        this.props.markAsPaid(orderID, selectedPayment.method, payment_type, is_auto_shipping);
    };

    generateCheckout = () => {
        const {
            order: { order_id: orderID },
        } = this.props.order;
        this.props.generateCheckout(orderID);
    };

    /******************************************************************************************************************************************************************/
    showModalConfirmAssignToOther = () => {
        this.setState({
            isShowModalAssignOther: true,
        });
    };

    getAssignToOtherModal = () => {
        const { t } = this.props;
        const { userInfo, isShowModalAssignOther } = this.state;
        const isLoadingAssignToOther = this.props.order.loading && this.props.order.waiting == orderTypes.ASSIGN_TO_OTHER_ORDER;

        if (!isShowModalAssignOther) {
            return null;
        }
        return (
            <Modal
                id='confirm-assign-other'
                visible={isShowModalAssignOther}
                width={600}
                title={t('ORDERS.MSG_ASSIGN_TO_TITLE')}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.OK')}
                onCancel={() => {
                    this.setState({
                        assigneeID: this.props.order.order.assignee_id,
                        isShowModalAssignOther: false,
                    });
                }}
                onOk={() => this.props.assignToOther(this.props.order.order.order_id, this.state.assigneeID)}
                isLoading={isLoadingAssignToOther}
            >
                {t('ORDERS.MSG_ASSIGN_TO_CONTENT', baseHelper.getStaffNameById(userInfo.staffs, this.state.assigneeID))}
            </Modal>
        );
    };

    updateTag = (_data) => {
        const {
            order: { order_id: orderID },
        } = this.props.order;
        const data = {
            order_id: orderID,
        };
        if (_data.create_tag) {
            data.create_tag_id = _data.create_tag.order_tag_id;
        }

        if (_data.delete_tag) {
            data.delete_tag_id = _data.delete_tag.order_tag_id;
        }

        this.props.updateTag(data);
    };

    openModalAddTag = () => {
        let blockTag = document.querySelector('#block-dropdown-tag-custom');

        blockTag.classList.remove('open');
        this.setState({
            tagSelected: {
                color: appConfig.TAG_COLORS[0],
            },
            isShowModalAddTag: true,
        });
    };

    getPaymentType = (type) => {
        const matched = find(appConfig.SN_PAYMENT_METHODS, (item) => item.CODE == type);
        if (matched) {
            return matched.NAME;
        }
    };

    getDataPaymentTypes = (types) => {
        let { selectedPayment } = this.state;

        let dataListTypes = [];
        if (selectedPayment && selectedPayment.method == appConfig.PAYMENT_PARTNERS.APPOTA.CODE) {
            map(appConfig.SN_PAYMENT_APPOTA_METHODS, (value, index) => {
                let findIndex = types.findIndex((item) => item == value.CODE);
                if (findIndex !== -1) {
                    dataListTypes.push({
                        code: value.CODE,
                        name: value.NAME,
                    });
                }
            });
        } else {
            map(appConfig.SN_PAYMENT_METHODS, (value, index) => {
                let findIndex = types.findIndex((item) => item == value.CODE);
                if (findIndex !== -1) {
                    dataListTypes.push({
                        code: value.CODE,
                        name: value.NAME,
                    });
                }
            });
        }
        return dataListTypes;
    };

    getMoreInfo = () => {
        const { t } = this.props;
        const order = this.props.order.order || {};

        const copy = (text) => {
            baseHelper.copyToClipboard(text);
            window.LadiUI.toastCustom('success', '', this.props.t('COMMON.COPIED'));
        };

        return (
            <div className='more-info block-tracking-info'>
                <div className='item-tracking' data-tooltip={order.ip ? order.ip : ''} data-tooltip-position='bottom'>
                    <span>
                        <i onClick={() => copy(order.ip ? order.ip : '')} className='ladi-icon icon-copy' />
                        <b>{t('ORDERS.IP')}:</b>
                    </span>
                    <span className='show-content'>{order.ip ? order.ip : ''}</span>
                </div>
                <div className='item-tracking' data-tooltip={order.url_page ? order.url_page : ''} data-tooltip-position='bottom'>
                    <span>
                        <i onClick={() => copy(order.url_page ? order.url_page : '')} className='ladi-icon icon-copy' />
                        <b>{t('ORDERS.URL_PAGE')}:</b>
                    </span>
                    <a target='_blank' href={order.url_page ? order.url_page : ''}>
                        <span className='show-content'>{order.url_page ? order.url_page : ''}</span>
                    </a>
                </div>
                <div className='item-tracking' data-tooltip={order.utm_source ? order.utm_source : ''} data-tooltip-position='bottom'>
                    <span>
                        <i onClick={() => copy(order.utm_source ? order.utm_source : '')} className='ladi-icon icon-copy' />
                        <b>{t('ORDERS.UTM_SOURCE')}:</b>
                    </span>
                    <span className='show-content'>{order.utm_source ? order.utm_source : ''}</span>
                </div>
                <div className='item-tracking' data-tooltip={order.utm_medium ? order.utm_medium : ''} data-tooltip-position='bottom'>
                    <span>
                        <i onClick={() => copy(order.utm_medium ? order.utm_medium : '')} className='ladi-icon icon-copy' />
                        <b>{t('ORDERS.UTM_MEDIUM')}:</b>
                    </span>
                    <span className='show-content'>{order.utm_medium ? order.utm_medium : ''}</span>
                </div>
                <div className='item-tracking' data-tooltip={order.utm_campaign ? order.utm_campaign : ''} data-tooltip-position='bottom'>
                    <span>
                        <i onClick={() => copy(order.utm_campaign ? order.utm_campaign : '')} className='ladi-icon icon-copy' />
                        <b>{t('ORDERS.UTM_CAMPAIGN')}:</b>
                    </span>
                    <span className='show-content'>{order.utm_campaign ? order.utm_campaign : ''}</span>
                </div>
                <div className='item-tracking' data-tooltip={order.utm_term ? order.utm_term : ''} data-tooltip-position='bottom'>
                    <span>
                        <i onClick={() => copy(order.utm_term ? order.utm_term : '')} className='ladi-icon icon-copy' />
                        <b>{t('ORDERS.UTM_TERM')}:</b>
                    </span>
                    <span className='show-content'>{order.utm_term ? order.utm_term : ''}</span>
                </div>
                <div className='item-tracking' data-tooltip={order.utm_content ? order.utm_content : ''} data-tooltip-position='bottom'>
                    <span>
                        <i onClick={() => copy(order.utm_content ? order.utm_content : '')} className='ladi-icon icon-copy' />
                        <b>{t('ORDERS.UTM_CONTENT')}:</b>
                    </span>
                    <span className='show-content'>{order.utm_content ? order.utm_content : ''}</span>
                </div>
                <div className='item-tracking' data-tooltip={order.msg_id ? order.msg_id : ''} data-tooltip-position='bottom'>
                    <span>
                        <i onClick={() => copy(order.msg_id ? order.msg_id : '')} className='ladi-icon icon-copy' />
                        <b>{t('ORDERS.MSG_ID')}:</b>
                    </span>
                    <span className='show-content'>{order.msg_id ? order.msg_id : ''}</span>
                </div>
                <div className='item-tracking' data-tooltip={order.ref ? order.ref : ''} data-tooltip-position='bottom'>
                    <span>
                        <i onClick={() => copy(order.ref ? order.ref : '')} className='ladi-icon icon-copy' />
                        <b>{t('ORDERS.REF')}:</b>
                    </span>
                    <span className='show-content'>{order.ref ? order.ref : ''}</span>
                </div>
                <div className='item-tracking' data-tooltip={order.ad_id ? order.ad_id : ''} data-tooltip-position='bottom'>
                    <span>
                        <i onClick={() => copy(order.ad_id ? order.ad_id : '')} className='ladi-icon icon-copy' />
                        <b>{t('ORDERS.AD_ID')}:</b>
                    </span>
                    <span className='show-content'>{order.ad_id ? order.ad_id : ''}</span>
                </div>

                <div className='item-tracking' data-tooltip={order.fb_id ? order.fb_id : ''} data-tooltip-position='bottom'>
                    <span>
                        <i onClick={() => copy(order.fb_id ? order.fb_id : '')} className='ladi-icon icon-copy' />
                        <b>{t('ORDERS.FB_ID')}:</b>
                    </span>
                    <span className='show-content'>{order.fb_id ? order.fb_id : ''}</span>
                </div>
            </div>
        );
    };

    setShowHideAddProduct = (status) => {
        if (status) {
            this.searchVariant();
        }
        this.setState({
            isShowModalAddProduct: status,
        });
        // window.LadiUI.showModal('add-product');
    };

    openModalShipping = () => {
        this.setState({
            isShowModalShipping: true,
        });
    };

    cancelModalShipping = () => {
        this.setState({
            isShowModalShipping: false,
        });
    };

    closeModalShipping = () => {
        this.setState({
            isShowModalShipping: false,
        });
    };

    handleCreateTag = (event) => {
        event.preventDefault();
        // Validate
        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        const { tagSelected } = this.state;
        let data = {
            name: tagSelected.name,
            color: tagSelected.color,
        };

        this.props.createOrderTag(data);
    };

    handleResendTicket = (orderID) => {
        this.selectedOrderID = orderID;

        this.setState({
            isShowModalConfirmResendTicket: true,
        });
    };

    //đơn hoàn tất
    getNotEditablePanel = () => {
        const { t } = this.props;
        const { assigneeID, selectedPayment, isShowModalAddProduct } = this.state;
        const isLoadingData =
            (this.props.order.loading &&
                includes(
                    [
                        orderTypes.SHOW_ORDER,
                        orderTypes.UPDATE_ORDER_STATUS,
                        orderTypes.UPDATE_ORDER,
                        orderTypes.PARTIAL_UPDATE_ORDER,
                        orderTypes.GENERATE_CHECKOUT_ORDER,
                        printTypes.PRINT_ORDER,
                    ],
                    this.props.order.waiting
                )) ||
            (this.props.paymentReducer.loading && paymentTypes.UPDATE_PAYMENT_STATUS == this.props.paymentReducer.waiting);

        const isLoadingUpdateAddress = this.props.order.loading && includes([orderTypes.UPDATE_ORDER_ADDRESS], this.props.order.waiting);
        const isLoadingAssign = this.props.order.loading && this.props.order.waiting == orderTypes.ASSIGN_ORDER;
        const isLoadingRefund = this.props.order.loading && orderTypes.REFUND_ORDER == this.props.order.waiting;
        const isLoadingCancel = this.props.order.loading && orderTypes.CANCEL_ORDER == this.props.order.waiting;
        const isLoadingMarkAsPaid = this.props.order.loading && orderTypes.MARK_AS_PAID == this.props.order.waiting;
        const isLoadingUpdateSpam = this.props.order.loading && orderTypes.UPDATE_SPAMS == this.props.order.waiting;
        const isLoadingResendTicket = this.props.order.loading && orderTypes.RESEND_TICKET_LIST == this.props.order.waiting;
        const isLoadingPartialUpdate = this.props.order.loading && orderTypes.PARTIAL_UPDATE_ORDER == this.props.order.waiting;
        const isLoadingGenerateCheckout = this.props.order.loading && orderTypes.GENERATE_CHECKOUT_ORDER == this.props.order.waiting;

        const isLoadingConfirmResendTicket = this.props.order.loading && orderTypes.RESEND_TICKET_LIST == this.props.order.waiting;
        const { orderCustomer, tags, currentCustomerInfo, note, userInfo } = this.state;

        const order = this.props.order.order || {};
        const isCanceled = order.status == appConfig.ORDER_STATUS.CANCELED.CODE;
        const restockAll = order.restock_all == appConfig.STATUS.ACTIVE;
        const orderDetails = order.order_details || [];

        const allTags = this.props.orderTag.allTags;
        const isNotDelivery = includes(
            [appConfig.SHIPPING_STATUS.PENDING.CODE, appConfig.SHIPPING_STATUS.CANCELED.CODE],
            order.shipping_status
        );

        let checkoutLink;
        if (order.is_generate_checkout && order.checkout_token) {
            checkoutLink = `${baseHelper.domainToUrl(this.props.storeReducer.userInfo.default_domain)}/checkout/${order.checkout_token}`;
            if (order.source == 'LADISALES' && order.url_page != '') {
                checkoutLink = `${baseHelper.getDomainName(order.url_page)}/checkout/${order.checkout_token}`;
            }
        }

        return (
            <div className='page-content' id='no-edit'>
                {(isLoadingData || isLoadingAssign || isLoadingUpdateSpam) && <LoadingSence blur={true} />}

                <div className='ladiui content-main-title flex-row'>
                    <div className='ladiui flex-row'>
                        <div className='ladiui main-title mr-12'>{`${t('MENU.ORDERS')} #${baseHelper.getText(order.reference_no)}`}</div>

                        <span className={`order-status ${order.status} mr-24`}>{this.getStatusName(order.status)}</span>
                        {order.mark_spam == appConfig.STATUS.ACTIVE && <span className='order-status mr-24'>{t('ORDERS.SPAM')}</span>}

                        {!isCanceled && restockAll && <span className={`order-status refund-all mr-24`}>{t('ORDERS.REFUND_ALL')}</span>}

                        <span className='placed-on flex mr-24'>
                            <img
                                src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-date.svg'}
                                alt=' '
                                style={{ marginRight: '4px' }}
                            />
                            <span>{baseHelper.formatStrToDate(order.created_at)}</span>
                        </span>
                        {order.status != appConfig.ORDER_STATUS.CANCELED.CODE && (
                            <div className='item-info mr-24'>
                                {assigneeID ? (
                                    <SelectOption
                                        wrapperClassName='select-assignee'
                                        innerClassName='ellipsis'
                                        currentKey={assigneeID}
                                        _key='ladi_uid'
                                        _value='name'
                                        items={userInfo.staffs || []}
                                        onSelectItem={(item) => {
                                            this.setState(
                                                {
                                                    assigneeID: item.ladi_uid,
                                                },
                                                () => {
                                                    this.showModalConfirmAssignToOther();
                                                }
                                            );
                                        }}
                                    />
                                ) : (
                                    <button
                                        className='ladiui btn btn-outline-primary btn-sm'
                                        onClick={() => this.props.assignOrder(order.order_id)}
                                    >
                                        {' '}
                                        {t('ORDERS.ASSIGN')}
                                    </button>
                                )}
                            </div>
                        )}

                        <span className='placed-on flex cursor-pointer mr-24' onClick={this.print}>
                            <img
                                src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-print-order.svg'}
                                alt=' '
                                style={{ marginRight: '4px' }}
                            />
                            <span>{t('ORDERS.PRINT_ORDER')}</span>
                        </span>

                        {order.is_draft == appConfig.STATUS.ACTIVE &&
                            order.is_generate_checkout != appConfig.STATUS.ACTIVE &&
                            order.status != appConfig.ORDER_STATUS.CANCELED.CODE && (
                                <span className='placed-on cursor-pointer flex mr-24' onClick={() => this.generateCheckout()}>
                                    <img className='mr-8' src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-tag.svg'} alt='' />
                                    <span>{t('ORDERS.GET_LINK_CHECKOUT_CREATE')}</span>
                                </span>
                            )}
                    </div>

                    <div className='flex'>
                        <div className='ladiui btn-group mr-24'>
                            <div className='ladiui dropdown hide-mt ba-c btn-action-bc'>
                                <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle pd-3'>
                                    <i className='ladiui icon icon-ldp-dot'></i>
                                </button>
                                <ul className='ladiui dropdown-menu r-0 block-list-actions-orders'>
                                    {[appConfig.SHIPPING_STATUS.PENDING.CODE, appConfig.SHIPPING_STATUS.CANCELED.CODE].includes(
                                        order.shipping_status
                                    ) && (
                                        <li>
                                            <a className='ladiui dropdown-item' onClick={this.openModalCancel}>
                                                {t('ACTIONS.CANCEL')}
                                            </a>
                                        </li>
                                    )}
                                    {[appConfig.SHIPPING_STATUS.PENDING.CODE, appConfig.SHIPPING_STATUS.CANCELED.CODE].includes(
                                        order.shipping_status
                                    ) && (
                                        <li>
                                            <a className='ladiui dropdown-item' onClick={this.openModalConfirmCancel}>
                                                {t('ACTIONS.REORDER')}
                                            </a>
                                        </li>
                                    )}
                                    {order.mark_spam == appConfig.STATUS.ACTIVE && (
                                        <li>
                                            <a
                                                className='ladiui dropdown-item'
                                                onClick={() =>
                                                    this.props.updateSpams({
                                                        order_ids: [order.order_id],
                                                        mark_spam: appConfig.STATUS.INACTIVE,
                                                    })
                                                }
                                            >
                                                {t('ORDERS.REMOVE_MARK_SPAM')}
                                            </a>
                                        </li>
                                    )}

                                    {order.mark_spam == appConfig.STATUS.INACTIVE &&
                                        order.status != appConfig.ORDER_STATUS.CANCELED.CODE && (
                                            <li>
                                                <a
                                                    className='ladiui dropdown-item'
                                                    onClick={() =>
                                                        this.props.updateSpams({
                                                            order_ids: [order.order_id],
                                                            mark_spam: appConfig.STATUS.ACTIVE,
                                                        })
                                                    }
                                                >
                                                    {t('ORDERS.MARK_SPAM')}
                                                </a>
                                            </li>
                                        )}
                                </ul>
                            </div>
                        </div>

                        <button
                            className={`ladiui btn btn-primary ${isLoadingPartialUpdate ? 'loader' : ''}`}
                            disabled={isLoadingPartialUpdate}
                            onClick={() => this.partialSubmit(isCanceled)}
                        >
                            <img
                                src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-save-data.svg'}
                                alt=''
                                style={{ marginRight: '8px' }}
                            />
                            {t('ORDERS.SAVE')}
                        </button>
                    </div>
                </div>

                <div className='flex order-custom-edit' style={{ marginBottom: '24px', marginTop: '16px' }}>
                    <img className='mr-8' src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-tag.svg'} alt='' />
                    <DropdownTag
                        ref={this.tagRef}
                        allTags={allTags}
                        selectedTags={tags}
                        innerClassName='dropdown-tag-color-order'
                        updateTag={this.updateTag}
                        openModalAddTag={this.openModalAddTag}
                    />
                </div>

                <div>
                    <div className='ladiui-order-left'>
                        <div className='ladi-card order-products' style={{ marginBottom: '8px' }}>
                            <div>
                                {orderDetails.map((item, index) => {
                                    return (
                                        <div className={`item-variant py-24 ${index == 0 ? 'border-none' : ''}`} key={index}>
                                            <div className='image-product image-product-variant mr-16'>
                                                <Image src={item.src} width={82} height={82} />
                                            </div>
                                            <div className='w-name-product'>
                                                <div className='item-variant-name-info'>
                                                    {item.product_id ? (
                                                        <a className='item-title' href={baseHelper.getUrlVariant(item)}>
                                                            {item.name || item.product_name_full}
                                                        </a>
                                                    ) : (
                                                        <span className='item-title'>{item.name || item.product_name_full}</span>
                                                    )}
                                                    {item.note ? <p className='product-note'>{item.note}</p> : ''}
                                                    <div className='item-variant-list-option'>
                                                        {item.options &&
                                                            item.options.map((option, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <span className='option-name'>{option.option_name}: </span>
                                                                        <span className='option-value'>{option.option_value_value}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-200 flex'>
                                                {item.discount_type && (
                                                    <Popover
                                                        wrapperProps={{ className: 'price-option' }}
                                                        wrapperPopoverProps={{
                                                            className: 'text-left price-box',
                                                            style: { width: 200 },
                                                        }}
                                                        content={
                                                            <div>
                                                                <h2 style={{ fontWeight: 600 }}>{t('DISCOUNTS.DISCOUNT_REDUCE')}</h2>
                                                                <h2>{t('DISCOUNTS.DISCOUNT_EACH_ITEM')}</h2>
                                                                <div style={{ marginBottom: 20, fontWeight: 600 }}>
                                                                    {baseHelper.formatMoneyPostFix(
                                                                        item.discount_fee,
                                                                        this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                                    )}
                                                                </div>
                                                                <h2 style={{ fontWeight: 600 }}>{t('COMMON.REASON')}</h2>
                                                                <div>{item.discount_note}</div>
                                                            </div>
                                                        }
                                                    >
                                                        <a style={{ textDecoration: 'line-through' }}>
                                                            <span className='price-before-discount'>
                                                                {baseHelper.formatMoneyPostFix(
                                                                    item.price,
                                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                                )}
                                                            </span>
                                                        </a>
                                                    </Popover>
                                                )}
                                                {item.discount_type &&
                                                    baseHelper.formatMoneyPostFix(
                                                        item.last_price,
                                                        this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                    )}
                                                {!item.discount_type &&
                                                    baseHelper.formatMoneyPostFix(
                                                        item.price,
                                                        this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                    )}
                                            </div>
                                            <div className='w-120'>
                                                <span>x {item.quantity}</span>
                                            </div>
                                            <div className='w-190 text-end item-title'>
                                                {baseHelper.formatMoneyPostFix(
                                                    item.total,
                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className='footer-order'>
                                <div className='w-49-p'>
                                    {order.is_generate_checkout == appConfig.STATUS.ACTIVE && order.checkout_token && (
                                        <div className='payment-shipping mb-16'>
                                            <div className='title-lable mb-8'>{t('ORDERS.LINK_CHECKOUT')}</div>
                                            <div className='url-checkout'>
                                                <SelectOption
                                                    wrapperClassName='select-checkout-config'
                                                    innerClassName='ellipsis'
                                                    currentKey={this.state.selectedCheckoutConfig.checkout_config_id}
                                                    _key='checkout_config_id'
                                                    _value='name'
                                                    items={this.props.checkoutConfigReducer.checkout_configs || []}
                                                    onSelectItem={(item) => {
                                                        this.setState(
                                                            {
                                                                selectedCheckoutConfig: item,
                                                            },
                                                            () => {
                                                                baseHelper.copyToClipboard(
                                                                    `${checkoutLink}?checkout_config_id=${this.state.selectedCheckoutConfig.checkout_config_id}`
                                                                );
                                                                window.LadiUI.toastCustom('success', '', t('COMMON.COPIED'));
                                                            }
                                                        );
                                                    }}
                                                    innerStyle={{
                                                        width: '100%',
                                                        marginRight: '0px !important',
                                                    }}
                                                />
                                                <div className='title-payment'>
                                                    <div
                                                        className='link flex'
                                                        data-tooltip={`${checkoutLink}?checkout_config_id=${this.state.selectedCheckoutConfig.checkout_config_id}`}
                                                        data-tooltip-position='top'
                                                    >
                                                        <a
                                                            className='link-checkout'
                                                            href={`${checkoutLink}?checkout_config_id=${this.state.selectedCheckoutConfig.checkout_config_id}`}
                                                        >
                                                            {`${checkoutLink}?checkout_config_id=${this.state.selectedCheckoutConfig.checkout_config_id}`}
                                                        </a>
                                                        <span
                                                            onClick={() => {
                                                                baseHelper.copyToClipboard(
                                                                    `${checkoutLink}?checkout_config_id=${this.state.selectedCheckoutConfig.checkout_config_id}`
                                                                );
                                                                window.LadiUI.toastCustom('success', '', this.props.t('COMMON.COPIED'));
                                                            }}
                                                            className='cursor-pointer btn-coppy'
                                                        >
                                                            <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-coppy.svg' alt='' />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {order.payment_status == appConfig.PAYMENT_STATUS.SUCCESS.CODE && this.hasEvent() && (
                                        <div className='payment-shipping flex space-between'>
                                            <div className='title-payment flex items-center'>
                                                <img
                                                    src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-ticket-seat.svg'}
                                                    alt=''
                                                    style={{ marginRight: '8px' }}
                                                />
                                                <span>{t('PRODUCT_EVENTS.TAB_EVENT_SEATS')}</span>
                                            </div>
                                            <div className='resend-view-ticket'>
                                                <button
                                                    className='ladiui btn btn-secondary mr-16'
                                                    onClick={() => this.handleResendTicket(order.order_id)}
                                                    disabled={isLoadingResendTicket}
                                                >
                                                    {' '}
                                                    Gửi lại danh sách vé
                                                </button>
                                                <button
                                                    className='ladiui btn btn-secondary'
                                                    onClick={() => {
                                                        this.setState({ isShowModalTicketSeat: true });
                                                    }}
                                                >
                                                    {' '}
                                                    {t('COMMON.VIEW')}
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    {!isCanceled && !restockAll && !isNotDelivery && (
                                        <div className='payment-shipping' style={{ borderTop: 0 }}>
                                            <div className='title-payment items-center' style={{ display: 'flex' }}>
                                                <img
                                                    src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-shipping-success.svg'}
                                                    alt=''
                                                    style={{ marginRight: '8px' }}
                                                />
                                                <span>{t('SHIPPINGS.DELIVERED_TITLE')}</span>
                                            </div>
                                        </div>
                                    )}

                                    <div className='payment-shipping'>
                                        <div className='title-payment items-center' style={{ display: 'flex' }}>
                                            <img
                                                src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-not-print-order.svg'
                                                alt=''
                                                style={{ marginRight: '8px' }}
                                            />
                                            {order.is_export_invoice == appConfig.STATUS.ACTIVE ? (
                                                <span>{t('ORDERS.ALLOW_EXPORT_INVOICE')}</span>
                                            ) : (
                                                <span>{t('ORDERS.DO_NOT_ALLOW_EXPORT_INVOICE')}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className='block-note mb-24'>
                                        <div className='title-lable mb-8'>{t('ORDERS.NOTE')}</div>
                                        <textarea
                                            className='ladiui textarea'
                                            value={note}
                                            name='note'
                                            onChange={(event) => {
                                                this.setState({
                                                    note: event.target.value,
                                                });
                                            }}
                                        />
                                    </div>

                                    <div className='ladiui toggle-dropdown-product-edit block-custom-field'>
                                        <h3
                                            className={`ladiui title-block pointer relative mb-24 ${
                                                this.state.isShowBlockCustomField ? 'arrow-up' : 'arrow-down'
                                            }`}
                                            onClick={() =>
                                                this.setState({
                                                    isShowBlockCustomField: !this.state.isShowBlockCustomField,
                                                })
                                            }
                                        >
                                            {t('PRODUCTS.CUSTOM_FIELD')}
                                        </h3>
                                        <div style={this.state.isShowBlockCustomField ? { display: 'block' } : { display: 'none' }}>
                                            <PanelCustomField
                                                ref={this.panelCustomFieldRef}
                                                customFields={order.custom_fields}
                                                groupType={appConfig.CUSTOM_FIELD.GROUP_TYPES.ORDER.CODE}
                                                actionType={appConfig.FORM_MODE.EDIT}
                                                orderID={order.order_id}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='w-49-p'>
                                    <div className='item-info'>
                                        <div className='flex'>
                                            <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-dola.svg'} alt='' />
                                            <span className='footer-lable'>{t('ORDERS.TOTAL_AMOUNT')}</span>
                                        </div>
                                        <div>
                                            <span>
                                                {baseHelper.formatMoneyPostFix(
                                                    order.sub_total,
                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='item-info'>
                                        <div className='flex'>
                                            <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-discount.svg'} alt='' />
                                            <span className='footer-lable'>{t('ORDERS.DISCOUNT')}&nbsp;</span>
                                            {order.discount_code
                                                ? ' - ' + order.discount_code
                                                : order.discount_note
                                                ? ' - ' + order.discount_note
                                                : ''}
                                        </div>
                                        <div>
                                            <span>
                                                {' - '}
                                                {baseHelper.formatMoneyPostFix(
                                                    order.discount_fee,
                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    {order.package_discount_value > 0 && (
                                        <div className='item-info'>
                                            <div className='flex'>
                                                <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-discount.svg'} alt='' />
                                                <span className='footer-lable'>{order.package_discount_note} </span>
                                            </div>
                                            <div>
                                                <span>
                                                    {baseHelper.formatMoneyPostFix(
                                                        order.package_discount_price,
                                                        this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    )}

                                    <div className='item-info'>
                                        <div className='flex'>
                                            <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-delivery.svg'} alt='' />
                                            <span className='footer-lable'>{t('ORDERS.SHIPPING')}&nbsp;</span>
                                            <span>{order.shipping_rate_name ? ' - ' + order.shipping_rate_name : ''}</span>
                                        </div>
                                        <div>
                                            <span>
                                                {baseHelper.formatMoneyPostFix(
                                                    order.shipping_fee,
                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </span>
                                        </div>
                                    </div>

                                    {order.discount_shipping_fee != undefined && order.discount_shipping_fee > 0 && (
                                        <div className='item-info'>
                                            <div className='flex'>
                                                <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-delivery.svg'} alt='' />
                                                <span className='footer-lable'>{t('ORDERS.DISCOUNT_SHIPPING')}&nbsp;</span>
                                                <span>{order.discount_shipping_code ? ' - ' + order.discount_shipping_code : ''}</span>
                                            </div>
                                            <div>
                                                <span>
                                                    {' - '}
                                                    {baseHelper.formatMoneyPostFix(
                                                        order.discount_shipping_fee,
                                                        this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    )}

                                    <div className='item-info'>
                                        <div className='flex'>
                                            <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-dola.svg'} alt='' />
                                            <span className='footer-lable'>{t('ORDERS.TOTAL_ORDER')}</span>
                                        </div>
                                        <div>
                                            <span className='bold-500'>
                                                {baseHelper.formatMoneyPostFix(
                                                    order.total,
                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='item-info'>
                                        <div className='flex'>
                                            <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-dola.svg'} alt='' />
                                            <span className='footer-lable'>{t('ORDERS.CUSTOMER_PAYMENT')}</span>
                                        </div>
                                        <div>
                                            <span style={{ color: '#41B983' }} className='bold-500'>
                                                {baseHelper.formatMoneyPostFix(
                                                    order.paid,
                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </span>
                                        </div>
                                    </div>

                                    {order.refund > 0 && (
                                        <div className='item-info'>
                                            <div className='flex'>
                                                <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-dola.svg'} alt='' />
                                                <span className='footer-lable'>{t('ORDERS.REFUND')}</span>
                                            </div>
                                            <div>
                                                <span>
                                                    {baseHelper.formatMoneyPostFix(
                                                        order.refund,
                                                        this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    )}

                                    {order.refund > 0 && (
                                        <div className='item-info'>
                                            <div className='flex'>
                                                <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-dola.svg'} alt='' />
                                                <span className='footer-lable'>{t('ORDERS.ORDER_PAID')}</span>
                                            </div>
                                            <div>
                                                <span>
                                                    {baseHelper.formatMoneyPostFix(
                                                        order.order_paid,
                                                        this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    )}

                                    {/* {!isCanceled && !restockAll && order.payment_status == appConfig.PAYMENT_STATUS.SUCCESS.CODE && ( */}
                                    {order.gateway_transaction_id && (
                                        <div className='item-info'>
                                            <div className='flex'>
                                                <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-trading-code.svg'} alt='' />
                                                <span className='footer-lable'>{t('ORDERS.TRADING_CODE')}</span>
                                            </div>
                                            <div>
                                                <span>{order.gateway_transaction_id}</span>
                                            </div>
                                        </div>
                                    )}

                                    {order.transaction_status_id && order.transaction_status_id != order.gateway_transaction_id && (
                                        <div className='item-info'>
                                            <div className='flex'>
                                                <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-trading-code.svg'} alt='' />
                                                <span className='footer-lable'>
                                                    {t('ORDERS.TRADING_CODE')} {this.getPaymentType(order.payment_type)}
                                                </span>
                                            </div>
                                            <div>
                                                <span>{order.transaction_status_id}</span>
                                            </div>
                                        </div>
                                    )}

                                    {/* {!isCanceled && !restockAll && order.payment_status == appConfig.PAYMENT_STATUS.PENDING.CODE && ( */}
                                    {!isCanceled && !restockAll && (
                                        <>
                                            <div className='item-info'>
                                                <div className='flex'>
                                                    <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-payment.svg'} alt='' />
                                                    <span className='footer-lable'>{t('ORDERS.PAYMENT_TYPE')}</span>
                                                </div>
                                                <div>
                                                    <span>
                                                        {appConfig.PAYMENT_PARTNERS[order.payment_method]
                                                            ? appConfig.PAYMENT_PARTNERS[order.payment_method].NAME
                                                            : ''}
                                                    </span>
                                                </div>
                                            </div>

                                            {order.payment_method == appConfig.PAYMENT_PARTNERS.VNPT_EPAY.CODE &&
                                            order.payment_vnpt_epay_data &&
                                            order.payment_vnpt_epay_data.is_va ? (
                                                <>
                                                    <div className='item-info pt-0'>
                                                        <span className='pl-24'>{t('ORDERS.VA_NAME')}</span>
                                                        <span>{order.payment_vnpt_epay_data.va_name}</span>
                                                    </div>
                                                    <div className='item-info pt-0'>
                                                        <span className='pl-24'>{t('ORDERS.VA_NUMBER')}</span>
                                                        <span>{order.payment_vnpt_epay_data.va_number}</span>
                                                    </div>
                                                </>
                                            ) : null}

                                            {order.payment_method == appConfig.PAYMENT_PARTNERS.FINAN.CODE &&
                                            order.payment_finan_pay_data ? (
                                                <>
                                                    <div className='item-info pt-0'>
                                                        <span className='pl-24'>{t('ORDERS.VA_NAME')}</span>
                                                        <span>{order.payment_finan_pay_data.va_name}</span>
                                                    </div>
                                                    <div className='item-info pt-0'>
                                                        <span className='pl-24'>{t('ORDERS.VA_NUMBER')}</span>
                                                        <span>{order.payment_finan_pay_data.va_number}</span>
                                                    </div>
                                                    {order.payment_finan_pay_data.remark && (
                                                        <div className='item-info pt-0'>
                                                            <span className='pl-24'>Ghi chú</span>
                                                            <span>{order.payment_finan_pay_data.remark}</span>
                                                        </div>
                                                    )}
                                                </>
                                            ) : null}

                                            {order.payment_method == appConfig.PAYMENT_PARTNERS.ALE_PAY.CODE &&
                                                order.payment_type == appConfig.PAYMENT_METHODS.ALE_PAY.TYPE.INSTALLMENT.CODE && (
                                                    <>
                                                        <div className='item-info pt-0'>
                                                            <span className='pl-24'>{t('ORDERS.PAYMENT_METHOD')}</span>
                                                            <span className='pl-24'>{this.getPaymentType(order.payment_type)}</span>
                                                        </div>
                                                        <div className='item-info pt-0'>
                                                            <span className='pl-24'>Thời hạn</span>
                                                            <span className='pl-24'>{order.payment_installment_month} tháng</span>
                                                        </div>
                                                    </>
                                                )}

                                            {order.payment_status == appConfig.PAYMENT_STATUS.PENDING.CODE && (
                                                <div className='item-info'>
                                                    <div className='flex'>
                                                        <span className='footer-lable'></span>
                                                    </div>
                                                    <div className='flex'>
                                                        <button
                                                            className='ladiui btn btn-secondary btn-sm mr-12'
                                                            onClick={this.openModalRefund}
                                                        >
                                                            {' '}
                                                            {t('ORDERS.REFUND_INVENTORY')}
                                                        </button>
                                                        <button
                                                            className='ladiui btn btn-primary btn-sm'
                                                            onClick={() => {
                                                                if (!this.loadedListPayment) {
                                                                    this.props.listGateways();
                                                                }
                                                                this.setState({
                                                                    isShowModalConfirmPayment: true,
                                                                });
                                                            }}
                                                        >
                                                            {t('ORDERS.CONFIRM_PAYMENT')}
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}

                                    {!isCanceled && !restockAll && order.payment_status == appConfig.PAYMENT_STATUS.SUCCESS.CODE && (
                                        <div className='item-info'>
                                            <div className=''>
                                                {baseHelper.parseFloat(order.order_paid) > 0 &&
                                                    baseHelper.parseFloat(order.order_paid) == baseHelper.parseFloat(order.total) && (
                                                        <React.Fragment>
                                                            {appConfig.PAYMENT_PARTNERS[order.payment_method] && (
                                                                <span className='footer-lable pl-0 pr-16'>
                                                                    {t(
                                                                        'ORDERS.PAYMENT_SUCCESS_ALL',
                                                                        appConfig.PAYMENT_PARTNERS[order.payment_method].NAME
                                                                    )}
                                                                    {order.payment_type ? `- ${order.payment_type}` : ''}
                                                                </span>
                                                            )}
                                                        </React.Fragment>
                                                    )}

                                                {baseHelper.parseFloat(order.order_paid) > 0 &&
                                                    baseHelper.parseFloat(order.order_paid) < baseHelper.parseFloat(order.total) && (
                                                        <span className='footer-lable'>
                                                            {t(
                                                                'ORDERS.PAYMENT_SUCCESS',
                                                                baseHelper.formatMoneyPostFix(
                                                                    order.order_paid,
                                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                                )
                                                            )}
                                                        </span>
                                                    )}

                                                {!baseHelper.isEmpty(order.refund) &&
                                                    baseHelper.parseFloat(order.refund) == baseHelper.parseFloat(order.total) && (
                                                        <span className='footer-lable'>{t('ORDERS.REFUND_ALL')}</span>
                                                    )}
                                            </div>
                                            <div className='flex'>
                                                {order.order_paid > 0 ? (
                                                    <button onClick={this.openModalRefund} className='ladiui btn btn-secondary refund'>
                                                        {t('ORDERS.REFUND')}
                                                    </button>
                                                ) : (
                                                    <button className='ladiui btn btn-secondary' onClick={this.openModalRefund}>
                                                        {' '}
                                                        {t('ORDERS.REFUND_INVENTORY')}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {!isCanceled && !restockAll && isNotDelivery && this.state.isShowModalShipping && (
                                <ShippingDelivery
                                    orderID={order.order_id}
                                    shippingAddress={this.state.shippingAddress}
                                    addresses={currentCustomerInfo.addresses}
                                    onChangeAddress={this.onChangeAddress}
                                    isShowModalShipping={this.state.isShowModalShipping}
                                    hideModalShipping={this.closeModalShipping}
                                    weight={order.weight}
                                    total={order.total}
                                    note={order.shipping_note}
                                    cod_amount={order.payment_status == appConfig.PAYMENT_STATUS.PENDING.CODE ? order.total : 0}
                                />
                            )}
                        </div>
                        {
                            // !baseHelper.isEmpty(order.shippings)
                            // &&
                            <ShippingDetail
                                orderID={order.order_id}
                                shippings={order.shippings}
                                disabled={isCanceled}
                                openModalShipping={this.openModalShipping}
                                cancelModalShipping={this.cancelModalShipping}
                            />
                        }

                        <div className='order-timeline'>{t('COMMON.HISTORY')}</div>
                        <History type={appConfig.HISTORY_FORM.ORDER} orderID={this.props.match.params['orderID']} />
                    </div>
                    <div className='ladiui-order-right block-customer-info-right'>
                        <div className='info-customer mb-24' style={{ padding: '0px !important' }}>
                            <div className='custom-info fix'>
                                <PanelCustomer
                                    mode={isCanceled ? appConfig.FORM_MODE.VIEW : appConfig.FORM_MODE.EDIT}
                                    orderID={this.props.match.params['orderID']}
                                    orderCustomer={orderCustomer}
                                    updatedOrderCustomer={this.updatedOrderCustomer}
                                    currentCustomerInfo={currentCustomerInfo}
                                />
                            </div>

                            <div className='block-shipping-address'>
                                <PanelShippingAddress
                                    ref={this.panelShippingAddressRef}
                                    mode={isCanceled ? appConfig.FORM_MODE.VIEW : appConfig.FORM_MODE.EDIT}
                                    shippingAddress={this.state.shippingAddress}
                                    addresses={currentCustomerInfo.addresses}
                                    onChangeAddress={this.onChangeAddress}
                                    isLoading={isLoadingUpdateAddress}
                                    classNameCustom='pb-24'
                                />
                                <PanelBillingAddress
                                    ref={this.panelBillingAddressRef}
                                    mode={isCanceled ? appConfig.FORM_MODE.VIEW : appConfig.FORM_MODE.EDIT}
                                    billingAddress={this.state.billingAddress}
                                    addresses={currentCustomerInfo.addresses}
                                    onChangeAddress={this.onChangeAddress}
                                    isLoading={isLoadingUpdateAddress}
                                    currentShippingAddress={this.state.shippingAddress}
                                    classNameCustom='pb-24'
                                />
                                {order.is_export_invoice == appConfig.STATUS.ACTIVE && (
                                    <PanelBillingInfomation
                                        ref={this.panelBillingInfomationRef}
                                        billingInfomation={this.state.billingInfomation}
                                        addresses={currentCustomerInfo.addresses}
                                        onChangeBillingInfo={this.onChangeAddress}
                                        classNameCustom='pb-24'
                                    />
                                )}

                                <div id='order-customer-field' className='order-customer-field'>
                                    <PanelCustomerField
                                        customFields={currentCustomerInfo.custom_fields}
                                        orderID={order.order_id}
                                        mode={appConfig.FORM_MODE.EDIT}
                                        customerID={orderCustomer.customer_id}
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            id='order-custom-field'
                            className='order-custom-field box-shadow-none set-max-width custom bottom mb-0'
                            style={{ border: '1px solid #f1f3f4' }}
                        >
                            <div className='title pb-8'>
                                <label className='ladiui-label'>{t('ORDERS.TRACKING')}</label>
                                <div className='hidden-custom-field'>
                                    <a
                                        href='#'
                                        onClick={() => {
                                            this.setState({
                                                isShowBlockUrl: !this.state.isShowBlockUrl,
                                            });
                                        }}
                                    >
                                        <i className='ladi-icon icon-down-arrow'></i>
                                    </a>
                                </div>
                            </div>
                            {this.state.isShowBlockUrl && this.getMoreInfo()}
                        </div>
                    </div>
                </div>

                {this.state.isShowModalRefund && (
                    <ModalRefund
                        orderID={order.order_id}
                        paymentStatus={order.payment_status}
                        refundAll={order.order_paid == 0}
                        visible={this.state.isShowModalRefund}
                        onOk={this.refund}
                        onCancel={this.hideModalRefund}
                        isLoading={isLoadingRefund}
                    />
                )}

                {this.state.isShowModalCancel && (
                    <ModalCancel
                        visible={this.state.isShowModalCancel}
                        onOk={this.cancelOrder}
                        onCancel={this.hideModalCancel}
                        isLoading={isLoadingCancel}
                    />
                )}

                {this.state.isShowModalConfirmCancel && (
                    <ModalConfirmCancel
                        visible={this.state.isShowModalConfirmCancel}
                        onOk={this.confirmOrderCancel}
                        onCancel={this.hideModalConfirmCancel}
                        isLoading={isLoadingCancel}
                    />
                )}

                {this.state.isShowModalConfirmPayment && (
                    <Modal
                        id='modal-confirm-payment'
                        title={t('ORDERS.MSG_PAYMENT_SUCCESS_TITLE')}
                        visible={this.state.isShowModalConfirmPayment}
                        onCancel={() => {
                            this.setState({
                                isShowModalConfirmPayment: false,
                            });
                        }}
                        bodyClassName='position-initial'
                        onOk={this.markAsPaid}
                        isLoading={isLoadingMarkAsPaid}
                        okText={t('COMMON.VERIFY')}
                        width={600}
                    >
                        {selectedPayment.method == appConfig.PAYMENT_PARTNERS.ALE_PAY.CODE &&
                            selectedPayment.type == appConfig.PAYMENT_METHODS.ALE_PAY.TYPE.INSTALLMENT.CODE && (
                                <p>
                                    <strong>{t('PAYMENTS.MSG_INSTALLMENT')}</strong>
                                </p>
                            )}
                        <p>
                            <strong>{t('ORDERS.MSG_PAYMENT_SUCCESS_CONFIRM')}</strong>
                        </p>
                        <p>
                            <span>
                                {t(
                                    'ORDERS.MSG_PAYMENT_SUCCESS_CONTENT2',
                                    baseHelper.formatMoneyPostFix(
                                        order.total,
                                        this.props.storeReducer.userInfo.currentStore.currency_symbol
                                    )
                                )}
                            </span>
                        </p>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>{t('PAYMENTS.LB_SELECT_PAYMENT')}</label>
                            <Dropdown
                                data={this.props.paymentReducer.gateways}
                                onSelectItem={(item) => {
                                    this.setState({
                                        selectedPayment: {
                                            ...this.state.selectedPayment,
                                            method: item.code,
                                        },
                                        listPaymentTypes: item.config.active_payment_methods,
                                    });
                                }}
                                currentKey={selectedPayment.method}
                                _key={'code'}
                                _value={'name'}
                                validationName={t('PAYMENTS.LB_PAYMENT_VALIDATION')}
                                placeHolder={t('PAYMENTS.LB_SELECT_PAYMENT')}
                                ref={(ref) => (this.paymentRef = ref)}
                                validations={{ isRequired: true }}
                                idBlock='dropdown-confirm-payment'
                                wrapperClassName='position-initial w100'
                            />
                        </div>
                        {!baseHelper.isEmpty(this.state.listPaymentTypes) && (
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('PAYMENTS.LB_SELECT_PAYMENT_TYPE')}</label>
                                <Dropdown
                                    data={this.getDataPaymentTypes(this.state.listPaymentTypes)}
                                    onSelectItem={(item) => {
                                        this.setState({
                                            selectedPayment: {
                                                ...this.state.selectedPayment,
                                                type: item.code,
                                            },
                                        });
                                    }}
                                    currentKey={selectedPayment.type}
                                    _key={'code'}
                                    _value={'name'}
                                    validationName={t('PAYMENTS.LB_PAYMENT_VALIDATION')}
                                    placeHolder={t('PAYMENTS.CHOOSE_PAYMENT_TYPE')}
                                    ref={(ref) => (this.paymentRef = ref)}
                                    validations={{ isRequired: true }}
                                    idBlock='dropdown-confirm-payment'
                                    wrapperClassName='position-initial w100'
                                />
                            </div>
                        )}
                        {userInfo.currentStore.store_id && userInfo.currentStore.store_id == '3319' && (
                            <div className='ladiui form-group'>
                                <div className=' flex flex-start'>
                                    <input
                                        type='checkbox'
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                        name='is_auto_shipping'
                                        onChange={(event) =>
                                            this.setState({
                                                editableOrder: {
                                                    ...this.state.editableOrder,
                                                    is_auto_shipping: !this.state.editableOrder.is_auto_shipping,
                                                },
                                            })
                                        }
                                        checked={this.state.editableOrder.is_auto_shipping}
                                    />{' '}
                                    Tự động tạo đơn vận chuyển
                                </div>
                            </div>
                        )}
                    </Modal>
                )}
                {orderDetails && orderDetails.length > 0 && (
                    <Modal
                        id='modal-ticket-seat'
                        wrapperClassName='tiket-seat-order-edit'
                        title={t('PRODUCT_EVENTS.TAB_EVENT_SEATS')}
                        visible={this.state.isShowModalTicketSeat}
                        onCancel={() => {
                            this.setState({
                                isShowModalTicketSeat: false,
                            });
                        }}
                        hasFooter={false}
                        width={1200}
                    >
                        <PanelTicketSeat orderID={order.order_id} isProductDigital={true} />
                    </Modal>
                )}
                {this.getAssignToOtherModal()}
                {isShowModalAddProduct && (
                    <ModalAddProduct
                        t={t}
                        onClose={this.setShowHideAddProduct}
                        isShow={isShowModalAddProduct}
                        listVariant={orderDetails}
                        isSelectedTypeProduct={false}
                        currency_symbol={this.props.storeReducer.userInfo.currentStore.currency_symbol}
                        openModalNewProduct={this.openModalNewProduct}
                        selectVariant={this.selectVariant}
                        // selectedProducts={variants || []}
                        onChangeSearchVariantInput={this.onChangeSearchVariantInput}
                    />
                )}

                {this.state.isShowModalAddTag && (
                    <Modal
                        id='modal-tag-edit'
                        title={t('TAGS.ADD_TAG')}
                        visible={this.state.isShowModalAddTag}
                        onCancel={() =>
                            this.setState({
                                isShowModalAddTag: false,
                            })
                        }
                        // onOk={this.handleCreateTag}
                        width={400}
                        bodyStyles={{ minHeight: '25vh' }}
                        isCustomFooter={true}
                        footer={
                            <div className='ladiui-modal-footer'>
                                <div className='ladiui page-tags'>
                                    <a className='flex' target='_blank' href='/settings#TAG'>
                                        <i className='icon-tag-footer-modal mr-8'></i>
                                        <span>Quản lý Tag</span>
                                    </a>
                                </div>
                                <div className='flex'>
                                    <button
                                        onClick={() =>
                                            this.setState({
                                                isShowModalAddTag: false,
                                            })
                                        }
                                        className='ladiui btn btn-secondary mr-12'
                                    >
                                        {t('ACTIONS.CLOSE')}
                                    </button>
                                    <button onClick={this.handleCreateTag} className='ladiui btn btn-primary'>
                                        {t('ACTIONS.SAVE')}
                                    </button>
                                </div>
                            </div>
                        }
                    >
                        <div className='ladiui form-group'>
                            <label className='ladiui-label' style={{ marginBottom: '8px' }}>
                                {t('TAGS.COL_TAG')}
                            </label>
                            <Input
                                value={this.state.tagSelected.name}
                                onChange={(event) => {
                                    this.setState({
                                        tagSelected: {
                                            ...this.state.tagSelected,
                                            name: event.target.value,
                                        },
                                    });
                                }}
                                validationName={t('TAGS.COL_TAG')}
                                validations={{ isRequired: true }}
                                ref={(ref) => this.inputsRef.add(ref)}
                            />
                        </div>
                        {this.state.typeSelected == appConfig.TAG_SELECTED.TAG_ORDER.CODE &&
                            this.state.tagSelected.color &&
                            this.state.tagSelected.name && (
                                <span
                                    className='order-tag-item'
                                    style={{ color: 'var(--main-default)', backgroundColor: this.state.tagSelected.color }}
                                >
                                    {this.state.tagSelected.name}
                                </span>
                            )}

                        {this.state.typeSelected == appConfig.TAG_SELECTED.TAG_ORDER.CODE && (
                            <div className='form-default-color'>
                                <label className='ladiui-label'>{t('TAGS.PICK_COLOR')}</label>
                                <div className='list-pick-color-tag-customer'>
                                    {map(appConfig.TAG_COLORS, (item) => (
                                        <span
                                            key={item}
                                            className='item'
                                            style={{ backgroundColor: item }}
                                            onClick={() => {
                                                this.setState({
                                                    tagSelected: {
                                                        ...this.state.tagSelected,
                                                        color: item,
                                                    },
                                                });
                                            }}
                                        >
                                            {item == this.state.tagSelected.color && <i className='ladi-icon icon-d-check' />}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Modal>
                )}

                {this.state.isShowModalConfirmResendTicket && (
                    <ConfirmModal
                        id='confirm-resend-ticket'
                        className='visible'
                        content={'Bạn có đồng ý gửi lại danh sách mã vé của đơn hàng này ?'}
                        title={'Thông báo'}
                        onOk={this.confirmResendTicket}
                        onCancel={this.hideModalConfirmResendTicket}
                        cancelText={'Hủy'}
                        okText={'Đồng ý'}
                        isLoading={isLoadingConfirmResendTicket}
                    />
                )}
            </div>
        );
    };
    //đơn chưa hoàn tất
    getEditablePanel = () => {
        const { t } = this.props;
        const { assigneeID, selectedPayment, isShowModalAddProduct } = this.state;
        const order = this.props.order.order || {};
        const { orderCustomer, note, shippingAddress, billingAddress, currentCustomerInfo, tags, userInfo } = this.state;

        const {
            total,
            subTotal,
            selectDiscount,
            shipping_fee,
            variants,
            searchVariant,
            discountFee,
            selectShipping,
            applyShipping,
            customVariant,
            disabledApplyDiscount,
            applyDiscount,
            emailNotification,
        } = this.state.editableOrder;

        const allTags = this.props.orderTag.allTags || [];

        const foundVariants = this.props.variant.variants || [];
        const isLoadingUpdateOrder = this.props.order.loading && includes([orderTypes.UPDATE_ORDER], this.props.order.waiting);
        const isLoadingAssign = this.props.order.loading && this.props.order.waiting == orderTypes.ASSIGN_ORDER;
        const isLoadingPrint = this.props.printReducer.loading && printTypes.PRINT_ORDER == this.props.printReducer.waiting;
        const isLoadingValidateDiscountCode = this.props.discount.loading && discountTypes.VALIDATE_DISCOUNT == this.props.discount.waiting;
        const isLoadingSearchVariant = this.props.variant.loading && this.props.variant.waiting == variantTypes.SEARCH_VARIANT;
        const isLoadingUpdateSpam = this.props.order.loading && orderTypes.UPDATE_SPAMS == this.props.order.waiting;
        const isLoadingGenerateCheckout = this.props.order.loading && orderTypes.GENERATE_CHECKOUT_ORDER == this.props.order.waiting;

        const isActivePercent = selectDiscount.type == appConfig.DISCOUNT.TYPE.PERCENT.value;
        const currencyCode = userInfo.currentStore.currency_code;
        const currencySymbol = userInfo.currentStore.currency_symbol;

        const isLoadingUpdateAddress = this.props.order.loading && includes([orderTypes.UPDATE_ORDER_ADDRESS], this.props.order.waiting);
        const isLoadingCancel = this.props.order.loading && orderTypes.CANCEL_ORDER == this.props.order.waiting;

        let checkoutLink;

        if (order.is_generate_checkout && order.checkout_token) {
            checkoutLink = `${baseHelper.domainToUrl(this.props.storeReducer.userInfo.default_domain)}/checkout/${order.checkout_token}`;
            if (order.source == 'LADISALES' && order.url_page != '') {
                checkoutLink = `${baseHelper.getDomainName(order.url_page)}/checkout/${order.checkout_token}`;
            }
        }

        const listOptionDiscount = {
            PERCENT: {
                CODE: appConfig.DISCOUNT.TYPE.PERCENT.value,
                NAME: '%',
            },
            FIXED: {
                CODE: appConfig.DISCOUNT.TYPE.FIXED.value,
                NAME: currencyCode,
            },
        };

        return (
            <div className='page-content'>
                {(isLoadingValidateDiscountCode || isLoadingPrint || isLoadingAssign || isLoadingUpdateSpam) && (
                    <LoadingSence blur={true} />
                )}

                <div className='ladiui content-main-title flex-row'>
                    <div className='ladiui flex-row'>
                        <div className='ladiui main-title mr-12'>{`${t('MENU.ORDERS')} #${baseHelper.getText(order.reference_no)}`}</div>

                        <span className={`order-status mr-24`}>{this.getStatusName(order.status)}</span>
                        {order.mark_spam == appConfig.STATUS.ACTIVE && <span className='order-status mr-24'>{t('ORDERS.SPAM')}</span>}

                        <span className='placed-on flex mr-24 cursor-pointer'>
                            <img
                                src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-date.svg'}
                                alt=' '
                                style={{ marginRight: '4px' }}
                            />
                            <span>{baseHelper.formatStrToDate(order.created_at)}</span>
                        </span>

                        <div className='item-info mr-24'>
                            {assigneeID ? (
                                <SelectOption
                                    wrapperClassName='select-assignee'
                                    innerClassName='ellipsis'
                                    currentKey={assigneeID}
                                    _key='ladi_uid'
                                    _value='name'
                                    items={userInfo.staffs || []}
                                    onSelectItem={(item) => {
                                        this.setState(
                                            {
                                                assigneeID: item.ladi_uid,
                                            },
                                            () => {
                                                this.showModalConfirmAssignToOther();
                                            }
                                        );
                                    }}
                                />
                            ) : (
                                <button
                                    className='ladiui btn btn-outline-primary btn-sm'
                                    onClick={() => this.props.assignOrder(order.order_id)}
                                >
                                    {' '}
                                    {t('ORDERS.ASSIGN')}
                                </button>
                            )}
                        </div>

                        <span className='placed-on flex mr-24 cursor-pointer' onClick={this.print}>
                            <img
                                src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-print-order.svg'}
                                alt=' '
                                style={{ marginRight: '4px' }}
                            />
                            <span>{t('ORDERS.PRINT_ORDER')}</span>
                        </span>

                        {order.is_draft == appConfig.STATUS.ACTIVE &&
                            order.is_generate_checkout != appConfig.STATUS.ACTIVE &&
                            order.status != appConfig.ORDER_STATUS.CANCELED.CODE && (
                                <span className='placed-on cursor-pointer flex mr-12' onClick={() => this.generateCheckout()}>
                                    <img
                                        src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-getlinkurl.svg'}
                                        alt=' '
                                        style={{ marginRight: '4px' }}
                                    />
                                    <span>{t('ORDERS.GET_LINK_CHECKOUT_CREATE')}</span>
                                </span>
                            )}
                    </div>

                    <div className='flex'>
                        <div className='ladiui btn-group mr-12'>
                            <div className='ladiui dropdown hide-mt ba-c btn-action-bc'>
                                <i
                                    className='ladi-icon icon-dots ladiui dropdown-toggle select-dropdown pd-5 edit-info-affiliate ml-3'
                                    data-toggle='dropdown'
                                ></i>
                                <ul className='ladiui dropdown-menu r-0'>
                                    <li>
                                        <a className='ladiui dropdown-item' onClick={this.openModalCancel}>
                                            {t('ACTIONS.CANCEL')}
                                        </a>
                                    </li>
                                    <li>
                                        <a className='ladiui dropdown-item' onClick={this.openModalConfirmCancel}>
                                            {t('ACTIONS.REORDER')}
                                        </a>
                                    </li>
                                    {order.mark_spam == appConfig.STATUS.ACTIVE && (
                                        <li>
                                            <a
                                                className='ladiui dropdown-item'
                                                onClick={() =>
                                                    this.props.updateSpams({
                                                        order_ids: [order.order_id],
                                                        mark_spam: appConfig.STATUS.INACTIVE,
                                                    })
                                                }
                                            >
                                                {t('ORDERS.REMOVE_MARK_SPAM')}
                                            </a>
                                        </li>
                                    )}
                                    {order.mark_spam == appConfig.STATUS.INACTIVE && (
                                        <li>
                                            <a
                                                className='ladiui dropdown-item'
                                                onClick={() =>
                                                    this.props.updateSpams({
                                                        order_ids: [order.order_id],
                                                        mark_spam: appConfig.STATUS.ACTIVE,
                                                    })
                                                }
                                            >
                                                {t('ORDERS.MARK_SPAM')}
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>

                        <button
                            className={`ladiui btn btn-primary ${isLoadingUpdateOrder ? 'loader' : ''}`}
                            disabled={isLoadingUpdateOrder}
                            onClick={() => this.fullSubmit()}
                        >
                            <img
                                src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-save-data.svg'}
                                alt=''
                                style={{ marginRight: '8px' }}
                            />

                            {t('ORDERS.SAVE')}
                        </button>
                    </div>
                </div>

                <div className='flex order-custom-edit' style={{ marginBottom: '24px', marginTop: '16px' }}>
                    <img className='mr-8' src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-tag.svg'} alt='' />
                    <DropdownTag
                        ref={this.tagRef}
                        innerClassName='dropdown-tag-color-order'
                        allTags={allTags}
                        selectedTags={tags}
                        updateTag={this.updateTag}
                        openModalAddTag={this.openModalAddTag}
                    />
                </div>

                <div>
                    <div className='ladiui-order-left'>
                        <div className='ladi-card order-products' style={{ marginBottom: 20 }}>
                            <div>
                                {variants.map((item, index) => {
                                    return (
                                        <div className={`item-variant ${index == 0 ? 'border-none' : ''}`} key={index}>
                                            <div className='image-product image-product-variant mr-16'>
                                                <Image src={item.src} width={82} height={82} />
                                            </div>
                                            <div className='w-name-product'>
                                                <div className='item-variant-name-info'>
                                                    {item.product_id ? (
                                                        <a className='item-title' href={baseHelper.getUrlVariant(item)}>
                                                            {item.name}
                                                        </a>
                                                    ) : (
                                                        <span className='item-title'>{item.name}</span>
                                                    )}

                                                    <div className='item-variant-list-option'>
                                                        {item.options &&
                                                            item.options.map((option, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <span className='option-name'>{option.option_name}: </span>
                                                                        <span className='option-value'>{option.option_value_value}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-200 flex'>
                                                {item.discount && (
                                                    <span className='price-before-discount'>
                                                        {baseHelper.formatMoneyPostFix(
                                                            item.price,
                                                            this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                        )}
                                                    </span>
                                                )}
                                                <PopoverDiscount
                                                    discount={item.discount}
                                                    apply={(discount) => this.applyVariantDiscount(index, discount)}
                                                    remove={() => this.removeVariantDiscount(index)}
                                                >
                                                    <a style={{ color: 'var(--main-default)' }} className='ladiui-custom-popup-init'>
                                                        {baseHelper.formatMoneyPostFix(
                                                            this.getLastPrice(item),
                                                            this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                        )}
                                                    </a>
                                                </PopoverDiscount>
                                            </div>
                                            <div className='w-120 item-variant-price'>
                                                <button
                                                    className='ladiui btn btn-sm'
                                                    onClick={() => this.changeQuantityVariant(-1, item.max_buy, index)}
                                                >
                                                    -
                                                </button>
                                                <Input
                                                    id={`quantity-${item.variant_id}`}
                                                    type='number'
                                                    className='item-variant-price-input'
                                                    min={1}
                                                    value={item.quantity}
                                                    onChange={(event) => this.onChangeQuantity(event, item.max_buy, index)}
                                                    onBlur={() => this.validateDiscount()}
                                                    style={{ textAlign: 'center' }}
                                                />
                                                <button
                                                    className='ladiui btn btn-sm'
                                                    onClick={() => this.changeQuantityVariant(1, item.max_buy, index)}
                                                >
                                                    +
                                                </button>
                                            </div>
                                            <div className='w-190 text-end item-title'>
                                                {baseHelper.formatMoneyPostFix(
                                                    item.total,
                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className='center-btn mb-20'>
                                <div className='ladiui border-none flex cursor-pointer' onClick={() => this.setShowHideAddProduct(true)}>
                                    <img className='mr-8' src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg'} alt='' />
                                    <div className='btn-add-product'>{t('PRODUCTS.ADD_NEW')}</div>
                                </div>
                            </div>

                            <div className='footer-order'>
                                <div className='w-49-p'>
                                    {order.is_generate_checkout == appConfig.STATUS.ACTIVE && order.checkout_token && (
                                        <div className='payment-shipping mb-16'>
                                            <div className='title-lable mb-8'>{t('ORDERS.LINK_CHECKOUT')}</div>
                                            <div className='url-checkout'>
                                                <SelectOption
                                                    wrapperClassName='select-checkout-config'
                                                    innerClassName='ellipsis'
                                                    currentKey={this.state.selectedCheckoutConfig.checkout_config_id}
                                                    _key='checkout_config_id'
                                                    _value='name'
                                                    items={this.props.checkoutConfigReducer.checkout_configs || []}
                                                    onSelectItem={(item) => {
                                                        this.setState(
                                                            {
                                                                selectedCheckoutConfig: item,
                                                            },
                                                            () => {
                                                                baseHelper.copyToClipboard(
                                                                    `${checkoutLink}?checkout_config_id=${this.state.selectedCheckoutConfig.checkout_config_id}`
                                                                );
                                                                window.LadiUI.toastCustom('success', '', t('COMMON.COPIED'));
                                                            }
                                                        );
                                                    }}
                                                    innerStyle={{
                                                        width: '30%',
                                                        marginRight: '0px !important',
                                                    }}
                                                />
                                                <div className='title-payment'>
                                                    <div
                                                        className='link flex'
                                                        data-tooltip={`${checkoutLink}?checkout_config_id=${this.state.selectedCheckoutConfig.checkout_config_id}`}
                                                        data-tooltip-position='top'
                                                    >
                                                        <a
                                                            className='link-checkout'
                                                            href={`${checkoutLink}?checkout_config_id=${this.state.selectedCheckoutConfig.checkout_config_id}`}
                                                        >
                                                            {`${checkoutLink}?checkout_config_id=${this.state.selectedCheckoutConfig.checkout_config_id}`}
                                                        </a>
                                                        <span
                                                            onClick={() => {
                                                                baseHelper.copyToClipboard(
                                                                    `${checkoutLink}?checkout_config_id=${this.state.selectedCheckoutConfig.checkout_config_id}`
                                                                );
                                                                window.LadiUI.toastCustom('success', '', this.props.t('COMMON.COPIED'));
                                                            }}
                                                            className='cursor-pointer btn-coppy'
                                                        >
                                                            <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-coppy.svg' alt='' />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className='block-note mb-24'>
                                        <div className='title-lable mb-8'>{t('ORDERS.NOTE')}</div>
                                        <textarea
                                            className='ladiui textarea'
                                            value={note}
                                            name='note'
                                            onChange={(event) => {
                                                this.setState({
                                                    note: event.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className='ladiui toggle-dropdown-product-edit block-custom-field'>
                                        <h3
                                            className={`ladiui title-block pointer relative mb-24 ${
                                                this.state.isShowBlockCustomField ? 'arrow-up' : 'arrow-down'
                                            }`}
                                            onClick={() =>
                                                this.setState({
                                                    isShowBlockCustomField: !this.state.isShowBlockCustomField,
                                                })
                                            }
                                        >
                                            {t('PRODUCTS.CUSTOM_FIELD')}
                                        </h3>
                                        <div style={this.state.isShowBlockCustomField ? { display: 'block' } : { display: 'none' }}>
                                            <PanelCustomField
                                                ref={this.panelCustomFieldRef}
                                                customFields={order.custom_fields}
                                                groupType={appConfig.CUSTOM_FIELD.GROUP_TYPES.ORDER.CODE}
                                                actionType={appConfig.FORM_MODE.EDIT}
                                                orderID={order.order_id}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='w-49-p'>
                                    <div className='item-info'>
                                        <div className='flex'>
                                            <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-dollar.svg' />
                                            <span className='footer-lable'>{t('ORDERS.TOTAL_AMOUNT')}</span>
                                        </div>
                                        <div>
                                            <span>
                                                {baseHelper.formatMoneyPostFix(
                                                    subTotal,
                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='item-info'>
                                        <div className='flex'>
                                            <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-discount.svg' />
                                            <span className='footer-lable'>{t('ORDERS.DISCOUNT')}</span>
                                            <Popover
                                                wrapperProps={{ className: 'discount-option' }}
                                                wrapperPopoverProps={{
                                                    className: 'text-left discount-box discount-popup',
                                                }}
                                                ref={this.popoverDiscountRef}
                                                content={
                                                    <div>
                                                        <div className='ladiui-input-group'>
                                                            {selectDiscount.type == appConfig.DISCOUNT.TYPE.FIXED.value && (
                                                                <NumberInput
                                                                    className='pl-10'
                                                                    prefix={currencySymbol}
                                                                    value={selectDiscount.value}
                                                                    onChange={this.onChangeDiscountValue}
                                                                    isDiscountType={appConfig.DISCOUNT.TYPE.FIXED.value}
                                                                />
                                                            )}
                                                            {selectDiscount.type == appConfig.DISCOUNT.TYPE.PERCENT.value && (
                                                                <NumberInput
                                                                    prefix='%'
                                                                    className='pl-10'
                                                                    min={0}
                                                                    max={100}
                                                                    value={selectDiscount.value}
                                                                    onChange={(event) => this.onChangeDiscountValue(event)}
                                                                    isDiscountType={appConfig.DISCOUNT.TYPE.PERCENT.value}
                                                                />
                                                            )}

                                                            <SelectOption
                                                                customStyle={{
                                                                    width: '65px',
                                                                    height: '22px',
                                                                    position: 'absolute',
                                                                    top: '6px',
                                                                    right: '10px',
                                                                }}
                                                                directionUp={true}
                                                                wrapperClassName='mini-dropdown fix'
                                                                innerClassName='select-weight mini-dropdown mini-dropdown-pd dropdown-discount-type'
                                                                currentKey={selectDiscount.type}
                                                                items={listOptionDiscount}
                                                                _key='CODE'
                                                                _value='NAME'
                                                                onSelectItem={(item) => {
                                                                    this.setState({
                                                                        editableOrder: {
                                                                            ...this.state.editableOrder,
                                                                            selectDiscount: {
                                                                                ...this.state.editableOrder.selectDiscount,
                                                                                type: item.CODE,
                                                                            },
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                        </div>

                                                        <div className='discount-reason'>
                                                            <Input
                                                                type='text'
                                                                name='note'
                                                                value={selectDiscount.note}
                                                                onChange={this.onChangeDiscountInput}
                                                                placeholder={t('COMMON.REASON')}
                                                            />
                                                        </div>

                                                        <div className='discount-code'>
                                                            <Input
                                                                type='text'
                                                                name='code'
                                                                value={selectDiscount.code}
                                                                onChange={this.onChangeDiscountInput}
                                                                placeholder={t('DISCOUNTS.OR_DISCOUNT_CODE')}
                                                            />
                                                        </div>

                                                        <div className='flex-end'>
                                                            <button
                                                                className='ladiui btn btn-secondary btn-sm  mr-24'
                                                                onClick={this.removeDiscount}
                                                            >
                                                                {' '}
                                                                {t('ACTIONS.REMOVE')}
                                                            </button>
                                                            <button
                                                                className={`ladiui btn btn-primary btn-sm  ${
                                                                    disabledApplyDiscount ? 'disabled' : ''
                                                                }`}
                                                                disabled={disabledApplyDiscount}
                                                                onClick={this.applyDiscount}
                                                            >
                                                                {' '}
                                                                {t('ACTIONS.APPLY')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <span>
                                                    &nbsp;{`(`}
                                                    <a className='ladiui-custom-popup-init'>{`${t('ORDERS.ADD_DISCOUNT')}`}</a>
                                                    {`)`}&nbsp;
                                                </span>
                                            </Popover>
                                            {applyDiscount.note || applyDiscount.code
                                                ? ' - ' + applyDiscount.note || applyDiscount.code
                                                : ''}
                                        </div>
                                        <div>
                                            <span>
                                                {baseHelper.formatMoneyPostFix(
                                                    discountFee,
                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='item-info'>
                                        <div className='flex'>
                                            <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-delivery.svg' />
                                            <span className='footer-lable'>{t('ORDERS.SHIPPING')}</span>
                                            <Popover
                                                ref={this.popoverShippingRef}
                                                wrapperProps={{ className: 'shipping-option' }}
                                                wrapperPopoverProps={{
                                                    className: 'shipping-box text-left ladiui-custom-popup-content shipping-popup',
                                                }}
                                                content={
                                                    <div>
                                                        <div className='shipping-group'>
                                                            {map(this.props.shippingRate.shipping_rates, (item, index) => (
                                                                <div key={index} className='flex mb-10'>
                                                                    <input
                                                                        id={`shipping-rate-service-${index}`}
                                                                        className='ladiui form-check-input'
                                                                        type='radio'
                                                                        name='radio-group'
                                                                        value={appConfig.SHIPPING_TYPE.AVAILABLE}
                                                                        onChange={() =>
                                                                            this.onChangeShippingType(
                                                                                appConfig.SHIPPING_TYPE.AVAILABLE,
                                                                                item
                                                                            )
                                                                        }
                                                                        checked={
                                                                            selectShipping.type == appConfig.SHIPPING_TYPE.AVAILABLE &&
                                                                            item.shipping_rate_detail_id == selectShipping.id
                                                                        }
                                                                    />
                                                                    <label htmlFor={`shipping-rate-service-${index}`}>{item.name}</label>
                                                                </div>
                                                            ))}

                                                            <div className='flex mb-10'>
                                                                <input
                                                                    id='radioShippingFree'
                                                                    type='radio'
                                                                    className='ladiui form-check-input'
                                                                    name='radio-group'
                                                                    value={appConfig.SHIPPING_TYPE.FREE}
                                                                    onChange={() => this.onChangeShippingType(appConfig.SHIPPING_TYPE.FREE)}
                                                                    checked={selectShipping.type == appConfig.SHIPPING_TYPE.FREE}
                                                                />
                                                                <label htmlFor='radioShippingFree'>{t('ORDERS.FREE_SHIPPING')}</label>
                                                            </div>
                                                            <div className='flex mb-10'>
                                                                <input
                                                                    id='radioShippingCustom'
                                                                    type='radio'
                                                                    className='ladiui form-check-input'
                                                                    name='radio-group'
                                                                    value={appConfig.SHIPPING_TYPE.CUSTOM}
                                                                    onChange={() =>
                                                                        this.onChangeShippingType(appConfig.SHIPPING_TYPE.CUSTOM)
                                                                    }
                                                                    checked={selectShipping.type == appConfig.SHIPPING_TYPE.CUSTOM}
                                                                />
                                                                <label htmlFor='radioShippingCustom'>{t('ORDERS.CUSTOM')}</label>
                                                            </div>
                                                        </div>
                                                        {selectShipping.type == appConfig.SHIPPING_TYPE.CUSTOM && (
                                                            <div className='shipping-group'>
                                                                <div className='shipping-item'>
                                                                    <Input
                                                                        type='text'
                                                                        name='note'
                                                                        value={selectShipping.note}
                                                                        onChange={this.onChangeShippingNote}
                                                                        placeholder={t('ORDERS.SHIPPING_RATE_NAME')}
                                                                    />
                                                                </div>
                                                                <div className='shipping-item'>
                                                                    <NumberInput
                                                                        prefix={currencySymbol}
                                                                        value={selectShipping.value}
                                                                        onChange={this.onChangeShippingValue}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                        <div className='flex-end'>
                                                            <button
                                                                className='ladiui btn btn-secondary btn-sm  mr-24'
                                                                data-dissmiss='shipping-popup'
                                                                onClick={this.clearShippingRate}
                                                            >
                                                                {' '}
                                                                {t('ACTIONS.REMOVE')}
                                                            </button>
                                                            <button
                                                                className='ladiui btn btn-primary btn-sm '
                                                                data-dissmiss='shipping-popup'
                                                                onClick={this.applyShipping}
                                                            >
                                                                {' '}
                                                                {t('ACTIONS.APPLY')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <span>
                                                    &nbsp;{`(`}
                                                    <a className='ladiui-custom-popup-init'>{t('ORDERS.ADD_SHIPPING')}</a>
                                                    {`)`}
                                                </span>
                                            </Popover>
                                        </div>
                                        <div>
                                            <span>
                                                {baseHelper.formatMoneyPostFix(
                                                    shipping_fee,
                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </span>
                                        </div>
                                    </div>

                                    {order.discount_shipping_fee != undefined && order.discount_shipping_fee > 0 && (
                                        <div className='item-info'>
                                            <div className='flex'>
                                                <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-delivery.svg'} alt='' />
                                                <span className='footer-lable'>{t('ORDERS.DISCOUNT_SHIPPING')}&nbsp;</span>
                                                <span>{order.discount_shipping_code ? ' - ' + order.discount_shipping_code : ''}</span>
                                            </div>
                                            <div>
                                                <span>
                                                    {' - '}
                                                    {baseHelper.formatMoneyPostFix(
                                                        order.discount_shipping_fee,
                                                        this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    )}

                                    <div className='item-info'>
                                        <div className='flex'>
                                            <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-dollar.svg' />
                                            <span className='footer-lable'>{t('ORDERS.TOTAL_ORDER')}</span>
                                        </div>
                                        <div>
                                            <span>
                                                {baseHelper.formatMoneyPostFix(
                                                    total,
                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='item-info'>
                                        <div className='flex'>
                                            <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-payment.svg'} alt='' />
                                            <span className='footer-lable'>{t('ORDERS.PAYMENT_ACTION')}</span>
                                        </div>
                                        <div className='flex'>
                                            <button
                                                className='ladiui btn btn-primary btn-sm mr-12'
                                                onClick={() => this.openModalConfirmCreateOrder(appConfig.PAYMENT_STATUS.PENDING.CODE)}
                                            >
                                                {' '}
                                                {t('ORDERS.MARK_AS_PENDING')}
                                            </button>
                                            <button
                                                className='ladiui btn btn-success btn-sm'
                                                onClick={() => this.openModalConfirmCreateOrder(appConfig.PAYMENT_STATUS.SUCCESS.CODE)}
                                            >
                                                {' '}
                                                {t('ORDERS.MARK_AS_PAID')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='order-timeline title-16'>{t('COMMON.ACTIVE')}</div>
                        <History type={appConfig.HISTORY_FORM.ORDER} orderID={this.props.match.params['orderID']} />
                    </div>

                    <div className='ladiui-order-right block-customer-info-right'>
                        <div className='info-customer mb-24'>
                            <div className='custom-info fix'>
                                <PanelCustomer
                                    mode={appConfig.FORM_MODE.CREATE}
                                    selectedCustomer={orderCustomer}
                                    onSelectCustomer={(orderCustomer) => {
                                        this.setState(
                                            {
                                                orderCustomer,
                                                billingAddress: orderCustomer.address_default,
                                                shippingAddress: orderCustomer.address_default,
                                            },
                                            () => this.validateDiscount()
                                        );
                                    }}
                                    confirmRemove={true}
                                />
                            </div>
                            <div className='block-shipping-address'>
                                <PanelShippingAddress
                                    ref={this.panelShippingAddressRef}
                                    mode={appConfig.FORM_MODE.EDIT}
                                    shippingAddress={shippingAddress}
                                    addresses={currentCustomerInfo.addresses}
                                    onChangeAddress={this.onChangeAddress}
                                    isLoading={isLoadingUpdateAddress}
                                    classNameCustom='pb-24'
                                />

                                <PanelBillingAddress
                                    ref={this.panelBillingAddressRef}
                                    mode={appConfig.FORM_MODE.EDIT}
                                    billingAddress={billingAddress}
                                    addresses={currentCustomerInfo.addresses}
                                    onChangeAddress={this.onChangeAddress}
                                    isLoading={isLoadingUpdateAddress}
                                    currentShippingAddress={shippingAddress}
                                    // classNameCustom="pb-24"
                                />
                            </div>
                        </div>

                        <div
                            id='order-custom-field'
                            className='order-custom-field box-shadow-none set-max-width custom bottom mb-0'
                            style={{ border: '1px solid #f1f3f4' }}
                        >
                            <div className='title'>
                                <label className='ladiui-label'>URL</label>
                                <div className='hidden-custom-field'>
                                    <a
                                        href='#'
                                        onClick={() => {
                                            this.setState({
                                                isShowBlockUrl: !this.state.isShowBlockUrl,
                                            });
                                        }}
                                    >
                                        <i className='ladi-icon icon-down-arrow'></i>
                                    </a>
                                </div>
                            </div>
                            {this.state.isShowBlockUrl && this.getMoreInfo()}
                        </div>
                    </div>
                </div>

                {this.state.editableOrder.isShowModalNewProduct && (
                    <Modal
                        id='modal-new-product'
                        title={t('PRODUCTS.ADD_PRODUCT')}
                        visible={this.state.editableOrder.isShowModalNewProduct}
                        onCancel={this.hideModalNewProduct}
                        onOk={this.createNewProduct}
                        width={700}
                    >
                        <div className='info'>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('PRODUCTS.NAME')}</label>
                                <Input
                                    name='name'
                                    onChange={this.onChangeCustomVariant}
                                    value={customVariant.name}
                                    validationName={t('PRODUCTS.NAME')}
                                    validations={{ isRequired: true }}
                                    ref={(ref) => this.formProductCreateRef.add(ref)}
                                />
                            </div>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('PRODUCTS.PRICE')}</label>

                                <Input
                                    name='price'
                                    type='number'
                                    currency={true}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/[, ]/g, '')}
                                    onChange={this.onChangeCustomVariant}
                                    value={customVariant.price}
                                    validationName={t('PRODUCTS.PRICE')}
                                    validations={{ isRequired: true }}
                                    ref={(ref) => this.formProductCreateRef.add(ref)}
                                />
                            </div>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('COMMON.QUANTITY')}</label>

                                <Input
                                    name='quantity'
                                    type='number'
                                    onChange={this.onChangeCustomVariant}
                                    value={customVariant.quantity}
                                    validationName={t('COMMON.QUANTITY')}
                                    validations={{ isRequired: true }}
                                    ref={(ref) => this.formProductCreateRef.add(ref)}
                                />
                            </div>
                        </div>
                    </Modal>
                )}

                {this.state.isShowModalCancel && (
                    <ModalCancel
                        visible={this.state.isShowModalCancel}
                        onOk={this.cancelOrder}
                        onCancel={this.hideModalCancel}
                        isLoading={isLoadingCancel}
                    />
                )}

                {this.state.isShowModalConfirmCancel && (
                    <ModalConfirmCancel
                        visible={this.state.isShowModalConfirmCancel}
                        onOk={this.confirmOrderCancel}
                        onCancel={this.hideModalConfirmCancel}
                        isLoading={isLoadingCancel}
                    />
                )}

                {this.state.editableOrder.isShowModalConfirmCreate && (
                    <Modal
                        id='modal-confirm-payment-order'
                        title={
                            selectedPayment.status === appConfig.PAYMENT_STATUS.PENDING.CODE
                                ? t('ORDERS.MSG_PAYMENT_PENDING_TITLE')
                                : t('ORDERS.MSG_PAYMENT_SUCCESS_TITLE')
                        }
                        visible={this.state.editableOrder.isShowModalConfirmCreate}
                        isCustomFooter={true}
                        onCancel={() => {
                            this.setState({
                                editableOrder: {
                                    ...this.state.editableOrder,
                                    isShowModalConfirmCreate: false,
                                },
                            });
                        }}
                        width={600}
                        bodyClassName='unset-modal'
                        footer={
                            <div className='ladiui-modal-footer'>
                                <div className='send-email flex'>
                                    <input
                                        id='emailNotification'
                                        checked={emailNotification}
                                        type='checkbox'
                                        onChange={() => {
                                            this.setState({
                                                editableOrder: {
                                                    ...this.state.editableOrder,
                                                    emailNotification: !this.state.editableOrder.emailNotification,
                                                },
                                            });
                                        }}
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                    />
                                    <label className='ladiui label'> {t('COMMON.EMAIL_NOTIFICATION')}</label>
                                </div>
                                <div className='flex'>
                                    <button
                                        onClick={() => {
                                            this.setState({
                                                editableOrder: {
                                                    ...this.state.editableOrder,
                                                    isShowModalConfirmCreate: false,
                                                },
                                            });
                                        }}
                                        className='ladiui btn btn-secondary'
                                    >
                                        {t('ACTIONS.CLOSE')}
                                    </button>
                                    <button
                                        onClick={() => this.fullSubmit(true)}
                                        className={`ladiui btn btn-primary ${isLoadingUpdateOrder ? 'loader' : ''}`}
                                        disabled={isLoadingUpdateOrder}
                                    >
                                        {' '}
                                        {t('ACTIONS.SAVE')}
                                    </button>
                                </div>
                            </div>
                        }
                    >
                        <p>
                            <strong>
                                {selectedPayment.status === appConfig.PAYMENT_STATUS.PENDING.CODE
                                    ? t('ORDERS.MSG_PAYMENT_PENDING_CONFIRM')
                                    : t('ORDERS.MSG_PAYMENT_SUCCESS_CONFIRM')}
                            </strong>
                        </p>
                        <p>
                            <span>
                                {selectedPayment.status === appConfig.PAYMENT_STATUS.PENDING.CODE
                                    ? t('ORDERS.MSG_PAYMENT_PENDING_CONTENT1')
                                    : t('ORDERS.MSG_PAYMENT_SUCCESS_CONTENT1')}
                            </span>
                        </p>
                        <p>
                            <span>
                                {selectedPayment.status === appConfig.PAYMENT_STATUS.PENDING.CODE
                                    ? t(
                                          'ORDERS.MSG_PAYMENT_PENDING_CONTENT2',
                                          baseHelper.formatMoneyPostFix(
                                              total,
                                              this.props.storeReducer.userInfo.currentStore.currency_symbol
                                          )
                                      )
                                    : t(
                                          'ORDERS.MSG_PAYMENT_SUCCESS_CONTENT2',
                                          baseHelper.formatMoneyPostFix(
                                              total,
                                              this.props.storeReducer.userInfo.currentStore.currency_symbol
                                          )
                                      )}
                            </span>
                        </p>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>{t('PAYMENTS.LB_SELECT_PAYMENT')}</label>
                            <Dropdown
                                data={this.props.paymentReducer.gateways}
                                onSelectItem={(item) => {
                                    this.setState({
                                        selectedPayment: {
                                            ...this.state.selectedPayment,
                                            method: item.code,
                                        },
                                        listPaymentTypes: item.config.active_payment_methods,
                                    });
                                }}
                                currentKey={selectedPayment.method}
                                _key={'code'}
                                _value={'name'}
                                validationName={t('PAYMENTS.LB_PAYMENT_VALIDATION')}
                                placeHolder={t('PAYMENTS.LB_SELECT_PAYMENT')}
                                ref={(ref) => (this.paymentRef = ref)}
                                validations={{ isRequired: true }}
                            />
                        </div>
                        {!baseHelper.isEmpty(this.state.listPaymentTypes) && (
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('PAYMENTS.LB_SELECT_PAYMENT_TYPE')}</label>
                                <Dropdown
                                    data={this.getDataPaymentTypes(this.state.listPaymentTypes)}
                                    onSelectItem={(item) => {
                                        this.setState({
                                            selectedPayment: {
                                                ...this.state.selectedPayment,
                                                type: item.code,
                                            },
                                        });
                                    }}
                                    currentKey={selectedPayment.type}
                                    _key={'code'}
                                    _value={'name'}
                                    validationName={t('PAYMENTS.LB_PAYMENT_VALIDATION')}
                                    placeHolder={t('PAYMENTS.CHOOSE_PAYMENT_TYPE')}
                                    ref={(ref) => (this.paymentRef = ref)}
                                    validations={{ isRequired: true }}
                                />
                            </div>
                        )}

                        {userInfo.currentStore.store_id && userInfo.currentStore.store_id == '3319' && (
                            <>
                                {selectedPayment.status == appConfig.PAYMENT_STATUS.SUCCESS.CODE ||
                                (selectedPayment.status == appConfig.PAYMENT_STATUS.PENDING.CODE &&
                                    selectedPayment.method == appConfig.PAYMENT_PARTNERS.COD.CODE) ? (
                                    <div className='ladiui form-group'>
                                        <div className=' flex flex-start'>
                                            <input
                                                type='checkbox'
                                                className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                                name='is_auto_shipping'
                                                onChange={(event) =>
                                                    this.setState({
                                                        editableOrder: {
                                                            ...this.state.editableOrder,
                                                            is_auto_shipping: !this.state.editableOrder.is_auto_shipping,
                                                        },
                                                    })
                                                }
                                                checked={this.state.editableOrder.is_auto_shipping}
                                            />{' '}
                                            Tự động tạo đơn vận chuyển
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </>
                        )}
                    </Modal>
                )}
                {this.getAssignToOtherModal()}

                {isShowModalAddProduct && (
                    <ModalAddProduct
                        t={t}
                        onClose={this.setShowHideAddProduct}
                        isShow={isShowModalAddProduct}
                        listVariant={foundVariants}
                        isSelectedTypeProduct={false}
                        currency_symbol={this.props.storeReducer.userInfo.currentStore.currency_symbol}
                        openModalNewProduct={this.openModalNewProduct}
                        selectVariant={this.selectVariant}
                        selectedProducts={variants || []}
                        onChangeSearchVariantInput={this.onChangeSearchVariantInput}
                    />
                )}
                {this.state.isShowModalAddTag && (
                    <Modal
                        id='modal-tag-edit'
                        title={t('TAGS.ADD_TAG')}
                        visible={this.state.isShowModalAddTag}
                        onCancel={() =>
                            this.setState({
                                isShowModalAddTag: false,
                            })
                        }
                        // onOk={this.handleCreateTag}
                        width={400}
                        bodyStyles={{ minHeight: '25vh' }}
                        isCustomFooter={true}
                        footer={
                            <div className='ladiui-modal-footer'>
                                <div className='ladiui page-tags'>
                                    <a className='flex' target='_blank' href='/settings#TAG'>
                                        <i className='icon-tag-footer-modal mr-8'></i>
                                        <span>Quản lý Tag</span>
                                    </a>
                                </div>
                                <div className='flex'>
                                    <button
                                        onClick={() =>
                                            this.setState({
                                                isShowModalAddTag: false,
                                            })
                                        }
                                        className='ladiui btn btn-secondary mr-12'
                                    >
                                        {t('ACTIONS.CLOSE')}
                                    </button>
                                    <button onClick={this.handleCreateTag} className='ladiui btn btn-primary'>
                                        {t('ACTIONS.SAVE')}
                                    </button>
                                </div>
                            </div>
                        }
                    >
                        <div className='ladiui form-group'>
                            <label className='ladiui-label' style={{ marginBottom: '8px' }}>
                                {t('TAGS.COL_TAG')}
                            </label>
                            <Input
                                value={this.state.tagSelected.name}
                                onChange={(event) => {
                                    this.setState({
                                        tagSelected: {
                                            ...this.state.tagSelected,
                                            name: event.target.value,
                                        },
                                    });
                                }}
                                validationName={t('TAGS.COL_TAG')}
                                validations={{ isRequired: true }}
                                ref={(ref) => this.inputsRef.add(ref)}
                            />
                        </div>
                        {this.state.typeSelected == appConfig.TAG_SELECTED.TAG_ORDER.CODE &&
                            this.state.tagSelected.color &&
                            this.state.tagSelected.name && (
                                <span
                                    className='order-tag-item'
                                    style={{ color: 'var(--main-default)', backgroundColor: this.state.tagSelected.color }}
                                >
                                    {this.state.tagSelected.name}
                                </span>
                            )}

                        {this.state.typeSelected == appConfig.TAG_SELECTED.TAG_ORDER.CODE && (
                            <div className='form-default-color'>
                                <label className='ladiui-label'>{t('TAGS.PICK_COLOR')}</label>
                                <div className='list-pick-color-tag-customer'>
                                    {map(appConfig.TAG_COLORS, (item) => (
                                        <span
                                            key={item}
                                            className='item'
                                            style={{ backgroundColor: item }}
                                            onClick={() => {
                                                this.setState({
                                                    tagSelected: {
                                                        ...this.state.tagSelected,
                                                        color: item,
                                                    },
                                                });
                                            }}
                                        >
                                            {item == this.state.tagSelected.color && <i className='ladi-icon icon-d-check' />}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Modal>
                )}
            </div>
        );
    };

    render() {
        const { t } = this.props;
        const isLoadingOrder = this.props.order.loading && orderTypes.SHOW_ORDER == this.props.order.waiting;
        const { isShowModalAddProduct } = this.state;

        if (isLoadingOrder !== false) {
            return <LoadingSence blur={true} />;
        }

        if (this.props.order && this.props.order.order_not_show) {
            return (
                <ConfirmModal
                    id='confirm-order-not-show'
                    className='visible'
                    content={this.props.order.message}
                    title={t('ORDER_NOT_SHOW_TITLE')}
                    hasFooter={false}
                    isCustomFooter={true}
                    footer={
                        <button className={`ladiui btn btn-primary btn-sm`} onClick={() => this.props.history.push('/orders')}>
                            {'OK'}
                        </button>
                    }
                />
            );

            // window.LadiUI.showErrorMessage("Thông báo", this.props.order.message, "OK");
        }

        const { isEditableOrder } = this.state;
        if (!isEditableOrder) {
            return this.getNotEditablePanel();
        }

        return this.getEditablePanel();
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        show: (orderID) => dispatch(orderActions.show(orderID)),
        generateCheckout: (orderID) => dispatch(orderActions.generateCheckout(orderID)),
        reload: (orderID) => dispatch(orderActions.reload(orderID)),
        print: (orderID) => dispatch(printActions.printOrder(orderID)),
        assignOrder: (orderID) => dispatch(orderActions.assign(orderID)),
        assignToOther: (orderID, assigneeID) => dispatch(orderActions.assignToOther(orderID, assigneeID)),
        updateOrder: (order) => dispatch(orderActions.update(order)),
        updateAddress: (data) => dispatch(orderActions.updateAddress(data)),
        cancelOrder: (orderID, reason) => dispatch(orderActions.cancel(orderID, reason)),
        updateShippingAddress: (data) => dispatch(orderActions.updateShippingAddress(data)),
        refund: (data) => dispatch(orderActions.refund(data)),
        partialUpdateOrder: (order) => dispatch(orderActions.partialUpdate(order)),
        markAsPaid: (orderID, paymentGatewayCode, paymentType, isAutoShipping) =>
            dispatch(orderActions.markAsPaid(orderID, paymentGatewayCode, paymentType, isAutoShipping)),
        updateTag: (data) => dispatch(orderActions.updateTag(data)),
        updateSpams: (data) => dispatch(orderActions.updateSpams(data)),
        updatePaymentStatus: (orderID, paymentID, status) => dispatch(paymentActions.updateStatus(orderID, paymentID, status)),
        listAllTags: () => dispatch(orderTagActions.listAll()),
        updateOrderCustomerInfo: (orderID, email, phone) => dispatch(orderActions.updateOrderCustomerInfo(orderID, email, phone)),
        searchVariant: (name) => dispatch(variantActions.search(name)),
        showCustomer: (customerID) => dispatch(customerActions.show(customerID)),
        searchCustomer: (name) => dispatch(customerActions.search(name)),
        validateDiscount: (data) => dispatch(discountActions.validateDiscount(data)),
        getAvailableShippingRate: (data) => dispatch(shippingRateActions.getAvailable(data)),
        listCheckoutConfig: () => dispatch(checkoutConfigActions.list()),
        listGateways: () => dispatch(paymentActions.listGateways()),
        createOrderTag: (data) => dispatch(orderTagActions.create(data)),
        resendTicketList: (data) => dispatch(orderActions.resendTicketList(data)),
    };
};

const mapStateToProps = (state) => ({
    order: { ...state.order },
    customer: { ...state.customer },
    paymentReducer: { ...state.payment },
    orderTag: { ...state.orderTag },
    discount: { ...state.discount },
    shippingRate: { ...state.shippingRate },
    variant: { ...state.variant },
    printReducer: { ...state.print },
    storeReducer: { ...state.store },
    checkoutConfigReducer: { ...state.checkoutConfigReducer },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderEdit));
