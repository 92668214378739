import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import shippingActions from "../../redux/futures/shipping/actions";
import { includes, map, remove, find } from "lodash";
import moment from "moment";

import appConfig from "../../config/app";
import Pagination from "react-js-pagination";
import OrderDateRangePicker from "../../components/OrderDateRangePicker";
import baseHelper from "../../helpers/BaseHelper";

import * as types from "../../redux/futures/shipping/types";

import * as printTypes from "../../redux/futures/print/types";
import printActions from "../../redux/futures/print/actions";

import LoadingTable from "../../components/LoadingTable";
import { withTranslation } from "react-i18next";
import ConfirmModal from "../../components/ConfirmModal";
import { appLocalStorage } from "../../localforage";
import SelectOption from "../../components/SelectOption";
import LoadingScene from "../../components/LoadingScene";
import LadiDropdownMenu from "../../components/LadiDropdownMenu";
import LadiPagination from "../../components/LadiPagination";

import FilterConditionShipping from "./components/FilterConditionShipping";

class Shipping extends React.Component {
  constructor(props) {
    super(props);

    this.moment = baseHelper.getMoment();
    const defaultConditions = {
      page: 1,
      limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
    };

    this.tabs = {
      all: "ALL",
      paymentPending: "PAYMENT_PENDING",
      paymentReceived: "PAYMENT_RECEIVED",
      shippingReady: "SHIPPING_READY",
      search: "SEARCH",
    };
    this.state = {
      activePage: 1,
      conditionProp: {
        status: {
          value: appConfig.ORDER_STATUS.OPEN.CODE,
          name: appConfig.ORDER_STATUS.OPEN.NAME,
          condition: "=",
        },
      },
      searchStatus: "",
      searchName: "",
      searchPartner: "",
      searchPaymentStatus: "",
      selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
      currentShipping: {},
      mode: "",
      userInfo: {},
      fromDate: null,
      toDate: null,
      conditions: defaultConditions,
      selectedIDs: [],
      tabSelected: this.tabs.all,
      totalAmount: 0,
    };

    this.sortBy = { shipping_id: "DESC" };
    this.selectedID = "";
    // this.selectedIDs = [];
    this.formRef = React.createRef();
  }

  componentWillMount() {
    appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
      const owner = find(result.staffs, (item) => item.ladi_uid == result.ladi_uid);
      owner.name = this.props.t("COMMON.ME");

      this.setState({
        userInfo: result,
      });
    });
  }

  componentDidMount() {
    this.list();
  }

  componentDidUpdate() {
    window.LadiUI.init();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props === nextProps) {
      return;
    }

    if (this.props.shippingReducer.action != nextProps.shippingReducer.action) {
      if (nextProps.shippingReducer.action == types.CREATE_SHIPPING) {
        if (nextProps.shippingReducer.status) {
          this.setState({
            currentShipping: {},
          });

          this.list();
          window.LadiUI.toastCustom("success", "", nextProps.shippingReducer.message);

          setTimeout(() => {
            window.LadiUI.closeModal(this.formRef.current.ref.current);
          }, 500);
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.shippingReducer.message);
        }
      }

      if (nextProps.shippingReducer.action == types.UPDATE_SHIPPING) {
        if (nextProps.shippingReducer.status) {
          window.LadiUI.toastCustom("success", "", nextProps.shippingReducer.message);

          this.list();
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.shippingReducer.message);
        }
      }

      if (includes([types.DELETE_SHIPPING, types.DELETE_SHIPPINGS], nextProps.shippingReducer.action)) {
        if (nextProps.shippingReducer.status) {
          window.LadiUI.toastCustom("success", "", nextProps.shippingReducer.message);

          this.list();

          window.LadiUI.closeModal("confirm-shipping-delete");
          window.LadiUI.closeModal("confirm-shipping-deletes");
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.shippingReducer.message);
        }
      }

      if (nextProps.shippingReducer.action == types.LIST_SHIPPING) {
        if (nextProps.shippingReducer.status) {
          this.setState({
            totalAmount: nextProps.shippingReducer.total,
          });
          window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui table .sorting"), (item) => {
            item.classList.remove("up");

            if (this.sortBy[item.getAttribute("name")] == "DESC") {
              item.classList.add("up");
            }
          });
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.shippingReducer.message);
        }
      }
    }

    if (this.props.printReducer.action != nextProps.printReducer.action) {
      if (nextProps.printReducer.action == printTypes.PRINTS_SHIPPING) {
        if (nextProps.printReducer.status) {
          baseHelper.print(nextProps.printReducer.source);
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.printReducer.message);
        }
      }
    }
  }

  onChangeTab = (tabID) => {
    this.clearFilter();

    switch (tabID) {
      case this.tabs.shippingReady:
        this.setState({
          searchStatus: appConfig.SHIPPING_STATUS.READY_TO_PICK.CODE,
        });
        break;
      case this.tabs.paymentPending:
        this.setState({
          searchPaymentStatus: appConfig.SHIPPING_PAYMENT_STATUS.PENDING.CODE,
        });
        break;
      case this.tabs.paymentReceived:
        this.setState({
          searchPaymentStatus: appConfig.SHIPPING_PAYMENT_STATUS.PAID.CODE,
        });
        break;
    }

    setTimeout(() => {
      this.setState(
        {
          tabSelected: tabID,
        },
        () => {
          this.list();
        }
      );
    }, 200);
  };

  clearFilter = () => {
    this.setState({
      searchName: "",
      fromDate: null,
      toDate: null,
      searchStatus: "",
      searchPartner: "",
      searchPaymentStatus: "",
    });
  };
  /**
   * list shipping
   */
  list = (resetActivePageFlag = true) => {
    if (document.getElementById("checkAllItem")) {
      document.getElementById("checkAllItem").checked = false;
      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = false;
      });
    }

    const { searchName, selectedLimit, searchPartner, searchStatus, searchPaymentStatus, tabSelected, fromDate, toDate, conditions } =
      this.state;

    let activePage = conditions.page;
    if (resetActivePageFlag) {
      activePage = 1;
      conditions.page = 1;
      this.setState({
        conditions,
      });
    }

    // Reset selectedIDs
    // this.selectedIDs = [];

    this.setState({
      selectedIDs: [],
    });

    const data = {
      search: {
        name: searchName,
        from_date: fromDate ? moment(new Date(fromDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
        to_date: toDate ? moment(new Date(toDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
        status: searchStatus,
        payment_status: searchPaymentStatus,
        partner_code: searchPartner,
      },
      paged: activePage,
      limit: conditions.limit,
      sort: this.sortBy,
    };

    switch (tabSelected) {
      case this.tabs.paymentPending:
        data.search.payment_status = appConfig.SHIPPING_PAYMENT_STATUS.PENDING.CODE;
        break;
      case this.tabs.paymentReceived:
        data.search.payment_status = appConfig.SHIPPING_PAYMENT_STATUS.PAID.CODE;
        break;
      case this.tabs.shippingReady:
        data.search.status = appConfig.SHIPPING_STATUS.READY_TO_PICK.CODE;
        break;
      case this.tabs.search:
        data.search = {
          ...data.search,
          status: searchStatus,
          partner_code: searchPartner,
          payment_status: searchPaymentStatus,
        };
        break;
    }

    this.props.list(data);
  };

  /*******************************SEARCH***************************/
  onChangeInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  /**
   * Tim kiem theo name
   */
  onKeyDownInput = (event) => {
    if (event.key === "Enter") {
      this.list();
    }
  };

  /**
   * Tim kiem theo status
   */
  onChangeSearchStatus = (status) => {
    this.setState(
      {
        searchStatus: status,
        tabSelected: this.tabs.search,
      },
      () => {
        document.getElementById("triggerTabSearch").click();
        this.list(true);
      }
    );
  };

  /**
   * Tim kiem theo partner
   */
  onChangeSearchPartner = (partner) => {
    this.setState(
      {
        searchPartner: partner,
        tabSelected: this.tabs.search,
      },
      () => {
        document.getElementById("triggerTabSearch").click();
        this.list(true);
      }
    );
  };

  /**
   * Tim kiem theo payment
   */
  onChangeSearchPaymentStatus = (status) => {
    this.setState(
      {
        searchPaymentStatus: status,
        tabSelected: this.tabs.search,
      },
      () => {
        document.getElementById("triggerTabSearch").click();
        this.list(true);
      }
    );
  };

  onChangeStaff = (staff) => {
    let staffID = staff ? staff.ladi_uid : null;

    this.setState(
      {
        tabSelected: this.tabs.search,
      },
      () => {
        document.getElementById("triggerTabSearch").click();
        this.list(true);
      }
    );
  };
  /****************************************************************/

  /*******************************PAGINATION***************************/
  /**
   * Change limit
   */
  onChangeLimit = (option) => {
    let { conditions } = this.state;
    conditions.limit = option.value;

    // this.setState({ selectedLimit: option.value }, () => {
    this.setState({ conditions: conditions }, () => {
      this.list();
    });
  };

  /**
   * Change page
   */
  onPageChange = (pageNumber) => {
    let { conditions } = this.state;
    conditions.page = pageNumber;

    this.setState({ conditions }, () => {
      this.list(false);
    });
  };
  /****************************************************************/

  /*******************************CHECK AND ACTIONS***************************/
  checkAllItem = (event) => {
    const { checked } = event.target;

    let selectedIDsTg = [];
    if (checked) {
      selectedIDsTg = map(this.props.shippingReducer.shippings, (shipping) => shipping.shipping_id);

      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = true;
      });
    } else {
      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = false;
      });
    }

    this.setState({
      selectedIDs: selectedIDsTg,
    });
  };

  checkItem = (event, shippingID) => {
    const { checked } = event.target;
    const { selectedIDs } = this.state;

    if (checked) {
      if (!includes(selectedIDs, shippingID)) {
        selectedIDs.push(shippingID);
      }

      if (selectedIDs.length == this.props.shippingReducer.shippings.length) {
        document.getElementById("checkAllItem").checked = true;
      }
    } else {
      document.getElementById("checkAllItem").checked = false;
      remove(selectedIDs, (selectedID) => selectedID == shippingID);
    }

    this.setState({
      selectedIDs: selectedIDs,
    });
  };

  sort = (event) => {
    const { classList } = event.target;
    const name = event.target.getAttribute("name");

    window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui table .sorting"), (item) => {
      if (item.getAttribute("name") != name) {
        item.classList.remove("up");
      }
    });

    this.sortBy = {};

    // Change arrow up and down
    if (includes(classList, "up")) {
      classList.remove("up");
      this.sortBy[name] = "ASC";
    } else {
      classList.add("up");
      this.sortBy[name] = "DESC";
    }

    this.setState({}, () => this.list());
  };

  openOptionDeleteModal = (selectedID) => {
    this.selectedID = selectedID;

    window.LadiUI.showModal("confirm-shipping-delete");
  };

  openOptionDeletesModal = () => {
    if (this.state.selectedIDs.length <= 0) {
      window.LadiUI.toastCustom("danger", "", this.props.t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
      return;
    }

    window.LadiUI.showModal("confirm-shipping-deletes");
  };

  prints = () => {
    if (this.state.selectedIDs.length <= 0) {
      window.LadiUI.toastCustom("danger", "", this.props.t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
      return;
    }

    this.props.prints(this.state.selectedIDs);
  };

  /****************************************************************/

  getStatusName = (status) => {
    if (!status) {
      return this.props.t("STATUS.ALL_STATUS");
    }

    const matched = find(appConfig.SHIPPING_STATUS, (item) => item.CODE == status);
    if (matched) {
      return matched.NAME;
    }
  };

  getPaymentStatusName = (paymentStatus) => {
    if (!paymentStatus) {
      return this.props.t("PAYMENT_STATUS.ALL_STATUS");
    }

    const matched = find(appConfig.SHIPPING_PAYMENT_STATUS, (item) => item.CODE == paymentStatus);
    if (matched) {
      return matched.NAME;
    }
  };

  eventSelectAction = (action) => {
    switch (action) {
      // case 'REMOVE_MARK_SPAM':
      //     this.openRemoveSpamModal();
      //     break;
      // case 'MARK_SPAM':
      //     this.openMarkSpamModal();
      //     break;
      // case 'CANCEL_ORDER':
      //     this.openModalCancel();
      //     break;
      // case 'CONFIRM_PAYMENT':
      //     this.openModalConfirmPayment();
      //     break;
      case "DELETE":
        this.openOptionDeletesModal();
        break;
      case "PRINT":
        this.prints();
        break;
      default:
    }
  };

  clearFilter = (callback) => {
    this.setState(
      {
        searchStatus: "",
        searchPartner: "",
        searchPaymentStatus: "",
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  filterActionProp = async (conditions) => {
    this.clearFilter();

    // let filter = {
    //     tabSelected: appConfig.ORDER_FILTER_TYPE.SEARCH_ALL,
    //     searchName: ""
    // }

    for await (const condition of conditions) {
      switch (condition.attributeFilter.value) {
        case "status":
          this.setState({
            searchStatus: condition.valueFilter.value,
          });
          break;
        case "partner":
          this.setState({
            searchPartner: condition.valueFilter.value,
          });
          break;
        case "payment":
          this.setState({
            searchPaymentStatus: condition.valueFilter.value,
          });
          break;
        default:
          break;
      }
    }

    // this.setState({
    //     ...filter
    // }, () => {
    //     this.list();
    // })
    this.list();
  };

  render() {
    const { t } = this.props;
    const { totalRecord, totalPage } = this.props.shippingReducer;
    const {
      activePage,
      selectedLimit,
      searchStatus,
      searchName,
      searchPartner,
      searchPaymentStatus,
      userInfo,
      tabSelected,
      conditions,
      conditionProp,
    } = this.state;
    let fromItem = 0;
    let toItem = 0;
    if ((this.props.shippingReducer.shippings || []).length > 0) {
      fromItem = (activePage - 1) * selectedLimit + 1;
      toItem = fromItem + this.props.shippingReducer.shippings.length - 1;
    }
    const isLoadingData = this.props.shippingReducer.loading && this.props.shippingReducer.waiting == types.LIST_SHIPPING;
    const isLoadingDelete =
      this.props.shippingReducer.loading && includes([types.DELETE_SHIPPING, types.DELETE_SHIPPINGS], this.props.shippingReducer.waiting);
    const isLoadingPrint = this.props.printReducer.loading && this.props.printReducer.waiting == printTypes.PRINTS_SHIPPING;

    // const msgShowItem = `${t('PAGINATION.SHOW')} ${fromItem} ${t('PAGINATION.TO')} ${toItem} ${t('PAGINATION.OF')} ${baseHelper.formatNumber(totalRecord)} ${t('PAGINATION.ITEMS')}`;
    const msgShowItem = ``;

    const SHIPPING_STATUS = {};
    map(appConfig.SHIPPING_STATUS, (item, key) => {
      if (item.CODE != appConfig.SHIPPING_STATUS.PENDING.CODE) {
        SHIPPING_STATUS[key] = item;
      }
    });

    const listAction = [
      { value: "PRINT", name: t("COMMON.PRINT") },
      { value: "DELETE", name: t("ACTIONS.DELETE") },
      // { value: 'REMOVE_MARK_SPAM', name: t('ORDERS.REMOVE_MARK_SPAM') },
      // { value: 'MARK_SPAM', name: t('ORDERS.MARK_SPAM') },
      // { value: 'CANCEL_ORDER', name: t('ORDERS.CANCEL_ORDER') },
      // { value: 'CONFIRM_PAYMENT', name: t('ORDERS.CONFIRM_PAYMENT') },
    ];

    return (
      <div className="page-content">
        {isLoadingPrint && <LoadingScene blur={true} />}
        <div>
          <div className="ladiui-table-actions mb-24">
            <div
              className="ladiui block-filter-condition"
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <div className="ladiui btn-group block-filter-header">
                <FilterConditionShipping filterActionProp={this.filterActionProp} conditionProp={conditionProp} />
              </div>

              <div className="ladiui search-group mr-24">
                <input
                  id="keyword_search"
                  className="ladiui search-field dropdown-toggle form-control search-width"
                  name="searchName"
                  placeholder={t("SHIPPINGS.SEARCH_SHIPPING")}
                  aria-expanded="false"
                  value={searchName}
                  onChange={this.onChangeInput}
                  onKeyDown={this.onKeyDownInput}
                />
                <i className="ladiui icon icon-search" onClick={this.list}></i>
              </div>
              <div className="date-filter">
                <OrderDateRangePicker
                  startDateId="fromDate"
                  endDateId="toDate"
                  startDate={this.state.fromDate}
                  endDate={this.state.toDate}
                  onDatesChange={({ startDate, endDate }) => {
                    this.setState(
                      {
                        fromDate: baseHelper.getFromDate(startDate),
                        toDate: baseHelper.getToDate(endDate),
                      },
                      () => {
                        if ((startDate && endDate) || (!startDate && !endDate)) {
                          this.list();
                        }
                      }
                    );
                  }}
                  focusedInput={this.state.focusedInput}
                  onFocusChange={(focusedInput) => {
                    this.setState({ focusedInput });
                  }}
                  showDefaultInputIcon={true}
                  inputIconPosition="after"
                  small={true}
                  hideKeyboardShortcutsPanel={true}
                  customInputIcon={<i className="ladi-icon icon-calendar-date-2" />}
                  startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                  endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                  isOutsideRange={() => false}
                  isDayHighlighted={(date) => {
                    return this.moment().isSame(date, "day");
                  }}
                  minimumNights={0}
                  showClearDates={true}
                />
              </div>
            </div>

            {/* <div className="action-item">
                            <ul className="ladiui -horizontal">
                                <li className="ladiui-dropdown">
                                    <a className="ladiui button outline-primary">{t('ACTIONS.TITLE')} <i className="ladi-icon icon-down-arrow" /></a>
                                    <ul>
                                        <li><a onClick={this.prints}>{t('COMMON.PRINT')}</a></li>
                                        <li><a onClick={this.openOptionDeletesModal}>{t('ACTIONS.DELETE')}</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <div className="action-item">
                            <div className="ladiui search-keyword">
                                <input type="text" id="search" placeholder={t('SHIPPINGS.SEARCH_SHIPPING')} name="searchName" value={searchName} onChange={this.onChangeInput} onKeyDown={this.onKeyDownInput} className="ladiui input" />
                                <i className="ladi-icon icon-zoom" onClick={this.list} />
                            </div>
                        </div>

                        <div className="action-item">
                            <SelectOption
                                wrapperStyle={{ minWidth: 150 }}
                                items={SHIPPING_STATUS}
                                _key="CODE"
                                _value="NAME"
                                pickTitle={t('STATUS.ALL_STATUS')}
                                onSelectItem={(item) => {
                                    this.onChangeSearchStatus(item ? item.CODE : null)
                                }}
                                currentKey={searchStatus}
                            />
                        </div>

                        <div className="action-item">
                            <SelectOption
                                wrapperStyle={{ minWidth: 160 }}
                                items={appConfig.SHIPPING_PARTNERS}
                                _key="CODE"
                                _value="NAME"
                                pickTitle={t('SHIPPING_PARTNERS.ALL_PARTNER')}
                                onSelectItem={(item) => {
                                    this.onChangeSearchPartner(item ? item.CODE : null)
                                }}
                                currentKey={searchPartner}
                            />
                        </div>

                        <div className="action-item">
                            <SelectOption
                                wrapperStyle={{ minWidth: 120 }}
                                items={appConfig.SHIPPING_PAYMENT_STATUS}
                                _key="CODE"
                                _value="NAME"
                                pickTitle={t('PAYMENT_STATUS.ALL_STATUS')}
                                onSelectItem={(item) => {
                                    this.onChangeSearchPaymentStatus(item ? item.CODE : null)
                                }}
                                currentKey={searchPaymentStatus}
                            />
                        </div> */}
          </div>

          <div className="order-tab">
            <ul className="ladiui nav nav-tabs overflow-hide">
              {/* tabSelected */}
              <li className="ladiui nav-item" id="tabAll">
                <span
                  data-tab="tabAll"
                  className={`ladiui tab-link ${tabSelected === this.tabs.all ? "active" : ""}`}
                  onClick={() => this.onChangeTab(this.tabs.all)}
                >
                  {t("SHIPPINGS.TAB_ALL")}
                </span>
              </li>
              <li className="ladiui nav-item" id="tabShippingPending">
                <span
                  data-tab="tabShippingPending"
                  className={`ladiui tab-link ${tabSelected === this.tabs.shippingReady ? "active" : ""}`}
                  onClick={() => this.onChangeTab(this.tabs.shippingReady)}
                >
                  {t("SHIPPINGS.TAB_SHIPPING_READY")}
                </span>
              </li>
              <li className="ladiui nav-item" id="tabPaymentPending">
                <span
                  data-tab="tabPaymentPending"
                  className={`ladiui tab-link ${tabSelected === this.tabs.paymentPending ? "active" : ""}`}
                  onClick={() => this.onChangeTab(this.tabs.paymentPending)}
                >
                  {t("SHIPPINGS.TAB_PAYMENT_PENDING")}
                </span>
              </li>
              <li className="ladiui nav-item" id="tabPaymentReceived">
                <san
                  data-tab="tabPaymentReceived"
                  className={`ladiui tab-link ${tabSelected === this.tabs.paymentReceived ? "active" : ""}`}
                  onClick={() => this.onChangeTab(this.tabs.paymentReceived)}
                >
                  {t("SHIPPINGS.TAB_PAYMENT_RECEIVED")}
                </san>
              </li>
              <li
                className="ladiui nav-item"
                id="tabSearch"
                style={this.state.tabSelected == this.tabs.search ? { display: "block" } : { display: "none" }}
              >
                <span
                  id="triggerTabSearch"
                  className={`ladiui tab-link ${tabSelected === this.tabs.search ? "active" : ""}`}
                  data-tab="tabSearch"
                >
                  {t("ORDERS.TAB_SEARCH")}
                </span>
              </li>
            </ul>
          </div>
          {isLoadingData ? (
            <LoadingTable />
          ) : (
            <div>
              <div className="ladi-card ladiui-table-responsive">
                <table className={`ladiui table text-left shipping-table table-order`}>
                  <thead style={{ position: "relative" }}>
                    <tr className="ladiui table-vertical header">
                      <th scope="col" className="ladiui checkall">
                        <input
                          id="checkAllItem"
                          onClick={this.checkAllItem}
                          type="checkbox"
                          className="ladiui-checkall ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle"
                        />
                      </th>

                      {this.state.selectedIDs.length <= 0 ? (
                        <th scope="col">{t("COMMON.CREATED_AT")}</th>
                      ) : (
                        <th scope="col">
                          {t("COMMON.CREATED_AT")}
                          <LadiDropdownMenu
                            labelDropdown={"Chọn hành động"}
                            listProp={listAction}
                            defaultTextProp={t("ACTIONS.TITLE")}
                            hideLabel={true}
                            cbProp={this.eventSelectAction}
                            classSize="btn-sm "
                            classWidth="w-180"
                            classDropdown="w-180 action-record-table"
                          />
                        </th>
                      )}

                      <th scope="col">{t("SHIPPINGS.SHIPPING_CODE")}</th>
                      <th
                        scope="col"
                        name="refernce_no"
                        onClick={this.sort}
                        className={`sorting ${this.sortBy["refernce_no"] == "DESC" ? "up" : ""}`}
                      >
                        {t("ORDERS.TITLE")}
                      </th>
                      <th scope="col">{t("CUSTOMERS.TITLE")}</th>
                      <th scope="col">{t("COMMON.PHONE")}</th>
                      <th
                        scope="col"
                        name="status"
                        onClick={this.sort}
                        className={`sorting ${this.sortBy["status"] == "DESC" ? "up" : ""}`}
                      >
                        {t("STATUS.TITLE")}
                      </th>
                      <th
                        scope="col"
                        name="payment_status"
                        onClick={this.sort}
                        className={`sorting ${this.sortBy["payment_status"] == "DESC" ? "up" : ""}`}
                      >
                        {t("SHIPPINGS.COD_TITLE")}
                      </th>
                      <th scope="col">{t("COMMON.TOTAL")}</th>
                      <th scope="col">{t("ORDERS.ASSIGNEE")}</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {map(this.props.shippingReducer.shippings, (shipping) => {
                      return (
                        <tr
                          key={shipping.shipping_id}
                          className={
                            shipping.status == appConfig.SHIPPING_STATUS.CANCELED.CODE
                              ? "ladiui table-vertical main shipping-cancel"
                              : "ladiui table-vertical main"
                          }
                        >
                          <td>
                            <input
                              type="checkbox"
                              onClick={(event) => this.checkItem(event, shipping.shipping_id)}
                              className="ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle"
                            />
                          </td>
                          {/* <td><a className="order-table-item" href={`/shippings/${shipping.shipping_id}`}>{`${shipping.shipping_id}`}</a></td> */}
                          <td>
                            <NavLink to={`/shippings/${shipping.shipping_id}`}>{baseHelper.formatStrToDate(shipping.created_at)}</NavLink>
                          </td>
                          <td>{baseHelper.getTrackingHref(shipping)}</td>
                          <td>
                            <a className="order-table-item" href={`/orders/${shipping.order_id}`}>{`#${shipping.reference_no}`}</a>
                          </td>
                          <td>
                            <a href={`/customers?customer_id=${shipping.customer_id}`}>{`${baseHelper.getText(
                              shipping.shipping_last_name
                            )} ${baseHelper.getText(shipping.shipping_first_name)}`}</a>
                          </td>
                          <td>{shipping.shipping_phone}</td>
                          <td>{this.getStatusName(shipping.status)}</td>
                          <td>{this.getPaymentStatusName(shipping.payment_status)}</td>
                          <td>
                            {baseHelper.formatMoneyPostFix(shipping.total, this.props.storeReducer.userInfo.currentStore.currency_symbol)}
                          </td>
                          <td className="text-center">
                            {baseHelper.getStaffNameById(userInfo.staffs, shipping.creator_id, userInfo.ladi_uid)}
                          </td>
                          <td className="text-right pd-0">
                            <div className="ladiui btn-group">
                              <div className="ladiui dropdown hide-mt ba-c">
                                {/* <i className="ladi-icon icon-dots ladiui dropdown-toggle select-dropdown pd-5" data-toggle="dropdown"></i> */}
                                <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                                  <i className="ladiui icon icon-ldp-dot"></i>
                                </button>
                                <ul className="ladiui dropdown-menu r-0">
                                  <li>
                                    <NavLink className="ladiui dropdown-item" to={`/shippings/${shipping.shipping_id}`}>
                                      {t("ACTIONS.EDIT")}
                                    </NavLink>
                                  </li>
                                  {shipping.status == appConfig.SHIPPING_STATUS.CANCELED.CODE && (
                                    <li>
                                      <a className="ladiui dropdown-item" onClick={() => this.openOptionDeleteModal(shipping.shipping_id)}>
                                        {t("ACTIONS.DELETE")}
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}

                    {totalRecord <= 0 && (
                      <tr className="text-center">
                        <td colSpan="100%">{t("NO_DATA")}</td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={8}>
                        <strong>{t("ORDERS.TOTAL_AMOUNT")}</strong>
                      </td>
                      <td colSpan={3}>
                        <strong className="pl-0">{baseHelper.formatMoneyPostFix(this.state.totalAmount, this.props.storeReducer.userInfo.currentStore.currency_symbol)}</strong>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              {totalRecord > 0 && (
                <LadiPagination
                  conditionsProp={conditions}
                  listName={t("CUSTOM_FIELD.ORDER")}
                  message={msgShowItem}
                  items={this.props.shippingReducer.shippings}
                  total={totalRecord}
                  limitAction={this.onChangeLimit}
                  pageAction={this.onPageChange}
                />
              )}
            </div>
          )}
        </div>

        {/* <Form
                    ref={this.formRef}
                    currentShipping={this.state.currentShipping}
                    mode={this.state.mode}
                /> */}

        <ConfirmModal
          id="confirm-shipping-delete"
          title={t("SHIPPINGS.MSG_DELETE_SHIPPING_TITLE")}
          content={t("SHIPPINGS.MSG_DELETE_SHIPPING_CONTENT")}
          cancelText={t("ACTIONS.CANCEL")}
          okText={t("ACTIONS.DELETE")}
          onOk={() => this.props.delete(this.selectedID)}
          isLoading={isLoadingDelete}
        />

        <ConfirmModal
          id="confirm-shipping-deletes"
          title={t("SHIPPINGS.MSG_DELETE_SHIPPING_TITLE")}
          content={t("SHIPPINGS.MSG_DELETE_SHIPPINGS_CONTENT")}
          cancelText={t("ACTIONS.CANCEL")}
          okText={t("ACTIONS.DELETE")}
          onOk={() => this.props.deletes(this.state.selectedIDs)}
          isLoading={isLoadingDelete}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    list: (data) => dispatch(shippingActions.list(data)),
    deletes: (shippingIDs) => dispatch(shippingActions.deletes(shippingIDs)),
    delete: (shippingID) => dispatch(shippingActions.delete(shippingID)),
    prints: (shippingIDs) => dispatch(printActions.printsShipping(shippingIDs)),
  };
};

const mapStateToProps = (state) => ({
  shippingReducer: { ...state.shipping },
  printReducer: { ...state.print },
  storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Shipping));
