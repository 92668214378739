import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Input from "../../../../components/Input";
import LoadingSence from "../../../../components/LoadingScene";
import { connect } from "react-redux";
import shippingActions from "../../../../redux/futures/shipping/actions";
import * as shippingTypes from "../../../../redux/futures/shipping/types";

import appConfig from "../../../../config/app";
import { includes, map } from "lodash";
import baseHelper from "../../../../helpers/BaseHelper";

class GHTKForm extends React.Component {
  static propTypes = {
    orderID: PropTypes.number,
    weight: PropTypes.any,
    storeAddressID: PropTypes.any,
    total: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      deliverWorkShift: {
        code: appConfig.GHTK.DELIVER_WORK_SHIFT.DEFAULT.code,
        name: appConfig.GHTK.DELIVER_WORK_SHIFT.DEFAULT.name,
      },
      method: {
        code: appConfig.GHTK.METHOD.ROAD.code,
        name: appConfig.GHTK.METHOD.ROAD.name,
      },
      isFreeShip: appConfig.GHTK.FREE_SHIP.ONLY_COD,
      insurance: props.total || 0,
      hasInsurance: false,
    };

    this.inputsRef = new Set();
  }

  componentDidMount() {
    this.setState({
      total: this.props.total,
    });
    if (this.props.storeAddressID) {
      this.getFee();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.storeAddressID != prevProps.storeAddressID) {
      if (this.props.storeAddressID) {
        this.getFee();
      }
    }
  }

  onChangeWorkShift = (deliverWorkShift) => {
    this.setState({
      deliverWorkShift,
    });
  };

  onChangeMethod = (method) => {
    this.setState(
      {
        method,
      },
      () => {
        this.getFee();
      }
    );
  };

  getFee = () => {
    this.props.resetFee();

    if (!this.validate()) {
      return;
    }
    const data = {
      order_id: this.props.orderID,
      shipping_partner_code: appConfig.SHIPPING_PARTNERS.GHTK.CODE,
      ghtk_data: {
        store_address_id: this.props.storeAddressID,
        method: this.state.method.code,
      },
      weight: this.props.weight,
    };

    this.props.getFee(data);
  };

  validate = () => {
    const { t, weight, storeAddressID } = this.props;
    if (!storeAddressID) {
      window.LadiUI.toastCustom("danger", "", t("SHIPPINGS.SETUP_AT_LEAST_ONE_HUB"));
      return false;
    }

    if (baseHelper.parseFloat(weight) <= 0) {
      document.getElementById("weight").classList.add("error");
      window.LadiUI.toastCustom("danger", "", t("VALIDATION.GREATER_THAN", t("COMMON.WEIGHT"), "0"));
      return false;
    }

    return true;
  };

  getData = () => {
    const { deliverWorkShift, method } = this.state;

    return {
      deliver_work_shift: deliverWorkShift.code,
      method: method.code,
      insurance: this.state.hasInsurance ? this.state.insurance : 0,
      is_freeship: this.state.isFreeShip,
    };
  };

  render() {
    const { t } = this.props;
    const { deliverWorkShift, method } = this.state;
    const loadingData =
      this.props.shippingReducer.loading &&
      includes([shippingTypes.SHIPPING_LIST_SERVICES, shippingTypes.SHIPPING_GET_FEE], this.props.shippingReducer.waiting);
    return (
      <div className="ghtk-form">
        {loadingData && <LoadingSence blur={true} />}

        <div className="ladiui-row">
          <div className="ladiui form-group">
            <label className="ladiui-label">{t("SHIPPINGS.GHTK_PICK_WORK_SHIP")}</label>
            {/* <div className="ladiui">
                            <div className="ladiui-select-options">
                                <span className="ladiui-select-init">{deliverWorkShift.name}</span>
                                <input type="hidden" name="select" defaultValue />
                                <ul>
                                    {
                                        map(appConfig.GHTK.DELIVER_WORK_SHIFT, item =>
                                            <li key={item.code} className="select-item" onClick={() => this.onChangeWorkShift(item)}><a data-value={item.code}>{item.name}</a></li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div> */}
            <div class="ladiui btn-group w100">
              <div class="ladiui dropdown w100">
                <button
                  style={{ minWidth: "100%" }}
                  class="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                  id="dropdownMenuButton3"
                  data-toggle="dropdown"
                >
                  <i></i>
                  <span class="ladiui dropdown-text">{deliverWorkShift.name}</span>
                </button>
                <ul class="ladiui dropdown-menu w100">
                  {map(appConfig.GHTK.DELIVER_WORK_SHIFT, (item) => (
                    <li key={item.code} onClick={() => this.onChangeWorkShift(item)}>
                      <a class="ladiui dropdown-item">{item.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="ladiui form-group mt-0">
            <label className="ladiui-label">{t("SHIPPINGS.METHOD")}</label>
            {/* <div className="ladiui">
                            <div className="ladiui-select-options">
                                <span className="ladiui-select-init">{method.name}</span>
                                <input type="hidden" name="select" defaultValue />
                                <ul>
                                    {
                                        map(appConfig.GHTK.METHOD, item =>
                                            <li key={item.code} className="select-item" onClick={() => this.onChangeMethod(item)}><a data-value={item.code}>{item.name}</a></li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div> */}
            <div class="ladiui btn-group w100">
              <div class="ladiui dropdown w100">
                <button
                  style={{ minWidth: "100%" }}
                  class="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                  id="dropdownMenuButton3"
                  data-toggle="dropdown"
                >
                  <i></i>
                  <span class="ladiui dropdown-text">{method.name}</span>
                </button>
                <ul class="ladiui dropdown-menu w100">
                  {map(appConfig.GHTK.METHOD, (item) => (
                    <li key={item.code} onClick={() => this.onChangeMethod(item)}>
                      <a class="ladiui dropdown-item">{item.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="ladiui-row">
          <div className="ladiui form-group mb-0">
            <div className="ladiui form-group flex">
              <input
                checked={this.state.isFreeShip == appConfig.GHTK.FREE_SHIP.COD_AND_TOTAL}
                onChange={() => {
                  this.setState({
                    isFreeShip:
                      this.state.isFreeShip == appConfig.GHTK.FREE_SHIP.COD_AND_TOTAL
                        ? appConfig.GHTK.FREE_SHIP.ONLY_COD
                        : appConfig.GHTK.FREE_SHIP.COD_AND_TOTAL,
                  });
                }}
                type="checkbox"
                className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
              />
              <label className="ladiui label"> {t("SHIPPINGS.IS_BUYER_SHIPPING_FREE")}</label>
            </div>

            <div className="ladiui form-group flex mb-0">
              <input
                checked={this.state.hasInsurance}
                onChange={() => {
                  this.setState(
                    {
                      hasInsurance: !this.state.hasInsurance,
                    },
                    () => {
                      let insurance = this.state.hasInsurance ? this.props.total : 0;
                      this.setState({
                        insurance,
                      });
                    }
                  );
                }}
                type="checkbox"
                className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
              />
              <label className="ladiui label"> {t("SHIPPINGS.HAS_INSURANCE")}</label>
            </div>
          </div>
          <div className="ladiui form-group mb-0">
            {this.state.hasInsurance && (
              <div className="ladiui form-group">
                <label className="ladiui-label">{t("SHIPPINGS.INSURANCE")}</label>
                <Input
                  name="insurance"
                  value={this.state.insurance}
                  onChange={(event) => this.setState({ insurance: event.target.value })}
                  type="number"
                  currency={true}
                  prefix={t("CURRENCIES.VND_CODE")}
                  formatter={(value) => `đ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => value.replace(/[đ, ]/g, "")}
                  disabled={!this.state.hasInsurance}
                  onBlur={() => this.getFee()}
                />
              </div>
            )}
          </div>
        </div>

        <div className="ladiui form-group">
          <label className="ladiui-label">{t("SHIPPINGS.ESTIMATE_FEE")}</label>
          <Input
            ref={(ref) => this.inputsRef.add(ref)}
            name="fee"
            value={baseHelper.formatMoneyPostFix(
              this.props.shippingReducer.fee,
              this.props.storeReducer.userInfo.currentStore.currency_symbol
            )}
            disabled={true}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetFee: (data) => dispatch(shippingActions.resetFee(data)),
    getFee: (data) => dispatch(shippingActions.getFee(data)),
  };
};

const mapStateToProps = (state) => ({
  shippingReducer: { ...state.shipping },
  storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withTranslation("translation", { withRef: true })(GHTKForm)
);
