import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Input from "./Input";

import settingActions from "../redux/futures/setting/actions";
import * as settingTypes from "../redux/futures/setting/types";

import appConfig from "../config/app";
import DropdownCheckboxSearch from "./DropdownCheckboxSearch";


import { map, compact, remove } from "lodash";


// eslint-disable-next-line max-lines-per-function
function ModalAddCustomField(props) {
    const { t, onClose, isShow, innerStyle, productId, selectedDataCustomField } = props;
    const wrapperRef = useRef(null);
    const dispatch = useDispatch();

    const settingReducer = useSelector((state) => state.setting);

    const inputsRef = new Set();

    const [conditions, setConditions] = useState({
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
    });

    const [dataCustomfields, setDataCustomfields] = useState([]);

    const [isTypeCustomField, setIsTypeCustomField] = useState("CUSTOMER");

    const [currentDataCustomField, setCurrentDataCustomField] = useState([]);
    const [currentDataCustomFieldCustomer, setCurrentDataCustomFieldCustomer] = useState([]);
    const [currentDataCustomFieldOrder, setCurrentDataCustomFieldOrder] = useState([]);



    const [selectedCustomField, setSelectedCustomfield] = useState([])

    const [valueSearch, setValueSearch] = useState("")

    useEffect(() => {
        let payload = {
            page: 1,
            limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
            keyword: "",
            group_type: isTypeCustomField,
            sort: {},
        }
        dispatch(settingActions.listByCustomField(payload))
    }, [])

    useEffect(() => {
        let payload = {
            page: 1,
            limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
            keyword: "",
            group_type: isTypeCustomField,
            sort: {},
        }
        dispatch(settingActions.listByCustomField(payload))
    }, [isTypeCustomField])

    useEffect(() => {
        if (selectedDataCustomField && selectedDataCustomField.length > 0) {
            let _customers = [...currentDataCustomFieldCustomer];
            let _orders = [...currentDataCustomFieldOrder];
            // setSelectedCustomfield([...selectedDataCustomField]);

            selectedDataCustomField.map((item) => {
                if (item.group_type == "CUSTOMER") {
                    _customers.push(item);
                } else if (item.group_type == "ORDER") {
                    _orders.push(item);
                }
            })

            setCurrentDataCustomFieldCustomer(_customers);
            setCurrentDataCustomFieldOrder(_orders);
            setCurrentDataCustomField([...selectedDataCustomField]);
        }
    }, [selectedDataCustomField])

    useEffect(() => {
        if (settingReducer.action == settingTypes.SETTING_LIST_BY_CUSTOM_FIELD) {
            if (settingReducer.status) {
                if (settingReducer.customFields && settingReducer.customFields.length > 0) {
                    // setDataCustomfields(settingReducer.customFields);

                    let _data = [];
                    settingReducer.customFields.map((item, index) => {
                        _data.push({
                            name: item.name ? item.name : "",
                            custom_field_id: item.custom_field_id ? item.custom_field_id : null,
                            label: item.label ? item.label : "",
                            group_type: item.group_type ? item.group_type : "",
                            data_type: item.data_type ? item.data_type : "",
                            data_values: item.data_values ? item.data_values : "",
                            is_enable: true,
                            is_required: true
                        });
                    });

                    setDataCustomfields(_data);
                }
            }
        }
    }, [settingReducer])

    const eventCancel = () => {
        onClose(false);
    };

    const handleSearchValue = (value) => {
        let payload = {
            page: 1,
            limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
            keyword: valueSearch,
            group_type: isTypeCustomField,
            sort: {},
        }
        dispatch(settingActions.listByCustomField(payload))
    }

    const onKeyDownInputSearch = (event) => {
        if (event.key == "Enter") {
            handleSearchValue();
        }
    }

    const handleRefreshCustomfield = () => {

        let payload = {
            page: 1,
            limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
            keyword: "",
            group_type: isTypeCustomField,
            sort: {},
        }
        dispatch(settingActions.listByCustomField(payload))
    }

    const handleAddCustomfield = (e) => {
        e.preventDefault();
        props.handleAddCustomfieldSelected(selectedCustomField);
    }

    const checkItem = (event, item) => {
        const { checked } = event.target;

        // let _selectedCategories = [...selectedCates];
        let _selected_custom_fields = [...selectedCustomField];

        if (checked) {
            let isSelected = _selected_custom_fields.some((value) => value.custom_field_id == item.custom_field_id)
            if (!isSelected) {
                _selected_custom_fields.push(item);
            }
        } else {
            remove(_selected_custom_fields, (value) => value.custom_field_id === item.custom_field_id);
        }

        // setSelectedCates(_selectedCategories);
        setSelectedCustomfield(_selected_custom_fields);
    }


    const checkAllItem = (event) => {
        let _custom_fields = [];
        let _selected_custom_field_ids = currentDataCustomField && currentDataCustomField.length > 0 ? currentDataCustomField.map(item => item.custom_field_id) : [];
        const { checked } = event.target;
        if (checked) {
            // setSelectedCates(dataCategories)
            if (dataCustomfields && dataCustomfields.length > 0) {
                map(dataCustomfields, (item) => {
                    if (!_selected_custom_field_ids.includes(item.custom_field_id)) {
                        _custom_fields.push(item);
                    }
                })
                // setDataCategories(_categories);
                setSelectedCustomfield(_custom_fields);
            }
        } else {
            // setDataCategories([]);

            setSelectedCustomfield([])
        }
    }

    const renderCustomFieldItem = () => {
        return map(dataCustomfields, (item, index) => {
            let isExits = currentDataCustomField.some((value) => value.custom_field_id == item.custom_field_id);
            if (!isExits) {
                return (
                    <li className='ladiui check-element' key={index}>
                        <label className='ladiui search-checkbox default-text flex cursor-pointer' data-id={index}>
                            <input
                                name='checkbox'
                                type='checkbox'
                                className='ladiui checkbox size-checkbox form-check-input-checkbox mr-0'
                                onChange={(event) => checkItem(event, item)}
                                checked={selectedCustomField.some((value) => value.custom_field_id == item.custom_field_id)}
                            />
                            {item.level && item.level > 1 && (
                                <i className='ldicon-subcategory ml-8' />
                            )}
                            <p className="ml-8">
                                {item.label ? item.label : ""}
                            </p>
                        </label>
                    </li>
                )
            }
        })
        // });

        // return items;
    }




    return (
        <div className={`ladiui modal ${isShow ? "show" : ""}`} id="modal-add-customfield-checkout-config" >
            <div className="ladiui modal-dialog modal-dialog-centered w-600" ref={wrapperRef}>
                <div className="ladiui modal-content ">
                    <div className="ladiui modal-header ">
                        <h2 className="ladiui modal-title ">Thêm trường tùy chỉnh</h2>
                        <button data-dismiss="modal" data-target="add-product" onClick={eventCancel} className="ladiui modal-close">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="ladiui modal-body" style={{ position: "initial" }}>
                        <div className="ladiui form-group">
                            <label className="ladiui-label">Chọn loại trường tùy chỉnh</label>
                            <div className="flex">
                                <div className='flex' style={{ width: "50%" }}>
                                    <input
                                        id='radioTypeCustom'
                                        type='radio'
                                        className='ladiui form-check-input'
                                        name='radio-group'
                                        value={isTypeCustomField}
                                        onChange={() => {
                                            setIsTypeCustomField("CUSTOMER");
                                            setSelectedCustomfield([]);
                                            setValueSearch("");
                                        }}
                                        checked={isTypeCustomField == "CUSTOMER"}
                                    />
                                    <label htmlFor='radioTypeCustom'>Khách hàng</label>
                                </div>
                                <div className='flex' style={{ width: "50%" }}>
                                    <input
                                        id='radioTypeCustom'
                                        type='radio'
                                        className='ladiui form-check-input'
                                        name='radio-group'
                                        value={isTypeCustomField}
                                        onChange={() => {
                                            setIsTypeCustomField("ORDER");
                                            setSelectedCustomfield([]);
                                            setValueSearch("");
                                        }}
                                        checked={isTypeCustomField == "ORDER"}
                                    />
                                    <label htmlFor='radioTypeCustom'>Đơn hàng</label>
                                </div>
                            </div>
                        </div>
                        <div className="ladiui search-group mb-24">
                            <input
                                className="ladiui search-field dropdown-toggle form-control"
                                value={valueSearch}
                                placeholder={"Tìm kiếm..."}
                                data-toggle="dropdown"
                                aria-expanded="false"
                                onChange={(e) => {
                                    setValueSearch(e.target.value)
                                }}
                                onKeyDown={onKeyDownInputSearch}
                            />
                            <i className="ladiui icon icon-search" onClick={handleSearchValue}></i>
                        </div>
                        <div className="list-custom-fields">
                            <ul>
                                {renderCustomFieldItem()}
                                <div onClick={() => handleRefreshCustomfield()}>
                                    <a className={`ladiui border-none btn-add-option mt-16`} style={{ color: "initial" }}>
                                        <i className='ladi-icon icon-sync mr-8' />
                                        {"Làm mới danh sách"}
                                    </a>
                                </div>
                            </ul>
                            <div className="flex mt-24 space-between">
                                <div className="flex">
                                    <input
                                        name='checkAll'
                                        type='checkbox'
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                        onChange={(event) => checkAllItem(event)}
                                        checked={
                                            isTypeCustomField == "CUSTOMER"
                                                ?
                                                selectedCustomField.length == (dataCustomfields.length - currentDataCustomFieldCustomer.length)
                                                :
                                                selectedCustomField.length == (dataCustomfields.length - currentDataCustomFieldOrder.length)
                                        }
                                    />
                                    <span>Chọn tất cả</span>
                                </div>
                                <div className="btn-add-custom-field">
                                    <a className="flex" onClick={() => window.open("/settings?is_new=true#CUSTOM_FIELD")}>
                                        <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg" className="mr-8" />
                                        {"Tạo mới trường tùy chỉnh"}
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="ladiui form-group">
                            <DropdownCheckboxSearch
                                data={dataCustomfields || []}
                                _key='name'
                                _value='value'
                                pickTitle={'Tiêu đề'}
                                placeHolder={'Chọn trường tùy chỉnh'}
                                onSubmit={(items) => {
                                    if (items && items.length > 0) {
                                        setSelectedCustomfield(items)
                                    }
                                }}
                                innerClassName='w100 position-initial'
                                styleCustomPeople={{ maxHeight: '450px', overflow: 'auto' }}
                                buttonStyle={{
                                    width: '100%',
                                    paddingRight: '30px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: ' -webkit-box',
                                    webkitLineClamp: 1,
                                    webkitBoxOrient: 'vertical',
                                    textAlign: 'left',
                                }}
                                renderHeaders={() => (
                                    <>
                                        <div className="btn-add-custom-field pl-16">
                                            <a className="flex" onClick={() => window.open("/settings?is_new=true#CUSTOM_FIELD")}>
                                                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg" className="mr-8" />
                                                {"Tạo mới trường tùy chỉnh"}
                                            </a>
                                        </div>
                                        <div onClick={() => handleRefreshCustomfield()}>
                                            <a className={`ladiui border-none btn-add-option ml-16 mt-16`} style={{ color: "initial" }}>
                                                <i className='ladi-icon icon-sync mr-8' />
                                                {"Làm mới danh sách"}
                                            </a>
                                        </div>
                                    </>
                                )}
                                selectedItems={selectedCustomField || []}
                                handleSearchValue={handleSearchValue}
                                isShowBlockInputSearch={true}
                                isCreateCategory={true}
                                idBlock="dropdown-choose-customer"
                            />
                        </div> */}

                    </div>

                    <div className="ladiui modal-footer">
                        <div className="ladiui footer-modal-btn">
                            <button
                                type="button"
                                data-dismiss="modal"
                                data-target="add-product"
                                className="ladiui btn btn-secondary bold"
                                onClick={eventCancel}
                            >
                                {t("ACTIONS.CANCEL")}
                            </button>
                            <button
                                type="button"
                                data-dismiss="modal"
                                data-target="add-product"
                                className="ladiui btn btn-primary bold ml-16"
                                onClick={(e) => handleAddCustomfield(e)}
                            >
                                {t("ACTIONS.ADD")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(ModalAddCustomField);
