import React from "react";
import { DateRangePicker } from "react-dates";
import { omit } from "lodash";
import i18n from "../i18n";
import "react-dates/lib/css/_datepicker.css";
import "../assets/css/ladi_daterangepicker.css";
import baseHelper from "../helpers/BaseHelper";

class OrderDateRangePicker extends React.Component {
  constructor(props) {
    super(props);

    this.moment = baseHelper.getMoment();
    this.last7Day = this.moment().subtract(7, "day");
    this.last30Day = this.moment().subtract(30, "day");
    this.firstDayOfMonth = this.moment().startOf("month");
    this.firstDayOfLastMonth = this.moment().subtract(1, "month").startOf("month");
    this.lastDayOfLastMonth = this.moment().subtract(1, "month").endOf("month");

    this.presets = [
      {
        text: i18n.t("DATE.YESTERDAY"),
        start: this.moment().subtract(1, "day"),
        end: this.moment().subtract(1, "day"),
        all: false,
      },
      {
        text: i18n.t("DATE.TODAY"),
        start: this.moment(),
        end: this.moment(),
        all: false,
      },
      {
        text: i18n.t("DATE.7DAY"),
        start: this.last7Day,
        end: this.moment(),
        all: false,
      },
      {
        text: i18n.t("DATE.30DAY"),
        start: this.last30Day,
        end: this.moment(),
        all: false,
      },
      {
        text: i18n.t("DATE.THIS_MONTH"),
        start: this.firstDayOfMonth,
        end: this.moment(),
        all: false,
      },
      {
        text: i18n.t("DATE.LAST_MONTH"),
        start: this.firstDayOfLastMonth,
        end: this.lastDayOfLastMonth,
        all: false,
      },
      // {
      //   text: i18n.t("DATE.ALL_TIME"),
      //   start: null,
      //   end: null,
      //   all: true,
      // },
    ];
  }

  isSameDay = (a, b) => {
    if (!this.moment.isMoment(a) || !this.moment.isMoment(b)) return false;
    // Compare least significant, most likely to change units first
    // Moment's isSame clones moment inputs and is a tad slow
    return a.date() === b.date() && a.month() === b.month() && a.year() === b.year();
  };

  renderDatePresets = () => {
    const { startDate, endDate } = this.props;

    return (
      <div className="custom-btn-date-panel">
        {this.presets.map(({ text, start, end, all }) => {
          const isSelected = (this.isSameDay(start, startDate) && this.isSameDay(end, endDate)) || (!startDate && !endDate && all);
          return (
            <button
              key={text}
              type="button"
              className={`custom-btn-date ${isSelected ? "selected" : ""}`}
              onClick={() => this.props.onDatesChange({ startDate: start, endDate: end })}
            >
              {text}
            </button>
          );
        })}
      </div>
    );
  };

  render() {
    // autoFocus, autoFocusEndDate, initialStartDate and initialEndDate are helper props for the
    // example wrapper but are not props on the SingleDatePicker itself and
    // thus, have to be omitted.
    const props = omit(this.props, ["autoFocus", "autoFocusEndDate", "initialStartDate", "initialEndDate", "presets"]);

    const isAllTime = !this.props.startDate && !this.props.endDate;

    const styleAllTime = {
      position: "absolute",
      zIndex: "1",
      background: "#fff",
      width: "210px",
      top: "1px",
      height: "34px",
      left: "1px",
      lineHeight: "34px",
      paddingLeft: "15px",
    };

    return (
      <div style={{ position: "relative" }}>
        {isAllTime ? (
          <div style={styleAllTime}>
            {i18n.t("DATE.CHOOSE_DATE")}
            <i className="ladiui icon icon-calendar-date"></i>
          </div>
        ) : (
          ""
        )}
        <DateRangePicker {...props} renderCalendarInfo={this.renderDatePresets} />
      </div>
    );
  }
}

export default OrderDateRangePicker;
