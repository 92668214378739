import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import LoadingTable from "../../../components/LoadingTable";
import { useTranslation } from "react-i18next";
import { configPie } from '../../../config/chartconfig';
import baseHelper from "../../../helpers/BaseHelper";
import { useDispatch, useSelector } from "react-redux";

function UtmSource(props) {
  const { data } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const storeReducer = useSelector((state) => state.store);

  const getOption = () => {
    if (!data || data.length === 0) return [];

    let seriesdata = [];

    for (const item of data) {
      if (!item.refund)
        item.refund = 0;

      if (!item.utm_name) {
        item.utm_name = t("REPORTS.UTM.NULL")
      }

      if (seriesdata.length < 9) {
        seriesdata.push({
          name: item.utm_name,
          y: item.total - item.refund,
          num_order: item.num_order
        })
      } else if (seriesdata.length === 9) {
        seriesdata.push({
          name: t("REPORTS.UTM.ORTHER"),
          y: item.total - item.refund,
          num_order: item.num_order
        })
      } else {
        seriesdata[9].y += item.total - item.refund
        seriesdata[9].num_order += item.num_order
      }
    }

    const options = { ...configPie };

    options.series = [
      {
        colorByPoint: true,
        data: seriesdata,
      },
    ]

    options.tooltip = {
      shared: true,
      backgroundColor: "#061438",
      borderColor: "#061438",
      borderRadius: 3,
      style: {
        color: "#ffffff",
      },
      useHTML: true,
      formatter: function () {
        let text = ``;
        text += `
        <br/>
        <div class="text-tooltip">  
          <div class="point-tolltip" style="background:${this.point.color}"></div> 
          <div>
            ${t("REPORTS.UTM.TABLE.REVENUE")}: ${baseHelper.formatMoneyPostFix(this.point.y, storeReducer.userInfo.currentStore.currency_symbol)}
          </div> 
        </div>
        <br/>
        <div class="text-tooltip">  
        <div class="point-tolltip" style="background:${this.point.color}"></div> 
        <div>
          ${t("REPORTS.UTM.TABLE.NUM")}: ${baseHelper.formatNumber(this.point.num_order)}
        </div> 
      </div>
        `
        return (
          `${this.point.name}
          <br/>
          ${text}`
        );
      },
    };

    return options;
  };

  return (
    <div className="chart-item revenue-stage-chart">
      {/* <div className="head-card">
        <i className="ladi-icon icon-orders"></i>
        <h3 className="mr-l-5">{t("REPORTS.UTMSOURCETITLE")}</h3>
      </div> */}
      <div className="content-chart">{!isLoading ? <HighchartsReact highcharts={Highcharts} options={getOption()} /> : <LoadingTable />}</div>
    </div>
  );
}

export default UtmSource;
