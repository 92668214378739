import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import baseHelper from '../../../../helpers/BaseHelper';
import Image from '../../../../components/Image';
import { Editor } from '@tinymce/tinymce-react';
import appConfig from '../../../../config/app';

import ModalAddDiscount from './ModalAddDiscount';
// eslint-disable-next-line max-lines-per-function
function ModalAddDescription(props) {
    const { t, onClose, isShow, selectedValue, handleAddContentLocation, dataSelect, selectedIndex } = props;
    const wrapperRef = useRef(null);

    const [dataConfig, setDataConfig] = useState({
        title: 'Mô tả cửa hàng',
        value: '',
    });

    useEffect(() => {
        if (dataSelect) {
            setDataConfig({
                title: dataSelect.title || dataConfig.title,
                value: dataSelect.value || '',
            });
        }
    }, [dataSelect]);

    // useEffect(() => {
    //   function handleClickOutside(event) {
    //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
    //       onClose(false);
    //     }
    //   }
    //   document.addEventListener("mousedown", handleClickOutside);
    //   return () => {
    //     document.removeEventListener("mousedown", handleClickOutside);
    //   };
    // }, [wrapperRef]);

    const dispatch = useDispatch();

    const eventCancel = () => {
        onClose(false);
    };

    const onChangeTitle = (e) => {
        let { value } = e.target;

        setDataConfig({
            ...dataConfig,
            title: value,
        });
    };

    const handleAddContentDescription = () => {
        let data = {
            title: dataConfig.title,
            type: 'DESCRIPTION',
            value: dataConfig.value,
        };
        handleAddContentLocation(data, selectedIndex);
    };

    const onChangeContentEditor = (_value) => {
        setDataConfig({
            ...dataConfig,
            value: _value,
        });
    };

    return (
        <>
            <div className={`ladiui modal ${isShow ? 'show' : ''}`} id='homepage-description-modal'>
                <div className='ladiui modal-dialog modal-dialog-centered w-600' ref={wrapperRef}>
                    <div className='ladiui modal-content '>
                        <div className='ladiui modal-header '>
                            <h2 className='ladiui modal-title '>Đoạn nội dung</h2>
                            <button data-dismiss='modal' data-target='add-product' onClick={eventCancel} className='ladiui modal-close'>
                                <i className='ladiui icon icon-ldp-close'></i>
                            </button>
                        </div>
                        <div className='ladiui modal-body'>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>Tiêu đề</label>
                                <input
                                    className='ladiui form-control'
                                    value={dataConfig.title}
                                    placeholder='Nhập vào tiêu đề'
                                    onChange={onChangeTitle}
                                />
                            </div>
                            <div className='ladiui form-group mb-0'>
                                <label className='ladiui-label'>Đoạn văn bản</label>
                                <Editor
                                    tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                                    licenseKey="gpl"
                                    value={dataConfig.value}
                                    init={{
                                        height: 250,
                                        plugins: 'preview fullpage autosave visualblocks fullscreen table lists link image code paste',
                                        paste_as_text: true,
                                        extended_valid_elements: 'a[data|href|class|style|rel|target]',
                                        toolbar:
                                            'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image |  numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat',
                                        toolbar_drawer: 'sliding',
                                        // language_url: '../../../../language/tinymce-vi',
                                        // valid_elements: '*[*]',
                                    }}
                                    onEditorChange={onChangeContentEditor}
                                />
                            </div>
                        </div>

                        <div className='ladiui modal-footer '>
                            <div className='ladiui footer-modal-btn'>
                                <button type='button' className='ladiui btn btn-secondary bold' onClick={eventCancel}>
                                    {t('ACTIONS.CLOSE')}
                                </button>
                                <button type='button' className='ladiui btn btn-primary' onClick={() => handleAddContentDescription()}>
                                    {selectedIndex >= 0 ? 'Cập nhật' : 'Thêm nội dung'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withTranslation()(ModalAddDescription);
