import React from 'react';
import routes from '../../routes';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { withTranslation } from 'react-i18next';

import appConfig from '../../config/app';
import authActions from '../../redux/futures/auth/actions';
import SidebarItem from '../../components/SidebarItem';
import baseHelper from '../../helpers/BaseHelper';

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.infoSectionRef = React.createRef();
        this.infoContentRef = React.createRef();
    }

    changeStore = (storeID) => {
        baseHelper.setCookie(appConfig.COOKIE.STORE_ID, storeID);
        window.location.href = '/';
    };

    signOut = () => {
        this.props.signOut();
    };

    render() {
        const { scopes, isDomainIPay, userInfo } = this.props;

        const availableItems = routes.getItemByScopes(scopes);

        return (
            <aside className='ladiui aside'>
                <nav className='ladiui nav'>
                    <ul className='ladiui menu'>
                        <li className='ladiui logo-item logo'>
                            <a href='#' className='ladiui flex-row-menu-item'>
                                {isDomainIPay ? (
                                    <img src='https://w.ladicdn.com/ladisales/ipay-icon.svg' alt='' style={{ height: '32px' }} />
                                ) : (
                                    <img src='https://w.ladicdn.com/ladiui/ladisales/icons/logo-ladisales.svg' alt='' />
                                )}
                            </a>
                        </li>
                        <li className='ladiui logo-item logo-hover'>
                            <a href='#' className='ladiui flex-row-menu-item'>
                                {isDomainIPay ? (
                                    <img
                                        src='https://w.ladicdn.com/ladisales/ipay.svg'
                                        alt=''
                                        style={{ height: '32px', backgroundColor: '#f1f3f4' }}
                                    />
                                ) : (
                                    <img src='https://w.ladicdn.com/ladiui/ladisales/icons/logo-ladisales-fw.svg' alt='' />
                                )}
                            </a>
                        </li>
                        {map(availableItems, (item, index) => {
                            if (!userInfo.is_show_course && index == '/courses') {
                                return null;
                            }
                            return (
                                <SidebarItem
                                    keyStr={item.keyStr}
                                    key={item.key}
                                    classLi={item.classLi}
                                    to={item.to}
                                    classNav={item.classNav}
                                    classIcon={item.classIcon}
                                    title={item.title}
                                    children={item.children}
                                    userEmail={userInfo ? userInfo.email : ''}
                                />
                            );
                            // if (!item.key.includes("courses")) {
                            //   return (
                            //     <SidebarItem
                            //       keyStr={item.keyStr}
                            //       key={item.key}
                            //       classLi={item.classLi}
                            //       to={item.to}
                            //       classNav={item.classNav}
                            //       classIcon={item.classIcon}
                            //       title={item.title}
                            //       children={item.children}
                            //       userEmail={userInfo ? userInfo.email : ""}
                            //     />
                            //   )
                            // } else {
                            //   if (userInfo && userInfo.email && appConfig.WHITE_LIST_EMAIL.includes(userInfo.email)) {
                            //     return (
                            //       <SidebarItem
                            //         keyStr={item.keyStr}
                            //         key={item.key}
                            //         classLi={item.classLi}
                            //         to={item.to}
                            //         classNav={item.classNav}
                            //         classIcon={item.classIcon}
                            //         title={item.title}
                            //         children={item.children}
                            //         userEmail={userInfo ? userInfo.email : ""}
                            //       />
                            //     )
                            //   }
                            // }
                        })}
                    </ul>
                </nav>
            </aside>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch(authActions.signOut()),
});

const mapStateToProps = (state) => ({
    setting: { ...state.setting },
    auth: { ...state.auth },
    staff: { ...state.staff },
});

// export default withTranslation()(Sidebar);
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Sidebar));
