import { REQUEST_METHOD } from "../../middlewares/services";

import * as types from "./types";
import { endpoint } from "../../../config/app";

const createImages = (productImages) => ({
  type: types.PRODUCT_IMAGE_CREATE,
  meta: {
    endpoint: endpoint.PRODUCT_IMAGE_CREATE,
    method: REQUEST_METHOD.POST,
    body: {
      product_image: productImages,
    },
    hasAuth: true,
  },
});

const deleteImage = (productID, srcHash) => {
  const product_image = {
    product_id: productID,
    src_hash: srcHash,
  };
  return {
    type: types.PRODUCT_IMAGE_DELETE,
    meta: {
      endpoint: endpoint.PRODUCT_IMAGE_DELETE,
      method: REQUEST_METHOD.POST,
      body: {
        product_image,
      },
      hasAuth: true,
    },
  };
};

const updatePosition = (data) => {
  return {
    type: types.PRODUCT_UPDATE_POSITION,
    meta: {
      endpoint: endpoint.PRODUCT_UPDATE_POSITION,
      method: REQUEST_METHOD.POST,
      body: {
        ...data,
      },
      hasAuth: true,
    },
  };
};

export default {
  delete: deleteImage,
  create: createImages,
  updatePosition,
};
