import React from "react";

import { connect } from "react-redux";

import * as inventoryTypes from "../../redux/futures/inventory/types";
import inventoryActions from "../../redux/futures/inventory/actions";

import { includes, map, remove, cloneDeep, forEach, find, cond } from "lodash";
import appConfig from "../../config/app";
import Pagination from "react-js-pagination";
import baseHelper from "../../helpers/BaseHelper";

import LoadingTable from "../../components/LoadingTable";
import { withTranslation } from "react-i18next";
import LoadingScene from "../../components/LoadingScene";
import { appLocalStorage } from "../../localforage";
import NumberInput from "../../components/NumberInput";
import Modal from "../../components/Modal";
import LadiPagination from "../../components/LadiPagination";
import LadiDropdownMenu from "../../components/LadiDropdownMenu";
class Inventory extends React.Component {
  constructor(props) {
    super(props);
    const defaultConditions = {
      page: 1,
      limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
    };
    this.state = {
      activePage: 1,
      selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
      userInfo: {},
      searchProductName: "",
      variants: [],
      isShowUpdateBatchModal: false,
      adjustBatch: 0,
      modeBatch: appConfig.INVENTORY_UPDATE_MODE.ADD,
      selectedVariants: [],
      conditions: defaultConditions,
      selectedIDs: [],
    };

    // this.selectedIDs = [];
  }

  componentWillMount() {
    appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
      this.setState({
        userInfo: result,
      });
    });
  }

  componentDidMount() {
    this.listVariant();
  }

  componentDidUpdate() {
    window.LadiUI.init();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props === nextProps) {
      return;
    }

    if (this.props.inventory.action != nextProps.inventory.action) {
      if (includes([inventoryTypes.INVENTORY_LIST_VARIANT], nextProps.inventory.action)) {
        if (nextProps.inventory.status) {
          const variants = cloneDeep(nextProps.inventory.variants);
          forEach(variants, (item) => {
            item.mode = appConfig.INVENTORY_UPDATE_MODE.ADD;
            item.adjust = 0;
          });
          this.setState({
            variants,
          });
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.inventory.message, "OK");
        }
      }

      if (includes([inventoryTypes.INVENTORY_UPDATES_QUANTITY, inventoryTypes.INVENTORY_UPDATE_QUANTITY], nextProps.inventory.action)) {
        if (nextProps.inventory.status) {
          window.LadiUI.toastCustom("success", "", nextProps.inventory.message);
          this.setState({
            isShowUpdateBatchModal: false,
          });
          this.listVariant();
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.inventory.message, "OK");
        }
      }
    }
  }

  /**
   * list variant
   */
  listVariant = (resetActivePageFlag = true) => {
    if (document.getElementById("checkAllItem")) {
      document.getElementById("checkAllItem").checked = false;
      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = false;
      });
    }

    const { searchProductName, selectedLimit, conditions } = this.state;
    let activePage = conditions.page;
    if (resetActivePageFlag) {
      activePage = 1;
      conditions.page = 1;

      this.setState({
        conditions: conditions,
      });
    }

    // Reset selectedIDs
    this.selectedIDs = [];

    const data = {
      search: {
        name: searchProductName,
      },
      paged: activePage,
      limit: conditions.limit,
      sort: this.sortBy,
    };

    this.props.listVariant(data);
  };

  /*******************************SEARCH***************************/
  onChangeInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  /**
   * Tim kiem theo name
   */
  onKeyDownInput = (event) => {
    if (event.key === "Enter") {
      this.listVariant();
    }
  };

  /****************************************************************/

  /*******************************PAGINATION***************************/
  /**
   * Change limit
   */
  onChangeLimit = (option) => {
    let { conditions } = this.state;
    conditions.limit = option.value;
    this.setState({ conditions: conditions }, () => {
      this.listVariant();
    });
  };

  /**
   * Change page
   */
  onPageChange = (pageNumber) => {
    let { conditions } = this.state;
    conditions.page = pageNumber;
    this.setState({ conditions: conditions }, () => {
      this.listVariant(false);
    });
  };
  /****************************************************************/

  /*******************************CHECK AND ACTIONS***************************/
  checkAllItem = (event) => {
    const { checked } = event.target;

    let selectedIDsTg = [];
    if (checked) {
      selectedIDsTg = map(this.props.inventory.variants, (item) => item.product_variant_id);

      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = true;
      });
    } else {
      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = false;
      });
    }

    this.setState({
      selectedIDs: selectedIDsTg,
    });
  };

  checkItem = (event, variantID) => {
    const { checked } = event.target;
    const { selectedIDs } = this.state;

    if (checked) {
      if (!includes(selectedIDs, variantID)) {
        selectedIDs.push(variantID);
      }

      if (selectedIDs.length == this.props.inventory.variants.length) {
        document.getElementById("checkAllItem").checked = true;
      }
    } else {
      document.getElementById("checkAllItem").checked = false;
      remove(selectedIDs, (item) => item == variantID);
    }

    this.setState({
      selectedIDs,
    });
  };

  sort = (event) => {
    const { classList } = event.target;
    const name = event.target.getAttribute("name");

    window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui table .sorting"), (item) => {
      if (item.getAttribute("name") != name) {
        item.classList.remove("up");
      }
    });

    this.sortBy = {};

    // Change arrow up and down
    if (includes(classList, "up")) {
      classList.remove("up");
      this.sortBy[name] = "ASC";
    } else {
      classList.add("up");
      this.sortBy[name] = "DESC";
    }

    this.setState({}, () => this.listVariant());
  };

  onChangeAdjustInput = (event, index) => {
    const { variants } = this.state;
    const { value } = event.target;

    const variant = variants[index];
    variant.adjust = value;

    this.adjust(variant);
    this.setState({
      variants,
    });
  };

  adjust = (variant) => {
    switch (variant.mode) {
      case appConfig.INVENTORY_UPDATE_MODE.ADD:
        variant.after_adjust = baseHelper.parseInt(variant.quantity) + baseHelper.parseInt(variant.adjust);
        break;
      case appConfig.INVENTORY_UPDATE_MODE.SET:
        variant.after_adjust = baseHelper.parseInt(variant.adjust);
        break;
    }
  };

  onChangeMode = (mode, index) => {
    const { variants } = this.state;

    const variant = variants[index];
    variant.mode = mode;

    this.adjust(variant);

    this.setState({
      variants,
    });
  };

  onChangeModeBatch = (mode) => {
    this.setState(
      {
        modeBatch: mode,
      },
      () => {
        this.adjustBatch();
      }
    );
  };

  adjustBatch = () => {
    const { selectedVariants } = this.state;
    switch (this.state.modeBatch) {
      case appConfig.INVENTORY_UPDATE_MODE.ADD:
        forEach(selectedVariants, (item) => {
          item.after_adjust = baseHelper.parseInt(item.quantity) + baseHelper.parseInt(this.state.adjustBatch);
        });
        break;
      case appConfig.INVENTORY_UPDATE_MODE.SET:
        forEach(selectedVariants, (item) => {
          item.after_adjust = baseHelper.parseInt(this.state.adjustBatch);
        });
        break;
    }

    this.setState({
      selectedVariants,
    });
  };

  updateQuantity = (index) => {
    const { variants } = this.state;

    const variant = variants[index];

    this.props.updateQuantity({
      product_id: variant.product_id,
      product_variant_id: variant.product_variant_id,
      mode: variant.mode,
      val: variant.adjust,
    });
  };

  updatesQuantity = () => {
    const data = {
      mode: this.state.modeBatch,
      val: this.state.adjustBatch,
      product_variant_ids: this.state.selectedIDs,
    };

    this.props.updatesQuantity(data);
  };

  openAdjustBatchModal = () => {
    let { selectedIDs } = this.state;
    if (selectedIDs.length <= 0) {
      window.LadiUI.toastCustom("danger", "", this.props.t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
      return;
    }

    const selectedVariants = [];

    forEach(selectedIDs, (item) => {
      const matched = find(this.state.variants, (_item) => _item.product_variant_id == item);

      selectedVariants.push({
        product_variant_id: matched.product_variant_id,
        name: matched.name,
        quantity: matched.quantity,
      });
    });

    this.setState(
      {
        selectedVariants,
      },
      () => {
        this.setState({
          isShowUpdateBatchModal: true,
        });
      }
    );
  };

  eventSelectAction = (action) => {
    switch (action) {
      case "UPDATE_BATCH":
        this.openAdjustBatchModal();
        break;
      default:
    }
  };

  /****************************************************************/
  render() {
    const { t } = this.props;
    const { totalRecord, totalPage } = this.props.inventory;
    const { activePage, selectedLimit, searchProductName, userInfo, conditions } = this.state;
    let fromItem = 0;
    let toItem = 0;
    if ((this.props.inventory.variants || []).length > 0) {
      fromItem = (conditions.page - 1) * conditions.limit + 1;
      toItem = fromItem + this.props.inventory.variants.length - 1;
    }

    const msgShowItem = `${t("PAGINATION.SHOW")} ${fromItem} ${t("PAGINATION.TO")} ${toItem} ${t(
      "PAGINATION.OF"
    )} ${baseHelper.formatNumber(totalRecord)} ${t("PAGINATION.ITEMS")}`;

    const isLoadingData = this.props.inventory.loading && this.props.inventory.waiting == inventoryTypes.INVENTORY_LIST_VARIANT;
    const isLoadingUpdate =
      this.props.inventory.loading && includes([inventoryTypes.INVENTORY_UPDATE_QUANTITY], this.props.inventory.waiting);
    const isLoadingUpdates =
      this.props.inventory.loading && includes([inventoryTypes.INVENTORY_UPDATES_QUANTITY], this.props.inventory.waiting);
    const isLoadingReload = this.props.inventory.loading && inventoryTypes.INVENTORY_RELOAD == this.props.inventory.waiting;

    const listAction = [{ value: "UPDATE_BATCH", name: t("INVENTORY.UPDATE_BATCH") }];

    return (
      <div className="page-content">
        {(isLoadingReload || isLoadingUpdate) && <LoadingScene blur={true} />}
        <div>
          <div className="ladiui-table-actions" style={{ justifyContent: "flex-start" }}>
            <div className="action-item">
              <div className="ladiui search-keyword">
                <input
                  type="text"
                  id="search"
                  placeholder={t("PRODUCTS.SEARCH_PRODUCT")}
                  name="searchProductName"
                  value={searchProductName}
                  onChange={this.onChangeInput}
                  onKeyDown={this.onKeyDownInput}
                  className="ladiui search-field dropdown-toggle form-control search-width"
                />
                <i className="ladi-icon icon-zoom" onClick={this.listVariant} />
              </div>
            </div>
          </div>
          {isLoadingData ? (
            <LoadingTable />
          ) : (
            <div>
              <div className="ladi-card">
                <table className={`ladiui table text-left ${isLoadingData ? "loader" : ""}`}>
                  <thead style={{ position: "relative" }}>
                    <tr className="ladiui table-vertical header">
                      <th scope="col" className="ladiui checkall">
                        <input
                          id="checkAllItem"
                          onClick={this.checkAllItem}
                          type="checkbox"
                          className="ladiui-checkall ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle"
                        />
                      </th>
                      <th scope="col">
                        {this.state.selectedIDs.length <= 0 ? (
                          t("INVENTORY.VARIANT")
                        ) : (
                          <LadiDropdownMenu
                            labelDropdown={"Chọn hành động"}
                            listProp={listAction}
                            defaultTextProp={t("ACTIONS.TITLE")}
                            hideLabel={true}
                            cbProp={this.eventSelectAction}
                            classSize="btn-sm "
                            classWidth="w-180"
                            classDropdown="w-180 action-record-table"
                          />
                        )}
                      </th>
                      <th scope="col">{t("PRODUCTS.SKU")}</th>
                      <th scope="col">{t("PRODUCTS.PRICE")}</th>
                      <th scope="col" style={{ minWidth: 120 }}>
                        {t("COMMON.QUANTITY")}
                      </th>
                      <th className="text-right" scope="col">
                        {t("INVENTORY.UPDATE_QUANTITY")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(this.state.variants, (item, index) => {
                      return (
                        <tr key={item.product_variant_id} className="ladiui table-vertical main">
                          <td>
                            <input
                              type="checkbox"
                              onClick={(event) => this.checkItem(event, item.product_variant_id)}
                              className="ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle"
                            />
                          </td>
                          <td>
                            <a href={baseHelper.getUrlVariant(item)}>{item.name}</a>
                          </td>
                          <td>{item.sku}</td>
                          <td>{baseHelper.formatMoneyPostFix(item.price, userInfo.currency_symbol)}</td>
                          <td>
                            {item.quantity}
                            {(item.adjust != 0 || (item.adjust == 0 && item.mode == appConfig.INVENTORY_UPDATE_MODE.SET)) && (
                              <i className="ladi-icon icon icon-triangle-right inventory-adjust-triagle" />
                            )}
                            {(item.adjust != 0 || (item.adjust == 0 && item.mode == appConfig.INVENTORY_UPDATE_MODE.SET)) &&
                              item.after_adjust}
                          </td>
                          <td className="text-right">
                            <div className="group-adjust-quantity flex" style={{ justifyContent: "flex-end" }}>
                              <button
                                onClick={() => {
                                  this.onChangeMode(appConfig.INVENTORY_UPDATE_MODE.ADD, index);
                                }}
                                className={`ladiui btn btn-secondary mode remove-right ${
                                  item.mode == appConfig.INVENTORY_UPDATE_MODE.ADD ? "active" : ""
                                }`}
                              >
                                {t("INVENTORY_UPDATE_MODE.ADD")}
                              </button>
                              <button
                                onClick={() => {
                                  this.onChangeMode(appConfig.INVENTORY_UPDATE_MODE.SET, index);
                                }}
                                className={`ladiui btn btn-secondary mode remove-right ${
                                  item.mode == appConfig.INVENTORY_UPDATE_MODE.SET ? "active" : ""
                                }`}
                              >
                                {t("INVENTORY_UPDATE_MODE.SET")}
                              </button>
                              <NumberInput
                                className="remove-right h-36"
                                value={item.adjust}
                                onChange={(event) => {
                                  this.onChangeAdjustInput(event, index);
                                }}
                                onlyNumber={true}
                                thousandSeparator={false}
                                decimalScale={0}
                                allowNegative={true}
                              />
                              <button
                                disabled={item.adjust == 0 && item.mode == appConfig.INVENTORY_UPDATE_MODE.ADD}
                                onClick={() => this.updateQuantity(index)}
                                className="item ladiui btn btn-primary inline-block"
                              >
                                {t("ACTIONS.SAVE")}
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                    {totalRecord <= 0 && (
                      <tr className="text-center">
                        <td colSpan="100%">{t("NO_DATA")}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {totalRecord > 0 && (
                <div className="ladiui pagination-block">
                  <LadiPagination
                    conditionsProp={conditions}
                    listName={t("PAGINATION.ITEMS")}
                    message={""}
                    items={this.props.inventory.variants}
                    total={totalRecord}
                    limitAction={this.onChangeLimit}
                    pageAction={this.onPageChange}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        {this.state.isShowUpdateBatchModal && (
          <Modal
            id="modal-update-batch"
            title={t("INVENTORY.MSG_UPDATE_BATCH_TITLE", this.state.selectedVariants.length)}
            onCancel={() => {
              this.setState({
                isShowUpdateBatchModal: false,
              });
            }}
            onOk={this.updatesQuantity}
            visible={this.state.isShowUpdateBatchModal}
            isLoading={isLoadingUpdates}
            disableOk={this.state.adjustBatch == 0 && this.state.modeBatch == appConfig.INVENTORY_UPDATE_MODE.ADD}
          >
            <div>
              <div className="group-adjust-quantity flex">
                <button
                  onClick={() => {
                    this.onChangeModeBatch(appConfig.INVENTORY_UPDATE_MODE.ADD);
                  }}
                  className={`ladiui btn btn-secondary mode remove-right ${
                    this.state.modeBatch == appConfig.INVENTORY_UPDATE_MODE.ADD ? "active" : ""
                  }`}
                >
                  {t("INVENTORY_UPDATE_MODE.ADD")}
                </button>
                <button
                  onClick={() => {
                    this.onChangeModeBatch(appConfig.INVENTORY_UPDATE_MODE.SET);
                  }}
                  className={`ladiui btn btn-secondary mode remove-right ${
                    this.state.modeBatch == appConfig.INVENTORY_UPDATE_MODE.SET ? "active" : ""
                  }`}
                >
                  {t("INVENTORY_UPDATE_MODE.SET")}
                </button>
                <NumberInput
                  value={this.state.adjustBatch}
                  onChange={(event) => {
                    this.setState(
                      {
                        adjustBatch: event.target.value,
                      },
                      () => {
                        this.adjustBatch();
                      }
                    );
                  }}
                  onlyNumber={true}
                  thousandSeparator={false}
                  decimalScale={0}
                  allowNegative={true}
                />
              </div>
            </div>

            <div className="block-adjustBatch" style={{ marginTop: 25 }}>
              <ul className="list">
                {map(this.state.selectedVariants, (item, index) => {
                  return (
                    <li className="item flex space-between" key={index}>
                      <span style={{ maxWidth: "300px" }}>{item.name}</span>
                      <span className="text-center">
                        {item.quantity}
                        {(this.state.adjustBatch != 0 ||
                          (this.state.adjustBatch == 0 && this.state.modeBatch == appConfig.INVENTORY_UPDATE_MODE.SET)) && (
                          <i className="ladi-icon icon icon-triangle-right inventory-adjust-triagle" />
                        )}
                        {(this.state.adjustBatch != 0 ||
                          (this.state.adjustBatch == 0 && this.state.modeBatch == appConfig.INVENTORY_UPDATE_MODE.SET)) &&
                          item.after_adjust}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listVariant: (data) => dispatch(inventoryActions.listVariant(data)),
    updateQuantity: (data) => dispatch(inventoryActions.updateQuantity(data)),
    updatesQuantity: (data) => dispatch(inventoryActions.updatesQuantity(data)),
    reload: (data) => dispatch(inventoryActions.reload(data)),
  };
};

const mapStateToProps = (state) => ({
  inventory: { ...state.inventory },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Inventory));
