import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import SelectOption from "../../../../components/SelectOption";
import appConfig from "../../../../config/app";
import Input from "../../../../components/Input";
import LoadingSence from "../../../../components/LoadingScene";
import { includes } from "lodash";
import baseHelper from "../../../../helpers/BaseHelper";

import shippingActions from "../../../../redux/futures/shipping/actions";
import * as shippingTypes from "../../../../redux/futures/shipping/types";

class VNPostForm extends React.Component {
  static propTypes = {
    orderID: PropTypes.number,
    weight: PropTypes.any,
    storeAddressID: PropTypes.any,
    codAmount: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedService: {},
      selectedIsPackageViewable: true,
      selectedPickupType: appConfig.VNPOST.PICK_TYPE.PICKUP.CODE,
      width: 0,
      height: 0,
      length: 0,
      isReceiverPayFreight: true,
    };

    this.inputsRef = new Set();
  }

  componentDidMount() {
    if (this.props.storeAddressID) {
      this.listServices();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.shippingReducer.action != nextProps.shippingReducer.action) {
      if (includes([shippingTypes.SHIPPING_LIST_SERVICES, shippingTypes.SHIPPING_RESET_SERVICES], nextProps.shippingReducer.action)) {
        if (nextProps.shippingReducer.status) {
          const selectedService = nextProps.shippingReducer.services.length > 0 ? nextProps.shippingReducer.services[0] : {};

          this.onChangeService(selectedService);
        } else {
          this.onChangeService({});
          window.LadiUI.showErrorMessage("Thông báo", nextProps.shippingReducer.message, "OK");
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.storeAddressID != this.props.storeAddressID || prevProps.codAmount != this.props.codAmount) {
      if (this.props.storeAddressID) {
        this.listServices();
      }
    }
  }

  onChangeInput = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  validate = () => {
    const { t, weight, storeAddressID } = this.props;
    if (!storeAddressID) {
      window.LadiUI.toastCustom("danger", "", t("SHIPPINGS.SETUP_AT_LEAST_ONE_HUB"));
      return false;
    }

    if (baseHelper.parseFloat(weight) <= 0) {
      document.getElementById("weight").classList.add("error");
      window.LadiUI.toastCustom("danger", "", t("VALIDATION.GREATER_THAN", t("COMMON.WEIGHT"), "0"));
      return false;
    }

    return true;
  };

  getData = () => {
    return {
      service: this.state.selectedService.code,
      pickup_type: this.state.selectedPickupType,
      is_package_viewable: this.state.selectedIsPackageViewable,
      width: this.state.width,
      height: this.state.height,
      length: this.state.length,
    };
  };

  onChangeService = (service) => {
    this.setState({
      selectedService: service,
    });
  };

  listServices = () => {
    this.props.resetServices();
    if (!this.validate()) {
      return;
    }

    const data = {
      order_id: this.props.orderID,
      shipping_partner_code: appConfig.SHIPPING_PARTNERS.VNPOST.CODE,
      store_address_id: this.props.storeAddressID,
      weight: this.props.weight,
      height: this.state.height,
      length: this.state.length,
      width: this.state.width,
      cod_amount: this.props.codAmount,
    };

    this.props.listServices(data);
  };

  render() {
    const { t, storeAddressID } = this.props;
    const { services } = this.props.shippingReducer;
    const { selectedService, isReceiverPayFreight, selectedIsPackageViewable, selectedPickupType, width, height, length } = this.state;
    const loadingData =
      this.props.shippingReducer.loading && includes([shippingTypes.SHIPPING_LIST_SERVICES], this.props.shippingReducer.waiting);
    return (
      <div>
        {loadingData && <LoadingSence blur={true} />}
        <div className="ladiui-row">
          <div className="ladiui form-group size">
            <label className="ladiui-label">{t("SHIPPINGS.GHN_SERVICE")}</label>
            <SelectOption
              wrapperStyle={{ minWidth: 150, zIndex: 6 }}
              items={services}
              _key="code"
              _value="name"
              onSelectItem={(selectedService) => {
                this.setState({
                  selectedService,
                });
              }}
              currentKey={selectedService.code}
            />
          </div>

          <div className="ladiui form-group size">
            <label className="ladiui-label">{t("VNPOST.PICK_TYPE.TITLE")}</label>
            <SelectOption
              wrapperStyle={{ minWidth: 150 }}
              items={appConfig.VNPOST.PICK_TYPE}
              _key="CODE"
              _value="NAME"
              onSelectItem={(item) => {
                this.setState({
                  selectedPickupType: item.CODE,
                });
              }}
              currentKey={selectedPickupType}
            />
          </div>
        </div>

        <div className="ladiui form-group flex">
          <input
            id="selectedIsPackageViewable"
            checked={selectedIsPackageViewable}
            onChange={() => {
              this.setState({
                selectedIsPackageViewable: !selectedIsPackageViewable,
              });
            }}
            type="checkbox"
            className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
          />
          <label className="ladiui label"> {t("SHIPPINGS.ALLOW_VIEW_PACKAGE")}</label>
        </div>

        <div className="ladiui form-group flex">
          <input
            checked={isReceiverPayFreight}
            onChange={() => {
              this.setState({
                isReceiverPayFreight: !this.state.isReceiverPayFreight,
              });
            }}
            type="checkbox"
            className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
          />
          <label className="ladiui label"> {t("SHIPPINGS.IS_BUYER_SHIPPING_FREE")}</label>
        </div>

        <div className="ladiui-row">
          <div className="ladiui form-group size">
            <label className="ladiui-label">{t("SHIPPINGS.WIDTH_SHORTCUT")}</label>
            <Input
              ref={(ref) => this.inputsRef.add(ref)}
              type="number"
              name="width"
              value={width}
              onChange={this.onChangeInput}
              onBlur={() => this.listServices(storeAddressID)}
            />
          </div>

          <div className="ladiui form-group size">
            <label className="ladiui-label">{t("SHIPPINGS.LENGTH_SHORTCUT")}</label>
            <Input
              ref={(ref) => this.inputsRef.add(ref)}
              name="length"
              type="number"
              value={length}
              onChange={this.onChangeInput}
              onBlur={() => this.listServices(storeAddressID)}
            />
          </div>

          <div className="ladiui form-group size">
            <label className="ladiui-label">{t("SHIPPINGS.HEIGHT_SHORTCUT")}</label>
            <Input
              ref={(ref) => this.inputsRef.add(ref)}
              name="height"
              type="number"
              value={height}
              onChange={this.onChangeInput}
              onBlur={() => this.listServices(storeAddressID)}
            />
          </div>
        </div>

        <div className="ladiui form-group">
          <label className="ladiui-label">{t("SHIPPINGS.ESTIMATE_FEE")}</label>
          <Input
            name="fee"
            value={baseHelper.formatMoneyPostFix(selectedService.fee, this.props.storeReducer.userInfo.currentStore.currency_symbol)}
            disabled={true}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listServices: (data) => dispatch(shippingActions.listServices(data)),
    resetServices: () => dispatch(shippingActions.resetServices()),
  };
};

const mapStateToProps = (state) => ({
  shippingReducer: { ...state.shipping },
  storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withTranslation("translation", { withRef: true })(VNPostForm)
);
