import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import LoadingTable from "../../components/LoadingTable";
import { useTranslation } from "react-i18next";
import { configPie } from '../../config/chartconfig';
import baseHelper from "../../helpers/BaseHelper";
import { useDispatch, useSelector } from "react-redux";

function ReportRevenueBySource(props) {
  const { revenue_source } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const storeReducer = useSelector((state) => state.store);

  const getOption = () => {
    if (!revenue_source || revenue_source.length === 0) return [];
    let seriesdata = [];
    seriesdata = revenue_source.map((item) => {
      if (!item.refund)
        item.refund = 0;
      return {
        name: item.source,
        y: item.total - item.refund,
        num_order: item.num_order
      };
    });

    const options = { ...configPie };
    options.series = [
      {
        colorByPoint: true,
        data: seriesdata,
      },
    ]

    options.tooltip = {
      shared: true,
      backgroundColor: "#061438",
      borderColor: "#061438",
      borderRadius: 3,
      style: {
        color: "#ffffff",
      },
      useHTML: true,
      formatter: function () {
        let text = ``;
        text += `
        <br/>
        <div class="text-tooltip">  
          <div class="point-tolltip" style="background:${this.point.color}"></div> 
          <div>
            ${'Doanh thu'}: ${baseHelper.formatMoneyPostFix(this.point.y, storeReducer.userInfo.currentStore.currency_symbol)}
          </div> 
        </div>
        <br/>
        <div class="text-tooltip">  
        <div class="point-tolltip" style="background:${this.point.color}"></div> 
        <div>
          ${'Số lượng'}: ${baseHelper.formatNumber(this.point.num_order)}
        </div> 
      </div>
        `
        return (
          `${this.point.name}
          <br/>
          ${text}`
        );
      },
    };
    return options;
  };

  return (
    <div className="chart-item revenue-stage-chart">
      <div className="head-card">
        <i className="ladi-icon icon-orders"></i>
        <h3 className="mr-l-5">{t("REPORTS.REVENUE_BY_SOURCE")}</h3>
      </div>
      <div className="content-chart">{!isLoading ? <HighchartsReact highcharts={Highcharts} options={getOption()} /> : <LoadingTable />}</div>
    </div>
  );
}

export default ReportRevenueBySource;
