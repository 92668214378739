import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const deleteTag = (orderTagID) => {
    const data = {
        order_tag_id: orderTagID,
    }
    return ({
        type: types.ORDER_TAG_DELETE,
        meta: {
            endpoint: endpoint.ORDER_TAG_DELETE,
            method: REQUEST_METHOD.POST,
            body: {
                order_tag: data
            },
            hasAuth: true,
        }
    })
};

const search = (name) => ({
    type: types.SEARCH_ORDER_TAG,
    meta: {
        endpoint: endpoint.ORDER_TAG_SEARCH,
        method: REQUEST_METHOD.POST,
        body: {
            search: name,
        },
        hasAuth: true,
    }
});

const listAll = () => ({
    type: types.LIST_ALL_ORDER_TAG,
    meta: {
        endpoint: endpoint.ORDER_TAG_LIST_ALL,
        method: REQUEST_METHOD.POST,
        body: {

        },
        hasAuth: true,
    }
});

const list = (data) => ({
    type: types.LIST_ORDER_TAG,
    meta: {
        endpoint: endpoint.ORDER_TAG_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const update = (orderTag) => ({
    type: types.UPDATE_ORDER_TAG,
    meta: {
        endpoint: endpoint.ORDER_TAG_UPDATE,
        method: REQUEST_METHOD.POST,
        body: {
            order_tag: orderTag
        },
        hasAuth: true,
    }
});

const create = (orderTag) => ({
    type: types.CREATE_ORDER_TAG,
    meta: {
        endpoint: endpoint.ORDER_TAG_CREATE,
        method: REQUEST_METHOD.POST,
        body: {
            order_tag: orderTag
        },
        hasAuth: true,
    }
});

export default {
    deleteTag,
    search,
    listAll,
    list,
    update,
    create
}