import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/Input";

import appConfig from "../../../config/app";
import Dropdown from "../../../components/Dropdown";

import { produce, find, compact, map } from "lodash"
import baseHelper from "../../../helpers/BaseHelper";

// eslint-disable-next-line max-lines-per-function
function ModalShowPasswordToken(props) {
    const { t, onClose, isShow, innerStyle } = props;
    const wrapperRef = useRef(null);

    const dispatch = useDispatch();

    const inputsRef = new Set();

    const [selectedData, setSelectedData] = useState({
        email: "",
        token: ""
    });


    useEffect(() => {
        if (props.dataPassword) {
            setSelectedData({
                email: props.dataPassword.email ? props.dataPassword.email : "",
                token: props.dataPassword.token ? props.dataPassword.token : ""
            })
        }
    }, [props.dataPassword])




    const eventCancel = () => {
        onClose(false);
    };

    return (
        <div className={`ladiui modal ${isShow ? "show" : ""}`} id="modal-add-student" >
            <div className="ladiui modal-dialog modal-dialog-centered w-600" ref={wrapperRef}>
                <div className="ladiui modal-content ">
                    <div className="ladiui modal-header ">
                        <h2 className="ladiui modal-title ">Lấy mật khẩu đăng nhập</h2>
                        <button data-dismiss="modal" data-target="add-product" onClick={eventCancel} className="ladiui modal-close">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className="ladiui modal-body" style={{ position: "initial" }}>
                        <div className="ladiui form-group">
                            <label className="ladiui-label">Email</label>
                            <input
                                className="ladiui form-control"
                                value={selectedData.email}
                                readonly={true}
                            />

                        </div>
                        <div className="ladiui form-group">
                            <label className="ladiui-label">Token đăng nhập</label>
                            <input
                                className="ladiui form-control"
                                value={selectedData.token}
                                readonly={true}
                            />

                        </div>
                    </div>

                    <div className="ladiui modal-footer">
                        <div className="ladiui footer-modal-btn">
                            <button
                                type="button"
                                data-dismiss="modal"
                                data-target="add-product"
                                className="ladiui btn btn-secondary bold"
                                onClick={eventCancel}
                            >
                                {t("ACTIONS.CLOSE")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(ModalShowPasswordToken);
