import React from "react";

import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import shippingActions from "../../../../redux/futures/shipping/actions";
import * as shippingTypes from "../../../../redux/futures/shipping/types";

import orderActions from "../../../../redux/futures/order/actions";

import ConfirmModal from "../../../../components/ConfirmModal";
import appConfig from "../../../../config/app";
import { find, map } from "lodash";
import baseHelper from "../../../../helpers/BaseHelper";

class ShippingDetail extends React.Component {
  static propTypes = {
    orderID: PropTypes.number,
    shippings: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedShipping: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.shippingReducer.action != nextProps.shippingReducer.action) {
      if (nextProps.shippingReducer.action == shippingTypes.SHIPPING_UPDATE_STATUS) {
        if (nextProps.shippingReducer.status) {
          window.LadiUI.toastCustom("success", "", nextProps.shippingReducer.message);
          this.props.reload(this.props.orderID);
          window.LadiUI.closeModal("confirm-shipping-cancel");
          this.props.cancelModalShipping();
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.shippingReducer.message, "OK");
        }
      }
    }
  }

  openModalConfirmCancel = (selectedShipping) => {
    this.setState(
      {
        selectedShipping,
      },
      () => window.LadiUI.showModal("confirm-shipping-cancel")
    );
  };

  getStatusNameByCode = (status) => {
    const matched = find(appConfig.SHIPPING_STATUS, (item) => item.CODE == status);
    if (matched) {
      return matched.NAME;
    }
  };

  getStatusPanel = (status) => {
    const statusName = this.getStatusNameByCode(status);

    switch (status) {
      case appConfig.SHIPPING_STATUS.READY_TO_PICK.CODE:
        return <span className="shipping-status shipping-status-1">{statusName}</span>;
      case appConfig.SHIPPING_STATUS.DELIVERING.CODE:
        return <span className="shipping-status shipping-status-2">{statusName}</span>;
      case appConfig.SHIPPING_STATUS.DELIVERED.CODE:
        return <span className="shipping-status shipping-status-3">{statusName}</span>;
      case appConfig.SHIPPING_STATUS.RETURNING.CODE:
        return <span className="shipping-status shipping-status-4">{statusName}</span>;
      case appConfig.SHIPPING_STATUS.RETURNED.CODE:
        return <span className="shipping-status shipping-status-5">{statusName}</span>;
      case appConfig.SHIPPING_STATUS.CANCELED.CODE:
        return <span className="shipping-status shipping-status-6">{statusName}</span>;
    }
  };

  render() {
    const { shippings, t, disabled } = this.props;
    const isLoadingCancel =
      this.props.shippingReducer.loading && shippingTypes.SHIPPING_UPDATE_STATUS == this.props.shippingReducer.waiting;

    return (
      <div className="ladi-card order-shipping-package">
        <strong>{t("SHIPPINGS.TITLE")}</strong>
        {shippings && shippings.length > 0 ? (
          <table className="ladiui table text-left table-shipping-detail">
            <thead>
              <tr className="ladiui table-vertical header">
                <th scope="col">{t("SHIPPINGS.NO")}</th>
                <th scope="col">{t("SHIPPINGS.PARTNER_TITLE")}</th>
                <th scope="col">{t("SHIPPINGS.SHIPPING_CODE")}</th>
                <th scope="col">{t("STATUS.TITLE")}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {map(shippings, (item, index) => (
                <tr key={index} className="ladiui table-vertical">
                  <td>
                    <NavLink to={`/shippings/${item.shipping_id}`}>{item.shipping_id}</NavLink>
                  </td>
                  <td>{item.shipping_partner_name}</td>
                  <td>{baseHelper.getTrackingHref(item)}</td>
                  <td>{this.getStatusPanel(item.status)}</td>
                  {item.status != appConfig.SHIPPING_STATUS.CANCELED.CODE && (
                    <td className="text-right pr-15">
                      <div className="ladiui btn-group">
                        <div className="ladiui dropdown hide-mt ba-c">
                          <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                            <i className="ladiui icon icon-ldp-dot"></i>
                          </button>
                          <ul className="ladiui dropdown-menu r-0">
                            <li>
                              <a className="ladiui dropdown-item" onClick={() => this.openModalConfirmCancel(item)}>
                                {t("ACTIONS.CANCEL")}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="button-payment-shipping" style={{ borderTop: 0, padding: "24px 0px", textAlign: "center" }}>
            {/* <div className="title-payment">
                                <i className="ladi-icon icon-delivery" />
                                <span>{t('SHIPPINGS.LABEL')}</span>
                            </div> */}
            <button
              type="button"
              disabled={disabled}
              className="ladiui btn btn-outline-primary"
              onClick={() => this.props.openModalShipping()}
            >
              {t("SHIPPINGS.LABEL")}
            </button>
          </div>
        )}

        <ConfirmModal
          id={`confirm-shipping-cancel`}
          title={t("SHIPPINGS.MSG_CANCEL_SHIPPING_TITLE")}
          content={t("SHIPPINGS.MSG_CANCEL_SHIPPING_CONTENT")}
          cancelText={t("ACTIONS.CLOSE")}
          okText={t("ACTIONS.CANCEL")}
          onOk={() => this.props.cancel(this.state.selectedShipping.shipping_id)}
          isLoading={isLoadingCancel}
          maxWidth={600}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    cancel: (shippingID) => dispatch(shippingActions.updateStatus(shippingID, appConfig.SHIPPING_STATUS.CANCELED.CODE)),
    reload: (orderID) => dispatch(orderActions.reload(orderID)),
  };
};

const mapStateToProps = (state) => ({
  shippingReducer: { ...state.shipping },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation("translation", { withRef: true })(ShippingDetail));
