export const LIST_STORE = "LIST_STORE";
export const DELETE_STORES = "DELETE_STORES";
export const DELETE_STORE = "DELETE_STORE";
export const SHOW_STORE = "SHOW_STORE";
export const SHOW_TRACKING_STORE = "SHOW_TRACKING_STORE";
export const UPDATE_TRACKING_STORE = "UPDATE_TRACKING_STORE";
export const RELOAD_STORE = "RELOAD_STORE";
export const CREATE_STORE = "CREATE_STORE";
export const UPDATE_STORE = "UPDATE_STORE";
export const GET_USER_INFO = "GET_USER_INFO";
export const APPLY_DEFAULT_ADDRESS_STORE = "APPLY_DEFAULT_ADDRESS_STORE";
export const CREATE_NEW_IP = "CREATE_NEW_IP";
export const DELETE_IP = "DELETE_IP";
