import * as types from './types';
import * as commonTypes from "../common/types";

export default (state = {
    sheets: []
}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            }
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            }
        }
        case types.INTEGRATE_FORM_ACCOUNT_LIST: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_FORM_ACCOUNT_LIST,
                form_accounts: action.status ? action.payload.items : [],
                total_record: action.status ? action.payload.total_record : 0,
                total_page: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.INTEGRATE_FORM_ACCOUNT_SHOW: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_FORM_ACCOUNT_SHOW,
                form_account: action.status ? action.payload.form_account : {},
                status: action.status,
                message: action.message
            }
        }
        case types.INTEGRATE_FORM_ACCOUNT_UPDATE: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_FORM_ACCOUNT_UPDATE,
                status: action.status,
                message: action.message
            }
        }
        case types.INTEGRATE_FORM_ACCOUNT_DELETES: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_FORM_ACCOUNT_DELETES,
                status: action.status,
                message: action.message
            }
        }
        case types.INTEGRATE_OAUTH2_GET_LINK: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_OAUTH2_GET_LINK,
                url: action.status ? action.payload.url : null,
                status: action.status,
                message: action.message
            }
        }
        case types.INTEGRATE_OAUTH_ACCESS: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_OAUTH_ACCESS,
                account: action.status ? action.payload.account : {},
                status: action.status,
                message: action.message
            }
        }
        case types.INTEGRATE_FORM_ACCOUNT_LOAD_DATA: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_FORM_ACCOUNT_LOAD_DATA,
                form_account_data: action.status ? action.payload : {},
                status: action.status,
                message: action.message
            }
        }
        case types.INTEGRATE_FORM_ACCOUNT_LOAD_CUSTOM_FIELDS: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_FORM_ACCOUNT_LOAD_CUSTOM_FIELDS,
                custom_fields: action.status ? action.payload : [],
                status: action.status,
                message: action.message
            }
        }
        case types.INTEGRATE_FORM_ACCOUNT_LOAD_GOOGLE_SPREADSHEET: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_FORM_ACCOUNT_LOAD_GOOGLE_SPREADSHEET,
                sheets: action.status ? action.payload.google_spreadsheets : [],
                status: action.status,
                message: action.message
            }
        }
        case types.INTEGRATE_FORM_ACCOUNT_LOAD_GOOGLE_WORKSHEET: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_FORM_ACCOUNT_LOAD_GOOGLE_WORKSHEET,
                worksheets: action.status ? action.payload.google_worksheets : [],
                status: action.status,
                message: action.message
            }
        }
        case types.INTEGRATE_FORM_ACCOUNT_CREATE_GOOGLE_SHEET: {
            const sheet = action.status ? action.payload : {};
            const sheets = state.sheets;
            if (sheet) {
                sheets.unshift(sheet);
            }
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_FORM_ACCOUNT_CREATE_GOOGLE_SHEET,
                sheet,
                sheets,
                status: action.status,
                message: action.message
            }
        }
        case types.INTEGRATE_FORM_ACCOUNT_SELECT_MANUALLY_GOOGLE_SHEET_ID: {
            let sheet;
            if (action.status) {
                sheet = {
                    id: action.payload.data.spreadsheetId,
                    name: action.payload.data.properties.title,
                }
            }
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_FORM_ACCOUNT_SELECT_MANUALLY_GOOGLE_SHEET_ID,
                sheet,
                status: action.status,
                message: action.message
            }
        }
        case types.INTEGRATE_FORM_ACCOUNT_RESET_DATA: {
            return {
                ...state,
                action: types.INTEGRATE_FORM_ACCOUNT_RESET_DATA,
                custom_fields: [],
                form_account_data: {},
                sheets: [],
                worksheets: [],
                status: true,
            }
        }
        case types.INTEGRATE_GOOGLE_SHEETS: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_GOOGLE_SHEETS,
                url: action.status ? action.payload.url : null,
                status: action.status,
                message: action.message
            }
        }
        case types.INTEGRATE_OAUTH2_GOOGLE_SHEETS: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_OAUTH2_GOOGLE_SHEETS,
                status: action.status,
                message: action.message
            }
        }
        case types.INTEGRATE_FORM_ACCOUNT_IN_FORM_CONFIG_LIST: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_FORM_ACCOUNT_IN_FORM_CONFIG_LIST,
                form_accounts_in_form_config: action.status ? action.payload.items : [],
                status: action.status,
                message: action.message,
            }
        }
        case types.INTEGRATE_HARAVAN: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_HARAVAN,
                url: action.status ? action.payload.url : null,
                status: action.status,
                message: action.message
            }
        }
        case types.INTEGRATE_CREATE_HARAVAN: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_CREATE_HARAVAN,
                status: action.status,
                message: action.message
            }
        }
        case types.INTEGRATE_SAPO: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_SAPO,
                url: action.status ? action.payload.url : null,
                status: action.status,
                message: action.message
            }
        }
        case types.INTEGRATE_CREATE_SAPO: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_CREATE_SAPO,
                status: action.status,
                message: action.message
            }
        }
        case types.INTEGRATE_SHOPIFY: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_SHOPIFY,
                url: action.status ? action.payload.url : null,
                status: action.status,
                message: action.message
            }
        }

        case types.INTEGRATE_NHANH: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_NHANH,
                url: action.status ? action.payload.url : null,
                status: action.status,
                message: action.message
            }
        }
        case types.INTEGRATE_CREATE_NHANH: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.INTEGRATE_CREATE_NHANH,
                status: action.status,
                message: action.message
            }
        }

        
        default:
            return state;
    }
}


