import React from "react";
import PropTypes from "prop-types";
import { map, compact } from "lodash";
import validatorHelper from "../helpers/ValidatorHelper";
import i18n from "../i18n";

import baseHelper from "../helpers/BaseHelper";

class Dropdown extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    onSelectItem: PropTypes.func,
    currentKey: PropTypes.any,
    currentValue: PropTypes.any,
    _key: PropTypes.string,
    _value: PropTypes.string,
    validations: PropTypes.shape({
      isRequired: PropTypes.bool,
    }),
    placeHolder: PropTypes.string,
    renderItem: PropTypes.func,
    wrapperStyle: PropTypes.object,
    wrapperClassName: PropTypes.string,
    renderHeaders: PropTypes.func,
    innerClassName: PropTypes.string,
    isShowBlockInputSearch: PropTypes.bool,
    isParentCategoryDropdown: PropTypes.bool,
    isDropdownSearchCallApi: PropTypes.bool
  };

  static defaultProps = {
    _key: "code",
    _value: "name",
    validations: {
      isRequired: false,
    },
    wrapperClassName: "w100",
    innerClassName: "w100",
  };

  constructor(props) {
    super(props);

    this.state = {
      searchInput: "",
      datas: props.data,
      // visibleData: props.data,
    };

    this.ref = React.createRef();
    this.inputRef = React.createRef();

    this.reset = this.reset.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }
    if (this.props.data != nextProps.data) {
      this.setState({
        datas: nextProps.data,
      });
    }
  }

  getValueByKey = (_key) => {
    let matchedItem = {};
    map(this.state.datas, (item) => {
      if (item[this.props._key] == _key) {
        matchedItem = item;
      }
    });
    return matchedItem[this.props._value];
  };

  onChangeSearchInput = (event) => {
    const { value } = event.target;

    let { isDropdownSearchCallApi, isDropdownBank } = this.props;

    if (!isDropdownSearchCallApi) {
      let visibleData = [];
      if (!isDropdownBank) {
        visibleData = map(this.props.data, (item) => {
          if (baseHelper.getAliasName(item[this.props._value]).indexOf(baseHelper.getAliasName(value)) > -1) {
            return item;
          }
        });
      } else {
        visibleData = map(this.props.data, (item) => {
          if (baseHelper.getAliasName(item[this.props._value]).indexOf(baseHelper.getAliasName(value)) > -1 || baseHelper.getAliasName(item['shortName']).indexOf(baseHelper.getAliasName(value)) > -1) {
            return item;
          }
        });
      }

      visibleData = compact(visibleData);

      this.setState({
        searchInput: value,
        datas: visibleData,
      });
    } else {
      this.setState({
        searchInput: value,
      });
    }
  };

  onKeyDownInputSearch = (event) => {
    let { isDropdownSearchCallApi } = this.props;
    let { searchInput } = this.state;
    if (isDropdownSearchCallApi) {
      if (event.key == "Enter") {
        this.props.handleSearchData(searchInput);
      }
    }
  }

  handleClickSearchData = () => {
    let { isDropdownSearchCallApi } = this.props;
    let { searchInput } = this.state;
    if (isDropdownSearchCallApi) {
      this.props.handleSearchData(searchInput);
    }
  }

  onSelectItem = (selected) => {
    if (selected[this.props._key] == this.props.currentKey) {
      return;
    }
    this.props.onSelectItem(selected);

    this.reset();
  };

  validate = () => {
    const errors = [];
    if (!this.ref.current) {
      return errors;
    }

    const { classList } = this.inputRef.current;
    classList.remove("error");

    let validateStatus = true;

    let { currentKey, validationName } = this.props;

    const { isRequired } = this.props.validations;
    if (isRequired) {
      if (validatorHelper.isEmpty(currentKey)) {
        validateStatus = false;
        errors.push(i18n.t("VALIDATION.ENTER", validationName));
      }
    }

    if (!validateStatus) {
      classList.add("error");
    }

    return errors;
  };

  removeItemSelected = () => {
    this.props.onSelectItem("");
  };

  renderItems = () => {
    const { _key, _value, isDropdownBank } = this.props;

    const { datas, searchInput } = this.state;

    const items = map(datas, (item, index) => {
      let paddingLeftValue = 16;
      paddingLeftValue = item.level ? item.level * 16 : 16;

      if (this.props.renderItem) {
        const element = this.props.renderItem(item, index);

        return React.cloneElement(element, {
          onClick: () => this.onSelectItem(item),
        });
      }

      return (
        <div
          onClick={() => this.onSelectItem(item)}
          key={index}
          className="ladiui dropdown-item"
          style={{ paddingLeft: paddingLeftValue, fontWeight: item.level == 1 ? "500" : "" }}
          data-value={item[_key]}
        >
          {item[_value]} {isDropdownBank ? ` (${item.shortName})` : ''}
        </div>
      );
    });

    return items;
  };

  reset() {
    this.setState({
      searchInput: "",
      datas: this.props.data
      // visibleData: this.props.data,
    });

    // ReactDOM.findDOMNode(this).querySelector('.ladiui.text').classList.remove('hide');
  }

  render() {
    const {
      id,
      currentKey,
      currentValue,
      placeHolder,
      wrapperStyle,
      wrapperClassName,
      renderHeaders,
      urlIcon,
      innerClassName,
      dropup,
      idBlock,
      innerBtnAddTag,
      isShowBlockInputSearch,
      isParentCategoryDropdown,
    } = this.props;
    const { searchInput } = this.state;
    const displayData = currentValue || this.getValueByKey(currentKey) || placeHolder;
    return (
      <div id={id} ref={this.ref} className={`ladiui dropdown ${wrapperClassName ? wrapperClassName : ""}`}>
        {/* <input ref={this.inputRef} onBlur={() => {
                    this.setState({
                        searchInput: ''
                    });
                }} value={searchInput} onChange={this.onChangeSearchInput} type="text" className="ladiui btn btn-outline-light dropdown-toggle select-dropdown custom-form-dynamic" id="dropdownMenuButton3" data-toggle="dropdown" style={{ width: '100%' }} /> */}
        <button
          ref={this.inputRef}
          className={`${innerClassName} ladiui btn btn-outline-light dropdown-toggle select-dropdown custom-form-dynamic`}
          id="dropdownMenuButton3"
          data-toggle="dropdown"
        >
          {urlIcon && <img className="mr-4 ml-8" src={urlIcon} alt="" />}
          <span className="ladiui dropdown-text">{displayData}</span>
        </button>
        <div id={idBlock} className={`ladiui dropdown-menu custom-form-dynamic ${dropup ? "dropup bt-36" : ""}`} style={{ width: "100%" }}>
          {renderHeaders && renderHeaders()}
          {isShowBlockInputSearch && (
            <div className="ladiui form-group dropdown-input-search-custom">
              <i className="ladi-icon ldicon-search" onClick={() => this.handleClickSearchData()} />
              <input
                type="text"
                className="ladiui form-control"
                name="searchInput"
                value={this.state.searchInput}
                placeholder="Tìm kiếm..."
                onChange={(e) => this.onChangeSearchInput(e)}
                onKeyDown={this.onKeyDownInputSearch}
              />
            </div>
          )}
          {isParentCategoryDropdown && (
            <div className="ladiui dropdown-item" onClick={() => this.removeItemSelected()}>
              Không chọn
            </div>
          )}
          {this.renderItems()}
          {innerBtnAddTag && innerBtnAddTag}
        </div>
        <input type="hidden" className="ladiui-search-dropdown-value" value="" />
      </div>
    );
  }
}

export default Dropdown;
