import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { I18nContext, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import produce from "immer";
import Input from "../../../components/Input";
import { cond, map, includes, remove } from "lodash";

import courseActions from "../../../redux/futures/course/actions";
import * as courseTypes from "../../../redux/futures/course/types";

import baseHelper from "../../../helpers/BaseHelper";

import appConfig from "../../../config/app";
import LadiPagination from "../../../components/LadiPagination";

import ModalMembershipKey from "../../courses/components/ModalMembershipKey";

import LadiDropdownMenu from "../../../components/LadiDropdownMenu";

import ConfirmModal from "../../../components/ConfirmModal";

import ModalReasonLock from "../../courses/components/ModalReasonLock";

import ModalAddStudent from "./ModalAddStudent";

const CDN = "https://w.ladicdn.com/";

const PanelListStudent = forwardRef((props, ref) => {
  let { isShow, productId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fileReducer = useSelector((state) => state.file);

  const courseReducer = useSelector((state) => state.course);

  const [conditions, setConditions] = useState({
    page: 1,
    limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
  });

  const listAction = [
    {
      value: "LOCK",
      name: "Vô hiệu",
    },
    {
      value: "UN_LOCK",
      name: "Kích hoạt",
    },
  ];

  const [students, setStudents] = useState([]);

  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [statusPaid, setStatusPaid] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);

  const [selectedProductId, setSelectedProductId] = useState(null);

  const [isShowModalMembershipKey, setIsShowModalMembershipKey] = useState(false);

  const [keyWord, setKeyWord] = useState("");
  const [statusVerify, setStatusVerify] = useState(null);
  const [statusLock, setStatusLock] = useState(null);

  const [selectedReason, setSelectedReason] = useState("");
  const [isShowModalReasonLock, setIsShowModalReasonLock] = useState(false);

  const [isShowModalAddStudent, setIsShowModalAddStudent] = useState(false)

  const inputsRef = new Set();

  useImperativeHandle(ref, () => ({
    validate() {
      return true;
    },
    getData() {
      return {};
    },
  }));

  useEffect(() => {
    if (productId) {
      setSelectedProductId(productId);
      let payload = {
        search: {
          product_id: productId,
          paid_status: statusPaid
        },
        ...conditions,
      };
      dispatch(courseActions.courseCustomerList(payload));
    }
  }, [productId]);

  useEffect(() => {
    if (selectedProductId) {
      let payload = {
        search: {
          product_id: selectedProductId,
          paid_status: statusPaid
        },
        ...conditions,
      };
      dispatch(courseActions.courseCustomerList(payload));
    }
  }, [conditions]);

  const handleSubmitData = () => {
    props.handleSubmitData();
  };

  useEffect(() => {
    if (selectedProductId) {
      let payload = {
        search: {
          product_id: selectedProductId,
          keyword: keyWord,
          is_lock: statusLock,
          paid_status: statusPaid
        },
        ...conditions,
      };
      dispatch(courseActions.courseCustomerList(payload));
    }
  }, [statusLock]);
  
  useEffect(() => {
    if (selectedProductId) {
      let payload = {
        search: {
          product_id: selectedProductId,
          keyword: keyWord,
          is_lock: statusLock,
          paid_status: statusPaid
        },
        ...conditions,
      };
      dispatch(courseActions.courseCustomerList(payload));
    }
  }, [statusPaid]);

  useEffect(() => {
    if (courseReducer.action == courseTypes.COURSE_CUSTOMER_LIST) {
      if (courseReducer.status) {
        if (courseReducer.customers) {
          setStudents([...courseReducer.customers]);
        }
      } else {
        window.LadiUI.showErrorMessage("Thông báo", courseReducer.message, "OK");
      }
    }
    if (courseReducer.action == courseTypes.CREATE_CUSTOMER) {
      if (courseReducer.status) {
        window.LadiUI.toastCustom("success", "", courseReducer.message);
        setIsShowModalAddStudent(false)

        let payload = {
          search: {
            product_id: productId,
          },
          ...conditions,
        };
        dispatch(courseActions.courseCustomerList(payload));
      } else {
        window.LadiUI.showErrorMessage("Thông báo", courseReducer.message, "OK");
      }
    }
    if (courseReducer.action == courseTypes.CREATE_MANY_CUSTOMERS) {
      if (courseReducer.status) {
        window.LadiUI.toastCustom("success", "", courseReducer.message);
        setIsShowModalAddStudent(false)

        let payload = {
          search: {
            product_id: productId,
          },
          ...conditions,
        };
        dispatch(courseActions.courseCustomerList(payload));
      } else {
        window.LadiUI.showErrorMessage("Thông báo", courseReducer.message, "OK");
      }
    }
    if (courseReducer.action == courseTypes.LOCK_COURSE) {
      if (courseReducer.status) {
        window.LadiUI.toastCustom("success", "", courseReducer.message);
        setIsShowModalMembershipKey(false);

        let inputCheck = Array.from(document.querySelectorAll("#checkInputItem"));
        for (let i = 0; i < inputCheck.length; i++) {
          inputCheck[i].checked = false;
        }
        document.getElementById("checkAllItem").checked = false;
        setSelectedIds([]);

        let payload = {
          search: {
            product_id: productId,
          },
          ...conditions,
        };
        dispatch(courseActions.courseCustomerList(payload));
      } else {
        window.LadiUI.showErrorMessage("Thông báo", courseReducer.message, "OK");
      }
    }
    if (courseReducer.action == courseTypes.CANCEL_LOCK_COURSE) {
      if (courseReducer.status) {
        window.LadiUI.toastCustom("success", "", courseReducer.message);
        window.LadiUI.closeModal("confirm-enable-student");

        let inputCheck = Array.from(document.querySelectorAll("#checkInputItem"));
        for (let i = 0; i < inputCheck.length; i++) {
          inputCheck[i].checked = false;
        }
        document.getElementById("checkAllItem").checked = false;
        setSelectedIds([]);

        let payload = {
          search: {
            product_id: productId,
          },
          ...conditions,
        };
        dispatch(courseActions.courseCustomerList(payload));
      } else {
        window.LadiUI.showErrorMessage("Thông báo", courseReducer.message, "OK");
      }
    }
  }, [courseReducer]);

  const onChangeLimit = (option) => {
    setConditions({
      ...conditions,
      limit: option.value,
    });
  };

  const onPageChange = (pageNumber) => {
    setConditions({
      ...conditions,
      page: pageNumber,
    });
  };

  const checkItem = (event, customerID) => {
    const { checked } = event.target;

    let _selectedIds = [...selectedIds];

    if (checked) {
      if (!includes(_selectedIds, customerID)) {
        _selectedIds.push(customerID);
      }

      if (_selectedIds.length == students.length) {
        document.getElementById("checkAllItem").checked = true;
      }
    } else {
      document.getElementById("checkAllItem").checked = false;
      remove(_selectedIds, (selectedID) => selectedID == customerID);
    }

    setSelectedIds(_selectedIds);
  };

  const checkAllItem = (event) => {
    const { checked } = event.target;

    let selectedIDsCm = [];
    if (checked) {
      selectedIDsCm = map(students, (customer) => customer.customer_id);

      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = true;
      });
    } else {
      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = false;
      });
    }

    setSelectedIds(selectedIDsCm);
  };

  const handleMembershipKey = (customerID) => {
    if (customerID) {
      setSelectedStudentId(customerID);
      setIsShowModalMembershipKey(true);
    }
  };

  const handleCancelMembershipKey = (customerID) => {
    if (customerID) {
      setSelectedStudentId(customerID);
      // setIsShowModalMembershipKey(true);
      window.LadiUI.showModal("confirm-enable-student");
    }
  };

  const handleOpenModalReasonLock = (reason) => {
    if (reason) {
      setSelectedReason(reason);
      setIsShowModalReasonLock(true);
    }
  };

  const fetchListStudent = () => {
    if (students && students.length > 0) {
      return students.map((item, index) => {
        return (
          <tr key={index} className="ladiui table-vertical main">
            <td>
              <input
                id="checkInputItem"
                type="checkbox"
                onClick={(event) => checkItem(event, item.customer_id)}
                className="ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle"
              />
            </td>
            <td>{(item.first_name ? item.first_name : "") + (item.last_name ? item.last_name : "")}</td>
            <td>{item.email}</td>
            <td>{item.phone}</td>
            <td>
                {item.is_lock == 0 ? (
                  <span className="flex">
                    <i className="ldicon-status-success mr-8" />
                    Kích hoạt
                  </span>
                ) : (
                  <span className="flex">
                    <i className="ldicon-status-error mr-8" />
                    Vô hiệu
                  </span>
                )}
            </td>
            <td>{item.buy_price && item.buy_price> 0 ? "Đã trả phí" : "Chưa trả phí"}</td>
            <td>{item.created_at ? baseHelper.formatDateToStr(item.created_at) : ""}</td>
            <td className="text-right pd-0">
              <div className="ladiui btn-group">
                <div className="ladiui dropdown hide-mt ba-c">
                  <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                    <i className="ladiui icon icon-ldp-dot"></i>
                  </button>
                  <ul className="ladiui dropdown-menu r-0">
                    {!item.is_lock || item.is_lock == 0 ? (
                      <li onClick={() => handleMembershipKey(item.customer_id)}>
                        <a className="ladiui dropdown-item space-nowrap">Vô hiệu tài khoản</a>
                      </li>
                    ) : (
                      <>
                        <li onClick={() => handleOpenModalReasonLock(item.lock_reason)}>
                          <a className="ladiui dropdown-item space-nowrap">Lý do vô hiệu</a>
                        </li>
                        <li onClick={() => handleCancelMembershipKey(item.customer_id)}>
                          <a className="ladiui dropdown-item space-nowrap">Kích hoạt tài khoản</a>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        );
      });
    }
  };

  const handleLockCustomer = (reason) => {
    // let payload = {
    //   product_id: productId,
    //   customer_id: selectedStudentId,
    //   reason: reason,
    // };

    let payload = {};
    if (selectedStudentId) {
      payload = {
        product_id: productId,
        customer_id: selectedStudentId,
        reason: reason,
      };
    } else {
      payload = {
        product_id: productId,
        customer_ids: selectedIds,
        reason: reason,
      };
    }

    dispatch(courseActions.lockCourse(payload));
  };

  const handleCancelLockCustomer = (reason) => {
    let payload = {};
    if (selectedStudentId) {
      payload = {
        product_id: productId,
        customer_id: selectedStudentId,
      };
    } else {
      payload = {
        product_id: productId,
        customer_ids: selectedIds,
      };
    }

    dispatch(courseActions.cancelLockCourse(payload));
  };

  const eventSelectAction = (action, event) => {
    switch (action) {
      case "LOCK":
        setIsShowModalMembershipKey(true);
        break;
      case "UN_LOCK":
        window.LadiUI.showModal("confirm-enable-student");
        break;
      default:
    }
  };

  const onKeyDownInput = (event) => {
    if (event.key === "Enter") {
      let payload = {
        search: {
          product_id: selectedProductId,
          keyword: keyWord,
          is_lock: statusLock,
          paid_status: statusPaid
        },
        ...conditions,
      };
      dispatch(courseActions.courseCustomerList(payload));
    }
  };

  const handleSearchCustomer = () => {
    let payload = {
      search: {
        product_id: selectedProductId,
        keyword: keyWord,
        is_lock: statusLock,
        paid_status: statusPaid
      },
      ...conditions,
    };
    dispatch(courseActions.courseCustomerList(payload));
  };

  return (
    <div className="page-setting">
      <div className="setting-content-domain-course config-panel">
        <div className="ladiui-table-actions" style={{ justifyContent: "space-between" }}>
          <div className="flex">
            <div className="ladiui search-group mr-24">
              <input
                id="keyword_search"
                className="ladiui search-field dropdown-toggle form-control search-width"
                name="searchName"
                placeholder="Tìm kiếm học viên"
                aria-expanded="false"
                value={keyWord}
                onChange={(event) => {
                  setKeyWord(event.target.value);
                }}
                onKeyDown={onKeyDownInput}
              />
              <i className="ladiui icon icon-search" onClick={() => handleSearchCustomer()}></i>
            </div>
            <div className="ladiui flex-row mr-24">
            <div className="ladiui btn-group">
                  <div className="ladiui dropdown">
                      <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown">
                          <i className="ladiui icon"></i>
                          <span className="ladiui dropdown-text">{"Loại tài khoản"}</span>
                      </button>
                      <ul className="ladiui dropdown-menu">
                          {appConfig.LIST_STATUS_PAID.map((item) => (
                              <li key={item.value} onClick={(e) => setStatusPaid(item.value)}>
                                  <a className="ladiui dropdown-item" href="#">
                                      {item.name}
                                  </a>
                              </li>
                          ))}
                      </ul>
                  </div>
              </div>
              </div>
            <div className="ladiui flex-row mr-24">
              <div className="ladiui btn-group">
                <div className="ladiui dropdown">
                  <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">{"Trạng thái vô hiệu"}</span>
                  </button>
                  <ul className="ladiui dropdown-menu">
                    {appConfig.LIST_STATUS_LOCK.map((item) => (
                      <li key={item.value} onClick={(e) => setStatusLock(item.value)}>
                        <a className="ladiui dropdown-item" href="#">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-add-student">
            <a className="flex" onClick={() => setIsShowModalAddStudent(true)}>
              <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg" className="mr-8" />
              Thêm học viên
            </a>
          </div>
        </div>

        <table className={`ladiui table text-left`}>
          <thead style={{ position: "relative" }}>
            <tr className="ladiui table-vertical header">
              <th scope="col" className="ladiui checkall">
                <input
                  id="checkAllItem"
                  onClick={checkAllItem}
                  type="checkbox"
                  className="ladiui checkbox size-checkbox form-check-input-checkbox ladiui-checkall vertical-middle"
                />
              </th>
              {selectedIds && selectedIds.length <= 0 ? (
                <th scope="col" name="full_name">
                  Tên học viên
                </th>
              ) : (
                <th>
                  Tên học viên
                  <LadiDropdownMenu
                    labelDropdown={"Chọn hành động"}
                    listProp={listAction}
                    defaultTextProp={t("ACTIONS.TITLE")}
                    hideLabel={true}
                    cbProp={eventSelectAction}
                    classSize="btn-sm "
                    classWidth="w-180"
                    classDropdown="w-180 action-record-table"
                  />
                </th>
              )}
              <th scope="col" name="full_name">
                Email
              </th>
              <th scope="col" name="email" className="text-left">
                Số điện thoại
              </th>
              <th scope="col" name="email" className="text-left">
                Trạng thái
              </th>
              <th scope="col" name="verify" className="text-left">
                  Loại tài khoản
              </th>
              <th scope="col" name="phone" className="text-left">
                Thời gian mua khóa học
              </th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            {fetchListStudent()}
            {students && students.length <= 0 && (
              <tr className="text-center">
                <td colSpan="100%">{t("NO_DATA")}</td>
              </tr>
            )}
          </tbody>
        </table>
        {courseReducer.totalRecord && courseReducer.totalRecord > 0 ? (
          <LadiPagination
            conditionsProp={conditions}
            listName={t("PAGINATION.ITEMS")}
            message={""}
            items={students}
            total={courseReducer.totalRecord}
            limitAction={onChangeLimit}
            pageAction={onPageChange}
          />
        ) : (
          ""
        )}
      </div>

      {isShowModalMembershipKey && (
        <ModalMembershipKey
          onClose={() => setIsShowModalMembershipKey(false)}
          isShow={isShowModalMembershipKey}
          innerStyle={{ display: "none" }}
          handleLockCustomer={handleLockCustomer}
          isStudentCourse={true}
        />
      )}

      {isShowModalReasonLock && (
        <ModalReasonLock
          onClose={() => setIsShowModalReasonLock(false)}
          isShow={isShowModalReasonLock}
          innerStyle={{ display: "none" }}
          isStudentCourse={false}
          selectedReason={selectedReason}
        />
      )}

      {isShowModalAddStudent && (
        <ModalAddStudent
          onClose={() => setIsShowModalAddStudent(false)}
          isShow={isShowModalAddStudent}
          selectedStudents={students || []}
          innerStyle={{ display: "none" }}
          styleCustom={{ position: "unset" }}
          productId={props.productId || null}
        />
      )}

      <ConfirmModal
        id="confirm-enable-student"
        title={"Mở khóa học viên"}
        content={"Học viên được mở khóa sẽ có thể tham gia khóa học trở lại. Bạn có muốn thực hiện hành động này?"}
        cancelText={t("ACTIONS.CANCEL")}
        okText={"Mở khóa học viên"}
        isBtnPrimary={true}
        onOk={handleCancelLockCustomer}
      // isLoading={this.state.loadingDelete}
      />
    </div>
  );
});
PanelListStudent.propTypes = {};

export default PanelListStudent;
