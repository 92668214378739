/** Import default package */
import React from "react";
import PropTypes from "prop-types";

/** Import from third party */
import { map, compact, omit } from "lodash";

/** Import component from my app */
import appConfig from "../../../config/app";

/** Import redux */
import { withTranslation } from "react-i18next";
import PanelAddress from "../../../components/PanelAddress";
import Modal from "../../../components/Modal";
import baseHelper from "../../../helpers/BaseHelper";

class ModalAddress extends React.Component {
  static propTypes = {
    address: PropTypes.object,
    mode: PropTypes.string,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      address: {
        customer_id: "",
        customer_address_id: "",
        first_name: "",
        last_name: "",
        company: "",
        address: "",
        apartment: "",
        country_code: "",
        country_name: "",
        postal_code: "",
        state_id: "",
        state_name: "",
        district_id: "",
        district_name: "",
        ward_id: "",
        ward_name: "",
        phone: "",
      },
    };
    this.ref = React.createRef();
    this.addressPanelRef = React.createRef();
    this.inputsRef = new Set();
  }

  componentWillMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props === nextProps) {
      return;
    }

    if (this.props.address !== nextProps.address) {
      this.init(nextProps);
    }
  }

  init = (props) => {
    const newAddress = {};
    map(this.state.address, (value, key) => {
      newAddress[key] = !baseHelper.isEmpty(props.address[key]) ? props.address[key] : "";
    });

    this.setState({
      address: newAddress,
    });
  };
  /*******************************HANDLE INPUT***************************/
  onChangeAddressInput = (event) => {
    this.setState({
      address: {
        ...this.state.address,
        [event.target.name]: event.target.value,
      },
    });
  };

  /*****************************************************************/

  submit = () => {
    // Validate
    let errors = [];
    const inputsRef = compact(Array.from(this.inputsRef));
    map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });
    errors = errors.concat(this.addressPanelRef.current.validate());
    errors = compact(errors);
    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      window.LadiUI.toastCustom("danger", "", errorMessage);
      return;
    }

    const address = this.addressPanelRef.current.getData();

    if (this.props.mode === appConfig.FORM_MODE.CREATE) {
      let newAddress = {
        ...this.state.address,
        ...address,
      };

      newAddress = omit(newAddress, ["customer_address_id"]);

      this.props.onSubmit(newAddress);
    } else {
      const newAddress = {
        ...this.state.address,
        ...address,
      };
      this.props.onSubmit(newAddress);
    }
  };

  render() {
    const { t, onCancel, visible } = this.props;
    const { address } = this.state;
    const title = this.props.mode === appConfig.FORM_MODE.CREATE ? t("CUSTOMERS.ADD_ADDRESS") : t("CUSTOMERS.EDIT_ADDRESS");
    return (
      <Modal
        visible={visible}
        id="modal-address"
        zIndex={9999}
        title={title}
        onCancel={onCancel}
        onOk={this.submit}
        width={900}
        isLoading={this.props.loading}
      >
        <div className="ladiui-tab-content">
          <PanelAddress
            mode={this.props.mode}
            ref={this.addressPanelRef}
            address={address}
            // styleCustom={{ marginBottom: "10px" }}
          />
        </div>
      </Modal>
    );
  }
}

export default withTranslation("translation", { withRef: true })(ModalAddress);
