import React from 'react';
import PropTypes from 'prop-types';
import { find, remove, map } from 'lodash';
import { withTranslation } from 'react-i18next';
import Modal from './Modal';
import Input from './Input';
import { cloneDeep, compact } from 'lodash';
import baseHelper from '../helpers/BaseHelper';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';

import SelectOption from './SelectOption';

import appConfig from '../config/app';

class ModalInterActiveChannel extends React.Component {
    static propTypes = {
        onCancel: PropTypes.func,
        onOk: PropTypes.func,
        visible: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            name: props.dataSelect.name || '',
            chanels: props.dataSelect.socials || [],
        };

        this.inputsRef = new Set();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.dataSelect != nextProps.dataSelect) {
            this.setState({
                name: nextProps.dataSelect.name || '',
                chanels: nextProps.dataSelect.socials || [],
            });
        }
    }

    submit = () => {
        let { name } = this.state;
        let data = {
            name: name,
            socials: this.state.chanels,
            type: 'SOCIAL',
        };
        this.props.onSubmit(data, this.props.indexData);
    };

    validate = () => {
        // Validate
        const { t } = this.props;
        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = errors.concat(this.locationRef.current.validate());

        errors = compact(errors);

        return errors;
    };
    onChangeInput = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value,
        });
    };

    fileChangedHandler = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                this.props.uploadProductImages(form);
            }
        }
    };

    onChangeContent = (value) => {
        this.setState({
            content: value,
        });
    };

    handleAddChanel = () => {
        let _chanels = [...this.state.chanels];

        let defaultValue = {
            type: 'Zalo',
            url: '',
            placeholder: 'https://zalo.me/phone-number',
        };

        _chanels.push(defaultValue);

        this.setState({
            chanels: _chanels,
        });
    };

    onChangChanelItem = (data, indexSelected) => {
        let _chanels = [...this.state.chanels];
        _chanels.map((item, index) => {
            if (index == indexSelected) {
                item.type = data.name;
                item.placeholder = data.placeholder;
            }
        });

        this.setState({
            chanels: _chanels,
        });
    };

    onChangeChanelitem = (event, indexSelected) => {
        let { value } = event.target;
        let _chanels = [...this.state.chanels];
        _chanels.map((item, index) => {
            if (index == indexSelected) {
                item.url = value;
            }
        });

        this.setState({
            chanels: _chanels,
        });
    };

    getUrlChanelByType = (type) => {
        let url = '';
        switch (type) {
            case 'Messenger':
                url = 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-messenger.svg';
                break;
            case 'Facebook':
                url = 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-facebook.svg';
                break;
            case 'Zalo':
                url = 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-zalo.svg';
                break;
            case 'Instagram':
                url = 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-instagram.svg';
                break;
            case 'Gmail':
                url = 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-gmail.svg';
                break;
            case 'Telegram':
                url = 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-telegram.svg';
                break;
            case 'Skype':
                url = 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-skype.svg';
                break;
            case 'Phone':
                url = 'https://w.ladicdn.com/ladiui/icons/ldicon-colored-call.svg';
                break;
            default:
        }

        return url;
    };

    handleDeleteChanel = (index) => {
        let _chanels = [...this.state.chanels];

        _chanels.splice(index, 1);

        this.setState({
            chanels: _chanels,
        });
    };

    listChanelItem = () => {
        let { chanels } = this.state;
        return chanels.map((item, index) => {
            return (
                <tr key={index} className='ladiui table-vertical'>
                    <td width={100}>
                        <SelectOption
                            wrapperClassName='select-assignee w100 position-initial'
                            innerClassName='ellipsis flex'
                            customClassName={'position-initial'}
                            currentValue={item.type}
                            logoChanel={this.getUrlChanelByType(item.type)}
                            _key='key'
                            _value='name'
                            items={appConfig.LIST_CHANELS || []}
                            innerStyle={{
                                overflow: 'hidden',
                                width: 160,
                                maxWidth: 160,
                            }}
                            idBlock='dropdown-active-chanel-custom'
                            onSelectItem={(data) => {
                                this.onChangChanelItem(data, index);
                            }}
                        />
                    </td>
                    <td>
                        <Input
                            value={item.url}
                            placeholder={item.placeholder}
                            onChange={(event) => this.onChangeChanelitem(event, index)}
                        />
                    </td>
                    <td className='text-right'>
                        <a onClick={() => this.handleDeleteChanel(index)}>
                            <i className='ladi-icon icon-bin'></i>
                        </a>
                    </td>
                </tr>
            );
        });
    };

    render() {
        const { t, visible, onCancel } = this.props;
        const { name, content, chanels } = this.state;
        return (
            <Modal
                id='modal-interactive-chanel'
                visible={visible}
                onCancel={onCancel}
                bodyClassName='position-initial'
                onOk={this.submit}
                title='Kênh tương tác'
                width={600}
            >
                <div>
                    <div className='ladiui form-group'>
                        <label className='ladiui-label'>Tên gợi nhớ</label>
                        <Input
                            name='name'
                            validationName='nhập tên hiển thị'
                            placeholder='Nhập tóm tắt'
                            value={name}
                            onChange={this.onChangeInput}
                            ref={(ref) => this.inputsRef.add(ref)}
                            validations={{ isRequired: true }}
                        />
                    </div>
                    <div className='block-list-chanels'>
                        <h3 className='title'>
                            Kênh tương tác ({chanels.length}/{appConfig.LIST_CHANELS.length})
                        </h3>
                        {chanels && chanels.length > 0 && (
                            <div className='list-chanels'>
                                <table className='ladiui table text-left table-custom'>
                                    <thead>
                                        <tr className='ladiui table-vertical header'>
                                            <th className='text-left pl-0' style={{ paddingLeft: '0!important' }}>
                                                Loại kênh
                                            </th>
                                            <th className='text-left'>Đường dẫn</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>{this.listChanelItem()}</tbody>
                                </table>
                            </div>
                        )}
                        {this.state.chanels && this.state.chanels.length < 7 && (
                            <div className='ladiui border-none btn-add-option mb-12 mt-24' onClick={() => this.handleAddChanel()}>
                                <img className='mr-8' src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg' />
                                <div className='btn-add-product'>Thêm kênh tương tác</div>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => { };

const mapStateToProps = (state) => ({});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation', { withRef: true })(ModalInterActiveChannel))
);
