import React from "react";
import PropTypes from "prop-types";
import Input from "./Input";
import baseHelper from "../helpers/BaseHelper";
import { withTranslation } from "react-i18next";
import socket from "../socket";
import appConfig from "../config/app";
import { connect } from "react-redux";

import { appLocalStorage } from "../localforage";

import ContentEditable from "react-contenteditable";
import { map, cloneDeep, find, includes, findIndex } from "lodash";
import ConfirmModal from "./ConfirmModal";

import customerHistoryActions from "../redux/futures/customer_history/actions";
import * as customerHistoryTypes from "../redux/futures/customer_history/types";

import orderHistoryActions from "../redux/futures/order_history/actions";
import * as orderHistoryTypes from "../redux/futures/order_history/types";

import shippingHistoryActions from "../redux/futures/shipping_history/actions";
import * as shippingHistoryTypes from "../redux/futures/shipping_history/types";

class History extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    customerID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    orderID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    shippingID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    discountID: PropTypes.string,
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      content: "",
      loadingSubmit: false,
      loadingUpdate: false,
      loadingData: false,
      loadingDelete: false,
      userInfo: {},
      histories: [],
      selectedID: "",
    };

    this.paged = 1;
    this.room = "";
  }

  componentDidMount() {
    this.list();

    this._isMounted = true;

    // add event show hide

    appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
      if (this._isMounted) {
        switch (this.props.type) {
          case appConfig.HISTORY_FORM.SHIPPING:
            this.room = `${this.props.shippingID}_${appConfig.SOCKET.ROOMS.HISTORY_SHIPPING}`;
            break;
          case appConfig.HISTORY_FORM.ORDER:
            this.room = `${this.props.orderID}_${appConfig.SOCKET.ROOMS.HISTORY_ORDER}`;
            break;
          case appConfig.HISTORY_FORM.CUSTOMER:
            this.room = `${this.props.customerID}_${appConfig.SOCKET.ROOMS.HISTORY_CUSTOMER}`;
            break;
        }

        this.setState(
          {
            userInfo: result,
          },
          () => {
            this.subscribe();
          }
        );
      }
    });

    const self = this;

    switch (this.props.type) {
      case appConfig.HISTORY_FORM.SHIPPING:
        socket.getInstance().on(appConfig.SOCKET.EVENTS.NEW_SHIPPING_HISTORY, (data) => {
          self.wsNew(data);
        });
        socket.getInstance().on(appConfig.SOCKET.EVENTS.UPDATED_SHIPPING_HISTORY, (data) => {
          self.wsUpdated(data);
        });

        socket.getInstance().on(appConfig.SOCKET.EVENTS.DELETED_SHIPPING_HISTORY, (data) => {
          self.wsDeleted(data);
        });
        break;
      case appConfig.HISTORY_FORM.ORDER:
        socket.getInstance().on(appConfig.SOCKET.EVENTS.NEW_ORDER_HISTORY, (data) => {
          self.wsNew(data);
        });
        socket.getInstance().on(appConfig.SOCKET.EVENTS.UPDATED_ORDER_HISTORY, (data) => {
          self.wsUpdated(data);
        });

        socket.getInstance().on(appConfig.SOCKET.EVENTS.DELETED_ORDER_HISTORY, (data) => {
          self.wsDeleted(data);
        });
        break;
      case appConfig.HISTORY_FORM.CUSTOMER:
        socket.getInstance().on(appConfig.SOCKET.EVENTS.NEW_CUSTOMER_HISTORY, (data) => {
          self.wsNew(data);
        });

        socket.getInstance().on(appConfig.SOCKET.EVENTS.UPDATED_CUSTOMER_HISTORY, (data) => {
          self.wsUpdated(data);
        });

        socket.getInstance().on(appConfig.SOCKET.EVENTS.DELETED_CUSTOMER_HISTORY, (data) => {
          self.wsDeleted(data);
        });
        break;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    socket.getInstance().emit(appConfig.SOCKET.EVENTS.LEAVE_HISTORY, { room: this.room });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props === nextProps) {
      return;
    }

    /** CUSTOMER */
    if (
      includes(
        [
          customerHistoryTypes.WS_NEW_CUSTOMER_HISTORY,
          customerHistoryTypes.WS_UPDATED_CUSTOMER_HISTORY,
          customerHistoryTypes.WS_DELETED_CUSTOMER_HISTORY,
        ],
        nextProps.customerHistoryReducer.action
      )
    ) {
      this.setState({
        histories: cloneDeep(nextProps.customerHistoryReducer.histories),
      });
    }

    if (this.props.customerHistoryReducer.action != nextProps.customerHistoryReducer.action) {
      if (nextProps.customerHistoryReducer.action == customerHistoryTypes.LIST_CUSTOMER_HISTORY) {
        this.setState({
          loadingData: false,
          histories: cloneDeep(nextProps.customerHistoryReducer.histories),
        });
      }

      if (nextProps.customerHistoryReducer.action == customerHistoryTypes.CREATE_CUSTOMER_HISTORY) {
        if (nextProps.customerHistoryReducer.status) {
          this.list();
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.customerHistoryReducer.message, "OK");
        }

        this.setState({
          loadingSubmit: false,
          content: "",
        });
      }

      if (nextProps.customerHistoryReducer.action == customerHistoryTypes.UPDATE_CUSTOMER_HISTORY) {
        if (nextProps.customerHistoryReducer.status) {
          this.list();
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.customerHistoryReducer.message, "OK");
        }

        this.setState({
          loadingUpdate: false,
        });
      }

      if (nextProps.customerHistoryReducer.action == customerHistoryTypes.DELETE_CUSTOMER_HISTORY) {
        if (nextProps.customerHistoryReducer.status) {
          window.LadiUI.toastCustom("success", "", nextProps.storeReducer.message);
          window.LadiUI.closeModal("confirm-history-delete");
          // this.list();
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.customerHistoryReducer.message, "OK");
        }

        this.setState({
          loadingDelete: false,
          selectedID: "",
        });
      }
    }

    /** ORDER */
    if (
      includes(
        [orderHistoryTypes.WS_NEW_ORDER_HISTORY, orderHistoryTypes.WS_UPDATED_ORDER_HISTORY, orderHistoryTypes.WS_DELETED_ORDER_HISTORY],
        nextProps.orderHistoryReducer.action
      )
    ) {
      this.setState({
        histories: cloneDeep(nextProps.orderHistoryReducer.histories),
      });
    }

    if (this.props.orderHistoryReducer.action != nextProps.orderHistoryReducer.action) {
      if (nextProps.orderHistoryReducer.action == orderHistoryTypes.LIST_ORDER_HISTORY) {
        this.setState({
          loadingData: false,
          histories: cloneDeep(nextProps.orderHistoryReducer.histories),
        });
      }

      if (nextProps.orderHistoryReducer.action == orderHistoryTypes.CREATE_ORDER_HISTORY) {
        if (nextProps.orderHistoryReducer.status) {
          this.list();
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.orderHistoryReducer.message, "OK");
        }

        this.setState({
          loadingSubmit: false,
          content: "",
        });
      }

      if (nextProps.orderHistoryReducer.action == orderHistoryTypes.UPDATE_ORDER_HISTORY) {
        if (nextProps.orderHistoryReducer.status) {
          this.list();
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.customerHistoryReducer.message, "OK");
        }

        this.setState({
          loadingUpdate: false,
        });
      }

      if (nextProps.orderHistoryReducer.action == orderHistoryTypes.DELETE_ORDER_HISTORY) {
        if (nextProps.orderHistoryReducer.status) {
          window.LadiUI.toastCustom("success", "", nextProps.storeReducer.message);
          window.LadiUI.closeModal("confirm-history-delete");
          // this.list();
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.customerHistoryReducer.message, "OK");
        }

        this.setState({
          loadingDelete: false,
          selectedID: "",
        });
      }
    }

    /** SHIPPING */
    if (
      includes(
        [
          shippingHistoryTypes.WS_NEW_SHIPPING_HISTORY,
          shippingHistoryTypes.WS_UPDATED_SHIPPING_HISTORY,
          shippingHistoryTypes.WS_DELETED_SHIPPING_HISTORY,
        ],
        nextProps.shippingHistoryReducer.action
      )
    ) {
      this.setState({
        histories: cloneDeep(nextProps.shippingHistoryReducer.histories),
      });
    }

    if (this.props.shippingHistoryReducer.action != nextProps.shippingHistoryReducer.action) {
      if (nextProps.shippingHistoryReducer.action == shippingHistoryTypes.LIST_SHIPPING_HISTORY) {
        this.setState({
          loadingData: false,
          histories: cloneDeep(nextProps.shippingHistoryReducer.histories),
        });
      }

      if (nextProps.shippingHistoryReducer.action == shippingHistoryTypes.CREATE_SHIPPING_HISTORY) {
        if (nextProps.shippingHistoryReducer.status) {
          this.list();
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.customerHistoryReducer.message, "OK");
        }

        this.setState({
          loadingSubmit: false,
          content: "",
        });
      }

      if (nextProps.shippingHistoryReducer.action == shippingHistoryTypes.UPDATE_SHIPPING_HISTORY) {
        if (nextProps.shippingHistoryReducer.status) {
          this.list();
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.customerHistoryReducer.message, "OK");
        }

        this.setState({
          loadingUpdate: false,
        });
      }

      if (nextProps.shippingHistoryReducer.action == shippingHistoryTypes.DELETE_SHIPPING_HISTORY) {
        if (nextProps.shippingHistoryReducer.status) {
          window.LadiUI.toastCustom("success", "", nextProps.storeReducer.message);
          window.LadiUI.closeModal("confirm-history-delete");
          // this.list();
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.customerHistoryReducer.message, "OK");
        }

        this.setState({
          loadingDelete: false,
          selectedID: "",
        });
      }
    }
  }

  subscribe() {
    socket.getInstance().emit(appConfig.SOCKET.EVENTS.JOIN_HISTORY, { room: this.room });
  }

  wsNew = (data) => {
    let matched;
    let histories;
    switch (this.props.type) {
      case appConfig.HISTORY_FORM.ORDER:
        if (data.order_id != this.props.orderID) {
          return;
        }
        matched = find(this.props.orderHistoryReducer.histories, (item) => item.order_history_id == data.order_history_id);
        if (matched) {
          return;
        }
        histories = cloneDeep(this.props.orderHistoryReducer.histories || []);
        histories.unshift(data);

        this.props.wsNewOrderHistory(histories);
        break;
      case appConfig.HISTORY_FORM.CUSTOMER:
        if (data.customer_id != this.props.customerID) {
          return;
        }
        matched = find(this.props.customerHistoryReducer.histories, (item) => item.customer_history_id == data.customer_history_id);
        if (matched) {
          return;
        }
        histories = cloneDeep(this.props.customerHistoryReducer.histories || []);
        histories.unshift(data);

        this.props.wsNewCustomerHistory(histories);
        break;
      case appConfig.HISTORY_FORM.SHIPPING:
        if (data.shipping_id != this.props.shippingID) {
          return;
        }
        matched = find(this.props.shippingHistoryReducer.histories, (item) => item.shipping_history_id == data.shipping_history_id);
        if (matched) {
          return;
        }
        histories = cloneDeep(this.props.shippingHistoryReducer.histories || []);
        histories.unshift(data);

        this.props.wsNewShippingHistory(histories);
        break;
    }
  };

  wsUpdated = (data) => {
    let index;
    let histories;
    switch (this.props.type) {
      case appConfig.HISTORY_FORM.ORDER:
        index = findIndex(this.props.orderHistoryReducer.histories, (item) => item.order_history_id == data.order_history_id);
        if (index < 0) {
          return;
        }
        histories = cloneDeep(this.props.orderHistoryReducer.histories || []);
        histories[index].content = data.content;

        this.props.wsUpdatedOrderHistory(histories);
        break;
      case appConfig.HISTORY_FORM.CUSTOMER:
        index = findIndex(this.props.customerHistoryReducer.histories, (item) => item.customer_history_id == data.customer_history_id);
        if (index < 0) {
          return;
        }
        histories = cloneDeep(this.props.customerHistoryReducer.histories || []);
        histories[index].content = data.content;

        this.props.wsUpdatedCustomerHistory(histories);
        break;
      case appConfig.HISTORY_FORM.SHIPPING:
        index = findIndex(this.props.shippingHistoryReducer.histories, (item) => item.shipping_history_id == data.shipping_history_id);
        if (index < 0) {
          return;
        }
        histories = cloneDeep(this.props.shippingHistoryReducer.histories || []);
        histories[index].content = data.content;

        this.props.wsUpdatedShippingHistory(histories);
        break;
    }
  };

  wsDeleted = (data) => {
    let index;
    let histories;
    switch (this.props.type) {
      case appConfig.HISTORY_FORM.ORDER:
        index = findIndex(this.props.orderHistoryReducer.histories, (item) => item.order_history_id == data.order_history_id);
        if (index < 0) {
          return;
        }
        histories = cloneDeep(this.props.orderHistoryReducer.histories || []);
        histories.splice(index, 1);

        this.props.wsDeletedOrderHistory(histories);
        break;
      case appConfig.HISTORY_FORM.CUSTOMER:
        index = findIndex(this.props.customerHistoryReducer.histories, (item) => item.customer_history_id == data.customer_history_id);
        if (index < 0) {
          return;
        }
        histories = cloneDeep(this.props.customerHistoryReducer.histories || []);
        histories.splice(index, 1);

        this.props.wsDeletedCustomerHistory(histories);
        break;
      case appConfig.HISTORY_FORM.SHIPPING:
        index = findIndex(this.props.shippingHistoryReducer.histories, (item) => item.shipping_history_id == data.shipping_history_id);
        if (index < 0) {
          return;
        }
        histories = cloneDeep(this.props.shippingHistoryReducer.histories || []);
        histories.splice(index, 1);

        this.props.wsDeletedShippingHistory(histories);
        break;
    }
  };

  submit = () => {
    const { content } = this.state;
    switch (this.props.type) {
      case appConfig.HISTORY_FORM.ORDER:
        this.props.createOrderHistory(this.props.orderID, content);
        break;
      case appConfig.HISTORY_FORM.CUSTOMER:
        this.props.createCustomerHistory(this.props.customerID, content);
        break;
      case appConfig.HISTORY_FORM.SHIPPING:
        this.props.createShippingHistory(this.props.shippingID, content);
        break;
    }

    this.setState({
      loadingSubmit: true,
    });
  };

  updateContent = (index) => {
    const history = this.state.histories[index];
    const content = baseHelper.decodeEntities(history.content);
    switch (this.props.type) {
      case appConfig.HISTORY_FORM.ORDER:
        this.props.updateOrderHistory(this.props.orderID, history.order_history_id, content);
        break;
      case appConfig.HISTORY_FORM.CUSTOMER:
        this.props.updateCustomerHistory(this.props.customerID, history.customer_history_id, content);
        break;
      case appConfig.HISTORY_FORM.SHIPPING:
        this.props.updateShippingHistory(this.props.shippingID, history.shipping_history_id, content);
        break;
    }

    this.setState({
      loadingUpdate: true,
    });
  };

  list = () => {
    const { paged } = this;
    switch (this.props.type) {
      case appConfig.HISTORY_FORM.ORDER:
        this.props.listOrderHistory(this.props.orderID, paged);
        break;
      case appConfig.HISTORY_FORM.CUSTOMER:
        this.props.listCustomerHistory(this.props.customerID, paged);
        break;
      case appConfig.HISTORY_FORM.SHIPPING:
        this.props.listShippingHistory(this.props.shippingID, paged);
        break;
    }

    this.setState({
      loadingData: true,
    });
  };

  enableEditItem = (index) => {
    const { histories } = this.state;
    histories[index].editable = true;

    this.setState({
      histories,
    });
  };

  disableEditItem = (index) => {
    const { histories } = this.state;

    switch (this.props.type) {
      case appConfig.HISTORY_FORM.ORDER:
        histories[index] = cloneDeep(this.props.orderHistoryReducer.histories[index]);
        break;
      case appConfig.HISTORY_FORM.CUSTOMER:
        histories[index] = cloneDeep(this.props.customerHistoryReducer.histories[index]);
        break;
      case appConfig.HISTORY_FORM.SHIPPING:
        histories[index] = cloneDeep(this.props.shippingHistoryReducer.histories[index]);
        break;
    }

    this.setState({
      histories,
    });
  };

  openModalConfirmDelete = (index) => {
    const history = this.state.histories[index];

    switch (this.props.type) {
      case appConfig.HISTORY_FORM.ORDER:
        this.setState(
          {
            selectedID: history.order_history_id,
          },
          () => {
            window.LadiUI.showModal("confirm-history-delete");
          }
        );
        break;
      case appConfig.HISTORY_FORM.CUSTOMER:
        this.setState(
          {
            selectedID: history.customer_history_id,
          },
          () => {
            window.LadiUI.showModal("confirm-history-delete");
          }
        );
        break;
      case appConfig.HISTORY_FORM.SHIPPING:
        this.setState(
          {
            selectedID: history.shipping_history_id,
          },
          () => {
            window.LadiUI.showModal("confirm-history-delete");
          }
        );
        break;
    }
  };

  delete = () => {
    this.setState({
      loadingDelete: true,
    });

    switch (this.props.type) {
      case appConfig.HISTORY_FORM.ORDER:
        this.props.deleteOrderHistory(this.props.orderID, this.state.selectedID);
        break;
      case appConfig.HISTORY_FORM.CUSTOMER:
        this.props.deleteCustomerHistory(this.props.customerID, this.state.selectedID);
        break;
      case appConfig.HISTORY_FORM.SHIPPING:
        this.props.deleteShippingHistory(this.props.shippingID, this.state.selectedID);
        break;
    }
  };

  getContentPanel = (data) => {
    if (!baseHelper.isJson(data)) {
      return <h4>{data}</h4>;
    }
    const { title, content } = JSON.parse(data);
    return (
      <div className="expand">
        <h4 className="title">
          {title}
          <i className="ladi-icon icon-triangle-right" />
        </h4>
        <div className="item">
          {map(content, (value, key) => {
            return (
              <div key={key}>
                <div className="key">{key}</div>
                <div className="value">{value}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    let placeholder = "";
    let hasShowMore = false;

    switch (this.props.type) {
      case appConfig.HISTORY_FORM.ORDER:
        hasShowMore = this.props.orderHistoryReducer.hasShowMore;
        placeholder = t("HISTORY.PLACEHOLDER_COMMENT", t("HISTORY.ORDER"));
        break;
      case appConfig.HISTORY_FORM.CUSTOMER:
        hasShowMore = this.props.customerHistoryReducer.hasShowMore;
        placeholder = t("HISTORY.PLACEHOLDER_COMMENT", t("HISTORY.CUSTOMER"));
        break;
      case appConfig.HISTORY_FORM.SHIPPING:
        hasShowMore = this.props.shippingHistoryReducer.hasShowMore;
        placeholder = t("HISTORY.PLACEHOLDER_COMMENT", t("HISTORY.SHIPPING"));
        break;
    }

    return (
      <div className="timeline">
        <div className="top">
          <div>
            <object
              className="avatar"
              data={`${appConfig.DOMAIN_CDN}/luid/avatar/${this.state.userInfo.ladi_uid}-avatar.png`}
              type="image/png"
            >
              <span className="noavatar">
                {baseHelper.getDisplayName(baseHelper.getStaffNameById(this.state.userInfo.staffs, this.state.userInfo.ladi_uid))}
              </span>
            </object>
          </div>
          <div className="input-text input-history">
            <Input
              placeholder={placeholder}
              value={this.state.content}
              className="input input-custom"
              onChange={(event) => {
                this.setState({
                  content: event.target.value,
                });
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.submit();
                }
              }}
            />
            <div className="comment">
              <span onClick={this.submit}>
                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.01719 0.357949L9.39219 3.73295C9.4615 3.80463 9.50024 3.90043 9.50024 4.00014C9.50024 4.09984 9.4615 4.19565 9.39219 4.26732L6.01719 7.64232C5.94433 7.70212 5.85183 7.73268 5.75768 7.72806C5.66354 7.72343 5.57448 7.68396 5.50783 7.61731C5.44118 7.55066 5.4017 7.4616 5.39708 7.36745C5.39245 7.27331 5.42301 7.18081 5.48281 7.10795L8.22031 4.37514L0.875 4.37514C0.775544 4.37514 0.680161 4.33563 0.609835 4.2653C0.539509 4.19498 0.5 4.09959 0.5 4.00014C0.5 3.90068 0.539509 3.8053 0.609835 3.73497C0.680161 3.66464 0.775544 3.62514 0.875 3.62514L8.22031 3.62514L5.48281 0.892323C5.42301 0.819463 5.39245 0.726964 5.39708 0.632819C5.4017 0.538675 5.44118 0.449615 5.50783 0.382965C5.57448 0.316314 5.66354 0.276838 5.75768 0.272214C5.85183 0.26759 5.94433 0.29815 6.01719 0.357949Z"
                    fill="white"
                  />
                </svg>
              </span>

              {/* <button onClick={this.submit} className={`ladiui btn btn-primary ${this.state.loadingSubmit ? 'loader' : ''}`} disabled={baseHelper.isEmpty(this.state.content)}>{t('COMMON.COMMENT')}</button> */}
            </div>
          </div>
        </div>
        {this.state.histories.length != 0 && (
          <div className={`${this.state.loadingData ? "loader" : ""}`}>
            <div className={`content`}>
              <ul className="timeline-new">
                {map(this.state.histories, (item, index) => {
                  return (
                    <li key={index}>
                      <div className="item-avt-left">
                        <div className="mr-8">
                          <object
                            className="avatar"
                            data={`${appConfig.DOMAIN_CDN}/luid/avatar/${item.creator_id}-avatar.png`}
                            type="image/png"
                          >
                            <span className="noavatar">
                              {baseHelper.getDisplayName(baseHelper.getStaffNameById(this.state.userInfo.staffs, item.creator_id))}
                            </span>
                          </object>
                        </div>
                        {/* {
                                            item.type == appConfig.HISTORY_TYPE.COMMENT
                                            &&
                                            <div className="bullet green" />
                                        } */}
                        {/* {
                                            includes([appConfig.HISTORY_TYPE.HISTORY, appConfig.HISTORY_TYPE.CANCEL_ORDER], item.type)
                                            &&
                                            <div className="bullet orange" />
                                        } */}
                        <div className="desc">
                          <h3>{baseHelper.getStaffNameById(this.state.userInfo.staffs, item.creator_id)}</h3>
                          {includes([appConfig.HISTORY_TYPE.HISTORY, appConfig.HISTORY_TYPE.CANCEL_ORDER], item.type) &&
                            this.getContentPanel(item.content)}
                          {item.type == appConfig.HISTORY_TYPE.COMMENT &&
                            (item.editable ? (
                              <div className="ladiui-input-group">
                                <div className="wrapper-input">
                                  <ContentEditable
                                    html={baseHelper.encodeEntities(item.content) || ""}
                                    disabled={false}
                                    onChange={(event) => {
                                      const { histories } = this.state;
                                      histories[index].content = baseHelper.decodeEntities(event.target.value);
                                      this.setState({
                                        histories,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="ladiui-input-group-prepend">
                                  <span style={{ display: "flex", alignItems: "center" }}>
                                    <button
                                      onClick={() => {
                                        this.disableEditItem(index);
                                      }}
                                      className="action ladiui btn btn-secondary"
                                    >
                                      {t("ACTIONS.CANCEL")}
                                    </button>
                                    <button
                                      disabled={baseHelper.isEmpty(item.content)}
                                      style={{ justifyContent: "center" }}
                                      onClick={() => {
                                        this.updateContent(index);
                                      }}
                                      className={`action ladiui btn btn-primary ${this.state.loadingUpdate ? "loader" : ""}`}
                                    >
                                      {t("ACTIONS.SAVE")}
                                    </button>
                                  </span>
                                </div>
                              </div>
                            ) : (
                              this.getContentPanel(item.content)
                            ))}
                        </div>
                      </div>

                      <div className="item-time-right">
                        <div className="time">{baseHelper.formatStrToDate(item.created_at)}</div>

                        {item.type == appConfig.HISTORY_TYPE.COMMENT && this.state.userInfo.ladi_uid == item.creator_id && (
                          <div>
                            <div className="ladiui btn-group list-comment-history">
                              <div className="ladiui dropdown hide-mt ba-c">
                                <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle" style={{ minWidth: "auto" }}>
                                  <i className="ladiui icon icon-ldp-dot"></i>
                                </button>
                                <ul className="ladiui dropdown-menu">
                                  <li>
                                    <a className="ladiui dropdown-item" onClick={() => this.enableEditItem(index)}>
                                      {t("ACTIONS.EDIT")}
                                    </a>
                                  </li>
                                  <li>
                                    <a className="ladiui dropdown-item" onClick={() => this.openModalConfirmDelete(index)}>
                                      {t("ACTIONS.DELETE")}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}

        {hasShowMore && (
          <div className="showmore">
            <a
              onClick={() => {
                this.paged += 1;

                this.list();
              }}
            >
              {t("COMMON.SHOWMORE")}
            </a>
          </div>
        )}

        <ConfirmModal
          id="confirm-history-delete"
          title={t("HISTORY.MSG_DELETE_HISTORY_TITLE")}
          content={t("HISTORY.MSG_DELETE_HISTORY_CONTENT")}
          cancelText={t("ACTIONS.CANCEL")}
          okText={t("ACTIONS.DELETE")}
          onOk={this.delete}
          isLoading={this.state.loadingDelete}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listCustomerHistory: (customerID, paged) => dispatch(customerHistoryActions.list(customerID, paged)),
    createCustomerHistory: (customerID, content) => dispatch(customerHistoryActions.create(customerID, content)),
    updateCustomerHistory: (customerID, customerHistoryID, content) =>
      dispatch(customerHistoryActions.update(customerID, customerHistoryID, content)),
    deleteCustomerHistory: (customerID, customerHistoryID) => dispatch(customerHistoryActions.delete(customerID, customerHistoryID)),
    wsNewCustomerHistory: (histories) => dispatch(customerHistoryActions.wsNew(histories)),
    wsUpdatedCustomerHistory: (histories) => dispatch(customerHistoryActions.wsUpdated(histories)),
    wsDeletedCustomerHistory: (histories) => dispatch(customerHistoryActions.wsDeleted(histories)),
    listOrderHistory: (orderID, paged) => dispatch(orderHistoryActions.list(orderID, paged)),
    createOrderHistory: (orderID, content) => dispatch(orderHistoryActions.create(orderID, content)),
    updateOrderHistory: (orderID, orderHistoryID, content) => dispatch(orderHistoryActions.update(orderID, orderHistoryID, content)),
    deleteOrderHistory: (orderID, orderHistoryID) => dispatch(orderHistoryActions.delete(orderID, orderHistoryID)),
    wsNewOrderHistory: (histories) => dispatch(orderHistoryActions.wsNew(histories)),
    wsUpdatedOrderHistory: (histories) => dispatch(orderHistoryActions.wsUpdated(histories)),
    wsDeletedOrderHistory: (histories) => dispatch(orderHistoryActions.wsDeleted(histories)),
    listShippingHistory: (shippingID, paged) => dispatch(shippingHistoryActions.list(shippingID, paged)),
    createShippingHistory: (shippingID, content) => dispatch(shippingHistoryActions.create(shippingID, content)),
    updateShippingHistory: (shippingID, shippingHistoryID, content) =>
      dispatch(shippingHistoryActions.update(shippingID, shippingHistoryID, content)),
    deleteShippingHistory: (shippingID, shippingHistoryID) => dispatch(shippingHistoryActions.delete(shippingID, shippingHistoryID)),
    wsNewShippingHistory: (histories) => dispatch(shippingHistoryActions.wsNew(histories)),
    wsUpdatedShippingHistory: (histories) => dispatch(shippingHistoryActions.wsUpdated(histories)),
    wsDeletedShippingHistory: (histories) => dispatch(shippingHistoryActions.wsDeleted(histories)),
  };
};

const mapStateToProps = (state) => ({
  storeReducer: { ...state.store },
  orderHistoryReducer: { ...state.orderHistory },
  customerHistoryReducer: { ...state.customerHistory },
  discountHistoryReducer: { ...state.discountHistory },
  shippingHistoryReducer: { ...state.shippingHistory },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withTranslation("translation", { withRef: true })(History)
);
