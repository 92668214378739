import appConfig, { status } from "../../config/app";
import i18n from "../../i18n";
import baseHelper from "../../helpers/BaseHelper";
import { SIGNOUT, GET_USER_INFO } from "../futures/auth/types";
import { authEndpoint } from "../../config/config";
import { has } from "lodash";

const cookiesMiddleware = (store) => (next) => (action) => {
  if (has(action, "new_store_id")) {
    const oldStoreID = baseHelper.getCookie(appConfig.COOKIE.STORE_ID);
    if (oldStoreID != action.new_store_id) {
      baseHelper.setCookie(appConfig.COOKIE.STORE_ID, action.new_store_id);
      baseHelper.setCookie('is_reload_cookie', true)
      window.location.reload();
      return;
    }
  }

  if (action.type === SIGNOUT) {
    let url = window.location.href;
    var resetCookieLogout = function () {
      try {
        if (typeof Storage != "undefined" && Storage != undefined) {
          localStorage.clear();
          sessionStorage.clear();
        }
      } catch (e) {
        //no code
      }

      var cookies = document.cookie.split(";");
      var excepts = ["LANGUAGE", "not_show_message"];

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var index = cookie.indexOf("=");
        var name = index > -1 ? cookie.substr(0, index) : cookie;
        name = name.trim();

        if (excepts.indexOf(name) != -1) {
          continue;
        }

        var str = name + " =; expires = Thu, 01 Jan 1970 00:00:00 GMT; path = /";
        document.cookie = str;

        if (window.location.protocol == "https:") {
          str += "; SameSite = None; secure";
        }

        document.cookie = str;
      }
    };
    resetCookieLogout();

    if (url.includes("app.ipay.com.vn")) {
      let urlRedirect = "https://accounts.ladiuid.com/signin?logout=1&callback_url=http://app.ipay.com.vn&app_code=ls";
      window.location.href = urlRedirect;
    } else {
      window.location.href = authEndpoint.AUTH_SIGNOUT;
    }
  }
  if (action.type === GET_USER_INFO && !action.no_store) {
    baseHelper.setCookie('is_reload_cookie', false)

    i18n.changeLanguage(action.payload.language);
  }

  next(action);
};

export default cookiesMiddleware;
