/** @format */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import moment from 'moment';

import fileActions from '../../../redux/futures/file/actions';
import * as fileTypes from '../../../redux/futures/file/types';

import { includes, isEmpty, map, remove } from 'lodash';
import Input from '../../../components/Input';
import SelectOption from '../../../components/SelectOption';

import BaseHelper from '../../../helpers/BaseHelper';

import appConfig from '../../../config/app';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const CDN = 'https://w.ladicdn.com/';

const PanelStoreInfo = forwardRef((props, ref) => {
    let { isShow, currentDataStoreInfo, isReloadIframe } = props;
    const location = useLocation();
    const history = useHistory();
    const queryStrings = new URLSearchParams(location.search);

    const formRef = React.createRef();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const fileReducer = useSelector((state) => state.file);

    const [dataStore, setDataStore] = useState({
        banner: '',
        logo: '',
        store_name: '',
        store_address: '',
        store_phone: '',
        review: 5,
        product_sold: '',
        status: true
    });

    const [chanels, setChanels] = useState([]);

    const [urlPublished, setUrlPublished] = useState(props.urlPublished);

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return {
                ...dataStore,
                interactive_channels: chanels,
            };
        },
    }));

    useEffect(() => {
        let iFrame = document.getElementById("iframe-store-info");

        if (iFrame) {
            iFrame.src = iFrame.src;
            // iFrame.contentWindow.location.reload();
        }
    }, [isReloadIframe]);

    useEffect(() => {
        if (props.urlPublished) {
            setUrlPublished(props.urlPublished);
        }
    }, [props.urlPublished])

    useEffect(() => {
        if (currentDataStoreInfo && !isEmpty(currentDataStoreInfo)) {
            setDataStore({
                banner: currentDataStoreInfo.banner ? currentDataStoreInfo.banner : '',
                logo: currentDataStoreInfo.logo ? currentDataStoreInfo.logo : '',
                product_sold: currentDataStoreInfo.quantity_of_products_sold
                    ? parseInt(currentDataStoreInfo.quantity_of_products_sold)
                    : '',
                store_address: currentDataStoreInfo.address ? currentDataStoreInfo.address : '',
                store_phone: currentDataStoreInfo.phone ? currentDataStoreInfo.phone : '',
                review: currentDataStoreInfo.reviews ? currentDataStoreInfo.reviews : '',
                store_name: currentDataStoreInfo.store_name ? currentDataStoreInfo.store_name : '',
                status: currentDataStoreInfo.status ? currentDataStoreInfo.status : '',
            });

            setChanels(currentDataStoreInfo.interactive_channels || []);
        }
    }, [currentDataStoreInfo]);

    useEffect(() => {
        if (fileReducer.action == fileTypes.UPLOAD_THUMBNAIL) {
            if (fileReducer.status) {
                if (fileReducer.image_url) {
                    setDataStore({
                        ...dataStore,
                        banner: fileReducer.image_url,
                    });
                }
            }
        }
        if (fileReducer.action == fileTypes.UPLOAD_LOGO_URL) {
            if (fileReducer.status) {
                if (fileReducer.logo_url) {
                    setDataStore({
                        ...dataStore,
                        logo: fileReducer.logo_url,
                    });
                }
            }
        }
    }, [fileReducer]);

    const onChangeInput = (event) => {
        let { name, value } = event.target;
        setDataStore((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onChangeUrlChanelitem = (event, indexSelected) => {
        let { value } = event.target;
        let _chanels = [...chanels];
        _chanels.map((item, index) => {
            if (index == indexSelected) {
                item.url = value;
            }
        });
        setChanels(_chanels);
    };

    const onChangeChanelItem = (data, indexSelected) => {
        let _chanels = [...chanels];
        _chanels.map((item, index) => {
            if (index == indexSelected) {
                item.type = data.name;
                item.placeholder = data.placeholder;
            }
        });
        setChanels(_chanels);
    };

    const handleDeleteChanel = (index) => {
        let _chanels = [...chanels];
        _chanels.splice(index, 1);
        setChanels(_chanels);
    };

    const getUrlChanelByType = (type) => {
        let url = '';
        switch (type) {
            case 'Messenger':
                url = 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-messenger.svg';
                break;
            case 'Facebook':
                url = 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-facebook.svg';
                break;
            case 'Zalo':
                url = 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-zalo.svg';
                break;
            case 'Instagram':
                url = 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-instagram.svg';
                break;
            case 'Gmail':
                url = 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-gmail.svg';
                break;
            case 'Telegram':
                url = 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-telegram.svg';
                break;
            case 'Skype':
                url = 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-skype.svg';
                break;
            case 'Phone':
                url = 'https://w.ladicdn.com/ladiui/icons/ldicon-colored-call.svg';
                break;
            default:
        }

        return url;
    };

    const listChanelItem = () => {
        return chanels.map((item, index) => {
            return (
                <Draggable key={index} draggableId={`${index}`} index={index}>
                    {(provided, snapshot) => (
                        <tr
                            className='ladiui table-vertical'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                                ...provided.draggableProps.style,
                                userSelect: 'none',
                                background: snapshot.isDragging ? '#e8f0fe' : 'none',
                            }}
                        >
                            <td width={50}>
                                <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg'} />
                            </td>
                            <td width={100}>
                                <SelectOption
                                    wrapperClassName='select-assignee w100 position-initial'
                                    innerClassName='ellipsis flex'
                                    customClassName={'position-initial'}
                                    currentValue={item.type}
                                    logoChanel={getUrlChanelByType(item.type)}
                                    _key='key'
                                    _value='name'
                                    items={appConfig.LIST_CHANELS}
                                    innerStyle={{
                                        overflow: 'hidden',
                                        width: 160,
                                        maxWidth: 160,
                                    }}
                                    idBlock='dropdown-active-chanel-store'
                                    onSelectItem={(data) => {
                                        onChangeChanelItem(data, index);
                                    }}
                                />
                            </td>
                            <td>
                                <Input
                                    value={item.url}
                                    placeholder={item.placeholder}
                                    onChange={(event) => onChangeUrlChanelitem(event, index)}
                                />
                            </td>
                            <td className='text-right'>
                                <a onClick={() => handleDeleteChanel(index)}>
                                    <i className='ladi-icon icon-bin'></i>
                                </a>
                            </td>
                        </tr>
                    )}
                </Draggable>
            );
        });
    };

    const handleAddChanel = () => {
        let _chanels = [...chanels];
        let defaultValue = {
            type: 'Zalo',
            url: '',
            placeholder: 'https://zalo.me/phone-number',
        };
        _chanels.push(defaultValue);
        setChanels(_chanels);
    };

    const fileChangedHandlerStoreBanner = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = BaseHelper.getFormDataUpload(files);
            if (form) {
                dispatch(fileActions.uploadThumbnail(form));
            }
        }
    };

    const handleDeleteBannerStore = () => {
        setDataStore({
            ...dataStore,
            banner: '',
        });
    };

    const fileChangedHandlerStoreLogo = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = BaseHelper.getFormDataUpload(files);
            if (form) {
                dispatch(fileActions.uploadLogo(form));
            }
        }
    };

    const handleDeleteLogoStore = () => {
        setDataStore({
            ...dataStore,
            logo: '',
        });
    };

    const handleSubmitData = () => {
        props.handleSubmitData();
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    const onDragEndSelectedChanels = (result) => {
        if (!result.destination || result.source.index == result.destination.index) {
            return;
        }
        const items = reorder(chanels, result.source.index, result.destination.index);
        setChanels(items);
    };

    return (
        <div className='panel-store-info' style={isShow ? { display: 'block' } : { display: 'none' }}>
            <div className='block-title-action'>
                <h3 className='title'>Thông tin cửa hàng</h3>
                <button className='ladiui btn btn-primary' onClick={() => handleSubmitData()}>
                    {
                        props.selectedPageCheckoutId ? "Lưu cửa hàng" : "Tạo cửa hàng"
                    }
                </button>
            </div>
            <div className='main-content'>
                <div className='left-content config-panel'>
                    <div className='panel'>
                        <div className='panel-line'>
                            <div className='main-description'>
                                Banner
                                <div className='sub-description'>
                                    Kích thước 450x150 (px)
                                    <br />
                                    (Định dạng .png, .jpg, .svg)
                                </div>
                            </div>
                            <div className='content'>
                                <div className='preview-store-banner'>
                                    <div id='store-banner'>
                                        <img
                                            className='store-banner'
                                            src={
                                                dataStore.banner
                                                    ? `${CDN}${dataStore.banner}`
                                                    : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'
                                            }
                                        />
                                        <div id='store-logo-overlay'>
                                            <div className='ladiui btn-group'>
                                                <div className='ladiui dropdown'>
                                                    <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                        <i className='ladiui icon icon-ldp-pen-edit'></i>
                                                    </button>
                                                    <ul
                                                        className='ladiui dropdown-menu custom-dropdown-menu'
                                                        style={{ minWidth: '100px', textAlign: 'left' }}
                                                    >
                                                        <li className='ladiui open-modal'>
                                                            <label htmlFor='upload-banner-store'>Thay ảnh</label>
                                                        </li>
                                                        <li
                                                            className='ladiui dropdown-item danger-text'
                                                            onClick={() => handleDeleteBannerStore()}
                                                        >
                                                            Xóa
                                                        </li>
                                                    </ul>
                                                </div>
                                                <input
                                                    id='upload-banner-store'
                                                    className='cursor-pointer'
                                                    type='file'
                                                    name='file'
                                                    accept='image/*'
                                                    multiple='multiple'
                                                    onChange={fileChangedHandlerStoreBanner}
                                                    onClick={(event) => {
                                                        event.target.value = null;
                                                    }}
                                                    style={{ display: 'none' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='panel'>
                        <div className='panel-line'>
                            <div className='main-description'>
                                Logo
                                <div className='sub-description'>
                                    Kích thước 198x198 (px)
                                    <br />
                                    (Định dạng .png, .jpg, .svg)
                                </div>
                            </div>
                            <div className='content'>
                                <div className='preview-store-logo'>
                                    <div id='store-logo'>
                                        <img
                                            className='store-logo'
                                            src={
                                                dataStore.logo
                                                    ? `${CDN}${dataStore.logo}`
                                                    : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'
                                            }
                                        />
                                        <div id='store-logo-overlay'>
                                            <div className='ladiui btn-group'>
                                                <div className='ladiui dropdown'>
                                                    <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                        <i className='ladiui icon icon-ldp-pen-edit'></i>
                                                    </button>
                                                    <ul
                                                        className='ladiui dropdown-menu custom-dropdown-menu'
                                                        style={{ minWidth: '100px', textAlign: 'left' }}
                                                    >
                                                        <li className='ladiui open-modal'>
                                                            <label htmlFor='upload-logo-store'>Thay ảnh</label>
                                                        </li>
                                                        <li
                                                            className='ladiui dropdown-item danger-text'
                                                            onClick={() => handleDeleteLogoStore()}
                                                        >
                                                            Xóa
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <input
                                                id='upload-logo-store'
                                                className='cursor-pointer'
                                                type='file'
                                                name='file'
                                                accept='image/*'
                                                multiple='multiple'
                                                onChange={fileChangedHandlerStoreLogo}
                                                onClick={(event) => {
                                                    event.target.value = null;
                                                }}
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='ladiui form-group'>
                        <label className='ladiui-label'>Tên cửa hàng</label>
                        <Input
                            placeholder={'Nhập tên cửa hàng'}
                            name='store_name'
                            value={dataStore.store_name}
                            className='input input-custom'
                            onChange={(event) => onChangeInput(event)}
                        />
                    </div>
                    <div className='ladiui form-group'>
                        <label className='ladiui-label'>Địa chỉ cửa hàng</label>
                        <Input
                            placeholder={'Nhập địa chỉ cửa hàng'}
                            name='store_address'
                            value={dataStore.store_address}
                            className='input input-custom'
                            onChange={(event) => onChangeInput(event)}
                        />
                    </div>
                    <div className='ladiui form-group'>
                        <label className='ladiui-label'>Số điện thoại cửa hàng</label>
                        <Input
                            placeholder={'Nhập số điện thoại cửa hàng'}
                            name='store_phone'
                            type='number'
                            value={dataStore.store_phone}
                            className='input input-custom'
                            onChange={(event) => onChangeInput(event)}
                        />
                    </div>
                    <div className='flex' style={{ gap: '24px' }}>
                        <div className='ladiui form-group mt-0'>
                            <label className='ladiui-label'>Đánh giá cửa hàng(Tối đa 5 điểm)</label>
                            <Input
                                placeholder={'Nhập đánh giá'}
                                name='review'
                                value={dataStore.review}
                                className='input input-custom'
                                onChange={(event) => onChangeInput(event)}
                            />
                        </div>
                        <div className='ladiui form-group mt-0'>
                            <label className='ladiui-label'>Sản phẩm đã bán</label>
                            <Input
                                placeholder={'Nhập số sản phẩm đã bán'}
                                name='product_sold'
                                value={dataStore.product_sold}
                                className='input input-custom'
                                onChange={(event) => onChangeInput(event)}
                            />
                        </div>
                    </div>
                    <div className='block-list-chanels'>
                        <label className='ladiui-label'>Kênh tương tác</label>
                        <span className='ladiui-sub-title' style={{ fontSize: '12px' }}>
                            Hiển thị khi khách hàng nhấn vào nút liên hệ
                        </span>

                        {chanels && chanels.length > 0 && (
                            <DragDropContext onDragEnd={onDragEndSelectedChanels}>
                                <Droppable droppableId='droppable'>
                                    {(provided, snapshot) => (
                                        <div
                                            className='list-chanels'
                                            ref={provided.innerRef}
                                            style={{
                                                background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                            }}
                                        >
                                            <table className='ladiui table text-left table-custom'>
                                                <thead>
                                                    <tr className='ladiui table-vertical header'>
                                                        <th className='text-left'></th>
                                                        <th className='text-left pl-0' style={{ paddingLeft: '0!important' }}>
                                                            Loại kênh
                                                        </th>
                                                        <th className='text-left'>Đường dẫn</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listChanelItem()}
                                                    {provided.placeholder}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}
                        {chanels && chanels.length < 7 && (
                            <div className='ladiui border-none btn-add-option mb-12 mt-24' onClick={() => handleAddChanel()}>
                                <img className='mr-8' src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg' />
                                <div className='btn-add-product'>Thêm kênh tương tác</div>
                            </div>
                        )}
                    </div>

                    <div className='flex mt-24'>
                        <div className='ladiui item-form switch'>
                            <label className='ladiui switch m-0'>
                                <input
                                    type='checkbox'
                                    onChange={(event) => {
                                        setDataStore({
                                            ...dataStore,
                                            status: !dataStore.status,
                                        });
                                    }}
                                    checked={dataStore.status}
                                />
                                <span className='ladiui slider round'></span>
                            </label>
                        </div>
                        <div className='ml-8'>
                            <span className='ladiui mb-0'>Hiển thị</span>
                        </div>
                    </div>
                    <div className='pt-8 mb-24'>
                        <span className='text-default'>
                            Cho phép hiển thị trang cửa hàng để khách vào mua hàng.
                        </span>
                    </div>
                </div>
                <div className='right-content'>
                    {
                        // urlPublished
                        // &&
                        <div className="ladiui iframe-checkout">
                            <iframe src={`${urlPublished}?isPreview=true`} width={"100%"} id="iframe-store-info" />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
});

PanelStoreInfo.propTypes = {};

export default PanelStoreInfo;
