import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../../components/Modal";

import baseHelper from "../../../../helpers/BaseHelper";
import { useTranslation } from "react-i18next";
import Input from "../../../../components/Input";
import appConfig from "../../../../config/app";
import { map, includes } from "lodash";

ModalStripe.propTypes = {
  onCancel: PropTypes.func,
  paymentGatewayConfigured: PropTypes.any,
};

export default function ModalStripe(props) {
  const {
    paymentGatewayConfigured,
    onCancel,
    upDateDataCofigPayment,
    connect,
    isSubmitLoading,
    onChangeStripeInput,
    stripeAccount,
    stripeRef,
    onchangeDataUpdateStripe,
    dataUpdateStripe,
    stripeMode,
    setPaypalModal,
    logo,
  } = props;

  const { t } = useTranslation();
  return (
    <Modal
      id="modal-stripe-pay"
      title={!paymentGatewayConfigured ? t("PAYMENTS.SET_UP_PAYMENT_METHOD") : t("PAYMENTS.DETAIL_PAYMENT_METHOD")}
      onCancel={onCancel}
      hasFooter={true}
      onOk={
        !paymentGatewayConfigured
          ? () => connect(appConfig.PAYMENT_PARTNERS.STRIPE.CODE)
          : () => upDateDataCofigPayment(appConfig.PAYMENT_PARTNERS.STRIPE.CODE, paymentGatewayConfigured)
      }
      visible={true}
      width={1000}
      logo={logo}
      bodyStyles={{ minHeight: "40vh" }}
    >
      <div className="content">
        {paymentGatewayConfigured ? (
          <React.Fragment>
            <div className="partner-account">
              <div className="ladiui form-group">
                <label className="ladiui-label">{t("PAYMENTS.LB_METHOD_NAME")}</label>

                <Input 
                 ref={(ref) => stripeRef.add(ref)}
                 value={dataUpdateStripe.method_name}
                 validationName={t('PAYMENTS.LB_METHOD_NAME')}
                 name='method_name'
                 onChange={(e) => onchangeDataUpdateStripe(e)}
                 validations={{ isRequired: true }}
                />
              </div>
              <div className="ladiui form-group">
                <label className="ladiui-label">{t("PAYMENTS.STRIPE.PUBLIC_KEY")}</label>
                <Input value={paymentGatewayConfigured.config.public_key} disabled={true} />
              </div>
              <div className="ladiui form-group">
                <label className="ladiui-label">{t("PAYMENTS.STRIPE.SECRET_KEY")}</label>
                <Input value={paymentGatewayConfigured.config.secret_key} disabled={true} />
              </div>
              <div id="data-tooltip-guide" className="ladiui form-group">
                <label className="ladiui-label">
                  <span className="tooltip-paymet-guide">{t("PAYMENTS.LB_PAYMENT_GUIDE")}</span>
                  <span className="tooltip-icon" data-tooltip={t("PAYMENTS.LB_TT_PAYMENT_GUIDE")} data-tooltip-position="right">
                    {" "}
                    <i className="ladi-icon icon-c-question" />
                  </span>
                </label>
                <textarea
                  name="payment_guide"
                  className="ladiui textarea"
                  defaultValue={paymentGatewayConfigured.config.payment_guide}
                  onChange={(e) => onchangeDataUpdateStripe(e)}
                />
              </div>
              {baseHelper.isDevelopEnv() && (
                <div className="ladiui form-group flex">
                  <input
                    type="checkbox"
                    className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                    checked={paymentGatewayConfigured.mode === appConfig.MODE.TEST}
                    disabled={true}
                  />
                  <label htmlFor="paypal_mode"> {t("MODE.TEST")}</label>
                </div>
              )}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>
              <div className="partner-account">
                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.LB_METHOD_NAME")}</label>
                  <Input
                    ref={(ref) => stripeRef.add(ref)}
                    value={stripeAccount.method_name}
                    validationName={t("PAYMENTS.LB_METHOD_NAME")}
                    name="method_name"
                    onChange={(event) => onChangeStripeInput(event)}
                    validations={{ isRequired: true }}
                  />
                </div>
                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.STRIPE.PUBLIC_KEY")}</label>
                  <Input
                    ref={(ref) => stripeRef.add(ref)}
                    value={stripeAccount.public_key}
                    validationName={t("PAYMENTS.STRIPE.CLIENT_SECRET")}
                    name="public_key"
                    onChange={(event) => onChangeStripeInput(event)}
                    validations={{ isRequired: true }}
                  />
                </div>
                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.STRIPE.SECRET_KEY")}</label>
                  <Input
                    ref={(ref) => stripeRef.add(ref)}
                    value={stripeAccount.secret_key}
                    validationName={t("PAYMENTS.STRIPE.CLIENT_ID")}
                    name="secret_key"
                    onChange={(event) => onChangeStripeInput(event)}
                    validations={{ isRequired: true }}
                  />
                </div>
                <div id="data-tooltip-guide" className="ladiui form-group">
                  <label className="ladiui-label">
                    <span className="tooltip-paymet-guide">{t("PAYMENTS.LB_PAYMENT_GUIDE")}</span>
                    <span className="tooltip-icon" data-tooltip={t("PAYMENTS.LB_TT_PAYMENT_GUIDE")} data-tooltip-position="right">
                      {" "}
                      <i className="ladi-icon icon-c-question" />
                    </span>
                  </label>
                  <textarea
                    name="payment_guide"
                    className="ladiui textarea"
                    value={stripeAccount.payment_guide}
                    onChange={(event) => onChangeStripeInput(event)}
                  />
                </div>
                {baseHelper.isDevelopEnv() && (
                  <div className="ladiui form-group flex">
                    <input
                      id="paypal_mode"
                      type="checkbox"
                      className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                      onChange={() => setPaypalModal(!stripeMode)}
                      checked={stripeMode == appConfig.MODE.TEST}
                    />
                    <label htmlFor="paypal_mode"> {t("MODE.TEST")}</label>
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </Modal>
  );
}
