/** @format */

import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { compact, map, includes, find, forEach, remove, isEmpty, cloneDeep } from "lodash";

import appConfig from "./../../config/app";

import * as formAccountTypes from "../../redux/futures/form_account/types";
import formAccountActions from "../../redux/futures/form_account/actions";

import * as formConfigTypes from "../../redux/futures/form_config/types";
import formConfigActions from "../../redux/futures/form_config/actions";

import ConfirmModal from "../../components/ConfirmModal";
import baseHelper from "../../helpers/BaseHelper";
import LoadingScene from "../../components/LoadingScene";
import LoadingTable from "../../components/LoadingTable";
import Pagination from "react-js-pagination";
import ModalFormAccount from "./components/ModalFormAccount";
import ModalFormAccountConfig from "./components/ModalFormAccountConfig";

import LadiPagination from "../../components/LadiPagination";

import Modal from "../../components/Modal";

import ModalConVerKit from "./components/ModalIntegrate/ModalConVerKit";
import ModalGoogleSheet from "./components/ModalIntegrate/ModalGoogleSheet";
import ModalGetResponse from "./components/ModalIntegrate/ModalGetResponse";
import ModalHubsPot from "./components/ModalIntegrate/ModalHubsPot";
import ModalActiveCampaign from "./components/ModalIntegrate/ModalActiveCampaign";
import ModalInfusion from "./components/ModalIntegrate/ModalInfusion";
import ModalMailChimp from "./components/ModalIntegrate/ModalMailChimp";
import ModalHaravan from "./components/ModalIntegrate/ModalHaravan";
import ModalShopify from "./components/ModalIntegrate/ModalShopify";
import ModalNhanh from "./components/ModalIntegrate/ModalNhanh";
import ModalWordPress from "./components/ModalIntegrate/ModalWordPress";
import ModalKiotViet from "./components/ModalIntegrate/ModalKiotViet";
import ModalSapo from "./components/ModalIntegrate/ModalSapo";

Integrate.propTypes = {};

export default function Integrate(props) {
  const location = useLocation();
  const { t } = useTranslation();
  const formAccountReducer = useSelector((state) => state.formAccount);
  const formConfigReducer = useSelector((state) => state.formConfigReducer);
  const dispatch = useDispatch();

  const [formAccounts, setFormAccounts] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [selectedLimit, setSelectedLimit] = useState(appConfig.PAGINATION.LIMIT_OPTIONS[0].value);
  const [activePage, setActivePage] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [mode, setMode] = useState(appConfig.FORM_MODE.CREATE);
  const [selectedFormAccount, setSelectedFormAccount] = useState({});
  const [accountConfig, setAccountConfig] = useState({});

  const [isShowFormAccount, setIsShowFormAccount] = useState(false);
  const [isShowModalInteGrate, setIsShowModalInteGrate] = useState(false);
  const [isShowModalFormAccountConfig, setIsShowModalFormAccountConfig] = useState(false);
  const [logoAccount, setLogoAccount] = useState(null);

  const [formType, setFormType] = useState("");

  let selectedID = [];
  let selectedIDs = [];
  //phuc update moi 17/06
  const inputRefs = new Set();
  const [textToggleInputType, setTextToggleInputType] = useState(false);
  //end

  useEffect(() => {
    window.LadiUI.init();
    window.LadiUI.customInit();
  });

  useEffect(() => {
    dispatch(formAccountActions.listFormAccount({ limit: selectedLimit }));
  }, []);

  //phuc update 1706

  useEffect(() => {
    window.callBackEventInfusionSoft = (event) => {
      dispatch(
        formAccountActions.oauthAccess({
          code: event.data.code,
          type: appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE,
        })
      );
    };

    window.callBackEventGoogleSheet = (event) => {
      dispatch(
        formAccountActions.oauth2GoogleSheets({
          code: event.data.code,
          id: props.currentFormAccount.id,
        })
      );
    };
  }, []);

  useEffect(() => {
    searchFormAccount();
  }, [formType]);

  useEffect(() => {
    // if (formAccountReducer.url) {
    //   formAccountReducer.url = formAccountReducer.url.replace("app.ladisales.com", "app.dev.ladisales.com");
    // }
    if (formAccountReducer.action === formAccountTypes.INTEGRATE_OAUTH_ACCESS) {
      if (formAccountReducer.status) {
        const data = {
          ...selectedFormAccount,
          type: selectedFormAccount.type,
          access_token: formAccountReducer.account.access_token,
          refresh_token: formAccountReducer.account.refresh_token,
        };

        props.onSubmit(data);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_OAUTH2_GET_LINK) {
      if (formAccountReducer.status) {
        window.LadiUI.popupWindow(formAccountReducer.url, "sharer", window, 550, 600);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_GOOGLE_SHEETS) {
      if (formAccountReducer.status) {
        window.LadiUI.popupWindow(formAccountReducer.url, "sharer", window, 550, 600);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_HARAVAN) {
      if (formAccountReducer.status) {
        window.LadiUI.popupWindow(formAccountReducer.url, "sharer", window, 550, 600);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_SAPO) {
      if (formAccountReducer.status) {
        window.LadiUI.popupWindow(formAccountReducer.url, "sharer", window, 550, 600);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_SHOPIFY) {
      if (formAccountReducer.status) {
        window.LadiUI.popupWindow(formAccountReducer.url, "sharer", window, 550, 600);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_NHANH) {
      if (formAccountReducer.status) {
        window.LadiUI.popupWindow(formAccountReducer.url, "sharer", window, 550, 600);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    }
  }, [formAccountReducer]);

  //end
  useEffect(() => {
    if (formAccountReducer.action === formAccountTypes.INTEGRATE_FORM_ACCOUNT_LIST) {
      if (formAccountReducer.status) {
        setFormAccounts(formAccountReducer.form_accounts);
        setTotalRecord(formAccountReducer.total_record);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_FORM_ACCOUNT_UPDATE) {
      if (formAccountReducer.status) {
        window.LadiUI.toastCustom("success", "", formAccountReducer.message);
        hideModalFormAccount();
        setActivePage(1);
        dispatch(
          formAccountActions.listFormAccount({
            limit: selectedLimit,
            page: 1,
            keyword: searchName,
          })
        );
        setFormType("");
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_FORM_ACCOUNT_DELETES) {
      if (formAccountReducer.status) {
        window.LadiUI.closeModal("confirm-form-account-delete");
        window.LadiUI.toastCustom("success", "", formAccountReducer.message);
        selectedIDs = [];
        dispatch(
          formAccountActions.listFormAccount({
            limit: selectedLimit,
            page: activePage,
            keyword: searchName,
          })
        );
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_FORM_ACCOUNT_SHOW) {
      if (formAccountReducer.status) {
        setSelectedFormAccount(formAccountReducer.form_account);
        setMode(appConfig.FORM_MODE.EDIT);
        // setIsShowFormAccount(true);
        setLogoAccount(getAccountTypeIcon(formAccountReducer.form_account.type));
        setIsShowModalInteGrate(true);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_OAUTH2_GOOGLE_SHEETS) {
      if (formAccountReducer.status) {
        setIsShowFormAccount(false);
        window.LadiUI.toastCustom("success", "", formAccountReducer.message);
        dispatch(
          formAccountActions.listFormAccount({
            limit: selectedLimit,
            page: activePage,
            keyword: searchName,
          })
        );
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_CREATE_HARAVAN) {
      if (formAccountReducer.status) {
        setIsShowFormAccount(false);
        window.LadiUI.toastCustom("success", "", formAccountReducer.message);
        dispatch(
          formAccountActions.listFormAccount({
            limit: selectedLimit,
            page: activePage,
            keyword: searchName,
          })
        );
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_CREATE_SAPO) {
      if (formAccountReducer.status) {
        setIsShowFormAccount(false);
        window.LadiUI.toastCustom("success", "", formAccountReducer.message);
        dispatch(
          formAccountActions.listFormAccount({
            limit: selectedLimit,
            page: activePage,
            keyword: searchName,
          })
        );
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_CREATE_NHANH) {
      if (formAccountReducer.status) {
        setIsShowFormAccount(false);
        window.LadiUI.toastCustom("success", "", formAccountReducer.message);
        dispatch(
          formAccountActions.listFormAccount({
            limit: selectedLimit,
            page: activePage,
            keyword: searchName,
          })
        );
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    }
  }, [formAccountReducer]);

  useEffect(() => {
    if (formConfigReducer.action === formConfigTypes.SHOW_ACCOUNT_CONFIG) {
      if (formConfigReducer.status) {
        setAccountConfig(formConfigReducer.account_config);
        setIsShowModalFormAccountConfig(true);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (
      formConfigReducer.action === formConfigTypes.ADD_ACCOUNT_CONFIG ||
      formConfigReducer.action === formConfigTypes.CANCEL_ACCOUNT_CONFIG
    ) {
      if (formConfigReducer.status) {
        window.LadiUI.closeModal("confirm-integrate-cancel");
        setIsShowModalFormAccountConfig(false);
        setFormType("");
        dispatch(
          formAccountActions.listFormAccount({
            limit: selectedLimit,
            page: activePage,
            keyword: searchName,
          })
        );
        window.LadiUI.toastCustom("success", "", formConfigReducer.message);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formConfigReducer.message, "OK");
      }
    }
  }, [formConfigReducer]);

  /******************************* Page and Limit ***************************/
  const onChangeLimit = (option) => {
    setSelectedLimit(option.value);
    setActivePage(1);
    const data = {
      limit: option.value,
      page: 1,
      paged: activePage,
      keyword: searchName,
      type: formType,
    };
    dispatch(formAccountActions.listFormAccount(data));
  };

  const onPageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const data = {
      limit: selectedLimit,
      page: pageNumber,
      paged: activePage,
      keyword: searchName,
    };
    dispatch(formAccountActions.listFormAccount(data));
  };

  /*******************************SEARCH***************************/
  const onChangeNameInput = (event) => {
    setSearchName(event.target.value);
  };

  /**
   * Tim kiem theo name
   */
  const onKeyDownInput = (event) => {
    if (event.key === "Enter") {
      searchFormAccount();
    }
  };

  const searchFormAccount = () => {
    setActivePage(1);
    const data = {
      limit: selectedLimit,
      page: 1,
      keyword: searchName,
      type: formType,
    };
    dispatch(formAccountActions.listFormAccount(data));
  };

  /*******************************CHECK AND ACTIONS***************************/
  const checkAllItem = (event) => {
    const { checked } = event.target;
    selectedIDs = [];

    if (checked) {
      selectedIDs = map(formAccounts, (item) => item.id);
      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = true;
      });
    } else {
      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = false;
      });
    }
  };

  const checkItem = (event, accountID) => {
    const { checked } = event.target;

    if (checked) {
      if (!includes(selectedIDs, accountID)) {
        selectedIDs.push(accountID);
      }

      if (selectedIDs.length == formAccounts.length) {
        document.getElementById("checkAllItem").checked = true;
      }
    } else {
      document.getElementById("checkAllItem").checked = false;
      remove(selectedIDs, (selectedAccountID) => selectedAccountID == accountID);
    }
  };

  const openOptionDeletesModal = () => {
    if (selectedIDs.length <= 0) {
      window.LadiUI.toastCustom("danger", "", t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
      return;
    }

    window.LadiUI.showModal("confirm-form-account-deletes");
  };

  const openModalFormAccount = () => {
    setMode(appConfig.FORM_MODE.CREATE);
    setSelectedFormAccount({});
    setIsShowFormAccount(true);
  };

  const hideModalFormAccount = () => {
    setIsShowFormAccount(false);
    setIsShowModalInteGrate(false);
  };

  const getAccountTypeName = (type) => {
    return appConfig.FORM_TYPE[type] ? appConfig.FORM_TYPE[type].NAME : "";
  };

  const openOptionDeleteModal = (id) => {
    selectedID = id;
    window.LadiUI.showModal("confirm-form-account-delete");
  };

  const openEditModal = (formAccount) => {
    dispatch(formAccountActions.show(formAccount.id));
  };

  const getAccountTypeIcon = (type) => {
    return baseHelper.getAccountTypeIcon(type);
  };

  const openFormAccountConfigModal = (formAccount) => {
    dispatch(
      formConfigActions.showAccountConfig({
        form_account_id: formAccount.id,
        type: formAccount.type,
      })
    );
  };

  const addAccountConfig = (accountConfig) => {
    dispatch(formConfigActions.addAccountConfig(accountConfig));
  };

  const openIntegrateCancelModal = (id) => {
    selectedID = id;
    window.LadiUI.showModal("confirm-integrate-cancel");
  };

  const getFormNameByType = (value) => {
    const matchedFormType = find(appConfig.FORM_TYPE, (item) => item.CODE == value);
    if (matchedFormType) {
      return matchedFormType.NAME;
    }
    return "Tất cả";
  };

  //phuc update moi ngay 17/06

  const onChangeInput = (event) => {
    let accountData = cloneDeep(selectedFormAccount);
    accountData = {
      ...accountData,
      [event.target.name]: event.target.value,
    };
    setSelectedFormAccount(accountData);
  };

  const getLinkApiUrl = () => {
    if (selectedFormAccount.type == appConfig.FORM_TYPE.HARAVAN.CODE) {
      dispatch(formAccountActions.haravan());
      window.callBackEventHaravan = (event) => {
        dispatch(
          formAccountActions.createHaravan({
            ...selectedFormAccount,
            token: event.data.code,
            shop_id: event.data.shop,
            type: "HARAVAN",
          })
        );
      };
    }
    if (selectedFormAccount.type == appConfig.FORM_TYPE.SAPO.CODE) {
      dispatch(
        formAccountActions.sapo({
          store_url: selectedFormAccount.api_url,
        })
      );
      window.callBackEventSapo = (event) => {
        dispatch(
          formAccountActions.createSapo({
            ...selectedFormAccount,
            store_url: event.data.store,
            code: event.data.code,
          })
        );
      };
    }
    if (selectedFormAccount.type == appConfig.FORM_TYPE.SHOPIFY.CODE) {
      dispatch(
        formAccountActions.shopify({
          store_url: selectedFormAccount.api_url,
        })
      );
      window.callBackEventShopify = (event) => {
        dispatch(
          formAccountActions.createShopify({
            ...selectedFormAccount,
            store_url: event.data.store,
            code: event.data.code,
          })
        );
      };
    }

    if (selectedFormAccount.type == appConfig.FORM_TYPE.NHANH.CODE) {
      dispatch(formAccountActions.nhanh());
      window.callBackEventNhanh = (event) => {
        dispatch(
          formAccountActions.createNhanh({
            ...selectedFormAccount,
            code: event.data.code,
          })
        );
      };
    }
  };

  const submit = () => {
    let errors = [];

    if (inputRefs) {
      const inputsRef = compact(Array.from(inputRefs));
      map(inputsRef, (ref) => {
        if (ref.validate) {
          errors = errors.concat(ref.validate());
        }
      });

      errors = compact(errors);

      if (errors.length > 0) {
        const errorMessage = errors.join("<br/>");
        window.LadiUI.toastCustom("danger", "", errorMessage);
        return;
      }
    }

    const data = {
      ...selectedFormAccount,
      type: selectedFormAccount.type,
    };

    if (selectedFormAccount.type == appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE) {
      dispatch(formAccountActions.oauth2GetLink(data.type));
    } else if (selectedFormAccount.type == appConfig.FORM_TYPE.GOOGLE_SHEET.CODE) {
      dispatch(formAccountActions.googleSheets());
    } else {
      dispatch(formAccountActions.update(data));
    }
  };

  const renderForm = () => {
    let formContent = <></>;
    switch (selectedFormAccount.type) {
      case appConfig.FORM_TYPE.GOOGLE_SHEET.CODE:
        formContent = <ModalGoogleSheet mode={mode} formAccount={selectedFormAccount} />;
        break;
      case appConfig.FORM_TYPE.CONVERTKIT.CODE:
        formContent = (
          <ModalConVerKit
            inputRefs={inputRefs}
            formAccount={selectedFormAccount}
            onChangeInput={onChangeInput}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
          />
        );
        break;
      case appConfig.FORM_TYPE.GET_RESPONSE.CODE:
        formContent = (
          <ModalGetResponse
            inputRefs={inputRefs}
            formAccount={selectedFormAccount}
            onChangeInput={onChangeInput}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
          />
        );
        break;
      case appConfig.FORM_TYPE.HUBSPOT.CODE:
        formContent = (
          <ModalHubsPot
            inputRefs={inputRefs}
            formAccount={selectedFormAccount}
            onChangeInput={onChangeInput}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
          />
        );
        break;
      case appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.CODE:
        formContent = (
          <ModalActiveCampaign
            inputRefs={inputRefs}
            formAccount={selectedFormAccount}
            onChangeInput={onChangeInput}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
          />
        );
        break;
      case appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE:
        formContent = <ModalInfusion inputRefs={inputRefs} formAccount={selectedFormAccount} onChangeInput={onChangeInput} />;
        break;
      case appConfig.FORM_TYPE.MAIL_CHIMP.CODE:
        formContent = (
          <ModalMailChimp
            inputRefs={inputRefs}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
            formAccount={selectedFormAccount}
            onChangeInput={onChangeInput}
          />
        );
        break;
      case appConfig.FORM_TYPE.HARAVAN.CODE:
        formContent = (
          <ModalHaravan
            inputRefs={inputRefs}
            formAccount={selectedFormAccount}
            onChangeInput={onChangeInput}
            getLinkApiUrl={getLinkApiUrl}
          />
        );
        break;
      case appConfig.FORM_TYPE.SAPO.CODE:
        formContent = (
          <ModalSapo inputRefs={inputRefs} getLinkApiUrl={getLinkApiUrl} formAccount={selectedFormAccount} onChangeInput={onChangeInput} />
        );
        break;
      case appConfig.FORM_TYPE.SHOPIFY.CODE:
        formContent = (
          <ModalShopify
            inputRefs={inputRefs}
            formAccount={selectedFormAccount}
            onChangeInput={onChangeInput}
            getLinkApiUrl={getLinkApiUrl}
          />
        );
        break;
      case appConfig.FORM_TYPE.WORDPRESS.CODE:
        formContent = (
          <ModalWordPress
            inputRefs={inputRefs}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
            formAccount={selectedFormAccount}
            onChangeInput={onChangeInput}
          />
        );
        break;
      case appConfig.FORM_TYPE.KIOTVIET.CODE:
        formContent = (
          <ModalKiotViet
            inputRefs={inputRefs}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
            formAccount={selectedFormAccount}
            onChangeInput={onChangeInput}
          />
        );
        break;
      case appConfig.FORM_TYPE.NHANH.CODE:
        formContent = (
          <ModalNhanh inputRefs={inputRefs} formAccount={selectedFormAccount} onChangeInput={onChangeInput} getLinkApiUrl={getLinkApiUrl} />
        );
        break;
      default:
    }
    return formContent;
  };

  const getUrlHelpIntegrate = (type) => {
    let url = "";
    switch (type) {
      case appConfig.FORM_TYPE.GOOGLE_SHEET.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-google-sheet";
        break;
      case appConfig.FORM_TYPE.CONVERTKIT.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-convertkit";
        break;
      case appConfig.FORM_TYPE.GET_RESPONSE.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-getresponse";
        break;
      case appConfig.FORM_TYPE.HUBSPOT.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-hubspot";
        break;
      case appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-activecampaign";
        break;
      case appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-infusion-soft";
        break;
      case appConfig.FORM_TYPE.MAIL_CHIMP.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-mailchimp";
        break;
      case appConfig.FORM_TYPE.HARAVAN.CODE:
        url =
          "https://help.ladipage.vn/dong-bo-don-hang-sang-cac-nen-tang-website-thuong-mai-dien-tu-va-phan-mem-quan-ly-ban-hang/dong-bo-don-hang-tu-landing-page-sang-haravan";
        break;
      case appConfig.FORM_TYPE.SAPO.CODE:
        url =
          "https://help.ladipage.vn/dong-bo-don-hang-sang-cac-nen-tang-website-thuong-mai-dien-tu-va-phan-mem-quan-ly-ban-hang/dong-bo-don-hang-tu-landing-page-sang-sapo";
        break;
      case appConfig.FORM_TYPE.SHOPIFY.CODE:
        url =
          "https://help.ladipage.vn/dong-bo-don-hang-sang-cac-nen-tang-website-thuong-mai-dien-tu-va-phan-mem-quan-ly-ban-hang/dong-bo-don-hang-tu-landing-page-sang-shopify";
        break;
      case appConfig.FORM_TYPE.WORDPRESS.CODE:
        url =
          "https://help.ladipage.vn/dong-bo-don-hang-sang-cac-nen-tang-website-thuong-mai-dien-tu-va-phan-mem-quan-ly-ban-hang/dong-bo-don-hang-tu-landing-page-sang-wordpress";
        break;
      case appConfig.FORM_TYPE.KIOTVIET.CODE:
        url =
          "https://help.ladipage.vn/dong-bo-don-hang-sang-cac-nen-tang-website-thuong-mai-dien-tu-va-phan-mem-quan-ly-ban-hang/dong-bo-don-hang-tu-landing-page-sang-kiotviet";
        break;
      case appConfig.FORM_TYPE.NHANH.CODE:
        url =
          "https://help.ladipage.vn/dong-bo-don-hang-sang-cac-nen-tang-website-thuong-mai-dien-tu-va-phan-mem-quan-ly-ban-hang/dong-bo-don-hang-tu-landingpage-sang-nhanh.vn";
        break;
      default:
    }
    return url;
  };

  //end

  const isLoadingData = formAccountReducer.loading && formAccountReducer.waiting === formAccountTypes.INTEGRATE_FORM_ACCOUNT_LIST;
  const isLoadingInfo = formAccountReducer.loading && includes([formAccountTypes.INTEGRATE_FORM_ACCOUNT_LIST], formAccountReducer.waiting);
  const isLoadingDelete =
    formAccountReducer.loading && includes([formAccountTypes.INTEGRATE_FORM_ACCOUNT_DELETES], formAccountReducer.waiting);
  const isLoadingScene =
    formAccountReducer.loading &&
    includes(
      [
        formAccountTypes.INTEGRATE_OAUTH_ACCESS,
        formAccountTypes.INTEGRATE_FORM_ACCOUNT_UPDATE,
        formAccountTypes.INTEGRATE_FORM_ACCOUNT_SHOW,
        formConfigTypes.SHOW_ACCOUNT_CONFIG,
        formConfigTypes.ADD_ACCOUNT_CONFIG,
      ],
      formAccountReducer.waiting
    );

  let fromItem = 0;
  let toItem = 0;
  if ((formAccounts || []).length > 0) {
    fromItem = (activePage - 1) * selectedLimit + 1;
    toItem = fromItem + formAccounts.length - 1;
  }

  const msgShowItem = `${t("PAGINATION.SHOW")} ${fromItem} ${t("PAGINATION.TO")} ${toItem} ${t("PAGINATION.OF")} ${baseHelper.formatNumber(
    totalRecord
  )} ${t("PAGINATION.ITEMS")}`;

  return (
    <div className="page-content page-integrate">
      <div className="ladipage-box-content">
        <div className="ladiui-tab-content">
          <div className="ladiui-tab-item active" id="tab-form-account">
            {/* <PanelFormAccount /> */}
            <div>
              {isLoadingScene && <LoadingScene blur={true} />}
              <div className="ladiui-table-actions">
                <div className="action-item" style={{ display: "flex" }}>
                  <div className="ladiui search-keyword">
                    <input
                      type="text"
                      id="search"
                      placeholder={t("INTEGRATE.SEARCH_AFFILIATE_ACCOUNT_EVENT")}
                      name="search_name"
                      value={searchName}
                      onChange={onChangeNameInput}
                      onKeyDown={onKeyDownInput}
                      className="ladiui form-control"
                    />
                    <i className="ladi-icon icon-zoom" onClick={searchFormAccount} />
                  </div>
                  <div className="ladiui dropdown" style={{ marginLeft: "24px" }}>
                    <button
                      className="ladiui btn btn-outline-light dropdown-toggle"
                      data-toggle="dropdown"
                      style={{ width: "100%", paddingRight: "30px" }}
                    >
                      {getFormNameByType(formType)}
                    </button>
                    <ul className="ladiui dropdown-menu custom-form-dynamic" style={{ width: "100%" }}>
                      <li onClick={() => setFormType("")}>
                        <a className="ladiui dropdown-item" href="#">
                          Tất cả
                        </a>
                      </li>
                      {map(appConfig.FORM_TYPE, (item, index) => (
                        <li key={index} onClick={() => setFormType(item.CODE)}>
                          <a className="ladiui dropdown-item" href="#">
                            {item.NAME}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="action-btn">
                  <button type="button" className="ladiui btn btn-primary btn-sm" onClick={openModalFormAccount}>
                    <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />

                    {t("INTEGRATE.ADD_NEW_AFFILIATE_ACCOUNT")}
                  </button>
                </div>
              </div>

              {isLoadingData ? (
                <LoadingTable />
              ) : (
                <div>
                  <div className="ladi-card ladiui-table-responsive">
                    <table className={`ladiui table text-left ${isLoadingInfo ? "loader" : ""}`}>
                      <thead>
                        <tr className="ladiui table-vertical header">
                          {/* <th className="ladiui checkall text-center">
                                        <input id="checkAllItem" onClick={checkAllItem} type="checkbox" className="ladiui checkbox ladiui-checkall"/>
                                    </th> */}
                          <th scope="col" className="text-left">
                            {t("INTEGRATE.AFFILIATE_ACCOUNT_NAME")}
                          </th>
                          <th scope="col" className="text-left">
                            {t("INTEGRATE.AFFILIATE_ACCOUNT_TYPE")}
                          </th>
                          {/* <th className="text-left">{t('INTEGRATE.AFFILIATE_ACCOUNT_GROUP')}</th> */}
                          <th scope="col" className="text-left">
                            {t("INTEGRATE.SOURCE")}
                          </th>
                          <th scope="col" className="text-left">
                            {t("INTEGRATE.AFFILIATE_ACCOUNT_STATUS")}
                          </th>
                          <th scope="col" className="text-left">
                            {t("INTEGRATE.AFFILIATE_ACCOUNT_UPDATED_AT")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(formAccounts, (item) => {
                          return (
                            <tr key={item.id} className="ladiui table-vertical main">
                              {/* <td className="text-center">
                                                    <input type="checkbox" onClick={(event) => checkItem(event, item.id)} className="ladiui checkbox size-checkbox form-check-input-checkbox"/>
                                                </td> */}
                              <td scope="row">{item.name}</td>
                              <td>
                                <img src={getAccountTypeIcon(item.type)} className="icon-form-account" />
                                {getAccountTypeName(item.type)}
                              </td>
                              {/* <td>{t(`GROUP_TYPE.${item.group}`)}</td> */}
                              <td>{item.app_code}</td>
                              <td>{item.is_configed ? t("INTEGRATE.STATUS.YES") : t("INTEGRATE.STATUS.NO")}</td>
                              <td>{baseHelper.formatDateToStr(item.updated_at)}</td>
                              <td className="text-right pd-0">
                                <div className="ladiui btn-group">
                                  <div className="ladiui dropdown hide-mt ba-c">
                                    <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                                      <i className="ladiui icon icon-ldp-dot"></i>
                                    </button>
                                    <ul className="ladiui dropdown-menu r-0">
                                      <li>
                                        <a className="ladiui dropdown-item" onClick={() => openEditModal(item)}>
                                          {t("ACTIONS.SHOW")}
                                        </a>
                                      </li>
                                      {![
                                        appConfig.FORM_TYPE.HARAVAN.CODE,
                                        appConfig.FORM_TYPE.NHANH.CODE,
                                        appConfig.FORM_TYPE.SAPO.CODE,
                                        appConfig.FORM_TYPE.WORDPRESS.CODE,
                                      ].includes(item.type) ? (
                                        <li>
                                          <a className="ladiui dropdown-item" onClick={() => openFormAccountConfigModal(item)}>
                                            {item.is_configed ? t("ACTIONS.EDIT_CONFIG") : t("ACTIONS.CONFIG")}
                                          </a>
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {item.is_configed && (
                                        <li>
                                          <a className="ladiui dropdown-item" onClick={() => openIntegrateCancelModal(item.id)}>
                                            {t("INTEGRATE.MSG_INTEGRATE_CANCEL_TITLE")}
                                          </a>
                                        </li>
                                      )}
                                      {item.app_code == appConfig.SOURCE.LS && (
                                        <li>
                                          <a className="ladiui dropdown-item" onClick={() => openOptionDeleteModal(item.id)}>
                                            {t("ACTIONS.DELETE")}
                                          </a>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                        {totalRecord <= 0 && (
                          <tr className="text-center">
                            <td colSpan="100%">{t("NO_DATA")}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {totalRecord > 0 && (
                    <LadiPagination
                      conditionsProp={{
                        page: activePage,
                        limit: selectedLimit,
                      }}
                      listName={t("PAGINATION.ITEMS")}
                      message={""}
                      items={formAccounts}
                      total={totalRecord}
                      limitAction={onChangeLimit}
                      pageAction={onPageChange}
                    />
                  )}
                </div>
              )}

              {isShowFormAccount && (
                <ModalFormAccount
                  mode={mode}
                  visible={isShowFormAccount}
                  onCancel={hideModalFormAccount}
                  onSubmit={(data) => dispatch(formAccountActions.update(data))}
                  isLoading={isLoadingData}
                  currentFormAccount={selectedFormAccount}
                />
              )}

              {
                // selectedFormAccount.type == appConfig.FORM_TYPE.GOOGLE_SHEET.CODE &&
                isShowModalInteGrate && (
                  <Modal
                    id="modal-edit-integrate"
                    title={t("FORM_CONFIG.EDIT_FORM_ACCOUNT")}
                    // onOk={submit}
                    visible={isShowModalInteGrate}
                    okText={t("ACTIONS.CONNECT")}
                    width={600}
                    onCancel={hideModalFormAccount}
                    logoAccount={logoAccount}
                    accountName={getAccountTypeName(selectedFormAccount.type)}
                    isShowAccountType={true}
                    isCustomFooter={true}
                    footer={
                      <div className="ladiui-modal-footer">
                        <div id="data-tooltip-guide" className="ladiui help-integrate">
                          <span className="tooltip-icon flex mr-10" data-tooltip-position="right">
                            {" "}
                            <i className="ladi-icon icon-c-question" />
                          </span>
                          <span>
                            {t("INTEGRATE.HELP_INTEGRATE")} &nbsp;
                            <a href={getUrlHelpIntegrate(selectedFormAccount.type)} target="_blank">
                              {getAccountTypeName(selectedFormAccount.type)}
                            </a>
                          </span>
                        </div>
                        <div className="flex">
                          <button onClick={hideModalFormAccount} className="ladiui btn btn-secondary mr-12">
                            {/* <i className="ladi-icon icon-e-remove mr-4" /> */}
                            {t("ACTIONS.REMOVE")}
                          </button>
                          <button onClick={submit} className={`ladiui btn btn-primary ${props.isLoading ? "loader" : ""}`}>
                            {selectedFormAccount.type == appConfig.FORM_TYPE.GOOGLE_SHEET.CODE ||
                            selectedFormAccount.type == appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE
                              ? t("ACTIONS.CONTINUE")
                              : t("ACTIONS.ADD_LINK")}
                          </button>
                        </div>
                      </div>
                    }
                  >
                    <div className="content-box-integrate">{renderForm()}</div>
                  </Modal>
                )
              }

              {isShowModalFormAccountConfig && (
                <ModalFormAccountConfig
                  visible={isShowModalFormAccountConfig}
                  onCancel={() => setIsShowModalFormAccountConfig(false)}
                  onSubmit={addAccountConfig}
                  accountConfig={accountConfig}
                />
              )}

              <ConfirmModal
                id="confirm-form-account-delete"
                title={t("INTEGRATE.MSG_DELETE_FORM_ACCOUNT_TITLE")}
                content={t("INTEGRATE.MSG_DELETE_FORM_ACCOUNT_CONTENT")}
                cancelText={t("ACTIONS.CANCEL")}
                okText={t("ACTIONS.DELETE")}
                onOk={() => dispatch(formAccountActions.deleteFormAccount(selectedID))}
                isLoading={isLoadingDelete}
              />

              <ConfirmModal
                id="confirm-integrate-cancel"
                title={t("INTEGRATE.MSG_INTEGRATE_CANCEL_TITLE")}
                content={t("INTEGRATE.MSG_INTEGRATE_CANCEL_CONTENT")}
                cancelText={t("ACTIONS.CANCEL")}
                okText={t("ACTIONS.OK")}
                onOk={() => dispatch(formConfigActions.cancelAccountConfig(selectedID))}
                isLoading={isLoadingDelete}
              />
            </div>
          </div>

          {/* <div className="ladiui-tab-item" id="tab-form-config">
                        <PanelFormConfig />
                    </div> */}
        </div>
      </div>
    </div>
  );
}
