import React from "react";

import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { includes, map, find, remove, cloneDeep, compact } from "lodash";
import appConfig from "../../config/app";
import Pagination from "react-js-pagination";
import moment from "moment";

import baseHelper from "../../helpers/BaseHelper";

import * as types from "../../redux/futures/order/types";
import orderActions from "../../redux/futures/order/actions";

import * as productType from "../../redux/futures/product/types";
import productActions from "../../redux/futures/product/actions";

import * as orderTagTypes from "../../redux/futures/order_tag/types";
import orderTagActions from "../../redux/futures/order_tag/actions";

import LoadingScene from "../../components/LoadingScene";

// import FormEdit from './components/FormEdit/FormEdit';
import LoadingTable from "../../components/LoadingTable";
import { withTranslation } from "react-i18next";
import ConfirmModal from "../../components/ConfirmModal";

import { appLocalStorage } from "../../localforage";

import OrderDateRangePicker from "../../components/OrderDateRangePicker";

import socket from "../../socket";
import ItemFilter from "./components/ItemFilter";
import SelectOption from "../../components/SelectOption";
import LadiDropdownMenu from "../../components/LadiDropdownMenu";
import LadiPagination from "../../components/LadiPagination";
import FilterConditionOrder from "./components/FilterConditionOrder";
import ModalExportOrder from "./components/ModalExportOrder";
import ListTags from "../../components/ListTags";

class MyOrder extends React.Component {
  constructor(props) {
    super(props);

    this.moment = baseHelper.getMoment();
    const defaultConditions = {
      page: 1,
      limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
    };

    this.state = {
      activePage: 1,
      searchName: "",
      conditionProp: {
        status: {
          value: appConfig.ORDER_STATUS.OPEN.CODE,
          name: appConfig.ORDER_STATUS.OPEN.NAME,
          condition: "=",
        },
      },
      searchStatus: appConfig.ORDER_STATUS.OPEN.CODE,
      searchSpam: appConfig.ORDER_SPAM.NOT_SPAM.CODE,
      searchPaymentStatus: [],
      searchShippingStatus: [],
      searchProductTypes: [],
      product_ids: [],
      searchUtm: {
        urlPage: "",
        source: "",
        medium: "",
        campaign: "",
        term: "",
        content: "",
      },
      searchTag: {},
      searchIP: "",
      fromDate: null,
      toDate: null,
      selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
      currentOrderInfo: {},
      mode: "",
      userInfo: {},

      tabSelected: appConfig.ORDER_FILTER_TYPE.ALL_NOT_SPAM,
      focusedInput: null,
      duplicationID: "",
      duplicationNo: "",
      selectedTags: [],
      selectedIDs: [],
      conditions: defaultConditions,
      isShowModalExportOrder: false,
      totalAmount: 0,
    };

    this.sortBy = {};
    this.selectedID = "";
    // this.selectedIDs = [];
    this.formEditRef = React.createRef();
    this.searchTagRef = React.createRef();
    this.room = "";
  }

  componentWillMount() {
    appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
      const { ladi_uid: ladiUID } = result;
      const storeID = result.currentStore.store_id;
      this.room = `${storeID}_${ladiUID}_${appConfig.SOCKET.ROOMS.MY_ORDER}`;

      this.setState(
        {
          userInfo: result,
        },
        () => {
          this.subscribe();
        }
      );
    });
  }

  componentDidMount() {
    this.list();
    this.props.searchProduct();
    this.props.listAllTags();
  }

  componentDidUpdate() {
    window.LadiUI.init();
    setTimeout(() => {
      window.LadiUI.customInit();
    }, 0);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props === nextProps) {
      return;
    }

    if (this.props.order.action != nextProps.order.action) {
      if (nextProps.order.action === types.SHOW_ORDER) {
        if (nextProps.order.status) {
          this.setState({
            mode: appConfig.FORM_MODE.EDIT,
            currentOrderInfo: nextProps.order.order,
          });
        }
      }

      if (nextProps.order.action === types.UPDATE_ORDER) {
        if (nextProps.order.status) {
          this.props.show(this.state.currentOrderInfo.order_id);

          this.list();

          window.LadiUI.toastCustom("success", "", nextProps.order.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.order.message, "OK");
        }
      }

      if (nextProps.order.action == types.EXPORT_ORDER) {
        if (nextProps.order.status) {
          window.LadiUI.toastCustom("success", "", nextProps.order.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.order.message, "OK");
        }
      }

      if (includes([types.CANCEL_ORDER], nextProps.order.action)) {
        if (nextProps.order.status) {
          window.LadiUI.toastCustom("success", "", nextProps.order.message);

          this.list();

          window.LadiUI.closeModal("confirm-cancel-order");
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.order.message, "OK");
        }
      }

      if (nextProps.order.action == types.LIST_MY_ORDER) {
        if (nextProps.order.status) {
          this.setState({
            totalAmount: nextProps.order.total,
          });
          window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui table .sorting"), (item) => {
            item.classList.remove("up");

            if (this.sortBy[item.getAttribute("name")] == "DESC") {
              item.classList.add("up");
            }
          });
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.order.message, "OK");
        }
      }

      if (nextProps.order.action === types.ASSIGN_ORDER) {
        if (nextProps.order.status) {
          this.list();
          window.LadiUI.toastCustom("success", "", nextProps.order.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.order.message, "OK");
        }
      }

      if (nextProps.order.action === types.UPDATE_SPAMS) {
        if (nextProps.order.status) {
          this.list();

          window.LadiUI.closeModal("confirm-mark-spam");
          window.LadiUI.closeModal("confirm-remove-spam");

          window.LadiUI.toastCustom("success", "", nextProps.order.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.order.message, "OK");
        }
      }

      if (nextProps.order.action === types.REMOVE_DUPLICATION_ORDER) {
        if (nextProps.order.status) {
          this.list();

          window.LadiUI.toastCustom("success", "", nextProps.order.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.order.message, "OK");
        }
      }
    }
  }

  componentWillUnmount() {
    socket.getInstance().removeListener(appConfig.SOCKET.EVENTS.NEW_MY_ORDER);
  }

  subscribe() {
    const { tabSelected } = this.state;

    switch (tabSelected) {
      case appConfig.ORDER_FILTER_TYPE.DRAFT:
      case appConfig.ORDER_FILTER_TYPE.DUPLICATION:
      case appConfig.ORDER_FILTER_TYPE.SEARCH_ALL:
      case appConfig.ORDER_FILTER_TYPE.ALL:
        socket.getInstance().on(appConfig.SOCKET.EVENTS.NEW_MY_ORDER, (data) => {
          this.wsAppendNewOrder(data);
        });
        break;
      case appConfig.ORDER_FILTER_TYPE.SPAM:
      case appConfig.ORDER_FILTER_TYPE.PAYMENT_PENDING:
      case appConfig.ORDER_FILTER_TYPE.SHIPPING_PENDING:
        socket.getInstance().removeListener(appConfig.SOCKET.EVENTS.NEW_MY_ORDER);
        break;
    }
  }

  wsAppendNewOrder = (newOrder) => {
    const { selectedLimit } = this.state;

    // Kiểm tra xem order đã tồn tại trong danh sách hiện tại hay chưa
    let orders = this.props.order.orders || [];
    let matched = find(orders, (item) => item.order_id == newOrder.order_id);

    if (matched) {
      return false;
    }

    // Kiểm tra xem order có bị trùng không?
    // Nếu không tìm thấy thì bye bye
    if (newOrder.parent_id) {
      matched = find(orders, (item) => item.order_id == newOrder.parent_id);
      if (!matched) {
        return;
      }
    }
    let totalRecord = cloneDeep(this.props.order.totalRecord);
    if (matched) {
      matched.total_child = baseHelper.parseInt(matched.total_child) + 1;
    } else {
      totalRecord++;
      orders.unshift(newOrder);
      orders = orders.slice(0, selectedLimit);

    }

    this.props.wsAppendNewOrder(orders, totalRecord);
  };

  /**
   * list order
   */
  list = (resetActivePageFlag = true) => {
    if (document.getElementById("checkAllItem")) {
      document.getElementById("checkAllItem").checked = false;
      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = false;
      });
    }

    const {
      searchName,
      selectedLimit,
      fromDate,
      toDate,
      searchSpam,
      conditions,
      searchStatus,
      searchPaymentStatus,
      searchShippingStatus,
      product_ids,
      searchProductTypes,
      tabSelected,
      searchUtm,
      searchIP,
      duplicationID,
    } = this.state;
    let activePage = conditions.page;

    if (resetActivePageFlag) {
      activePage = 1;
      conditions.page = 1;
      this.setState({
        conditions,
      });
    }

    // Reset selectedIDs
    // this.selectedIDs = [];
    this.setState({
      selectedIDs: [],
    });
    // let tagIDs = this.searchTagRef.current.getIDs();
    let tagIDs = this.state.searchTag;
    // tagIDs = compact(tagIDs);

    const data = {
      search: {
        status: searchStatus,
        mark_spam: searchSpam,
        payment_status: searchPaymentStatus,
        shipping_status: searchShippingStatus,
        product_types: searchProductTypes,
        name: searchName,
        from_date: fromDate ? moment(new Date(fromDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
        to_date: toDate ? moment(new Date(toDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
        tag_ids: tagIDs,
        ip: searchIP,
        url_page: searchUtm.urlPage,
        utm_source: searchUtm.source,
        utm_medium: searchUtm.medium,
        utm_campaign: searchUtm.campaign,
        utm_term: searchUtm.term,
        utm_content: searchUtm.content,
        product_ids: product_ids
      },
      paged: activePage,
      limit: conditions.limit,
      sort: this.sortBy,
    };

    data.type = tabSelected;
    if (tabSelected == appConfig.ORDER_FILTER_TYPE.DUPLICATION) {
      data.parent_id = duplicationID;
    }

    this.props.listMyOrders(data);
  };

  /*******************************SEARCH***************************/
  onChangeInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  /**
   * Tim kiem theo name
   */
  onKeyDownInput = (event) => {
    if (event.key === "Enter") {
      this.list();
    }
  };

  /****************************************************************/

  /*******************************PAGINATION***************************/
  /**
   * Change limit
   */
  onChangeLimit = (option) => {
    let { conditions } = this.state;
    conditions.limit = option.value;

    // this.setState({ selectedLimit: option.value }, () => {
    this.setState({ conditions: conditions }, () => {
      this.list();
    });
  };

  /**
   * Change page
   */
  onPageChange = (pageNumber) => {
    let { conditions } = this.state;
    conditions.page = pageNumber;

    this.setState({ conditions }, () => {
      this.list(false);
    });
  };
  /****************************************************************/

  /*******************************CHECK AND ACTIONS***************************/
  checkAllItem = (event) => {
    const { checked } = event.target;

    let selectedIDsTg = [];
    if (checked) {
      selectedIDsTg = map(this.props.order.orders, (order) => order.order_id);

      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = true;
      });
    } else {
      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = false;
      });
    }

    this.setState({
      selectedIDs: selectedIDsTg,
    });
  };

  checkItem = (event, orderID) => {
    const { checked } = event.target;
    const { selectedIDs } = this.state;

    if (checked) {
      if (!includes(selectedIDs, orderID)) {
        selectedIDs.push(orderID);
      }

      if (selectedIDs.length == this.props.order.orders.length) {
        document.getElementById("checkAllItem").checked = true;
      }
    } else {
      document.getElementById("checkAllItem").checked = false;
      remove(selectedIDs, (selectedOrderID) => selectedOrderID == orderID);
    }

    this.setState({
      selectedIDs: selectedIDs,
    });
  };

  deletes = () => {
    if (this.state.selectedIDs.length <= 0) {
      window.LadiUI.toastCustom("danger", "", this.props.t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
      return;
    }

    this.props.deletes(this.state.selectedIDs);
  };

  deleteItem = (orderID) => {
    this.props.delete(orderID);
  };

  sort = (event) => {
    const { classList } = event.target;
    const name = event.target.getAttribute("name");

    window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui table .sorting"), (item) => {
      if (item.getAttribute("name") != name) {
        item.classList.remove("up");
      }
    });

    this.sortBy = {};

    // Change arrow up and down
    if (includes(classList, "up")) {
      classList.remove("up");
      this.sortBy[name] = "ASC";
    } else {
      classList.add("up");
      this.sortBy[name] = "DESC";
    }

    this.setState({}, () => this.list());
  };

  /**
   * Tim kiem theo status
   */
  onChangeSearchStatus = (status) => {
    this.setState({
      searchStatus: status,
    });
  };

  onChangeSearchSpam = (spam) => {
    this.setState({
      searchSpam: spam,
    });
  };

  onChangePaymentStatus = (paymentStatus) => {
    if (paymentStatus === null) {
      this.setState({
        searchPaymentStatus: [],
      });

      return;
    }
    const { searchPaymentStatus } = this.state;
    if (includes(searchPaymentStatus, paymentStatus)) {
      remove(searchPaymentStatus, (item) => item == paymentStatus);
    } else {
      searchPaymentStatus.push(paymentStatus);
    }

    this.setState({
      searchPaymentStatus,
    });
  };

  onChangeShippingStatus = (shippingStatus) => {
    if (shippingStatus === null) {
      this.setState({
        searchShippingStatus: [],
      });

      return;
    }

    const { searchShippingStatus } = this.state;
    if (includes(searchShippingStatus, shippingStatus)) {
      remove(searchShippingStatus, (item) => item == shippingStatus);
    } else {
      searchShippingStatus.push(shippingStatus);
    }

    this.setState({
      searchShippingStatus,
    });
  };

  onChangeProductType = (productType) => {
    if (productType === null) {
      this.setState({
        searchProductTypes: [],
      });

      return;
    }
    const { searchProductTypes } = this.state;
    if (includes(searchProductTypes, productType)) {
      remove(searchProductTypes, (item) => item == productType);
    } else {
      searchProductTypes.push(productType);
    }

    this.setState({
      searchProductTypes,
    });
  };

  openModalCancel = (selectedID) => {
    this.selectedID = selectedID;

    window.LadiUI.showModal("confirm-cancel-order");
  };

  onChangeInputUtm = (event) => {
    this.setState({
      searchUtm: {
        ...this.state.searchUtm,
        [event.target.name]: event.target.value,
      },
    });
  };

  getPaymentStatus = (status) => {
    const matched = find(appConfig.PAYMENT_STATUS, (item) => item.CODE == status);
    if (matched) {
      return matched.NAME;
    }
  };

  getShippingStatus = (status) => {
    const matched = find(appConfig.SHIPPING_STATUS, (item) => item.CODE == status);
    if (matched) {
      return matched.NAME;
    }
  };

  onChangeTab = (tabID) => {
    this.clearFilter(() => {
      let searchStatus = this.state.searchStatus;
      let searchSpam = this.state.searchSpam;
      let searchPaymentStatus = this.state.searchPaymentStatus;
      let searchShippingStatus = this.state.searchShippingStatus;
      switch (tabID) {
        case appConfig.ORDER_FILTER_TYPE.DRAFT:
          searchStatus = appConfig.ORDER_STATUS.OPEN.CODE;
          searchSpam = appConfig.ORDER_SPAM.NOT_SPAM.CODE;
          break;
        case appConfig.ORDER_FILTER_TYPE.PAYMENT_PENDING:
          searchStatus = appConfig.ORDER_STATUS.OPEN.CODE;
          searchPaymentStatus = [appConfig.PAYMENT_STATUS.PENDING.CODE];
          searchSpam = appConfig.ORDER_SPAM.NOT_SPAM.CODE;
          break;
        case appConfig.ORDER_FILTER_TYPE.SHIPPING_PENDING:
          searchStatus = appConfig.ORDER_STATUS.OPEN.CODE;
          searchShippingStatus = [appConfig.SHIPPING_STATUS.PENDING.CODE];
          searchSpam = appConfig.ORDER_SPAM.NOT_SPAM.CODE;
          break;
        case appConfig.ORDER_FILTER_TYPE.SPAM:
          searchSpam = appConfig.ORDER_SPAM.SPAM.CODE;
          break;
        case appConfig.ORDER_FILTER_TYPE.ALL:
          break;
      }

      this.setState(
        {
          tabSelected: tabID,
          searchStatus,
          searchSpam,
          searchPaymentStatus,
          searchShippingStatus,
        },
        () => {
          this.subscribe();
          this.list();
        }
      );
    });
  };

  openRemoveSpamModal = () => {
    if (this.state.selectedIDs.length <= 0) {
      window.LadiUI.toastCustom("danger", "", this.props.t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
      return;
    }

    window.LadiUI.showModal("confirm-remove-spam");
  };

  openMarkSpamModal = () => {
    if (this.state.selectedIDs.length <= 0) {
      window.LadiUI.toastCustom("danger", "", this.props.t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
      return;
    }

    window.LadiUI.showModal("confirm-mark-spam");
  };

  setDuplicationID = (order) => {
    this.setState(
      {
        duplicationID: order.order_id,
        duplicationNo: `#${order.reference_no}`,
        tabSelected: appConfig.ORDER_FILTER_TYPE.DUPLICATION,
      },
      () => {
        document.getElementById("triggerTabSearch").click();
        this.list(true);
      }
    );
  };

  eventSelectAction = (action) => {
    switch (action) {
      case "REMOVE_MARK_SPAM":
        this.openRemoveSpamModal();
        break;
      case "MARK_SPAM":
        this.openMarkSpamModal();
        break;
      // case 'CANCEL_ORDER':
      //     this.openModalCancel();
      //     break;
      // case 'CONFIRM_PAYMENT':
      //     this.openModalConfirmPayment();
      //     break;
      // case 'PRINT':
      //     this.prints();
      //     break;
      default:
    }
  };

  clearFilter = (callback) => {
    this.setState(
      {
        duplicationID: "",
        duplicationNo: "",
        // searchName: "",
        searchStatus: null,
        searchSpam: null,
        searchTag: {},
        // fromDate: null,
        // toDate: null,
        selectedTags: [],
        searchStatus: null,
        searchAssigneeID: "",
        searchCreatorID: "",
        searchPaymentStatus: [],
        searchShippingStatus: [],
        product_ids: [],
        searchProductTypes: [],
        searchPaymentMethod: [],
        searchIP: "",
        searchUtm: {
          urlPage: "",
          source: "",
          medium: "",
          campaign: "",
          term: "",
          content: "",
        },
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  filterActionProp = async (conditions) => {
    this.clearFilter();

    let filter = {
      tabSelected: appConfig.ORDER_FILTER_TYPE.SEARCH_ALL,
      searchUtm: {},
    };

    for await (const condition of conditions) {
      switch (condition.attributeFilter.value) {
        case "status":
          filter.searchStatus = condition.valueFilter.value;
          break;
        case "payment_status":
          filter.searchPaymentStatus = condition.listFilter.map((item) => item.value);
          break;
        case "payment_method":
          filter.searchPaymentMethod = condition.listFilter.map((item) => item.value);
          break;
        case "payment_shipping_status":
          filter.searchShippingStatus = condition.listFilter.map((item) => item.value);
          break;
        case "product_ids":
          filter.product_ids = condition.listFilter.map((item) => item.value);
          break;
        case "product_type":
          filter.searchProductTypes = condition.listFilter.map((item) => item.value);
          break;
        case "utm_medium":
          filter.searchUtm.medium = condition.valueFilter.value;
          break;
        case "creator":
          filter.searchCreatorID = condition.valueFilter.value;
          break;
        case "staff":
          filter.searchAssigneeID = condition.valueFilter.value;
          break;
        case "tags":
          filter.searchTag = condition.listFilter.map((item) => item.value);
          break;
        case "ip":
          filter.searchIP = condition.valueFilter.value;
          break;
        case "spam":
          filter.searchSpam = condition.valueFilter.value;
          break;
        case "utm_page":
          filter.searchUtm.urlPage = condition.valueFilter.value;
          break;
        case "utm_source":
          filter.searchUtm.source = condition.valueFilter.value;
          break;
        case "utm_campaign":
          filter.searchUtm.campaign = condition.valueFilter.value;
          break;
        case "utm_term":
          filter.searchUtm.term = condition.valueFilter.value;
          break;
        case "utm_content":
          filter.searchUtm.content = condition.valueFilter.value;
          break;
        case "msg_id":
          filter.searchUtm.msgID = condition.valueFilter.value;
          break;
        default:
          break;
      }
    }

    this.setState(
      {
        ...filter,
      },
      () => {
        this.list();
      }
    );
  };

  /******************************CALLBACK MODAL*********************/
  exportOrder = (type) => {
    let ids;
    const { selectedIDs } = this.state;
    switch (baseHelper.parseInt(type)) {
      case appConfig.EXPORT_ORDER.ALL_ORDER:
        ids = appConfig.EXPORT_TYPE.ALL;
        break;
      case appConfig.EXPORT_ORDER.ON_PAGE:
        const onPageIDs = map(this.props.order.orders, (item) => item.order_id);
        ids = onPageIDs;
        break;
      case appConfig.EXPORT_ORDER.SELECTED_ORDER:
        if (baseHelper.isEmpty(selectedIDs)) {
          window.LadiUI.toastCustom("warning", "", this.props.t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
          return;
        }
        ids = selectedIDs;
        break;
    }

    this.props.exportOrder({ ids });
  };

  exportTransaction = (selected) => {
    let ids = [];

    if (selected == appConfig.EXPORT_ORDER.ALL_ORDER) {
      ids = ["all"];
    } else {
      ids = selected;
    }

    this.props.exportTransaction(ids);
  };
  /****************************************************************/

  handleClickTag = (item, selectedTags) => {
    this.setState(
      {
        searchTag: {
          ...this.state.searchTag,
          order_tag_id: item,
        },
        selectedTags,
      },
      () => {
        this.list();
      }
    );
  };

  /****************************************************************/
  render() {
    const { t } = this.props;
    const { totalRecord, totalDuplication } = this.props.order;
    const {
      activePage,
      selectedLimit,
      searchName,
      searchStatus,
      searchSpam,
      tabSelected,
      conditions,
      searchPaymentStatus,
      searchShippingStatus,
      searchProductTypes,
      searchUtm,
      searchIP,
      selectedTags,
      duplicationNo,
      conditionProp,
    } = this.state;
    let fromItem = 0;
    let toItem = 0;
    if ((this.props.order.orders || []).length > 0) {
      fromItem = (activePage - 1) * selectedLimit + 1;
      toItem = fromItem + this.props.order.orders.length - 1;
    }

    // const msgShowItem = `${t('PAGINATION.SHOW')} ${fromItem} ${t('PAGINATION.TO')} ${toItem} ${t('PAGINATION.OF')} ${baseHelper.formatNumber(totalRecord)} ${t('PAGINATION.ITEMS')}  ${t('ORDERS.INCLUDE_DUPLICATION', totalDuplication)}`;
    const msgShowItem = `${t("PAGINATION.SHOW")} ${fromItem} ${t("PAGINATION.TO")} ${toItem} ${t(
      "PAGINATION.OF"
    )} ${baseHelper.formatNumber(totalRecord)} ${t("PAGINATION.ITEMS")} ${t("ORDERS.INCLUDE_DUPLICATION", totalDuplication)}`;

    const isLoadingData = this.props.order.loading && this.props.order.waiting == types.LIST_MY_ORDER;
    const isLoadingCancel = this.props.order.loading && includes([types.CANCEL_ORDER], this.props.order.waiting);
    const isLoadingAssign = this.props.order.loading && types.ASSIGN_ORDER == this.props.order.waiting;
    const isLoadingUpdateSpam = this.props.order.loading && types.UPDATE_SPAMS == this.props.order.waiting;
    const isLoadingRemoveDuplication = this.props.order.loading && types.REMOVE_DUPLICATION_ORDER == this.props.order.waiting;
    const isLoadingExportOrder = this.props.order.loading && includes([types.EXPORT_ORDER], this.props.order.waiting);
    const isLoadingExportTransaction = this.props.order.loading && includes([types.EXPORT_TRANSACTION], this.props.order.waiting);

    const allTags = this.props.orderTagReducer.allTags || [];
    const productLists = this.props.productReducer.products || [];

    const listAction = [
      // { value: 'PRINT', name: t('COMMON.PRINT') },
      { value: "REMOVE_MARK_SPAM", name: t("ORDERS.REMOVE_MARK_SPAM") },
      { value: "MARK_SPAM", name: t("ORDERS.MARK_SPAM") },
      // { value: 'CANCEL_ORDER', name: t('ORDERS.CANCEL_ORDER') },
      // { value: 'CONFIRM_PAYMENT', name: t('ORDERS.CONFIRM_PAYMENT') },
    ];

    return (
      <div className="page-content">
        {(isLoadingAssign || isLoadingUpdateSpam || isLoadingRemoveDuplication) && <LoadingScene blur={true} />}
        <div>
          <div className="ladiui-table-actions mb-24">
            <div
              className="ladiui block-filter-condition"
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <div className="ladiui btn-group block-filter-header">
                <FilterConditionOrder
                  filterActionProp={this.filterActionProp}
                  conditionProp={conditionProp}
                  allTags={allTags}
                  products={productLists}
                  isMyOrder={true}
                />
              </div>

              <div className="ladiui search-group mr-24">
                <input
                  id="keyword_search"
                  className="ladiui search-field dropdown-toggle form-control search-width"
                  name="searchName"
                  placeholder={t("ORDERS.SEARCH_ORDER")}
                  aria-expanded="false"
                  value={searchName}
                  onChange={this.onChangeInput}
                  onKeyDown={this.onKeyDownInput}
                />
                <i className="ladiui icon icon-search" onClick={this.list}></i>
              </div>

              <div className="date-filter">
                <OrderDateRangePicker
                  startDateId="fromDate"
                  endDateId="toDate"
                  startDate={this.state.fromDate}
                  endDate={this.state.toDate}
                  onDatesChange={({ startDate, endDate }) => {
                    this.setState(
                      {
                        fromDate: baseHelper.getFromDate(startDate),
                        toDate: baseHelper.getToDate(endDate),
                      },
                      () => {
                        if ((startDate && endDate) || (!startDate && !endDate)) {
                          this.list();
                        }
                      }
                    );
                  }}
                  focusedInput={this.state.focusedInput}
                  onFocusChange={(focusedInput) => {
                    this.setState({ focusedInput });
                  }}
                  showDefaultInputIcon={true}
                  inputIconPosition="after"
                  small={true}
                  hideKeyboardShortcutsPanel={true}
                  customInputIcon={<i className="ladi-icon icon-calendar-date-2" />}
                  startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                  endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                  isOutsideRange={() => false}
                  isDayHighlighted={(date) => {
                    return this.moment().isSame(date, "day");
                  }}
                  minimumNights={0}
                  showClearDates={true}
                />
              </div>

              <div className="ladiui btn-group custom-btn-list-landingpage">
                <button
                  className="ladiui btn btn-light"
                  onClick={() =>
                    this.setState({
                      isShowModalExportOrder: true,
                    })
                  }
                >
                  <img
                    src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-export.svg"
                    style={{ marginBottom: "-2px", marginRight: "10px" }}
                  />
                  {t("ACTIONS.EXPORT")}
                </button>
              </div>
            </div>
          </div>

          <div className="order-tab">
            <ul className="ladiui nav nav-tabs overflow-hide">
              <li className="ladiui nav-item" id="tabAll">
                <span
                  data-tab="tabAll"
                  className={`ladiui tab-link ${tabSelected === appConfig.ORDER_FILTER_TYPE.ALL_NOT_SPAM ? "active" : ""}`}
                  onClick={() => this.onChangeTab(appConfig.ORDER_FILTER_TYPE.ALL_NOT_SPAM)}
                >
                  {t("ORDERS.TAB_ALL")}
                </span>
              </li>
              <li className="ladiui nav-item" id="tabDraft">
                <span
                  data-tab="tabDraft"
                  className={`ladiui tab-link ${tabSelected === appConfig.ORDER_FILTER_TYPE.DRAFT ? "active" : ""}`}
                  onClick={(event) => this.onChangeTab(appConfig.ORDER_FILTER_TYPE.DRAFT, event)}
                >
                  {t("ORDERS.TAB_DRAFT")}
                </span>
              </li>
              <li className="ladiui nav-item" id="tabShippingPending">
                <span
                  data-tab="tabShippingPending"
                  className={`ladiui tab-link ${tabSelected === appConfig.ORDER_FILTER_TYPE.SHIPPING_PENDING ? "active" : ""}`}
                  onClick={() => this.onChangeTab(appConfig.ORDER_FILTER_TYPE.SHIPPING_PENDING)}
                >
                  {t("ORDERS.TAB_SHIPPING_PENDING")}
                </span>
              </li>
              <li className="ladiui nav-item" id="tabPaymentPending">
                <span
                  data-tab="tabPaymentPending"
                  className={`ladiui tab-link ${tabSelected === appConfig.ORDER_FILTER_TYPE.PAYMENT_PENDING ? "active" : ""}`}
                  onClick={() => this.onChangeTab(appConfig.ORDER_FILTER_TYPE.PAYMENT_PENDING)}
                >
                  {t("ORDERS.TAB_PAYMENT_PENDING")}
                </span>
              </li>
              <li className="ladiui nav-item" id="tabSpam">
                <span
                  data-tab="tabSpam"
                  className={`ladiui tab-link ${tabSelected === appConfig.ORDER_FILTER_TYPE.SPAM ? "active" : ""}`}
                  onClick={() => this.onChangeTab(appConfig.ORDER_FILTER_TYPE.SPAM)}
                >
                  {t("ORDERS.TAB_SPAM")}
                </span>
              </li>
              <li
                className="ladiui nav-item"
                id="tabDuplication"
                style={this.state.tabSelected == appConfig.ORDER_FILTER_TYPE.DUPLICATION ? { display: "block" } : { display: "none" }}
              >
                <span
                  id="triggerTabDuplication"
                  className={`ladiui tab-link ${tabSelected === appConfig.ORDER_FILTER_TYPE.DUPLICATION ? "active" : ""}`}
                  data-tab="tabDuplication"
                >
                  {t("ORDERS.TAB_SEARCH_DUPLICATION", duplicationNo)}
                </span>
              </li>
              <li
                className="ladiui nav-item"
                id="tabSearch"
                style={this.state.tabSelected == appConfig.ORDER_FILTER_TYPE.SEARCH_ALL ? { display: "block" } : { display: "none" }}
              >
                <span
                  id="triggerTabSearch"
                  className={`ladiui tab-link ${tabSelected === appConfig.ORDER_FILTER_TYPE.SEARCH_ALL ? "active" : ""}`}
                  data-tab="tabSearch"
                >
                  {t("ORDERS.TAB_SEARCH")}
                </span>
              </li>
            </ul>
          </div>
          {isLoadingData ? (
            <LoadingTable />
          ) : (
            <div>
              <div className="ladi-card ladiui-table-responsive">
                <table className="ladiui table text-left order-table table-order">
                  <thead style={{ position: "relative" }}>
                    <tr className="ladiui table-vertical header">
                      <th scope="col" className="ladiui checkall">
                        <input
                          id="checkAllItem"
                          onClick={this.checkAllItem}
                          type="checkbox"
                          className="ladiui-checkall ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle"
                        />
                      </th>
                      {this.state.selectedIDs.length <= 0 ? (
                        <th
                          scope="col"
                          name="reference_no"
                          onClick={this.sort}
                          className={`text-left sorting ${this.sortBy["reference_no"] == "DESC" ? "up" : ""}`}
                          style={{ width: "150px", maxWidth: "fit-content" }}
                        >
                          {t("ORDERS.REF_NO")}{" "}
                        </th>
                      ) : (
                        <th scope="col" style={{ width: "150px", maxWidth: "fit-content" }}>
                          {t("ORDERS.REF_NO")}{" "}
                          <LadiDropdownMenu
                            labelDropdown={"Chọn hành động"}
                            listProp={listAction}
                            defaultTextProp={t("ACTIONS.TITLE")}
                            hideLabel={true}
                            cbProp={this.eventSelectAction}
                            classSize="btn-sm "
                            classWidth="w-180"
                            classDropdown="w-180 action-record-table"
                          />
                        </th>
                      )}
                      {/* <th name="reference_no" onClick={this.sort} className={`text-left sorting ${this.sortBy['reference_no'] == 'DESC' ? 'up' : ''}`}>{t('ORDERS.REF_NO')} */}

                      <th scope="col">{t("ORDERS.CUSTOMER")}</th>
                      <th scope="col">{t("COMMON.PHONE")}</th>
                      <th scope="col">{t("COMMON.ORDERED_AT")}</th>
                      <th scope="col">{t("ORDERS.PAYMENT")}</th>
                      <th scope="col">{t("ORDERS.LB_SHIPPING")}</th>
                      <th scope="col">{t("ORDERS.TOTAL")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(this.props.order.orders, (order) => {
                      const tagIDs = order.tag_ids ? order.tag_ids.split(appConfig.SEPARATOR.DEFAULT) : [];
                      const tagNames = order.tag_names ? order.tag_names.split(appConfig.SEPARATOR.DEFAULT) : [];
                      const tagColors = order.tag_colors ? order.tag_colors.split(appConfig.SEPARATOR.DEFAULT) : [];

                      const isDuplicate = order.parent_id;
                      const isShow = order.is_show == appConfig.STATUS.ACTIVE;

                      let className = "ladiui table-vertical main";
                      if (order.status == appConfig.ORDER_STATUS.CANCELED.CODE) {
                        className = `${className} order-cancel`;
                      }

                      if (isDuplicate) {
                        className = `${className} duplicate`;
                      }

                      return (
                        <>
                          <tr key={order.order_id} className={`${className}`} style={tagIDs.length > 0 ? { borderBottom: "none" } : {}}>
                            <td>
                              <input
                                type="checkbox"
                                onClick={(event) => this.checkItem(event, order.order_id)}
                                className="ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle"
                              />
                            </td>
                            {/* onClick={() => { document.getElementById('menu-more-filter').click() }} */}
                            <td style={{ display: "flex", justifyContent: "space-between", minWidth: "150px", maxWidth: "fit-content" }}>
                              <NavLink
                                className="order-table-item block"
                                to={`/orders/${order.order_id}`}
                              >{`#${order.reference_no}`}</NavLink>
                              {order.total_child > 0 && (
                                <span onClick={() => this.setDuplicationID(order)} className="badge badge-light flex ml-16 mr-16">
                                  <i className="ladi-icon icon-duplicate mr-4" />({order.total_child})
                                </span>
                              )}
                              {order.parent_id && (
                                <span
                                  onClick={() => this.setDuplicationID(order.parent_id, order.parent_reference_no)}
                                  className="badge badge-child-light flex ml-16 mr-16"
                                >
                                  <i className="ladi-icon icon-duplicate mr-4" />#{order.parent_reference_no}
                                </span>
                              )}
                            </td>
                            <td className={`${!isShow && "prevent-click"}`}>
                              <a href={`/customers?customer_id=${order.customer_id}`}>{`${baseHelper.getText(
                                order.customer_last_name
                              )} ${baseHelper.getText(order.customer_first_name)}`}</a>
                            </td>
                            <td>{order.customer_phone}</td>
                            <td>{baseHelper.formatStrToDate(order.ordered_at)}</td>
                            <td>
                              {this.getPaymentStatus(order.payment_status)}
                              {order.payment_retry_get_status >= 5 && order.payment_status != appConfig.PAYMENT_STATUS.SUCCESS && (
                                <span style={{ marginLeft: 5 }} data-tooltip={t("ORDERS.PAYMENT_FAILED")} data-tooltip-position="top">
                                  <i className="ladi-icon icon-c-warning" />
                                </span>
                              )}
                            </td>
                            <td>{this.getShippingStatus(order.shipping_status)}</td>
                            {/* <td>
                            {tagIDs.length > 0 && (
                              <ListTags
                                tagColors={tagColors}
                                tagValues={tagIDs}
                                tagNames={tagNames}
                                handleClickTagItem={this.handleClickTag}
                              />
                            )}
                          </td> */}
                            <td>
                              {baseHelper.formatMoneyPostFix(
                                order.refund ? order.total - order.refund : order.total,
                                this.props.storeReducer.userInfo.currentStore.currency_symbol
                              )}
                            </td>

                            <td className="text-right pd-0">
                              <div className="ladiui btn-group">
                                <div className="ladiui dropdown hide-mt ba-c">
                                  <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                                    <i className="ladiui icon icon-ldp-dot"></i>
                                  </button>
                                  <ul className="ladiui dropdown-menu r-0">
                                    <li>
                                      <a
                                        className="ladiui dropdown-item"
                                        onClick={() => this.props.history.push(`/orders/${order.order_id}`)}
                                      >
                                        {t("ACTIONS.EDIT")}
                                      </a>
                                    </li>
                                    {order.mark_spam == appConfig.STATUS.ACTIVE && (
                                      <li>
                                        <a
                                          className="ladiui dropdown-item"
                                          onClick={() =>
                                            this.props.updateSpams({
                                              order_ids: [order.order_id],
                                              mark_spam: appConfig.STATUS.INACTIVE,
                                            })
                                          }
                                        >
                                          {t("ORDERS.REMOVE_MARK_SPAM")}
                                        </a>
                                      </li>
                                    )}
                                    {order.mark_spam == appConfig.STATUS.INACTIVE && order.status != appConfig.ORDER_STATUS.CANCELED.CODE && (
                                      <li>
                                        <a
                                          className="ladiui dropdown-item"
                                          onClick={() =>
                                            this.props.updateSpams({
                                              order_ids: [order.order_id],
                                              mark_spam: appConfig.STATUS.ACTIVE,
                                            })
                                          }
                                        >
                                          {t("ORDERS.MARK_SPAM")}
                                        </a>
                                      </li>
                                    )}
                                    {isDuplicate && (
                                      <li>
                                        <a className="ladiui dropdown-item" onClick={() => this.props.removeDuplication(order.order_id)}>
                                          {t("ORDERS.REMOVE_DUPLICATION")}
                                        </a>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                          {tagIDs.length > 0 && (
                            <tr className="ladiui table-vertical main custom-tag">
                              <td></td>
                              <td colSpan={10}>
                                {tagIDs.length > 0 && (
                                  <ListTags
                                    tagColors={tagColors}
                                    tagValues={tagIDs}
                                    tagNames={tagNames}
                                    handleClickTagItem={this.handleClickTag}
                                  />
                                )}
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })}
                    {totalRecord <= 0 && (
                      <tr className="text-center">
                        <td colSpan="100%">{t("NO_DATA")}</td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={7}>
                        <strong>{t("ORDERS.TOTAL_AMOUNT")}</strong>
                      </td>
                      <td colSpan={2}>
                        <strong className="pl-0">{baseHelper.formatMoneyPostFix(this.state.totalAmount, this.props.storeReducer.userInfo.currentStore.currency_symbol)}</strong>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              {totalRecord > 0 && (
                <LadiPagination
                  conditionsProp={conditions}
                  listName={t("CUSTOM_FIELD.ORDER")}
                  message={msgShowItem}
                  items={this.props.order.orders}
                  total={totalRecord}
                  limitAction={this.onChangeLimit}
                  pageAction={this.onPageChange}
                />
              )}
            </div>
          )}
        </div>

        {this.state.isShowModalExportOrder && (
          <ModalExportOrder
            visible={this.state.isShowModalExportOrder}
            isLoadingExportOrder={isLoadingExportOrder}
            isLoadingExportTransaction={isLoadingExportTransaction}
            onExportOrder={this.exportOrder}
            onExportTransaction={this.exportTransaction}
            onCancel={() => {
              this.setState({
                isShowModalExportOrder: false,
              });
            }}
          />
        )}

        <ConfirmModal
          id="confirm-cancel-order"
          title={t("ORDERS.MSG_CANCEL_ORDER_TITLE")}
          content={t("ORDERS.MSG_CANCEL_ORDER_CONTENT")}
          cancelText={t("ACTIONS.CLOSE")}
          okText={t("ACTIONS.CANCEL")}
          onOk={() => this.props.cancel(this.selectedID)}
          isLoading={isLoadingCancel}
        />

        <ConfirmModal
          id="confirm-mark-spam"
          title={t("ORDERS.MARK_SPAM")}
          content={t("ORDERS.MSG_MARK_SPAM_TO_CONTENT")}
          cancelText={t("ACTIONS.CANCEL")}
          okText={t("ACTIONS.OK")}
          onOk={() =>
            this.props.updateSpams({
              order_ids: this.state.selectedIDs,
              mark_spam: appConfig.STATUS.ACTIVE,
            })
          }
          isLoading={isLoadingUpdateSpam}
        />

        <ConfirmModal
          id="confirm-remove-spam"
          title={t("ORDERS.REMOVE_MARK_SPAM")}
          content={t("ORDERS.MSG_REMOVE_MARK_SPAM_TO_CONTENT")}
          cancelText={t("ACTIONS.CANCEL")}
          okText={t("ACTIONS.OK")}
          onOk={() =>
            this.props.updateSpams({
              order_ids: this.state.selectedIDs,
              mark_spam: appConfig.STATUS.INACTIVE,
            })
          }
          isLoading={isLoadingUpdateSpam}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listMyOrders: (data) => dispatch(orderActions.listMyOrders(data)),
    cancel: (orderID) => dispatch(orderActions.cancel(orderID)),
    show: (orderID) => dispatch(orderActions.show(orderID)),
    exportOrder: (data) => dispatch(orderActions.exportOrder(data)),
    exportTransaction: (data) => dispatch(orderActions.exportTransaction(data)),
    removeDuplication: (orderID) => dispatch(orderActions.removeDuplication(orderID)),
    wsAppendNewOrder: (orders, totalRecord) => dispatch(orderActions.appendNewOrder(orders, totalRecord)),
    listAllTags: () => dispatch(orderTagActions.listAll()),
    updateSpams: (data) => dispatch(orderActions.updateSpams(data)),
    searchProduct: (data) => dispatch(productActions.search(data))
  };
};

const mapStateToProps = (state) => ({
  order: { ...state.order },
  orderTagReducer: { ...state.orderTag },
  storeReducer: { ...state.store },
  productReducer: { ...state.product }
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyOrder));
