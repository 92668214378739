import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import queryString from "query-string";

import reportActions from "../../redux/futures/report/actions";
import * as reportTypes from "../../redux/futures/report/types";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { cloneDeep, map, find } from "lodash";
import Pagination from "react-js-pagination";
import LoadingTable from "../../components/LoadingTable";

import OrderDateRangePicker from "../../components/OrderDateRangePicker";
import baseHelper from "../../helpers/BaseHelper";

import appConfig from "../../config/app";
import chartConfig from "../../config/chartconfig";
import ItemFilter from "../orders/components/ItemFilter";
import { appLocalStorage } from "../../localforage";
import SelectOption from "../../components/SelectOption";
import LadiPagination from "../../components/LadiPagination";
class OrderOverTime extends React.Component {
  constructor(props) {
    super(props);
    this.moment = baseHelper.getMoment();

    const now = this.moment();
    const firstDay = now.set({
      date: 1,
    });

    const defaultConditions = {
      page: 1,
      limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
    };

    this.state = {
      fromDate: firstDay,
      toDate: this.moment(),
      focusedInput: null,
      activePage: 1,
      selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
      optionsList: [],
      userInfo: {},
      data: [],
      pageNumber: 1,
      conditions: defaultConditions,
      searchCreatorID: null
    };
  }

  componentWillMount() {
    appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
      const owner = find(result.staffs, (item) => item.ladi_uid == result.ladi_uid);
      owner.name = this.props.t("COMMON.ME");
      this.setState({
        userInfo: result,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.reportReducer.action != nextProps.reportReducer.action) {
      if (nextProps.reportReducer.action == reportTypes.ORDER_OVERTIME_REPORT) {
        if (nextProps.reportReducer.status) {
          const { fromDate, toDate } = this.state;
          const moment = this.moment;
          let data = [];
          const num_orders_day = nextProps.reportReducer.orders;

          for (
            let datex = moment(fromDate).format("YYYY/MM/DD");
            datex <= moment(toDate).format("YYYY/MM/DD");
            datex = moment(datex, "YYYY/MM/DD").add(1, "d").format("YYYY/MM/DD")
          ) {
            let xxxxx = moment(datex).format("DD/MM/YYYY");
            let item = {};
            item.date = xxxxx;
            item.total_new = 0;
            item.total_draft = 0;
            item.total_canceled = 0;
            item.total_success = 0;
            item.total_revenue = 0;

            let itemSuccess = num_orders_day.orderSuccess.find((item) => moment(item.date).format("DD/MM/YYYY") === xxxxx);
            if (itemSuccess) {
              item.total_success = itemSuccess.quantity;
              item.total_revenue = itemSuccess.total_success;
            }

            let itemPending = num_orders_day.orderPending.find((item) => moment(item.date).format("DD/MM/YYYY") === xxxxx);
            if (itemPending) item.total_draft = itemPending.quantity;

            let itemCancel = num_orders_day.orderCancel.find((item) => moment(item.date).format("DD/MM/YYYY") === xxxxx);
            if (itemCancel) item.total_canceled = itemCancel.quantity;

            let itemNew = num_orders_day.orderNew.find((item) => moment(item.date).format("DD/MM/YYYY") === xxxxx);
            if (itemNew) item.total_new = itemNew.quantity;

            data.push(item);
          }
          this.setState({
            data,
          });

          this.processData(data);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.reportReducer.message, "OK");
        }
      }
    }
  }

  componentDidMount() {
    const { userInfo } = this.state;
    const { from: _fromDate, to: _toDate, staff_id } = queryString.parse(this.props.location.search);

    const fromDate = baseHelper.getFromDate(baseHelper.getMoment()(_fromDate));
    const toDate = baseHelper.getToDate(baseHelper.getMoment()(_toDate));

    this.setState(
      {
        fromDate: fromDate,
        toDate: toDate,
        searchAssigneeID: staff_id ? staff_id : null
      },
      () => {
        this.getReport();
      }
    );

    window.LadiUI.init();
  }
  componentDidUpdate() {
    window.LadiUI.init();
  }

  processData = (data) => {
    const { fromDate, toDate } = this.state;
    const orderSeries = [
      {
        type: "spline",
        name: "Tổng đơn mới",
        // color: '#41d3f4',
        data: [],
      },
      {
        name: "Thành công",
        // color: '#1853fa',
        data: [],
      },
      {
        name: "Chưa xử lý",
        // color: '#f4c241',
        data: [],
      },
      {
        name: "Thất bại",
        // color: '#b3b3b3',
        data: [],
      },
    ];

    const xAxis = [];

    map(data, (item) => {
      xAxis.push(item.date);

      orderSeries[0].data.push(item.total_new);
      orderSeries[1].data.push(item.total_success);
      orderSeries[2].data.push(item.total_draft);
      orderSeries[3].data.push(item.total_canceled);
    });

    const optionsList = [];
    const optOrder = cloneDeep(chartConfig.option_column);
    optOrder.series = orderSeries;
    optOrder.title.text = "Báo cáo đơn hàng";
    optOrder.xAxis.categories = xAxis;
    optOrder.colors = ["#F7B500", "#A6CEE3", "#1F78B4", "#A8D24C"];
    optOrder.yAxis.title.text = "";
    optionsList.push(optOrder);

    this.setState({
      optionsList,
    });
  };

  getReport = (resetActivePageFlag = true) => {
    const { fromDate, toDate, selectedLimit, searchAssigneeID, searchCreatorID, conditions } = this.state;

    let activePage = conditions.page;
    if (resetActivePageFlag) {
      activePage = 1;
      conditions.page = 1;

      this.setState({
        conditions,
      });
    }
    const data = {
      from_date: fromDate,
      to_date: toDate,
      assignee_id: searchAssigneeID,
      creator_id: searchCreatorID,
      limit: conditions.limit,
      paged: activePage,
    };
    this.props.orderOverTimeReport(data);
  };

  /*******************************PAGINATION***************************/
  /**
   * Change limit
   */
  onChangeLimit = (option) => {
    let { conditions } = this.state;
    conditions.limit = option.value;

    // this.setState({ selectedLimit: option.value }, () => {
    this.setState({ conditions: conditions }, () => {
      this.getReport();
    });
    // this.setState({ selectedLimit: option.value }, () => {
    //     this.getReport();
    // })
  };

  /**
   * Change page
   */
  onPageChange = (pageNumber) => {
    // this.setState({ activePage: pageNumber });

    let { conditions } = this.state;
    conditions.page = pageNumber;

    this.setState({ conditions }, () => {
      this.getReport(false);
    });
  };

  render() {
    const { t } = this.props;
    // const { totalRecord, totalPage } = this.props.reportReducer;
    const { activePage, selectedLimit, searchAssigneeID, searchCreatorID, userInfo, data, conditions } = this.state;

    let fromItem = 0;
    let toItem = 0;
    const totalRecord = data.length;

    if ((data || []).length > 0) {
      fromItem = (conditions.page - 1) * conditions.limit + 1;
      // toItem = data.length > fromItem + (conditions.limit - 1) ? fromItem + (conditions.limit - 1) : fromItem + (data.length - 1);
      toItem = totalRecord;
      if (conditions.page * conditions.limit < totalRecord) {
        toItem = conditions.page * conditions.limit;
      }
    }

    // if (conditions.page * conditions.limit < totalRecord) {
    //     toItem = conditions.page + conditions.limit;
    // }

    const staffs = userInfo.staffs;

    const msgShowItem = `${t("PAGINATION.SHOW")} ${fromItem} ${t("PAGINATION.TO")} ${toItem} ${t(
      "PAGINATION.OF"
    )} ${baseHelper.formatNumber(totalRecord)} ${t("PAGINATION.ITEMS")}`;
    const isLoadingData = this.props.reportReducer.loading && this.props.reportReducer.waiting == reportTypes.ORDER_OVERTIME_REPORT;

    return (
      <div className="order-orver-time-page page-content">
        <h1 className="page-title pt-0 pb-24">{t("REPORTS.DETAIL_ORDER.TITLE")}</h1>
        <div className="ladiui-table-actions" style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
          <div className="action-item">
            <OrderDateRangePicker
              startDateId="fromDate"
              endDateId="toDate"
              startDate={this.state.fromDate}
              endDate={this.state.toDate}
              onDatesChange={({ startDate, endDate }) => {
                this.setState(
                  {
                    fromDate: baseHelper.getFromDate(startDate),
                    toDate: baseHelper.getToDate(endDate),
                  },
                  () => {
                    if (startDate && endDate) {
                      this.getReport();
                    }
                  }
                );
              }}
              focusedInput={this.state.focusedInput}
              onFocusChange={(focusedInput) => {
                this.setState({ focusedInput });
              }}
              showDefaultInputIcon={true}
              inputIconPosition="after"
              small={true}
              hideKeyboardShortcutsPanel={true}
              customInputIcon={<i className="ladi-icon icon-calendar-date-2" />}
              startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
              endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
              isOutsideRange={() => false}
              isDayHighlighted={(date) => {
                return this.moment().isSame(date, "day");
              }}
              minimumNights={0}
            />
          </div>
          <div className="action-item">
            <SelectOption
              wrapperClassName="select-creator"
              wrapperStyle={{ minWidth: 120 }}
              currentKey={searchCreatorID}
              _key="ladi_uid"
              _value="name"
              items={staffs || []}
              onSelectItem={(item) => {
                this.setState(
                  {
                    searchCreatorID: item ? item.ladi_uid : null,
                  },
                  () => {
                    this.getReport();
                  }
                );
              }}
              pickTitle={t("REPORTS.DETAIL_ORDER.CREATOR")}
            />
          </div>

          <div className="action-item">
            <SelectOption
              wrapperClassName="select-assignee"
              wrapperStyle={{ minWidth: 150 }}
              currentKey={searchAssigneeID}
              _key="ladi_uid"
              _value="name"
              items={staffs || []}
              onSelectItem={(item) => {
                this.setState(
                  {
                    searchAssigneeID: item ? item.ladi_uid : null,
                  },
                  () => {
                    this.getReport();
                  }
                );
              }}
              pickTitle={t("REPORTS.DETAIL_ORDER.ASSIGNEE")}
            />
          </div>
        </div>
        <div>
          <div className="ladiui-row flex dashboard">
            {map(this.state.optionsList, (option) => {
              return (
                <div className="ladiui card col mt-0" key={"LINE" + option.title}>
                  <div className="card-body">
                    <div className="ls-dashboard-chart">
                      <HighchartsReact highcharts={Highcharts} options={option} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* <div className="ladiui-table-actions" style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
            <div className="action-item">
              <OrderDateRangePicker
                startDateId="fromDate"
                endDateId="toDate"
                startDate={this.state.fromDate}
                endDate={this.state.toDate}
                onDatesChange={({ startDate, endDate }) => {
                  this.setState(
                    {
                      fromDate: baseHelper.getFromDate(startDate),
                      toDate: baseHelper.getToDate(endDate),
                    },
                    () => {
                      if (startDate && endDate) {
                        this.getReport();
                      }
                    }
                  );
                }}
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) => {
                  this.setState({ focusedInput });
                }}
                showDefaultInputIcon={true}
                inputIconPosition="after"
                small={true}
                hideKeyboardShortcutsPanel={true}
                customInputIcon={<i className="ladi-icon icon-calendar-date-2" />}
                startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                isOutsideRange={() => false}
                isDayHighlighted={(date) => {
                  return this.moment().isSame(date, "day");
                }}
                minimumNights={0}
              />
            </div>
            <div className="action-item">
              <SelectOption
                wrapperClassName="select-creator"
                wrapperStyle={{ minWidth: 120 }}
                currentKey={searchCreatorID}
                _key="ladi_uid"
                _value="name"
                items={staffs || []}
                onSelectItem={(item) => {
                  this.setState(
                    {
                      searchCreatorID: item ? item.ladi_uid : null,
                    },
                    () => {
                      this.getReport();
                    }
                  );
                }}
                pickTitle={t("REPORTS.DETAIL_ORDER.CREATOR")}
              />
            </div>

            <div className="action-item">
              <SelectOption
                wrapperClassName="select-assignee"
                wrapperStyle={{ minWidth: 150 }}
                currentKey={searchAssigneeID}
                _key="ladi_uid"
                _value="name"
                items={staffs || []}
                onSelectItem={(item) => {
                  this.setState(
                    {
                      searchAssigneeID: item ? item.ladi_uid : null,
                    },
                    () => {
                      this.getReport();
                    }
                  );
                }}
                pickTitle={t("REPORTS.DETAIL_ORDER.ASSIGNEE")}
              />
            </div>
          </div> */}

          {isLoadingData ? (
            <LoadingTable />
          ) : (
            <div>
              <div className="ladi-card ladiui-table-responsive">
                <table className={`ladiui table text-left ${isLoadingData ? "loader" : ""}`}>
                  <thead>
                    <tr className="ladiui table-vertical header">
                      <th scope="col" name="date" onClick={this.sort} className={`text-left`}>
                        {t("COMMON.DATE")}
                      </th>
                      <th scope="col" name="total_order" className="text-left">
                        {t("REPORTS.DETAIL_ORDER.TOTAL_NEW")}
                      </th>
                      <th scope="col" name="draft" className="text-left">
                        {t("REPORTS.DETAIL_ORDER.DRAFT")}
                      </th>
                      <th scope="col" name="canceled" className="text-left">
                        {t("REPORTS.DETAIL_ORDER.CANCELED")}
                      </th>
                      <th scope="col" className="text-left">
                        {t("REPORTS.DETAIL_ORDER.SUCCESS")}
                      </th>
                      <th scope="col" className="text-left">
                        {t("REPORTS.DETAIL_ORDER.REVENUE")}
                      </th>
                      {/* <th className="text-left">{t('REPORTS.DETAIL_ORDER.AVERAGE')}</th> */}
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {map(data, (item, index) => {
                      if (index >= fromItem - 1 && index < toItem) {
                        return (
                          <tr key={item.date} className="ladiui table-vertical">
                            <td>{item.date}</td>
                            <td>{item.total_new}</td>
                            <td>{item.total_draft}</td>
                            <td>{item.total_canceled}</td>
                            <td>{item.total_success}</td>
                            <td>
                              {baseHelper.formatMoneyPostFix(
                                item.total_revenue,
                                this.props.storeReducer.userInfo.currentStore.currency_symbol
                              )}
                            </td>
                            {/* <td >{baseHelper.formatMoneyPostFix(item.average, this.props.storeReducer.userInfo.currentStore.currency_symbol)}</td> */}
                          </tr>
                        );
                      }
                    })}
                    {totalRecord <= 0 && (
                      <tr className="text-center">
                        <td colSpan="100%">{t("NO_DATA")}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {totalRecord > 0 && (
                <LadiPagination
                  conditionsProp={conditions}
                  listName={t("PAGINATION.ITEMS")}
                  // message={""}
                  message={msgShowItem}
                  items={data}
                  total={totalRecord}
                  limitAction={this.onChangeLimit}
                  pageAction={this.onPageChange}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    orderOverTimeReport: (data) => dispatch(reportActions.orderOverTimeReport(data)),
  };
};

const mapStateToProps = (state) => ({
  reportReducer: { ...state.report },
  storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderOverTime));
