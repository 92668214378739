import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Input from "../../../../components/Input";
import LoadingSence from "../../../../components/LoadingScene";
import { connect } from "react-redux";
import shippingActions from "../../../../redux/futures/shipping/actions";
import * as shippingTypes from "../../../../redux/futures/shipping/types";

import appConfig from "../../../../config/app";
import { includes, isEqual, map } from "lodash";
import baseHelper from "../../../../helpers/BaseHelper";
import Dropdown from "../../../../components/Dropdown";

class GHNForm extends React.Component {
  static propTypes = {
    orderID: PropTypes.number,
    weight: PropTypes.any,
    fromDistrictID: PropTypes.any,
    total: PropTypes.number,
    shopID: PropTypes.any,
    shifts: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedService: {
        code: "",
        name: "",
      },
      isBuyerShippingFree: true,
      insurance: props.total || 0,
      hasInsurance: false,
      coupon: "",
      width: 0,
      height: 0,
      length: 0,
      stations: [],
      pick_shift: 1,
      pick_station_id: null,
      pick_type_id: "",
      required_note: "CHOTHUHANG",
    };

    this.inputsRef = new Set();
  }

  componentDidMount() {
    let payload = {
      shipping_partner_code: "GHN",
      ghn_data: {
        from_district_id: this.props.fromDistrictID,
      },
    };

    this.props.listStations(payload);

    if (this.props.fromDistrictID) {
      this.listServices(this.props.fromDistrictID);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.shippingReducer.action != nextProps.shippingReducer.action) {
      if (includes([shippingTypes.SHIPPING_LIST_SERVICES, shippingTypes.SHIPPING_RESET_SERVICES], nextProps.shippingReducer.action)) {
        if (nextProps.shippingReducer.status) {
          const selectedService = nextProps.shippingReducer.services.length > 0 ? nextProps.shippingReducer.services[0] : {};

          this.onChangeService(selectedService, true);
        } else {
          this.onChangeService({});
          window.LadiUI.showErrorMessage("Thông báo", nextProps.shippingReducer.message, "OK");
        }
      }

      if (nextProps.shippingReducer.action == shippingTypes.SHIPPING_LIST_STATIONS) {
        if (nextProps.shippingReducer.status) {
          this.setState({
            stations: nextProps.shippingReducer.stations,
          });
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.fromDistrictID != this.props.fromDistrictID) {
      this.listServices(this.props.fromDistrictID);
    }
  }

  listServices = (fromDistrictID) => {
    this.props.resetServices();
    if (!this.validate()) {
      return;
    }

    const data = {
      order_id: this.props.orderID,
      shipping_partner_code: appConfig.SHIPPING_PARTNERS.GHN.CODE,
      from_district_id: fromDistrictID,
      weight: this.props.weight,
      height: this.state.height,
      length: this.state.length,
      width: this.state.width,
      shop_id: this.props.shopID,
    };

    this.props.listServices(data);
  };

  onChangeService = (selectedService, forceGetFee = false) => {
    this.setState(
      {
        selectedService,
      },
      () => {
        if (!baseHelper.isEmpty(selectedService)) {
          this.getFee(forceGetFee);
        }
      }
    );
  };

  getFee = (forceGetFee = false) => {
    if (baseHelper.isEmpty(this.state.selectedService)) {
      window.LadiUI.toastCustom("danger", "", this.props.t("SHIPPINGS.SETUP_AT_LEAST_ONE_SERVICE"));
      return;
    }

    const data = {
      order_id: this.props.orderID,
      shipping_partner_code: appConfig.SHIPPING_PARTNERS.GHN.CODE,
      ghn_data: {
        from_district_id: this.props.fromDistrictID,
        service_id: this.state.selectedService.code,
        coupon: this.state.coupon,
        insurance: this.state.hasInsurance ? baseHelper.parseInt(this.state.insurance) : 0,
        shop_id: this.props.shopID,
      },
      weight: this.props.weight,
      height: this.state.height,
      length: this.state.length,
      width: this.state.width,
    };

    if (forceGetFee) {
      this.oldData = data;
      this.props.getFee(data);
    } else if (!isEqual(this.oldData, data)) {
      this.oldData = data;
      this.props.getFee(data);
    }
  };

  /**
   *
   * @param {*} forListService
   * true: only validate for listService, else validate all
   */
  validate = (forListService = true) => {
    const { t, weight, fromDistrictID } = this.props;
    if (!fromDistrictID) {
      window.LadiUI.toastCustom("danger", "", t("SHIPPINGS.SETUP_AT_LEAST_ONE_HUB"));
      return false;
    }

    if (baseHelper.parseFloat(weight) <= 0) {
      document.getElementById("weight").classList.add("error");
      window.LadiUI.toastCustom("danger", "", t("VALIDATION.GREATER_THAN", t("COMMON.WEIGHT"), "0"));
      return false;
    }

    if (!forListService) {
      const { selectedService } = this.state;
      if (!selectedService.code) {
        window.LadiUI.toastCustom("danger", "", t("SHIPPINGS.GHN_REQUIRED_SERVICE"));
        return false;
      }
    }

    return true;
  };

  onChangeInput = (event) => {
    let { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  // onChangeCheckParsel = () => {
  //   this.setState({
  //     can_check_parcel: !this.state.can_check_parcel,
  //   });
  // };

  onChangeIsBuyerShippingFree = () => {
    this.setState({
      isBuyerShippingFree: !this.state.isBuyerShippingFree,
    });
  };

  getData = () => {
    const { selectedService, width, height, length, coupon, isBuyerShippingFree, required_note, pick_shift, pick_station_id } = this.state;

    return {
      service_id: selectedService.code,
      width,
      height,
      length,
      coupon,
      required_note,
      pick_shift,
      pick_station_id,
      payment_type_id: isBuyerShippingFree ? 2 : 1,
      insurance: this.state.hasInsurance ? baseHelper.parseInt(this.state.insurance) : 0,
    };
  };

  getStation = () => {
    let { stations } = this.state;
    if (!stations || stations.length <= 0) {
      // window.LadiUI.toastCustom("danger", "", "Địa chỉ bưu cục không tồn tại !");
      window.LadiUI.showErrorMessage(
        "Thông báo",
        "Không lấy được danh sách địa chỉ bưu cục, vui lòng liên hệ Giao Hàng Nhanh để được tư vấn và hỗ trợ. Xin cảm ơn!",
        "OK"
      );
      this.setState({
        pick_station_id: null,
      });
    } else {
      this.setState({
        pick_station_id: stations[0].locationCode,
      });
    }
  };

  render() {
    const { t, fromDistrictID } = this.props;
    const { services } = this.props.shippingReducer;
    const { selectedService, width, height, length, coupon, isBuyerShippingFree, pick_station_id, pick_type } = this.state;
    const loadingData =
      this.props.shippingReducer.loading &&
      includes([shippingTypes.SHIPPING_LIST_SERVICES, shippingTypes.SHIPPING_GET_FEE], this.props.shippingReducer.waiting);
    return (
      <div className="ghn-form">
        {loadingData && <LoadingSence blur={true} />}
        <div className="ladiui form-group">
          <label className="ladiui-label">{t("SHIPPINGS.GHN_SERVICE")}</label>
          <div className="ladiui data-hub">
            {/* <div className="ladiui-select-options">
                            <span className="ladiui-select-init">{selectedService.name}</span>
                            <input type="hidden" name="select" defaultValue />
                            <ul>
                                {
                                    map(services, item =>
                                        <li key={item.code} className="select-item" onClick={() => this.onChangeService(item)}><a data-value={item.code}>{item.name}</a></li>
                                    )
                                }
                            </ul>
                        </div> */}

            <div className="ladiui btn-group w100">
              <div className="ladiui dropdown w100">
                <button
                  style={{ minWidth: "100%" }}
                  className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                  id="dropdownMenuButton3"
                  data-toggle="dropdown"
                >
                  <i></i>
                  <span className="ladiui dropdown-text">{selectedService.name}</span>
                </button>
                <ul className="ladiui dropdown-menu w100">
                  {map(services, (item) => (
                    <li key={item.code} onClick={() => this.onChangeService(item)}>
                      <a className="ladiui dropdown-item">{item.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="delivery-method mb-24">
          <label className="ladiui-label">{t("GHN_FORM.DELIVERY_METHOD")}</label>
          <div className="flex">
            <div className="item flex">
              <input
                type="radio"
                className="ladiui form-check-input mr-8"
                id="exampleCheck"
                checked={pick_station_id != null}
                onChange={this.getStation}
                name="pick_station_id"
              />
              <label className="ladiui label">{t("GHN_FORM.RECEIVED_OFFICE")}</label>
            </div>
            <div className="item flex ml-24">
              <input
                type="radio"
                className="ladiui form-check-input mr-8"
                checked={pick_station_id == null}
                onChange={() => {
                  this.setState({
                    pick_station_id: null,
                  });
                }}
                id="exampleCheck"
                name="pick_station_id"
              />
              <label className="ladiui label">{t("GHN_FORM.RECEIVED_SHOP")}</label>
            </div>
          </div>
        </div>

        {this.state.pick_station_id !== null && (
          <div className="block-pick-stations mb-24">
            <label className="ladiui-label">Chọn bưu cục</label>
            <Dropdown
              data={this.state.stations}
              onSelectItem={(item) => {
                this.setState({
                  pick_station_id: item.locationCode,
                });
              }}
              currentKey={this.state.pick_station_id}
              _key={"locationCode"}
              _value={"locationName"}
              validationName={"Chọn bưu cục"}
              placeHolder={"Chọn bưu cục"}
              ref={(ref) => this.inputsRef.add(ref)}
              validations={{ isRequired: true }}
            />
          </div>
        )}

        <div className="block-delivery-shift mb-24">
          <label className="ladiui-label">{t("GHN_FORM.DELIVERY_SHIFT")}</label>
          <Dropdown
            data={this.props.shifts}
            onSelectItem={(item) => {
              this.setState({
                pick_shift: item.id,
              });
            }}
            currentKey={this.state.pick_shift}
            _key={"id"}
            _value={"title"}
            validationName={t("GHN_FORM.CHOOSE_PICK_SHIFT")}
            placeHolder={t("GHN_FORM.CHOOSE_PICK_SHIFT")}
            ref={(ref) => this.inputsRef.add(ref)}
            validations={{ isRequired: true }}
          />
        </div>

        <div className="block-required-note">
          <label className="ladiui-label">{t("GHN_FORM.REQUIRED_NOTE")}</label>
          <div className="item flex">
            <input
              type="radio"
              className="ladiui form-check-input mr-8"
              onChange={() => {
                this.setState({
                  required_note: "CHOTHUHANG",
                });
              }}
              id="exampleCheck"
              checked={this.state.required_note == "CHOTHUHANG"}
              name="required_note"
            />
            <label className="ladiui label">{t("GHN_FORM.TRY_GOODS")}</label>
          </div>
          <div className="item flex">
            <input
              type="radio"
              className="ladiui form-check-input mr-8"
              id="exampleCheck"
              name="required_note"
              onChange={() => {
                this.setState({
                  required_note: "CHOXEMHANGKHONGTHU",
                });
              }}
              checked={this.state.required_note == "CHOXEMHANGKHONGTHU"}
            />
            <label className="ladiui label">{t("GHN_FORM.VIEW_NOT_TRY")}</label>
          </div>
          <div className="item flex">
            <input
              type="radio"
              className="ladiui form-check-input mr-8"
              id="exampleCheck"
              name="required_note"
              onChange={() => {
                this.setState({
                  required_note: "KHONGCHOXEMHANG",
                });
              }}
              checked={this.state.required_note == "KHONGCHOXEMHANG"}
            />
            <label className="ladiui label">{t("GHN_FORM.NOT_VIEW")}</label>
          </div>
        </div>

        <div className="list-options">
          <div className="ladiui form-group mb-0">
            <label className="ladiui-label">Tùy chọn</label>
            {/* <div className="item flex">
              <input
                id="canCheckParsel"
                checked={can_check_parcel}
                onChange={this.onChangeCheckParsel}
                type="checkbox"
                className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
              />
              <label className="ladiui label"> {t("SHIPPINGS.ALLOW_VIEW_PACKAGE")}</label>
            </div> */}

            <div className="item flex">
              <input
                id="isBuyerShippingFree"
                checked={isBuyerShippingFree}
                onChange={this.onChangeIsBuyerShippingFree}
                type="checkbox"
                className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
              />
              <label className="ladiui label"> {t("SHIPPINGS.IS_BUYER_SHIPPING_FREE")}</label>
            </div>

            <div className="item flex">
              <input
                checked={this.state.hasInsurance}
                onChange={() => {
                  this.setState(
                    {
                      hasInsurance: !this.state.hasInsurance,
                    },
                    () => {
                      let insurance = this.state.hasInsurance ? this.props.total : 0;
                      this.setState(
                        {
                          insurance,
                        },
                        () => this.getFee()
                      );
                    }
                  );
                }}
                type="checkbox"
                className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
              />
              <label className="ladiui label"> {t("SHIPPINGS.HAS_INSURANCE")}</label>
            </div>

            {this.state.hasInsurance && (
              <div className="item">
                <label className="ladiui-label">{t("SHIPPINGS.INSURANCE")}</label>
                <Input
                  name="insurance"
                  value={this.state.insurance}
                  onChange={(event) => this.setState({ insurance: event.target.value })}
                  type="number"
                  currency={true}
                  prefix={t("CURRENCIES.VND_CODE")}
                  formatter={(value) => `đ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => value.replace(/[đ, ]/g, "")}
                  disabled={!this.state.hasInsurance}
                  onBlur={() => this.getFee()}
                />
              </div>
            )}
          </div>
        </div>

        <div className="ladiui-row">
          <div className="ladiui form-group coupon custom-form-group" style={{ flex: 2 }}>
            <label className="ladiui-label">{t("SHIPPINGS.GHN_COUPON")}</label>
            <Input
              ref={(ref) => this.inputsRef.add(ref)}
              name="coupon"
              value={coupon}
              onChange={this.onChangeInput}
              onBlur={() => this.getFee()}
            />
          </div>

          <div className="ladiui form-group size">
            <label className="ladiui-label">{t("SHIPPINGS.WIDTH_SHORTCUT")}</label>
            <Input
              ref={(ref) => this.inputsRef.add(ref)}
              type="number"
              name="width"
              value={width}
              onChange={this.onChangeInput}
              onBlur={() => this.listServices(fromDistrictID)}
            />
          </div>

          <div className="ladiui form-group size">
            <label className="ladiui-label">{t("SHIPPINGS.LENGTH_SHORTCUT")}</label>
            <Input
              ref={(ref) => this.inputsRef.add(ref)}
              name="length"
              type="number"
              value={length}
              onChange={this.onChangeInput}
              onBlur={() => this.listServices(fromDistrictID)}
            />
          </div>

          <div className="ladiui form-group size">
            <label className="ladiui-label">{t("SHIPPINGS.HEIGHT_SHORTCUT")}</label>
            <Input
              ref={(ref) => this.inputsRef.add(ref)}
              name="height"
              type="number"
              value={height}
              onChange={this.onChangeInput}
              onBlur={() => this.listServices(fromDistrictID)}
            />
          </div>
        </div>

        <div className="ladiui form-group mt-0">
          <label className="ladiui-label">{t("SHIPPINGS.ESTIMATE_FEE")}</label>
          <Input
            ref={(ref) => this.inputsRef.add(ref)}
            name="fee"
            value={baseHelper.formatMoneyPostFix(
              this.props.shippingReducer.fee,
              this.props.storeReducer.userInfo.currentStore.currency_symbol
            )}
            disabled={true}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listServices: (data) => dispatch(shippingActions.listServices(data)),
    resetServices: () => dispatch(shippingActions.resetServices()),
    getFee: (data) => dispatch(shippingActions.getFee(data)),
    listStations: (data) => dispatch(shippingActions.listStations(data)),
  };
};

const mapStateToProps = (state) => ({
  shippingReducer: { ...state.shipping },
  storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation("translation", { withRef: true })(GHNForm));
