/** @format */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import moment from "moment";
import appConfig from "../../config/app";
import baseHelper from "../../helpers/BaseHelper";

import * as pageCheckoutTypes from "../../redux/futures/page_checkout/types";
import pageCheckoutActions from "../../redux/futures/page_checkout/actions";
import LoadingTable from "../../components/LoadingTable";
import ConfirmModal from "../../components/ConfirmModal";
import ModalPageCheckout from "./components/ModalPageCheckout";
import LadiPagination from "../../components/LadiPagination";
import LadiDropdownMenu from "../../components/LadiDropdownMenu";

import { includes, map, remove } from "lodash";

import OrderDateRangePicker from "../../components/OrderDateRangePicker";
import ModalInfoApiKey from "../../components/ModalInfoApiKey";

export default function PageCheckout(props) {
  const location = useLocation();
  const history = useHistory();
  const queryStrings = new URLSearchParams(location.search);

  const [activePage, setActivePage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [selectedLimit, setSelectedLimit] = useState(appConfig.PAGINATION.LIMIT_OPTIONS[0].value);
  // const [isShowModal, setIsShowModal] = useState(false);
  const [mode, setMode] = useState(appConfig.FORM_MODE.CREATE);
  const [currentPageCheckout, setCurrentPageCheckout] = useState({});
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [isReloadIframe, setReloadIframe] = useState(false);

  const [selectedApiKey, setSelectedApiKey] = useState("");

  const [isShowModalInfoApiKey, setIsShowModalInfoApiKey] = useState(false);

  const [sortBy, setSortBy] = useState({
    page_checkout_id: "DESC",
  });

  const [conditions, setConditions] = useState({
    page: 1,
    limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
  });

  const pageCheckoutReducer = useSelector((state) => state.pageCheckoutReducer);

  let selectedID = "";
  // let selectedIDs = [];

  // let sortBy = {
  //   page_checkout_id: "DESC",
  // };
  const formRef = React.createRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const Moment = baseHelper.getMoment();

  // useEffect(() => {
  //   if ((fromDate && toDate) || (!fromDate && !toDate)) list();
  // }, [fromDate, toDate]);

  useEffect(() => {
    list(false);
    if (queryStrings && queryStrings.has("isShowApiKey")) {
      setSelectedApiKey(queryStrings.get("api_key"));
      setIsShowModalInfoApiKey(true);
    }
  }, []);

  useEffect(() => {
    list(false);
  }, [selectedLimit, activePage]);

  useEffect(() => {
    // if (pageCheckoutReducer.action == pageCheckoutTypes.CREATE_PAGE_CHECKOUT) {
    //   setReloadIframe(!isReloadIframe);
    //   if (pageCheckoutReducer.status) {
    //     setIsShowModal(false);
    //     list();
    //     window.LadiUI.toastCustom("success", "", pageCheckoutReducer.message);
    //   } else {
    //     window.LadiUI.showErrorMessage("Thông báo", pageCheckoutReducer.message, "OK");
    //   }
    // }

    // if (pageCheckoutReducer.action == pageCheckoutTypes.UPDATE_PAGE_CHECKOUT) {
    //   setReloadIframe(!isReloadIframe);
    //   if (pageCheckoutReducer.status) {
    //     list();
    //     dispatch(pageCheckoutActions.reload(currentPageCheckout.page_checkout_id));
    //     window.LadiUI.toastCustom("success", "", pageCheckoutReducer.message);
    //   } else {
    //     window.LadiUI.showErrorMessage("Thông báo", pageCheckoutReducer.message, "OK");
    //   }
    // }

    // if (pageCheckoutReducer.action == pageCheckoutTypes.SHOW_PAGE_CHECKOUT) {
    //   if (pageCheckoutReducer.status) {
    //     setIsShowModal(true);
    //     if (pageCheckoutReducer.page_checkout.interface_options && typeof pageCheckoutReducer.page_checkout.interface_options == "string")
    //       pageCheckoutReducer.page_checkout.interface_options = JSON.parse(pageCheckoutReducer.page_checkout.interface_options);
    //     else pageCheckoutReducer.page_checkout.interface_options = [];
    //     setCurrentPageCheckout(pageCheckoutReducer.page_checkout);
    //     setMode(appConfig.FORM_MODE.EDIT);
    //   } else {
    //     window.LadiUI.showErrorMessage("Thông báo", pageCheckoutReducer.message, "OK");
    //   }
    // }

    if (pageCheckoutReducer.action == pageCheckoutTypes.RELOAD_PAGE_CHECKOUT) {
      if (pageCheckoutReducer.status) {
        if (pageCheckoutReducer.page_checkout.interface_options && typeof pageCheckoutReducer.page_checkout.interface_options == "string")
          pageCheckoutReducer.page_checkout.interface_options = JSON.parse(pageCheckoutReducer.page_checkout.interface_options);
        else pageCheckoutReducer.page_checkout.interface_options = [];

        setCurrentPageCheckout(pageCheckoutReducer.page_checkout);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", pageCheckoutReducer.message, "OK");
      }
    }

    if ([pageCheckoutTypes.DELETE_PAGE_CHECKOUT, pageCheckoutTypes.DELETES_PAGE_CHECKOUT].includes(pageCheckoutReducer.action)) {
      if (pageCheckoutReducer.status) {
        list();
        window.LadiUI.toastCustom("success", "", pageCheckoutReducer.message);
        window.LadiUI.closeModal("confirm-page-checkout-delete");
        window.LadiUI.closeModal("confirm-page-checkout-deletes");
      } else {
        window.LadiUI.showErrorMessage("Thông báo", pageCheckoutReducer.message, "OK");
      }
    }
  }, [pageCheckoutReducer]);

  /**
   * Tim kiem theo name
   */
  const onKeyDownInput = (event) => {
    if (event.key === "Enter") {
      list();
    }
  };

  const list = (resetActivePageFlag = true) => {
    if (document.getElementById("checkAllItem")) {
      document.getElementById("checkAllItem").checked = false;
      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = false;
      });
    }

    if (resetActivePageFlag) {
      setActivePage(1);
    }

    // Reset selectedIDs
    setSelectedIDs([]);

    const data = {
      keyword: keyword,
      search: {
        from_date: fromDate ? moment(new Date(fromDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
        to_date: toDate ? moment(new Date(toDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
      },
      paged: activePage,
      limit: selectedLimit,
      sort: sortBy,
    };

    dispatch(pageCheckoutActions.list(data));
  };

  /*******************************PAGINATION***************************/
  /**
   * Change limit
   */
  const onChangeLimit = (option) => {
    setSelectedLimit(option.value);
    setActivePage(1);
  };

  /**
   * Change page
   */
  const onPageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  /****************************************************************/

  /*******************************CHECK AND ACTIONS***************************/
  const checkAllItem = (event) => {
    const { checked } = event.target;

    let ids = [];
    if (checked) {
      ids = map(pageCheckoutReducer.page_checkouts, (item) => item.page_checkout_id);

      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = true;
      });
    } else {
      ids = [];
      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = false;
      });
    }

    setSelectedIDs(ids);
  };

  const checkItem = (event, pageCheckoutID) => {
    const { checked } = event.target;

    let ids = [...selectedIDs];

    if (checked) {
      if (!includes(ids, pageCheckoutID)) {
        ids.push(pageCheckoutID);
      }

      if (ids.length == pageCheckoutReducer.page_checkouts.length) {
        document.getElementById("checkAllItem").checked = true;
      }
    } else {
      document.getElementById("checkAllItem").checked = false;
      remove(ids, (selectedID) => selectedID == pageCheckoutID);
    }

    setSelectedIDs(ids);
  };

  const sort = (event) => {
    const { classList } = event.target;

    const name = event.target.getAttribute("name");

    window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui table .sorting"), (item) => {
      if (item.getAttribute("name") != name) {
        item.classList.remove("up");
      }
    });

    // Change arrow up and down
    if (includes(classList, "up")) {
      classList.remove("up");
      setSortBy({
        [name]: "ASC",
      });
    } else {
      classList.add("up");
      setSortBy({
        [name]: "DESC",
      });
    }
    list();
  };

  const openModalCreate = () => {
    // setIsShowModal(true);
    // setCurrentPageCheckout({});
    // setMode(appConfig.FORM_MODE.CREATE);
    props.history.push(`/page-checkout/create`);
  };

  const openModalCreateSDK = () => {
    props.history.push(`/page-checkout/sdk-create`);
  };

  const openModalEdit = (pageCheckout) => {
    if (pageCheckout && pageCheckout.types) {
      switch (pageCheckout.types) {
        case appConfig.PAGE_CHECKOUT.PRODUCTS.CODE:
        case appConfig.PAGE_CHECKOUT.TAGS.CODE:
          return props.history.push(`/page-checkout/update/${pageCheckout.page_checkout_id}`);
        case appConfig.PAGE_CHECKOUT.EMBED.CODE:
          return props.history.push(`/page-checkout/sdk-update/${pageCheckout.page_checkout_id}`);
        default:
          return props.history.push(`/page-checkout/update/${pageCheckout.page_checkout_id}`);
      }
    }
  };

  // const hideModal = () => {
  //   setIsShowModal(false);
  // };

  // const submit = (pageCheckout, interfaceOptions) => {
  //   let dataSend = {
  //     page_checkout: { ...pageCheckout, interface_options: JSON.stringify(interfaceOptions) },
  //   };

  //   if (mode == appConfig.FORM_MODE.CREATE) {
  //     dispatch(pageCheckoutActions.create(dataSend));
  //   } else {
  //     dispatch(pageCheckoutActions.update(dataSend));
  //   }
  // };

  const openDeleteModal = (_selectedID) => {
    selectedID = _selectedID;
    window.LadiUI.showModal("confirm-page-checkout-delete");
  };

  const openDeletesModal = () => {
    if (selectedIDs.length <= 0) {
      window.LadiUI.toastCustom("danger", "", t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
      return;
    }

    window.LadiUI.showModal("confirm-page-checkout-deletes");
  };

  const eventSearchKeyboard = (e) => {
    if (e.keyCode === 13) {
      this.setState({
        conditions: {
          ...this.state.conditions,
          ...{ keyword: e.target.value, page: 1 },
        },
      });
    }
  };

  const eventSelectActions = (action) => {
    switch (action) {
      case "DELETE":
        openDeletesModal();
        break;
      default:
    }
  };

  const onCancelModalInfoApi = () => {
    setIsShowModalInfoApiKey(false);
  };

  const isLoadingData = pageCheckoutReducer.loading && pageCheckoutReducer.waiting == pageCheckoutTypes.LIST_PAGE_CHECKOUT;
  const isLoadingInfo = pageCheckoutReducer.loading && pageCheckoutReducer.waiting == pageCheckoutTypes.SHOW_PAGE_CHECKOUT;
  const isSubmitLoading =
    pageCheckoutReducer.loading &&
    includes([pageCheckoutTypes.CREATE_PAGE_CHECKOUT, pageCheckoutTypes.UPDATE_PAGE_CHECKOUT], pageCheckoutReducer.waiting);
  const isLoadingDelete =
    pageCheckoutReducer.loading &&
    includes([pageCheckoutTypes.DELETES_PAGE_CHECKOUT, pageCheckoutTypes.DELETE_PAGE_CHECKOUT], pageCheckoutReducer.waiting);
  const { total_record: totalRecord, total_page: totalPage } = pageCheckoutReducer;
  let fromItem = 0;
  let toItem = 0;
  if ((pageCheckoutReducer.page_checkouts || []).length > 0) {
    fromItem = (activePage - 1) * selectedLimit + 1;
    toItem = fromItem + pageCheckoutReducer.page_checkouts.length - 1;
  }

  const msgShowItem = `${t("PAGINATION.SHOW")} ${fromItem} ${t("PAGINATION.TO")} ${toItem} ${t("PAGINATION.OF")} ${baseHelper.formatNumber(
    totalRecord
  )} ${t("PAGINATION.ITEMS")}`;

  const listAction = [{ value: "DELETE", name: t("ACTIONS.DELETE") }];

  return (
    <div id="page-checkout" className="customer-page">
      <div>
        <div className="ladiui-table-actions">
          <div className="ladiui content-main-tool block-filter-condition flex-row align-item-baseline">
            <div className="ladiui search-group mr-24">
              <input
                id="search"
                className="ladiui search-field dropdown-toggle form-control search-width"
                onChange={(event) => setKeyword(event.target.value)}
                placeholder={t("PAGE_CHECKOUT.SEARCH")}
                aria-expanded="false"
                value={keyword}
                onKeyDown={onKeyDownInput}
              />
              <i className="ladiui icon icon-search" onClick={list}></i>
            </div>
            {/* <div className="date-filter">
              <OrderDateRangePicker
                startDateId="fromDate"
                endDateId="toDate"
                startDate={fromDate}
                endDate={toDate}
                onDatesChange={({ startDate, endDate }) => {
                  setFromDate(startDate);
                  setToDate(endDate);
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => {
                  setFocusedInput(focusedInput);
                }}
                showDefaultInputIcon={true}
                inputIconPosition="after"
                small={true}
                hideKeyboardShortcutsPanel={true}
                customInputIcon={<i className="ladi-icon icon-calendar-date-2" />}
                startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                isOutsideRange={() => false}
                isDayHighlighted={(date) => {
                  return Moment().isSame(date, "day");
                }}
                minimumNights={0}
                showClearDates={true}
              />
            </div> */}
          </div>
          <div className="action-btn">
            <div className="btn-action ladiui dropdown hide-mt">
              <button className="ladiui dropdown-toggle btn btn-primary btn-sm" data-toggle="dropdown">
                <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />

                {t("PAGE_CHECKOUT.ADD")}
              </button>
              <ul className="ladiui dropdown-menu r-0 block-new-page-checkout" style={{ width: 400 }}>
                <li>
                  <a className="ladiui dropdown-item flex" onClick={openModalCreate}>
                    <div>
                      <i className="ladi-icon icon-page-checkout-ladisales"></i>
                    </div>
                    <div className="title-description">
                      <h3>Tạo trang thanh toán trên LadiSales</h3>
                      <span>Tạo trang thanh toán của bạn trên nền tảng LadiSales</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a className="ladiui dropdown-item flex" onClick={openModalCreateSDK}>
                    <div>
                      <i className="ladi-icon icon-other-page-checkout"></i>
                    </div>
                    <div className="title-description">
                      <h3>Tạo trang thanh toán cho nền tảng khác</h3>
                      {/* <span>Thiết lập trang thanh toán của bạn trên các nền tảng như Wordpress, Shopify, Haravan...</span> */}
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {isLoadingData ? (
          <LoadingTable />
        ) : (
          <div>
            <div className="ladi-card ladiui-table-responsive">
              <table className={`ladiui table text-left ${isLoadingInfo ? "loader" : ""}`}>
                <thead style={{ position: "relative" }}>
                  <tr className="ladiui table-vertical header">
                    <th scope="col" className="ladiui checkall">
                      <input
                        id="checkAllItem"
                        onClick={checkAllItem}
                        type="checkbox"
                        className="ladiui checkbox size-checkbox form-check-input-checkbox ladiui-checkall vertical-middle"
                      />
                    </th>

                    {selectedIDs && selectedIDs.length <= 0 ? (
                      <th scope="col" name="name" onClick={sort} className={`text-left sorting ${sortBy["name"] == "DESC" ? "up" : ""}`}>
                        {t("PAGE_CHECKOUT.NAME")}
                      </th>
                    ) : (
                      <th>
                        {t("PAGE_CHECKOUT.NAME")}
                        <LadiDropdownMenu
                          labelDropdown={"Chọn hành động"}
                          listProp={listAction}
                          defaultTextProp={t("ACTIONS.TITLE")}
                          hideLabel={true}
                          cbProp={eventSelectActions}
                          classSize="btn-sm "
                          classWidth="w-180"
                          classDropdown="w-180 action-record-table"
                        />
                      </th>
                    )}
                    <th scope="col" name="total_product" className="text-left">
                      {t("PAGE_CHECKOUT.TOTAL_PRODUCT")}
                    </th>
                    <th scope="col">Loại trang</th>
                    <th
                      scope="col"
                      name="created_at"
                      onClick={sort}
                      className={`text-left sorting ${sortBy["created_at"] == "DESC" ? "up" : ""}`}
                    >
                      {t("COMMON.CREATED_AT")}
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {map(pageCheckoutReducer.page_checkouts, (item) => {
                    return (
                      <tr key={item.page_checkout_id} className="ladiui table-vertical main">
                        <td scope="row">
                          <input
                            type="checkbox"
                            onClick={(event) => checkItem(event, item.page_checkout_id)}
                            className="ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle"
                          />
                        </td>
                        <td>
                          <a onClick={() => openModalEdit(item)}>
                            {item.name} {item.is_default ? "( Mặc định)" : ""}
                          </a>
                        </td>
                        <td>{item.total_product}</td>
                        <td className="page-checkout-type">{item.types}</td>
                        <td>{baseHelper.formatStrToDate(item.created_at)}</td>
                        <td className="text-right pd-0">
                          <div className="ladiui btn-group">
                            <div className="ladiui dropdown hide-mt ba-c">
                              <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                                <i className="ladiui icon icon-ldp-dot"></i>
                              </button>
                              <ul className="ladiui dropdown-menu r-0">
                                <li>
                                  <a className="ladiui dropdown-item" onClick={() => openModalEdit(item)}>
                                    {t("ACTIONS.EDIT")}
                                  </a>
                                </li>
                                <li>
                                  <a className="ladiui dropdown-item" onClick={() => openDeleteModal(item.page_checkout_id)}>
                                    {t("ACTIONS.DELETE")}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  {totalRecord <= 0 && (
                    <tr className="text-center">
                      <td colSpan="100%">{t("NO_DATA")}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {totalRecord > 0 && (
              <LadiPagination
                conditionsProp={{ page: activePage, limit: selectedLimit }}
                listName={t("PAGINATION.ITEMS")}
                message={""}
                items={pageCheckoutReducer.page_checkouts}
                total={totalRecord}
                limitAction={onChangeLimit}
                pageAction={onPageChange}
              />
            )}
          </div>
        )}
      </div>

      {/* {isShowModal && (
        <ModalPageCheckout
          isReloadIframe={isReloadIframe}
          currentPageCheckout={currentPageCheckout}
          mode={mode}
          visible={isShowModal}
          onCancel={hideModal}
          onSubmit={submit}
          loading={isSubmitLoading}
        />
      )} */}

      {isShowModalInfoApiKey && (
        <ModalInfoApiKey
          onCancel={onCancelModalInfoApi}
          // onSubmit={onSubmitModalAddText}
          visible={isShowModalInfoApiKey}
          infoApi={selectedApiKey}
        />
      )}

      <ConfirmModal
        id="confirm-page-checkout-delete"
        title={t("PAGE_CHECKOUT.MSG_DELETE_PAGE_CHECKOUT_TITLE")}
        content={t("PAGE_CHECKOUT.MSG_DELETE_PAGE_CHECKOUT_CONTENT")}
        cancelText={t("ACTIONS.CANCEL")}
        okText={t("ACTIONS.DELETE")}
        onOk={() => dispatch(pageCheckoutActions.delete(selectedID))}
        isLoading={isLoadingDelete}
      />

      <ConfirmModal
        id="confirm-page-checkout-deletes"
        title={t("PAGE_CHECKOUT.MSG_DELETE_PAGE_CHECKOUT_TITLE")}
        content={t("PAGE_CHECKOUT.MSG_DELETE_PAGE_CHECKOUTS_CONTENT")}
        cancelText={t("ACTIONS.CANCEL")}
        okText={t("ACTIONS.DELETE")}
        onOk={() => dispatch(pageCheckoutActions.deletes(selectedIDs))}
        isLoading={isLoadingDelete}
      />
    </div>
  );
}
