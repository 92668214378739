import * as types from './types';
import * as commonTypes from '../common/types';
import { map } from 'lodash';

export default (state = { paths: [] }, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            };
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }
        case types.UPLOAD_PRODUCT_IMAGES: {
            let productImages = [];

            if (action.status) {
                productImages = map(action.payload, (item) => item.path);
            }
            return {
                ...state,
                action: types.UPLOAD_PRODUCT_IMAGES,
                product_images: action.status ? productImages : [],
                status: action.status,
                message: action.message,
            };
        }
        case types.UPLOAD_PRODUCT_REVIEW_IMAGES: {
            let productImages = [];

            if (action.status) {
                productImages = map(action.payload, (item) => item.path);
            }
            return {
                ...state,
                action: types.UPLOAD_PRODUCT_REVIEW_IMAGES,
                product_images: action.status ? productImages : [],
                status: action.status,
                message: action.message,
            };
        }
        case types.UPLOAD_CHECKOUT_IMAGES: {
            let productImages = [];

            if (action.status) {
                productImages = map(action.payload, (item) => item.path);
            }
            return {
                ...state,
                action: types.UPLOAD_CHECKOUT_IMAGES,
                product_images: action.status ? productImages : [],
                status: action.status,
                message: action.message,
            };
        }
        case types.UPLOAD_IMAGES: {
            let urlImages = [];

            if (action.status) {
                urlImages = map(action.payload, (item) => item.path);
            }
            return {
                ...state,
                action: types.UPLOAD_IMAGES,
                url_images: action.status ? urlImages : [],
                status: action.status,
                message: action.message,
            };
        }
        case types.UPLOAD_VARIANT_IMAGE: {
            let images = [];
            if (action.status) {
                images = map(action.payload, (item) => item.path);
            }
            return {
                ...state,
                action: types.UPLOAD_VARIANT_IMAGE,
                ticket_images: action.status ? images : [],
                status: action.status,
                message: action.message,
            };
        }
        case types.UPLOAD_SWATCH_IMAGE: {
            return {
                ...state,
                action: types.UPLOAD_SWATCH_IMAGE,
                swatch_image: action.status ? action.payload[0].path : [],
                status: action.status,
                message: action.message,
            };
        }
        case types.UPLOAD_LOGO_URL: {
            return {
                ...state,
                action: types.UPLOAD_LOGO_URL,
                logo_url: action.status ? action.payload[0].path : '',
                status: action.status,
                message: action.message,
            };
        }
        case types.UPLOAD_FAVICON_URL: {
            return {
                ...state,
                action: types.UPLOAD_FAVICON_URL,
                favicon_url: action.status ? action.payload[0].path : '',
                status: action.status,
                message: action.message,
            };
        }
        case types.UPLOAD_COVER_URL: {
            return {
                ...state,
                action: types.UPLOAD_COVER_URL,
                cover_url: action.status ? action.payload[0].path : '',
                status: action.status,
                message: action.message,
            };
        }
        case types.UPLOAD_FILES: {
            return {
                ...state,
                file_url: action.status ? action.payload[0].path : '',
                files: action.status ? action.payload : [],
                action: types.UPLOAD_FILES,
                status: action.status,
                message: action.message,
            };
        }

        case types.UPLOAD_FILE_DIGITAL: {
            return {
                ...state,
                file_url: action.payload ? action.payload[0].url : '',
                action: types.UPLOAD_FILE_DIGITAL,
                status: action.status,
                message: action.message,
            };
        }
        case types.UPLOAD_FILE_LESSON: {
            return {
                ...state,
                file_urls: action.payload ? action.payload : [],
                action: types.UPLOAD_FILE_LESSON,
                status: action.status,
                message: action.message,
            };
        }
        case types.UPLOAD_CART_IMAGE: {
            return {
                ...state,
                action: types.UPLOAD_CART_IMAGE,
                cart_image_url: action.status ? action.payload[0].path : [],
                status: action.status,
                message: action.message,
            };
        }
        case types.UPLOAD_CART_LOGO: {
            return {
                ...state,
                action: types.UPLOAD_CART_LOGO,
                cart_logo_url: action.status ? action.payload[0].path : [],
                status: action.status,
                message: action.message,
            };
        }
        case types.UPLOAD_FOOTER_LOGO: {
            return {
                ...state,
                action: types.UPLOAD_FOOTER_LOGO,
                cart_logo_url: action.status ? action.payload[0].path : [],
                status: action.status,
                message: action.message,
            };
        }
        case types.UPLOAD_CART_BACK_GROUND: {
            return {
                ...state,
                action: types.UPLOAD_CART_BACK_GROUND,
                background_image_url: action.status ? action.payload[0].path : [],
                status: action.status,
                message: action.message,
            };
        }
        case types.UPLOAD_THUMBNAIL: {
            return {
                ...state,
                action: types.UPLOAD_THUMBNAIL,
                image_url: action.status ? action.payload[0].path : [],
                status: action.status,
                message: action.message,
            };
        }
        case types.UPLOAD_AVATAR: {
            return {
                ...state,
                action: types.UPLOAD_AVATAR,
                avatar_url: action.status ? action.payload[0].path : [],
                status: action.status,
                message: action.message,
            };
        }
        case types.UPLOAD_CATEGORY_IMAGE: {
            return {
                ...state,
                action: types.UPLOAD_CATEGORY_IMAGE,
                image_url: action.status ? action.payload[0].path : [],
                status: action.status,
                message: action.message,
            };
        }
        case types.BUNNY_CREATE_VIDEO: {
            return {
                ...state,
                action: types.BUNNY_CREATE_VIDEO,
                // videoLink: action.status ? action.payload[0].url : "",
                videoSize: action.status ? action.payload[0].size : null,
                videoName: action.status ? action.payload[0].product_video_name : '',
                productVideoId: action.status ? action.payload[0].product_video_id : null,
                status: action.status,
                message: action.message,
            };
        }
        case types.CHUNK_VIDEO: {
            return {
                ...state,
                action: types.CHUNK_VIDEO,
                // videoLink: action.status ? action.payload[0].url : "",
                videoSize: action.status ? action.payload[0].size : null,
                videoName: action.status ? action.payload[0].product_video_name : '',
                productVideoId: action.status ? action.payload[0].product_video_id : null,
                status: action.status,
                message: action.message,
            };
        }
        case types.UPLOAD_BANNER_STORE: {
            return {
                ...state,
                action: types.UPLOAD_BANNER_STORE,
                banner_url: action.status ? action.payload[0].path : [],
                status: action.status,
                message: action.message,
            };
        }
        default:
            return state;
    }
};
