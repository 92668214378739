/** Import default package */
import React from "react";
import PropTypes from "prop-types";
import appConfig from "../../../../../config/app";

/** Import from third party */
import { cloneDeep, compact, map, indexOf } from "lodash";

/** Import component from my app */
import Input from "../../../../../components/Input";

/** Import redux */
import { withTranslation } from "react-i18next";
import ConfirmModal from "../../../../../components/ConfirmModal";
import Modal from "../../../../../components/Modal";
import ModalSwatch from "../../../../../components/ModalSwatch";
import produce from "immer";

import { connect } from "react-redux";

import * as productOptionTypes from "../../../../../redux/futures/product_option/types";
import productOptionActions from "../../../../../redux/futures/product_option/actions";

import productActions from "../../../../../redux/futures/product/actions";
import * as productActionTypes from "../../../../../redux/futures/product/types";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

class ModalEditOption extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    deleteOption: PropTypes.func,
    visible: PropTypes.bool,
    loading: PropTypes.bool,
    loadingDelete: PropTypes.bool,
    product_id: PropTypes.number,
    options: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      options: cloneDeep(props.options) || [],
      deleteOption: {},
      deleteOptionValue: {},
      deleteVariantValue: {},
      selectedOptionIndex: 0,
    };
    this.ref = React.createRef();

    this.defaultOptionNames = [props.t("PRODUCTS.VARIANT_1"), props.t("PRODUCTS.VARIANT_2"), props.t("PRODUCTS.VARIANT_3")];
    this.inputsRef = new Set();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.options != nextProps.options) {
      const options = cloneDeep(nextProps.options);
      this.setState({
        options,
      });
    }

    if (this.props.productOption.action != nextProps.productOption.action) {
      if (nextProps.productOption.action === productOptionTypes.SAVE_DATA_OPTION_NAME) {
        if (nextProps.productOption.status) {
          this.props.reloadProduct(this.props.product_id);
          window.LadiUI.toastCustom("success", "", nextProps.productOption.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.productOption.message, "OK");
        }
      }
    }
  }

  onChangeOptionName = (event, index) => {
    let { options } = this.state;
    let options2 = [...options];
    let option = { ...options2[index] };
    option.name = event.target.value;

    options2[index] = option;
    this.setState({
      options: options2,
    });
  };

  addOtherOption = () => {
    let options = [...this.state.options];

    const currentNames = map(options, (option) => option.name);
    const availableNames = [];

    map(this.defaultOptionNames, (name) => {
      if (indexOf(currentNames, name) < 0) {
        availableNames.push(name);
      }
    });

    const nextOption = {
      name: availableNames[0],
      position: options.length + 1,
      // values: [{ name: '' }],
      values: [],
      valueInput: "",
      type: appConfig.PRODUCT_OPTION_TYPE.TEXT.CODE,
    };

    options.push(nextOption);

    this.setState({
      options,
    });
  };

  onChangeOptionValueInput = (event, index) => {
    const { options } = this.state;
    let options2 = [...options];
    const option = { ...options2[index] };

    option.valueInput = event.target.value;

    options2[index] = option;

    this.setState({
      options: options2,
    });
  };

  addOptionValue = (index) => {
    let { options } = this.state;

    let options2 = [...options];

    let option = { ...options2[index] };

    if (!option.valueInput) option.valueInput = "";
    const valueInput = option.valueInput.trim();
    const values = option.values;

    if (valueInput && !values.find((item) => item.name == valueInput)) {
      values.push({
        name: valueInput,
      });
    }

    option.valueInput = "";

    options2[index] = option;

    this.setState({
      options: options2,
    });
  };

  openDeleteOptionValueModal = (indexOption, indexValue) => {
    const { options } = this.state;
    const selectedOption = options[indexOption];
    const selectedOptionValue = selectedOption.values[indexValue];

    this.setState({
      deleteOption: null,
      deleteOptionValue: {
        product_option_id: selectedOption.option_id,
        option_name: selectedOption.name,
        product_option_value_id: selectedOptionValue.option_value_id,
        option_value_name: selectedOptionValue.name,
      },
    });

    window.LadiUI.showModal("confirm-delete-option");
  };

  openDeleteOptionNew = (indexOption) => {
    const { options } = this.state;

    options.splice(indexOption, 1);
    this.setState({
      options,
    });
  };

  delete = () => {
    const { deleteOption, deleteOptionValue } = this.state;
    let data;
    if (deleteOption) {
      data = {
        product_id: this.props.product_id,
        product_option_id: deleteOption.product_option_id,
      };
    } else if (deleteOptionValue) {
      data = {
        product_id: this.props.product_id,
        product_option_id: deleteOptionValue.product_option_id,
        product_option_value_id: deleteOptionValue.product_option_value_id,
      };
    }

    if (data.product_id && data.product_option_id) {
      this.props.deleteOption(data);
    } else {
    }
  };

  deleteVariant = () => {
    const { deleteOption, deleteVariantValue } = this.state;
    let data;
    if (deleteOption) {
      data = {
        product_id: this.props.product_id,
        product_option_id: deleteOption.product_option_id,
      };
    } else if (deleteVariantValue) {
      data = {
        product_id: this.props.product_id,
        product_option_id: deleteVariantValue.product_option_id,
      };
    }

    if (data.product_id && data.product_option_id) {
      this.props.deleteOption(data);
    } else {
    }
  };

  openModalAdjust = (selectedOptionIndex) => {
    this.setState({
      isShowModalAdjust: true,
      selectedOptionIndex,
    });
  };

  hideModalAdjust = () => {
    this.setState({
      isShowModalAdjust: false,
    });
  };

  applySwatch = (option) => {
    const { options, selectedOptionIndex } = this.state;
    options[selectedOptionIndex] = option;

    this.setState({
      options,
    });

    this.submit();

    this.hideModalAdjust();
  };

  submit = () => {
    // Validate
    let errors = [];
    const inputsRef = compact(Array.from(this.inputsRef));
    map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });

    errors = compact(errors);
    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      window.LadiUI.toastCustom("danger", "", errorMessage);
      return;
    }

    const options = cloneDeep(this.state.options);
    const productOptionsCreate = [];
    const productOptionsUpdate = [];

    map(options, (item) => {
      if (item.option_id) {
        item.product_option_id = item.option_id;
        productOptionsUpdate.push(item);
      } else {
        // delete item.valueInput;
        productOptionsCreate.push(item);
      }
    });

    const data = {
      product_id: this.props.product_id,
      product_options_create: productOptionsCreate,
      product_options_update: productOptionsUpdate,
    };

    this.props.onSubmit(data);
  };

  combinationVariants = (props) => {
    if (!props) {
      props = this.props;
    }
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    // let tg = list[startIndex];

    return result;
  };

  onDragEnd = async (result) => {
    const { options } = this.state;

    const productOptionsCreate = [];
    const productOptionsUpdate = [];

    // dropped outside the list
    if (!result.destination || result.source.index == result.destination.index) {
      return;
    }

    const items = this.reorder(options, result.source.index, result.destination.index);

    if (items) {
      map(items, (_item, _index) => {
        _item.position = _index + 1;
        if (_item.option_id) {
          _item.product_option_id = _item.option_id;
          productOptionsUpdate.push(_item);
        } else {
          productOptionsCreate.push(_item);
        }
      });
    }

    const data = {
      product_id: this.props.product_id,
      product_options_create: productOptionsCreate,
      product_options_update: productOptionsUpdate,
    };

    this.props.onSubmit(data);

    this.setState({
      options: items,
    });
  };

  removeOption = (index) => {
    const { options } = this.state;
    const selectedOption = options[index];

    if (!selectedOption.option_id) {
      this.setState({
        options: produce(options, (draft) => {
          draft.splice(index, 1);

          if (draft.length <= 0) {
            this.setState({
              checkedOption: false,
            });
          }
        }),
      });
    } else {
      this.setState({
        deleteOption: null,
        deleteVariantValue: {
          product_option_id: selectedOption.option_id,
          option_name: selectedOption.name,
        },
      });

      window.LadiUI.showModal("confirm-delete-variant");
    }
  };

  handleOnKeyPress = async (event, index) => {
    if (event.key == "Enter") {
      await this.addOptionValue(index);
      this.submit();
    }
  };


  handleSaveOptionName = (option) => {
    let payload = {
      option_id: option.option_id,
      name: option.name
    };

    this.props.updateOptionName(payload);
  }

  render() {
    const { t } = this.props;
    const { options, deleteOption, deleteOptionValue, isShowModalAdjust, selectedOptionIndex } = this.state;
    const isShowAddOther = options.length < 3;

    const titleConfirm = deleteOption ? t("PRODUCTS.MSG_DELETE_OPTION") : t("PRODUCTS.MSG_DELETE_OPTION_VALUE");

    let contentConfrim = "";
    if (deleteOption) {
      contentConfrim = t("PRODUCTS.MSG_DELETE_OPTION_CONTENT", deleteOption.name);
    } else {
      contentConfrim = t("PRODUCTS.MSG_DELETE_VARIANT_CONTENT", deleteOptionValue.option_name, deleteOptionValue.option_value_name);
    }

    return (
      <>
        {/* <Modal
          id="modal-edit-option"
          title={t("PRODUCTS.EDIT_OPTION")}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onOk={this.submit}
          isLoading={this.props.loading}
          width={800}
        > */}
        <div className="ladiui form-group clearfix product-variants">
          <div className="product-variant-box">
            <div className="ladiui-form-group variant-option-head">
              <span>{t("PRODUCTS.OPTION_NAME")}</span>
              <span>{t("PRODUCTS.OPTION_VALUE")}</span>
            </div>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    className="ladiui-form-group variant-option-list"
                    ref={provided.innerRef}
                    style={{
                      background: snapshot.isDragging ? "#e8f0fe" : "none",
                    }}
                  >
                    {map(options, (option, index) => {
                      const values = option.values;

                      return (
                        <Draggable key={index} draggableId={"" + index} index={index}>
                          {(provided, snapshot) => (
                            <div
                              key={index}
                              className="flex list-item"
                              id="variant-option-1"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                userSelect: "none",
                                background: snapshot.isDragging ? "#e8f0fe" : "none",
                              }}
                            >
                              <img className="mr-12" src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg"} />
                              <div className="variant-option-item">
                                <div className="variant-option-name flex">
                                  <Input
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    name="name"
                                    validationName="Option"
                                    value={option.name}
                                    onChange={(event) => this.onChangeOptionName(event, index)}
                                    validations={{ isRequired: true }}
                                    allowSpecialChar={false}
                                  />
                                  <span className="btn-save-option-name" onClick={() => this.handleSaveOptionName(option)}>Lưu</span>
                                </div>
                                <div className="variant-option-value">
                                  {option.option_id ? (
                                    <div className="ladiui-tags">
                                      <div className="ladiui-tag-list" style={{ flexWrap: "wrap" }}>
                                        {map(values, (item, _index) => (
                                          <span key={_index} className="tag" style={{ marginBottom: 8 }}>
                                            {item.name}

                                            <i
                                              className="ladiui span-img cursor-pointer  icon-close-no-bg-16"
                                              onClick={() => this.openDeleteOptionValueModal(index, _index)}
                                            />
                                          </span>
                                        ))}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="ladiui-tags">
                                      <div className="ladiui-tag-list mr-8" style={{ flexWrap: "wrap" }}>
                                        {map(values, (item, _index) => (
                                          <span key={_index} className="tag" style={{ marginBottom: 8 }}>
                                            {item.name}
                                            {/* <img
                                              className="ladiui span-img cursor-pointer"
                                              src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-close-no-bg.svg"}
                                              alt=""
                                              onClick={() => this.openDeleteOptionNew(index, _index)}
                                            /> */}

                                            <i
                                              className="ladiui span-img cursor-pointer  icon-close-no-bg-16"
                                              onClick={() => this.openDeleteOptionNew(index, _index)}
                                            />
                                          </span>
                                        ))}
                                      </div>
                                      <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        placeholder={t("PRODUCTS.ADD_VARIANT_VALUE")}
                                        validationName="Option Value"
                                        value={option.valueInput}
                                        onChange={(event) => this.onChangeOptionValueInput(event, index)}
                                        // onKeyPress={(event) => {
                                        //   if (event.key === "Enter") {
                                        //     this.addOptionValue(index);
                                        //   }
                                        // }}
                                        onKeyPress={(event) => this.handleOnKeyPress(event, index)}
                                        allowSpecialChar={false}
                                        disabled={values.length > 0 ? true : false}
                                        className={`w-unset un-hover ${values.length > 0 ? " display-none" : ""}`}
                                        noFormControl={true}
                                      />
                                    </div>
                                  )}
                                </div>
                                {values.length > 0 && (
                                  <div className="adjust">
                                    <small>
                                      <a
                                        onClick={() => this.openModalAdjust(index)}
                                        className="link"
                                        style={{ color: "var(--main-primary)" }}
                                      >
                                        {t("COMMON.ADJUST")}
                                      </a>
                                    </small>
                                  </div>
                                )}

                                <div className="remove-variant-option">
                                  <a onClick={() => this.removeOption(index)}>
                                    <i className="ladi-icon icon-bin" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {isShowAddOther && (
              // <a onClick={this.addOtherOption} className="ladiui btn btn-secondary new-variant-option">
              //   {t("PRODUCTS.ADD_OPTION")}
              // </a>
              <div className="ladiui border-none btn-add-option mb-12 mt-12" onClick={this.addOtherOption}>
                <img className="mr-8" src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg"} alt="" />
                <div className="btn-add-product"> {t("PRODUCTS.ADD_OPTION")}</div>
              </div>
            )}
          </div>
        </div>

        {/* </modal> */}

        <ConfirmModal
          style={{ zIndex: 1050 }}
          id="confirm-delete-option"
          title={titleConfirm}
          content={contentConfrim}
          cancelText={t("ACTIONS.CANCEL")}
          okText={t("ACTIONS.DELETE")}
          onOk={this.delete}
          isLoading={this.props.loadingDelete}
          v
        />

        <ConfirmModal
          style={{ zIndex: 1050 }}
          id="confirm-delete-variant"
          title={"Xóa biến thể"}
          content={"Bạn có chắc muốn xóa biến thể này không, thao tác này không thể khôi phục ?"}
          cancelText={t("ACTIONS.CANCEL")}
          okText={t("ACTIONS.DELETE")}
          onOk={this.deleteVariant}
          isLoading={this.props.loadingDeleteVariant}
        />

        {isShowModalAdjust && (
          <ModalSwatch
            mode={appConfig.FORM_MODE.EDIT}
            visible={isShowModalAdjust}
            onCancel={this.hideModalAdjust}
            onSubmit={this.applySwatch}
            option={options[selectedOptionIndex]}
          />
        )}
      </>
    );
  }
}

// export default withTranslation("translation", { withRef: true })(ModalEditOption);

const mapDispatchToProps = (dispatch) => {
  return {
    updateOptionName: (data) => dispatch(productOptionActions.saveDataOptionName(data)),
    reloadProduct: (productID) => dispatch(productActions.reload(productID)),
  };
};

const mapStateToProps = (state) => ({
  productOption: { ...state.productOption },
  productActions: { ...state.product },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation("translation", { withRef: true })(ModalEditOption));

