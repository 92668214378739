import React from "react";
import PropTypes from "prop-types";
import { cloneDeep, compact, map, includes } from "lodash";

import * as storeAddressTypes from "../../../redux/futures/store_address/types";
import storeAddressActions from "../../../redux/futures/store_address/actions";

import * as storeTypes from "../../../redux/futures/store/types";
import storeActions from "../../../redux/futures/store/actions";

import { connect } from "react-redux";
import Input from "../../../components/Input";
import FormScope from "./ModalSettingScope";
import appConfig from "../../../config/app";
import baseHelper from "../../../helpers/BaseHelper";
import { withTranslation } from "react-i18next";
import Modal from "../../../components/Modal";
import Location from "../../../components/Location";
import LoadingScene from "../../../components/LoadingScene";

class ModalSettingAddress extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      address: {
        store_address_id: "",
        name: "",
        email: "",
        phone: "",
        address: "",
        country_code: "",
        country_name: "",
        postal_code: "",
        state_id: "",
        state_name: "",
        district_id: "",
        district_name: "",
        ward_id: "",
        ward_name: "",
        postal_code: "",
        is_shipping_location: appConfig.STATUS.ACTIVE,
      },
      isShowModalAddress: false,
      mode: null,
      addresses: [],
    };

    this.inputsRef = new Set();
    this.locationRef = React.createRef();
  }

  componentDidMount() {
    this.props.listStoreAddress();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.store.action != nextProps.store.action) {
      if (nextProps.store.action == storeTypes.APPLY_DEFAULT_ADDRESS_STORE) {
        if (nextProps.store.status) {
          this.props.listStoreAddress();
          window.LadiUI.toastCustom("success", "", nextProps.store.message);
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.store.message);
        }
      }
    }

    if (this.props.storeAddress.action != nextProps.storeAddress.action) {
      if (nextProps.storeAddress.action == storeAddressTypes.DELETE_STORE_ADDRESS) {
        if (nextProps.storeAddress.status) {
          this.props.listStoreAddress();
          window.LadiUI.toastCustom("success", "", nextProps.storeAddress.message);
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.storeAddress.message);
        }
      }

      if (nextProps.storeAddress.action == storeAddressTypes.CREATE_STORE_ADDRESS) {
        if (nextProps.storeAddress.status) {
          this.props.listStoreAddress();
          this.setState({
            isShowModalAddress: false,
          });
          window.LadiUI.toastCustom("success", "", nextProps.storeAddress.message);
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.storeAddress.message);
        }
      }

      if (nextProps.storeAddress.action == storeAddressTypes.UPDATE_STORE_ADDRESS) {
        if (nextProps.storeAddress.status) {
          this.props.listStoreAddress();
          window.LadiUI.toastCustom("success", "", nextProps.storeAddress.message);
          this.setState({
            isShowModalAddress: false,
          });
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.storeAddress.message);
        }
      }
      if (nextProps.storeAddress.action == storeAddressTypes.LIST_STORE_ADDRESS) {
        if (nextProps.storeAddress.status) {
          this.setState({
            addresses: nextProps.storeAddress.addresses,
          });
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.storeAddress.message);
        }
      }
    }
  }

  onChangeInput = (event) => {
    this.setState({
      address: {
        ...this.state.address,
        [event.target.name]: event.target.value,
      },
    });
  };

  onChangeShippingLocation = () => {
    this.setState({
      address: {
        ...this.state.address,
        is_shipping_location:
          this.state.address.is_shipping_location == appConfig.STATUS.INACTIVE ? appConfig.STATUS.ACTIVE : appConfig.STATUS.INACTIVE,
      },
    });
  };

  applyDefaultAddress = (storeAddressID) => {
    this.props.applyDefaultAddress(storeAddressID);
  };

  deleteItem = (storeAddressID) => {
    this.props.delete(storeAddressID);
  };

  openModalAddressCreate = () => {
    this.setState({
      address: {
        store_address_id: "",
        name: "",
        email: "",
        phone: "",
        address: "",
        country_code: "",
        country_name: "",
        state_id: "",
        state_name: "",
        district_id: "",
        district_name: "",
        ward_id: "",
        ward_name: "",
        postal_code: "",
        is_shipping_location: appConfig.STATUS.ACTIVE,
      },
      mode: appConfig.FORM_MODE.CREATE,
      isShowModalAddress: true,
    });
  };

  openModalAddressEdit = (address) => {
    this.setState({
      address: cloneDeep(address),
      mode: appConfig.FORM_MODE.EDIT,
      isShowModalAddress: true,
    });
  };

  hideModalAddress = () => {
    this.setState({
      isShowModalAddress: false,
    });
  };

  onChangeLocation = (address) => {
    this.setState({
      address: {
        ...this.state.address,
        ...address,
      },
    });
  };

  submit = () => {
    // Validate Input
    let errors = [];
    const inputsRef = compact(Array.from(this.inputsRef));
    map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });

    errors = errors.concat(this.locationRef.current.validate());

    errors = compact(errors);
    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      window.LadiUI.toastCustom("danger", "", errorMessage);
      return;
    }

    const address = this.locationRef.current.getData();

    const storeAddress = {
      ...this.state.address,
      ...address,
    };

    if (this.state.mode == appConfig.FORM_MODE.CREATE) {
      delete storeAddress.store_address_id;
      this.props.create(storeAddress);
    } else {
      this.props.update(storeAddress);
    }
  };

  getModalAddress = () => {
    if (!this.state.isShowModalAddress) {
      return null;
    }

    const { t } = this.props;
    const title =
      this.state.mode == appConfig.FORM_MODE.CREATE ? t("STORE_ADDRESS.ADD_STORE_ADDRESS") : t("STORE_ADDRESS.EDIT_STORE_ADDRESS");
    const { address } = this.state;
    const loading =
      this.props.storeAddress.loading &&
      includes([storeAddressTypes.CREATE_STORE_ADDRESS, storeAddressTypes.UPDATE_STORE_ADDRESS], this.props.storeAddress.waiting);
    return (
      <Modal
        visible={this.state.isShowModalAddress}
        id="modal-address"
        zIndex={9999}
        title={title}
        onCancel={this.hideModalAddress}
        onOk={this.submit}
        isLoading={loading}
        width={900}
      >
        <div>
          <div className="ladiui form-group">
            <label className="ladiui-label">{t("STORE_ADDRESS.NAME")}</label>
            <Input
              name="name"
              validationName={t("STORE_ADDRESS.NAME")}
              placeholder={t("STORE_ADDRESS.NAME")}
              value={address.name}
              onChange={this.onChangeInput}
              ref={(ref) => this.inputsRef.add(ref)}
              validations={{ isRequired: true }}
            />
          </div>

          <div className="ladiui-row">
            <div className="col-50">
              <div className="ladiui form-group">
                <label className="ladiui-label">{t("COMMON.EMAIL")}</label>
                <Input
                  name="email"
                  validationName={t("COMMON.EMAIL")}
                  placeholder={t("COMMON.EMAIL")}
                  value={address.email}
                  onChange={this.onChangeInput}
                  ref={(ref) => this.inputsRef.add(ref)}
                  validations={{ email: true, isRequired: true }}
                />
              </div>
            </div>
            <div className="col-50">
              <div className="ladiui form-group">
                <label className="ladiui-label">{t("CUSTOMERS.PHONE")}</label>
                <Input
                  name="phone"
                  validationName={t("CUSTOMERS.PHONE")}
                  placeholder={t("CUSTOMERS.PHONE")}
                  value={address.phone}
                  onChange={this.onChangeInput}
                  ref={(ref) => this.inputsRef.add(ref)}
                  validations={{ phone: true, isRequired: true }}
                />
              </div>
            </div>
          </div>

          <div className="ladiui form-group">
            <label className="ladiui-label">{t("COMMON.ADDRESS")}</label>
            <Input
              name="address"
              validationName={t("COMMON.ADDRESS")}
              placeholder={t("COMMON.ADDRESS")}
              value={address.address}
              onChange={this.onChangeInput}
              ref={(ref) => this.inputsRef.add(ref)}
              validations={{ isRequired: true }}
            />
          </div>

          <Location
            ref={this.locationRef}
            wrapperClassName="ladiui form-group"
            wrapperProps={{ style: { margin: "auto" } }}
            address={address}
            autoLoad={this.state.mode == appConfig.FORM_MODE.EDIT ? true : false}
            onChangeInput={this.onChangeLocation}
          />

          <div className="ladiui form-group flex" style={{ clear: "both" }}>
            <input
              onChange={this.onChangeShippingLocation}
              checked={address.is_shipping_location == appConfig.STATUS.ACTIVE}
              type="checkbox"
              className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
            />
            <label> {t("STORE_ADDRESS.IS_SHIPPING_LOCATION")}</label>
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    const { t, visible } = this.props;
    const { addresses } = this.state;

    const isLoadingScreen =
      this.props.store.loading &&
      includes([storeTypes.APPLY_DEFAULT_ADDRESS_STORE, storeAddressTypes.DELETE_STORE_ADDRESS], this.props.store.waiting);

    return (
      <div visible={visible} className="page-setting" id="page-setting-address">
        {isLoadingScreen && <LoadingScene blur={true} />}
        <div className="table-address">
          <div className="block-title-action mb-12">
            <h3 className="title">Địa chỉ cửa hàng </h3>
            <div className="btn-action">
              <button type="button" className="ladiui btn btn-primary btn-sm " onClick={this.openModalAddressCreate}>
                <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />

                {t("CUSTOMERS.ADD_ADDRESS")}
              </button>
            </div>
          </div>

          <div className="ladi-card ladiui-table-responsive">
            <table className="ladiui table text-left">
              <thead>
                <tr className="ladiui table-vertical header">
                  <th scope="col" name="name" className="text-left">
                    {t("STORE_ADDRESS.NAME")}
                  </th>
                  <th scope="col" name="email" className="text-left">
                    {t("COMMON.EMAIL")}
                  </th>
                  <th scope="col" name="phone" className="text-left">
                    {t("COMMON.PHONE")}
                  </th>
                  <th scope="col" name="address" className="text-left">
                    {t("COMMON.ADDRESS")}
                  </th>
                  <th scope="col" name="country" className="text-left">
                    {t("COMMON.COUNTRY")}
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {map(addresses, (address) => {
                  return (
                    <tr key={address.store_address_id} className="ladiui table-vertical main">
                      <td>
                        {address.name}
                        {address.isDefault ? <span className="address-default-btn">Mặc định</span> : ""}
                      </td>
                      <td>{address.email}</td>
                      <td>{address.phone}</td>
                      <td>{address.address}</td>
                      <td>{baseHelper.getCountryNameByCode(address.country_code)}</td>
                      <td className="text-right pd-0">
                        <div className="ladiui btn-group">
                          <div className="ladiui dropdown hide-mt ba-c">
                            <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                              <i className="ladiui icon icon-ldp-dot"></i>
                            </button>
                            <ul className="ladiui dropdown-menu r-0">
                              {!address.isDefault && (
                                <li>
                                  <a onClick={() => this.applyDefaultAddress(address.store_address_id)} className="ladiui dropdown-item">
                                    {t("CUSTOMERS.APPLY_DEFAULT_ADDRESS")}
                                  </a>
                                </li>
                              )}
                              <li>
                                <a onClick={() => this.openModalAddressEdit(address)} className="ladiui dropdown-item">
                                  {t("ACTIONS.EDIT")}
                                </a>
                              </li>
                              {!address.isDefault && (
                                <li>
                                  <a className="ladiui dropdown-item" onClick={() => this.deleteItem(address.store_address_id)}>
                                    {t("ACTIONS.DELETE")}
                                  </a>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        {this.getModalAddress()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    applyDefaultAddress: (storeAddressID) => dispatch(storeActions.applyDefaultAddress(storeAddressID)),
    delete: (storeAddressID) => dispatch(storeAddressActions.delete(storeAddressID)),
    create: (storeAddress) => dispatch(storeAddressActions.create(storeAddress)),
    update: (storeAddress) => dispatch(storeAddressActions.update(storeAddress)),
    listStoreAddress: () => dispatch(storeAddressActions.list()),
  };
};

const mapStateToProps = (state) => ({
  store: { ...state.store },
  storeAddress: { ...state.storeAddress },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withTranslation("translation", { withRef: true })(ModalSettingAddress)
);
