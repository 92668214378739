import React from "react";
import PropTypes from "prop-types";
import { map, remove } from "lodash";
import Modal from "../../../../components/Modal";

import baseHelper from "../../../../helpers/BaseHelper";
import { useTranslation } from "react-i18next";
import Input from "../../../../components/Input";
import appConfig from "../../../../config/app";

ModalKiotViet.propTypes = {
  formAccount: PropTypes.any,
  onChangeInput: PropTypes.func,
};

export default function ModalKiotViet(props) {
  const { formAccount, inputRefs, textToggleInputType, setTextToggleInputType, onChangeInput } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div className="content">
        <div className="ladiui form-group">
          <label className="ladiui-label">{t("INTEGRATE.ACCOUNT.LB_NAME")}</label>
          <Input
            ref={(ref) => inputRefs.add(ref)}
            name="name"
            validationName={t("INTEGRATE.ACCOUNT.LB_NAME")}
            placeholder={t("INTEGRATE.ACCOUNT.LB_NAME")}
            value={formAccount.name}
            onChange={onChangeInput}
            validations={{ isRequired: true }}
          />
        </div>
        <div className="ladiui form-group">
          <label className="ladiui-label">{t("INTEGRATE.ACCOUNT.LB_NAME_CONNECT")}</label>
          <Input
            ref={(ref) => inputRefs.add(ref)}
            name="user_name"
            validationName={t("INTEGRATE.ACCOUNT.ENTER_LB_NAME_CONNECT")}
            placeholder={t("INTEGRATE.ACCOUNT.ENTER_LB_NAME_CONNECT")}
            value={formAccount.user_name}
            onChange={onChangeInput}
            validations={{ isRequired: true }}
          />
        </div>
        <div className="ladiui form-group">
          <label className="ladiui-label">{t("INTEGRATE.ACCOUNT.CLIENT_ID")}</label>
          <Input
            ref={(ref) => inputRefs.add(ref)}
            name="client_id"
            validationName={t("INTEGRATE.ACCOUNT.ENTER_CLIENT_ID")}
            placeholder={t("INTEGRATE.ACCOUNT.ENTER_CLIENT_ID")}
            value={formAccount.client_id}
            onChange={onChangeInput}
            validations={{ isRequired: true }}
          />
        </div>
        <div className="ladiui form-group">
          <label className="ladiui-label">{t("INTEGRATE.ACCOUNT.SECURITY")}</label>
          <Input
            type={textToggleInputType ? "text" : "password"}
            ref={(ref) => inputRefs.add(ref)}
            name="secret_key"
            validationName={t("INTEGRATE.ACCOUNT.ENTER_SECURITY")}
            placeholder={t("INTEGRATE.ACCOUNT.ENTER_SECURITY")}
            value={formAccount.secret_key}
            onChange={onChangeInput}
            validations={{ isRequired: true }}
          />
          <span className="text-toggle-input-type" onClick={() => setTextToggleInputType(!textToggleInputType)}>
            {textToggleInputType ? (
              <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-show-password.svg" alt="" />
            ) : (
              <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-hide-password.svg" alt="" />
            )}
          </span>
        </div>
      </div>
    </div>
  );
}
