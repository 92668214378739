import React from "react";
import "../../assets/css/report/report.css";
import chartConfig from "../../config/chartconfig";

import reportActions from "../../redux/futures/report/actions";
import * as reportTypes from "../../redux/futures/report/types";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { cloneDeep, map } from "lodash";
import SelectOption from "../../components/SelectOption";

import baseHelper from "../../helpers/BaseHelper";

class ReportTopProduct extends React.Component {
  constructor(props) {
    super(props);

    this.moment = baseHelper.getMoment();

    const now = this.moment();
    const firstDay = now.set({
      date: 1,
    });

    this.state = {
      fromDate: baseHelper.getFromDate(firstDay),
      toDate: baseHelper.getToDate(this.moment()),
      type: "total",
      isLoadingInfo: false,
      data: [],
    };

    const optionOrders = cloneDeep(chartConfig.option_column);
    optionOrders.title = props.t("REPORTS.ORDER");

    this.listTag = [
      {
        name: props.t("REPORTS.BY_REVENUE"),
        value: "total",
      },
      {
        name: props.t("REPORTS.BY_QUANTITY"),
        value: "quantity",
      },
    ];
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.report.action != nextProps.report.action) {
      if (nextProps.report.action == reportTypes.REPORT_TOP_PRODUCT) {
        this.setState({
          isLoadingInfo: false,
        });
        if (nextProps.report.status) {
          this.setState({
            data: nextProps.report.data.top_product,
          });
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.report.message, "OK");
        }
      }
    }

    if (this.props.fromDate !== nextProps.fromDate) {
      this.setState(
        {
          fromDate: nextProps.fromDate,
        },
        () => {
          this.getReportTopProduct();
        }
      );
    }
    if (this.props.toDate !== nextProps.toDate) {
      this.setState(
        {
          toDate: nextProps.toDate,
        },
        () => {
          this.getReportTopProduct();
        }
      );
    }
  }

  componentDidMount() {
    window.LadiUI.init();
    this.setState({
      isLoadingInfo: true,
    });
    this.getReportTopProduct();
  }
  componentDidUpdate() {
    window.LadiUI.init();
  }

  getReportTopProduct = () => {
    const { fromDate, toDate, type } = this.state;
    if (!fromDate || !toDate || !type) return;

    let data = {
      from_date: fromDate,
      to_date: toDate,
      type: type,
    };
    this.props.topProductReport(data);
  };

  render() {
    const { type, isLoadingInfo, data } = this.state;
    const { t } = this.props;

    return (
      <div className="chart-item revenue-stage-chart">
        <div className="head-card top-product">
          <div className="item-card">
            <i className="ladi-icon icon-orders"></i>
            <h3 className="mr-l-5">{t("REPORTS.PRODUCT.TOP_PRODUCT")}</h3>
          </div>
          <div>
            <SelectOption
              wrapperStyle={{ minWidth: 150 }}
              items={this.listTag}
              _key="value"
              _value="name"
              currentKey={type}
              onSelectItem={(item) => {
                this.setState(
                  {
                    type: item.value,
                  },
                  () => {
                    this.getReportTopProduct();
                  }
                );
              }}
            />
          </div>
        </div>
        <div className="content-chart">
          <table className={`ladiui table text-left ${isLoadingInfo ? "loader" : ""}`}>
            <thead>
              <tr className="ladiui table-vertical header">
                <th scope="col" name="full_name" className={`text-left`}>
                  {t("REPORTS.PRODUCT.NAME")}
                </th>
                <th scope="col" name="email" className="text-left">
                  {t("REPORTS.PRODUCT.QUANTITY")}
                </th>
                <th scope="col" name="phone" className="text-left">
                  {t("REPORTS.PRODUCT.REVENUE")}
                </th>
              </tr>
            </thead>
            <tbody>
              {map(data, (value, index) => {
                return (
                  <tr key={index} className="ladiui table-vertical">
                    <td>{value.name}</td>
                    <td>{value.quantity}</td>
                    <td>{baseHelper.formatMoneyPostFix(value.total, this.props.storeReducer.userInfo.currentStore.currency_symbol)}</td>
                  </tr>
                );
              })}
              {data.length <= 0 && (
                <tr className="text-center">
                  <td colSpan="100%">{t("REPORTS.PRODUCT.NO_DATA")}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    topProductReport: (data) => dispatch(reportActions.topProductReport(data)),
  };
};

const mapStateToProps = (state) => ({
  report: { ...state.report },
  storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReportTopProduct));
