import React from "react";

import appConfig from "../../../config/app";
import { debounce, cloneDeep, compact, map, find } from "lodash";

import customerActions from "../../../redux/futures/customer/actions";
import * as customerTypes from "../../../redux/futures/customer/types";

import orderActions from "../../../redux/futures/order/actions";
import * as orderTypes from "../../../redux/futures/order/types";

import { connect } from "react-redux";

import PropTypes from "prop-types";

import ModalCustomer from "../../customers/components/ModalCustomer";
import { withTranslation } from "react-i18next";
import AsyncAutoComplete from "../../../components/AsyncAutoComplete";
import baseHelper from "../../../helpers/BaseHelper";
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import ConfirmModal from "../../../components/ConfirmModal";

class OrderCustomer extends React.Component {
  static propTypes = {
    orderID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selectedCustomer: PropTypes.object, // Thông tin chi tiết của customer đó
    selectCustomer: PropTypes.func,
    orderCustomer: PropTypes.object, // Thông tin customer của order: email, phone
    mode: PropTypes.string,
    create: PropTypes.func,
    updatedOrderCustomer: PropTypes.func,
    confirmRemove: PropTypes.bool,
  };

  static defaultProps = {
    confirmRemove: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchCustomer: "",
      displaySearchCustomer: props.t("CUSTOMERS.SEARCH_CUSTOMER"),
      currentCustomerInfo: cloneDeep(props.currentCustomerInfo) || {},
      isShowModalCustomer: false,
      isShowModalOrderCustomerEdit: false,
      email: props.orderCustomer ? props.orderCustomer.email : "",
      phone: props.orderCustomer ? props.orderCustomer.phone : "",
      isShowBlockCustomer: true,
      isShowBlockDropdownCustomer: false,
    };

    this.inputsRef = new Set();
    this.searchCustomer = debounce(this.searchCustomer, 500);

    this.firstFocus = true;
  }

  componentDidMount() {
    window.LadiUI.init();
    this.searchCustomer();
  }

  componentDidUpdate() {
    window.LadiUI.init();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.orderCustomer != nextProps.orderCustomer) {
      this.setState({
        email: nextProps.orderCustomer ? nextProps.orderCustomer.email : "",
        phone: nextProps.orderCustomer ? nextProps.orderCustomer.phone : "",
      });
    }

    if (this.props.currentCustomerInfo != nextProps.currentCustomerInfo) {
      this.setState({
        currentCustomerInfo: cloneDeep(nextProps.currentCustomerInfo),
      });
    }

    if (this.props.customerReducer.action != nextProps.customerReducer.action) {
      if (nextProps.customerReducer.action === customerTypes.CREATE_CUSTOMER) {
        if (nextProps.customerReducer.status) {
          this.setState({
            currentCustomerInfo: {},
          });

          nextProps.onSelectCustomer(nextProps.customerReducer.customer);

          window.LadiUI.toastCustom("success", "", nextProps.customerReducer.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.customerReducer.message, "OK");
        }
      }

      if (nextProps.customerReducer.action === customerTypes.UPDATE_CUSTOMER) {
        if (nextProps.customerReducer.status) {
          this.props.reload(this.props.orderCustomer.customer_id);
          if (this.props.orderID) {
            this.props.reloadOrder(this.props.orderID);
          }

          window.LadiUI.toastCustom("success", "", nextProps.customerReducer.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.customerReducer.message, "OK");
        }
      }

      if (this.props.mode == appConfig.FORM_MODE.CREATE) {
        // if (nextProps.customerReducer.action === customerTypes.SHOW_CUSTOMER) {
        //     if (this.props.selectedCustomer.customer_id != nextProps.customerReducer.customer.customer_id) {
        //         if (nextProps.customerReducer.status) {
        //             nextProps.onSelectCustomer(nextProps.customerReducer.customer);
        //         } else {
        //             window.LadiUI.toastCustom('danger', '', nextProps.customerReducer.message);
        //         }
        //     }
        // }
      }
    }

    if (this.props.order.action != nextProps.order.action) {
      if (nextProps.order.action === orderTypes.UPDATE_ORDER_CUSTOMER_INFO) {
        if (nextProps.order.status) {
          this.hideModalCustomerEdit();
          this.props.updatedOrderCustomer(this.state.email, this.state.phone);
          window.LadiUI.toastCustom("success", "", nextProps.order.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.order.message, "OK");
        }
      }
    }
  }

  /*******************************SEARCH CUSTOMER***************************/
  onChangeSearchCustomerInput = (event) => {
    this.setState(
      {
        searchCustomer: event.target.value,
      },
      () => this.searchCustomer()
    );
  };

  /**
   * Tim kiem theo name
   */
  onKeyDownSearchCustomerInput = (event) => {
    if (event.key === "Enter") {
      this.searchCustomer();
    }
  };

  onFocusCustomerInput = (event) => {
    if (this.firstFocus) {
      this.searchCustomer();
    }

    this.firstFocus = false;
  };

  searchCustomer = () => {
    this.props.search(this.state.searchCustomer);
  };

  openModalCustomerCreate = () => {
    this.setState({
      isShowModalCustomer: true,
      isShowBlockDropdownCustomer: true,
    });
  };

  openModalCustomerEdit = () => {
    this.setState({
      isShowModalCustomer: true,
    });
  };

  /**
   * @description:
   * Vi code JS doi value cua displaySearchCustomer nhung state trong React khong doi
   * =>Khong render lai ma phai thay doi state cua displaySearchCustomer de React hieu duoc
   */
  selectCustomer = (customer) => {
    this.setState(
      {
        displaySearchCustomer: "",
      },
      () => {
        this.setState({
          searchCustomer: "",
          displaySearchCustomer: this.props.t("CUSTOMERS.SEARCH_CUSTOMER"),
        });
      }
    );

    this.props.onSelectCustomer(customer);
  };

  openModalOrderCustomerEdit = () => {
    this.setState({
      isShowModalOrderCustomerEdit: true,
    });
  };

  hideModalCustomerEdit = () => {
    this.setState({
      isShowModalOrderCustomerEdit: false,
      email: this.props.orderCustomer.email,
      phone: this.props.orderCustomer.phone,
    });
  };

  removeCustomer = () => {
    if (this.props.confirmRemove) {
      window.LadiUI.showModal("confirm-customer-delete");
    } else {
      this.props.onSelectCustomer({});
    }
  };

  createNewCustomer = (newCustomer) => {
    this.props.create(newCustomer);
  };

  updateCustomer = (customer) => {
    customer.order_id = this.props.orderID;
    this.props.update(customer);
  };

  updateOrderCustomer = () => {
    // Validate
    let errors = [];
    const inputsRef = compact(Array.from(this.inputsRef));
    map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });

    errors = compact(errors);
    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      window.LadiUI.toastCustom("danger", "", errorMessage);
      return;
    }

    this.props.updateOrderCustomer(this.props.orderID, this.state.email, this.state.phone);
  };

  /****************************************************************/

  render() {
    const { t } = this.props;
    const { searchCustomer, email, phone, isShowBlockCustomer, isShowBlockDropdownCustomer } = this.state;
    const foundCustomers = this.props.customerReducer.customers || [];
    const { selectedCustomer, mode, orderCustomer, isResetCustomer } = this.props;
    const isLoadingSearchCustomer =
      this.props.customerReducer.loading && this.props.customerReducer.waiting == customerTypes.SEARCH_CUSTOMER;
    const isSubmitLoadingCreate = this.props.customerReducer.loading && this.props.customerReducer.waiting == customerTypes.CREATE_CUSTOMER;
    const isSubmitLoadingEdit = this.props.customerReducer.loading && this.props.customerReducer.waiting == customerTypes.UPDATE_CUSTOMER;
    const isLoadingUpdateOrderCustomer = this.props.order.loading && this.props.order.waiting == orderTypes.UPDATE_ORDER_CUSTOMER_INFO;

    if (mode == appConfig.FORM_MODE.CREATE) {
      if (selectedCustomer.customer_id) {
        return (
          <div id="order-custom-field" className="order-customer box-shadow-none" style={{ overflow: "inherit" }}>
            <div className="order-heading title">
              <h3>{t("CUSTOMERS.TITLE")}</h3>
              {isResetCustomer && (
                <div className="hidden-custom-field">
                  <a className="order-customer-edit" onClick={this.removeCustomer}>
                    Huỷ Chọn
                  </a>
                </div>
              )}
            </div>
            {isShowBlockCustomer && (
              <div className="customer-info draft-order">
                <span>
                  <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-user.svg" />
                  <>
                    <a target="blank" href={`/customers?customer_id=${selectedCustomer.customer_id}`}>
                      {" "}
                      {`${baseHelper.getText(selectedCustomer.last_name)} ${baseHelper.getText(selectedCustomer.first_name)}`}
                    </a>
                    <span className="total-order">{t("ORDERS.CUSTOMER_TOTAL_ORDER", selectedCustomer.total_order || 0)}</span>
                  </>
                </span>
                <span>
                  <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-phone.svg" /> {selectedCustomer.phone || t("COMMON.NO_PHONE")}
                </span>
                <span>
                  <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-mail.svg" /> {selectedCustomer.email || t("COMMON.NO_EMAIL")}
                </span>
                {/* {this.props.customerReducer.customer &&
                  this.props.customerReducer.customer.custom_fields &&
                  map(
                    this.props.customerReducer.customer.custom_fields,
                    (item, index) => {
                      return baseHelper.showCustomerInfo(item, index);
                    }
                  )} */}

                {/* <a className="order-customer-edit" onClick={this.removeCustomer}><i className="ladiui ladi-icon icon-e-remove" /></a> */}
              </div>
            )}

            <ConfirmModal
              id="confirm-customer-delete"
              title={t("ORDERS.MSG_REMOVE_CUSTOMER_TITLE")}
              content={t("ORDERS.MSG_REMOVE_CUSTOMER_CONTENT")}
              cancelText={t("ACTIONS.CANCEL")}
              okText={t("ACTIONS.OK")}
              onOk={() => {
                this.props.onSelectCustomer({});
                window.LadiUI.closeModal("confirm-customer-delete");
              }}
            />
          </div>
        );
      }

      return (
        <div className="order-customer not-customer-selected block-dropdown-customer">
          <label className="ladiui-label title-block-customer">{t("CUSTOMERS.TITLE")}</label>

          {/* <AsyncAutoComplete
            id="autocomplete-customer"
            items={foundCustomers}
            getItemValue={(item) => searchCustomer}
            loading={isLoadingSearchCustomer}
            renderItem={(item) => {
              if (item.isDefault) {
                return (
                  <div key={-1} className="item">
                    <div className="text-left">
                      <i className="ladi-icon icon-c-add" />{" "}
                      {t("CUSTOMERS.ADD_CUSTOMER")}
                    </div>
                  </div>
                );
              }
              return (
                <div key={item.customer_id} className="item">
                  <div className="name">
                    <span>{`${baseHelper.getText(
                      item.last_name
                    )} ${baseHelper.getText(item.first_name)}`}</span>
                    <div className="email">{item.email}</div>
                  </div>
                </div>
              );
            }}
            value={searchCustomer}
            onChange={this.onChangeSearchCustomerInput}
            onSelect={(value, item) => {
              if (item.isDefault) {
                this.openModalCustomerCreate();
              } else {
                this.selectCustomer(item);
              }
            }}
            inputProps={{
              onFocus: this.onFocusCustomerInput,
              className:
                "ladiui input custom-input-autocomplete custom-async-customer",
              placeholder: t("CUSTOMERS.SEARCH_CUSTOMER"),
            }}
            wrapperProps={{ className: "ladiui-search-custom has-default" }}
            hasDefaultItem={true}
            highlightedIndex={foundCustomers.length > 0 ? 1 : null}
          /> */}
          <div className="block-custom-content">
            <div className="ladiui select-search dropdown w100 open">
              <button className="ladiui btn btn-outline-light dropdown-toggle w100" id="dropdownMenuButton4" data-toggle="dropdown">
                <i></i>
                <span className="ladiui dropdown-text" data-text="Chọn khách hàng">
                  Chọn khách hàng
                </span>
                <span className="ladiui-btn-tag"></span>
                {/* <span className="ladiui removeName">
                {" "}
                <i className="ladiui icon icon-ldp-close"></i>
              </span> */}
              </button>

              <ul className={`ladiui dropdown-menu w100 ${isShowBlockDropdownCustomer ? "display-none" : ""}`}>
                <li className="ladiui search-group">
                  <input
                    style={{ backgroundColor: "#f1f3f4" }}
                    className="ladiui search-field form-control"
                    placeholder="Tìm kiếm"
                    aria-expanded="false"
                    value={searchCustomer}
                    onChange={this.onChangeSearchCustomerInput}
                  />
                  <i className="ladiui icon icon-search"></i>
                </li>
                <li className="ladiui search-group">
                  <div className="mb-20">
                    <div className="ladiui flex flex-start border-none cursor-pointer" onClick={() => this.openModalCustomerCreate()}>
                      <img className="mr-8" src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg"} alt="" />
                      <div className="btn-add-product customer">{"Thêm khách hàng mới"}</div>
                    </div>
                  </div>
                </li>
                <li className="ladiui">
                  <ul className="ladiui list-customer-form-edit">
                    {foundCustomers.map((item, index) => {
                      return (
                        <li key={index} className="ladiui check-element" onClick={() => this.selectCustomer(item)}>
                          <span className="text-name">
                            {item.first_name} {item.last_name}
                          </span>
                          <span className="email">{item.email}</span>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            </div>
          </div>

          {this.state.isShowModalCustomer && (
            <ModalCustomer
              visible={this.state.isShowModalCustomer}
              onCancel={() =>
                this.setState({
                  isShowModalCustomer: false,
                  isShowBlockDropdownCustomer: false,
                })
              }
              currentCustomerInfo={this.state.currentCustomerInfo}
              mode={appConfig.FORM_MODE.CREATE}
              onSubmit={this.createNewCustomer}
              loading={isSubmitLoadingCreate}
            />
          )}
        </div>
      );
    } else {
      if (baseHelper.isEmpty(orderCustomer)) {
        return null;
      }

      if (mode == appConfig.FORM_MODE.EDIT) {
        return (
          <div id="order-custom-field" className="order-customer box-shadow-none" style={{ overflow: "inherit", padding: "0px!important" }}>
            <div className="order-heading title">
              <h3>{t("CUSTOMERS.TITLE")}</h3>
              {/* <div className="hidden-custom-field"> */}
              {/* <a className="order-customer-edit" onClick={this.removeCustomer}>Huỷ Chọn</a> */}

              {/* <a href="#" onClick={() => { this.setState({ isShowBlockCustomer: !this.state.isShowBlockCustomer }) }} >
                                    <i className="ladi-icon icon-down-arrow"></i>
                                </a> */}
              {/* </div> */}
            </div>
            {isShowBlockCustomer && (
              <div className="customer-info flex space-between items-baseline">
                <div className="info">
                  <span>
                    <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-user.svg" />
                    <>
                      <a onClick={this.openModalCustomerEdit}>
                        {" "}
                        {`${baseHelper.getText(orderCustomer.first_name)} ${baseHelper.getText(orderCustomer.last_name)}`}
                      </a>
                      {/* <span className="total-order">{t("ORDERS.CUSTOMER_TOTAL_ORDER", orderCustomer.total_order || 0)}</span> */}
                    </>
                  </span>
                  <span>
                    <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-phone.svg" /> {orderCustomer.phone || t("COMMON.NO_PHONE")}
                  </span>
                  <span>
                    <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-mail.svg" /> {orderCustomer.email || t("COMMON.NO_EMAIL")}
                  </span>
                  {/* {
                                        this.props.customerReducer.customer && this.props.customerReducer.customer.custom_fields &&

                                        map(this.props.customerReducer.customer.custom_fields, (item, index) => {
                                            return baseHelper.showCustomerInfo(item, index);
                                        })

                                    } */}
                </div>
                <a onClick={this.openModalOrderCustomerEdit} className="ladiui order-customer-edit order-customer-edit-btn">
                  {t("ACTIONS.EDIT")}
                </a>
              </div>
            )}
            {this.state.isShowModalOrderCustomerEdit && (
              <Modal
                id="modal-order-customer-edit"
                onCancel={this.hideModalCustomerEdit}
                onOk={this.updateOrderCustomer}
                visible={this.state.isShowModalOrderCustomerEdit}
                width={600}
                title={t("ORDERS.UPDATE_USER_INFO")}
                isLoading={isLoadingUpdateOrderCustomer}
              >
                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("CUSTOMERS.PHONE")}</label>
                  <Input
                    tabIndex={1}
                    formName="customer"
                    name="phone"
                    validationName={t("CUSTOMERS.PHONE")}
                    placeholder={t("CUSTOMERS.PHONE")}
                    value={phone}
                    onChange={(event) => {
                      this.setState({
                        phone: event.target.value,
                      });
                    }}
                    ref={(ref) => this.inputsRef.add(ref)}
                    validations={{ phone: true }}
                  />
                </div>

                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("COMMON.EMAIL")}</label>
                  <Input
                    tabIndex={2}
                    formName="customer"
                    name="email"
                    validationName={t("COMMON.EMAIL")}
                    placeholder={t("COMMON.EMAIL")}
                    value={email}
                    onChange={(event) => {
                      this.setState({
                        email: event.target.value,
                      });
                    }}
                    ref={(ref) => this.inputsRef.add(ref)}
                    validations={{ email: true }}
                  />
                </div>
              </Modal>
            )}

            {this.state.isShowModalCustomer && (
              <ModalCustomer
                visible={this.state.isShowModalCustomer}
                onCancel={() =>
                  this.setState({
                    isShowModalCustomer: false,
                  })
                }
                currentCustomerInfo={this.state.currentCustomerInfo}
                mode={appConfig.FORM_MODE.EDIT}
                onSubmit={this.updateCustomer}
                loading={isSubmitLoadingEdit}
              />
            )}
          </div>
        );
      } else if (mode == appConfig.FORM_MODE.VIEW) {
        return (
          <div className="order-customer" style={{ overflow: "inherit" }}>
            <div className="order-heading title">
              <h3>{t("CUSTOMERS.TITLE")}</h3>
            </div>
            <div className="customer-info">
              <span>
                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-user.svg" />
                <>
                  {orderCustomer.customer_id ? (
                    <a target="blank" href={`/customers?customer_id=${orderCustomer.customer_id}`}>
                      {" "}
                      {`${baseHelper.getText(orderCustomer.first_name)} ${baseHelper.getText(orderCustomer.last_name)}`}
                    </a>
                  ) : (
                    `${baseHelper.getText(orderCustomer.first_name)} ${baseHelper.getText(orderCustomer.last_name)}`
                  )}
                </>

                <span className="total-order">{t("ORDERS.CUSTOMER_TOTAL_ORDER", orderCustomer.total_order || 0)}</span>
              </span>
              <span>
                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-phone.svg" /> {orderCustomer.phone || t("COMMON.NO_PHONE")}
              </span>
              <span>
                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-mail.svg" /> {orderCustomer.email || t("COMMON.NO_EMAIL")}
              </span>
              {/* {this.props.customerReducer.customer &&
                this.props.customerReducer.customer.custom_fields &&
                map(
                  this.props.customerReducer.customer.custom_fields,
                  (item, index) => {
                    return baseHelper.showCustomerInfo(item, index);
                  }
                )} */}
            </div>
          </div>
        );
      }
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    search: (name) => dispatch(customerActions.search(name)),
    reload: (customerID) => dispatch(customerActions.reload(customerID)),
    reloadOrder: (orderID) => dispatch(orderActions.reload(orderID)),
    create: (customer) => dispatch(customerActions.create(customer)),
    update: (customer) => dispatch(customerActions.update(customer)),
    updateOrderCustomer: (orderID, email, phone) => dispatch(orderActions.updateOrderCustomerInfo(orderID, email, phone)),
  };
};

const mapStateToProps = (state) => ({
  customerReducer: { ...state.customer },
  order: { ...state.order },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderCustomer));
