import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data) => ({
    type: types.LIST_CHECKOUT_CONFIG,
    meta: {
        endpoint: endpoint.LIST_CHECKOUT_CONFIG,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const create = (data) => ({
    type: types.CREATE_CHECKOUT_CONFIG,
    meta: {
        endpoint: endpoint.CREATE_CHECKOUT_CONFIG,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const update = (data) => ({
    type: types.UPDATE_CHECKOUT_CONFIG,
    meta: {
        endpoint: endpoint.UPDATE_CHECKOUT_CONFIG,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const _delete = (checkoutConfigID) => ({
    type: types.DELETE_CHECKOUT_CONFIG,
    meta: {
        endpoint: endpoint.DELETE_CHECKOUT_CONFIG,
        method: REQUEST_METHOD.POST,
        body: {
            checkout_config_id: checkoutConfigID
        },
        hasAuth: true,
    }
});

const markDefault = (checkoutConfigID) => ({
    type: types.MARK_DEFAULT_CHECKOUT_CONFIG,
    meta: {
        endpoint: endpoint.MARK_DEFAULT_CHECKOUT_CONFIG,
        method: REQUEST_METHOD.POST,
        body: {
            checkout_config_id: checkoutConfigID
        },
        hasAuth: true,
    }
});

const getInfo = (checkoutConfigID) => ({
    type: types.GET_CHECKOUT_CONFIG,
    meta: {
        endpoint: endpoint.GET_CHECKOUT_CONFIG,
        method: REQUEST_METHOD.POST,
        body: {
            checkout_config_id: checkoutConfigID
        },
        hasAuth: true,
    }
});

export default {
    create,
    list,
    update,
    delete: _delete,
    markDefault,
    getInfo
}
