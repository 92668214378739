import React from "react";
import "../../assets/css/report/report.css";
import chartConfig from "../../config/chartconfig";

import reportActions from "../../redux/futures/report/actions";
import * as reportTypes from "../../redux/futures/report/types";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { cloneDeep, map } from "lodash";
import SelectOption from "../../components/SelectOption";

import baseHelper from "../../helpers/BaseHelper";

class ReportTopProduct extends React.Component {
  constructor(props) {
    super(props);

    this.moment = baseHelper.getMoment();

    const now = this.moment();
    const firstDay = now.set({
      date: 1,
    });

    this.state = {
      fromDate: baseHelper.getFromDate(firstDay),
      toDate: baseHelper.getToDate(this.moment()),
      type: "total",
      isLoadingInfo: false,
      data: this.props.data_staff || [],
      queryOverTime: ""
    };

    const optionOrders = cloneDeep(chartConfig.option_column);
    optionOrders.title = props.t("REPORTS.ORDER");

    this.listTag = [
      {
        name: props.t("REPORTS.BY_REVENUE"),
        value: "total",
      },
      {
        name: props.t("REPORTS.BY_QUANTITY"),
        value: "quantity",
      },
    ];
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.data_staff != nextProps.data_staff) {
      // let categories = [];
      // let dataStaff = [];

      // if (nextProps.data_staff.resultSuccess && nextProps.data_staff.resultSuccess.length > 0) {
      //   nextProps.data_staff.resultSuccess.map((value, index) => {
      //     if (!value.refund)
      //       value.refund = 0
      //     let indexStaff = categories.indexOf(value.email)

      //     if (indexStaff < 0) {
      //       categories.push(value.email);
      //       let dataItem = {
      //         ...value,
      //         total_success: value.total - value.refund
      //       }
      //       dataStaff.push(dataItem)
      //     } else {
      //       dataStaff[indexStaff].total_success = value.total - value.refund;
      //     }

      //   })
      // }
      // if (nextProps.data_staff.resultPending && nextProps.data_staff.resultPending.length > 0) {
      //   nextProps.data_staff.resultPending.map((value, index) => {
      //     if (!value.refund)
      //       value.refund = 0
      //     let indexStaff = categories.indexOf(value.email)

      //     if (indexStaff < 0) {
      //       categories.push(value.email);
      //       let dataItem = {
      //         ...value,
      //         total_pending: value.total - value.refund
      //       }
      //       dataStaff.push(dataItem)
      //     } else {
      //       dataStaff[indexStaff].total_pending = value.total - value.refund;
      //     }

      //   })
      // }


      await this.setState({
        data: nextProps.data_staff
      })
    }
    if (this.props.queryOverTime != nextProps.queryOverTime) {
      await this.setState({
        queryOverTime: nextProps.queryOverTime
      })
    }
  }

  componentDidMount() {
    window.LadiUI.init();
    this.setState({
      isLoadingInfo: true,
    });


    this.setState({
      queryOverTime: this.props.queryOverTime
    })
  }
  componentDidUpdate() {
    window.LadiUI.init();
  }


  render() {
    const { type, isLoadingInfo, data, queryOverTime } = this.state;
    const { t } = this.props;

    return (
      <div className="chart-item revenue-stage-chart">
        <div className="head-card top-product">
          <div className="item-card">
            <i className="ladi-icon icon-orders"></i>
            <h3 className="mr-l-5">{t("REPORTS.STAFF.BY_STAFF")}</h3>
          </div>
        </div>
        <div className="content-chart">
          <table className={`ladiui table text-left`}>
            <thead>
              <tr className="ladiui table-vertical header">
                <th scope="col" name="full_name" className={`text-left`}>
                  {t("REPORTS.STAFF.STAFF_NAME")}
                </th>
                <th scope="col" className="text-left">
                  Tổng tiền
                </th>
                <th scope="col" className="text-left">
                  Phí giao hàng
                </th>
                <th scope="col" className="text-left">
                  Tiền hàng trả lại
                </th>
                <th scope="col" className="text-left">
                  Doanh số
                </th>
                <th scope="col" className="text-left">
                  SL đơn hàng
                </th>
              </tr>
            </thead>
            <tbody>
              {map(this.state.data, (item, index) => {
                return (
                  <tr key={index} className="ladiui table-vertical">
                    <td>
                      <NavLink to={`/reports/orders_over_time?${queryOverTime}&staff_id=${item.ladi_uid ? item.ladi_uid : ""}`}>
                        {item.email}
                      </NavLink>
                    </td>
                    <td>{item.total ? baseHelper.formatMoneyPostFix(item.total, this.props.storeReducer.userInfo.currentStore.currency_symbol) : 0}</td>
                    <td>{item.shipping_fee ? baseHelper.formatMoneyPostFix(item.shipping_fee, this.props.storeReducer.userInfo.currentStore.currency_symbol) : 0}</td>
                    <td>{item.refund ? baseHelper.formatMoneyPostFix(item.refund, this.props.storeReducer.userInfo.currentStore.currency_symbol) : 0}</td>
                    <td>{baseHelper.formatMoneyPostFix(item.total - item.shipping_fee - item.refund, this.props.storeReducer.userInfo.currentStore.currency_symbol)}</td>
                    <td>{item.num_order_success ? baseHelper.formatNumber(item.num_order_success) : 0}</td>
                  </tr>
                );
              })}
              {this.state.data.length <= 0 && (
                <tr className="text-center">
                  <td colSpan="100%">{t("REPORTS.PRODUCT.NO_DATA")}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

const mapStateToProps = (state) => ({
  storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReportTopProduct));
