import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import baseHelper from '../helpers/BaseHelper';

class SelectOption extends React.Component {
    static propTypes = {
        items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
        headerItems: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        headerKey: PropTypes.string,
        headerValue: PropTypes.string,
        wrapperClassName: PropTypes.string,
        wrapperStyle: PropTypes.object,
        innerClassName: PropTypes.string,
        innerStyle: PropTypes.object,
        onSelectItem: PropTypes.func,
        currentKey: PropTypes.any,
        currentValue: PropTypes.any,
        _key: PropTypes.string,
        _value: PropTypes.string,
        placeHolder: PropTypes.string,
        pickTitle: PropTypes.string,
        disabled: PropTypes.bool,
        directionUp: PropTypes.bool,
    };

    static defaultProps = {
        validations: {
            isRequired: false,
        },
        disabled: false,
        directionUp: false,
    };

    constructor(props) {
        super(props);
    }

    getValueByKey = (_key) => {
        if (baseHelper.isEmpty(_key)) {
            return this.props.pickTitle;
        }

        let matchedItem = {};
        map(this.props.items, (item) => {
            if (item[this.props._key] == _key) {
                matchedItem = item;
            }
        });

        return matchedItem[this.props._value];
    };

    onSelectItem = (selected) => {
        if (!selected.is_header) {
            if (
                (baseHelper.isEmpty(selected) && baseHelper.isEmpty(this.props.currentKey)) ||
                (selected && selected[this.props._key] === this.props.currentKey)
            ) {
                return;
            }
        }

        this.props.onSelectItem(selected);
    };

    render() {
        const {
            wrapperClassName,
            wrapperStyle,
            id,
            customStyle,
            innerClassName,
            innerStyle,
            currentValue,
            currentKey,
            items,
            _key,
            _value,
            pickTitle,
            disabled,
            headerItems,
            headerKey,
            headerValue,
            directionUp,
            classIconCTA,
            logoChanel,
            customClassName,
            placeHolder,
            idBlock,
        } = this.props;

        return (
            <div className={`ladiui btn-group ${customClassName}`} style={customStyle}>
                <div
                    className={`ladiui dropdown w100 ${wrapperClassName ? wrapperClassName : ''}`}
                    style={wrapperStyle ? wrapperStyle : {}}
                    id={id}
                >
                    <button
                        className={`ladiui btn btn-outline-light dropdown-toggle ${innerClassName}`}
                        data-toggle='dropdown'
                        style={innerStyle}
                        disabled={disabled}
                    >
                        {classIconCTA && <i className={`${classIconCTA} mr-8`} />}
                        {logoChanel && <img src={logoChanel} className='mr-8' />}
                        <span className='ladiui dropdown-text'>{currentValue || this.getValueByKey(currentKey) || placeHolder}</span>
                    </button>

                    <ul id={idBlock} className={`ladiui dropdown-menu ${directionUp ? 'dropup bt-0' : ''}`} style={{ width: '100%' }}>
                        {pickTitle && (
                            <li onClick={() => this.onSelectItem('')}>
                                <a className='ladiui dropdown-item' data-value={''}>
                                    {pickTitle}
                                </a>
                            </li>
                        )}
                        {map(headerItems, (item, index) => (
                            <li
                                key={item[headerKey]}
                                className={` ${item.className ? item.className : ''}`}
                                onClick={() => {
                                    this.onSelectItem(item);
                                }}
                            >
                                <a className='ladiui dropdown-item'>
                                    {item.icon && <i className={`ladi-icon ${item.icon}`} />}
                                    {item[headerValue] || item}
                                </a>
                            </li>
                        ))}
                        {map(items, (item, index) => (
                            <li key={item[_key] || item} className='select-item' onClick={() => this.onSelectItem(item)}>
                                <a className='ladiui dropdown-item flex' data-value={item[_key] || item}>
                                    {item.CLASS_ICON && <i className={item.CLASS_ICON} />}
                                    {item.image_url && <img src={item.image_url} className='mr-8' />}
                                    {item[_value] || item}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
}

export default SelectOption;
