/** @format */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import moment from 'moment';

import fileActions from '../../../redux/futures/file/actions';
import * as fileTypes from '../../../redux/futures/file/types';

import Input from '../../../components/Input';
import SelectOption from '../../../components/SelectOption';

import pageCheckoutActions from '../../../redux/futures/page_checkout/actions';
import * as pageCheckoutTypes from '../../../redux/futures/page_checkout/types';

import checkoutConfigActions from '../../../redux/futures/checkout_config/actions';
import * as checkoutConfigTypes from '../../../redux/futures/checkout_config/types';

import domainActions from '../../../redux/futures/domain/actions';
import * as domainTypes from '../../../redux/futures/domain/types';

import BaseHelper from '../../../helpers/BaseHelper';

import PanelProductSeoSocial from '../../../components/PanelProductSeoSocial';

import appConfig from '../../../config/app';

import { includes, isElement, isEmpty, map, remove, find } from 'lodash';

const CDN = 'https://w.ladicdn.com/';
const PanelSettingGeneralStore = forwardRef((props, ref) => {
    let { isShow, currentDataSeo, currentDataGeneral, isReloadIframe } = props;
    const location = useLocation();
    const history = useHistory();
    const queryStrings = new URLSearchParams(location.search);

    const productSeoSocialRef = React.createRef();

    const formRef = React.createRef();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const fileReducer = useSelector((state) => state.file);
    const domainReducer = useSelector((state) => state.domainReducer);
    const pageCheckoutReducer = useSelector((state) => state.pageCheckoutReducer);
    const checkoutConfigReducer = useSelector((state) => state.checkoutConfigReducer);
    const [urlPublished, setUrlPublished] = useState(props.urlPublished);

    const [pageCheckout, setPageCheckout] = useState({
        checkout_config_id: null,
        domain: '',
    });

    const [selectedPath, setSelectedPath] = useState('');

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return {
                ...pageCheckout,
                seo: productSeoSocialRef?.current ? productSeoSocialRef.current.getData() : {},
            };
        },
    }));

    useEffect(() => {
        let iFrame = document.getElementById('iframe-general-store');

        if (iFrame) {
            iFrame.src = iFrame.src;
            // iFrame.contentWindow.location.reload();
        }
    }, [isReloadIframe]);

    useEffect(() => {
        if (props.urlPublished) {
            setUrlPublished(props.urlPublished);
        }
    }, [props.urlPublished]);

    useEffect(() => {
        // let payload = {
        //     search: {},
        // };
        // dispatch(pageCheckoutActions.list(payload));
        dispatch(
            domainActions.list({
                types: 'PAGE_STORE',
                is_verify: 1,
            })
        );
        dispatch(checkoutConfigActions.list());
    }, []);

    useEffect(() => {
        if (currentDataGeneral && !isEmpty(currentDataGeneral)) {
            setPageCheckout({
                ...pageCheckout,
                checkout_config_id: currentDataGeneral.checkout_config_id || null,
                domain: currentDataGeneral.domain || '',
            });

            if (currentDataGeneral.path) {
                setSelectedPath(currentDataGeneral.path);
            }
        }
    }, [currentDataGeneral]);

    useEffect(() => {}, [fileReducer]);

    // useEffect(() => {
    //     if (pageCheckoutReducer.action == pageCheckoutTypes.LIST_PAGE_CHECKOUT) {
    //         if (pageCheckoutReducer.page_checkouts && pageCheckoutReducer.page_checkouts.length > 0) {
    //             let defaultPageCheckout = pageCheckoutReducer.page_checkouts.filter((item) => item.is_default == 1);

    //             if (defaultPageCheckout) {
    //                 setPageCheckout({
    //                     ...pageCheckout,
    //                     checkout_config_id: defaultPageCheckout[0].page_checkout_id,
    //                 });
    //             }
    //         }
    //     }
    // }, [pageCheckoutReducer]);

    useEffect(() => {
        if (checkoutConfigReducer.action == checkoutConfigTypes.LIST_CHECKOUT_CONFIG) {
            if (checkoutConfigReducer.status) {
                if (!props.checkoutConfigID) {
                    const defaultCheckoutConfig = find(
                        checkoutConfigReducer.checkout_configs,
                        (item) => item.is_default == appConfig.STATUS.ACTIVE
                    );
                    if (defaultCheckoutConfig) {
                        setPageCheckout({
                            ...pageCheckout,
                            checkout_config_id: defaultCheckoutConfig.checkout_config_id,
                        });
                    }
                }
            } else {
                // window.LadiUI.toastCustom("danger", "", checkoutConfigReducer.message);
                window.LadiUI.showErrorMessage('Thông báo', checkoutConfigReducer.message, 'OK');
            }
        }
    }, [checkoutConfigReducer]);

    const handelChangeConfig = (checkoutConfigId) => {
        setPageCheckout({
            ...pageCheckout,
            checkout_config_id: checkoutConfigId,
        });
    };

    const handelChangeDomain = (domainData) => {
        setPageCheckout({
            ...pageCheckout,
            domain: domainData.domain,
        });
    };

    const handleSubmitData = () => {
        props.handleSubmitData();
    };

    return (
        <div className='panel-store-info block-setting-general-store' style={isShow ? { display: 'block' } : { display: 'none' }}>
            <div className='block-title-action'>
                <h3 className='title'>Cài đặt chung</h3>
                <button className='ladiui btn btn-primary' onClick={() => handleSubmitData()}>
                    {props.selectedPageCheckoutId ? 'Lưu cửa hàng' : 'Tạo cửa hàng'}
                </button>
            </div>
            <div className='main-content'>
                <div className='left-content config-panel'>
                    <PanelProductSeoSocial ref={productSeoSocialRef} productInfoSeo={currentDataSeo} />
                    <div className='line-bar' style={{ margin: '24px 0px', borderBottom: '1px solid #EEE' }}></div>
                    <div className='block-domain'>
                        <h3 className='title'>Quản lý trang thanh toán và tên miền</h3>
                        <div className='ladiui form-group'>
                            <div>
                                <label className='ladiui-label'>Chọn cấu hình thanh toán</label>
                                <div className='flex'>
                                    <SelectOption
                                        wrapperStyle={{
                                            minWidth: '250px',
                                            width: '100%',
                                            display: 'inline-block',
                                            zIndex: 999,
                                        }}
                                        innerStyle={{ width: '100%', minWidth: '100%' }}
                                        customStyle={{ padding: '0px', width: '100%', zIndex: '1000' }}
                                        wrapperClassName='select-option-checkout-config mr-24'
                                        innerClassName='ellipsis'
                                        currentKey={pageCheckout.checkout_config_id}
                                        placeHolder={`Chọn cấu hình thanh toán`}
                                        headerItems={[
                                            {
                                                key: 'create',
                                                name: t('CHECKOUTS.ADD_PAGE_CHECKOUT_CONFIG'),
                                                icon: 'icon-c-add',
                                                className: 'prevent-hide',
                                                is_header: true,
                                            },
                                            {
                                                key: 'refresh',
                                                name: t('COMMON.REFRESH_LIST'),
                                                icon: 'icon-sync',
                                                className: 'prevent-hide',
                                                is_header: true,
                                            },
                                        ]}
                                        headerKey={'key'}
                                        headerValue={'name'}
                                        items={checkoutConfigReducer.checkout_configs || []}
                                        _key={'checkout_config_id'}
                                        _value={'name'}
                                        onSelectItem={(item) => {
                                            if (item.checkout_config_id) {
                                                handelChangeConfig(item.checkout_config_id);
                                            } else {
                                                switch (item.key) {
                                                    case 'create':
                                                        window.open('/settings?type=setting-checkout&is_new=true#CONFIG_CHECKOUT');
                                                        break;
                                                    case 'refresh':
                                                        dispatch(checkoutConfigActions.list());
                                                        break;
                                                }
                                            }
                                        }}
                                    />
                                    <a
                                        className='ladiui btn btn-outline-primary'
                                        target='_blank'
                                        // href={`/page_checkout/sdk-update/${pageCheckout.checkout_config_id}`}
                                        href={`/settings?type=setting-checkout&checkout_config_id=${pageCheckout.checkout_config_id}#CONFIG_CHECKOUT`}
                                    >
                                        Chỉnh sửa cấu hình
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>Truy cập đường dẫn mặc định</label>
                            <div className='flex'>
                                <div
                                    className='link-default w100'
                                    style={{ padding: '8px 12px', border: '1px solid #e5e5e5', borderRadius: '2px' }}
                                >
                                    <a>{`https://${appConfig.DOMAIN_CHECKOUTS}/page/${selectedPath}`}</a>
                                </div>
                                <button
                                    className='ladiui btn btn-outline-primary ml-24'
                                    onClick={() => window.open(`https://${appConfig.DOMAIN_CHECKOUTS}/page/${selectedPath}`, '_blank')}
                                >
                                    Truy cập
                                </button>
                            </div>
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label pb-0'>Hoặc</label>
                        </div>
                        <div className='ladiui form-group'>
                            <div>
                                <label className='ladiui-label'>Chọn tên miền cửa hàng</label>
                                <div className='flex'>
                                    <SelectOption
                                        wrapperStyle={{
                                            minWidth: '250px',
                                            width: '100%',
                                            display: 'inline-block',
                                            zIndex: 999,
                                        }}
                                        innerStyle={{ width: '100%', minWidth: '100%' }}
                                        customStyle={{ padding: '0px', width: '100%' }}
                                        wrapperClassName='select-option-checkout-config'
                                        innerClassName='ellipsis'
                                        placeHolder={`Chọn tên miền cửa hàng`}
                                        currentValue={pageCheckout.domain}
                                        headerItems={[
                                            {
                                                key: 'create',
                                                name: t('CHECKOUTS.ADD_DOMAIN_CONFIG'),
                                                icon: 'icon-c-add',
                                                className: 'prevent-hide',
                                                is_header: true,
                                            },
                                            {
                                                key: 'refresh',
                                                name: t('COMMON.REFRESH_LIST'),
                                                icon: 'icon-sync',
                                                className: 'prevent-hide',
                                                is_header: true,
                                            },
                                        ]}
                                        headerKey={'key'}
                                        headerValue={'name'}
                                        items={domainReducer.domains || []}
                                        _key={'domain_id'}
                                        _value={'domain'}
                                        onSelectItem={(item) => {
                                            if (item.domain_id) {
                                                handelChangeDomain(item);
                                            } else {
                                                switch (item.key) {
                                                    case 'create':
                                                        window.open('/settings?type=setting-checkout&is_new=true#DOMAIN');
                                                        break;
                                                    case 'refresh':
                                                        dispatch(
                                                            domainActions.list({
                                                                types: 'PAGE_STORE',
                                                                is_verify: 1,
                                                            })
                                                        );
                                                        break;
                                                }
                                            }
                                        }}
                                    />
                                    <button
                                        className='ladiui btn btn-outline-primary ml-24'
                                        onClick={() => window.open(`https://${pageCheckout.domain}`, '_blank')}
                                    >
                                        Truy cập
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='right-content'>
                    {
                        // urlPublished
                        // &&
                        <div className='ladiui iframe-checkout'>
                            <iframe src={`${urlPublished}?isPreview=true`} width={'100%'} id='iframe-general-store' />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
});

PanelSettingGeneralStore.propTypes = {};

export default PanelSettingGeneralStore;
