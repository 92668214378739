import React from "react";
import Modal from "../../../../components/Modal";
import PropTypes from "prop-types";
import baseHelper from "../../../../helpers/BaseHelper";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { compact, map, isEqual } from "lodash";

import orderActions from "../../../../redux/futures/order/actions";
import * as orderTypes from "../../../../redux/futures/order/types";

import NumberInput from "../../../../components/NumberInput";
import { appLocalStorage } from "../../../../localforage";
import appConfig from "../../../../config/app";
import Input from "../../../../components/Input";

class ModalRefund extends React.Component {
  static propTypes = {
    orderID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    paymentStatus: PropTypes.string.isRequired,
    refundAll: PropTypes.bool,
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    refundAll: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      orderDetails: [],
      shippingRefund: 0,
      refund: 0,
      restock: false,
      userInfo: {
        currentStore: {},
      },
    };

    this.formRefundRef = new Set();
  }

  componentWillMount() {
    appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
      this.setState({
        userInfo: result,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.order.action != nextProps.order.action) {
      if (nextProps.order.action == orderTypes.SUGGEST_REFUND) {
        if (nextProps.order.status) {
          this.setState({
            orderDetails: nextProps.order.orderDetails,
            shippingRefund: nextProps.order.shippingRefund,
            refund: nextProps.order.refund,
          });
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.order.message, "OK");
        }
      }
    }
  }

  componentDidMount() {
    this.suggest();
  }

  suggest = () => {
    const data = {
      order_id: this.props.orderID,
      order_details: this.state.orderDetails,
      shipping_refund: this.state.shippingRefund,
    };
    if (!isEqual(this.oldData, data)) {
      this.oldData = data;
      this.props.suggest(data);
    }
  };

  refund = () => {
    // Validate
    let errors = [];
    const inputsRef = compact(Array.from(this.formRefundRef));
    map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });

    errors = compact(errors);
    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      window.LadiUI.toastCustom("danger", "", errorMessage);
      return;
    }

    const data = {
      order_id: this.props.orderID,
      restock: this.state.restock,
      order_details: this.state.orderDetails,
    };

    if (this.props.paymentStatus == appConfig.PAYMENT_STATUS.SUCCESS.CODE) {
      data.refund = this.state.refund;
    }

    this.props.onOk(data);
  };

  onChangeQuantity = (event, index) => {
    const { orderDetails } = this.state;

    const selected = orderDetails[index];
    selected.quantity = baseHelper.parseInt(event.target.value);

    this.setState({
      orderDetails,
    });
  };

  onChangeInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { t, refundAll } = this.props;
    const { orderDetails, shippingRefund, restock } = this.state;
    const currencyCode = this.state.userInfo.currentStore.currency_code;
    const currencySymbol = this.state.userInfo.currentStore.currency_symbol;
    const { subTotal, discountFee, shippingFee, availableShippingRefund, availableRefund, totalQuantity } = this.props.order;

    const isLoadingSuggest = this.props.order.loading && orderTypes.SUGGEST_REFUND == this.props.order.waiting;
    const isRefundMode = this.props.paymentStatus == appConfig.PAYMENT_STATUS.SUCCESS.CODE && !refundAll;

    return (
      <Modal
        id="modal-refund"
        title={isRefundMode ? t("ORDERS.REFUND") : t("ORDERS.RESTOCK")}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        onOk={this.refund}
        width={700}
        bodyClassName={`${isLoadingSuggest ? "loader" : ""}`}
        bodyStyles={{ minHeight: "35vh" }}
        isLoading={this.props.isLoading}
        disableOk={isRefundMode ? this.state.refund <= 0 : !restock}
      >
        <div className={`ladi-card`}>
          <table className="ladiui table text-left" style={{ boxShadow: "none" }}>
            <tbody>
              {map(orderDetails, (item, index) => {
                return (
                  <tr key={index} className="ladiui table-vertical">
                    <td>{item.product_name}</td>
                    <td style={{ paddingLeft: 10, paddingRight: 10 }}>
                      {item.discount_fee > 0 && (
                        <div style={{ whiteSpace: "nowrap" }}>
                          <span>{baseHelper.formatMoneyPostFix(item.last_price, currencyCode)}</span>
                          <a
                            style={{
                              marginLeft: "4px",
                              textDecoration: "line-through",
                            }}
                          >
                            {baseHelper.formatMoneyPostFix(item.price, currencyCode)}
                          </a>
                        </div>
                      )}
                      {item.discount_fee == 0 && baseHelper.formatMoneyPostFix(item.price, currencyCode)}
                    </td>
                    <td className="text-right">
                      {item.available_quantity <= 0 && t("ORDERS.PRODUCT_RESTOCK_ALL")}
                      {item.available_quantity > 0 && (
                        <Input
                          id={`quantity-${item.order_detail_id}`}
                          type="number"
                          min={0}
                          max={item.available_quantity}
                          value={item.quantity}
                          onChange={(event) => this.onChangeQuantity(event, index)}
                          onBlur={() => this.suggest()}
                          style={{ textAlign: "center" }}
                        />
                      )}
                    </td>
                    <td className="text-right">{baseHelper.formatMoneyPostFix(item.total, currencyCode)}</td>
                  </tr>
                );
              })}
              <tr className="ladiui table-vertical">
                <td colSpan={2}></td>
                <td className="text-right">{t("COMMON.TOTAL")}</td>
                <td className="text-right">{baseHelper.formatMoneyPostFix(subTotal, currencyCode)}</td>
              </tr>

              {isRefundMode && discountFee > 0 && (
                <tr className="ladiui table-vertical">
                  <td colSpan={2}></td>
                  <td className="text-right">{t("ORDERS.DISCOUNT")}</td>
                  <td className="text-right">{baseHelper.formatMoneyPostFix(discountFee, currencyCode)}</td>
                </tr>
              )}

              {isRefundMode && availableShippingRefund > 0 && (
                <tr className="ladiui table-vertical">
                  <td colSpan={2}></td>
                  <td className="text-right">{`${t("ORDERS.SHIPPING")} (${t(
                    "ORDERS.REMAIN",
                    baseHelper.formatMoneyPostFix(availableShippingRefund, currencyCode)
                  )})`}</td>
                  <td className="text-right">
                    <NumberInput
                      name="shippingRefund"
                      prefix={currencySymbol}
                      value={shippingRefund}
                      onChange={this.onChangeInput}
                      onBlur={this.suggest}
                    />
                  </td>
                </tr>
              )}
              <tr className="ladiui table-vertical">
                <td colSpan={2}></td>
                <td className="text-right" style={{ whiteSpace: "nowrap" }}>
                  {t("ORDERS.MONEY_CAN_REFUND")}:
                </td>
                <td className="text-right" style={{ whiteSpace: "nowrap", paddingLeft: "10px" }}>
                  {baseHelper.formatMoneyPostFix(availableRefund, currencyCode)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {isRefundMode && availableRefund > 0 && (
          <div className="ladiui form-group" style={{ marginTop: 25 }}>
            <div className="ladiui-row">
              <div className="col-50">
                <label className="ladiui-label">{t("ORDERS.LB_REFUND")}</label>
              </div>
              <div className="col-50">
                <NumberInput
                  name="refund"
                  prefix={currencySymbol}
                  validationName={t("ORDERS.LB_REFUND_VALIDATION")}
                  value={this.state.refund}
                  onChange={this.onChangeInput}
                  validations={{
                    max: availableRefund,
                  }}
                  ref={(ref) => this.formRefundRef.add(ref)}
                />
              </div>
            </div>
          </div>
        )}

        <div className="ladiui form-group" style={{ marginTop: 25, textAlign: "right", display: "flex", justifyContent: "end" }}>
          <input
            id="restock"
            type="checkbox"
            className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
            onChange={() => {
              if (!this.state.restock) {
                if (totalQuantity <= 0) {
                  return;
                }
              }
              this.setState({
                restock: !this.state.restock,
              });
            }}
            checked={restock}
          />
          <label htmlFor="restock"> {t("ORDERS.RESTOCK_PRODUCT", totalQuantity)}</label>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    suggest: (data) => dispatch(orderActions.suggestRefund(data)),
    refund: (data) => dispatch(orderActions.refund(data)),
  };
};

const mapStateToProps = (state) => ({
  order: { ...state.order },
  storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalRefund));
