import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import QrReader from "react-web-qr-reader";

export default function CheckQrcode(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleScan = (result) => {
    if (result.data) {
      let urlDecode = result.data;

      let ticketsSeatCode = urlDecode.split("?ticket_seat_code=");
      let ticketSeatCodeUrl = ticketsSeatCode[ticketsSeatCode.length - 1];

      let code = urlDecode.split("?code=");
      let codeUrl = code[code.length - 1];

      let url = `/checkin/info-handle?ticket_seat_code=${ticketSeatCodeUrl || codeUrl}`;

      if (url) {
        window.location.href = url;
      }
    }
  };

  const handleError = (error) => {
    console.log(error);
  };

  return (
    <>
      <div className="page-content page-checkin-content p-0">
        <div className="app-test">
          <QrReader delay={500} style={{ width: "100%", height: "100%" }} onError={handleError} onScan={handleScan} />
        </div>
      </div>
    </>
  );
}
