import * as types from "./types";
import * as commonTypes from "../common/types";

export default (state = { totalQuantity: 0 }, action) => {
  switch (action.type) {
    case commonTypes.ASYNC_START: {
      if (!types[action.waiting]) {
        return state;
      }

      return {
        ...state,
        loading: true,
        waiting: action.waiting,
        action: commonTypes.ASYNC_START,
      };
    }
    case commonTypes.ASYNC_END: {
      if (!types[action.done]) {
        return state;
      }

      return {
        ...state,
        waiting: null,
        loading: false,
        action: commonTypes.ASYNC_END,
        done: action.done,
      };
    }
    case types.LIST_ORDER: {
      return {
        ...state,
        action: types.LIST_ORDER,
        orders: action.status ? action.payload.orders : [],
        total: action.status ? action.payload.total : 0,
        totalRecord: action.status ? action.payload.total_record : 0,
        totalPage: action.status ? action.payload.total_page : 0,
        totalDuplication: action.status ? action.payload.total_duplication : 0,
        status: action.status,
        message: action.message,
      };
    }
    case types.DELETE_ORDER: {
      return {
        ...state,
        action: types.DELETE_ORDER,
        status: action.status,
        message: action.message,
      };
    }
    case types.CANCEL_ORDER: {
      return {
        ...state,
        action: types.CANCEL_ORDER,
        status: action.status,
        message: action.message,
      };
    }
    case types.CANCEL_ORDERS: {
      return {
        ...state,
        action: types.CANCEL_ORDERS,
        status: action.status,
        message: action.message,
      };
    }
    case types.UPDATE_ORDER_STATUS: {
      return {
        ...state,
        action: types.UPDATE_ORDER_STATUS,
        status: action.status,
        message: action.message,
      };
    }
    case types.CREATE_ORDER: {
      return {
        ...state,
        action: types.CREATE_ORDER,
        status: action.status,
        order_id: action.status ? action.payload.order.order_id : {},
        message: action.message,
      };
    }
    case types.GET_LINK_CHECKOUT_CREATE: {
      return {
        ...state,
        action: types.GET_LINK_CHECKOUT_CREATE,
        status: action.status,
        order_id: action.status ? action.payload.order.order_id : {},
        message: action.message,
      };
    }
    case types.UPDATE_ORDER: {
      return {
        ...state,
        action: types.UPDATE_ORDER,
        status: action.status,
        message: action.message,
      };
    }
    case types.SHOW_ORDER: {
      return {
        ...state,
        action: types.SHOW_ORDER,
        order: action.status ? action.payload.order : {},
        order_not_show: action.payload.order_not_show,
        status: action.status,
        message: action.message,
      };
    }
    case types.GENERATE_CHECKOUT_ORDER: {
      return {
        ...state,
        action: types.GENERATE_CHECKOUT_ORDER,
        order: action.status
          ? {
              ...state.order,
              is_generate_checkout: true,
              checkout_token: action.payload.checkout_token,
            }
          : state.order,
        status: action.status,
        message: action.message,
      };
    }
    case types.FULLFILL_ORDER: {
      return {
        ...state,
        action: types.FULLFILL_ORDER,
        status: action.status,
        message: action.message,
      };
    }
    case types.RELOAD_ORDER: {
      return {
        ...state,
        action: types.RELOAD_ORDER,
        order: action.status ? action.payload.order : {},
        order_not_show: action.payload.order_not_show,
        status: action.status,
        message: action.message,
      };
    }
    case types.WS_APPEND_NEW_ORDER: {
      return {
        ...state,
        action: types.WS_APPEND_NEW_ORDER,
        orders: action.payload.orders,
        totalRecord: action.payload.total_record,
        status: action.status,
        message: action.message,
      };
    }
    case types.UPDATE_ORDER_CUSTOMER_INFO: {
      return {
        ...state,
        action: types.UPDATE_ORDER_CUSTOMER_INFO,
        status: action.status,
        message: action.message,
      };
    }
    case types.ASSIGN_ORDER: {
      return {
        ...state,
        action: types.ASSIGN_ORDER,
        status: action.status,
        message: action.message,
        assigneeID: action.status ? action.payload.assignee_id : null,
      };
    }
    case types.LIST_MY_ORDER: {
      return {
        ...state,
        action: types.LIST_MY_ORDER,
        orders: action.status ? action.payload.orders : [],
        totalRecord: action.status ? action.payload.total_record : 0,
        totalPage: action.status ? action.payload.total_page : 0,
        totalDuplication: action.status ? action.payload.total_duplication : 0,
        total: action.status ? action.payload.total : 0,
        status: action.status,
        message: action.message,
      };
    }
    case types.PARTIAL_UPDATE_ORDER: {
      return {
        ...state,
        action: types.PARTIAL_UPDATE_ORDER,
        status: action.status,
        message: action.message,
      };
    }
    case types.REFUND_ORDER: {
      return {
        ...state,
        action: types.REFUND_ORDER,
        status: action.status,
        message: action.message,
      };
    }
    case types.SUGGEST_REFUND: {
      return {
        ...state,
        action: types.SUGGEST_REFUND,
        orderDetails: action.status ? action.payload.order_details : [],
        subTotal: action.status ? action.payload.sub_total : 0,
        availableShippingRefund: action.status ? action.payload.available_shipping_refund : 0,
        shippingRefund: action.status ? action.payload.shipping_refund : 0,
        discountFee: action.status ? action.payload.discount_fee : 0,
        availableRefund: action.status ? action.payload.available_refund : 0,
        totalQuantity: action.status ? action.payload.total_quantity : 0,
        refund: action.status ? action.payload.refund : 0,
        status: action.status,
        message: action.message,
      };
    }
    case types.UPDATE_ORDER_ADDRESS: {
      return {
        ...state,
        action: types.UPDATE_ORDER_ADDRESS,
        status: action.status,
        message: action.message,
      };
    }
    case types.SHIPPING_UPDATE_ORDER_ADDRESS: {
      return {
        ...state,
        action: types.SHIPPING_UPDATE_ORDER_ADDRESS,
        status: action.status,
        message: action.message,
      };
    }
    case types.EXPORT_ORDER: {
      return {
        ...state,
        action: types.EXPORT_ORDER,
        status: action.status,
        message: action.message,
      };
    }
    case types.EXPORT_TRANSACTION: {
      return {
        ...state,
        action: types.EXPORT_TRANSACTION,
        status: action.status,
        message: action.message,
      };
    }
    case types.ASSIGN_TO_OTHER_ORDER: {
      return {
        ...state,
        action: types.ASSIGN_TO_OTHER_ORDER,
        status: action.status,
        message: action.message,
      };
    }
    case types.MARK_AS_PAID: {
      return {
        ...state,
        action: types.MARK_AS_PAID,
        status: action.status,
        message: action.message,
      };
    }
    case types.MARK_AS_PAIDS: {
      return {
        ...state,
        action: types.MARK_AS_PAIDS,
        status: action.status,
        message: action.message,
      };
    }
    case types.WS_ASSIGNED_ORDER: {
      return {
        ...state,
        action: types.WS_ASSIGNED_ORDER,
        orders: action.payload.orders,
      };
    }
    case types.WS_UPDATED_ORDER: {
      return {
        ...state,
        action: types.WS_UPDATED_ORDER,
        orders: action.payload.orders,
      };
    }
    case types.UPDATE_TAG: {
      return {
        ...state,
        action: types.UPDATE_TAG,
        status: action.status,
        message: action.message,
      };
    }
    case types.UPDATE_SPAMS: {
      return {
        ...state,
        action: types.UPDATE_SPAMS,
        status: action.status,
        message: action.message,
      };
    }
    case types.REMOVE_DUPLICATION_ORDER: {
      return {
        ...state,
        action: types.REMOVE_DUPLICATION_ORDER,
        status: action.status,
        message: action.message,
      };
    }
    case types.RESEND_TICKET_LIST: {
      return {
        ...state,
        action: types.RESEND_TICKET_LIST,
        status: action.status,
        message: action.message,
      };
    }
    default:
      return state;
  }
};
