import { REQUEST_METHOD } from "../../middlewares/services";

import * as types from "./types";
import { endpoint } from "../../../config/app";

const list = (data) => ({
  type: types.LIST_ORDER,
  meta: {
    endpoint: endpoint.ORDER_LIST,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const deleteOrder = (orderID) => ({
  type: types.DELETE_ORDER,
  meta: {
    endpoint: endpoint.ORDER_DELETE_ID,
    method: REQUEST_METHOD.POST,
    body: {
      order_id: orderID,
    },
    hasAuth: true,
  },
});

const create = (order) => ({
  type: types.CREATE_ORDER,
  meta: {
    endpoint: endpoint.ORDER_CREATE,
    method: REQUEST_METHOD.POST,
    body: {
      order,
    },
    hasAuth: true,
  },
});

const getLinkCheckout = (order) => ({
  type: types.GET_LINK_CHECKOUT_CREATE,
  meta: {
    endpoint: endpoint.ORDER_CREATE,
    method: REQUEST_METHOD.POST,
    body: {
      order,
    },
    hasAuth: true,
  },
});

const update = (order) => ({
  type: types.UPDATE_ORDER,
  meta: {
    endpoint: endpoint.ORDER_UPDATE,
    method: REQUEST_METHOD.POST,
    body: {
      order,
    },
    hasAuth: true,
  },
});

const show = (orderID) => ({
  type: types.SHOW_ORDER,
  meta: {
    endpoint: endpoint.ORDER_SHOW,
    method: REQUEST_METHOD.POST,
    body: {
      order_id: orderID,
    },
    hasAuth: true,
  },
});

const fullfill = (data) => ({
  type: types.FULLFILL_ORDER,
  meta: {
    endpoint: endpoint.FULLFILL_ORDER,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const reload = (orderID) => ({
  type: types.RELOAD_ORDER,
  meta: {
    endpoint: endpoint.ORDER_SHOW,
    method: REQUEST_METHOD.POST,
    body: {
      order_id: orderID,
    },
    hasAuth: true,
  },
});

const appendNewOrder = (orders, totalRecord) => ({
  type: types.WS_APPEND_NEW_ORDER,
  payload: {
    orders,
    total_record: totalRecord,
  },
});

const updateOrderCustomerInfo = (orderID, email, phone) => ({
  type: types.UPDATE_ORDER_CUSTOMER_INFO,
  meta: {
    endpoint: endpoint.UPDATE_ORDER_CUSTOMER_INFO,
    method: REQUEST_METHOD.POST,
    body: {
      order_id: orderID,
      email,
      phone,
    },
    hasAuth: true,
  },
});

const assign = (orderID) => ({
  type: types.ASSIGN_ORDER,
  meta: {
    endpoint: endpoint.ASSIGN_ORDER,
    method: REQUEST_METHOD.POST,
    body: {
      order_id: orderID,
    },
    hasAuth: true,
  },
});

const listMyOrders = (data) => ({
  type: types.LIST_MY_ORDER,
  meta: {
    endpoint: endpoint.LIST_MY_ORDER,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const partialUpdate = (order) => ({
  type: types.PARTIAL_UPDATE_ORDER,
  meta: {
    endpoint: endpoint.PARTIAL_UPDATE_ORDER,
    method: REQUEST_METHOD.POST,
    body: {
      order,
    },
    hasAuth: true,
  },
});

const refund = (data) => ({
  type: types.REFUND_ORDER,
  meta: {
    endpoint: endpoint.REFUND_ORDER,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const suggestRefund = (data) => ({
  type: types.SUGGEST_REFUND,
  meta: {
    endpoint: endpoint.SUGGEST_REFUND,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const cancel = (orderID, reason) => ({
  type: types.CANCEL_ORDER,
  meta: {
    endpoint: endpoint.CANCEL_ORDER,
    method: REQUEST_METHOD.POST,
    body: {
      order_id: orderID,
      reason,
    },
    hasAuth: true,
  },
});

const cancels = (orderIDs, reason) => ({
  type: types.CANCEL_ORDERS,
  meta: {
    endpoint: endpoint.CANCEL_ORDERS,
    method: REQUEST_METHOD.POST,
    body: {
      order_ids: orderIDs,
      reason,
    },
    hasAuth: true,
  },
});

const updateAddress = (data) => ({
  type: types.UPDATE_ORDER_ADDRESS,
  meta: {
    endpoint: endpoint.UPDATE_ORDER_ADDRESS,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const updateShippingAddress = (data) => ({
  type: types.SHIPPING_UPDATE_ORDER_ADDRESS,
  meta: {
    endpoint: endpoint.UPDATE_ORDER_ADDRESS,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const exportOrder = (data) => ({
  type: types.EXPORT_ORDER,
  meta: {
    endpoint: endpoint.EXPORT_ORDER,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const exportTransaction = (data) => ({
  type: types.EXPORT_TRANSACTION,
  meta: {
    endpoint: endpoint.EXPORT_TRANSACTION,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const markAsPaid = (orderID, paymentGatewayCode, paymentType) => ({
  type: types.MARK_AS_PAID,
  meta: {
    endpoint: endpoint.MARK_AS_PAID,
    method: REQUEST_METHOD.POST,
    body: {
      order_id: orderID,
      payment_gateway_code: paymentGatewayCode,
      payment_type: paymentType,
    },
    hasAuth: true,
  },
});

const assignedOrder = (orders) => ({
  type: types.WS_ASSIGNED_ORDER,
  payload: {
    orders,
  },
});

const assignToOther = (orderID, assigneeID) => ({
  type: types.ASSIGN_TO_OTHER_ORDER,
  meta: {
    endpoint: endpoint.ASSIGN_TO_OTHER_ORDER,
    method: REQUEST_METHOD.POST,
    body: {
      order_id: orderID,
      assignee_id: assigneeID,
    },
    hasAuth: true,
  },
});

const updateTag = (data) => ({
  type: types.UPDATE_TAG,
  meta: {
    endpoint: endpoint.UPDATE_TAG,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const updateSpams = (data) => ({
  type: types.UPDATE_SPAMS,
  meta: {
    endpoint: endpoint.UPDATE_SPAMS,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const removeDuplication = (orderID) => ({
  type: types.REMOVE_DUPLICATION_ORDER,
  meta: {
    endpoint: endpoint.REMOVE_DUPLICATION_ORDER,
    method: REQUEST_METHOD.POST,
    body: {
      order_id: orderID,
    },
    hasAuth: true,
  },
});

const generateCheckout = (orderID) => ({
  type: types.GENERATE_CHECKOUT_ORDER,
  meta: {
    endpoint: endpoint.GENERATE_CHECKOUT_ORDER,
    method: REQUEST_METHOD.POST,
    body: {
      order_id: orderID,
    },
    hasAuth: true,
  },
});

const markAsPaids = (data) => ({
  type: types.MARK_AS_PAIDS,
  meta: {
    endpoint: endpoint.MARK_AS_PAIDS,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const resendTicketList = (data) => ({
  type: types.RESEND_TICKET_LIST,
  meta: {
    endpoint: endpoint.RESEND_TICKET_LIST,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

export default {
  list,
  delete: deleteOrder,
  create,
  getLinkCheckout,
  update,
  show,
  fullfill,
  reload,
  appendNewOrder,
  updateOrderCustomerInfo,
  assign,
  listMyOrders,
  partialUpdate,
  refund,
  suggestRefund,
  cancel,
  cancels,
  updateAddress,
  updateShippingAddress,
  exportOrder,
  exportTransaction,
  assignedOrder,
  markAsPaid,
  assignToOther,
  updateTag,
  updateSpams,
  removeDuplication,
  generateCheckout,
  markAsPaids,
  resendTicketList,
};
