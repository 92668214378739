import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import baseHelper from '../../../../helpers/BaseHelper';
import Image from '../../../../components/Image';

import productActions from '../../../../redux/futures/product/actions';
import * as productTypes from '../../../../redux/futures/product/types';

import ModalAddProduct from '../../../orders/OrderCreate/components/ModalAddProduct';

import appConfig from '../../../../config/app';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { useHistory, useLocation } from "react-router-dom";

// eslint-disable-next-line max-lines-per-function
function ModalProduct(props) {
    const { t, onClose, isShow, innerStyle, selectedDiscount, dataSelect, selectedIndex, handleAddContentLocation } = props;
    const wrapperRef = useRef(null);


    // useEffect(() => {
    //   function handleClickOutside(event) {
    //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
    //       onClose(false);
    //     }
    //   }
    //   document.addEventListener("mousedown", handleClickOutside);
    //   return () => {
    //     document.removeEventListener("mousedown", handleClickOutside);
    //   };
    // }, [wrapperRef]);

    const productReducer = useSelector((state) => state.product);

    const [isShowModalAddProduct, setIsShowModalAddProduct] = useState(false);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const [valueSearchProduct, setValueSearchProduct] = useState('');

    const dispatch = useDispatch();

    const [dataConfig, setDataConfig] = useState({
        title: 'Sản phẩm đề xuất',
        is_show_star: false,
        layout_type: 'Carousel',
    });

    const eventCancel = () => {
        onClose(false);
    };

    useEffect(() => {
        let pageCheckoutID = props.selectedPageCheckoutId;
        if (pageCheckoutID) {
            let productTypes = ['Physical', 'Event', 'Service', 'F_B', 'COMBO', 'Digital'];
            dispatch(productActions.search('', '', null, '', productTypes, pageCheckoutID));
        }
    }, []);

    useEffect(() => {
        if (productReducer.action == productTypes.SEARCH_PRODUCT) {
            if (productReducer.status) {
                if (productReducer.products && productReducer.products.length > 0) {
                    setProducts(productReducer.products);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
    }, [productReducer]);

    useEffect(() => {
        if (dataSelect) {
            setDataConfig({
                title: dataSelect.title || dataConfig.title,
                is_show_star: dataSelect.is_show_star,
                layout_type: dataSelect.layout_type ? dataSelect.layout_type : "Carousel",
            });

            setSelectedProducts(dataSelect.values || []);
        }
    }, [dataSelect]);

    useEffect(() => {
        if (valueSearchProduct) {
            let productTypes = ['Physical', 'Event', 'Service', 'F_B', 'COMBO', 'Digital'];
            dispatch(productActions.search(valueSearchProduct, '', null, '', productTypes, props.selectedPageCheckoutId));
        }
    }, [valueSearchProduct]);

    const onChangeTitle = (e) => {
        let { value } = e.target;

        setDataConfig({
            ...dataConfig,
            title: value,
        });
    };

    const handleDeleteProduct = (productId) => {
        let _selectedProducts = [...selectedProducts];

        if (productId) {
            _selectedProducts = _selectedProducts.filter((item) => item.product_id != productId);
        }

        setSelectedProducts(_selectedProducts);
    };

    const getPriceProduct = (item) => {
        let price = 0;

        if (item.min_price && item.max_price) {
            if (item.min_price == item.max_price) {
                price = baseHelper.formatMoneyPostFix(item.max_price);
            } else {
                price = baseHelper.formatMoneyPostFix(item.min_price) + ' - ' + baseHelper.formatMoneyPostFix(item.max_price);
            }
        } else {
            price = baseHelper.formatMoneyPostFix(item.max_price);
        }
        return price;
    };

    const handleProductEdit = (type, productID) => {
        if (type)
            switch (type) {
                case appConfig.PRODUCT_TYPE.SERVICE.CODE:
                    window.open(`/services/update/${productID}`, '_blank', 'noopener,noreferrer');
                    break;
                case appConfig.PRODUCT_TYPE.DIGITAL.CODE:
                    window.open(`/digital/update/${productID}`, '_blank', 'noopener,noreferrer');
                    break;
                case appConfig.PRODUCT_TYPE.EVENT.CODE:
                    window.open(`/events/update/${productID}`, '_blank', 'noopener,noreferrer');
                    break;
                case appConfig.PRODUCT_TYPE.COURSE.CODE:
                    window.open(`/courses/update/${productID}`, '_blank', 'noopener,noreferrer');
                    break;
                default:
                    window.open(`/products/update/${productID}`, '_blank', 'noopener,noreferrer');
                    break;
            }
    };

    const listProductItem = () => {
        return selectedProducts.map((item, index) => {
            return (
                <Draggable key={index} draggableId={`${index}`} index={index}>
                    {(provided, snapshot) => (
                        <tr
                            className='ladiui table-vertical'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                                ...provided.draggableProps.style,
                                userSelect: 'none',
                                background: snapshot.isDragging ? '#e8f0fe' : 'none',
                            }}
                        >
                            <td>
                                <div className='flex'>
                                    <img {...provided.dragHandleProps} src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg'} />
                                </div>
                            </td>
                            <td>
                                <div className="w-310 product-name cursor-pointer" onClick={() => handleProductEdit(item.type, item.product_id)}>{item.name}</div>
                            </td>
                            <td>{getPriceProduct(item)}</td>
                            <td className='text-right pd-0' onClick={() => handleDeleteProduct(item.product_id)}>
                                <a>
                                    <i className='ladi-icon icon-bin' />
                                </a>
                            </td>
                        </tr>
                    )}
                </Draggable>
            );
        });
    };

    const selectProduct = (product) => {
        let _selectedProducts = [...selectedProducts];

        if (product) {
            _selectedProducts.push(product);
        }

        setSelectedProducts(_selectedProducts);
    };

    const onChangeSearchValueProduct = async (event) => {
        let value = event.target.value;
        await setValueSearchProduct(value);
    };

    const handleAddContentProduct = () => {
        let data = {
            type: 'PRODUCT',
            ...dataConfig,
            // values: selectedProducts && selectedProducts.length > 0 ? selectedProducts.map((item) => item.product_id) : [],
            values: selectedProducts && selectedProducts.length > 0 ? selectedProducts : [],
        };
        handleAddContentLocation(data, selectedIndex);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    const onDragEndSelectedProduct = (result) => {
        if (!result.destination || result.source.index == result.destination.index) {
            return;
        }
        const items = reorder(selectedProducts, result.source.index, result.destination.index);
        setSelectedProducts(items);
    };

    return (
        <>
            <div className={`ladiui modal ${isShow ? 'show' : ''}`} id='homepage-add-product-modal'>
                <div className='ladiui modal-dialog modal-dialog-centered w-600' ref={wrapperRef}>
                    <div className='ladiui modal-content '>
                        <div className='ladiui modal-header '>
                            <h2 className='ladiui modal-title '>Danh sách sản phẩm</h2>
                            <button data-dismiss='modal' data-target='add-product' onClick={eventCancel} className='ladiui modal-close'>
                                <i className='ladiui icon icon-ldp-close'></i>
                            </button>
                        </div>
                        <div className='ladiui modal-body'>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>Tiêu đề</label>
                                <input
                                    className='ladiui form-control'
                                    value={dataConfig.title}
                                    placeholder='Nhập vào tiêu đề'
                                    onChange={onChangeTitle}
                                />
                            </div>
                            <div className={`list-products mt-24`}>
                                <h3 className='ladiui-label'>Danh sách sản phẩm</h3>
                                {selectedProducts && selectedProducts.length > 0 && (
                                    <DragDropContext onDragEnd={onDragEndSelectedProduct}>
                                        <Droppable droppableId='droppable'>
                                            {(provided, snapshot) => (
                                                <table
                                                    className='ladiui table panel-package table-custom'
                                                    ref={provided.innerRef}
                                                    style={{
                                                        background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                                    }}
                                                >
                                                    <thead>
                                                        <tr className='ladiui table-vertical header'>
                                                            <th className='text-left' />
                                                            <th className='text-left'>Tên sản phẩm</th>
                                                            <th className='text-left'>Giá sản phẩm</th>
                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {listProductItem()}
                                                        {provided.placeholder}
                                                    </tbody>
                                                </table>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                )}
                            </div>

                            <div className='cursor-pointer' style={innerStyle}>
                                <a className='flex-center-left cursor-pointer mt-24 pd-0' onClick={() => setIsShowModalAddProduct(true)}>
                                    <img className='mr-8' src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg'} alt='' />
                                    <div>Thêm sản phẩm</div>
                                </a>
                            </div>

                            <div className='block-display-type'>
                                <h3 className='ladiui-label'>Bố cục hiển thị</h3>
                                <div className='block-content'>
                                    <div
                                        className={`block-item left ${dataConfig.layout_type == 'Carousel' ? 'active' : ''}`}
                                        onClick={() => {
                                            setDataConfig({
                                                ...dataConfig,
                                                layout_type: 'Carousel',
                                            });
                                        }}
                                    >
                                        <div className='list-pr-exam'>
                                            <div className='pr-item'>
                                                <div className='top'></div>
                                                <div className='bottom'></div>
                                            </div>
                                            <div className='pr-item'>
                                                <div className='top'></div>
                                                <div className='bottom'></div>
                                            </div>
                                            <div className='pr-item'>
                                                <div className='top'></div>
                                                <div className='bottom'></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`block-item right ${dataConfig.layout_type == 'Grid' ? 'active' : ''}`}
                                        onClick={() => {
                                            setDataConfig({
                                                ...dataConfig,
                                                layout_type: 'Grid',
                                            });
                                        }}
                                    >
                                        <div className='list-pr-exam'>
                                            <div className='pr-item'>
                                                <div className='top'></div>
                                                <div className='bottom'></div>
                                            </div>
                                            <div className='pr-item'>
                                                <div className='top'></div>
                                                <div className='bottom'></div>
                                            </div>
                                            <div className='pr-item'>
                                                <div className='top'></div>
                                                <div className='bottom'></div>
                                            </div>
                                            <div className='pr-item'>
                                                <div className='top'></div>
                                                <div className='bottom'></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex mt-24'>
                                <div className='ladiui item-form switch'>
                                    <label className='ladiui switch m-0'>
                                        <input
                                            type='checkbox'
                                            checked={dataConfig.is_show_star}
                                            onChange={() => {
                                                setDataConfig({
                                                    ...dataConfig,
                                                    is_show_star: !dataConfig.is_show_star,
                                                });
                                            }}
                                        />
                                        <span className='ladiui slider round'></span>
                                    </label>
                                </div>
                                <div className='ml-8'>
                                    <span className='ladiui title-text-form-lever2 mb-0'>Đánh dấu sản phẩm nổi bật</span>
                                </div>
                            </div>
                        </div>

                        <div className='ladiui modal-footer '>
                            <div className='ladiui footer-modal-btn'>
                                <button type='button' className='ladiui btn btn-secondary bold' onClick={eventCancel}>
                                    {t('ACTIONS.CLOSE')}
                                </button>
                                <button type='button' className='ladiui btn btn-primary' onClick={() => handleAddContentProduct()}>
                                    {selectedIndex >= 0 ? 'Cập nhật' : 'Thêm nội dung'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isShowModalAddProduct && (
                <ModalAddProduct
                    onClose={() => setIsShowModalAddProduct(false)}
                    isShow={isShowModalAddProduct}
                    listVariant={products || []}
                    innerStyle={{ display: 'none' }}
                    selectVariant={selectProduct}
                    isSelectedTypeProduct={true}
                    selectedProducts={selectedProducts || []}
                    searchVariantValue={valueSearchProduct}
                    onChangeSearchVariantInput={onChangeSearchValueProduct}
                />
            )}
        </>
    );
}

export default withTranslation()(ModalProduct);
