import React from "react";

import { connect } from "react-redux";

import { map, find } from "lodash";
import appConfig from "../../config/app";
import Pagination from "react-js-pagination";
import baseHelper from "../../helpers/BaseHelper";

import * as types from "../../redux/futures/checkout/types";
import checkoutActions from "../../redux/futures/checkout/actions";

import LoadingSence from "../../components/LoadingScene";
import Image from "../../components/Image";
import PanelCustomer from "../orders/components/OrderCustomer";
import PanelShippingAddress from "../orders/components/ShippingAddress";
import { withTranslation } from "react-i18next";
import Input from "../../components/Input";

class CheckoutEdit extends React.Component {
  constructor(props) {
    super(props);

    this.moment = baseHelper.getMoment();

    this.state = {
      customer_note: "",
    };

    this.sortBy = {};
  }

  componentDidMount() {
    this.props.show(this.props.match.params["checkoutID"]);
  }

  componentDidUpdate() {
    window.LadiUI.init();
    window.LadiUI.customInit();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props === nextProps) {
      return;
    }

    if (this.props.checkoutReducer.action != nextProps.checkoutReducer.action) {
      if (nextProps.checkoutReducer.action === types.SHOW_CHECKOUT) {
        if (nextProps.checkoutReducer.status) {
          this.setState({
            customer_note: nextProps.checkoutReducer.checkout.customer_note,
          });
        } else {
          this.props.history.push("/checkouts");
        }
      }

      if (nextProps.checkoutReducer.action === types.UPDATE_CHECKOUT) {
        if (nextProps.checkoutReducer.status) {
          window.LadiUI.toastCustom("success", "", nextProps.checkoutReducer.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.checkoutReducer.message, "OK");
        }
      }

      if (nextProps.checkoutReducer.action === types.SEND_NOTIFICATION_CHECKOUT) {
        if (nextProps.checkoutReducer.status) {
          this.props.reload(this.props.checkoutReducer.checkout.checkout_id);
          window.LadiUI.toastCustom("success", "", nextProps.checkoutReducer.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.checkoutReducer.message, "OK");
        }
      }
    }
  }

  /*******************************SEARCH***************************/
  onChangeInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  getStatus = (status) => {
    const found = find(appConfig.CHECKOUT.STATUS, (item) => item.CODE == status);
    if (found) {
      return found.NAME;
    }
  };

  getMoreInfo = () => {
    const { t } = this.props;
    const { checkout = {} } = this.props.checkoutReducer;

    const copy = (text) => {
      baseHelper.copyToClipboard(text);
      window.LadiUI.toastCustom("success", "", this.props.t("COMMON.COPIED"));
    };

    return (
      <div className="more-info order-customer block-tracking-info pr-0">
        <div className="item-tracking" data-tooltip={checkout.ip} data-tooltip-position="bottom">
          <span>
            <i onClick={() => copy(checkout.ip)} className="ladi-icon icon-copy" />
            <b>{t("ORDERS.IP")}:</b>
          </span>
          <span className="show-content">{checkout.ip}</span>
        </div>
        <div className="item-tracking" data-tooltip={checkout.url_page} data-tooltip-position="bottom">
          <span>
            <i onClick={() => copy(checkout.url_page)} className="ladi-icon icon-copy" />
            <b>{t("ORDERS.URL_PAGE")}:</b>
          </span>
          <a target="_blank" href={checkout.url_page}>
            <span className="show-content">{checkout.url_page}</span>
          </a>
        </div>
      </div>
    );
  };

  update = () => {
    const checkoutID = this.props.match.params["checkoutID"];
    this.props.update({
      checkout_id: checkoutID,
      customer_note: this.state.customer_note,
    });
  };

  /****************************************************************/
  render() {
    const { t } = this.props;
    const isLoadingData =
      this.props.checkoutReducer.loading &&
      [types.SHOW_CHECKOUT, types.SEND_NOTIFICATION_CHECKOUT].includes(this.props.checkoutReducer.waiting);
    const isLoadingUpdate = this.props.checkoutReducer.loading && [types.UPDATE_CHECKOUT].includes(this.props.checkoutReducer.waiting);

    const { checkout = [] } = this.props.checkoutReducer;
    const { details: details = [] } = checkout;
    const customer = {
      customer_id: checkout.customer_id,
      email: checkout.customer_email,
      phone: checkout.customer_phone,
      first_name: checkout.customer_first_name,
      last_name: checkout.customer_last_name,
      total_order: checkout.total_order,
    };
    const address = {
      first_name: checkout.customer_first_name,
      last_name: checkout.customer_last_name,
      phone: checkout.customer_phone,
      address: checkout.customer_address,
      country_code: checkout.country_code,
      country_name: checkout.country_name,
      state_id: checkout.state_id,
      state_name: checkout.state_name,
      district_id: checkout.district_id,
      district_name: checkout.district_name,
      ward_id: checkout.ward_id,
      ward_name: checkout.ward_name,
      postal_code: checkout.postal_code,
    };

    let urlEmail = `${baseHelper.domainToUrl(this.props.storeReducer.userInfo.default_domain || "checkout.ladi.sale")}/checkout/${
      checkout.checkout_token
    }`;

    if (checkout.url_page) {
      let urlPage = new URL(checkout.url_page).searchParams;
      const checkout_config_id = urlPage.get("checkout_config_id");

      if (checkout_config_id) {
        urlEmail += `?checkout_config_id=${checkout_config_id}`;
      }
    }

    return (
      <div className="page-content">
        {isLoadingData && <LoadingSence blur={true} />}
        <div className="ladiui content-main-title flex-row">
          <div className="ladiui flex-row">
            <div className="ladiui main-title mr-12">{`${t("CHECKOUTS.TITLE")} #${baseHelper.getText(checkout.checkout_id)}`}</div>

            <span className="order-status mr-12">{this.getStatus(checkout.status)}</span>
            <span className="placed-on flex mr-12">
              <i className="ladi-icon icon-calendar-date-2 mr-8" />
              <span>{baseHelper.formatStrToDate(checkout.created_at)}</span>
            </span>
          </div>
          <button className={`ladiui btn btn-primary ${isLoadingUpdate && "loader"}`} disabled={isLoadingUpdate} onClick={this.update}>
            <img src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-save-data.svg"} alt="" style={{ marginRight: "8px" }} />
            <div className="ladiui btn-custom-text ">{t("ORDERS.SAVE")}</div>
          </button>
        </div>

        {!checkout && <div className="email-confirm" />}

        {checkout.email_status == appConfig.CHECKOUT.EMAIL_STATUS.NOT_SEND.CODE && (
          <div className="email-confirm">
            <span className="title">{t("CHECKOUTS.SEND_EMAIL_CONFIRM")}</span>
            <Input value={checkout && checkout.checkout_url ? checkout.checkout_url : urlEmail} disabled={true} className="input-custom" />
            {checkout.customer_email && (
              <button className="ladiui btn btn-outline-primary" onClick={() => this.props.sendNotification(checkout.checkout_id)}>
                {t("CHECKOUTS.BTN_SEND_EMAIL")}
              </button>
            )}
          </div>
        )}

        {checkout.email_status == appConfig.CHECKOUT.EMAIL_STATUS.SENT.CODE && (
          <div className="email-confirm sent">
            <span className="title">{t("CHECKOUTS.SEND_EMAIL_SUCCESS")}</span>
            <Input value={urlEmail} disabled={true} />
          </div>
        )}

        <div className="incomplete-order">
          <div className="ladiui-order-left">
            <div className="ladi-card order-products" style={{ marginBottom: 20 }}>
              <div>
                {details.map((item, index) => {
                  let name = item.product_name;
                  if (item.option_name) {
                    name = `${name} ${item.option_name}`;
                  }
                  const hasDiscount = item.discount_value > 0 ? true : false;
                  let lastPrice = item.price;
                  if (hasDiscount) {
                    lastPrice -= item.discount_fee;
                  }
                  return (
                    <div className={`item-variant ${index == 0 ? "border-none" : ""}`} key={index}>
                      <div className="w-100">
                        <Image src={item.src} width={82} height={82} />
                      </div>

                      <div className="w-name-product">
                        <div className="item-variant-name-info">
                          {item.product_id ? (
                            <a className="item-title" href={baseHelper.getUrlVariant(item)}>
                              {name}
                            </a>
                          ) : (
                            <span className="item-title">{name}</span>
                          )}
                          <div className="item-variant-list-option">
                            {item.options &&
                              item.options.map((option, index) => {
                                return (
                                  <div key={index}>
                                    <span className="option-name">{option.option_name}: </span>
                                    <span className="option-value">{option.option_value_value}</span>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>

                      <div className="w-200 flex">
                        {hasDiscount ? (
                          <div>
                            <span className="price-before-discount">
                              {baseHelper.formatMoneyPostFix(item.price, this.props.storeReducer.userInfo.currentStore.currency_symbol)}
                            </span>
                            <span data-tooltip={item.discount_note} data-tooltip-position="top">
                              <a>
                                <div className="price-option">
                                  {baseHelper.formatMoneyPostFix(lastPrice, this.props.storeReducer.userInfo.currentStore.currency_symbol)}
                                </div>
                              </a>
                            </span>
                          </div>
                        ) : (
                          <span className="price-option">
                            {baseHelper.formatMoneyPostFix(item.price, this.props.storeReducer.userInfo.currentStore.currency_symbol)}
                          </span>
                        )}
                      </div>
                      <div className="w-120 mt-0">{item.quantity}</div>
                      <div className="w-190 text-end item-title">
                        {baseHelper.formatMoneyPostFix(item.total, this.props.storeReducer.userInfo.currentStore.currency_symbol)}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="footer-order">
                <div className="w-49-p">
                  <div className="title-lable mb-10">{t("COMMON.NOTE")}</div>
                  <textarea
                    className="ladiui textarea"
                    value={this.state.customer_note || ""}
                    name="customer_note"
                    onChange={(event) => {
                      this.setState({
                        customer_note: event.target.value,
                      });
                    }}
                  />
                </div>

                <div className="w-49-p">
                  <div className="item-info">
                    <div className="flex">
                      <img src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-dola.svg"} alt="" />
                      <span className="footer-lable">{t("ORDERS.SUB_TOTAL")}</span>
                    </div>
                    <div>
                      <span>
                        {baseHelper.formatMoneyPostFix(checkout.sub_total, this.props.storeReducer.userInfo.currentStore.currency_symbol)}
                      </span>
                    </div>
                  </div>

                  <div className="item-info">
                    <div className="flex">
                      <img src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-discount.svg"} alt="" />
                      <span className="footer-lable mr-8">{t("ORDERS.DISCOUNT")}</span>
                      <span className="discount-note">{checkout.discount_note || checkout.discount_code}</span>
                    </div>
                    <div>
                      <span>
                        {baseHelper.formatMoneyPostFix(
                          checkout.discount_fee,
                          this.props.storeReducer.userInfo.currentStore.currency_symbol
                        )}
                      </span>
                    </div>
                  </div>

                  {checkout.package_discount_value > 0 && (
                    <div className="item-info">
                      <div className="flex">
                        <img src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-discount.svg"} alt="" />
                        <span className="footer-lable mr-8">{checkout.package_discount_note}</span>
                      </div>
                      <div>
                        <span>
                          {baseHelper.formatMoneyPostFix(
                            checkout.package_discount_price,
                            this.props.storeReducer.userInfo.currentStore.currency_symbol
                          )}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="item-info">
                    <div className="flex">
                      <img src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-dola.svg"} alt="" />
                      <span className="footer-lable">{t("ORDERS.TOTAL_ORDER")}</span>
                    </div>
                    <div>
                      <span>
                        {baseHelper.formatMoneyPostFix(checkout.total, this.props.storeReducer.userInfo.currentStore.currency_symbol)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ladiui-order-right t-317 order-unsuccess">
            <div className="info-customer mb-24">
              <div className="order-unfinish">
                <PanelCustomer mode={appConfig.FORM_MODE.VIEW} orderID={this.props.match.params["orderID"]} orderCustomer={customer} />
              </div>
              <div className="block-shipping-address checkout">
                <PanelShippingAddress mode={appConfig.FORM_MODE.VIEW} shippingAddress={address} />
              </div>
            </div>
            <div
              id="order-custom-field"
              className="order-custom-field box-shadow-none set-max-width custom bottom mb-0 checkout-url-custom"
              style={{ border: "1px solid #f1f3f4" }}
            >
              <label className="ladiui-label">URL</label>
              {this.getMoreInfo()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    show: (checkoutID) => dispatch(checkoutActions.show(checkoutID)),
    reload: (checkoutID) => dispatch(checkoutActions.reload(checkoutID)),
    sendNotification: (checkoutID) => dispatch(checkoutActions.sendNotification(checkoutID)),
    update: (data) => dispatch(checkoutActions.update(data)),
  };
};

const mapStateToProps = (state) => ({
  checkoutReducer: { ...state.checkout },
  storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CheckoutEdit));
