import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import baseHelper from "../../helpers/BaseHelper";
import appConfig from "../../config/app";

import * as ticketSeatTypes from "../../redux/futures/ticket_seat/types";
import ticketSeatActions from "../../redux/futures/ticket_seat/actions";

export default function CheckinInfoHandle(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ticketSeat = useSelector((state) => state.ticketSeatReducer);
  const [dataTicketSeat, setDataTicketSeat] = useState({});

  useEffect(() => {
    const search = props.location.search;
    let { ticket_seat_code: ticketsSeatCode, store_id } = queryString.parse(search);

    let _storeID_cookie = baseHelper.getCookie(appConfig.COOKIE.STORE_ID);

    if (store_id) {
      if (store_id != _storeID_cookie) {
        baseHelper.setCookie(appConfig.COOKIE.STORE_ID, store_id);
        window.location.reload();
      }
    }

    if (ticketsSeatCode) {
      dispatch(ticketSeatActions.showTicketSeat({ code: ticketsSeatCode }));
    }
  }, []);

  const handleOpenCheckQrCode = () => {
    let url = `/check-qrcode`;
    window.location.href = url;
  };

  const handleBackPage = () => {
    let url = `/checkin?event_id=${dataTicketSeat.product_id}`;
    window.location.href = url;
  };

  const handleCheckinAgain = () => {
    if (dataTicketSeat.code && dataTicketSeat.product_id) {
      let params = {
        code: dataTicketSeat.code,
        product_id: dataTicketSeat.product_id,
      };
      dispatch(ticketSeatActions.checkIn(params));
    }
  };

  const handleCheckin = () => {
    const search = props.location.search;
    let { ticket_seat_code: ticketsSeatCode } = queryString.parse(search);

    if (ticketsSeatCode && dataTicketSeat.product_id) {
      let params = {
        code: ticketsSeatCode,
        product_id: dataTicketSeat.product_id,
      };
      dispatch(ticketSeatActions.checkIn(params));
    }
  };

  const handleCancelCheckin = () => {
    let message = "Bạn có muốn bỏ check in cho sự kiện này ?";
    let params = {};
    if (window.confirm(message) == true) {
      if (dataTicketSeat.checked_in && dataTicketSeat.ticket_seat_id) {
        if (dataTicketSeat.checked_in == 0) {
          params = {
            checked_in: 1,
            ticket_seat_id: dataTicketSeat.ticket_seat_id,
          };
        } else {
          params = {
            checked_in: 0,
            ticket_seat_id: dataTicketSeat.ticket_seat_id,
          };
        }
        dispatch(ticketSeatActions.changeCheckIn(params));
      }
    }
  };

  useEffect(() => {
    const search = props.location.search;
    let { ticket_seat_code: ticketsSeatCode } = queryString.parse(search);

    if (ticketSeat.action === ticketSeatTypes.SHOW_TICKET_SEAT) {
      if (ticketSeat.status) {
        setDataTicketSeat({ ...ticketSeat.ticketSeat });
      } else {
        window.LadiUI.showErrorMessage("Thông báo", ticketSeat.message, "OK");
      }
    }
    if (ticketSeat.action === ticketSeatTypes.CHECK_IN_TICKET_SEAT) {
      if (ticketSeat.status) {
        window.LadiUI.toastCustom("success", "", ticketSeat.message);
        dispatch(ticketSeatActions.showTicketSeat({ code: ticketSeat.ticketSeat.code }));
      } else {
        window.LadiUI.showErrorMessage("Thông báo", ticketSeat.message, "OK");
      }
    }
    if (ticketSeat.action === ticketSeatTypes.CHANGE_CHECK_IN) {
      if (ticketSeat.status) {
        window.LadiUI.toastCustom("success", "", ticketSeat.message);
        dispatch(ticketSeatActions.showTicketSeat({ code: ticketSeat.ticketSeat.code }));
      } else {
        window.LadiUI.showErrorMessage("Thông báo", ticketSeat.message, "OK");
      }
    }
  }, [ticketSeat]);

  return (
    <>
      <div className="page-content p-0">
        <div className="page-checkin-info">
          <div className="block-actions-top">
            <button type="button" onClick={() => handleBackPage()}>
              <i className="ldicon-chevron-left mr-8" />
              Quay lại
            </button>
            <button type="button" onClick={() => handleOpenCheckQrCode()}>
              Tiếp tục
              <i className="ldicon-chevron-right ml-8" />
            </button>
          </div>
          <div className="block-info-checkin">
            <h3 className="title">Thông tin vé</h3>
            <div className="info">
              <span className="item">
                Sự kiện: <strong>{dataTicketSeat.product_name}</strong>
              </span>
              <span className="item">
                Họ tên:{" "}
                <strong>
                  {(dataTicketSeat.first_name ? dataTicketSeat.first_name : "") +
                    (dataTicketSeat.last_name ? dataTicketSeat.last_name : "")}
                </strong>
              </span>
              <span className="item">
                Số điện thoại: <strong>{dataTicketSeat.phone}</strong>
              </span>
              <span className="item">
                Email: <strong>{dataTicketSeat.email}</strong>
              </span>
              <span className="item">
                Mã vé: <strong>{dataTicketSeat.code}</strong>
              </span>
              <span className="item">
                Loại vé: <strong>{dataTicketSeat.option_name}</strong>
              </span>
              <span className="item">
                Ngày tạo: <strong>{baseHelper.formatDateToStr(dataTicketSeat.created_at)}</strong>
              </span>
              <span className="item">
                Trạng thái:{" "}
                <span className={`status ${dataTicketSeat.checked_in == 0 ? "checkin" : "checked-in"}`}>
                  {dataTicketSeat.checked_in == 0 ? "Chưa checkin" : "Đã checkin"}
                </span>
              </span>
            </div>
            {dataTicketSeat.checked_in == 0 ? (
              <button type="button" className="ladiui btn btn-primary btn-sm btn-checkin" onClick={() => handleCheckin()}>
                Check in
              </button>
            ) : (
              <div className="actions-checkin-cancel">
                <button type="button" className="ladiui btn btn-outline-danger btn-sm" onClick={() => handleCancelCheckin()}>
                  Hủy check in
                </button>
                <button type="button" className="ladiui btn btn-primary btn-sm" onClick={() => handleCheckinAgain()}>
                  Check in lại
                </button>
              </div>
            )}
          </div>
          <div className="block-history-checkin">
            <h3 className="title">Lịch sử check in</h3>
            <ul className="list-history">
              {dataTicketSeat.checkin_history && dataTicketSeat.checkin_history.length > 0 ? (
                dataTicketSeat.checkin_history.map((item, index) => {
                  return (
                    <li key={index} className="item">
                      <span>{item.type}</span>
                      <span className="time">{baseHelper.formatDateToStr(item.time)}</span>
                    </li>
                  );
                })
              ) : (
                <li>
                  <span style={{ width: "100%", textAlign: "center", display: "block" }} className="mt-24">
                    Chưa có lịch sử check in !
                  </span>
                </li>
              )}
            </ul>
          </div>
          <div className="powered-by">
            <span>
              Powered by <img src="https://w.ladicdn.com/ladiui/icons/ldicon-colored-brand-ladisales.svg" className="ml-8 mr-8"></img>{" "}
              <strong>Ladisales</strong>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
