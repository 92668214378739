/** Import default package */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

/** Import from third party */
import { cloneDeep, map, includes, compact, omit, find } from "lodash";

/** Import component from my app */
import Input from "../../../components/Input";
import baseHelper from "../../../helpers/BaseHelper";
import appConfig from "../../../config/app";
import ModalAddress from "./ModalAddress";
import PanelAddress from "../../../components/PanelAddress";

/** Import redux */
import customerActions from "../../../redux/futures/customer/actions";
import * as customerTypes from "../../../redux/futures/customer/types";

import customerTagActions from "../../../redux/futures/customer_tag/actions";
import * as customerTagTypes from "../../../redux/futures/customer_tag/types";

import addressActions from "../../../redux/futures/customer_address/actions";
import * as addressTypes from "../../../redux/futures/customer_address/types";

import { withTranslation } from "react-i18next";
import Modal from "../../../components/Modal";
import Tag from "../../../components/Tag";
import ConfirmModal from "../../../components/ConfirmModal";
import History from "../../../components/History";
import PanelCustomField from "../../../components/PanelCustomField";

class ModalCustomer extends React.Component {
  static propTypes = {
    currentCustomerInfo: PropTypes.object,
    mode: PropTypes.string,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    loading: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      customer: {
        customer_id: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
      },
      addressDefault: {
        customer_address_id: "",
        first_name: "",
        last_name: "",
        company: "",
        address: "",
        apartment: "",
        country_code: "",
        country_name: "",
        state_id: "",
        state_name: "",
        district_id: "",
        district_name: "",
        ward_id: "",
        ward_name: "",
        postal_code: "",
        phone: "",
      },
      addresses: {},
      currentAddress: {},
      note: "",
      mode: "",
      tags: [],
      customFields: [],
      isShowModalAddress: false,
      selectedCustomerAddressID: "",
      showFooterCustomer: true,
      isShowPanelAddress: false,
    };

    this.initialState = cloneDeep(this.state);

    this.ref = React.createRef();
    this.formRef = React.createRef();
    this.addressPanelRef = React.createRef();
    this.inputsRef = new Set();
    this.tagRef = React.createRef();
    this.panelCustomFieldRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.currentCustomerInfo) {
      this.init(this.props);
    }

    this.props.listAllTags();
  }

  componentDidUpdate() {
    window.LadiUI.init();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props === nextProps) {
      return;
    }

    if (this.props.currentCustomerInfo !== nextProps.currentCustomerInfo) {
      if (!nextProps.currentCustomerInfo) {
        this.resetState();
      } else {
        this.init(nextProps);
      }
    }

    if (this.props.customerAddress.action != nextProps.customerAddress.action) {
      if (nextProps.customerAddress.action === addressTypes.DELETE_CUSTOMER_ADDRESS) {
        if (nextProps.customerAddress.status) {
          window.LadiUI.closeModal("confirm-delete-address");
          this.props.reloadCustomer(this.state.customer.customer_id);
          window.LadiUI.toastCustom("success", "", nextProps.customerAddress.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.customerAddress.message, "OK");
        }
      }

      if (nextProps.customerAddress.action === addressTypes.CREATE_CUSTOMER_ADDRESS) {
        if (nextProps.customerAddress.status) {
          this.setState({
            isShowModalAddress: false,
          });
          this.props.reloadCustomer(this.state.customer.customer_id);
          window.LadiUI.toastCustom("success", "", nextProps.customerAddress.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.customerAddress.message, "OK");
        }
      }

      if (nextProps.customerAddress.action === addressTypes.UPDATE_CUSTOMER_ADDRESS) {
        if (nextProps.customerAddress.status) {
          this.props.reloadCustomer(this.state.customer.customer_id);
          window.LadiUI.toastCustom("success", "", nextProps.customerAddress.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.customerAddress.message, "OK");
        }
      }
    }

    if (this.props.customer.action != nextProps.customer.action) {
      if (nextProps.customer.action === customerTypes.APPLY_DEFAULT_ADDRESS_CUSTOMER) {
        if (nextProps.customer.status) {
          window.LadiUI.closeModal("confirm-apply-default-address");
          this.props.reloadCustomer(this.state.customer.customer_id);
          window.LadiUI.toastCustom("success", "", nextProps.customer.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.customer.message, "OK");
        }
      }
    }
  }

  init = (props) => {
    const currentCustomerInfo = { ...props.currentCustomerInfo };
    let { note, tags } = currentCustomerInfo;
    const addressDefault = currentCustomerInfo.address_default || {};
    const addresses = currentCustomerInfo.addresses || [];
    const customFields = currentCustomerInfo.custom_fields || [];

    delete currentCustomerInfo.address_default;
    delete currentCustomerInfo.note;
    delete currentCustomerInfo.tags;
    delete currentCustomerInfo.addresses;
    delete currentCustomerInfo.custom_fields;

    const customer = { ...currentCustomerInfo };

    const newCustomer = {};
    map(this.state.customer, (value, key) => {
      newCustomer[key] = !baseHelper.isEmpty(customer[key]) ? customer[key] : "";
    });

    const newAddressDefault = {};
    map(this.state.addressDefault, (value, key) => {
      newAddressDefault[key] = !baseHelper.isEmpty(addressDefault[key]) ? addressDefault[key] : "";
    });

    const newNote = !baseHelper.isEmpty(note) ? note : "";
    const newTags = !baseHelper.isEmpty(tags) ? tags : [];
    const newCustomFields = !baseHelper.isEmpty(customFields) ? customFields : [];

    this.setState({
      customer: newCustomer,
      addressDefault: newAddressDefault,
      customFields: newCustomFields,
      addresses,
      note: newNote,
      tags: newTags,
    });
  };

  resetState = () => {
    this.setState({
      ...cloneDeep(this.initialState),
    });
  };

  /*******************************HANDLE INPUT***************************/
  onChangeCustomerInput = (event) => {
    this.setState({
      customer: {
        ...this.state.customer,
        [event.target.name]: event.target.value,
      },
    });

    if (includes(["first_name", "last_name", "phone"], event.target.name)) {
      this.setState({
        addressDefault: {
          ...this.state.addressDefault,
          [event.target.name]: event.target.value,
        },
      });
    }
  };

  onChangeAddressInput = (event) => {
    this.setState({
      addressDefault: {
        ...this.state.addressDefault,
        [event.target.name]: event.target.value,
      },
    });
  };

  onChangeInput = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  /*****************************************************************/

  /*******************************MODAL***************************/
  openModalAddressCreate = () => {
    this.setState({
      currentAddress: {
        customer_id: this.state.customer.customer_id,
      },
      mode: appConfig.FORM_MODE.CREATE,
      isShowModalAddress: true,
    });
  };

  openModalAddressEdit = (address) => {
    this.setState({
      currentAddress: {
        customer_id: this.state.customer.customer_id,
        ...address,
      },
      mode: appConfig.FORM_MODE.EDIT,
      isShowModalAddress: true,
    });
  };

  openModalDefaultAddress = (selectedCustomerAddressID) => {
    this.setState({
      selectedCustomerAddressID,
    });

    window.LadiUI.showModal("confirm-apply-default-address");
  };

  openModalDeleteAddress = (selectedCustomerAddressID) => {
    this.setState({
      selectedCustomerAddressID,
    });

    window.LadiUI.showModal("confirm-delete-address");
  };

  hideModalAddress = () => {
    this.setState({
      isShowModalAddress: false,
    });
  };

  onCancel = () => {
    this.props.onCancel();
  };

  submitAddress = (newAddress) => {
    if (this.state.mode === appConfig.FORM_MODE.CREATE) {
      this.props.createAddress(newAddress);
    } else {
      this.props.updateAddress(newAddress);
    }
  };

  /**************************************************************/

  submit = (event) => {
    event.preventDefault();
    // Validate
    let errors = [];
    const inputsRef = compact(Array.from(this.inputsRef));
    map(inputsRef, (ref) => {
      if (ref.validate) {
        const error = ref.validate();
        if (error.length > 0) {
          errors = errors.concat(error);
        }
      }
    });

    if (this.props.mode === appConfig.FORM_MODE.CREATE) {
      errors = errors.concat(this.addressPanelRef.current.validate());
    }

    const { customer, note } = this.state;
    // Validate nếu cả phone & email trống
    if (baseHelper.isEmpty(customer.email) && baseHelper.isEmpty(customer.phone)) {
      errors = errors.concat(this.props.t("VALIDATION.REQUIRED_EMAIL_OR_PHONE"));
    }

    errors = compact(errors);
    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      window.LadiUI.toastCustom("danger", "", errorMessage);

      return;
    }

    const tags = this.tagRef.current.getData();
    const productCustomField = this.panelCustomFieldRef.current.getData();

    if (this.props.mode === appConfig.FORM_MODE.CREATE) {
      const addressDefault = this.addressPanelRef.current.getData();

      const newCustomer = {
        ref_type: appConfig.APP.LS,
        ...omit(customer, ["customer_id"]),
        note,
        custom_fields: productCustomField,
        tags,
      };

      if (!baseHelper.hasAllEmptyValues(addressDefault)) {
        newCustomer.address = addressDefault;
      }

      this.props.onSubmit(newCustomer);
    } else {
      const newCustomer = {
        ...customer,
        custom_fields: productCustomField,
        note,
        tags,
      };
      this.props.onSubmit(newCustomer);
    }
  };

  getPaymentStatus = (status) => {
    const matched = find(appConfig.PAYMENT_STATUS, (item) => item.CODE == status);
    if (!matched) {
      return "";
    }

    return matched.NAME;
  };

  getShippingStatus = (status) => {
    const matched = find(appConfig.SHIPPING_STATUS, (item) => item.CODE == status);
    if (!matched) {
      return "";
    }

    return matched.NAME;
  };

  getAddressComponent = () => {
    const { t } = this.props;
    const { addressDefault, addresses } = this.state;

    if (!this.props.mode) {
      return null;
    }

    const isSubmitAddressLoading =
      this.props.customerAddress.loading &&
      includes([addressTypes.CREATE_CUSTOMER_ADDRESS, addressTypes.UPDATE_CUSTOMER_ADDRESS], this.props.customerAddress.waiting);
    const isLoadingApplyDefaultAddress =
      this.props.customer.loading && customerTypes.APPLY_DEFAULT_ADDRESS_CUSTOMER == this.props.customer.waiting;
    const isLoadingDeleteAddress =
      this.props.customerAddress.loading && addressTypes.DELETE_CUSTOMER_ADDRESS == this.props.customerAddress.waiting;

    return (
      <div className="table-address">
        <a className="ladiui-link pull-right mb-12" onClick={this.openModalAddressCreate}>
          {t("CUSTOMERS.ADD_ADDRESS")}
        </a>

        <div className="ladi-card">
          <table className="ladiui table text-left" style={{ minWidth: "auto" }}>
            <thead>
              <tr className="ladiui table-vertical header">
                <th scope="col" name="first_name" className="text-left">
                  {t("COMMON.FULL_NAME")}
                </th>
                <th scope="col" name="address" className="text-left">
                  {t("CUSTOMERS.ADDRESS")}
                </th>
                <th scope="col" name="phone" className="text-left">
                  {t("CUSTOMERS.PHONE")}
                </th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {map(addresses, (address) => {
                const isDefault = address.customer_address_id == addressDefault.customer_address_id;
                let addressInfos = [
                  address.address,
                  address.ward_name,
                  address.district_name,
                  address.state_name,
                  baseHelper.getCountryNameByCode(address.country_code),
                ];
                addressInfos = compact(addressInfos);
                return (
                  <tr key={address.customer_address_id} className={isDefault ? "default ladiui table-vertical" : "ladiui table-vertical"}>
                    <td style={{ width: 200 }}>{`${baseHelper.getText(address.last_name)} ${baseHelper.getText(address.first_name)}`} </td>
                    <td>{addressInfos.join(", ")}</td>
                    <td style={{ width: 125 }}>{address.phone}</td>

                    <td className="text-right pd-0">
                      <div className="ladiui btn-group">
                        <div className="ladiui dropdown hide-mt ba-c">
                          <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle" style={{ minWidth: "auto" }}>
                            <i className="ladiui icon icon-ldp-dot"></i>
                          </button>
                          {/* <i className="ladi-icon icon-dots ladiui dropdown-toggle select-dropdown pd-5" data-toggle="dropdown"></i> */}
                          <ul className="ladiui dropdown-menu r-0">
                            {!isDefault && (
                              <li>
                                <a
                                  className="ladiui dropdown-item"
                                  onClick={() => this.openModalDefaultAddress(address.customer_address_id)}
                                >
                                  {t("CUSTOMERS.APPLY_DEFAULT_ADDRESS")}
                                </a>
                              </li>
                            )}
                            <li>
                              <a className="ladiui dropdown-item" onClick={() => this.openModalAddressEdit(address)}>
                                {t("ACTIONS.EDIT")}
                              </a>
                            </li>
                            {!isDefault && (
                              <li>
                                <a
                                  className="ladiui dropdown-item"
                                  onClick={() => this.openModalDeleteAddress(address.customer_address_id)}
                                >
                                  {t("ACTIONS.DELETE")}
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <ConfirmModal
          id="confirm-apply-default-address"
          title={t("ADDRESSES.MSG_APPLY_DEFAULT_ADDRESS_TITLE")}
          content={t("ADDRESSES.MSG_APPLY_DEFAULT_ADDRESS_CONTENT")}
          cancelText={t("ACTIONS.CANCEL")}
          okText={t("ACTIONS.OK")}
          onOk={() => this.props.applyDefaultAddress(this.state.customer.customer_id, this.state.selectedCustomerAddressID)}
          isLoading={isLoadingApplyDefaultAddress}
        />

        <ConfirmModal
          id="confirm-delete-address"
          title={t("ADDRESSES.MSG_DELETE_ADDRESS_TITLE")}
          content={t("ADDRESSES.MSG_DELETE_ADDRESS_CONTENT")}
          cancelText={t("ACTIONS.CANCEL")}
          okText={t("ACTIONS.DELETE")}
          onOk={() => this.props.deleteAddress(this.state.customer.customer_id, this.state.selectedCustomerAddressID)}
          isLoading={isLoadingDeleteAddress}
        />
      </div>
    );
  };

  getOrderComponent = () => {
    const { currentCustomerInfo, t } = this.props;
    const detailSpend = currentCustomerInfo.detail_spend;

    if (detailSpend.count <= 0) {
      return <div className="text-center mt-24">{t("CUSTOMERS.EMPTY_ORDER")}</div>;
    }

    return (
      <div className="customer-order">
        <div className="order-overview">
          <div className="item">
            <p className="title">{t("CUSTOMERS.TOTAL_ORDER")}</p>
            <h3>{`${detailSpend.count} ${t("CUSTOMERS.LABEL_ORDER")}`}</h3>
          </div>

          <div className="item">
            <p className="title">{t("CUSTOMERS.TOTAL_SPEND")}</p>
            <h3>{baseHelper.formatMoneyPostFix(detailSpend.total, this.props.storeReducer.userInfo.currentStore.currency_symbol)}</h3>
          </div>

          <div className="item">
            <p className="title">{t("CUSTOMERS.AVERAGE_SPEND")}</p>
            <h3>{baseHelper.formatMoneyPostFix(detailSpend.average, this.props.storeReducer.userInfo.currentStore.currency_symbol)}</h3>
          </div>
        </div>

        <div className="ladi-card" style={{ marginTop: 16 }}>
          <div className="heading mb-12">
            <label className="ladiui-label pb-0">{t("CUSTOMERS.RECENT_ORDER")}</label>
            <a href={`/orders?customer_id=${currentCustomerInfo.customer_id}`}>{t("CUSTOMERS.ALL_ORDER")}</a>
          </div>
          <table className="ladiui table text-left" style={{ minWidth: "auto" }}>
            <thead>
              <tr className="ladiui table-vertical header">
                <th scope="col" className="text-left">
                  {t("CUSTOMERS.ORDER_NO")}
                </th>
                <th scope="col" className="text-left">
                  {t("CUSTOMERS.ORDER_CREATE")}
                </th>
                <th scope="col" className="text-left">
                  {t("CUSTOMERS.ORDER_TOTAL")}
                </th>
                <th scope="col" className="text-left">
                  {t("CUSTOMERS.ORDER_PAYMENT_STATUS")}
                </th>
                <th scope="col" className="text-left">
                  {t("CUSTOMERS.ORDER_SHIPPING_STATUS")}
                </th>
                <th scope="col" className="text-left">
                  {t("CUSTOMERS.REFUND")}
                </th>
              </tr>
            </thead>
            <tbody>
              {map(currentCustomerInfo.recent_orders, (item) => {
                return (
                  <tr
                    key={item.order_id}
                    className={
                      item.order_status == appConfig.ORDER_STATUS.CANCELED.CODE
                        ? "ladiui table-vertical order-cancel"
                        : "ladiui table-vertical"
                    }
                  >
                    <td className="reference-no">
                      <a className="order-table-item" href={`/orders/${item.order_id}`}>
                        {item.reference_no}
                      </a>
                    </td>
                    <td>{baseHelper.formatStrToDate(item.created_at)}</td>
                    <td>{baseHelper.formatMoneyPostFix(item.total, this.props.storeReducer.userInfo.currentStore.currency_symbol)}</td>
                    <td>{this.getPaymentStatus(item.payment_status)}</td>
                    <td>{this.getShippingStatus(item.shipping_status)}</td>
                    <td>{item.refund && item.refund != 0 ? baseHelper.formatMoneyPostFix(item.refund) : ""}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  getHistoryComponent = () => {
    return <History type={appConfig.HISTORY_FORM.CUSTOMER} customerID={this.props.currentCustomerInfo.customer_id} />;
  };

  render() {
    const { t, visible } = this.props;
    const { customer, note, tags, addressDefault, isShowPanelAddress, customFields } = this.state;

    const title = this.props.mode === appConfig.FORM_MODE.CREATE ? t("CUSTOMERS.ADD_CUSTOMER") : t("CUSTOMERS.EDIT_CUSTOMER");
    const okText = this.props.mode === appConfig.FORM_MODE.CREATE ? t("CUSTOMERS.ADD_CUSTOMER") : t("ACTIONS.SAVE");
    const allTags = this.props.customerTag.allTags || [];

    return (
      <>
        <Modal
          visible={visible}
          id="modal-customer"
          zIndex={1050}
          title={title}
          onCancel={this.onCancel}
          onOk={this.submit}
          isLoading={this.props.loading}
          width={900}
          okText={okText}
          hasFooter={this.state.showFooterCustomer}
          bodyStyles={{ minHeight: "40vh" }}
          bodyClassName="position-initial"
        >
          <div>
            {this.props.mode == appConfig.FORM_MODE.EDIT && (
              <ul className="ladiui nav nav-tabs mb-24" id="myTab" role="tablist">
                <li className="ladiui nav-item" role="presentation">
                  <span
                    onClick={() => {
                      this.setState({
                        showFooterCustomer: true,
                      });
                    }}
                    className="ladiui tab-link active"
                    id="trigger-tab-general"
                    data-toggle="tab"
                    data-parent="myTab"
                    data-target="tab-general"
                  >
                    {t("COMMON.TAB_GENERAL")}
                  </span>
                </li>
                <li className="ladiui nav-item" role="presentation">
                  <span
                    onClick={() => {
                      this.setState({
                        showFooterCustomer: false,
                      });
                    }}
                    className="ladiui tab-link"
                    id="trigger-tab-adress"
                    data-toggle="tab"
                    data-parent="myTab"
                    data-target="tab-address"
                  >
                    {t("COMMON.TAB_ADDRESS")}
                  </span>
                </li>
                <li className="ladiui nav-item" role="presentation">
                  <span
                    onClick={() => {
                      this.setState({
                        showFooterCustomer: false,
                      });
                    }}
                    className="ladiui tab-link"
                    id="trigger-tab-order"
                    data-toggle="tab"
                    data-parent="myTab"
                    data-target="tab-order"
                  >
                    {t("COMMON.TAB_ORDER")}
                  </span>
                </li>
                <li className="ladiui nav-item" role="presentation">
                  <span
                    onClick={() => {
                      this.setState({
                        showFooterCustomer: false,
                      });
                    }}
                    className="ladiui tab-link"
                    id="trigger-tab-history"
                    data-toggle="tab"
                    data-parent="myTab"
                    data-target="tab-history"
                  >
                    {t("COMMON.HISTORY")}
                  </span>
                </li>
                <li className="ladiui nav-item" role="presentation">
                  <span
                    onClick={() => {
                      this.setState({
                        showFooterCustomer: true,
                      });
                    }}
                    className="ladiui tab-link"
                    id="trigger-tab-custom-field"
                    data-toggle="tab"
                    data-parent="myTab"
                    data-target="tab-custom-field"
                  >
                    {t("COMMON.CUSTOM_FIELD")}
                  </span>
                </li>
              </ul>
            )}

            {this.props.mode == appConfig.FORM_MODE.CREATE && (
              <ul className="ladiui nav nav-tabs mb-24" id="myTab" role="tablist">
                <li className="ladiui nav-item" role="presentation">
                  <span
                    onClick={() => {
                      this.setState({
                        showFooterCustomer: true,
                        isShowPanelAddress: false,
                      });
                    }}
                    className="ladiui tab-link active"
                    id="trigger-tab-general"
                    data-toggle="tab"
                    data-parent="myTab"
                    data-target="tab-general"
                  >
                    {/* data-tab="tab-general" id="trigger-tab-general" */}
                    {t("COMMON.TAB_GENERAL")}
                  </span>
                </li>
                <li className="ladiui nav-item" role="presentation">
                  <span
                    onClick={() => {
                      this.setState({
                        showFooterCustomer: true,
                        isShowPanelAddress: true,
                      });
                    }}
                    className="ladiui tab-link"
                    id="trigger-tab-custom-field"
                    data-toggle="tab"
                    data-parent="myTab"
                    data-target="tab-custom-field"
                  >
                    {t("COMMON.CUSTOM_FIELD")}
                  </span>
                </li>
              </ul>
            )}

            <div className="ladiui tab-content" id="myTabContent">
              <div className="ladiui tab-pane fade show open" id="tab-general" role="tabpanel" aria-labelledby="trigger-tab-general">
                <div className="content">
                  <div className="ladiui-row">
                    <div className="col-50">
                      <div className="ladiui form-group">
                        <label className="ladiui-label">{t("COMMON.FIRST_NAME")}</label>
                        <Input
                          tabIndex={2}
                          formName="customer"
                          name="first_name"
                          validationName={t("COMMON.FIRST_NAME")}
                          placeholder={t("COMMON.FIRST_NAME")}
                          value={customer.first_name}
                          onChange={this.onChangeCustomerInput}
                          ref={(ref) => this.inputsRef.add(ref)}
                          validations={{ isRequired: true }}
                        />
                      </div>

                      <div className="ladiui form-group">
                        <label className="ladiui-label">{t("COMMON.EMAIL")}</label>
                        <Input
                          tabIndex={3}
                          formName="customer"
                          name="email"
                          validationName={t("COMMON.EMAIL")}
                          placeholder={t("COMMON.EMAIL")}
                          value={customer.email}
                          onChange={this.onChangeCustomerInput}
                          ref={(ref) => this.inputsRef.add(ref)}
                          validations={{ email: true }}
                        />
                      </div>
                    </div>

                    <div className="col-50">
                      <div className="ladiui form-group">
                        <label className="ladiui-label">{t("COMMON.LAST_NAME")}</label>
                        <Input
                          tabIndex={1}
                          formName="customer"
                          name="last_name"
                          validationName={t("COMMON.LAST_NAME")}
                          placeholder={t("COMMON.LAST_NAME")}
                          value={customer.last_name}
                          onChange={this.onChangeCustomerInput}
                        />
                      </div>

                      <div className="ladiui form-group">
                        <label className="ladiui-label">{t("CUSTOMERS.PHONE")}</label>
                        <Input
                          tabIndex={4}
                          formName="customer"
                          name="phone"
                          validationName={t("CUSTOMERS.PHONE")}
                          placeholder={t("CUSTOMERS.PHONE")}
                          value={customer.phone}
                          onChange={this.onChangeCustomerInput}
                          ref={(ref) => this.inputsRef.add(ref)}
                          validations={{ phone: true }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="ladiui form-group">
                    <label className="ladiui-label">{t("CUSTOMERS.NOTE")}</label>
                    <Input tabIndex={5} name="note" placeholder={t("CUSTOMERS.NOTE")} value={note} onChange={this.onChangeInput} />
                  </div>
                  <Tag tabIndex={6} ref={this.tagRef} id="customer-tag" allTags={allTags} selectedTags={tags} />
                </div>
              </div>
              {this.props.mode == appConfig.FORM_MODE.CREATE && (
                <div className="condition-group" style={{ display: isShowPanelAddress ? "none" : "inherit", marginTop: 32 }}>
                  <label className="ladiui-label">{t("COMMON.TAB_ADDRESS")}</label>
                </div>
              )}
              {this.props.mode == appConfig.FORM_MODE.CREATE && (
                <div className="content modal-add-customer" style={{ display: isShowPanelAddress ? "none" : "inherit" }}>
                  <PanelAddress
                    mode={appConfig.FORM_MODE.CREATE}
                    ref={this.addressPanelRef}
                    address={addressDefault}
                    isRequiredPhone={false}
                  />
                </div>
              )}
              {this.props.mode == appConfig.FORM_MODE.EDIT && (
                <div className="ladiui tab-pane fade" id="tab-address" role="tabpanel" aria-labelledby="trigger-tab-address">
                  {this.getAddressComponent()}
                </div>
              )}

              {this.props.mode == appConfig.FORM_MODE.EDIT && (
                <div className="ladiui tab-pane fade" id="tab-order" role="tabpanel" aria-labelledby="trigger-tab-order">
                  {this.getOrderComponent()}
                </div>
              )}

              {this.props.mode == appConfig.FORM_MODE.EDIT && (
                <div className="ladiui tab-pane fade mt-0" id="tab-history" role="tabpanel" aria-labelledby="trigger-tab-history">
                  {this.getHistoryComponent()}
                </div>
              )}

              <div className="ladiui tab-pane fade" id="tab-custom-field" role="tabpanel" aria-labelledby="trigger-tab-custom-field">
                <PanelCustomField
                  ref={this.panelCustomFieldRef}
                  customFields={customFields}
                  groupType={appConfig.CUSTOM_FIELD.GROUP_TYPES.CUSTOMER.CODE}
                />
              </div>
            </div>
          </div>
        </Modal>
        {this.state.isShowModalAddress && (
          <ModalAddress
            ref={this.formRef}
            address={this.state.currentAddress}
            mode={this.state.mode}
            visible={this.state.isShowModalAddress}
            onCancel={this.hideModalAddress}
            onSubmit={this.submitAddress}
          // loading={isSubmitAddressLoading}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reloadCustomer: (customerID) => dispatch(customerActions.reload(customerID)),
    deleteTag: (customerID, customerTagID) => dispatch(customerTagActions.deleteTag(customerID, customerTagID)),
    createAddress: (address) => dispatch(addressActions.create(address)),
    updateAddress: (address) => dispatch(addressActions.update(address)),
    deleteAddress: (customerID, addressID) => dispatch(addressActions.delete(customerID, addressID)),
    listAllTags: () => dispatch(customerTagActions.listAll()),
    applyDefaultAddress: (customerID, customerAddressID) => dispatch(customerActions.applyDefaultAddress(customerID, customerAddressID)),
  };
};

const mapStateToProps = (state) => ({
  customer: { ...state.customer },
  customerAddress: { ...state.customerAddress },
  customerTag: { ...state.customerTag },
  storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withTranslation("translation", { withRef: true })(ModalCustomer)
);
