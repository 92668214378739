/** @format */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import moment from "moment";
import appConfig from "../../../config/app";
import baseHelper from "../../../helpers/BaseHelper";

import productReviewActions from "../../../redux/futures/product_review/actions";
import * as productReviewTypes from "../../../redux/futures/product_review/types";

import LoadingTable from "../../../components/LoadingTable";
import ConfirmModal from "../../../components/ConfirmModal";
import LadiPagination from "../../../components/LadiPagination";
import LadiDropdownMenu from "../../../components/LadiDropdownMenu";

import ModalAddProductView from "../../../components/ModalAddProductView";

import { includes, map, remove, values } from "lodash";

let CDN = "https://w.ladicdn.com/";

export default function PageManageReview(props) {
    const location = useLocation();
    const history = useHistory();
    const queryStrings = new URLSearchParams(location.search);

    const [activePage, setActivePage] = useState(1);
    const [selectedLimit, setSelectedLimit] = useState(appConfig.PAGINATION.LIMIT_OPTIONS[0].value);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [isReloadIframe, setReloadIframe] = useState(false);
    const [isShowModalAddView, setIsShowModalAddView] = useState(false);

    const [currentProductReview, setCurrentProductReview] = useState({});

    const [valueSearch, setValueSearch] = useState("");

    const [isManageCreateReview, setIsManageCreateReview] = useState(false);

    let LIST_STARS = [1, 2, 3, 4, 5];

    const productReviewReducer = useSelector((state) => state.productReview);

    let selectedID = "";
    // let selectedIDs = [];

    // let sortBy = {
    //   page_checkout_id: "DESC",
    // };
    const formRef = React.createRef();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const Moment = baseHelper.getMoment();

    useEffect(() => {
        list();
    }, [selectedLimit]);

    useEffect(() => {
        list(false);
    }, [activePage]);

    useEffect(() => {
        if (productReviewReducer.action == productReviewTypes.CREATE_REVIEW) {
            if (productReviewReducer.status) {
                list();
                setIsShowModalAddView(false);
                window.LadiUI.toastCustom('success', '', productReviewReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReviewReducer.message, 'OK');
            }
        }
        if (productReviewReducer.action == productReviewTypes.CREATE_REVIEWS) {
            if (productReviewReducer.status) {
                list();
                setIsShowModalAddView(false);
                setIsManageCreateReview(false);
                window.LadiUI.toastCustom('success', '', productReviewReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReviewReducer.message, 'OK');
            }
        }
        if (productReviewReducer.action == productReviewTypes.UPDATE_REVIEW) {
            if (productReviewReducer.status) {
                list();
                setIsShowModalAddView(false);
                window.LadiUI.toastCustom('success', '', productReviewReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReviewReducer.message, 'OK');
            }
        }
        if (productReviewReducer.action == productReviewTypes.DELETE_REVIEW) {
            if (productReviewReducer.status) {
                list();
                window.LadiUI.closeModal("confirm-product-review-delete");
                window.LadiUI.toastCustom('success', '', productReviewReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReviewReducer.message, 'OK');
            }
        }
        if (productReviewReducer.action == productReviewTypes.DELETE_REVIEWS) {
            if (productReviewReducer.status) {
                list();
                window.LadiUI.closeModal("confirm-product-review-deletes");
                window.LadiUI.toastCustom('success', '', productReviewReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReviewReducer.message, 'OK');
            }
        }
    }, [productReviewReducer]);

    /**
     * Tim kiem theo name
     */
    const onKeyDownInput = (event) => {
        if (event.key === "Enter") {
            list();
        }
    };

    const list = (resetActivePageFlag = true) => {
        if (document.getElementById("checkAllItem")) {
            document.getElementById("checkAllItem").checked = false;
            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = false;
            });
        }

        if (resetActivePageFlag) {
            setActivePage(1);
        }

        // Reset selectedIDs
        setSelectedIDs([]);

        const data = {
            search: {
                keyword: valueSearch
            },
            paged: activePage,
            limit: selectedLimit,
        };

        dispatch(productReviewActions.list(data));
    };

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    const onChangeLimit = (option) => {
        setSelectedLimit(option.value);
        setActivePage(1);
    };

    /**
     * Change page
     */
    const onPageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    const checkAllItem = (event) => {
        const { checked } = event.target;

        let ids = [];
        if (checked) {
            ids = map(productReviewReducer.reviews, (item) => item.product_reviews_id);

            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = true;
            });
        } else {
            ids = [];
            window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
                e.checked = false;
            });
        }

        setSelectedIDs(ids);
    };

    const checkItem = (event, productReviewID) => {

        const { checked } = event.target;

        let ids = [...selectedIDs];

        if (checked) {
            if (!includes(ids, productReviewID)) {
                ids.push(productReviewID);
            }

            if (ids.length == productReviewReducer.reviews.length) {
                document.getElementById("checkAllItem").checked = true;
            }
        } else {
            document.getElementById("checkAllItem").checked = false;
            remove(ids, (selectedID) => selectedID == productReviewID);
        }

        setSelectedIDs(ids);
    };

    const openDeletesModal = () => {
        if (selectedIDs.length <= 0) {
            window.LadiUI.toastCustom("danger", "", t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
            return;
        }

        window.LadiUI.showModal("confirm-product-review-deletes");
    };


    const eventSelectActions = (action) => {
        switch (action) {
            case "DELETE":
                openDeletesModal();
                break;
            default:
        }
    };

    const handleDeleteStorePage = (_selectedID) => {
        if (_selectedID) {
            selectedID = _selectedID;
            window.LadiUI.showModal("confirm-page-store-delete");
        }
    };

    const openModalEdit = (pageCheckoutID) => {
        if (pageCheckoutID) {
            props.history.push(`/store-page/update/${pageCheckoutID}`);
        }
    };

    const handleShowModalAddView = () => {
        setCurrentProductReview({});
        setIsShowModalAddView(true);
        setIsManageCreateReview(true);
    }

    const handleCreateReview = (payload) => {
        dispatch(productReviewActions.create(payload))
    }

    const handleCreateReviews = (payload) => {
        dispatch(productReviewActions.creates(payload))
    }


    const handleUpdateReview = (payload) => {
        dispatch(productReviewActions.update(payload))
    }

    const handleEditReviewItem = (item) => {
        if (item) {
            setCurrentProductReview(item);
            setIsShowModalAddView(true);
            setIsManageCreateReview(false);
        }
    }

    const handleDeleteReviewItem = (productReviewID) => {
        if (productReviewID) {
            selectedID = productReviewID
            window.LadiUI.showModal("confirm-product-review-delete");
        }
    }

    const onKeyDownInputSearch = (event) => {
        if (event.key == "Enter") {
            list();
        }
    }


    const handelProductEdit = (type, productID) => {
        switch (type) {
            case appConfig.PRODUCT_TYPE.SERVICE.CODE:
                window.open(`/services/update/${productID}`, '_blank', 'noopener,noreferrer');
                break;
            case appConfig.PRODUCT_TYPE.DIGITAL.CODE:
                window.open(`/digital/update/${productID}`, '_blank', 'noopener,noreferrer');
                break;
            case appConfig.PRODUCT_TYPE.EVENT.CODE:
                window.open(`/events/update/${productID}`, '_blank', 'noopener,noreferrer');
                break;
            default:
                window.open(`/products/update/${productID}`, '_blank', 'noopener,noreferrer');
                break;
        }
    };

    const { totalRecord, totalPage } = productReviewReducer;

    let fromItem = 0;
    let toItem = 0;
    if ((productReviewReducer.reviews || []).length > 0) {
        fromItem = (activePage - 1) * selectedLimit + 1;
        toItem = fromItem + productReviewReducer.reviews.length - 1;
    }

    const isLoadingData = false;
    const isLoadingInfo = false;
    const msgShowItem = `${t("PAGINATION.SHOW")} ${fromItem} ${t("PAGINATION.TO")} ${toItem} ${t("PAGINATION.OF")} ${baseHelper.formatNumber(
        totalRecord
    )} ${t("PAGINATION.ITEMS")}`;

    const isLoadingProductView = productReviewReducer.loading && includes([productReviewTypes.CREATE_REVIEW, productReviewTypes.CREATE_REVIEWS, productReviewTypes.UPDATE_REVIEW], productReviewReducer.waiting);

    const listAction = [{ value: "DELETE", name: t("ACTIONS.DELETE") }];

    return (
        <div id="panel-product-reviews" className="page-content">
            <div>
                {isLoadingData ? (
                    <LoadingTable />
                ) : (
                    <div>
                        <div className="flex space-between">
                            {/* <h3 style={{ fontSize: "16px", paddingBottom: 0, fontWeight: 500 }}>Danh sách đánh giá</h3>
                             */}
                            <div className='ladiui search-group mr-24'>
                                <input
                                    id='keyword_search'
                                    className='ladiui search-field dropdown-toggle form-control search-width'
                                    name='searchName'
                                    placeholder={t('Tìm kiếm đánh giá')}
                                    aria-expanded='false'
                                    value={valueSearch}
                                    onChange={(event) => {
                                        setValueSearch(event.target.value)
                                    }}
                                    onKeyDown={onKeyDownInputSearch}
                                />
                                <i className='ladiui icon icon-search' onClick={() => list()}></i>
                            </div>
                            <button className="ladiui btn btn-primary flex" onClick={() => handleShowModalAddView()}>
                                <img src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" className="mr-8" />
                                <span>Tạo đánh giá</span>
                            </button>
                        </div>
                        {
                            totalRecord <= 0
                                ?
                                <div className="block-no-data">
                                    <div>
                                        <img src="https://w.ladicdn.com/ladiui/ladipage/icon-not-foder.svg" />
                                        <p>Chưa có đánh giá nào</p>
                                    </div>
                                </div>
                                :
                                <div className="ladi-card ladiui-table-responsive">
                                    <table className={`ladiui table text-left ${isLoadingInfo ? "loader" : ""}`}>
                                        <thead style={{ position: "relative" }}>
                                            <tr className="ladiui table-vertical header">
                                                <th scope="col" className="ladiui checkall">
                                                    <input
                                                        id="checkAllItem"
                                                        onClick={checkAllItem}
                                                        type="checkbox"
                                                        className="ladiui checkbox size-checkbox form-check-input-checkbox ladiui-checkall vertical-middle"
                                                    />
                                                </th>

                                                {selectedIDs && selectedIDs.length <= 0 ? (
                                                    <th scope="col" name="name" className={`text-left`}>
                                                        {"Nội dung"}
                                                    </th>
                                                ) : (
                                                    <th>
                                                        {"Nội dung"}
                                                        <LadiDropdownMenu
                                                            labelDropdown={"Chọn hành động"}
                                                            listProp={listAction}
                                                            defaultTextProp={t("ACTIONS.TITLE")}
                                                            hideLabel={true}
                                                            cbProp={eventSelectActions}
                                                            classSize="btn-sm "
                                                            classWidth="w-180"
                                                            classDropdown="w-180 action-record-table"
                                                        />
                                                    </th>
                                                )}
                                                <th scope="col" className={`text-left`}>
                                                    Sản phẩm
                                                </th>
                                                <th scope="col" className={`text-left`}>
                                                    Khách hàng
                                                </th>
                                                <th scope="col" className={`text-left`}>
                                                    Điểm đánh giá
                                                </th>
                                                <th scope="col" className={`text-left`}>
                                                    Thời gian
                                                </th>
                                                <th scope="col" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {map(productReviewReducer.reviews, (item) => {
                                                return (
                                                    <tr key={item.product_reviews_id} className="ladiui table-vertical main">
                                                        <td scope="row">
                                                            <input
                                                                type="checkbox"
                                                                onClick={(event) => checkItem(event, item.product_reviews_id)}
                                                                className="ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle"
                                                            />
                                                        </td>
                                                        <td>
                                                            <span className="limit-text-vertical" style={{ maxWidth: "500px" }}>{item.content}</span>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="limit-text-vertical" style={{ maxWidth: "300px" }}
                                                                onClick={() => handelProductEdit(item.product_type, item.product_id)}
                                                            >
                                                                {item.product_name ? item.product_name : ""}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <div className="flex">
                                                                <div className="avatar" style={{ width: "28px", height: "28px", borderRadius: "50%", marginRight: "8px", overflow: "hidden" }}>
                                                                    <img
                                                                        className="store-logo"
                                                                        src={item.avatar ? `${CDN}${item.avatar}` : "https://w.ladicdn.com/ladiui/ladisales/no-image.svg"}
                                                                        alt=""
                                                                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                                                    />
                                                                </div>
                                                                <span>{item.name ? item.name : ""}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {
                                                                item.point
                                                                &&
                                                                <div className="list-stars flex">
                                                                    {
                                                                        LIST_STARS.map((value) => {
                                                                            if (value > item.point) {
                                                                                return <i className="ldicon-star-secondary mr-8" />
                                                                            } else {
                                                                                return <i className="ldicon-star mr-8" />;
                                                                            }
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </td>
                                                        <td>{item.date_created ? baseHelper.formatDateToStr(item.date_created) : ""}</td>
                                                        <td className="text-right pd-0">
                                                            <div className="ladiui btn-group">
                                                                <div className="ladiui dropdown hide-mt ba-c">
                                                                    <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                                                                        <i className="ladiui icon icon-ldp-dot"></i>
                                                                    </button>
                                                                    <ul className="ladiui dropdown-menu r-0">
                                                                        <li onClick={() => handleEditReviewItem(item)}>
                                                                            <a className="ladiui dropdown-item">{t("ACTIONS.EDIT")}</a>
                                                                        </li>
                                                                        <li onClick={() => handleDeleteReviewItem(item.product_reviews_id)}>
                                                                            <a className="ladiui dropdown-item">{t("ACTIONS.DELETE")}</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            {totalRecord <= 0 && (
                                                <tr className="text-center">
                                                    <td colSpan="100%">{t("NO_DATA")}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                        }
                        {totalRecord > 0 && (
                            <LadiPagination
                                conditionsProp={{ page: activePage, limit: selectedLimit }}
                                listName={t("PAGINATION.ITEMS")}
                                message={""}
                                items={productReviewReducer.reviews || []}
                                total={totalRecord}
                                limitAction={onChangeLimit}
                                pageAction={onPageChange}
                            />
                        )}
                    </div>
                )}

                <ConfirmModal
                    id="confirm-product-review-delete"
                    title={"Xóa đánh giá?"}
                    content={"Đánh giá này sẽ được xóa vĩnh viễn. Bạn có chắc muốn xóa đánh giá này ?"}
                    cancelText={t("ACTIONS.CANCEL")}
                    okText={t("ACTIONS.DELETE")}
                    onOk={() => dispatch(productReviewActions.deleteReview(selectedID))}
                // isLoading={isLoadingDelete}
                />

                <ConfirmModal
                    id="confirm-product-review-deletes"
                    title={"Xóa đánh giá?"}
                    content={"Đánh giá này sẽ được xóa vĩnh viễn. Bạn có chắc muốn xóa những đánh giá này ?"}
                    cancelText={t("ACTIONS.CANCEL")}
                    okText={t("ACTIONS.DELETE")}
                    onOk={() => dispatch(productReviewActions.deleteReviews(selectedIDs))}
                // isLoading={isLoadingDelete}
                />

                {
                    isShowModalAddView
                    &&
                    <ModalAddProductView
                        isShow={isShowModalAddView}
                        onClose={() => setIsShowModalAddView(false)}
                        productID={props.productID || null}
                        handleCreateReview={handleCreateReview}
                        isLoading={isLoadingProductView}
                        handleCreateReviews={handleCreateReviews}
                        handleUpdateReview={handleUpdateReview}
                        selectedProductReview={currentProductReview || {}}
                        isManageReview={true}
                    />
                }
            </div>
        </div>
    );
}
