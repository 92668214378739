import React from "react";

import { connect } from "react-redux";

import { map, includes, remove, cloneDeep, compact, find } from "lodash";
import appConfig from "../../../../config/app";
import baseHelper from "../../../../helpers/BaseHelper";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import * as productTagTypes from "../../../../redux/futures/product_tag/types";
import productTagActions from "../../../../redux/futures/product_tag/actions";

import * as orderTagTypes from "../../../../redux/futures/order_tag/types";
import orderTagActions from "../../../../redux/futures/order_tag/actions";

import * as customerTagTypes from "../../../../redux/futures/customer_tag/types";
import customerTagActions from "../../../../redux/futures/customer_tag/actions";

import LoadingTable from "../../../../components/LoadingTable";
import { withTranslation } from "react-i18next";

import fileActions from "../../../../redux/futures/file/actions";
import * as fileTypes from "../../../../redux/futures/file/types";

import Modal from "../../../../components/Modal";
import Input from "../../../../components/Input";
import Image from "../../../../components/Image";
import ConfirmModal from "../../../../components/ConfirmModal";
import LadiPagination from "../../../../components/LadiPagination";

class Tag extends React.Component {
  constructor(props) {
    super(props);

    // this.tabs = {
    //     product: 'PRODUCT',
    //     order: 'ORDER',
    //     customer: 'CUSTOMER',
    // }
    const defaultConditions = {
      page: 1,
      limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
    };

    this.state = {
      activePage: 1,
      searchName: "",
      tabSelected: appConfig.TAG_SELECTED.TAG_PRODUCT.CODE,
      tags: [],
      tagSelected: {},
      isShowModal: false,
      isShowModalReorder: false,
      mode: null,
      typeSelected: appConfig.TAG_SELECTED.TAG_PRODUCT.CODE,
      keyword: "",
      selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
      conditions: defaultConditions,
    };

    this.selectedID = "";
    this.selectedIDs = [];
    this.inputsRef = new Set();
    this.tagSelectedBackup = {};
  }

  componentDidMount() {
    this.list();
  }

  componentDidUpdate() {
    window.LadiUI.init();
    setTimeout(() => {
      window.LadiUI.customInit();
    }, 0);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props === nextProps) {
      return;
    }

    if (this.props.productTagReducer.action != nextProps.productTagReducer.action) {
      if (nextProps.productTagReducer.action == productTagTypes.LIST_PRODUCT_TAG) {
        if (nextProps.productTagReducer.status) {
          this.setState({
            tags: nextProps.productTagReducer.allTags,
          });
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.productTagReducer.message);
        }
      }

      if (nextProps.productTagReducer.action == productTagTypes.UPDATE_PRODUCT_TAG) {
        if (nextProps.productTagReducer.status) {
          this.list();
          window.LadiUI.toastCustom("success", "", nextProps.productTagReducer.message);
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.productTagReducer.message);
        }
      }

      if (nextProps.productTagReducer.action == productTagTypes.CREATE_PRODUCT_TAG) {
        if (nextProps.productTagReducer.status) {
          this.setState({
            isShowModal: false,
          });

          if (this.state.tabSelected == appConfig.TAG_SELECTED.TAG_PRODUCT.CODE) {
            this.list();
          }

          window.LadiUI.toastCustom("success", "", nextProps.productTagReducer.message);
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.productTagReducer.message);
        }
      }

      if (nextProps.productTagReducer.action == productTagTypes.PRODUCT_TAG_DELETE) {
        if (nextProps.productTagReducer.status) {
          window.LadiUI.closeModal("confirm-tag-delete");
          this.list();
          window.LadiUI.toastCustom("success", "", nextProps.productTagReducer.message);
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.productTagReducer.message);
        }
      }

      if (nextProps.productTagReducer.action == productTagTypes.REORDER_PRODUCT_TAG) {
        if (nextProps.productTagReducer.status) {
          this.list();
          window.LadiUI.toastCustom("success", "", nextProps.productTagReducer.message);
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.productTagReducer.message);
        }
      }
    }

    if (this.props.customerTagReducer.action != nextProps.customerTagReducer.action) {
      if (nextProps.customerTagReducer.action == customerTagTypes.LIST_CUSTOMER_TAG) {
        if (nextProps.customerTagReducer.status) {
          this.setState({
            tags: nextProps.customerTagReducer.allTags,
          });
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.customerTagReducer.message);
        }
      }

      if (nextProps.customerTagReducer.action == customerTagTypes.UPDATE_CUSTOMER_TAG) {
        if (nextProps.customerTagReducer.status) {
          this.list();
          window.LadiUI.toastCustom("success", "", nextProps.customerTagReducer.message);
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.customerTagReducer.message);
        }
      }

      if (nextProps.customerTagReducer.action == customerTagTypes.CREATE_CUSTOMER_TAG) {
        if (nextProps.customerTagReducer.status) {
          this.setState({
            isShowModal: false,
          });
          if (this.state.tabSelected == appConfig.TAG_SELECTED.TAG_CUSTOMER.CODE) {
            this.list();
          }
          window.LadiUI.toastCustom("success", "", nextProps.customerTagReducer.message);
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.customerTagReducer.message);
        }
      }

      if (nextProps.customerTagReducer.action == customerTagTypes.CUSTOMER_TAG_DELETE) {
        if (nextProps.customerTagReducer.status) {
          this.list();
          window.LadiUI.closeModal("confirm-tag-delete");
          window.LadiUI.toastCustom("success", "", nextProps.customerTagReducer.message);
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.customerTagReducer.message);
        }
      }
    }

    if (this.props.orderTagReducer.action != nextProps.orderTagReducer.action) {
      if (nextProps.orderTagReducer.action == orderTagTypes.LIST_ORDER_TAG) {
        if (nextProps.orderTagReducer.status) {
          this.setState({
            tags: nextProps.orderTagReducer.allTags,
          });
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.orderTagReducer.message);
        }
      }

      if (nextProps.orderTagReducer.action == orderTagTypes.UPDATE_ORDER_TAG) {
        if (nextProps.orderTagReducer.status) {
          this.list();
          window.LadiUI.toastCustom("success", "", nextProps.orderTagReducer.message);
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.orderTagReducer.message);
        }
      }

      if (nextProps.orderTagReducer.action == orderTagTypes.CREATE_ORDER_TAG) {
        if (nextProps.orderTagReducer.status) {
          this.setState({
            isShowModal: false,
          });
          if (this.state.tabSelected == appConfig.TAG_SELECTED.TAG_ORDER.CODE) {
            this.list();
          }
          window.LadiUI.toastCustom("success", "", nextProps.orderTagReducer.message);
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.orderTagReducer.message);
        }
      }

      if (nextProps.orderTagReducer.action == orderTagTypes.ORDER_TAG_DELETE) {
        if (nextProps.orderTagReducer.status) {
          this.list();

          window.LadiUI.closeModal("confirm-tag-delete");
          window.LadiUI.toastCustom("success", "", nextProps.orderTagReducer.message);
        } else {
          window.LadiUI.toastCustom("danger", "", nextProps.orderTagReducer.message);
        }
      }
    }

    if (nextProps.fileReducer.action == fileTypes.UPLOAD_COVER_URL) {
      if (nextProps.fileReducer.status) {
        this.setState({
          tagSelected: {
            ...this.state.tagSelected,
            image_url: nextProps.fileReducer.cover_url,
          },
        });
      } else {
        window.LadiUI.toastCustom("danger", "", nextProps.fileReducer.message);
      }
    }
  }

  /**
   * list tags
   */
  list = (resetActivePageFlag = true) => {
    if (document.getElementById("checkAllItem")) {
      document.getElementById("checkAllItem").checked = false;
      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = false;
      });
    }

    const { searchName, selectedLimit, tabSelected, conditions } = this.state;
    let activePage = conditions.page;
    if (resetActivePageFlag) {
      activePage = 1;
      conditions.page = 1;
      this.setState({
        conditions,
      });
    }

    // Reset selectedIDs
    this.selectedIDs = [];

    const data = {
      search: {
        name: searchName,
      },
      paged: activePage,
      limit: conditions.limit,
    };

    switch (tabSelected) {
      case appConfig.TAG_SELECTED.TAG_PRODUCT.CODE:
        this.props.listProductTag(data);
        break;
      case appConfig.TAG_SELECTED.TAG_CUSTOMER.CODE:
        this.props.listCustomerTag(data);
        break;
      case appConfig.TAG_SELECTED.TAG_ORDER.CODE:
        this.props.listOrderTag(data);
        break;
    }
  };

  /*******************************SEARCH***************************/
  onChangeInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  /**
   * Tim kiem theo name
   */
  onKeyDownInput = (event) => {
    if (event.key === "Enter") {
      this.list();
    }
  };

  /****************************************************************/

  /*******************************PAGINATION***************************/
  /**
   * Change limit
   */
  onChangeLimit = (option) => {
    let { conditions } = this.state;
    conditions.limit = option.value;

    // this.setState({ selectedLimit: option.value }, () => {
    this.setState({ conditions: conditions }, () => {
      this.list();
    });
  };

  /**
   * Change page
   */
  onPageChange = (pageNumber) => {
    let { conditions } = this.state;
    conditions.page = pageNumber;

    this.setState({ conditions }, () => {
      this.list(false);
    });
  };
  /****************************************************************/

  /*******************************CHECK AND ACTIONS***************************/
  // checkAllItem = (event) => {
  //     const { checked } = event.target;

  //     this.selectedIDs = [];
  //     if (checked) {
  //         switch (this.state.tabSelected) {
  //             case appConfig.TAG_SELECTED.TAG_PRODUCT.CODE:
  //                 this.selectedIDs = map(this.state.tags, item => item.product_tag_id);
  //                 break;
  //             case appConfig.TAG_SELECTED.TAG_CUSTOMER.CODE:
  //                 this.selectedIDs = map(this.state.tags, item => item.customer_tag_id);
  //                 break;
  //             case appConfig.TAG_SELECTED.TAG_ORDER.CODE:
  //                 this.selectedIDs = map(this.state.tags, item => item.order_tag_id);
  //                 break;
  //             default:
  //         }

  //         window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
  //             e.checked = true;
  //         });
  //     } else {
  //         window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
  //             e.checked = false;
  //         });
  //     }
  // }

  // checkItem = (event, item) => {
  //     const { checked } = event.target;
  //     const { selectedIDs } = this;

  //     let tagID;

  //     switch (this.state.tabSelected) {
  //         case appConfig.TAG_SELECTED.TAG_PRODUCT.CODE:
  //             tagID = item.product_tag_id;
  //             break;
  //         case appConfig.TAG_SELECTED.TAG_CUSTOMER.CODE:
  //             tagID = item.customer_tag_id;
  //             break;
  //         case appConfig.TAG_SELECTED.TAG_ORDER.CODE:
  //             tagID = item.order_tag_id;
  //             break;
  //     }

  //     if (checked) {
  //         if (!includes(selectedIDs, tagID)) {
  //             selectedIDs.push(tagID);
  //         }

  //         if (selectedIDs.length == this.state.tags.length) {
  //             document.getElementById("checkAllItem").checked = true;
  //         }
  //     } else {
  //         document.getElementById("checkAllItem").checked = false;
  //         remove(selectedIDs, item => item == tagID);
  //     }
  // }

  deletes = () => {
    if (this.selectedIDs.length <= 0) {
      window.LadiUI.toastCustom("danger", "", this.props.t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
      return;
    }

    switch (this.state.tabSelected) {
      case appConfig.TAG_SELECTED.TAG_PRODUCT.CODE:
        this.props.deleteProductTags(this.selectedIDs);
        break;
      case appConfig.TAG_SELECTED.TAG_CUSTOMER.CODE:
        this.props.deleteCustomerTags(this.selectedIDs);
        break;
      case appConfig.TAG_SELECTED.TAG_ORDER.CODE:
        this.props.deleteOrderTags(this.selectedIDs);
        break;
    }
  };

  deleteItem = (selectedID) => {
    switch (this.state.tabSelected) {
      case appConfig.TAG_SELECTED.TAG_PRODUCT.CODE:
        this.props.deleteProductTag(selectedID);
        break;
      case appConfig.TAG_SELECTED.TAG_CUSTOMER.CODE:
        this.props.deleteCustomerTag(selectedID);
        break;
      case appConfig.TAG_SELECTED.TAG_ORDER.CODE:
        this.props.deleteOrderTag(selectedID);
        break;
    }
  };

  openModalEdit = (tagSelected) => {
    this.setState({
      typeSelected: this.state.tabSelected,
      tagSelected: cloneDeep(tagSelected),
      isShowModal: true,
      mode: appConfig.FORM_MODE.EDIT,
    });
  };

  openModalCreate = () => {
    this.setState({
      tagSelected: {
        color: appConfig.TAG_COLORS[0],
      },
      isShowModal: true,
      mode: appConfig.FORM_MODE.CREATE,
    });
  };

  openModalReorderProduct = (tagSelected) => {
    this.setState({
      tagSelected: cloneDeep(tagSelected),
      isShowModalReorder: true,
    });

    this.tagSelectedBackup = cloneDeep(tagSelected);
  };

  submit = (event) => {
    event.preventDefault();
    // Validate
    let errors = [];
    const inputsRef = compact(Array.from(this.inputsRef));
    map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });

    errors = compact(errors);
    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      window.LadiUI.toastCustom("danger", "", errorMessage);
      return;
    }

    const { tagSelected, mode } = this.state;
    let data = {
      name: tagSelected.name,
    };

    if (mode == appConfig.FORM_MODE.CREATE) {
      switch (this.state.typeSelected) {
        case appConfig.TAG_SELECTED.TAG_PRODUCT.CODE:
          data = {
            name: tagSelected.name,
            image_url: tagSelected.image_url,
          };
          this.props.createProductTag(data);
          break;
        case appConfig.TAG_SELECTED.TAG_CUSTOMER.CODE:
          this.props.createCustomerTag(data);
          break;
        case appConfig.TAG_SELECTED.TAG_ORDER.CODE:
          data.color = tagSelected.color;
          this.props.createOrderTag(data);
          break;
      }
    } else if (mode == appConfig.FORM_MODE.EDIT) {
      if (tagSelected.product_tag_id) {
        data.product_tag_id = tagSelected.product_tag_id;
        data.image_url = tagSelected.image_url;
        this.props.updateProductTag(data);
        return;
      }

      if (tagSelected.customer_tag_id) {
        data.customer_tag_id = tagSelected.customer_tag_id;
        this.props.updateCustomerTag(data);
        return;
      }

      if (tagSelected.order_tag_id) {
        data.order_tag_id = tagSelected.order_tag_id;
        data.color = tagSelected.color;
        this.props.updateOrderTag(data);
        return;
      }
    }
  };

  onChangeTab = (tabID) => {
    setTimeout(() => {
      this.setState(
        {
          tabSelected: tabID,
          typeSelected: tabID,
        },
        () => {
          this.list();
        }
      );
    }, 200);
  };

  geTypeName = (value) => {
    const matchedDiscount = find(appConfig.TAG_SELECTED, (item) => item.CODE == value);
    return matchedDiscount.NAME;
  };

  openDeleteModal = (item) => {
    this.selectedID = item.product_tag_id || item.customer_tag_id || item.order_tag_id;
    window.LadiUI.showModal("confirm-tag-delete");
  };

  reOrderProduct = () => {
    const { tagSelected } = this.state;
    const positions = map(tagSelected.products, (item) => item.product_tag_value_id);
    this.props.reOrderProduct({
      product_tag_id: tagSelected.product_tag_id,
      positions,
    });
  };

  onChangeKeyword = (event) => {
    this.setState({
      keyword: event.target.value,
    });
  };

  getModalProducts = () => {
    const { tagSelected, keyword } = this.state;
    const { t } = this.props;
    const isLoading = this.props.productTagReducer.loading && this.props.productTagReducer.waitting == productTagTypes.REORDER_PRODUCT_TAG;

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const items = reorder(tagSelected.products, result.source.index, result.destination.index);

      this.setState({
        tagSelected: {
          ...tagSelected,
          products: items,
        },
      });
    };

    const onPin = (index) => {
      const items = reorder(tagSelected.products, index, 0);

      this.setState({
        tagSelected: {
          ...tagSelected,
          products: items,
        },
      });
    };

    if (!this.state.isShowModalReorder) {
      return null;
    }

    const isMatched = (name) => {
      if (
        baseHelper.getAliasName(name).indexOf(baseHelper.getAliasName(keyword)) >= 0 ||
        baseHelper.getAliasName(keyword).indexOf(baseHelper.getAliasName(name)) >= 0
      ) {
        return true;
      }
      return false;
    };

    const FilteredProduct = () => {
      return tagSelected.products.map((item, index) => (
        <Draggable key={item.product_tag_value_id} draggableId={item.product_tag_value_id} index={index}>
          {(provided, snapshot) => (
            <tr
              className="ladiui table-vertical main"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                ...provided.draggableProps.style,
                userSelect: "none",
                background: snapshot.isDragging ? "#e8f0fe" : "none",
                display: isMatched(item.name) ? "table-row" : "none",
              }}
            >
              <td className="text-center">{index + 1}</td>
              <td className="text-left">
                <a href={`/products?product_id=${item.product_id}`}>{item.name}</a>
              </td>
              <td style={{ cursor: "pointer" }}>
                <i className="ladi-icon icon-arrow-up" onClick={() => onPin(index)} />
              </td>
            </tr>
          )}
        </Draggable>
      ));
    };
    return (
      <Modal
        id="modal-tag-edit"
        title={t("TAGS.PRODUCT_REORDER_TILE")}
        visible={this.state.isShowModalReorder}
        onCancel={() =>
          this.setState({
            isShowModalReorder: false,
            tagSelected: cloneDeep(this.tagSelectedBackup),
          })
        }
        onOk={this.reOrderProduct}
        width={600}
        isLoading={isLoading}
        disableOk={baseHelper.isEmpty(tagSelected.products)}
      >
        {baseHelper.isEmpty(tagSelected.products) ? (
          <div>{t("NO_DATA")}</div>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div className="ladi-card ladiui-table-responsive" style={{ overflowY: "scroll", maxHeight: 500 }}>
                  <div className="ladiui search-keyword">
                    <Input
                      id="keyword"
                      className="ladiui form-control"
                      name="keyword"
                      placeholder={t("PRODUCTS.SEARCH_PRODUCT")}
                      value={keyword}
                      onChange={this.onChangeKeyword}
                    />
                    <i className="ladi-icon icon-zoom" onClick={this.list} />
                  </div>
                  <table
                    className="ladiui table text-left order-table"
                    ref={provided.innerRef}
                    style={{
                      background: snapshot.isDragging ? "#e8f0fe" : "none",
                    }}
                  >
                    <thead>
                      <tr className="ladiui table-vertical header">
                        <th scope="col" style={{ width: 100 }} className="text-center">
                          {t("TAGS.STT")}
                        </th>
                        <th scope="col" className="text-left">
                          {t("TAGS.PRODUCT_NAME")}
                        </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      <FilteredProduct />
                      {provided.placeholder}
                    </tbody>
                  </table>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Modal>
    );
  };

  fileChangedHandler = (event, type) => {
    const files = event.target.files;
    const form = baseHelper.getFormDataUpload(files);
    if (form) {
      if (form) {
        switch (type) {
          case "logo":
            this.props.uploadLogo(form);
            break;
          case "favicon":
            this.props.uploadFavicon(form);
            break;
          case "cover":
            this.props.uploadCover(form);
            break;
        }
      }
    }
  };

  render() {
    const { t } = this.props;
    let totalRecord = 0;
    switch (this.state.tabSelected) {
      case appConfig.TAG_SELECTED.TAG_PRODUCT.CODE:
        totalRecord = this.props.productTagReducer.totalRecord;
        break;
      case appConfig.TAG_SELECTED.TAG_CUSTOMER.CODE:
        totalRecord = this.props.customerTagReducer.totalRecord;
        break;
      case appConfig.TAG_SELECTED.TAG_ORDER.CODE:
        totalRecord = this.props.orderTagReducer.totalRecord;
        break;
    }

    const { activePage, selectedLimit, searchName, conditions } = this.state;
    let fromItem = 0;
    let toItem = 0;
    if ((this.state.tags || []).length > 0) {
      fromItem = (conditions.page - 1) * conditions.limit + 1;
      toItem = fromItem + this.state.tags.length - 1;
    }

    const msgShowItem = `${t("PAGINATION.SHOW")} ${fromItem} ${t("PAGINATION.TO")} ${toItem} ${t(
      "PAGINATION.OF"
    )} ${baseHelper.formatNumber(totalRecord)} ${t("PAGINATION.ITEMS")}`;

    const isLoadingData =
      (this.props.productTagReducer.loading && this.props.productTagReducer.waiting == productTagTypes.LIST_PRODUCT_TAG) ||
      (this.props.customerTagReducer.loading && this.props.customerTagReducer.waiting == customerTagTypes.LIST_CUSTOMER_TAG) ||
      (this.props.orderTagReducer.loading && this.props.orderTagReducer.waiting == orderTagTypes.LIST_ORDER_TAG);

    const isLoadingSubmit =
      (this.props.productTagReducer.loading && this.props.productTagReducer.waiting == productTagTypes.UPDATE_PRODUCT_TAG) ||
      (this.props.customerTagReducer.loading && this.props.customerTagReducer.waiting == customerTagTypes.UPDATE_CUSTOMER_TAG) ||
      (this.props.orderTagReducer.loading && this.props.orderTagReducer.waiting == orderTagTypes.UPDATE_ORDER_TAG) ||
      (this.props.productTagReducer.loading && this.props.productTagReducer.waiting == productTagTypes.CREATE_PRODUCT_TAG) ||
      (this.props.customerTagReducer.loading && this.props.customerTagReducer.waiting == customerTagTypes.CREATE_CUSTOMER_TAG) ||
      (this.props.orderTagReducer.loading && this.props.orderTagReducer.waiting == orderTagTypes.CREATE_ORDER_TAG);

    const isLoadingDelete =
      (this.props.productTagReducer.loading && this.props.productTagReducer.waiting == productTagTypes.PRODUCT_TAG_DELETE) ||
      (this.props.customerTagReducer.loading && this.props.customerTagReducer.waiting == customerTagTypes.CUSTOMER_TAG_DELETE) ||
      (this.props.orderTagReducer.loading && this.props.orderTagReducer.waiting == orderTagTypes.ORDER_TAG_DELETE);
    return (
      <div id="page-tags" className="page-tags page-setting">
        <div className="block-title-action">
          <h1 className="title">{t("TAGS.TITLE")}</h1>
          <div className="btn-action">
            <button className="ladiui btn btn-primary btn-sm" onClick={this.openModalCreate}>
              <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />

              {t("TAGS.ADD_TAG")}
            </button>
          </div>
        </div>
        <div>
          <div className="ladiui-table-actions mb-24">
            <div className="action-item">
              <div className="ladiui search-keyword" style={{ width: 248 }}>
                <input
                  type="text"
                  id="search"
                  placeholder={t("TAGS.SEARCH_TAG")}
                  name="searchName"
                  value={searchName}
                  onChange={this.onChangeInput}
                  onKeyDown={this.onKeyDownInput}
                  className="ladiui form-control"
                />
                <i className="ladi-icon icon-zoom" onClick={this.list} />
              </div>
            </div>
          </div>
          <div className="ladiui tab">
            <ul className="ladiui nav nav-tabs" id="myTab" role="tablist">
              <li className="ladiui nav-item" id="tabProduct">
                <span
                  className="ladiui tab-link active"
                  id="tab-product"
                  data-toggle="tab"
                  data-parent="myTab"
                  data-target="tab-product"
                  onClick={() => this.onChangeTab("PRODUCT")}
                >
                  {t("TAGS.TAB_PRODUCT")}
                </span>
              </li>
              <li className="ladiui nav-item" id="tabCustomer">
                <span
                  className="ladiui tab-link"
                  id="tab-customer"
                  data-toggle="tab"
                  data-parent="myTab"
                  data-target="tab-customer"
                  onClick={() => this.onChangeTab("CUSTOMER")}
                >
                  {t("TAGS.TAB_CUSTOMER")}
                </span>
              </li>
              <li className="ladiui nav-item" id="tabOrder">
                <span
                  className="ladiui tab-link"
                  id="tab-order"
                  data-toggle="tab"
                  data-parent="myTab"
                  data-target="tab-order"
                  onClick={() => this.onChangeTab("ORDER")}
                >
                  {t("TAGS.TAB_ORDER")}
                </span>
              </li>
            </ul>
          </div>
          {isLoadingData ? (
            <LoadingTable />
          ) : (
            <div>
              <div className="ladi-card ladiui-table-responsive">
                <table className="ladiui table text-left order-table">
                  <thead>
                    <tr className="ladiui table-vertical header">
                      {/* <th scope="col" className="ladiui checkall text-center">
                                                    <input id="checkAllItem" onClick={this.checkAllItem} type="checkbox" className="ladiui checkbox ladiui-checkall" />
                                                </th> */}
                      <th scope="col">{t("TAGS.COL_TAG")}</th>
                      {this.state.tabSelected == appConfig.TAG_SELECTED.TAG_PRODUCT.CODE && <th scope="col">{t("TAGS.COL_PRODUCT")}</th>}
                      {this.state.tabSelected == appConfig.TAG_SELECTED.TAG_CUSTOMER.CODE && <th scope="col">{t("TAGS.COL_CUSTOMER")}</th>}
                      {this.state.tabSelected == appConfig.TAG_SELECTED.TAG_ORDER.CODE && <th scope="col">{t("TAGS.COL_ORDER")}</th>}
                      <th scope="col">{t("COMMON.CREATED_AT")}</th>
                      <th scope="col">{t("COMMON.UPDATED_AT")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(this.state.tags, (item, index) => {
                      return (
                        <tr key={index} className="ladiui table-vertical main">
                          {/* <td scope="row" className="text-center">
                                                            <input type="checkbox" onClick={(event) => this.checkItem(event, item)} className="ladiui checkbox size-checkbox form-check-input-checkbox" />
                                                        </td> */}

                          {this.state.tabSelected != appConfig.TAG_SELECTED.TAG_ORDER.CODE ? (
                            <td>
                              <a onClick={() => this.openModalEdit(item)}>{item.name}</a>
                            </td>
                          ) : (
                            <td>
                              <span
                                onClick={() => this.openModalEdit(item)}
                                style={item.color ? { backgroundColor: item.color } : {}}
                                className="order-tag"
                              >
                                {item.name}
                              </span>
                            </td>
                          )}

                          <td>{item.quantity}</td>
                          <td>{baseHelper.formatStrToDate(item.created_at)}</td>
                          <td>{baseHelper.formatStrToDate(item.updated_at)}</td>
                          <td className="text-right pd-0">
                            <div className="ladiui btn-group">
                              <div className="ladiui dropdown hide-mt ba-c">
                                <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                                  <i className="ladiui icon icon-ldp-dot"></i>
                                </button>
                                <ul className="ladiui dropdown-menu r-0">
                                  <li>
                                    <a
                                      className="ladiui dropdown-item"
                                      onClick={() => {
                                        this.openModalEdit(item);
                                      }}
                                    >
                                      {t("ACTIONS.EDIT")}
                                    </a>
                                  </li>
                                  <li>
                                    <a className="ladiui dropdown-item" onClick={() => this.openDeleteModal(item)}>
                                      {t("ACTIONS.DELETE")}
                                    </a>
                                  </li>
                                  {this.state.tabSelected == appConfig.TAG_SELECTED.TAG_PRODUCT.CODE && (
                                    <li>
                                      <a
                                        className="ladiui dropdown-item"
                                        onClick={() => {
                                          this.openModalReorderProduct(item);
                                        }}
                                      >
                                        {t("TAGS.PRODUCT_REORDER_TILE")}
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                    {totalRecord <= 0 && (
                      <tr className="text-center">
                        <td colSpan="100%">{t("NO_DATA")}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {totalRecord > 0 && (
                <LadiPagination
                  conditionsProp={conditions}
                  listName={t("PAGINATION.ITEMS")}
                  message={""}
                  items={this.state.tags}
                  total={totalRecord}
                  limitAction={this.onChangeLimit}
                  pageAction={this.onPageChange}
                />
              )}
            </div>
          )}
        </div>

        {this.state.isShowModal && (
          <Modal
            id="modal-tag-edit"
            title={this.state.mode == appConfig.FORM_MODE.CREATE ? t("TAGS.ADD_TAG") : t("TAGS.EDIT")}
            visible={this.state.isShowModal}
            onCancel={() =>
              this.setState({
                isShowModal: false,
              })
            }
            onOk={this.submit}
            width={400}
            isLoading={isLoadingSubmit}
            bodyStyles={this.state.mode == appConfig.FORM_MODE.CREATE ? { minHeight: "25vh" } : {}}
          >
            <div className="ladiui form-group">
              <label className="ladiui-label" style={{ marginBottom: "8px" }}>
                {t("TAGS.COL_TAG")}
              </label>
              <Input
                value={this.state.tagSelected.name}
                onChange={(event) => {
                  this.setState({
                    tagSelected: {
                      ...this.state.tagSelected,
                      name: event.target.value,
                    },
                  });
                }}
                validationName={t("TAGS.COL_TAG")}
                validations={{ isRequired: true }}
                ref={(ref) => this.inputsRef.add(ref)}
              />
            </div>
            {this.state.mode == appConfig.FORM_MODE.CREATE && (
              <div className="ladiui form-group data-status">
                <label className="ladiui-label" style={{ marginBottom: "8px" }}>
                  {t("TAGS.CATEGORY")}
                </label>

                <div className="ladiui list-type-tags" style={{ width: "100%" }}>
                  <div
                    className="list-content"
                    style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                  >
                    {map(appConfig.TAG_SELECTED, (item, index) => (
                      <div key={index} className="item-check" style={{ display: "flex", alignItems: "center" }}>
                        <input
                          id="autoPercent"
                          type="radio"
                          name="selected-type-tags"
                          className="ladiui form-check-input"
                          onChange={() => {
                            this.setState({ typeSelected: item.CODE });
                          }}
                          checked={this.state.typeSelected == item.CODE}
                        />
                        <label htmlFor="autoPercent">{item.NAME}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {this.state.typeSelected == appConfig.TAG_SELECTED.TAG_ORDER.CODE &&
              this.state.tagSelected.color &&
              this.state.tagSelected.name && (
                <span className="order-tag-item" style={{ color: "var(--main-default)", backgroundColor: this.state.tagSelected.color }}>
                  {this.state.tagSelected.name}
                </span>
              )}

            {this.state.typeSelected == appConfig.TAG_SELECTED.TAG_ORDER.CODE && (
              <div className="form-default-color">
                <label className="ladiui-label">{t("TAGS.PICK_COLOR")}</label>
                <div className="list-pick-color-tag-customer">
                  {map(appConfig.TAG_COLORS, (item) => (
                    <span
                      key={item}
                      className="item"
                      style={{ backgroundColor: item }}
                      onClick={() => {
                        this.setState({
                          tagSelected: {
                            ...this.state.tagSelected,
                            color: item,
                          },
                        });
                      }}
                    >
                      {item == this.state.tagSelected.color && <i className="ladi-icon icon-d-check" />}
                    </span>
                  ))}
                </div>
              </div>
            )}

            {this.state.typeSelected == appConfig.TAG_SELECTED.TAG_PRODUCT.CODE && (
              <div className="ladiui form-group mb-0">
                <div className="text-center image setting-block-image">
                  <div className="block-tag-image" style={{ width: "100%", height: "360px" }}>
                    <Image src={this.state.tagSelected.image_url} />
                  </div>
                  <div className="ladiui-button-upload image block-upload-image">
                    <div className="block-overlay" style={{ marginTop: "130px" }}>
                      <button type="button" className="ladiui btn btn-primary icon">
                        <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-gallery.svg" alt="" />

                        <p>
                          Bấm vào đây để chọn ảnh sản phẩm <br /> từ máy tính của bạn
                        </p>
                      </button>
                      <input
                        type="file"
                        name="file"
                        accept="image/*"
                        onChange={(event) => this.fileChangedHandler(event, "cover")}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Modal>
        )}

        {this.getModalProducts()}

        <ConfirmModal
          id="confirm-tag-delete"
          title={t("TAGS.MSG_DELETE_TAG_TITLE")}
          content={t("TAGS.MSG_DELETE_TAG_CONTENT")}
          cancelText={t("ACTIONS.CANCEL")}
          okText={t("ACTIONS.DELETE")}
          onOk={() => this.deleteItem(this.selectedID)}
          isLoading={isLoadingDelete}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listProductTag: (data) => dispatch(productTagActions.list(data)),
    listCustomerTag: (data) => dispatch(customerTagActions.list(data)),
    listOrderTag: (data) => dispatch(orderTagActions.list(data)),
    updateProductTag: (data) => dispatch(productTagActions.update(data)),
    updateCustomerTag: (data) => dispatch(customerTagActions.update(data)),
    updateOrderTag: (data) => dispatch(orderTagActions.update(data)),
    createProductTag: (data) => dispatch(productTagActions.create(data)),
    createCustomerTag: (data) => dispatch(customerTagActions.create(data)),
    createOrderTag: (data) => dispatch(orderTagActions.create(data)),
    deleteProductTag: (data) => dispatch(productTagActions.deleteTag(data)),
    deleteCustomerTag: (data) => dispatch(customerTagActions.deleteTag(data)),
    deleteOrderTag: (data) => dispatch(orderTagActions.deleteTag(data)),
    reOrderProduct: (data) => dispatch(productTagActions.reOrder(data)),
    uploadCover: (form) => dispatch(fileActions.uploadCover(form)),
  };
};

const mapStateToProps = (state) => ({
  productTagReducer: { ...state.productTag },
  customerTagReducer: { ...state.customerTag },
  orderTagReducer: { ...state.orderTag },
  fileReducer: { ...state.file },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Tag));
