import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const deleteVariant = (productID, variantID) => ({
    type: types.DELETE_VARIANT,
    meta: {
        endpoint: endpoint.VARIANT_DELETE_ID,
        method: REQUEST_METHOD.POST,
        body: {
            variant: {
                product_id: productID,
                variant_id: variantID,
            },
        },
        hasAuth: true,
    },
});

const create = (variant) => ({
    type: types.CREATE_VARIANT,
    meta: {
        endpoint: endpoint.VARIANT_CREATE,
        method: REQUEST_METHOD.POST,
        body: {
            variant,
        },
        hasAuth: true,
    },
});

const update = (variant) => ({
    type: types.UPDATE_VARIANT,
    meta: {
        endpoint: endpoint.VARIANT_UPDATE,
        method: REQUEST_METHOD.POST,
        body: {
            variant,
        },
        hasAuth: true,
    },
});

const changeImage = (variant) => ({
    type: types.CHANGE_IMAGE_VARIANT,
    meta: {
        endpoint: endpoint.VARIANT_CHANGE_IMAGE,
        method: REQUEST_METHOD.POST,
        body: {
            variant,
        },
        hasAuth: true,
    },
});

const show = (productID, variantID) => ({
    type: types.SHOW_VARIANT,
    meta: {
        endpoint: endpoint.VARIANT_SHOW,
        method: REQUEST_METHOD.POST,
        body: {
            variant: {
                product_id: productID,
                variant_id: variantID,
            },
        },
        hasAuth: true,
    },
});

const searchVariant = (name, ignoreProductIDs = [], productType, search_type = 'UpSell') => ({
    type: types.SEARCH_VARIANT,
    meta: {
        endpoint: endpoint.VARIANT_SEARCH,
        method: REQUEST_METHOD.POST,
        body: {
            search: name,
            ignore_product_ids: ignoreProductIDs,
            product_type: productType,
            search_type,
        },
        hasAuth: true,
    },
});

const getVariantInfos = (productID) => ({
    type: types.GET_VARIANT_INFOS,
    meta: {
        endpoint: endpoint.GET_VARIANT_INFOS,
        method: REQUEST_METHOD.POST,
        body: {
            product_id: productID,
        },
        hasAuth: true,
    },
});

const reOrder = (params) => ({
    type: types.RE_ORDER_VARIANT,
    meta: {
        endpoint: endpoint.RE_ORDER_VARIANT,
        method: REQUEST_METHOD.POST,
        body: params,
        hasAuth: true,
    },
});

const ticketCreationFrom = (ticketCreationForm) => ({
    type: types.TICKET_CREATION_FORM,
    payload: {
        ticket_creation_form: ticketCreationForm,
    },
    // type: types.TICKET_CREATION_FORM,
    // ticket_creation_form: ticketCreationForm
});

export default {
    delete: deleteVariant,
    create,
    update,
    changeImage,
    show,
    search: searchVariant,
    getVariantInfos,
    reOrder,
    ticketCreationFrom,
};
