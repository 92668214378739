import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = { stores: [], userInfo: { currentStore: {} } }, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            }
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            }
        }
        case types.LIST_CHECKOUT_CONFIG: {
            return {
                ...state,
                action: types.LIST_CHECKOUT_CONFIG,
                checkout_configs: action.status ? action.payload : [],
                status: action.status,
                message: action.message,
            }
        }
        case types.UPDATE_CHECKOUT_CONFIG: {
            return {
                ...state,
                action: types.UPDATE_CHECKOUT_CONFIG,
                status: action.status,
                message: action.message,
            }
        }
        case types.CREATE_CHECKOUT_CONFIG: {
            return {
                ...state,
                action: types.CREATE_CHECKOUT_CONFIG,
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_CHECKOUT_CONFIG: {
            return {
                ...state,
                action: types.DELETE_CHECKOUT_CONFIG,
                status: action.status,
                message: action.message,
            }
        }
        case types.MARK_DEFAULT_CHECKOUT_CONFIG: {
            return {
                ...state,
                action: types.MARK_DEFAULT_CHECKOUT_CONFIG,
                status: action.status,
                message: action.message,
            }
        }
        case types.GET_CHECKOUT_CONFIG: {
            return {
                ...state,
                action: types.GET_CHECKOUT_CONFIG,
                status: action.status,
                message: action.message,
                checkout_config: action.status ? action.payload : {},
            }
        }
        default:
            return state;
    }
}

