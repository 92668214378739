import * as types from "./types";
import * as commonTypes from "../common/types";

export default (state = {}, action) => {
  switch (action.type) {
    case commonTypes.ASYNC_START: {
      if (!types[action.waiting]) {
        return state;
      }

      return {
        ...state,
        loading: true,
        waiting: action.waiting,
        action: commonTypes.ASYNC_START,
      };
    }
    case commonTypes.ASYNC_END: {
      if (!types[action.done]) {
        return state;
      }

      return {
        ...state,
        waiting: null,
        loading: false,
        action: commonTypes.ASYNC_END,
      };
    }
    case types.PRODUCT_IMAGE_DELETE: {
      return {
        ...state,
        action: types.PRODUCT_IMAGE_DELETE,
        status: action.status,
        message: action.message,
      };
    }
    case types.PRODUCT_IMAGE_CREATE: {
      return {
        ...state,
        action: types.PRODUCT_IMAGE_CREATE,
        status: action.status,
        message: action.message,
        images: action.status ? action.payload.images : [],
      };
    }
    case types.PRODUCT_UPDATE_POSITION: {
      return {
        ...state,
        action: types.PRODUCT_UPDATE_POSITION,
        status: action.status,
        message: action.message,
      };
    }
    default:
      return state;
  }
};
