export const LIST_ORDER = "LIST_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const CANCEL_ORDERS = "CANCEL_ORDERS";
export const CREATE_ORDER = "CREATE_ORDER";
export const GET_LINK_CHECKOUT_CREATE = "GET_LINK_CHECKOUT_CREATE";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const GET_LINK_UPDATE_CHECKOUT = "GET_LINK_UPDATE_CHECKOUT";
export const UPDATE_ORDER_ADDRESS = "UPDATE_ORDER_ADDRESS";
export const SHIPPING_UPDATE_ORDER_ADDRESS = "SHIPPING_UPDATE_ORDER_ADDRESS";
export const PARTIAL_UPDATE_ORDER = "PARTIAL_UPDATE_ORDER";
export const GENERATE_CHECKOUT_ORDER = "GENERATE_CHECKOUT_ORDER";
export const SHOW_ORDER = "SHOW_ORDER";
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
export const FULLFILL_ORDER = "FULLFILL_ORDER";
export const RELOAD_ORDER = "RELOAD_ORDER";
export const UPDATE_ORDER_CUSTOMER_INFO = "UPDATE_ORDER_CUSTOMER_INFO";
export const ASSIGN_ORDER = "ASSIGN_ORDER";
export const ASSIGN_TO_OTHER_ORDER = "ASSIGN_TO_OTHER_ORDER";
export const LIST_MY_ORDER = "LIST_MY_ORDER";
export const REFUND_ORDER = "REFUND_ORDER";
export const EXPORT_ORDER = "EXPORT_ORDER";
export const EXPORT_TRANSACTION = "EXPORT_TRANSACTION";
export const WS_APPEND_NEW_ORDER = "WS_APPEND_NEW_ORDER";
export const WS_ASSIGNED_ORDER = "WS_ASSIGNED_ORDER";
export const WS_UPDATED_ORDER = "WS_UPDATED_ORDER";
export const MARK_AS_PAID = "MARK_AS_PAID";
export const SUGGEST_REFUND = "SUGGEST_REFUND";
export const UPDATE_TAG = "UPDATE_TAG";
export const UPDATE_SPAMS = "UPDATE_SPAMS";
export const REMOVE_DUPLICATION_ORDER = "REMOVE_DUPLICATION_ORDER";
export const MARK_AS_PAIDS = "MARK_AS_PAIDS";
export const RESEND_TICKET_LIST = "RESEND_TICKET_LIST";
