import { REQUEST_METHOD } from '../../middlewares/services';
import * as types from './types';
import { endpoint } from '../../../config/app';

const listFormAccount = (data) => ({
    type: types.INTEGRATE_FORM_ACCOUNT_LIST,
    meta: {
        endpoint: endpoint.INTEGRATE_LIST_FORM_ACCOUNT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const show = (formAccountID) => ({
    type: types.INTEGRATE_FORM_ACCOUNT_SHOW,
    meta: {
        endpoint: endpoint.INTEGRATE_SHOW_FORM_ACCOUNT,
        method: REQUEST_METHOD.POST,
        body: {
            id: formAccountID
        },
        hasAuth: true,
    }
});

const update = (formAccount) => ({
    type: types.INTEGRATE_FORM_ACCOUNT_UPDATE,
    meta: {
        endpoint: endpoint.INTEGRATE_UPDATE_FORM_ACCOUNT,
        method: REQUEST_METHOD.POST,
        body: formAccount,
        hasAuth: true,
    }
});

const deleteFormAccount = (formAccountID) => ({
    type: types.INTEGRATE_FORM_ACCOUNT_DELETES,
    meta: {
        endpoint: endpoint.INTEGRATE_DELETES_FORM_ACCOUNT,
        method: REQUEST_METHOD.POST,
        body: {
            id: formAccountID
        },
        hasAuth: true,
    }
});

const oauth2GetLink = (type) => ({
    type: types.INTEGRATE_OAUTH2_GET_LINK,
    meta: {
        endpoint: endpoint.INTEGRATE_OAUTH2_GET_LINK,
        method: REQUEST_METHOD.POST,
        body: { type },
        hasAuth: true,
    }
});

const oauthAccess = (data) => ({
    type: types.INTEGRATE_OAUTH_ACCESS,
    meta: {
        endpoint: endpoint.INTEGRATE_OAUTH_ACCESS,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const loadData = (data) => ({
    type: types.INTEGRATE_FORM_ACCOUNT_LOAD_DATA,
    meta: {
        endpoint: endpoint.INTEGRATE_LOAD_DATA_FORM_ACCOUNT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const loadCustomFields = (data) => ({
    type: types.INTEGRATE_FORM_ACCOUNT_LOAD_CUSTOM_FIELDS,
    meta: {
        endpoint: endpoint.INTEGRATE_LOAD_CUSTOM_FIELDS_FORM_ACCOUNT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const loadSheets = (data) => ({
    type: types.INTEGRATE_FORM_ACCOUNT_LOAD_GOOGLE_SPREADSHEET,
    meta: {
        endpoint: endpoint.INTEGRATE_FORM_ACCOUNT_LOAD_GOOGLE_SPREADSHEET,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const loadWorksheets = (data) => ({
    type: types.INTEGRATE_FORM_ACCOUNT_LOAD_GOOGLE_WORKSHEET,
    meta: {
        endpoint: endpoint.INTEGRATE_FORM_ACCOUNT_LOAD_GOOGLE_WORKSHEET,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const createSheet = (data) => ({
    type: types.INTEGRATE_FORM_ACCOUNT_CREATE_GOOGLE_SHEET,
    meta: {
        endpoint: endpoint.INTEGRATE_FORM_ACCOUNT_CREATE_GOOGLE_SHEET,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const selectManuallySheetID = (data) => ({
    type: types.INTEGRATE_FORM_ACCOUNT_SELECT_MANUALLY_GOOGLE_SHEET_ID,
    meta: {
        endpoint: endpoint.INTEGRATE_FORM_ACCOUNT_SELECT_MANUALLY_GOOGLE_SHEET_ID,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const googleSheets = () => ({
    type: types.INTEGRATE_GOOGLE_SHEETS,
    meta: {
        endpoint: endpoint.INTEGRATE_GOOGLE_SHEETS,
        method: REQUEST_METHOD.POST,
        hasAuth: true,
    }
});

const oauth2GoogleSheets = (data) => ({
    type: types.INTEGRATE_OAUTH2_GOOGLE_SHEETS,
    meta: {
        endpoint: endpoint.INTEGRATE_OAUTH2_GOOGLE_SHEETS,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const resetData = () => ({
    type: types.INTEGRATE_FORM_ACCOUNT_RESET_DATA
})

const listFormAccountInFormConfig = (data) => ({
    type: types.INTEGRATE_FORM_ACCOUNT_IN_FORM_CONFIG_LIST,
    meta: {
        endpoint: endpoint.INTEGRATE_LIST_FORM_ACCOUNT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const haravan = () => ({
    type: types.INTEGRATE_HARAVAN,
    meta: {
        endpoint: endpoint.INTEGRATE_HARAVAN,
        method: REQUEST_METHOD.POST,
        hasAuth: true,
    }
});

const createHaravan = (data) => ({
    type: types.INTEGRATE_CREATE_HARAVAN,
    meta: {
        endpoint: endpoint.INTEGRATE_CREATE_ALL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const sapo = (data) => ({
    type: types.INTEGRATE_SAPO,
    meta: {
        endpoint: endpoint.INTEGRATE_SAPO,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const createSapo = (data) => ({
    type: types.INTEGRATE_CREATE_SAPO,
    meta: {
        endpoint: endpoint.INTEGRATE_CREATE_SAPO,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const shopify = (data) => ({
    type: types.INTEGRATE_SHOPIFY,
    meta: {
        endpoint: endpoint.INTEGRATE_SHOPIFY,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const nhanh = () => ({
    type: types.INTEGRATE_NHANH,
    meta: {
        endpoint: endpoint.INTEGRATE_NHANH,
        method: REQUEST_METHOD.POST,
        hasAuth: true,
    }
});

const createNhanh = (data) => ({
    type: types.INTEGRATE_CREATE_NHANH,
    meta: {
        endpoint: endpoint.INTEGRATE_CREATE_NHANH,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

export default {
    listFormAccount,
    show,
    update,
    deleteFormAccount,
    oauth2GetLink,
    oauthAccess,
    loadData,
    loadCustomFields,
    loadSheets,
    loadWorksheets,
    resetData,
    createSheet,
    selectManuallySheetID,
    googleSheets,
    oauth2GoogleSheets,
    listFormAccountInFormConfig,
    haravan,
    createHaravan,
    sapo,
    createSapo,
    shopify,
    nhanh,
    createNhanh
}
