import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import baseHelper from "../../../../helpers/BaseHelper";
import Image from "../../../../components/Image";

import fileActions from "../../../../redux/futures/file/actions";
import * as fileTypes from "../../../../redux/futures/file/types";

import { Editor } from "@tinymce/tinymce-react";

const CDN = "https://w.ladicdn.com/";

// eslint-disable-next-line max-lines-per-function
function ModalAddDescription(props) {
  const { t, onClose, isShow, selectedValue, handleAddContentLocation, dataSelect, selectedIndex } = props;
  const wrapperRef = useRef(null);

  const fileReducer = useSelector((state) => state.file);

  const [titleBanner, setTitleBanner] = useState("Banner cửa hàng");

  const [banner, setBanner] = useState({
    url: "",
    link: "",
  });

  const [isShowInputAddLink, setIsShowInputAddLink] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClose(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const dispatch = useDispatch();

  const eventCancel = () => {
    onClose(false);
  };

  useEffect(() => {
    if (dataSelect) {
      setTitleBanner(dataSelect.title || titleBanner);
      setBanner(dataSelect.values && dataSelect.values.length > 0 ? dataSelect.values[0] : {});
    }
  }, [dataSelect]);

  useEffect(() => {
    if (fileReducer.action == fileTypes.UPLOAD_BANNER_STORE) {
      if (fileReducer.status) {
        if (fileReducer.banner_url) {
          setBanner({
            ...banner,
            url: fileReducer.banner_url,
          });
        }
      } else {
        window.LadiUI.showErrorMessage("Thông báo", fileReducer.message, "OK");
      }
    }
  }, [fileReducer]);

  const fileChangedHandlerBannerStore = (event) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      const form = baseHelper.getFormDataUpload(files);
      if (form) {
        dispatch(fileActions.uploadBannerStore(form));
      }
    }
  };

  const openUploadBannerStore = () => {
    document.getElementById("upload-banner-store-content").click();
  };

  const handleChangeInputLink = (event) => {
    let value = event.target.value;
    setBanner({
      ...banner,
      link: value,
    });
  };

  // const onKeyDownInput = (event) => {
  //   if (event.key === "Enter") {
  //     setIsShowInputAddLink(false);
  //   }
  // };

  const handleAddContentBanner = () => {
    let data = {
      title: titleBanner,
      type: "BANNER",
      values: [banner],
    };
    handleAddContentLocation(data, selectedIndex);
  };

  const onChangeTitle = (event) => {
    let value = event.target.value;

    setTitleBanner(value);
  };

  return (
    <>
      <div className={`ladiui modal ${isShow ? "show" : ""}`} id="homepage-add-banner">
        <div className="ladiui modal-dialog modal-dialog-centered w-600" ref={wrapperRef}>
          <div className="ladiui modal-content ">
            <div className="ladiui modal-header ">
              <h2 className="ladiui modal-title ">Hình ảnh</h2>
              <button data-dismiss="modal" data-target="add-product" onClick={eventCancel} className="ladiui modal-close">
                <i className="ladiui icon icon-ldp-close"></i>
              </button>
            </div>
            <div className="ladiui modal-body">
              <div className="ladiui form-group">
                <label className="ladiui-label">Tiêu đề</label>
                <input className="ladiui form-control" value={titleBanner} placeholder="Nhập vào tiêu đề" onChange={onChangeTitle} />
              </div>
              <div className="block-content-image">
                {banner.url ? (
                  <div className="block-show-image">
                    <div className="image">
                      <img src={`${CDN}${banner.url}`} />
                    </div>
                    <div className="block-action-item">
                      <div
                        className="item block-icon-delete"
                        onClick={() => {
                          setBanner({
                            ...banner,
                            url: "",
                          });
                        }}
                      >
                        <i className="ldicon-delete"></i>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="block-upload" onClick={openUploadBannerStore}>
                      <div>
                        <img className="" src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-import-image.svg" />
                      </div>
                      <div className="mt-24">Tải lên hình ảnh</div>
                    </div>
                    <div className="ladiui button upload text-center">
                      <input
                        id="upload-banner-store-content"
                        className="cursor-pointer"
                        type="file"
                        name="file"
                        accept="image/*"
                        onChange={fileChangedHandlerBannerStore}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        style={{ display: "none" }}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="ladiui form-group">
                <label className="ladiui-label">
                  Đường dẫn liên kết hình ảnh
                </label>
                <input
                  className="ladiui form-control"
                  type="text"
                  value={banner.link}
                  placeholder="Nhập đường dẫn liên kết"
                  onChange={(e) => handleChangeInputLink(e)}
                />
              </div>
            </div>

            <div className="ladiui modal-footer ">
              <div className="ladiui footer-modal-btn">
                <button type="button" className="ladiui btn btn-secondary bold" onClick={eventCancel}>
                  {t("ACTIONS.CLOSE")}
                </button>
                <button type="button" className="ladiui btn btn-primary" onClick={() => handleAddContentBanner()}>
                  {selectedIndex >= 0 ? "Cập nhật" : "Thêm nội dung"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(ModalAddDescription);
