/** Import default package */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
/** Import from third party */
import { compact, map, indexOf, forEach, remove, find, join, cloneDeep, sortedUniq, values, includes, pick } from "lodash";

/** Import component from my app */
import Input from "../../../../components/Input";
import { withTranslation } from "react-i18next";
import NumberInput from "../../../../components/NumberInput";

import appConfig from "../../../../config/app";
import ModalSwatch from "../../../../components/ModalSwatch";
import produce from "immer";
import baseHelper from "../../../../helpers/BaseHelper";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

class PanelCreateVariant extends React.Component {
  static propTypes = {
    inputsRef: PropTypes.any,
    product: PropTypes.object,
    variants: PropTypes.array,
    options: PropTypes.array,
  };

  static defaultProps = {
    inputsRef: new Set(),
  };

  constructor(props) {
    super(props);

    this.state = {
      variants: [],
      options: [
        {
          valueInput: "",
          name: props.t("PRODUCTS.VARIANT_1"),
          position: 1,
          type: appConfig.PRODUCT_OPTION_TYPE.TEXT.CODE,
          values: [],
        },
      ],
      selectedOptionIndex: 0,
      isShowModalAdjust: false,
    };

    this.defaultOptionNames = [props.t("PRODUCTS.VARIANT_1"), props.t("PRODUCTS.VARIANT_2"), props.t("PRODUCTS.VARIANT_3")];
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.variants != nextProps.variants) {
      const variants = cloneDeep(nextProps.variants);
      this.setState(
        {
          variants,
        },
        () => this.combinationVariants()
      );
    }

    if (this.props.options != nextProps.options) {
      const options = cloneDeep(nextProps.options);
      this.setState({
        options,
      });
    }
  }

  componentDidMount() {
    if (!baseHelper.isEmpty(this.props.variants)) {
      this.setState(
        {
          variants: produce(this.props.variants, (draft) => { }),
        },
        () => this.combinationVariants()
      );
    }

    if (!baseHelper.isEmpty(this.props.options)) {
      this.setState({
        options: produce(this.props.options, (draft) => { }),
      });
    }

    if (!baseHelper.isEmpty(this.props.variants)) {
      this.setState({
        variants: produce(this.props.variants, (draft) => { }),
      });
    }
  }

  /*******************************OPTIONS***************************/
  removeOption = (index) => {
    let { options } = this.state;
    this.setState(
      {
        options: produce(options, (draft) => {
          draft.splice(index, 1);

          if (draft.length <= 0) {
            this.setState({
              checkedOption: false,
            });
          }
        }),
      },
      () => this.combinationVariants()
    );
  };

  addOtherOption = () => {
    const { options } = this.state;
    const currentNames = map(options, (option) => option.name);
    const availableNames = [];

    map(this.defaultOptionNames, (name) => {
      if (indexOf(currentNames, name) < 0) {
        availableNames.push(name);
      }
    });

    const nextOption = {
      name: availableNames[0],
      type: appConfig.PRODUCT_OPTION_TYPE.TEXT.CODE,
      values: [],
    };

    this.setState({
      options: produce(options, (draft) => {
        draft.push(nextOption);
      }),
    });
  };

  onChangeOptionName = (event, index) => {
    const { options, variants } = this.state;

    const option = options[index];
    const oldOptionName = option.name;
    const newOptionName = event.target.value;

    this.setState({
      options: produce(options, (draft) => {
        draft[index].name = event.target.value;
      }),
    });

    if (oldOptionName != newOptionName) {
      this.setState({
        variants: produce(variants, (draft) => {
          draft.forEach((item) => {
            const optionVariants = item.options;
            optionVariants[newOptionName] = optionVariants[oldOptionName];
            delete optionVariants[oldOptionName];
          });
        }),
      });
    }
  };

  removeOptionValue = (optionValue, index) => {
    let { options } = this.state;

    this.setState(
      {
        options: produce(options, (draft) => {
          const option = draft[index];
          const values = option.values;

          remove(values, (item) => item.name == optionValue);
          if (option.values.length <= 0) {
            draft.slice(index, 1);
          }

          draft = compact(draft);
        }),
      },
      () => this.combinationVariants()
    );
  };

  addOptionValue = (index) => {
    const { options } = this.state;
    this.setState(
      {
        options: produce(options, (draft) => {
          const option = draft[index];
          if (!option.valueInput) option.valueInput = "";
          const valueInput = option.valueInput.trim();

          const values = option.values;

          if (valueInput && !find(values, (item) => item.name == valueInput)) {
            values.push({
              name: valueInput,
            });
          }

          option.valueInput = "";
        }),
      },
      () => this.combinationVariants()
    );
  };

  onChangeOptionValueInput = (event, index) => {
    const { options } = this.state;

    this.setState({
      options: produce(options, (draft) => {
        draft[index].valueInput = event.target.value;
      }),
    });
  };
  /*****************************************************************/

  /*******************************VARIANTS***************************/

  onChangeVariantInput = (event, index) => {
    const { name, value } = event.target;
    const { variants } = this.state;

    this.setState({
      variants: produce(variants, (draft) => {
        draft[index][name] = value;
      }),
    });
  };

  combinationVariants = (props) => {
    if (!props) {
      props = this.props;
    }
    // Tu dong tinh toan cac hoan vi cua variants
    let newVariants = [];

    map(this.state.options, (option) => {
      if (option.values.length > 0) {
        newVariants = this.getNewVariant(option, newVariants, props);
      }
    });

    this.setState({
      variants: newVariants,
    });
  };

  getNewVariant = (option, currentVariants, props) => {
    const pickFields = [
      "price",
      "price_compare",
      "cost_per_item",
      "weight",
      "weight_unit",
      "sku",
      "nextSku",
      "min_buy",
      "max_buy",
      "quantity",
      "src",
    ];
    const { price, price_compare, cost_per_item, sku, weight, weight_unit, quantity, min_buy, max_buy } = props.product;
    const { name, values: _values } = option;
    const newVariants = [];

    let indexSKU = 1;
    if (baseHelper.isEmpty(currentVariants)) {
      map(_values, (item) => {
        const nextSku = sku ? `${sku}-${indexSKU}` : "";
        indexSKU++;
        let newVariant = {
          options: {
            [name]: item.name,
          },
          price,
          price_compare,
          cost_per_item,
          weight,
          weight_unit,
          min_buy,
          max_buy,
          sku: nextSku,
          quantity,
          src: "",
        };

        // Merge with old variant
        const oldVariant = this.getOldVariantInfo(values(newVariant.options));
        if (oldVariant) {
          newVariant = {
            ...newVariant,
            ...pick(oldVariant, pickFields),
          };
        }

        newVariants.push(newVariant);
      });
    } else {
      map(currentVariants, (variant) => {
        map(_values, (item) => {
          const nextSku = sku ? `${sku}-${indexSKU}` : "";
          indexSKU++;
          let newVariant = {
            options: {
              ...variant.options,
              [name]: item.name,
            },
            price,
            price_compare,
            cost_per_item,
            weight,
            weight_unit,
            sku: nextSku,
            quantity,
            min_buy,
            max_buy,
            src: "",
          };

          // Merge with old variant
          const oldVariant = this.getOldVariantInfo(values(newVariant.options));
          if (oldVariant) {
            newVariant = {
              ...newVariant,
              ...pick(oldVariant, pickFields),
            };
          }

          newVariants.push(newVariant);
        });
      });
    }

    // append position
    map(newVariants, (item, index) => (item.position = index));
    return newVariants;
  };

  getOldVariantInfo = (optionValues) => {
    let sortedOptionValueStr = join(sortedUniq(optionValues), "-");
    const foundVariant = find(this.state.variants, (item) => {
      const _optionValues = values(item.options);
      const _sortedOptionValueStr = join(sortedUniq(_optionValues), "-");

      if (includes(_sortedOptionValueStr, sortedOptionValueStr) || includes(sortedOptionValueStr, _sortedOptionValueStr)) {
        return true;
      }

      return false;
    });

    return foundVariant;
  };

  /*****************************************************************/

  getData = () => {
    const { inventory_checked: inventoryChecked } = this.props.product;
    let { variants, options } = cloneDeep(this.state);

    if (variants.length <= 0) {
      options = [];
    }

    if (inventoryChecked == appConfig.INVENTORY_STATUS.ACTIVE.CODE) {
      forEach(variants, (item) => {
        item.inventory_checked = appConfig.INVENTORY_STATUS.ACTIVE.CODE;
      });
    }

    remove(options, (option) => !option.values || option.values.length <= 0);
    map(options, (item, index) => {
      item.position = index + 1;
    });

    return {
      variants,
      options,
    };
  };

  openModalAdjust = (selectedOptionIndex) => {
    this.setState({
      isShowModalAdjust: true,
      selectedOptionIndex,
    });
  };

  hideModalAdjust = () => {
    this.setState({
      isShowModalAdjust: false,
    });
  };

  applySwatch = (option) => {
    const { options, selectedOptionIndex } = this.state;

    this.setState(
      {
        options: produce(options, (draft) => {
          draft[selectedOptionIndex] = option;
        }),
      },
      () => {
        this.combinationVariants();
      }
    );

    this.hideModalAdjust();
  };

  removeVariant = (index) => {
    const { variants } = this.state;

    this.setState({
      variants: produce(variants, (draft) => {
        draft.splice(index, 1);
      }),
    });
  };

  onDragEnd = (result) => {
    const { options } = this.state;
    // dropped outside the list
    if (!result.destination || result.source.index == result.destination.index) {
      return;
    }

    this.setState(
      {
        options: produce(options, (draft) => {
          const tg = { ...draft[result.source.index] };
          draft[result.source.index] = { ...draft[result.destination.index] };
          draft[result.destination.index] = tg;

          // const [removed] = draft.splice(result.source.index, 1);
          // draft.splice(result.destination.index, 0, removed);
        }),
      },
      () => {
        this.combinationVariants();
        // this.props.reOrderPackage({
        //     product_id: this.props.product.product_id,
        //     product_variant_ids: map(this.state.variants, item => item.product_variant_id),
        // });
      }
    );
  };

  render() {
    const { t, product } = this.props;
    const { variants, options, selectedOptionIndex, isShowModalAdjust } = this.state;

    const { currency_symbol: currencySymbol, currency_code: currencyCode } = this.props.store.userInfo.currentStore;
    const isShowAddOther = options.length < 3;

    const generateName = (options) => {
      const optionNames = map(options, (value, key) => value);
      const name = join(optionNames, " • ");
      return <span className="variant-option-value">{name}</span>;
    };

    return (
      <div>
        <div className="ladiui form-group clearfix product-variants">
          <div className="product-variant-box">
            <div className="ladiui-form-group variant-option-head">
              <span>{t("PRODUCTS.OPTION_NAME")}</span>
              <span>{t("PRODUCTS.OPTION_VALUE")}</span>
            </div>

            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    className="ladiui-form-group variant-option-list"
                    ref={provided.innerRef}
                    style={{
                      background: snapshot.isDragging ? "#e8f0fe" : "none",
                    }}
                  >
                    {map(options, (option, index) => {
                      const values = option.values || [];
                      const valueInput = option.valueInput || "";

                      return (
                        <Draggable key={index} draggableId={"" + index} index={index}>
                          {(provided, snapshot) => (
                            <div
                              className="flex list-item"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                userSelect: "none",
                                background: snapshot.isDragging ? "#e8f0fe" : "none",
                                display: "table-row",
                              }}
                            >
                              <img className="mr-12" src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg"} />
                              <div className="variant-option-item" id="variant-option-1">
                                <div className="variant-option-name">
                                  <Input
                                    type="text"
                                    name="variant_option_name"
                                    validationName="Option name"
                                    value={option.name}
                                    onChange={(event) => this.onChangeOptionName(event, index)}
                                    ref={(ref) => this.props.inputsRef.add(ref)}
                                    validations={{ isRequired: true }}
                                    allowSpecialChar={false}
                                  />
                                </div>
                                <div className="variant-option-value">
                                  <div className="ladiui-tags">
                                    <div className="ladiui-tag-list mr-8" style={{ flexWrap: "wrap" }}>
                                      {map(values, (item, _index) => (
                                        <span key={_index} className="tag" style={{ marginBottom: 8 }}>
                                          {item.name}
                                          <i
                                            className="ladiui span-img cursor-pointer  icon-close-no-bg-16"
                                            onClick={() => this.removeOptionValue(item.name, index)}
                                          />
                                        </span>
                                      ))}
                                    </div>
                                    <Input
                                      placeholder={t("PRODUCTS.ADD_VARIANT_VALUE")}
                                      onKeyPress={(event) => {
                                        if (event.key === "Enter") {
                                          this.addOptionValue(index);
                                        }
                                      }}
                                      onChange={(event) => this.onChangeOptionValueInput(event, index)}
                                      value={valueInput}
                                      allowSpecialChar={false}
                                      className="w-unset un-hover"
                                      noFormControl={true}
                                    />
                                  </div>
                                </div>
                                {values.length > 0 && (
                                  <div className="adjust">
                                    <span>
                                      <a onClick={() => this.openModalAdjust(index)}>{t("COMMON.ADJUST")}</a>
                                    </span>
                                  </div>
                                )}

                                <div className="remove-variant-option">
                                  <a onClick={() => this.removeOption(index)}>
                                    <i className="ladi-icon icon-bin" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {
              isShowAddOther && (
                <div className="ladiui border-none btn-add-option mt-24" onClick={this.addOtherOption}>
                  <img className="mr-8" src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg"} alt="" />
                  <div className="btn-add-product"> {t("PRODUCTS.ADD_OPTION")}</div>
                </div>
              )

              // <a onClick={this.addOtherOption} className="ladiui button secondary new-variant-option">
              //     {t('PRODUCTS.ADD_OPTION')}</a>
            }
          </div>
        </div>

        <div className="ladi-card block-table-list-variants" style={{ maxWidth: "50%" }}>
          {variants.length > 0 && (
            <table className="ladiui table text-left table-custom">
              <thead>
                <tr className="ladiui table-vertical header">
                  <th className="text-left">{t("PRODUCTS.VARIANT")}</th>
                  <th className="text-left">{t("PRODUCTS.PRICE")}</th>
                  <th className="text-left">{t("PRODUCTS.SKU")}</th>
                  {product.inventory_checked == appConfig.INVENTORY_STATUS.ACTIVE.CODE && (
                    <th className="text-left">{t("COMMON.QUANTITY")}</th>
                  )}
                  <th />
                </tr>
              </thead>
              <tbody>
                {map(variants, (variant, index) => {
                  const { options } = variant;
                  return (
                    <tr className="ladiui table-vertical" key={index}>
                      <td>{generateName(options)}</td>
                      <td>
                        <NumberInput
                          name="price"
                          suffix={currencySymbol}
                          value={variant.price}
                          onChange={(event) => this.onChangeVariantInput(event, index)}
                        />
                      </td>
                      <td>
                        <Input
                          name="sku"
                          value={variant.sku}
                          onChange={(event) => this.onChangeVariantInput(event, index)}
                          placeholder="Nhập mã SKU"
                        />
                      </td>
                      {product.inventory_checked == appConfig.INVENTORY_STATUS.ACTIVE.CODE && (
                        <td>
                          <NumberInput
                            name="quantity"
                            value={variant.quantity}
                            onChange={(event) => this.onChangeVariantInput(event, index)}
                            onlyNumber={true}
                            decimalScale={0}
                            thousandSeparator={false}
                            placeholder="Nhập số lượng"
                          />
                        </td>
                      )}
                      <td className="text-right">
                        <div className="remove-variant-option">
                          <a onClick={() => this.removeVariant(index)}>
                            <i className="ladi-icon icon-bin" style={{ color: "#6d6d6d" }} />
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>

        {isShowModalAdjust && (
          <ModalSwatch
            mode={appConfig.FORM_MODE.CREATE}
            visible={isShowModalAdjust}
            onCancel={this.hideModalAdjust}
            onSubmit={this.applySwatch}
            option={options[selectedOptionIndex]}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  store: { ...state.store },
});
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  withTranslation("translation", { withRef: true })(PanelCreateVariant)
);
