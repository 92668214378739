import React from "react";
import "../../assets/css/report/report.css";

import chartConfig from "../../config/chartconfig";

import reportActions from "../../redux/futures/report/actions";
import * as reportTypes from "../../redux/futures/report/types";

import * as storePageTypes from "../../redux/futures/store_page/types";
import storePageActions from "../../redux/futures/store_page/actions";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { cloneDeep, map } from "lodash";
import LoadingScene from "../../components/LoadingScene";

import baseHelper from "../../helpers/BaseHelper";
import OrderDateRangePicker from "../../components/OrderDateRangePicker";

import appConfig from "../../config/app";
import ReportRevenue from "./ReportRevenue";
import ReportOrder from "./ReportOrder";
import ReportRevenueByStaff from "./ReportRevenueByStaff";
import ReportRevenueBySource from "./ReportRevenueBySource";
import ReportTopProduct from "./ReportTopProduct";
import ReportUtm from "./utm/ReportUtm";
import ReportDetailStaff from "./ReportDetailStaff";
import moment from "moment";
class Report extends React.Component {
  constructor(props) {
    super(props);
    this.moment = baseHelper.getMoment();

    const now = this.moment();
    const firstDay = now.set({
      date: 1,
    });

    this.state = {
      fromDate: baseHelper.getFromDate(firstDay),
      toDate: baseHelper.getToDate(this.moment()),
      optionsList: [],
      focusedInput: null,
      revenue_day: [],
      num_orders_day: [],
      revenue_by_staff: [],
      revenue_source: [],
      report_by_staff: [],
      store_pages: [],
      pageCheckoutId: ""
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.report.action != nextProps.report.action) {
      if (nextProps.report.action == reportTypes.OVERVIEW_REPORT) {
        if (nextProps.report.status) {
          this.setState({
            revenue_day: nextProps.report.data.revenue_day,
            num_orders_day: nextProps.report.data.num_orders_day,
            revenue_by_staff: nextProps.report.data.revenue_by_staff,
            revenue_source: nextProps.report.data.revenue_source,
            report_by_staff: nextProps.report.data.report_by_staff,
          });
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.report.message, "OK");
        }
      }
    }
    if (this.props.storePage.action != nextProps.storePage.action) {
      if (nextProps.storePage.action == storePageTypes.STORE_PAGE_LIST) {
        if (nextProps.storePage.status) {
          this.setState({
            store_pages: nextProps.storePage.store_pages || []
          })
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.storePage.message, "OK");
        }
      }
    }
  }

  componentDidMount() {
    window.LadiUI.init();
    this.getReport();

    const dataListStore = {
      keyword: "",
      search: {},
      paged: 1,
      limit: 20,
    };

    this.props.listStores(dataListStore);
  }
  componentDidUpdate() {
    window.LadiUI.init();
  }

  getReport = () => {
    const { fromDate, toDate, pageCheckoutId } = this.state;

    let newFromDate = fromDate ? moment(new Date(fromDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null;
    let newToDate = toDate ? moment(new Date(toDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null;
    this.props.overviewReport(newFromDate, newToDate, pageCheckoutId);
  };

  handleSetPageCheckoutId = async (pageCheckoutId) => {
    await this.setState({
      pageCheckoutId: pageCheckoutId
    })
    this.getReport();
  }

  render() {
    const { t } = this.props;
    const { revenue_day, num_orders_day, revenue_by_staff, fromDate, toDate, revenue_source, report_by_staff, store_pages } = this.state;
    const isLoadingData = this.props.report.loading && this.props.report.waiting == reportTypes.OVERVIEW_REPORT;
    const _fromDate = fromDate ? fromDate.toISOString() : "";
    const _toDate = toDate ? toDate.toISOString() : "";
    return (
      <div className="page-report-content">
        {isLoadingData && <LoadingScene blur={true} />}
        <h1 className="page-title pt-0">{t("MENU.REPORTS_OVERVIEW")}</h1>
        <div className="ladiui-table-actions reports" style={{ justifyContent: "flex-start" }}>
          <div className="action-item">
            <OrderDateRangePicker
              startDateId="fromDate"
              endDateId="toDate"
              startDate={this.state.fromDate}
              endDate={this.state.toDate}
              onDatesChange={({ startDate, endDate }) => {
                this.setState(
                  {
                    fromDate: baseHelper.getFromDate(startDate),
                    toDate: baseHelper.getToDate(endDate),
                    queryOverTime: ``,
                  },
                  () => {
                    if (startDate && endDate) {
                      this.getReport();
                    }
                  }
                );
              }}
              focusedInput={this.state.focusedInput}
              onFocusChange={(focusedInput) => {
                this.setState({ focusedInput });
              }}
              showDefaultInputIcon={true}
              inputIconPosition="after"
              small={true}
              hideKeyboardShortcutsPanel={true}
              customInputIcon={<i className="ladi-icon icon-calendar-date-2" />}
              startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
              endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
              isOutsideRange={() => false}
              isDayHighlighted={(date) => {
                return this.moment().isSame(date, "day");
              }}
              minimumNights={0}
            />
            {/* <OrderDateRangePicker
              startDateId="fromDate"
              endDateId="toDate"
              startDate={this.state.fromDate}
              endDate={this.state.toDate}
              onDatesChange={({ startDate, endDate }) => {
                this.setState(
                  {
                    fromDate: baseHelper.getFromDate(startDate),
                    toDate: baseHelper.getToDate(endDate),
                  },
                  () => {
                    if ((startDate && endDate) || (!startDate && !endDate)) {
                      this.getReport();
                    }
                  }
                );
              }}
              focusedInput={this.state.focusedInput}
              onFocusChange={(focusedInput) => {
                this.setState({ focusedInput });
              }}
              showDefaultInputIcon={true}
              inputIconPosition="after"
              small={true}
              hideKeyboardShortcutsPanel={true}
              customInputIcon={<i className="ladi-icon icon-calendar-date-2" />}
              startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
              endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
              isOutsideRange={() => false}
              isDayHighlighted={(date) => {
                return this.moment().isSame(date, "day");
              }}
              minimumNights={0}
              showClearDates={true}
            /> */}
          </div>
          <div className="ladiui flex-row mr-24">
            <div className="ladiui btn-group">
              <div className="ladiui dropdown">
                <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown">
                  <i className="ladiui icon"></i>
                  <span className="ladiui dropdown-text">{"Chọn cửa hàng"}</span>
                </button>
                <ul className="ladiui dropdown-menu">
                  <li onClick={() => this.handleSetPageCheckoutId('')}>
                    <a className="ladiui dropdown-item" href="#">
                      Tất cả cửa hàng
                    </a>
                  </li>
                  {store_pages.map((item, index) => (
                    <li key={index} onClick={() => this.handleSetPageCheckoutId(item.page_checkout_id)}>
                      <a className="ladiui dropdown-item" href="#">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="report-overview">
          <div className="item">
            <ReportOrder
              num_orders_day={num_orders_day}
              queryOverTime={`from=${_fromDate}&to=${_toDate}`}
              fromDate={fromDate}
              toDate={toDate}
            />
          </div>
          <div className="item">
            <ReportRevenue revenue_day={revenue_day} fromDate={fromDate} toDate={toDate} />
          </div>
          <div className="item">
            <ReportRevenueByStaff revenue_by_staff={revenue_by_staff} queryOverTime={`from=${_fromDate}&to=${_toDate}`} fromDate={fromDate} toDate={toDate} />
          </div>
          <div className="item">
            <ReportRevenueBySource revenue_source={revenue_source} />
          </div>
          <div className="item-top-product">
            <ReportUtm />
          </div>
          <div className="item-top-product">
            <ReportTopProduct fromDate={fromDate} toDate={toDate} />
          </div>
          <div className="item-top-product">
            <ReportDetailStaff data_staff={report_by_staff} queryOverTime={`from=${_fromDate}&to=${_toDate}`} />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    overviewReport: (fromDate, toDate, pageCheckoutId) => dispatch(reportActions.overviewReport(fromDate, toDate, pageCheckoutId)),
    listStores: (data) => dispatch(storePageActions.storePageList(data))
  };
};

const mapStateToProps = (state) => ({
  report: { ...state.report },
  storePage: { ...state.storePage }
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Report));
