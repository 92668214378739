import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import appConfig from "../../../config/app";
import TagSelect from "../../../components/TagSelect";
import Input from "../../../components/Input";
import { map } from "lodash";
import { object } from "prop-types";

import * as productTypes from "../../../redux/futures/product/types";
import productActions from "../../../redux/futures/product/actions";

// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function FilterConditionOrder(props) {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const { t, conditionProp, filterActionProp, staffs, allTags, isMyOrder, allStorePages, products } = props;
  const [attributeFilter, setAttributeFilter] = useState(null);
  const [conditionFilter, setConditionFilter] = useState(null);
  const [valueFilter, setValueFilter] = useState(null);
  const [listFilter, setListFilter] = useState([]);
  const [allConditionSelect, setAllConditionSelect] = useState([]);
  const [showListFilter, setShowListFilter] = useState(false);
  const [listProducts, setListProducts] = useState([]);

  const [listStaffs, setListStaffs] = useState([]);
  const [listTags, setListTags] = useState([]);

  const LIST_STATUS = [
    {
      name: appConfig.ORDER_STATUS.OPEN.NAME,
      value: appConfig.ORDER_STATUS.OPEN.CODE,
    },
    {
      name: appConfig.ORDER_STATUS.CANCELED.NAME,
      value: appConfig.ORDER_STATUS.CANCELED.CODE,
    },
  ];
  const listPaymentStatus = [
    {
      name: appConfig.PAYMENT_STATUS.SUCCESS.NAME,
      value: appConfig.PAYMENT_STATUS.SUCCESS.CODE,
    },
    {
      name: appConfig.PAYMENT_STATUS.PENDING.NAME,
      value: appConfig.PAYMENT_STATUS.PENDING.CODE,
    },
  ];

  const listPaymentMethod = Object.entries(appConfig.PAYMENT_PARTNERS).map((item, index) => {
    return {
      name: item[1].NAME,
      value: item[1].CODE,
    };
  });

  const listShippingStatus = Object.entries(appConfig.SHIPPING_STATUS).map((item) => {
    return {
      name: item[1].NAME,
      value: item[1].CODE,
    };
  });

  const listProductType = [
    {
      name: appConfig.PRODUCT_TYPE.PHYSICAL.NAME,
      value: appConfig.PRODUCT_TYPE.PHYSICAL.CODE,
    },
    {
      name: appConfig.PRODUCT_TYPE.SERVICE.NAME,
      value: appConfig.PRODUCT_TYPE.SERVICE.CODE,
    },
    {
      name: appConfig.PRODUCT_TYPE.EVENT.NAME,
      value: appConfig.PRODUCT_TYPE.EVENT.CODE,
    },
    {
      name: appConfig.PRODUCT_TYPE.COMBO.NAME,
      value: appConfig.PRODUCT_TYPE.COMBO.CODE,
    },
    {
      name: appConfig.PRODUCT_TYPE.F_B.NAME,
      value: appConfig.PRODUCT_TYPE.F_B.CODE,
    },
    {
      name: appConfig.PRODUCT_TYPE.COURSE.NAME,
      value: appConfig.PRODUCT_TYPE.COURSE.CODE,
    },
  ];

  const listSpam = [
    {
      name: appConfig.ORDER_SPAM.SPAM.NAME,
      value: appConfig.ORDER_SPAM.SPAM.CODE,
    },
    {
      name: appConfig.ORDER_SPAM.NOT_SPAM.NAME,
      value: appConfig.ORDER_SPAM.NOT_SPAM.CODE,
    },
  ];

  const LIST_STORE_PAGES = [];
  if (allStorePages && allStorePages.length > 0) {
    allStorePages.map((item, index) => {
      let storePage = {
        name: item.name,
        value: item.page_checkout_id,
      };
      LIST_STORE_PAGES.push(storePage);
    });
  }

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setAttributeFilter(null);
        setConditionFilter(null);
        setValueFilter(null);
        setShowListFilter(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (props.staffs && props.staffs.length > 0) {
      let data = [];
      for (let i = 0; i < props.staffs.length; i++) {
        let value = {
          name: staffs[i].name,
          value: staffs[i].ladi_uid,
        };
        data.push(value);
      }

      setListStaffs(data);
    }
  }, [props.staffs]);

  useEffect(() => {
    if (props.products && props.products.length > 0) {
      let data = [];
      for (let i = 0; i < props.products.length; i++) {
        let value = {
          name: products[i].name,
          value: products[i].product_id,
        };
        data.push(value);
      }

      setListProducts(data);
    }
  }, [props.products]);

  useEffect(() => {
    if (props.allTags && props.allTags.length > 0) {
      let data = [];
      for (let i = 0; i < props.allTags.length; i++) {
        let value = {
          name: allTags[i].name,
          value: allTags[i].order_tag_id,
        };
        data.push(value);
      }

      setListTags(data);
    }
  }, [props.allTags]);

  const eventFilterCondition = () => {
    if (!attributeFilter || (!valueFilter && !listFilter)) {
      return;
    }
    const allCondition = allConditionSelect.filter((value) => value.attributeFilter.value !== attributeFilter.value);

    const newAllConditionFilter = [...allCondition, ...[{ attributeFilter, valueFilter, listFilter }]];
    setAllConditionSelect(newAllConditionFilter);
    filterActionProp(newAllConditionFilter);
    eventCloseFilterCondition();
  };

  const eventCloseFilterCondition = () => {
    setAttributeFilter(null);
    setConditionFilter(null);
    setValueFilter(null);
    setShowListFilter(false);
    setListFilter([]);
  };

  const eventRemoveFilter = (index) => {
    // const allCondition = allConditionSelect.filter((value) => value.attributeFilter.value !== attributeFilterValue);
    allConditionSelect.splice(index, 1);

    setAllConditionSelect(allConditionSelect);
    // delete conditionProp[attributeFilterValue];

    filterActionProp(allConditionSelect);
  };

  // document.addEventListener("click", (event) => {
  //   const box = document.getElementById("filter-condtion-area");
  //   if (box && !box.contains(event.target)) {
  //     // eventCloseFilterCondition();
  //     setAttributeFilter(null);
  //     setConditionFilter(null);
  //     setValueFilter(null);
  //     setShowListFilter(false);
  //   }
  // });

  const renderSelectValue = (title, listValue) => {
    return (
      <div className="ladiui flex-row">
        <div className="ladiui btn-group">
          <div className="ladiui dropdown">
            <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown">
              <i className="ladiui icon"></i>
              <span className="ladiui dropdown-text">{title}</span>
            </button>
            <ul className="ladiui dropdown-menu">
              {listValue.map((item) => (
                <li key={item.value} onClick={() => setValueFilter({ name: item.name, value: item.value })}>
                  <a className="ladiui dropdown-item" href="#">
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderMultiSelect = (title, listValue) => {
    return (
      <div className="ladiui flex-row">
        <div className="ladiui btn-group">
          <div className="ladiui dropdown">
            <TagSelect
              allTags={listValue}
              selectedTags={listFilter}
              setSelected={setListFilter}
              title={title}
              placeholder={title}
              className={"w-400"}
            />
          </div>
        </div>
      </div>
    );
  };

  const handleSearchProduct = (valueSearch) => {
    dispatch(productActions.search(valueSearch));
  }

  const renderMultiSelectSearch = (title, listValue) => {
    return (
      <div className="ladiui flex-row">
        <div className="ladiui btn-group">
          <div className="ladiui dropdown">
            <TagSelect
              allTags={listValue}
              selectedTags={listFilter}
              setSelected={setListFilter}
              title={title}
              placeholder={title}
              className={"w-400"}
              isSearchApi={true}
              handleCallApi={handleSearchProduct}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderInputSearch = (title) => {
    return (
      <div className="ladiui flex-row">
        <div className="ladiui btn-group">
          <Input
            value={valueFilter ? valueFilter.value : ""}
            onChange={(e) => setValueFilter({ value: e.target.value, name: e.target.value })}
          />
        </div>
      </div>
    );
  };

  const renderDetailCondition = (condition) => {
    switch (condition) {
      case "status":
        return renderSelectValue("Chọn trạng thái", LIST_STATUS);
      case "payment_status":
        return renderMultiSelect("Chọn trạng thái thanh toán", listPaymentStatus);
      case "payment_method":
        return renderMultiSelect("Chọn Phương thức thanh toán", listPaymentMethod);
      case "payment_shipping_status":
        return renderMultiSelect("Chọn trạng thái giao hàng", listShippingStatus);
      case "product_ids":
        return renderMultiSelectSearch("Chọn sản phẩm", listProducts);
      case "product_type":
        return renderMultiSelect("Chọn loại sản phẩm", listProductType);
      case "utm_medium":
        return renderInputSearch("Nhập UTM Medium");
      case "creator":
        return renderSelectValue("Người tạo", listStaffs);
      case "staff":
        return renderSelectValue("Nhân viên", listStaffs);
      case "tags":
        return renderMultiSelect("Danh sách tag", listTags);
      case "ip":
        return renderInputSearch("IP");
      case "spam":
        return renderSelectValue("Spam", listSpam);
      case "utm_page":
        return renderInputSearch("Nhập UTM Page");
      case "utm_source":
        return renderInputSearch("Nhập UTM Source");
      case "utm_campaign":
        return renderInputSearch("Nhập UTM Campaign");
      case "utm_term":
        return renderInputSearch("Nhập UTM Term");
      case "utm_content":
        return renderInputSearch("Nhập UTM Content");
      case "msg_id":
        return renderInputSearch("MSG ID");
      case "ref":
        return renderInputSearch("REF");
      case "ad_id":
        return renderInputSearch("Nhập Ad ID");
      case "fb_id":
        return renderInputSearch("Nhập Facebook ID");
      case "page_checkout_ids":
        return renderSelectValue(t("Chọn cửa hàng"), LIST_STORE_PAGES);
        break;
      default:
        return "";
    }
  };

  return (
    <>
      <div
        ref={wrapperRef}
        id="filter-condtion-area"
        className={allConditionSelect.length > 0 ? "filter-condtion-area mb-24 mr-24" : "filter-condtion-area mr-24"}
      >
        <button className="ladiui btn btn-outline-light" onClick={() => setShowListFilter(!showListFilter)}>
          <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-filter.svg" style={{ marginRight: "10px" }} />
          Thêm điều kiện lọc
        </button>
        <div className={showListFilter ? "list-filter-condition show" : "list-filter-condition"}>
          <div className="ladiui flex-row mt-0">
            <div className="mr-8">
              <div className="ladiui btn-group">
                <div className="ladiui dropdown">
                  <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">{attributeFilter ? attributeFilter.name : "Chọn tiêu chí"}</span>
                  </button>
                  <ul className="ladiui dropdown-menu">
                    <li
                      onClick={() => {
                        setAttributeFilter({
                          name: "Trạng thái đơn hàng",
                          value: "status",
                        });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        Trạng thái đơn hàng
                      </a>
                    </li>
                    {!isMyOrder && (
                      <li
                        onClick={() => {
                          setAttributeFilter({
                            name: "Trạng thái thanh toán",
                            value: "payment_status",
                          });
                          setListFilter([]);
                        }}
                      >
                        <a className="ladiui dropdown-item" href="#">
                          Trạng thái thanh toán
                        </a>
                      </li>
                    )}
                    {!isMyOrder && (
                      <li
                        onClick={() => {
                          setAttributeFilter({
                            name: "Phương thức thanh toán",
                            value: "payment_method",
                          });
                          setListFilter([]);
                        }}
                      >
                        <a className="ladiui dropdown-item" href="#">
                          Phương thức thanh toán
                        </a>
                      </li>
                    )}
                    <li
                      onClick={() => {
                        setAttributeFilter({
                          name: "Trạng thái giao hàng",
                          value: "payment_shipping_status",
                        });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        Trạng thái giao hàng
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setAttributeFilter({
                          name: "Sản phẩm",
                          value: "product_ids",
                        });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        Sản phẩm
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setAttributeFilter({
                          name: "Loại sản phẩm",
                          value: "product_type",
                        });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        Loại sản phẩm
                      </a>
                    </li>
                    {!isMyOrder && (
                      <li
                        onClick={() => {
                          setAttributeFilter({
                            name: "Người tạo",
                            value: "creator",
                          });
                          setListFilter([]);
                        }}
                      >
                        <a className="ladiui dropdown-item" href="#">
                          Người tạo
                        </a>
                      </li>
                    )}
                    {!isMyOrder && (
                      <li
                        onClick={() => {
                          setAttributeFilter({
                            name: "Nhân viên",
                            value: "staff",
                          });
                          setListFilter([]);
                        }}
                      >
                        <a className="ladiui dropdown-item" href="#">
                          Nhân viên
                        </a>
                      </li>
                    )}
                    <li
                      onClick={() => {
                        setAttributeFilter({ name: "Tags", value: "tags" });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        Tags
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setAttributeFilter({ name: "IP", value: "ip" });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        IP
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setAttributeFilter({ name: "Spam", value: "spam" });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        Spam
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setAttributeFilter({
                          name: "UTM Page",
                          value: "utm_page",
                        });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        UTM Page
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setAttributeFilter({
                          name: "UTM Source",
                          value: "utm_source",
                        });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        UTM Source
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setAttributeFilter({
                          name: "UTM Campaign",
                          value: "utm_campaign",
                        });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        UTM Campaign
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setAttributeFilter({
                          name: "UTM Term",
                          value: "utm_term",
                        });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        UTM Term
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setAttributeFilter({
                          name: "UTM Medium",
                          value: "utm_medium",
                        });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        UTM Medium
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setAttributeFilter({
                          name: "UTM Content",
                          value: "utm_content",
                        });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        UTM Content
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setAttributeFilter({
                          name: "Ad ID",
                          value: "ad_id",
                        });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        Ad ID
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setAttributeFilter({
                          name: "Facebook ID",
                          value: "fb_id",
                        });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        Facebook ID
                      </a>
                    </li>
                    {!isMyOrder && (
                      <li
                        onClick={() => {
                          setAttributeFilter({
                            name: "MSG ID",
                            value: "msg_id",
                          });
                          setListFilter([]);
                        }}
                      >
                        <a className="ladiui dropdown-item" href="#">
                          MSG ID
                        </a>
                      </li>
                    )}
                    <li
                      onClick={() => {
                        setAttributeFilter({
                          name: "REF",
                          value: "ref",
                        });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        REF
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setAttributeFilter({
                          name: "Cửa hàng",
                          value: "page_checkout_ids",
                        });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        Cửa hàng
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {attributeFilter ? renderDetailCondition(attributeFilter.value) : ""}
          </div>
          <div className="ladiui footer-list-filter-condition flex-row mt-24">
            <button type="button" className="ladiui btn btn-secondary btn-sm mr-8" onClick={() => eventCloseFilterCondition()}>
              Hủy
            </button>
            <button type="button" className="ladiui btn btn-primary btn-sm" onClick={() => eventFilterCondition()}>
              Áp dụng
            </button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="ladiui result-filter-condition flex-row mb-24">
          {allConditionSelect.map((item, index) => (
            <span className="ladiui btn-tag" key={index}>
              {item.attributeFilter.name}: {item.valueFilter ? item.valueFilter.name : ""}{" "}
              {item.listFilter.length > 0 ? JSON.stringify(item.listFilter.map((item) => item.name)) : ""}
              {/* <img
                className="cursor-pointer"
                style={{ marginLeft: "8px" }}
                src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-close-no-bg.svg"}
                onClick={() => eventRemoveFilter(index)}
              /> */}
              <i style={{ marginLeft: "8px" }} className="cursor-pointer  icon-close-no-bg-16" onClick={() => eventRemoveFilter(index)} />
            </span>
          ))}
        </div>
      </div>
    </>
  );
}

export default withTranslation()(FilterConditionOrder);
