import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import baseHelper from '../../../helpers/BaseHelper';
import appConfig from '../../../config/app';

import Tag from '../../tags/Tag';
import NumberInput from '../../../components/NumberInput';
import Input from '../../../components/Input';

import pageCheckoutActions from "../../../redux/futures/page_checkout/actions";
import * as pageCheckoutTypes from "../../../redux/futures/page_checkout/types";

import { Editor } from '@tinymce/tinymce-react';
import { map } from 'lodash';

// function useQuery() {
//     const { search } = useLocation();

//     return React.useMemo(() => new URLSearchParams(search), [search]);
// }

function StorePageCreate(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const queryURL = baseHelper.useQuery();
    const { t, i18n, onClick, info } = props;


    const tagRef = useRef();
    const storePageNameRef = useRef();

    const pageCheckoutReducer = useSelector((state) => state.pageCheckoutReducer);

    const [storePageData, setStorePageData] = useState({
        store_name: '',
        store_address: "",
        store_phone: "",
        review: 5,
        product_sold: ''
    });

    const [valid, setValid] = useState({
        name: true,
    });

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (pageCheckoutReducer.action == pageCheckoutTypes.CREATE_PAGE_CHECKOUT) {
            if (pageCheckoutReducer.status) {
                window.LadiUI.toastCustom('success', '', pageCheckoutReducer.message);
                if (pageCheckoutReducer.page_checkout.page_checkout_id) {
                    props.history.push(`/store-page/update/${pageCheckoutReducer.page_checkout.page_checkout_id}`);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
            }
        }
    }, [pageCheckoutReducer]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'store_name' && value) {
            setValid({
                name: true,
            });
        }

        setStorePageData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const submit = () => {
        let errors = [];
        if (storePageNameRef) {
            const error = storePageNameRef.current.validate();
            if (error.length > 0) {
                errors = [...errors, ...error];
            }
        }

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('warning', '', errorMessage);
            return;
        }

        const payloadStore = {
            page_checkout: {
                checkout_config_id: null,
                domain: '',
                path: "",
                types: 'Store',
                name: storePageData ? storePageData.store_name : '',
                seo: {},
                config_store: {
                    banner: '',
                    logo: '',
                    address: storePageData.store_address ? storePageData.store_address : '',
                    phone: storePageData.store_phone ? storePageData.store_phone : '',
                    reviews: storePageData.review ? storePageData.review : null,
                    quantity_of_products_sold: storePageData.product_sold ? storePageData.product_sold : null,
                    interactive_channels: [],
                },
                status: true,
                home_page_config: [],
                config_category: [],
            },
        };

        dispatch(pageCheckoutActions.create(payloadStore));
    };

    return (
        <div className='product-create'>
            <div className='ladiui title-form text-center'>
                <label className='ladiui title-text-form-lever1 text-left'>Thêm mới cửa hàng</label>
            </div>
            <div className='ladiui form-group mb-0'>
                <label className='ladiui title-text-form-lever2'>
                    Tên cửa hàng
                </label>
                <Input
                    className={`ladiui form-control input-price ${valid.name ? '' : 'valid-input-error'}`}
                    ref={storePageNameRef}
                    name='store_name'
                    validationName={t('tên cửa hàng')}
                    placeholder='Nhập tên cửa hàng'
                    value={storePageData.store_name}
                    onChange={handleChange}
                    validations={{ isRequired: true }}
                />
            </div>
            <div className='ladiui form-group mb-0'>
                <label className='ladiui title-text-form-lever2'>
                    Địa chỉ cửa hàng
                </label>
                <Input
                    className={`ladiui form-control input-price`}
                    name='store_address'
                    validationName={t('địa chỉ cửa hàng')}
                    placeholder='Nhập địa chỉ cửa hàng'
                    value={storePageData.store_address}
                    onChange={handleChange}
                />
            </div>

            <div className='ladiui form-group mb-0'>
                <label className='ladiui title-text-form-lever2'>
                    Số điện thoại cửa hàng
                </label>
                <Input
                    className={`ladiui form-control input-price`}
                    name='store_phone'
                    validationName={t('số điện thoại cửa hàng')}
                    placeholder='Nhập số điện thoại cửa hàng'
                    value={storePageData.store_phone}
                    onChange={handleChange}
                />
            </div>

            <div className='ladiui form-group mb-0'>
                <label className='ladiui title-text-form-lever2'>
                    Đánh giá cửa hàng (Tối đa 5 điểm)
                </label>
                <Input
                    className={`ladiui form-control input-price`}
                    name='review'
                    validationName={t('đánh giá cửa hàng')}
                    placeholder='Nhập đánh giá cửa hàng'
                    value={storePageData.review}
                    onChange={handleChange}
                />
            </div>

            <div className='ladiui form-group mb-0'>
                <label className='ladiui title-text-form-lever2'>
                    Sản phẩm đã bán
                </label>
                <Input
                    className={`ladiui form-control input-price ${valid.name ? '' : 'valid-input-error'}`}
                    name='product_sold'
                    validationName={t('địa chỉ cửa hàng')}
                    placeholder='Nhập số lượng sản phẩm đã bán'
                    value={storePageData.product_sold}
                    onChange={handleChange}
                />
            </div>

            <div className='ladiui modal-footer pr-0'>
                <button onClick={() => history.push('/store-page')} className='ladiui btn btn-secondary btn-sm'>
                    {' '}
                    {t('ACTIONS.REMOVE')}
                </button>
                <button onClick={submit} className={`ladiui btn btn-primary btn-sm ${isLoading ? 'loader' : ''}`} disabled={isLoading}>
                    {' '}
                    {t('ACTIONS.NEXT')}
                </button>
            </div>
        </div>
    );
}

export default withTranslation()(StorePageCreate);
