/** @format */

import React from "react";

import baseHelper from "../../../helpers/BaseHelper";
import appConfig from "../../../config/app";
import { cloneDeep, compact, map, includes } from "lodash";

import Modal from "../../../components/Modal";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import PanelAddress from "../../../components/PanelAddress";

import { connect } from "react-redux";

import * as orderTypes from "../../../redux/futures/order/types";

class ShippingAddress extends React.Component {
  static propTypes = {
    mode: PropTypes.string,
    shippingAddress: PropTypes.object,
    addresses: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onChangeAddress: PropTypes.func,
    isLoading: PropTypes.bool,
    isRequiredState: PropTypes.bool,
    isRequiredDistrict: PropTypes.bool,
    isRequiredWard: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      shippingAddress: {},
      editShippingAddress: {},
      isShowShippingAddressModal: false,
      isShowBlockShippingAddress: true,
    };

    this.ref = React.createRef();
    this.addressPanelRef = React.createRef();
    this.inputsRef = new Set();

    this.idForm = `modal-shipping-address-${baseHelper.getRandomInt()}`;
  }

  componentDidMount() {
    if (this.props.shippingAddress) {
      const shippingAddress = this.props.shippingAddress || {};
      this.setState({
        shippingAddress: cloneDeep(shippingAddress),
        editShippingAddress: cloneDeep(shippingAddress),
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props === nextProps) {
      return;
    }

    if (this.props.shippingAddress != nextProps.shippingAddress) {
      const shippingAddress = nextProps.shippingAddress || {};

      this.setState({
        shippingAddress: cloneDeep(shippingAddress),
        editShippingAddress: cloneDeep(shippingAddress),
      });
    }

    if (this.props.orderReducer.action != nextProps.orderReducer.action) {
      if (includes([orderTypes.UPDATE_ORDER_ADDRESS, orderTypes.SHIPPING_UPDATE_ORDER_ADDRESS], nextProps.orderReducer.action)) {
        if (nextProps.orderReducer.status) {
          this.hideShippingAddressModal();
        }
      }
    }
  }

  onChangeAddressInput = (event) => {
    this.setState({
      editShippingAddress: {
        ...this.state.editShippingAddress,
        [event.target.name]: event.target.value,
      },
    });
  };

  openEditShippingAddressForm = () => {
    baseHelper.resetForm(this.idForm);
    const { shippingAddress } = this.state;

    this.setState({
      editShippingAddress: cloneDeep(shippingAddress),
      isShowShippingAddressModal: true,
    });
  };

  submit = () => {
    // Validate
    let errors = [];
    const inputsRef = compact(Array.from(this.inputsRef));
    map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });
    errors = errors.concat(this.addressPanelRef.current.validate());
    errors = compact(errors);
    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      window.LadiUI.toastCustom("danger", "", errorMessage);
      return;
    }

    const { mode } = this.props;

    const editShippingAddress = this.addressPanelRef.current.getData();
    this.setState({
      shippingAddress: cloneDeep(editShippingAddress),
    });

    if (this.props.onChangeAddress) {
      this.props.onChangeAddress(editShippingAddress, appConfig.ADDRESS_TYPE.SHIPPING);
    }

    if (mode == appConfig.FORM_MODE.CREATE) {
      this.hideShippingAddressModal();
    }
  };

  hideShippingAddressModal = () => {
    this.setState({
      isShowShippingAddressModal: false,
    });
  };

  onChangeShipping = async (selectedShippingAddress) => {
    await this.setState({
      editShippingAddress: cloneDeep(selectedShippingAddress),
    });

    let elm = document.getElementById("list-address-selected");
    elm.classList.remove("open");
  };

  getData = () => {
    return this.state.shippingAddress;
  };

  getShippingModal = () => {
    const { t, isLoading } = this.props;
    const { addresses } = this.props;
    const { editShippingAddress, isShowShippingAddressModal } = this.state;

    if (!isShowShippingAddressModal) {
      return null;
    }

    return (
      <Modal
        visible={isShowShippingAddressModal}
        id={this.idForm}
        zIndex={9999}
        title={t("ADDRESSES.EDIT_SHIPPING_ADDRESS")}
        onCancel={this.hideShippingAddressModal}
        onOk={this.submit}
        width={900}
        wrapperClassName="modal-fix-overflow modal-shipping-address-custom"
        isLoading={isLoading}
      >
        <div className="action-item">
          <div className="ladiui btn-group -horizontal">
            <div className="ladiui dropdown">
              <button className="ladiui btn btn-outline-primary btn-sm dropdown-toggle" data-toggle="dropdown">
                {t("ADDRESSES.SELECT_ANOTHER_ADDRESS")}
                {/* <i className="ladi-icon icon-down-arrow" /> */}
              </button>
              <ul
                id="list-address-selected"
                className="ladiui dropdown-menu"
                style={{
                  zIndex: 4,
                  maxHeight: 500,
                  overflow: "scroll",
                  width: "500px",
                }}
              >
                {map(addresses, (item, index) => {
                  let addressInfos = [
                    item.address,
                    item.ward_name,
                    item.district_name,
                    item.state_name,
                    baseHelper.getCountryNameByCode(item.country_code),
                  ];
                  addressInfos = compact(addressInfos);
                  const address = addressInfos.join(", ");

                  return (
                    <li key={index} className="order-address-dropdown ladiui dropdown-item" onClick={() => this.onChangeShipping(item)}>
                      <div style={{ fontWeight: 500 }}>{`${baseHelper.getText(item.last_name)} ${baseHelper.getText(
                        item.first_name
                      )}`}</div>
                      <div>{address}</div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="ladiui-tab-content" style={{ padding: "15px 0px" }}>
          <PanelAddress
            mode={appConfig.FORM_MODE.EDIT}
            ref={this.addressPanelRef}
            address={editShippingAddress}
            isRequiredState={this.props.isRequiredState}
            isRequiredDistrict={this.props.isRequiredDistrict}
            isRequiredWard={this.props.isRequiredWard}
          />
        </div>
      </Modal>
    );
  };

  render() {
    const { t, mode, classNameCustom } = this.props;
    const { shippingAddress, isShowBlockShippingAddress } = this.state;
    if (baseHelper.isEmpty(shippingAddress)) {
      return null;
    }

    let addressInfos = [
      shippingAddress.address,
      shippingAddress.ward_name,
      shippingAddress.district_name,
      shippingAddress.state_name,
      shippingAddress.country_name || baseHelper.getCountryNameByCode(shippingAddress.country_code),
    ];
    addressInfos = compact(addressInfos);
    const address = addressInfos.join(", ");

    return (
      <div className={`order-customer ${classNameCustom}`}>
        <div className="order-heading title pb-24 create-customer-shipping">
          <h3>{t("ADDRESSES.SHIPPING")}</h3>
          {mode != appConfig.FORM_MODE.VIEW && (
            <a onClick={this.openEditShippingAddressForm} className="ladiui order-customer-edit">
              {t("ACTIONS.EDIT")}
            </a>
          )}
          {/* <div className="hidden-custom-field">
                        <a href="#" onClick={() => { this.setState({ isShowBlockShippingAddress: !isShowBlockShippingAddress }) }} >
                            <i className="ladi-icon icon-down-arrow"></i>
                        </a>
                    </div> */}
        </div>
        {isShowBlockShippingAddress && (
          <div className="customer-info custom-shipping-address">
            {(shippingAddress.first_name || shippingAddress.last_name) && (
              <span>
                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-user.svg" />{" "}
                {`${baseHelper.getText(shippingAddress.last_name)} ${baseHelper.getText(shippingAddress.first_name)}`}
              </span>
            )}
            {shippingAddress.phone && (
              <span>
                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-phone.svg" /> {baseHelper.getText(shippingAddress.phone)}
              </span>
            )}
            {address && (
              <span>
                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-map.svg" /> {address}
              </span>
            )}
            {shippingAddress.note && (
              <span>
                {/* <img src="https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg" />  */}
                <div className="ldicon-note mr-8" />
                {shippingAddress.note}
              </span>
            )}
            {shippingAddress.apartment && (
              <span>
                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-map.svg" /> {baseHelper.getText(shippingAddress.apartment)}
              </span>
            )}
          </div>
        )}
        {this.getShippingModal()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => ({
  orderReducer: { ...state.order },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation("translation", { withRef: true })(ShippingAddress));
