import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { compact, map, includes } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import * as domainTypes from "../../../redux/futures/domain/types";
import domainActions from "../../../redux/futures/domain/actions";

import appConfig from "../../../config/app";
import baseHelper from "../../../helpers/BaseHelper";
import Modal from "../../../components/Modal";
import Pagination from "react-js-pagination";
import LoadingTable from "../../../components/LoadingTable";
import Input from "../../../components/Input";
import ConfirmModal from "../../../components/ConfirmModal";
import LadiPagination from "../../../components/LadiPagination";

import Dropdown from "../../../components/Dropdown";

export default function PageSettingDomain(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryStrings = new URLSearchParams(location.search);

  const domainReducer = useSelector((state) => state.domainReducer);

  const [activePage, setActivePage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [selectedLimit, setSelectedLimit] = useState(appConfig.PAGINATION.LIMIT_OPTIONS[0].value);
  const [domain, setDomain] = useState({
    domain: "",
    types: "PAGE_CHECKOUT_PRODUCT",
  });
  const [selectedDomain, setSelectedDomain] = useState({});

  const [isShowModal, setIsShowModal] = useState(false);

  const inputsRef = new Set();

  let sortBy = {};

  useEffect(() => {
    list();

    if (queryStrings.get("is_new")) {
      openModalCreate();
    }
  }, []);

  useEffect(() => {
    list();
  }, [selectedLimit]);

  useEffect(() => {
    list(false);
  }, [activePage]);

  useEffect(() => {
    if (
      [
        domainTypes.CREATE_DOMAIN,
        domainTypes.VERIFY_DOMAIN,
        domainTypes.MARK_DEFAULT_DOMAIN,
        domainTypes.DELETE_DOMAIN,
        domainTypes.REMOVE_DEFAULT_DOMAIN,
      ].includes(domainReducer.action)
    ) {
      if (domainReducer.status) {
        setIsShowModal(false);
        window.LadiUI.closeModal("confirm-domain-delete");
        window.LadiUI.closeModal("confirm-domain-verify");
        window.LadiUI.closeModal("confirm-domain-mark-default");
        window.LadiUI.closeModal("confirm-domain-remove-default");
        list();
        window.LadiUI.toastCustom("success", "", domainReducer.message);
      } else {
        // window.LadiUI.toastCustom("danger", "", domainReducer.message);
        window.LadiUI.showErrorMessage("Thông báo", domainReducer.message, "OK");
      }
    }
  }, [domainReducer]);

  /*******************************PAGINATION***************************/
  /**
   * Change limit
   */
  const onChangeLimit = (option) => {
    setSelectedLimit(option.value);
  };

  /**
   * Change page
   */
  const onPageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  /****************************************************************/
  const openModalCreate = () => {
    setIsShowModal(true);
    setDomain("");
  };

  const openDeleteModal = (selectedDomain) => {
    setSelectedDomain(selectedDomain);
    window.LadiUI.showModal("confirm-domain-delete");
  };

  const openVerifyModal = (selectedDomain) => {
    setSelectedDomain(selectedDomain);
    window.LadiUI.showModal("confirm-domain-verify");
  };

  const openMarkDefaultModal = (selectedDomain) => {
    setSelectedDomain(selectedDomain);
    window.LadiUI.showModal("confirm-domain-mark-default");
  };

  const openRemoveDefaultModal = (selectedDomain) => {
    setSelectedDomain(selectedDomain);
    window.LadiUI.showModal("confirm-domain-remove-default");
  };

  const submit = () => {
    let errors = [];
    const _inputsRef = compact(Array.from(inputsRef));

    map(_inputsRef, (ref) => {
      if (ref.validate) {
        const error = ref.validate();

        if (error.length > 0) {
          errors = errors.concat(error);
        }
      }
    });

    errors = compact(errors);

    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      window.LadiUI.toastCustom("danger", "", errorMessage);

      return;
    }

    dispatch(domainActions.create(domain));
  };

  /**
   * Tim kiem theo name
   */
  const onKeyDownInput = (event) => {
    if (event.key === "Enter") {
      list();
    }
  };

  const list = (resetActivePageFlag = true) => {
    if (document.getElementById("checkAllItem")) {
      document.getElementById("checkAllItem").checked = false;
      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = false;
      });
    }

    if (resetActivePageFlag) {
      setActivePage(1);
    }

    const data = {
      keyword: keyword,
      paged: activePage,
      limit: selectedLimit,
      sort: sortBy,
    };

    dispatch(domainActions.list(data));
  };

  const onChangeDomain = (event) => {
    let { name, value } = event.target;
    setDomain((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangDomainType = (data) => {
    if (data.CODE) {
      setDomain({
        ...domain,
        types: data.CODE,
      });
    }
  };

  const getDomainName = (type) => {
    let domainName = "";

    let findDomain = appConfig.LIST_DOMAIN_TYPE.filter((item) => item.CODE == type);

    if (findDomain && findDomain.length > 0) {
      domainName = findDomain[0].NAME;
    }

    return domainName;
  };

  const isLoadingData = domainReducer.loading && domainReducer.waiting == domainTypes.LIST_DOMAIN;
  const isSubmitLoading = domainReducer.loading && includes([domainTypes.CREATE_DOMAIN], domainReducer.waiting);
  const isLoadingDelete = domainReducer.loading && includes([domainTypes.DELETE_DOMAIN], domainReducer.waiting);
  const isLoadingVerify = domainReducer.loading && includes([domainTypes.VERIFY_DOMAIN], domainReducer.waiting);
  const isLoadingMarkDefault = domainReducer.loading && includes([domainTypes.MARK_DEFAULT_DOMAIN], domainReducer.waiting);
  const isLoadingRemoveDefault = domainReducer.loading && includes([domainTypes.REMOVE_DEFAULT_DOMAIN], domainReducer.waiting);

  const { total_record: totalRecord, total_page: totalPage } = domainReducer;
  let fromItem = 0;
  let toItem = 0;
  if ((domainReducer.domains || []).length > 0) {
    fromItem = (activePage - 1) * selectedLimit + 1;
    toItem = fromItem + domainReducer.domains.length - 1;
  }

  const msgShowItem = `${t("PAGINATION.SHOW")} ${fromItem} ${t("PAGINATION.TO")} ${toItem} ${t("PAGINATION.OF")} ${baseHelper.formatNumber(
    totalRecord
  )} ${t("PAGINATION.ITEMS")}`;

  return (
    <div className="page-setting" id="page-setting-domain">
      <div className="block-title-action">
        <h3 className="title">{t("SETTINGS.DOMAIN")}</h3>
        <div className="btn-action">
          <div className="action-item create-domain">
            <button type="button" className="ladiui btn btn-primary btn-sm " onClick={openModalCreate}>
              <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />
              {t("STORES.LB_CREATE_DOMAIN")}
            </button>
          </div>
        </div>
      </div>

      <div className="ladiui-table-actions">
        <div className="action-item">
          <div className="ladiui search-keyword">
            <input
              type="text"
              id="search"
              placeholder={t("COMMON.SEARCH")}
              value={keyword}
              onChange={(event) => setKeyword(event.target.value)}
              onKeyDown={onKeyDownInput}
              className="ladiui form-control"
            />
            <i className="ladi-icon icon-zoom" onClick={list} />
          </div>
        </div>
      </div>

      {isLoadingData ? (
        <LoadingTable />
      ) : (
        <div>
          <div className="ladi-card ladiui-table-responsive">
            <table className="ladiui table text-left">
              <thead>
                <tr className="ladiui table-vertical header">
                  <th scope="col" name="name" className={`text-left`}>
                    {t("DOMAIN.DOMAIN")}
                  </th>
                  <th scope="col" name="name" className={`text-left`}>
                    {t("DOMAIN.DOMAIN_TYPE")}
                  </th>
                  <th scope="col" name="name" className={`text-left`}>
                    {t("COMMON.STATUS")}
                  </th>
                  <th scope="col" name="name" className={`text-left`}>
                    {t("COMMON.CREATED_AT")}
                  </th>
                  <th scope="col" name="name" className={`text-left`}>
                    {t("COMMON.UPDATED_AT")}
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {map(domainReducer.domains, (item) => {
                  return (
                    <tr key={item.domain_id} className="ladiui table-vertical main">
                      <td scope="row">
                        {item.domain}
                        {item.is_default ? <span className="address-default-btn">Mặc định</span> : ""}
                      </td>
                      <td>{getDomainName(item.types)}</td>
                      <td>{item.is_verified == appConfig.STATUS.ACTIVE ? t("DOMAIN_VERIFIED.YES") : t("DOMAIN_VERIFIED.NO")}</td>
                      <td>{baseHelper.formatStrToDate(item.created_at)}</td>
                      <td>{baseHelper.formatStrToDate(item.updated_at)}</td>
                      <td className="text-right pd-0">
                        <div className="ladiui btn-group">
                          <div className="ladiui dropdown hide-mt ba-c">
                            <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                              <i className="ladiui icon icon-ldp-dot"></i>
                            </button>
                            <ul className="ladiui dropdown-menu actions-dropdown-domain r-0">
                              <li>
                                <a className="ladiui dropdown-item" onClick={() => openDeleteModal(item)}>
                                  {t("ACTIONS.DELETE")}
                                </a>
                              </li>
                              {item.is_verified == appConfig.STATUS.INACTIVE && (
                                <li>
                                  <a className="ladiui dropdown-item" onClick={() => openVerifyModal(item)}>
                                    {t("DOMAIN.LB_DOMAIN_VERIFY")}
                                  </a>
                                </li>
                              )}
                              {item.is_verified == appConfig.STATUS.ACTIVE && item.is_default == appConfig.STATUS.INACTIVE && (
                                <li>
                                  <a className="ladiui dropdown-item" onClick={() => openMarkDefaultModal(item)}>
                                    {t("ACTIONS.MARK_DEFAULT")}
                                  </a>
                                </li>
                              )}
                              {item.is_default == appConfig.STATUS.ACTIVE && (
                                <li>
                                  <a className="ladiui dropdown-item" onClick={() => openRemoveDefaultModal(item)}>
                                    {t("ACTIONS.REMOVE_DEFAULT")}
                                  </a>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
                {totalRecord <= 0 && (
                  <tr className="text-center">
                    <td colSpan="100%">{t("NO_DATA")}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {totalRecord > 0 && (
            <LadiPagination
              conditionsProp={{ page: activePage, limit: selectedLimit }}
              listName={t("PAGINATION.ITEMS")}
              message={""}
              items={domainReducer.domains}
              total={totalRecord}
              limitAction={onChangeLimit}
              pageAction={onPageChange}
            />
          )}
        </div>
      )}

      {isShowModal && (
        <Modal
          id="modal-domain"
          title={t("DOMAIN.ADD")}
          visible={isShowModal}
          onCancel={() => setIsShowModal(false)}
          onOk={submit}
          isLoading={isSubmitLoading}
          bodyClassName="position-initial"
          okText={t("DOMAIN.CREATE")}
          width={600}
        >
          <div className="ladiui form-group">
            <label className="ladiui-label">{t("DOMAIN.NAME")}</label>
            <Input
              ref={(ref) => inputsRef.add(ref)}
              name="domain"
              validationName={t("STORES.LB_DOMAIN")}
              placeholder={`${t("STORES.PL_DOMAIN")}`}
              value={domain.domain}
              onChange={(event) => onChangeDomain(event)}
              validations={{ isDomain: true }}
            />
          </div>
          <div className="ladiui form-group">
            <label className="ladiui-label">{"Loại tên miền"}</label>
            <Dropdown
              data={appConfig.LIST_DOMAIN_TYPE}
              onSelectItem={(data) => {
                handleChangDomainType(data);
              }}
              currentKey={domain.types}
              wrapperClassName="position-initial w100"
              idBlock="dropdown-domain-custom"
              _key="CODE"
              _value="NAME"
              placeHolder={"Chọn loại"}
              // dropup={dropup}
            />
          </div>
        </Modal>
      )}

      <ConfirmModal
        id="confirm-domain-delete"
        title={t("DOMAIN.MSG_DELETE_DOMAIN_TITLE")}
        content={t("DOMAIN.MSG_DELETE_DOMAIN_CONTENT")}
        cancelText={t("ACTIONS.CANCEL")}
        okText={t("ACTIONS.DELETE")}
        onOk={() => dispatch(domainActions.delete(selectedDomain.domain))}
        isLoading={isLoadingDelete}
      />

      <ConfirmModal
        id="confirm-domain-verify"
        title={t("DOMAIN.MSG_VERIFY_DOMAIN_TITLE")}
        content={t("DOMAIN.MSG_VERIFY_DOMAIN_CONTENT")}
        cancelText={t("ACTIONS.CANCEL")}
        okText={t("DOMAIN.LB_DOMAIN_VERIFY")}
        onOk={() => dispatch(domainActions.verify(selectedDomain.domain))}
        isLoading={isLoadingVerify}
      />

      <ConfirmModal
        id="confirm-domain-mark-default"
        title={t("DOMAIN.MSG_MARK_DEFAULT_DOMAIN_TITLE")}
        content={t("DOMAIN.MSG_MARK_DEFAULT_DOMAIN_CONTENT")}
        cancelText={t("ACTIONS.CANCEL")}
        okText={t("ACTIONS.MARK_DEFAULT")}
        onOk={() => dispatch(domainActions.markDefault(selectedDomain.domain))}
        isLoading={isLoadingMarkDefault}
      />

      <ConfirmModal
        id="confirm-domain-remove-default"
        title={t("DOMAIN.MSG_REMOVE_DEFAULT_DOMAIN_TITLE")}
        content={t("DOMAIN.MSG_REMOVE_DEFAULT_DOMAIN_CONTENT")}
        cancelText={t("ACTIONS.CANCEL")}
        okText={t("ACTIONS.REMOVE_DEFAULT")}
        onOk={() => dispatch(domainActions.removeDefault(selectedDomain.domain))}
        isLoading={isLoadingRemoveDefault}
      />
    </div>
  );
}
