import React from 'react';

import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Input from '../../../../components/Input';
import PanelShippingAddress from '../../components/ShippingAddress';
import appConfig from '../../../../config/app';
import { connect } from 'react-redux';

import shippingActions from '../../../../redux/futures/shipping/actions';
import * as shippingTypes from '../../../../redux/futures/shipping/types';

import orderActions from '../../../../redux/futures/order/actions';
import * as orderTypes from '../../../../redux/futures/order/types';

import { cloneDeep, includes, map, find, add } from 'lodash';
import Modal from '../../../../components/Modal';
import GHNForm from './GHNForm';
import GHTKForm from './GHTKForm';
import VNPostForm from './VNPostForm';
import ViettelPostForm from './ViettelPostForm';
import LoadingScene from '../../../../components/LoadingScene';
import SelectOption from '../../../../components/SelectOption';
import { appLocalStorage } from '../../../../localforage';
import baseHelper from '../../../../helpers/BaseHelper';
import ShippingAddressGHN from './ShippingAddressGHN';

class ShippingDelivery extends React.Component {
    static propTypes = {
        orderID: PropTypes.number,
        shippingAddress: PropTypes.object,
        weight: PropTypes.any,
        total: PropTypes.any,
        note: PropTypes.string,
        isShowModalShipping: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            isShowingPanel: false,
            selectedPartner: {
                code: '',
                name: '',
            },
            selectedHub: {
                code: '',
                name: '',
            },
            selectedGhnShifts: [],
            weight: props.weight,
            temp_weight: props.weight,
            note: props.note,
            shipping_code: '',
            cod_amount: props.cod_amount,
            temp_cod_amount: props.cod_amount,
            email_notification: true,
            isShowModalShipping: props.isShowModalShipping,
        };

        this.focusWeight = null;
        this.focusCodAmount = null;
        this.ghnFormRef = React.createRef();
        this.ghtkFormRef = React.createRef();
        this.vnPostFormRef = React.createRef();
        this.viettelPostFormRef = React.createRef();
        this.inputsRef = new Set();
    }

    componentDidMount() {
        this.props.listPartners(appConfig.LIST_PARTNER_ACTION.SHIPPING);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.weight != nextProps.weight) {
            this.setState({
                weight: nextProps.weight,
                temp_weight: nextProps.weight,
            });
        }

        if (this.props.cod_amount != nextProps.cod_amount) {
            this.setState({
                cod_amount: nextProps.cod_amount,
            });
        }

        if (this.props.shippingReducer.action != nextProps.shippingReducer.action) {
            if (nextProps.shippingReducer.action == shippingTypes.SHIPPING_LIST_PARTNERS) {
                if (nextProps.shippingReducer.status) {
                    // Lấy ra partnerCode gần đây nhất
                    appLocalStorage.getItem(appConfig.LOCAL_FORAGE.RECENT_PARTNRER_CODE).then((result) => {
                        let recentPartner = find(nextProps.shippingReducer.partners, (item) => item.code == result);
                        if (!recentPartner) {
                            recentPartner = nextProps.shippingReducer.partners[0];
                            appLocalStorage.setItem(appConfig.LOCAL_FORAGE.RECENT_PARTNRER_CODE, recentPartner.code);
                        }

                        this.setState(
                            {
                                selectedPartner: recentPartner,
                            },
                            () => {
                                this.listHubs();
                            }
                        );
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.shippingReducer.message, 'OK');
                }
            }

            if (
                includes(
                    [
                        shippingTypes.SHIPPING_LIST_HUBS,
                        shippingTypes.SHIPPING_LIST_OTHER_HUBS,
                        shippingTypes.SHIPPING_LIST_GHN_HUBS,
                        shippingTypes.SHIPPING_LIST_GHTK_HUBS,
                        shippingTypes.SHIPPING_LIST_VNPOST_HUBS,
                        shippingTypes.SHIPPING_LIST_VTPOST_HUBS,
                    ],
                    nextProps.shippingReducer.action
                )
            ) {
                if (nextProps.shippingReducer.status) {
                    if (nextProps.shippingReducer.hubs.length > 0) {
                        this.setState({
                            selectedHub: nextProps.shippingReducer.hubs[0],
                            selectedGhnShifts: nextProps.shippingReducer.ghnShifts,
                        });
                    } else {
                        this.setState({
                            selectedHub: {
                                code: '',
                                name: '',
                            },
                        });
                        window.LadiUI.showErrorMessage('Thông báo', nextProps.t('SHIPPINGS.SETUP_AT_LEAST_ONE_HUB'), 'OK');
                    }
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.shippingReducer.message, 'OK');
                }
            }

            if (nextProps.shippingReducer.action == shippingTypes.SHIPPING_GET_FEE) {
                if (nextProps.shippingReducer.status) {
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.shippingReducer.message, 'OK');
                }
            }
        }

        if (this.props.order.action != nextProps.order.action) {
            if (orderTypes.SHIPPING_UPDATE_ORDER_ADDRESS == nextProps.order.action) {
                if (nextProps.order.status) {
                    this.getPartnerData();
                } else {
                }
            }

            if (orderTypes.RELOAD_ORDER == nextProps.order.action) {
                if (nextProps.order.status) {
                    this.getPartnerData(true);
                }
            }
        }
    }

    resetState = () => {
        this.setState({
            ...cloneDeep(this.initialState),
            weight: this.props.weight,
            temp_weight: this.props.weight,
        });
    };

    listHubs = () => {
        setTimeout(() => {
            this.props.listHubs(this.state.selectedPartner.code);
        }, 0);
    };

    onChangeInput = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    };

    onChangePartner = (selectedPartner) => {
        if (selectedPartner.code == this.state.selectedPartner.code) {
            return;
        }

        this.props.resetFee();

        this.setState(
            {
                selectedPartner,
                selectedHub: {
                    code: '',
                    name: '',
                },
            },
            () => {
                appLocalStorage.setItem(appConfig.LOCAL_FORAGE.RECENT_PARTNRER_CODE, selectedPartner.code);
                switch (selectedPartner.code) {
                    case appConfig.SHIPPING_PARTNERS.OTHER.CODE:
                        if (this.props.shippingReducer.loadedOtherHub) {
                            setTimeout(() => {
                                this.props.listOtherHubs();
                            }, 0);
                            return;
                        }
                        break;
                    case appConfig.SHIPPING_PARTNERS.GHN.CODE:
                        if (this.props.shippingReducer.loadedGhnHub) {
                            setTimeout(() => {
                                this.props.listGhnHubs();
                            }, 0);
                            return;
                        }
                        break;
                    case appConfig.SHIPPING_PARTNERS.GHTK.CODE:
                        if (this.props.shippingReducer.loadedGhtkHub) {
                            setTimeout(() => {
                                this.props.listGhtkHubs();
                            }, 0);
                            return;
                        }
                        break;
                    case appConfig.SHIPPING_PARTNERS.VNPOST.CODE:
                        if (this.props.shippingReducer.loadedVnPostHub) {
                            setTimeout(() => {
                                this.props.listVnPostHubs();
                            }, 0);
                            return;
                        }
                        break;
                    case appConfig.SHIPPING_PARTNERS.VTPOST.CODE:
                        if (this.props.shippingReducer.loadedVtPostHub) {
                            setTimeout(() => {
                                this.props.listVtPostHubs();
                            }, 0);
                            return;
                        }
                        break;
                }
                this.listHubs();
            }
        );
    };

    onChangeHub = async (selectedHub) => {
        await this.setState({
            selectedHub,
        });
    };

    onBlurWeight = () => {
        if (this.focusWeight == this.state.weight) {
            return;
        }

        // Clear validate weight
        document.getElementById('weight').classList.remove('error');
        this.props.resetFee();

        this.setState(
            {
                temp_weight: this.state.weight,
            },
            () => {
                if (!this.validateShippingAddress()) {
                    return;
                }

                this.getPartnerData();
            }
        );
    };

    onBlurCodAmount = () => {
        if (this.focusCodAmount == this.state.cod_amount) {
            return;
        }

        // Clear validate cod amount
        document.getElementById('cod_amount').classList.remove('error');

        this.setState(
            {
                temp_cod_amount: this.state.cod_amount,
            },
            () => {}
        );
    };

    validateShippingAddress = () => {
        const { shippingAddress } = this.props;
        let result = true;
        if (
            baseHelper.isEmpty(
                shippingAddress.state_id,
                shippingAddress.district_id,
                shippingAddress.address,
                shippingAddress.phone,
                shippingAddress.first_name
            )
        ) {
            result = false;
        }

        if (this.state.selectedPartner.code == appConfig.SHIPPING_PARTNERS.GHN.CODE && baseHelper.isEmpty(shippingAddress.ward_id)) {
            result = false;
        }

        if (!result) {
            window.LadiUI.toastCustom('danger', '', this.props.t('VALIDATION.REQUIRED_SHIPPING_ADDRESS'));
        }
        return result;
    };

    getPartnerData = (forceGetFee = false) => {
        const { selectedPartner, selectedHub } = this.state;
        switch (selectedPartner.code) {
            case appConfig.SHIPPING_PARTNERS.GHN.CODE:
                if (this.ghnFormRef.current) {
                    return this.ghnFormRef.current.listServices(selectedHub.district_id);
                }
            case appConfig.SHIPPING_PARTNERS.GHTK.CODE:
                if (this.ghtkFormRef.current) {
                    return this.ghtkFormRef.current.getFee();
                }
            case appConfig.SHIPPING_PARTNERS.VNPOST.CODE:
                if (this.vnPostFormRef.current) {
                    return this.vnPostFormRef.current.listServices(selectedHub.code);
                }
            case appConfig.SHIPPING_PARTNERS.VTPOST.CODE:
                if (this.viettelPostFormRef.current) {
                    return this.viettelPostFormRef.current.getFee(forceGetFee);
                }
            default:
                break;
        }
    };

    onChangeEmailNotification = () => {
        this.setState({
            email_notification: !this.state.email_notification,
        });
    };

    submit = () => {
        if (!this.validateShippingAddress()) {
            return;
        }
        // Clear validate weight
        document.getElementById('weight').classList.remove('error');
        const { selectedPartner, selectedHub, note, email_notification, cod_amount, weight } = this.state;

        const data = {
            order_id: this.props.orderID,
            shipping_partner_code: selectedPartner.code,
            cod_amount,
            shipping_note: note,
            email_notification,
            weight,
        };

        switch (selectedPartner.code) {
            case appConfig.SHIPPING_PARTNERS.GHN.CODE:
                if (!this.ghnFormRef.current.validate(false)) {
                    return;
                }

                const ghnData = this.ghnFormRef.current.getData();
                data.ghn_data = {
                    client_hub_id: selectedHub.code,
                    shop_id: selectedHub.code,
                    return_phone: selectedHub.phone,
                    return_district_id: selectedHub.district_id,
                    return_ward_code: selectedHub.ward_code,
                    return_address: selectedHub.address,
                    ...ghnData,
                };
                break;
            case appConfig.SHIPPING_PARTNERS.GHTK.CODE:
                if (!this.ghtkFormRef.current.validate()) {
                    return;
                }

                const ghtkData = this.ghtkFormRef.current.getData();
                data.ghtk_data = {
                    store_address_id: selectedHub.code,
                    ...ghtkData,
                };
                break;
            case appConfig.SHIPPING_PARTNERS.VNPOST.CODE:
                if (!this.vnPostFormRef.current.validate()) {
                    return;
                }

                const vnPostData = this.vnPostFormRef.current.getData();
                data.vnpost_data = {
                    store_address_id: selectedHub.code,
                    ...vnPostData,
                };
                break;
            case appConfig.SHIPPING_PARTNERS.VTPOST.CODE:
                if (!this.viettelPostFormRef.current.validate()) {
                    return;
                }

                const viettelPostData = this.viettelPostFormRef.current.getData();
                data.viettel_post_data = {
                    store_address: selectedHub,
                    ...viettelPostData,
                };
                break;
            default:
                break;
        }

        this.props.fullfill(data);
    };

    getPartnerLayout = () => {
        const { selectedPartner, selectedHub, temp_weight, temp_cod_amount, selectedGhnShifts } = this.state;

        switch (selectedPartner.code) {
            case appConfig.SHIPPING_PARTNERS.OTHER.CODE:
                return <div></div>;
            case appConfig.SHIPPING_PARTNERS.GHN.CODE:
                return (
                    <GHNForm
                        ref={this.ghnFormRef}
                        orderID={this.props.orderID}
                        fromDistrictID={selectedHub.district_id}
                        weight={temp_weight}
                        total={this.props.total}
                        shopID={selectedHub.code}
                        shifts={selectedGhnShifts}
                    />
                );
            case appConfig.SHIPPING_PARTNERS.GHTK.CODE:
                return (
                    <GHTKForm
                        ref={this.ghtkFormRef}
                        orderID={this.props.orderID}
                        storeAddressID={selectedHub.code}
                        weight={temp_weight}
                        total={this.props.total}
                    />
                );
            case appConfig.SHIPPING_PARTNERS.VNPOST.CODE:
                return (
                    <VNPostForm
                        ref={this.vnPostFormRef}
                        storeAddressID={selectedHub.code}
                        orderID={this.props.orderID}
                        weight={temp_weight}
                        codAmount={temp_cod_amount}
                    />
                );
            case appConfig.SHIPPING_PARTNERS.VTPOST.CODE:
                return (
                    <ViettelPostForm
                        ref={this.viettelPostFormRef}
                        orderID={this.props.orderID}
                        selectedHub={selectedHub}
                        weight={temp_weight}
                        codAmount={temp_cod_amount}
                        total={this.props.total}
                    />
                );
        }
    };

    hideModalShipping = () => {
        this.setState({
            selectedPartner: {
                code: '',
                name: '',
            },
            selectedHub: {
                code: '',
                name: '',
            },
        });
        this.props.hideModalShipping();
    };

    onChangeAddress = (address, addressType) => {
        this.props.onChangeAddress(address, addressType, true);
    };

    onChangeAddressGHN = (address, addressType) => {
        this.setState({
            selectedHub: {
                ...this.state.selectedHub,
                ...address,
            },
        });
    };

    render() {
        const isLoadingPartner =
            this.props.shippingReducer.loading &&
            includes([shippingTypes.SHIPPING_LIST_PARTNERS, shippingTypes.SHIPPING_LIST_HUBS], this.props.shippingReducer.waiting);
        // const { t } = this.props;
        const { t, shippingAddress, addresses, isShowModalShipping } = this.props;
        const { partners, hubs } = this.props.shippingReducer;
        const { cod_amount, note, email_notification, selectedPartner, selectedHub, weight } = this.state;
        const isSubmitLoading = this.props.order.loading && orderTypes.FULLFILL_ORDER == this.props.order.waiting;
        const isLoadingUpdateAddress =
            this.props.order.loading && includes([orderTypes.SHIPPING_UPDATE_ORDER_ADDRESS], this.props.order.waiting);
        return (
            <Modal
                id='modal-shipping-form'
                visible={isShowModalShipping}
                title={t('SHIPPINGS.CREATE_SHIPPING')}
                bodyStyles={{ display: 'flex' }}
                width={900}
                onCancel={this.hideModalShipping}
                footer={
                    <div>
                        <div className='shipping-footer'>
                            <div className='sendEmail'>
                                <input
                                    style={{ display: 'none' }}
                                    id='emailNotification'
                                    checked={email_notification}
                                    type='checkbox'
                                    onChange={this.onChangeEmailNotification}
                                    className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                />
                                <label style={{ display: 'none' }} className='ladiui label'>
                                    {' '}
                                    {t('COMMON.EMAIL_NOTIFICATION')}
                                </label>
                            </div>
                            <div className='flex' style={{ display: 'flex' }}>
                                <button onClick={this.hideModalShipping} className='ladiui btn btn-secondary'>
                                    {t('ACTIONS.CLOSE')}
                                </button>
                                <button
                                    onClick={this.submit}
                                    className={`ladiui btn btn-primary ${isSubmitLoading ? 'loader' : ''}`}
                                    disabled={isSubmitLoading}
                                >
                                    {' '}
                                    {t('ACTIONS.SAVE')}
                                </button>
                            </div>
                        </div>
                    </div>
                }
            >
                <div className='left'>
                    <div className='ladiui-row'>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>{t('SHIPPINGS.FROM_PARTNER')}</label>
                            <div className='ladiui data-hub'>
                                <SelectOption
                                    wrapperStyle={{ zIndex: 7 }}
                                    innerClassName='select-hub'
                                    innerStyle={{
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        width: 200,
                                        whiteSpace: 'nowrap',
                                    }}
                                    currentValue={selectedPartner.name}
                                    _key='code'
                                    _value='name'
                                    items={partners || []}
                                    onSelectItem={this.onChangePartner}
                                />
                            </div>
                        </div>

                        <div className='ladiui form-group' style={{ marginTop: '0px' }}>
                            <label className='ladiui-label'>{t('SHIPPINGS.FROM_HUB')}</label>
                            <div className='ladiui dropdown' style={{ width: '100%' }}>
                                <button
                                    className='ladiui btn btn-outline-light dropdown-toggle'
                                    data-toggle='dropdown'
                                    style={{ width: '100%', paddingRight: '30px' }}
                                >
                                    {selectedHub.name}
                                </button>
                                <ul className='ladiui dropdown-menu custom-form-dynamic' style={{ width: '100%' }}>
                                    {map(hubs, (item, index) => (
                                        <li key={index} onClick={() => this.onChangeHub(item)}>
                                            <a data-value={item.code} className='ladiui dropdown-item' href='#'>
                                                {item.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>

                    {this.getPartnerLayout()}

                    <div className='ladiui form-group'>
                        <label className='ladiui-label'>{t('SHIPPINGS.NOTE')}</label>
                        <textarea
                            className='ladiui textarea'
                            name='note'
                            value={note ? note : ''}
                            onChange={this.onChangeInput}
                            style={{ minHeight: 100 }}
                        />
                    </div>

                    <div className='ladiui form-group'>
                        <p
                            className='suggestion'
                            dangerouslySetInnerHTML={{
                                __html: t('SUGGESTIONS.SHIPPING_PARTNER_HUB'),
                            }}
                        ></p>
                    </div>
                </div>

                <div className='right block-shipping-delivery'>
                    <PanelShippingAddress
                        mode={appConfig.FORM_MODE.EDIT}
                        shippingAddress={shippingAddress}
                        addresses={addresses}
                        onChangeAddress={this.onChangeAddress}
                        isLoading={isLoadingUpdateAddress}
                        isRequiredState={selectedPartner.code == appConfig.SHIPPING_PARTNERS.GHN.CODE}
                        isRequiredDistrict={selectedPartner.code == appConfig.SHIPPING_PARTNERS.GHN.CODE}
                        isRequiredWard={selectedPartner.code == appConfig.SHIPPING_PARTNERS.GHN.CODE}
                    />

                    {selectedPartner.code == appConfig.SHIPPING_PARTNERS.GHN.CODE && (
                        <ShippingAddressGHN
                            mode={appConfig.FORM_MODE.EDIT}
                            shippingAddress={selectedHub}
                            onChangeAddress={this.onChangeAddressGHN}
                            isRequiredState={selectedPartner.code == appConfig.SHIPPING_PARTNERS.GHN.CODE}
                            isRequiredDistrict={selectedPartner.code == appConfig.SHIPPING_PARTNERS.GHN.CODE}
                            isRequiredWard={selectedPartner.code == appConfig.SHIPPING_PARTNERS.GHN.CODE}
                            classNameCustom='mt-24'
                        />
                    )}

                    <div className='order-info'>
                        <div className='heading'>
                            <h3>{t('ORDERS.INFO')}</h3>
                        </div>
                        <div className='content'>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('SHIPPINGS.WEIGHT')}</label>
                                <Input
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    id='weight'
                                    name='weight'
                                    value={weight}
                                    onChange={this.onChangeInput}
                                    onFocus={() => {
                                        this.focusWeight = weight;
                                    }}
                                    onBlur={this.onBlurWeight}
                                />
                            </div>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('SHIPPINGS.COD_AMOUNT')}</label>
                                <Input
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    id='cod_amount'
                                    name='cod_amount'
                                    value={cod_amount}
                                    onChange={this.onChangeInput}
                                    type='number'
                                    currency={true}
                                    prefix={t('CURRENCIES.VND_CODE')}
                                    formatter={(value) => `đ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/[đ, ]/g, '')}
                                    onFocus={() => {
                                        this.focusCodAmount = cod_amount;
                                    }}
                                    onBlur={this.onBlurCodAmount}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        listServices: (data) => dispatch(shippingActions.listServices(data)),
        listPartners: (type) => dispatch(shippingActions.listPartners(type)),
        resetFee: () => dispatch(shippingActions.resetFee()),
        listHubs: (partnerCode) => dispatch(shippingActions.listHubs(partnerCode)),
        listOtherHubs: () => dispatch(shippingActions.listOtherHubs()),
        listGhnHubs: () => dispatch(shippingActions.listGhnHubs()),
        listVnPostHubs: () => dispatch(shippingActions.listVnPostHubs()),
        listVtPostHubs: () => dispatch(shippingActions.listVtPostHubs()),
        listGhtkHubs: () => dispatch(shippingActions.listGhtkHubs()),
        fullfill: (data) => dispatch(orderActions.fullfill(data)),
        reload: (orderID) => dispatch(orderActions.reload(orderID)),
    };
};

const mapStateToProps = (state) => ({
    shippingReducer: { ...state.shipping },
    order: { ...state.order },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(withTranslation('translation', { withRef: true })(ShippingDelivery));
